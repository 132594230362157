import React from 'react'
import { Tabs, Alert, Result, Button, Row, Col, Divider } from 'antd'
import { LocationSettingProps } from './Settings'
import DeliveryForm from './PreOrderSettings/DeliveryForm'
import PickupForm from './PreOrderSettings/Pickup'
import CutoffTimesForm from './PreOrderSettings/CutoffTimesForm'
import {
  getEnabledCutoffs,
  getStorePreorderSettings
} from './PreOrderSettings/utils'
import { PageTooltip } from './../Widgets'
import { RiErrorWarningLine } from 'react-icons/ri'
import { Cutoffs } from '@slerp/controls'

const { TabPane } = Tabs

export const validateCutoffs = (cutoffs?: Cutoffs | null) => {
  if (!cutoffs) return false

  const enabledCutoffs = getEnabledCutoffs(cutoffs)
  return enabledCutoffs.length !== 0
}

const PreOrderSettings = (props: LocationSettingProps) => {
  const preorderSettings = getStorePreorderSettings(props.store)
  const needsToSetupCutoffs = !validateCutoffs(preorderSettings?.cutoffs)

  const TABS = [
    {
      label: (
        <>
          Cut-off Times{' '}
          {needsToSetupCutoffs ? (
            <RiErrorWarningLine className='cl-text-warning _no-hover-color' />
          ) : (
            ''
          )}
        </>
      ),
      key: 'cutoffs',
      content: <CutoffTimesForm store={props.store} />,
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/setting-cut-off-times-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Cut-off Times
          </a>
          <a
            href='https://support.slerp.com/en_us/setting-the-pre-order-time-period-feature-HkHbcDhp_'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-order Time Period
          </a>
          <a
            href='https://support.slerp.com/en_us/pre-orders-setup-By4F8Uj6O'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-orders setup
          </a>
          <a
            href='https://support.slerp.com/knowledge/setting-special-cut-off-times-for-pre-orders-1'
            target='_blank'
            rel='noopener noreferrer'
          >
            Special Cut-off Times
          </a>
          <a
            href='https://support.slerp.com/knowledge/special-availability-products-1'
            target='_blank'
            rel='noopener noreferrer'
          >
            Special Days Category
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Delivery',
      key: 'delivery',
      content: (
        <DeliveryForm
          merchant={props.merchant}
          store={props.store}
          invalidCutoffs={needsToSetupCutoffs}
          refetch={props.refetch}
        />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/custom-delivery-radius'
            target='_blank'
            rel='noopener noreferrer'
          >
            Custom Radius (polygon)
          </a>
          <a
            href='https://support.slerp.com/knowledge/how-is-a-vehicle-chosen-for-an-order'
            target='_blank'
            rel='noopener noreferrer'
          >
            Delivery methods & vehicle allocation
          </a>
          <a
            href='https://support.slerp.com/knowledge/delivery-costs'
            target='_blank'
            rel='noopener noreferrer'
          >
            Delivery Costs
          </a>
          <a
            href='https://support.slerp.com/knowledge/setting-a-delivery-zone-for-pre-order-deliveries'
            target='_blank'
            rel='noopener noreferrer'
          >
            Delivery Zone for Pre-orders
          </a>
          <a
            href='https://support.slerp.com/knowledge/enable-order-limit-by-time-slot-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Enable Order Limit for Pre-orders
          </a>
          <a
            href='https://support.slerp.com/en_us/order-limit-by-time-slot-BkFv9LjTO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Orders by time slot
          </a>
          <a
            href='https://support.slerp.com/knowledge/intro-to-delivery'
            target='_blank'
            rel='noopener noreferrer'
          >
            Local & Nationwide Delivery
          </a>
          <a
            href='https://support.slerp.com/en_us/quoted-courier-fee-vs-custom-fee-Synv7PnTd'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-order Delivery Fees
          </a>
          <a
            href='https://support.slerp.com/en_us/choosing-delivery-times-for-pre-orders-ryDqEwn6_'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-order Delivery Times
          </a>
          <a
            href='https://support.slerp.com/en_us/pre-orders-setup-By4F8Uj6O'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-orders setup
          </a>
          <a
            href='https://support.slerp.com/knowledge/delivery-times-slots-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Time Slots for Pre-orders Deliveries
          </a>
          <a
            href='https://support.slerp.com/knowledge/who-will-deliver-your-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Who Will Deliver Pre-orders?
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Pickup',
      key: 'pickup',
      content: (
        <PickupForm
          store={props.store}
          invalidCutoffs={needsToSetupCutoffs}
          refetch={props.refetch}
        />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/pickup-times-slots-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Collection & Pick-up Timings
          </a>
          <a
            href='https://support.slerp.com/knowledge/enable-order-limit-by-time-slot-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Enable Order Limit for Pre-orders
          </a>
          <a
            href='https://support.slerp.com/en_us/order-limit-by-time-slot-BkFv9LjTO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Orders by time slot
          </a>
          <a
            href='https://support.slerp.com/knowledge/pickup-times-slots-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-order Pickup Times
          </a>
          <a
            href='https://support.slerp.com/en_us/pre-orders-setup-By4F8Uj6O'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pre-orders setup
          </a>
          <a
            href='https://support.slerp.com/knowledge/delivery-times-slots-for-pre-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Time Slots for Pre-orders Pickups
          </a>
        </PageTooltip>
      )
    }
  ]

  const defaultPageKey = 'cutoffs'
  const keyFromURL = window.location.hash.replace('#', '') || defaultPageKey
  const tabKeys = TABS.map((tab) => tab.key)
  const activeKey = tabKeys.includes(keyFromURL)
    ? keyFromURL.toString()
    : defaultPageKey

  const CutoffsWarning = () => (
    <Alert
      message='Please setup your cutoff times'
      description={
        <>
          Cutoffs determine the days that pre-orders can be placed for, and the
          times that the pre-orders must be placed by.
          {activeKey !== 'cutoffs' ? (
            <>
              {' '}
              Click <a href={`${window.location.pathname}#cutoffs`}>here</a> to
              update your cutoff settings.
            </>
          ) : (
            ' Please update your cutoffs below.'
          )}
        </>
      }
      type='warning'
      showIcon
      closable
      style={{ margin: '16px 0' }}
    />
  )

  return (
    <>
      {needsToSetupCutoffs && <CutoffsWarning />}
      <Tabs
        onTabClick={(key) =>
          (window.location.href = `${window.location.pathname}#${key}`)
        }
        defaultActiveKey={activeKey}
        /* I couldn't fix the animation 😿.
        Disabling it for now */
        animated={{ inkBar: false, tabPane: true }}
      >
        {TABS.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            <Row gutter={40} className='_mt-24 _pl-16'>
              <Col span={15}>{tab.content}</Col>
              <Col>
                <Divider type='vertical' />
              </Col>
              <Col>{tab.tooltip}</Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}

export default PreOrderSettings
