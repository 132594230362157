import React, { useState } from 'react'
import { Form, Radio, Tooltip, PageHeader } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { AppSettings } from 'types'
import debounce from 'lodash/debounce'

const { useForm } = Form

interface Props {
  appSettings: AppSettings
  onChange: (values: AppSettings) => void
}

const OrderNow = (props: Props) => {
  const [form] = useForm()
  const { appSettings, onChange } = props
  const [splashPage, setSplashPage] = useState<boolean>(
    appSettings?.splash_page
  )

  const debouncedOnChange = debounce(onChange, 400)

  return (
    <Form
      className='_mt-48 _pl-16 _mb-48'
      form={form}
      data-testid='app-order-now-form'
      onValuesChange={debouncedOnChange}
      layout='vertical'
      scrollToFirstError={true}
      initialValues={appSettings}
    >
      <PageHeader title='Order Now' className='settings-title' />
      <Form.Item
        name='splash_page'
        label={
          <>
            Splash page:
            <Tooltip
              title={
                <>
                  If disabled, a list of locations is displayed instead.
                  <br />
                  <br />
                  If enabled, the Splash image uploaded in Designer will be
                  used.
                </>
              }
              placement='right'
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
      >
        <Radio.Group className='radio-group' data-testid='splash-page'>
          <Radio value={false} onChange={() => setSplashPage(false)}>
            Disabled
          </Radio>
          <Radio value={true} onChange={() => setSplashPage(true)}>
            Enabled
          </Radio>
        </Radio.Group>
      </Form.Item>

      {!splashPage && (
        <>
          <Form.Item
            name='search_bar'
            label={
              <>
                Search bar:
                <Tooltip
                  title='A search bar to search location names'
                  placement='right'
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          >
            <Radio.Group className='radio-group' data-testid='search-bar'>
              <Radio value={false}>Disabled</Radio>
              <Radio value={true}>Enabled</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name='locations_map'
            className='_mt-24'
            label={
              <>
                Show map button:
                <Tooltip
                  title='A map to see where stores are located'
                  placement='right'
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          >
            <Radio.Group className='radio-group' data-testid='show-map-button'>
              <Radio value={false}>Disabled</Radio>
              <Radio value={true}>Enabled</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default OrderNow
