import React from 'react'
import { useMutation } from '@apollo/client'
import { capitalize } from 'lodash'
import { Button, Col, message } from 'antd'
import { useSession } from '@slerp/accounts'
import { OrderInfo } from '../type'
import errorReducer from 'errors/errorReducer'
import { COMPLETE_ORDER } from '../../OrderQueries'
import RefundButton from './RefundButton'

interface AcceptedType {
  order: OrderInfo
  successCallback: () => void
  setShowRefundForm: (value?: any) => void
}

const Accepted = ({
  order,
  successCallback,
  setShowRefundForm
}: AcceptedType) => {
  const { user } = useSession()

  const [completeOrder, { loading: completingOrder }] = useMutation(
    COMPLETE_ORDER,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const completeOrderHandler = (e: React.MouseEvent) => {
    message.loading('Completing order... Please wait.', 1)
    completeOrder({
      variables: { transactionId: order.transaction_id, userId: user.id }
    })
      .then((response) => {
        const { completeOrder } = response?.data || {}
        message.success(`Order ${completeOrder.transactionId} completed!`, 1)
        successCallback()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  return (
    <>
      <Col>
        <RefundButton
          order={order}
          onClick={() => setShowRefundForm(true)}
          disabled={completingOrder}
        />
      </Col>
      <Col>
        <Button
          data-testid='order-info-actions-complete'
          title='fulfill'
          type='primary'
          disabled={completingOrder}
          onClick={completeOrderHandler}
        >
          {`${capitalize(order.fulfillment_type)} complete`}
        </Button>
      </Col>
    </>
  )
}

export default Accepted
