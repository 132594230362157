import React from 'react'
import styled from '@emotion/styled'
import { GoInfo } from 'react-icons/go'
import { FiAlertTriangle } from 'react-icons/fi'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import { MessageType } from './MessageForm'

export const MessageBubble: React.FC<{ messageType: MessageType }> = ({
  messageType
}) => {
  const messageContent = {
    warning: {
      title: 'Warnings',
      text: 'Use warnings to alert customers about circumstances or considerations when ordering. For example, longer wait times during peak hours.',
      icon: <WarningIcon />,
      bgColor: '#fff5ed'
    },
    announcement: {
      title: 'Announcements',
      text: 'Use announcements to engage or inform customers. For example, how to find a catering store with longer cut-off times in the future or to promote loyalty programs.',
      icon: <GoInfo size={16} color='#2a2a2a' />,
      bgColor: '#F7FFF4'
    }
  }

  const { title, text, icon, bgColor } = messageContent[messageType]

  return (
    <Container bgColor={bgColor} span={24}>
      <Row className='_mb-4' align='middle' gutter={[12, 0]}>
        <Col>{icon}</Col>
        <Col className='_pt-2'>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row className='_mb-0'>
        <span>{text}</span>
      </Row>
    </Container>
  )
}

const Container = styled(Col)<{ bgColor: string }>`
  padding: 12px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 12px;

  box-shadow: 0px 4px 16px 0px #00000008;

  h3 {
    font-size: 16px;
    line-height: 18.75px;
    margin: 0;
  }

  span {
    font-size: 14px;
    line-height: 16.41px;
    padding-left: 28px;
  }
`

const WarningIcon = styled(FiAlertTriangle)`
  width: 16px;
  height: 18.22px;
  fill: none;
  stroke-width: 2px;
  color: #2a2a2a;
`
