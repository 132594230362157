import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { Text } from '../Text'
import Tether from 'react-tether'
import { useTheme } from '../../contexts/Theme'

interface Props {
  heading?: string
  text: string
  width?: number
  attachment?: string
  disabled?: boolean
}

const Tooltip: React.FC<Props> = ({
  heading,
  text,
  attachment = 'middle right',
  width,
  disabled,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme() as any

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Tether
        attachment={attachment}
        targetAttachment='top left'
        constraints={[
          {
            to: 'scrollParent',
            attachment: 'together'
          }
        ]}
        renderTarget={(ref) => (
          <div
            ref={ref as React.RefObject<HTMLDivElement>}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {children}
          </div>
        )}
        renderElement={(ref) =>
          isOpen && (
            <Container
              ref={ref as React.RefObject<HTMLDivElement>}
              width={width}
            >
              {heading && (
                <HeadingContainer>
                  <Text color='white' size='sm' weight='700'>
                    {heading}
                  </Text>
                </HeadingContainer>
              )}

              <Text color='white' size='sm'>
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </Text>
            </Container>
          )
        }
        className='tether-zindex-setter'
      />

      <Global
        styles={(css as any)`
        .tether-zindex-setter {
          z-index: ${theme.zIndex.tooltip};
        }
      `}
      />
    </>
  )
}

const Container = styled.div<{ width?: number }>`
  position: relative;
  padding: 4px 8px;
  margin: 8px;
  ${({ width }) => width && `width: ${width}px;`}
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  z-index: ${({ theme }: any) => theme.zIndex.tooltip};

  a {
    color: ${({ theme }: any) => theme.colors.gray400};
  }
`

const HeadingContainer = styled.div`
  margin-bottom: 4px;
`

export { Tooltip }
