import gql from 'graphql-tag'

const GET_STORE_VARIANT_PRODUCT_PRICINGS = gql`
  query getStoreVariantProductPricings($storeVariantId: uuid!) {
    store_variants(where: { id: { _eq: $storeVariantId } }) {
      store {
        id
        name
        table_enabled: settings(path: "table_enabled")
      }
      storeByPreorderStoreId {
        id
        name
      }
      product_variant {
        id
        name
        vat
        price
        location_fulfillment_type_pricing: pricings(
          where: { store_variant_id: { _eq: $storeVariantId } }
        ) {
          id
          variant_id
          price
          price_with_vat
          vat
          store_variant_id
          fulfillment_type
          inserted_at
          updated_at
          published_at
        }
        global_ft_pricing: pricings(
          where: { store_variant_id: { _is_null: true } }
        ) {
          id
          variant_id
          price
          price_with_vat
          vat
          store_variant_id
          fulfillment_type
          inserted_at
          updated_at
        }
      }
    }
  }
`

const UPSERT_STORE_VARIANT_CORE_PRICING = gql`
  mutation upsertStoreVariantCorePricing($values: [pricings_insert_input!]!) {
    insert_pricings(
      objects: $values
      on_conflict: {
        constraint: pricings_pkey
        update_columns: [price, price_with_vat, vat, updated_at, published_at]
      }
    ) {
      affected_rows
    }
  }
`

const GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_SAMEDAY_INVENTORY_BY_PRODUCT_NAME = gql`
  query getStoreVariantIdAndPricingsFromSamedayInventory(
    $storeId: uuid!
    $productId: uuid!
    $variantName: String!
  ) {
    store_variants(
      where: {
        store_id: { _eq: $storeId }
        product_variant: {
          name: { _eq: $variantName }
          product: { id: { _eq: $productId } }
        }
      }
    ) {
      id
      pricings {
        id
        variant_id
        store_variant_id
        fulfillment_type
        price
        price_with_vat
        vat
      }
    }
  }
`

const GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_PREORDER_INVENTORY_BY_PRODUCT_NAME = gql`
  query getStoreVariantIdAndPricingsFromPreorderInventory(
    $storeId: uuid!
    $productId: uuid!
    $variantName: String!
  ) {
    store_variants(
      where: {
        preorder_store_id: { _eq: $storeId }
        product_variant: {
          name: { _eq: $variantName }
          product: { id: { _eq: $productId } }
        }
      }
    ) {
      id
      pricings {
        id
        variant_id
        store_variant_id
        fulfillment_type
        price
        price_with_vat
        vat
      }
    }
  }
`

const UNPUBLISH_LOCATION_FULFILMENT_TYPE_PRICINGS = gql`
  mutation unpublishFulfilmentTypePricings(
    $fulfilmentTypes: [pricings_insert_input!]!
  ) {
    insert_pricings(
      objects: $fulfilmentTypes
      on_conflict: {
        constraint: pricings_pkey
        update_columns: [published_at, updated_at]
      }
    ) {
      affected_rows
    }
  }
`
const DELETE_LOCATION_FULFILMENT_TYPE_PRICINGS = gql`
  mutation deleteLocationFulfilmentTypePricing($pricingIds: [uuid!]) {
    delete_pricings(where: { id: { _in: $pricingIds } }) {
      affected_rows
    }
  }
`

const GET_PRODUCT_BASIC_INFO_BY_STORE_VARIANT_ID = gql`
  query getProductBasicInfoByStoreVariant($storeVariantId: uuid!) {
    store_variants_by_pk(id: $storeVariantId) {
      product_variant {
        product {
          name
        }
        options
      }
    }
  }
`

export {
  GET_STORE_VARIANT_PRODUCT_PRICINGS,
  UPSERT_STORE_VARIANT_CORE_PRICING,
  GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_SAMEDAY_INVENTORY_BY_PRODUCT_NAME,
  GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_PREORDER_INVENTORY_BY_PRODUCT_NAME,
  UNPUBLISH_LOCATION_FULFILMENT_TYPE_PRICINGS,
  DELETE_LOCATION_FULFILMENT_TYPE_PRICINGS,
  GET_PRODUCT_BASIC_INFO_BY_STORE_VARIANT_ID
}
