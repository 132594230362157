import React, { useEffect, useState } from 'react'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { uuid } from 'uuidv4'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Card from 'antd/lib/card'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import EditCategoryForm from './Form/EditCategory'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import { MerchantSettings } from '@slerp/controls'
import {
  GET_MERCHANT_SETTINGS,
  GET_MERCHANT_CATEGORIES,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  TOGGLE_CATEGORY_ARCHIVE_STATE,
  TOGGLE_PRODUCT_ARCHIVE_STATE,
  buildProductsCategoriesByCategoryIds
} from '../ProductQueries'
import Loading from 'components/Utils/Loading'
import CategoryForm from './Form'
import { toggleCategoryArchiveState } from '../Actions'
import '../Products.css'
import { filterProductsWithSingleCategory } from './utils'
import ErrorMessage from 'components/Utils/ErrorMessage'
export interface Category {
  id: string
  name: string
  slug: string
  description: string
}

export interface ProductCategoryDefaultVariant {
  category_id: string
  product: { id: string; default_variant_id: string }
}

const CategoriesList = () => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [editingCategory, setEditingCategory] = useState<string>('')
  const [categoryUpdateInProgress, setCategoryUpdateInProgress] =
    useState<boolean>(false)
  const [showCategoryForm, setShowCategoryForm] = useState<boolean>(false)
  const [categoryIds, setCategoryIds] = useState<string[]>([])
  const [categoryIdsAsParams, setCategoryIdsAsParams] =
    useState<Record<'category_id', { _eq: string }>[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    data: settings,
    loading: isLoadingMerchantSettings,
    error: errorMerchantSettings
  } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: { merchantSlug: merchant.slug },
    onError: (error) =>
      message.error(`Unable to merchant settings due to: ${error.message}`, 5)
  })

  const {
    data: merchantCategories,
    loading: isLoadingMerchantCategories,
    refetch: refetchMerchantCategories,
    error: errorMerchantCategories
  } = useQuery<{ categories: Category[] }>(GET_MERCHANT_CATEGORIES, {
    variables: { merchantId: merchant.id },
    onCompleted: (data) => {
      if (!data) return
      const { categories } = data

      const idsParams = categories?.map((category: Category) => ({
        category_id: { _eq: category.id }
      }))

      const ids = categories?.map((category: Category) => category.id)

      setCategoryIds(ids)
      setCategoryIdsAsParams(idsParams)
    },
    onError: (error) =>
      message.error(`Unable to load categories due to: ${error.message}`, 5),
    notifyOnNetworkStatusChange: true
  })

  const query = buildProductsCategoriesByCategoryIds(true)

  const {
    data: productsCategoriesData,
    loading: isLoadingProductsCategories,
    error: errorProductsCategories
  } = useQuery<{
    products_categories: ProductCategoryDefaultVariant[]
  }>(query, {
    variables: {
      categoryIds: categoryIdsAsParams
    },
    skip: isLoadingMerchantCategories || !merchantCategories,
    notifyOnNetworkStatusChange: true,
    onError: (error) =>
      message.error(
        `Unable to load products_categories due to: ${error.message}`,
        5
      )
  })

  const { categories } = merchantCategories || {}
  const { products_categories } = productsCategoriesData || {}

  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )
  const [toggleCategoryArchiveStates] = useMutation(
    TOGGLE_CATEGORY_ARCHIVE_STATE
  )
  const [toggleProductArchiveState] = useMutation(TOGGLE_PRODUCT_ARCHIVE_STATE)

  const archiveCategory = (
    category: Category,
    productsOnCurrentCategory: ProductCategoryDefaultVariant[]
  ) => {
    const { merchants } = settings
    const { setting: merchantSettings }: { setting: MerchantSettings } =
      merchants[0]
    const merchantSlug = merchant.slug

    if (!categories)
      return message.error(
        'Unable to archive category due to empty categories data',
        5
      )
    if (!products_categories)
      return message.error(
        'Unable to archive category due to empty products_categories data',
        5
      )

    const filterProductsCategoriesWithCurrentProducts =
      products_categories.filter((pc) =>
        productsOnCurrentCategory.some(
          (ppc) => ppc.product.id === pc.product.id
        )
      )

    const affectedProducts = filterProductsWithSingleCategory(
      filterProductsCategoriesWithCurrentProducts
    )

    setCategoryUpdateInProgress(true)
    toggleCategoryArchiveState({
      categoryId: category.id,
      shouldArchive: true,
      merchantSlug,
      merchantSettings,
      toggleArchiveState: toggleCategoryArchiveStates,
      toggleProductArchiveState,
      updateMerchantArrangementSettings,
      affectedProducts
    })
      .then(() => client.resetStore())
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to archive category ${category.name} due to: ${error.message}`
        )
      })
  }

  const Category = ({ categoryId }: { categoryId: string }) => {
    if (!categories) return <></>
    if (!products_categories) return <></>

    const productsOnCurrentCategory = products_categories.filter(
      (pc) => pc.category_id === categoryId
    )

    const categoryIndex =
      categories.map((cat: Category) => cat.id).indexOf(categoryId) ?? 0
    const currentCategory = categories[categoryIndex] || {}
    const isEditing = editingCategory === currentCategory.id

    return (
      <Card
        data-testid='draggable-column-item'
        className='width-100'
        bodyStyle={{
          padding: 8
        }}
      >
        <Row align='middle' className='-category-list'>
          <Col span={23}>
            <Row align='middle' className='-category-list'>
              <Col
                span={18}
                className='_pl-12'
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '0.875rem'
                  }}
                >
                  {currentCategory.name}
                </span>
                <span
                  style={{
                    marginLeft: 8,
                    fontSize: '0.75rem',
                    color: '#77778E'
                  }}
                >
                  {currentCategory.description}
                </span>
              </Col>
              <Col span={6}>
                <Row
                  gutter={[8, 8]}
                  align='middle'
                  justify='end'
                  className='-category-list'
                >
                  <Col>{`${productsOnCurrentCategory.length} ${
                    productsOnCurrentCategory.length > 1
                      ? 'products'
                      : 'product'
                  }`}</Col>
                  <Col>
                    <Tooltip placement='top' title='Edit category'>
                      <Button
                        data-testid={`edit-category-${currentCategory?.name
                          ?.replace(/\s+/g, '-')
                          .toLowerCase()}`}
                        disabled={categoryUpdateInProgress}
                        loading={categoryUpdateInProgress}
                        size='small'
                        icon={<EditOutlined />}
                        onClick={() => setEditingCategory(currentCategory.id)}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip placement='top' title='Archive category'>
                      <Button
                        data-testid={`archive-category-${currentCategory?.name
                          ?.replace(/\s+/g, '-')
                          .toLowerCase()}`}
                        disabled={categoryUpdateInProgress}
                        loading={categoryUpdateInProgress}
                        size='small'
                        icon={<DeleteOutlined />}
                        onClick={() =>
                          archiveCategory(
                            currentCategory,
                            productsOnCurrentCategory
                          )
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {isEditing && (
          <>
            <Divider className='_mt-4 _mb-0' />
            <Row>
              <Col className='_pt-16 _pr-16 _pl-16' span={24}>
                {
                  <EditCategoryForm
                    category={currentCategory}
                    cancelHandler={() => setEditingCategory('')}
                  />
                }
              </Col>
            </Row>
          </>
        )}
      </Card>
    )
  }

  useEffect(() => {
    setIsLoading(
      isLoadingMerchantSettings ||
        isLoadingMerchantCategories ||
        isLoadingProductsCategories
    )
  }, [
    isLoadingMerchantSettings,
    isLoadingMerchantCategories,
    isLoadingProductsCategories
  ])

  if (isLoading) return <Loading />
  if (errorProductsCategories)
    return (
      <ErrorMessage
        title='Products categories error'
        subheading={errorProductsCategories?.message}
      />
    )
  if (errorMerchantCategories)
    return (
      <ErrorMessage
        title='Merchant categories error'
        subheading={errorMerchantCategories?.message}
      />
    )
  if (errorMerchantSettings)
    return (
      <ErrorMessage
        title='Merchant settings error'
        subheading={errorMerchantSettings?.message}
      />
    )

  return (
    <>
      <div className='categories-container _mb-32'>
        {categoryIds.map((id: string) => (
          <Category key={id} categoryId={id}></Category>
        ))}
      </div>

      <Row gutter={[8, 8]} align='middle'>
        <Col span={24}>
          <Row justify='end' align='middle'>
            <Col>
              {!showCategoryForm && (
                <Button
                  type='primary'
                  onClick={() => {
                    setShowCategoryForm(true)
                  }}
                  className='_mr-8'
                >
                  Add category
                </Button>
              )}
              <Modal
                visible={showCategoryForm}
                title='Add a new category'
                forceRender={true}
                destroyOnClose={true}
                closable={false}
                footer={null}
                onCancel={() => setShowCategoryForm(false)}
              >
                <CategoryForm
                  key={uuid()}
                  merchantCategories={merchantCategories?.categories!}
                  onCancel={() => setShowCategoryForm(false)}
                  onStartSave={() => {
                    message.destroy()
                    message.loading(`Creating category... Please Wait.`, 2)
                  }}
                  onEndSave={() => {
                    message.destroy()
                    message.success(`Category created!`, 3)
                    client.resetStore()
                    refetchMerchantCategories()
                  }}
                />
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default CategoriesList
