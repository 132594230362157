import React, { ReactNode } from 'react'
import moment from 'moment-timezone'
import compact from 'lodash/compact'
import { Col, Collapse, Row, Tag, Typography, Table } from 'antd'

const { Column } = Table
interface PaymentInformationProps {
  order: OrderInfo
  reloadOrder: () => void
}

interface DetailProps {
  type?: string
  content: string | ReactNode
  label: string
}

const { Panel } = Collapse
const { Text } = Typography

const PaymentInformation = ({
  order,
  reloadOrder
}: PaymentInformationProps) => {
  const { payment } = order
  const { data: paymentDetail = {} } = payment || {}
  const PaymentHeader = () => {
    return (
      <>
        <div className='_inline-grid'>
          <Row className='_mb-0'>
            <Col>
              <Text strong>Payee name</Text>
            </Col>
          </Row>
          <Row className='_mb-0'>
            <Col className='_cl-text-secondary-75'>{paymentDetail.id}</Col>
          </Row>
        </div>
      </>
    )
  }

  const PaymentState = () => {
    const { paid, amount } = paymentDetail
    return (
      <>
        <Row className='_mb-0' align='middle' gutter={[8]}>
          <Col>
            <span className='_cl-text-secondary-75'>Amount: £</span>
            {amount && amount / 100}
          </Col>
          <Col>
            <Tag color={paid ? 'green' : 'orange'}>
              {paid ? 'Paid' : 'Unpaid'}
            </Tag>
          </Col>
        </Row>
      </>
    )
  }

  const Detail = ({ label, content }: DetailProps) => (
    <div className='_mb-24'>
      <Row className='_mb-2'>
        <Col span={12} className='_cl-text-secondary-75'>
          {`${label}:`}
        </Col>
      </Row>
      <Row className='_mb-2' align='middle'>
        <Col span={12}>{content}</Col>
      </Row>
    </div>
  )

  const paymentCaptured = () => {
    const { authorized, reason, seller_message } = paymentDetail?.outcome || {}
    return compact([authorized, reason, seller_message]).join(' ')
  }

  const REFUND_DETAIL_COLUMNS = [
    {
      title: 'Refunded Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => {
        return <span>{amount ? `£${amount.toFixed(2)}` : 'N/A'}</span>
      }
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (reason: string) => {
        return <span>{reason || 'N/A'}</span>
      },
      width: '40%'
    },
    {
      title: 'Date',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (created: number) => {
        return <span>{moment(created).format('DD/MM/YY, hh:mm')}</span>
      }
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user.firstname',
      render: (user: { firstname: string; lastname: string }) => {
        return (
          <span>
            {user.firstname} {user.lastname}
          </span>
        )
      }
    }
  ]

  return (
    <Collapse className='-plain'>
      <Panel header={<PaymentHeader />} extra={<PaymentState />}>
        {paymentDetail.description && (
          <Detail label='Payment for' content={paymentDetail.description} />
        )}
        <Detail
          label='Fees'
          content={`£${
            paymentDetail.balance_transaction
              ? paymentDetail.balance_transaction.fee / 100
              : 0
          }`}
        />
        <Detail
          label='Paid At'
          content={moment
            .unix(paymentDetail.created)
            .format('ddd MMM DD YYYY, h:mm:ss a')}
        />
        {paymentDetail.source && (
          <Detail
            label='Paid By'
            content={`${paymentDetail.source.brand} ${paymentDetail.source.last4}`}
          />
        )}
        <Detail label='Captured' content={paymentCaptured()} />
        <Detail
          label='Fully Refunded'
          content={<Tag>{paymentDetail.refunded ? 'Yes' : 'No'}</Tag>}
        />
        <Table
          dataSource={order.refunds}
          data-testid='refund-details-table'
          columns={REFUND_DETAIL_COLUMNS}
        />
      </Panel>
    </Collapse>
  )
}

export default PaymentInformation
