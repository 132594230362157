import styled from '@emotion/styled'
import React from 'react'
import { ConfigureSidebar, StyleSidebar } from './Sidebar'
import { Canvas } from './Canvas'
import { SaveAttempt } from './SaveAttempt'
import { ViewportSettings } from './ViewportSettings'
import { HeadingToolbar } from './HeadingToolbar'
import { useApp } from '../contexts/App'
import { Page } from './types'
import Messages from './Messages'

const Main = () => {
  const { page } = useApp()

  return (
    <Container page={page}>
      {page === 'web' && (
        <>
          <StyleSidebar />
          <Canvas />
          <SaveAttempt />
          <ConfigureSidebar />
          <CanvasToolbar>
            <ToolbarContainer>
              <ViewportSettings />
            </ToolbarContainer>
            <ToolbarContainer>
              <HeadingToolbar />
            </ToolbarContainer>
          </CanvasToolbar>
        </>
      )}
      {page === 'messages' && <Messages />}
    </Container>
  )
}

const Container = styled.div<{ page: Page }>`
  position: relative;

  display: flex;
  flex-flow: ${({ page }) => (page === 'messages' ? 'column' : 'row')};
  width: 100%;
  height: 100%;

  @keyframes openContent {
    from {
      width: 20px;
    }

    to {
      width: 25vw;
    }
  }

  @keyframes closeContent {
    from {
      width: 25vw;
    }

    to {
      width: 20px;
    }
  }

  @keyframes openOpacity {
    0% {
      opacity: 0;
    }

    40% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes closeOpacity {
    0% {
      opacity: 1;
    }

    40% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes openStyleLabel {
    from {
      right: 0;
    }

    to {
      right: -12px;
    }
  }

  @keyframes closeStyleLabel {
    from {
      right: -12px;
    }

    to {
      right: 0;
    }
  }

  @keyframes openConfigureLabel {
    from {
      left: 4px;
    }

    to {
      left: -12px;
    }
  }

  @keyframes closeConfigureLabel {
    from {
      left: -12px;
    }

    to {
      left: 4px;
    }
  }

  @keyframes activeToolbarLabel {
    from {
      color: #dbd9df;
    }

    to {
      color: #8c8ca1;
    }
  }

  @keyframes inactiveToolbarLabel {
    from {
      color: #8c8ca1;
    }

    to {
      color: #dbd9df;
    }
  }
`

const CanvasToolbar = styled.div`
  position: fixed;
  bottom: 7.5vh;
  left: 50%;
  z-index: 3000;

  display: flex;
  gap: 8px;

  transform: translateX(-50%);
`

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  border-radius: 4px;
  border: 1px solid ${({ theme }: any) => theme.colors.background50};
  background: ${({ theme }: any) => theme.colors.background25};
`

export default Main
