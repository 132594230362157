import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { QUERY_STAMP_LOGS } from './../Loyalty/LoyaltyQueries'
import { message } from 'antd'
import { Key } from 'antd/lib/table/interface'
import { isEmpty } from 'lodash'
import FailedEmailsStampModal from '../Loyalty/FailedEmailsStampModal'
import { useSlerp } from 'packages/@slerp/client'

export const StampLogs = () => {
  const { user } = useSlerp()
  const { merchant } = user
  const { id: merchant_id } = merchant
  const exclusive = localStorage.getItem('exclusive')
  const [showFailedModal, setShowFailedModal] = useState<boolean>(false)
  const [getStampLogs, { data, startPolling, stopPolling }] = useLazyQuery(
    QUERY_STAMP_LOGS,
    {
      onCompleted: (data) => {
        const { id } = data?.stamp_logs[0] || {}
        if (!id) return
        startPollingHandler(id)
      },
      onError: (error) =>
        message.error(`Unable to get stamp logs due to: ${error}`, 5),
      fetchPolicy: 'no-cache'
    }
  )

  const { customer_count, finished_at, id, failed_email_stamps } =
    data?.stamp_logs[0] || {}

  const startPollingHandler = (log_id: Key) => {
    const content = exclusive
      ? 'Your requested loyalty card changes are in progress'
      : 'Stamping in progress'
    startPolling && startPolling(2500)
    message.loading({
      key: log_id,
      duration: 0,
      content
    })
  }

  const stopPollingHandler = async (log_id: Key) => {
    setTimeout(() => {
      message.destroy(log_id)
    }, 1)
    const content = exclusive
      ? 'Loyalty card updated'
      : `Successfully added stamps to ${customer_count} ${
          customer_count > 1 ? 'customers' : 'customer'
        }`
    message.success(content, 5)
    localStorage.removeItem('stamp_log_id')
    localStorage.removeItem('exclusive')

    if (!isEmpty(failed_email_stamps)) {
      setShowFailedModal(true)
    }
    stopPolling && stopPolling()
  }

  useEffect(() => {
    if (!id) return
    if (!finished_at) return

    stopPollingHandler(id as Key)
  }, [finished_at, id])

  useEffect(() => {
    const stamp_log_id = localStorage.getItem('stamp_log_id')
    // Remove null and undefined filter after a month or so
    if (
      Boolean(stamp_log_id) &&
      stamp_log_id !== 'null' &&
      stamp_log_id !== 'undefined'
    ) {
      getStampLogs({
        variables: {
          stampId: stamp_log_id,
          merchantId: merchant_id
        }
      })
    }
  }, [])

  return (
    <>
      {showFailedModal && (
        <FailedEmailsStampModal
          emails={failed_email_stamps}
          visible={showFailedModal}
          onClose={() => setShowFailedModal(false)}
        />
      )}
    </>
  )
}
