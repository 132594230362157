import React from 'react'
import { Modal, Button, message } from 'antd'
import { MUTATION_REMOVE_FRANCHISE_ATTRIBUTION } from '../SettingsMutations'
import { useMutation } from '@apollo/client'

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (id: string) => void
  store: null | {
    id: string
    name: String
  }
}

export const DeleteAttribution = ({
  visible,
  onClose,
  onSuccess,
  store
}: Props) => {
  const handleClose = () => {
    onClose()
  }

  const [updateAttributions] = useMutation(
    MUTATION_REMOVE_FRANCHISE_ATTRIBUTION,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        handleClose()
        onSuccess(store?.id || '')

        message.destroy()
        message.success('Attributions removed!')
      },
      onError: () => {
        message.destroy()
        message.error('Something went wrong!')
      }
    }
  )

  if (!store) {
    return <></>
  }

  const handleDelete = () => {
    updateAttributions({ variables: { storeId: store.id } })
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={handleClose}
      title='Delete Attribution'
      footer={[
        <Button key='back' type='ghost' onClick={handleClose}>
          No
        </Button>,
        <Button
          key='submit'
          type='primary'
          data-testid='deleteAttribution'
          onClick={handleDelete}
        >
          Yes
        </Button>
      ]}
    >
      <p>
        Are you sure you want to delete attribution settings for {store.name}?
      </p>
    </Modal>
  )
}
