import React from 'react'
import moment from 'moment-timezone'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { uuid } from 'uuidv4'
import { HourPicker } from 'components/Widgets'

interface DayShiftRangeProps {
  classNames: string
  disabled: boolean
  startTime: string
  secondsLength: number
  index: number
  updateShiftStart: () => void
  updateShiftDuration: () => void
  removeShift: () => void
}

const TIME_FORMAT = 'HH:mm:ss'

const DayShiftRange = ({
  classNames,
  disabled,
  index,
  secondsLength,
  startTime,
  updateShiftStart,
  updateShiftDuration,
  removeShift
}: DayShiftRangeProps) => {
  return (
    <Row key={uuid()} gutter={[8, 0]}>
      <Col span={6}>
        <HourPicker
          classNames={classNames}
          dataTestId='split-hours-day-row-start'
          disabled={disabled}
          value={startTime}
          onChange={(value: string) => updateShiftStart(value, index)}
        />
      </Col>
      <Col span={6}>
        <HourPicker
          classNames={classNames}
          dataTestId='split-hours-day-row-end'
          disabled={disabled}
          value={moment(startTime, TIME_FORMAT)
            .add(secondsLength, 's')
            .format(TIME_FORMAT)}
          onChange={(value: string) =>
            updateShiftDuration(value, startTime, index)
          }
        />
      </Col>
      <Col>
        <Button
          icon={<CloseCircleOutlined />}
          disabled={disabled}
          type='link'
          data-testid='split-hours-day-row-remove'
          className='-primary -plain'
          onClick={() => removeShift(index)}
        />
      </Col>
    </Row>
  )
}

export default DayShiftRange
