import React from 'react'
import { Result, Spin } from 'antd'

interface BusyProps {
  status?: 'success' | 'error' | 'info' | 'warning'
  title?: string
  subtitle?: string
}

const Busy = ({ status, title, subtitle }: BusyProps) => (
  <Result
    status={status || 'info'}
    title={title || 'Please wait while we load your data'}
    subTitle={subtitle || 'This may take a while'}
    className='_w-100'
    style={{ width: '100%' }}
    icon={<Spin size='large' />}
  />
)

export default Busy
