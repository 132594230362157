import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { MUTATION_CONNECT_AND_UPDATE_STRIPE_SETTINGS } from './LayoutMutations'
import { StripeConnectRoute } from 'components/Utils/Stripe'

interface Props {
  path: string
}

const StripeRedirect = ({ path }: Props) => {
  const storeJson = localStorage.getItem('redirect-store')
  const redirectStore = storeJson ? JSON.parse(storeJson) : null

  return redirectStore ? (
    <StripeConnectRoute
      path={path}
      redirectUrl={`/location/${redirectStore.slug}/settings/connect-redirect`}
      connectVariables={{
        storeId: redirectStore.id
      }}
      connectMutation={MUTATION_CONNECT_AND_UPDATE_STRIPE_SETTINGS}
    />
  ) : (
    <Route
      path='/locations/stripe/connect'
      render={() => <Redirect to='/locations' />}
    />
  )
}

export default StripeRedirect
