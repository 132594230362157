import React, { useState, ReactNode } from 'react'
import { Checkbox, Divider, Input, Select } from 'antd'
import { uniq } from 'lodash'
import { useQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { GET_MERCHANT_ORDER_STORES } from 'components/Locations/Dashboard/DashboardQueries'
import { Store } from '@slerp/controls'

interface Props {
  value?: string[]
  onChange?: (values: string[]) => void
  disabled?: boolean
  userType: 'storeUser' | 'storeManager'
}

interface CheckboxOptionProps {
  children: ReactNode
  checked: boolean
  checkboxEnabled: boolean
}

const LocationsSelect = React.forwardRef(
  ({ value = [], onChange, disabled = false, userType }: Props, ref: any) => {
    const ALL_STORES_OPTION_VAL = 'all-stores-select-option-value'
    const { merchant, user } = useSession()
    const [searchValue, setSearchValue] = useState<string>('')
    const [areAllStoresSelected, setAreAllStoresSelected] =
      useState<boolean>(false)
    const isStoreManagerMode = userType === 'storeManager'

    const { data, loading } = useQuery(GET_MERCHANT_ORDER_STORES, {
      variables: {
        merchantId: merchant.id,
        userEmail: user.email
      }
    })
    const stores: Store[] = data?.stores || []

    const handleOnChange = (val: string[]) => {
      setAreAllStoresSelected(val.length >= stores.length)
      onChange && onChange(val)
    }

    const searchFilter = (store: Store) => {
      return `${store.name} ${store.address.zip}`
        .toLowerCase()
        .includes(searchValue)
    }

    // decorator, we show checkboxes for only when on storeManagerMode
    const SelectOption = ({
      children,
      checked,
      checkboxEnabled
    }: CheckboxOptionProps) => {
      return checkboxEnabled ? (
        <Checkbox checked={checked}>{children}</Checkbox>
      ) : (
        children
      )
    }

    return (
      <div ref={ref}>
        <Select
          loading={loading}
          value={areAllStoresSelected ? ALL_STORES_OPTION_VAL : value}
          mode={isStoreManagerMode ? 'multiple' : undefined}
          maxTagCount={5}
          optionLabelProp='label'
          showArrow={true}
          showSearch={false}
          allowClear={!areAllStoresSelected}
          disabled={disabled}
          onClear={() => handleOnChange([])}
          onSelect={(val: string) => {
            if (val === ALL_STORES_OPTION_VAL) {
              return handleOnChange(
                areAllStoresSelected ? [] : stores.map((store) => store.id)
              )
            }

            if (areAllStoresSelected) {
              return handleOnChange(
                stores.map((store) => store.id).filter((id) => id !== val)
              )
            }
            // only the storemanager can have multiple stores accessible
            if (isStoreManagerMode) {
              handleOnChange(uniq([...value, val]))
            } else {
              handleOnChange([val])
            }
          }}
          onDeselect={(val: string) => {
            handleOnChange(value.filter((id) => id !== val))
          }}
          dropdownRender={(menu) => (
            <>
              <Input.Search
                placeholder='Search'
                style={{ padding: '12px 12px 0' }}
                onChange={(e: any) => setSearchValue(e.target.value)}
              />
              <Divider style={{ margin: '14px 0' }} />
              {menu}
            </>
          )}
        >
          {isStoreManagerMode && (
            <Select.Option label='All locations' value={ALL_STORES_OPTION_VAL}>
              <Checkbox checked={areAllStoresSelected}>All locations</Checkbox>
            </Select.Option>
          )}
          {stores.filter(searchFilter).map((store: Store) => {
            return (
              <Select.Option
                label={`${store.name} ${store.address.zip}`}
                value={store.id}
              >
                <SelectOption
                  checked={value.includes(store.id)}
                  checkboxEnabled={isStoreManagerMode}
                >
                  <div className='order-store-info'>
                    <span className='name'>{store.name}</span>{' '}
                    <span className='zip'>{store.address.zip}</span>
                  </div>
                </SelectOption>
              </Select.Option>
            )
          })}
        </Select>
      </div>
    )
  }
)

export default LocationsSelect
