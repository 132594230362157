import { CommissionRate } from './types'

export const DEFAULT_COMMISSION_RATES: CommissionRate = {
  delivery: null,
  same_day_self_delivery: null,
  preorder_delivery: null,
  preorder_self_delivery: null,
  nationwide_delivery: null,
  pickup: null,
  preorder_pickup: null,
  table: null
}
