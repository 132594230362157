import React, { useState } from 'react'
import Form from './Form'
import { Category, StoreInfo, StoreSpecialAvalability } from '../Inventories'
import { Row, Col, Button, Typography, message } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { DELETE_SPECIAL_AVAILABILITY_FOR_CATEGORY } from '../DashboardQueries'
import { useMutation, useApolloClient } from '@apollo/client'
import moment from 'moment-timezone'
import { RRule } from 'rrule'

const { Text } = Typography

interface Props {
  categories: Category[]
  currentCategory: Category
  storeId: string
  stores: StoreInfo[]
  hasExistingSA?: boolean
  isPreorder: boolean | undefined
}

interface BarRRuleProps extends Props {
  special_availability: SpecialAvailability
}

interface SpecialAvailability {
  id: string
  end_time: string
  end_datetime: string
  rrule: string
  order_type: string
  store_special_availabilities: StoreSpecialAvalability[]
}

const ORDER_TYPE: { [key: string]: string } = {
  sameday: 'SAMEDAY',
  preorder: 'PRE-ORDER',
  all: 'ALL'
}

const WEEKDAYS_ABBREVIATION: string[] = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN'
]
const WEEKDAYS: string[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]

const Bar = (props: Props) => {
  const { currentCategory } = props

  return (
    <>
      {currentCategory.category_special_availabilities.map(
        (special_availability) => (
          <BarRRule
            key={special_availability.special_availability.id}
            {...props}
            special_availability={special_availability.special_availability}
          />
        )
      )}
    </>
  )
}

const BarRRule = (props: BarRRuleProps) => {
  const {
    categories,
    currentCategory,
    storeId,
    stores,
    isPreorder,
    special_availability,
    hasExistingSA
  } = props
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const client = useApolloClient()

  const ruleFromString = RRule.fromString(special_availability.rrule)
  const { dtstart, until, byweekday, byhour, byminute } = ruleFromString.options

  const isAppliedToBothInventories = () => {
    if (special_availability.order_type === 'all') return true
    if (isPreorder && special_availability.order_type === 'sameday') return true
    if (!isPreorder && special_availability.order_type === 'preorder')
      return true

    return false
  }

  const store_ids = special_availability.store_special_availabilities.map(
    ({ store }) => store.id
  )

  const initialValues = {
    category_name: currentCategory.name,
    category_id: currentCategory.id,
    special_availability_id: special_availability.id,
    by_day: byweekday.map((bwd) => WEEKDAYS[bwd]),
    applied_to_both_inventories: isAppliedToBothInventories(),
    apply_to_multiple_locations: !!store_ids.length,
    start_date: moment(dtstart),
    end_date: until ? moment(until) : null,
    start_time: moment(`${byhour}:${byminute}`, 'HH:mm'),
    end_time: moment(special_availability.end_time, 'HH:mm'),
    store_ids
  }

  const [deleteSpecialAvailability] = useMutation(
    DELETE_SPECIAL_AVAILABILITY_FOR_CATEGORY,
    { fetchPolicy: 'no-cache' }
  )

  const handleDelete = () => {
    message.loading('Deleting... Please wait.')
    deleteSpecialAvailability({
      variables: {
        specialAvailabilityId: special_availability.id
      }
    })
      .finally(() => {
        message.destroy()
        client.resetStore()
        message.success('Successfully deleted special availability', 1)
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Unable to delete special availability. Due to ${error.message}`,
          3
        )
      })
  }

  const includedStoreIds =
    special_availability.store_special_availabilities.map((s) => s.store.id)
  if (!includedStoreIds.includes(storeId)) return <></>
  if (isPreorder && special_availability.order_type === 'sameday') return <></>
  if (!isPreorder && special_availability.order_type === 'preorder')
    return <></>

  return showEditForm ? (
    <Form
      categories={categories}
      storeId={storeId}
      stores={stores}
      isPreorder={isPreorder}
      isEdit={true}
      initialValues={initialValues}
      hasExistingSA={hasExistingSA}
      onClose={() => setShowEditForm(false)}
    />
  ) : (
    <Row>
      <Col className='container' span={24}>
        <Row justify='space-between' className='_mb-0'>
          <Col span={22}>
            <Row>
              <Col className='category-name'>
                <Text strong>{currentCategory.name}</Text>
              </Col>
            </Row>
            <Row className='_mb-0' gutter={[8, 0]}>
              <Col span={5}>
                <Row className='_mb-0'>
                  <Text className='label'>START DATE:</Text>
                </Row>
                <Row className='_mb-0'>
                  <Text strong>{moment(dtstart).format('DD/MM/YYYY')}</Text>
                </Row>
              </Col>
              <Col span={10}>
                <Row className='_mb-0'>
                  <Text className='label'>REPEAT EVERY WEEK:</Text>
                </Row>
                <Row className='_mb-0'>
                  <Text strong>
                    {byweekday.map((v) => WEEKDAYS_ABBREVIATION[v]).join(', ')}
                  </Text>
                </Row>
                <Row className='_mb-0'>
                  <Text className='label'>SCHEDULED FOR:</Text>
                </Row>
                <Row className='_mb-0'>
                  <Text strong>
                    {special_availability.end_time
                      ? `${moment(initialValues.start_time).format(
                          'HH:mm'
                        )}-${moment(
                          special_availability.end_time,
                          'HH:mm'
                        ).format('HH:mm')}`
                      : moment(initialValues.start_time).format('HH:mm')}
                  </Text>
                </Row>
              </Col>
              <Col span={5}>
                <Row className='_mb-0'>
                  <Text className='label'>END DATE:</Text>
                </Row>
                <Row className='_mb-0'>
                  <Text strong>
                    {!!until ? moment(until).format('DD/MM/YYYY') : '-'}
                  </Text>
                </Row>
              </Col>
              <Col span={4}>
                <Row className='_mb-0'>
                  <Text className='label'>APPLIED TO:</Text>
                </Row>
                <Row className='_mb-0'>
                  {special_availability.order_type === 'all' ? (
                    <Col>
                      <Row className='_mb-0'>
                        <Text strong>{ORDER_TYPE.sameday}</Text>
                      </Row>
                      <Row className='_mb-0'>
                        <Text strong>{ORDER_TYPE.preorder}</Text>
                      </Row>
                    </Col>
                  ) : (
                    <Text strong>
                      {ORDER_TYPE[special_availability.order_type]}
                    </Text>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className='_py-6'>
            <Row>
              <Button
                className='_py-5 _px-10'
                onClick={() => setShowEditForm(true)}
              >
                <EditOutlined size={25} />
              </Button>
            </Row>
            <Row className='_mb-0'>
              <Button className='_py-5 _px-10' onClick={handleDelete}>
                <DeleteOutlined size={24} />
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Bar
