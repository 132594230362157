import React from 'react'
import { Form, Input, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

type Props = {
  inputName: string
  defaultValue: number
  disabled: boolean
  testId: string
}

const MinimumOrderValueInput = ({
  inputName,
  defaultValue,
  disabled,
  testId
}: Props) => {
  return (
    <Form.Item
      name={inputName}
      label={
        <>
          Minimum order value
          <Tooltip
            placement='right'
            title={
              <>
                Set a minimum amount your customers must spend before checking
                out. Default to 0 if you don't require a limit.
              </>
            }
            className='_ml-8'
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      }
    >
      <Input
        type='number'
        step='0.01'
        min='0.0'
        addonBefore='£'
        style={{ width: '160px' }}
        defaultValue={defaultValue}
        disabled={disabled}
        data-testid={testId}
        required
        onFocus={onFocusScrollNumber}
        onBlur={onBlurScrollNumber}
      />
    </Form.Item>
  )
}

export default MinimumOrderValueInput
