import React from 'react'
import { uuid } from 'uuidv4'
import moment from 'moment'
import { Button, Col, Row, Switch } from 'antd'
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { HourPicker } from 'components/Widgets'
import { SplitHour } from '../types'
import { GetShiftLengthInSeconds, ValidateShift } from '../utils'

interface SpecialDayProps {
  dateStamp: string
  shifts: SplitHour[]
  disabled: boolean
  updateSpecialDayShifts: () => void
  removeDatedShifts: (dateStamp: string) => void
}

const TIME_FORMAT = 'HH:mm:ss'
const DAY_NAME_FORMAT = 'dddd'
const DEFAULT_SHIFT_SHAPE = {
  day_of_week: 'monday',
  start_time: '08:00:00',
  seconds_length: 3600,
  enabled: true,
  special_date: null,
  closed: false
}

const SpecialDay = ({
  dateStamp,
  shifts,
  disabled,
  updateSpecialDayShifts,
  removeDatedShifts
}: SpecialDayProps) => {
  const dayShifts = shifts
  const dayOfWeek = moment(dateStamp).format(DAY_NAME_FORMAT).toLowerCase()

  const addNewShift = () => {
    const newDayShifts = [
      ...dayShifts,
      {
        ...DEFAULT_SHIFT_SHAPE,
        day_of_week: dayOfWeek,
        special_date: dateStamp
      }
    ]
    updateSpecialDayShifts(dateStamp, newDayShifts)
  }

  const updateShiftStart = (startTime: string, index: number) => {
    const newDayShifts = [...dayShifts]
    newDayShifts[index].start_time = startTime ? `${startTime}:00` : '08:00:00'
    updateSpecialDayShifts(dateStamp, newDayShifts)
  }

  const updateShiftDuration = (
    endTime: string,
    startTime: string,
    index: number
  ) => {
    const shiftLength = GetShiftLengthInSeconds(startTime, endTime)
    const newDayShifts = [...dayShifts]

    if (newDayShifts[index]) {
      newDayShifts[index].seconds_length = shiftLength
      updateSpecialDayShifts(dateStamp, newDayShifts)
    }
  }

  const removeShift = (index: number) => {
    const newDayShifts = [...dayShifts]
    if (newDayShifts[index]) {
      newDayShifts.splice(index, 1)
      updateSpecialDayShifts(dateStamp, newDayShifts)
    }
  }

  const toggleShiftClosure = (value: boolean) => {
    const newDayShifts = [...dayShifts].map((shift: SplitHour) => {
      shift.closed = !value
      return shift
    })
    updateSpecialDayShifts(dateStamp, newDayShifts)
  }

  const openForBusiness = dayShifts.find(({ closed }: SplitHour) => !closed)

  return (
    <Row className='_mb-32' align='top' data-testid='split-hours-day-row'>
      <Col span={3}>
        <Row
          align='middle'
          className='_mb-0'
          data-testid='split-hours-day-row-day-name'
        >
          {dateStamp}
        </Row>
      </Col>
      <Col span={2}>
        <Switch
          defaultChecked={openForBusiness}
          onChange={toggleShiftClosure}
          disabled={disabled}
        />
      </Col>
      {!openForBusiness && (
        <>
          <Col span={7}>
            <Row>Not operating on this day</Row>
          </Col>
          <Col>
            <Button
              icon={<CloseCircleOutlined />}
              disabled={disabled}
              type='link'
              data-testid='split-hours-day-row-remove'
              className='-primary -plain'
              onClick={() => removeDatedShifts(dateStamp)}
            />
          </Col>
        </>
      )}
      {openForBusiness && (
        <Col span={16}>
          {dayShifts.map((shift: SplitHour, index: number) => {
            const { start_time, seconds_length } = shift
            const classNames = ValidateShift(
              shift,
              dayShifts,
              {},
              dayOfWeek,
              index
            )
              ? ''
              : '-error'
            return (
              <Row key={uuid()} gutter={[8, 0]}>
                <Col span={6}>
                  <HourPicker
                    classNames={classNames}
                    dataTestId='split-hours-day-row-start'
                    disabled={disabled}
                    value={start_time}
                    onChange={(value: string) => updateShiftStart(value, index)}
                  />
                </Col>
                <Col span={6}>
                  <HourPicker
                    classNames={classNames}
                    dataTestId='split-hours-day-row-end'
                    disabled={disabled}
                    value={moment(start_time, TIME_FORMAT)
                      .add(seconds_length, 's')
                      .format(TIME_FORMAT)}
                    onChange={(value: string) =>
                      updateShiftDuration(value, start_time, index)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    icon={<CloseCircleOutlined />}
                    disabled={disabled}
                    type='link'
                    data-testid='split-hours-day-row-remove'
                    className='-primary -plain'
                    onClick={() => removeShift(index)}
                  />
                </Col>
              </Row>
            )
          })}
          <Row align='top' className='_mb-0'>
            <Col>
              <Button
                icon={<PlusCircleOutlined />}
                type='link'
                size='small'
                disabled={disabled}
                className='-primary'
                data-testid='split-hours-day-row-add'
                onClick={addNewShift}
              >
                Add shift
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  )
}

export default SpecialDay
