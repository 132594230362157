import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { SketchPicker, ColorResult } from 'react-color'
import validateColor from 'validate-color'
import { isDescendant } from './utils'

interface Props {
  value: string
  onChange: (color: string) => void
}

const ColorInput: React.FC<Props> = (props) => {
  const [internalValue, setInternalValue] = useState(props.value)
  const [isShown, setIsShown] = useState(false)
  const containerRef = useRef(null)
  const targetRef = useRef(null)

  const handleColorChange = (color: ColorResult) => {
    const alpha = Math.round(color.rgb.a * 255).toString(16)
    const alphaToHex = alpha.length === 1 ? 0 + alpha : alpha
    const hex = color.hex === 'transparent' ? color.hex : color.hex + alphaToHex

    setInternalValue(hex)
    props.onChange(hex)
  }

  const handleInputChange = (evt: any) => {
    const color = evt.target.value
    setInternalValue(color)
  }

  const handleBlur = () => {
    if (validateColor(internalValue)) {
      props.onChange(internalValue)
    } else {
      setInternalValue(props.value)
    }
  }

  // Click event
  useEffect(() => {
    const handler = (evt: any) => {
      if (!containerRef.current) return

      if (targetRef.current! && isDescendant(targetRef.current!, evt.target)) {
        return
      }

      if (isDescendant(containerRef.current!, evt.target)) {
        setIsShown(true)
      } else {
        setIsShown(false)
      }
    }

    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [containerRef, targetRef])

  // Escape key event
  useEffect(() => {
    const handler = (evt: any) => {
      if (evt.key === 'Escape') setIsShown(false)
    }

    document.addEventListener('keyup', handler)

    return () => {
      document.removeEventListener('keyup', handler)
    }
  }, [])

  return (
    <Container ref={containerRef}>
      <ColorBox color={internalValue} />
      <Input
        value={internalValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
      />
      {isShown && (
        <ColorPickerDropdown ref={targetRef}>
          <SketchPicker
            color={internalValue}
            onChangeComplete={handleColorChange}
          />
        </ColorPickerDropdown>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.input`
  padding: 8px 8px 8px 40px;
  width: 100%;
  height: ${({ theme }: any) => theme.input.height}px;
  color: ${({ theme }: any) => theme.colors.gray600};
  font-size: ${({ theme }: any) => theme.fontSizes.sm};
  font-family: ${({ theme }: any) => theme.font.sans};
  background-color: ${({ theme }: any) => theme.colors.gray100};
  border: none;
  border-radius: 4px;
`

const ColorBox = styled.button`
  position: absolute;
  top: 8px;
  left: 8px;
  height: 20px;
  width: 20px;
  background-color: ${({ color }: any) => color};
  border: none;
  border-radius: 4px;
  cursor: pointer;
`

const ColorPickerDropdown = styled.div`
  position: absolute;
  top: 48px;
  left: 0px;
  z-index: ${({ theme }: any) => theme.zIndex.dropdown};
`

export { ColorInput }
