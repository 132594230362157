import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'
import { Radio, Space } from 'antd'

type Types = 'opening_hours' | 'custom_hours'

const FulfillmentTimesType = ({
  value,
  handleChange,
  disabled = false
}: {
  value: Types
  handleChange: (value: Types) => void
  disabled?: boolean
}) => {
  return (
    <Radio.Group
      defaultValue={value}
      onChange={(e: RadioChangeEvent) => handleChange(e.target.value)}
      disabled={disabled}
    >
      <Space direction='vertical' align='start'>
        <Radio
          value={'opening_hours'}
          data-testid='fulfillment-times-opening-hours'
        >
          Opening hours
        </Radio>
        <Radio
          value={'custom_hours'}
          data-testid='fulfillment-times-custom-hours'
        >
          Custom hours
        </Radio>
      </Space>
    </Radio.Group>
  )
}

export default FulfillmentTimesType
