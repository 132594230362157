import React from 'react'
import { Radio, Space } from 'antd'
import CourierFee from './CourierFee'
import SlerpOptimizeDeliveryPricing from './SlerpOptimizeDeliveryPricing'
import { PriceMatrix } from '@slerp/controls'
import isEmpty from 'lodash/isEmpty'

type DeliveryFees = 'quoted' | 'custom' | 'rate_card' | ''

const DeliveryFee = ({
  storeSlug,
  value,
  handleChange,
  courier,
  disabled = false,
  priceMatrix
}: {
  storeSlug: string
  value: DeliveryFees
  handleChange: (value: DeliveryFees) => void
  courier: 'partner' | 'other'
  disabled?: boolean
  priceMatrix: PriceMatrix | undefined
}) => {
  return (
    <Space direction='vertical' align='start'>
      <Radio.Group
        value={value}
        className='_mt-4'
        onChange={(e) => handleChange(e.target.value)}
        disabled={disabled}
      >
        <Space direction='vertical' align='start'>
          <Radio
            value={'rate_card'}
            data-testid='rate-card'
            disabled={isEmpty(priceMatrix)}
          >
            Optimised Delivery Pricing (recommended)
          </Radio>
          <Radio
            value={'quoted'}
            data-testid='courier-fee-quoted'
            disabled={courier === 'other'}
          >
            Standard Delivery Pricing (non-optimised pricing)
          </Radio>
          <Radio
            value={'custom'}
            data-testid='courier-fee-custom'
            disabled={courier === 'partner'}
          >
            Custom fee
          </Radio>
          {value === 'custom' && <CourierFee disabled={disabled} />}
        </Space>
      </Radio.Group>
      {value === 'rate_card' && (
        <SlerpOptimizeDeliveryPricing
          priceMatrix={priceMatrix}
          storeSlug={storeSlug}
        />
      )}
    </Space>
  )
}

export default DeliveryFee
