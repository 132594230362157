import { useLazyQuery } from '@apollo/client'
import { QUERY_HUBSPOT_ACCESS_TOKEN } from 'components/Layout/LayoutQueries'
import { useEffect, useState } from 'react'
import jwt from 'jsonwebtoken'

interface User {
  id: string
  api_key: string
  email: string
  firstname: string
  lastname: string
  role: string
}

interface HubspotJWT {
  aud: string
  exp: number
  first_name: string
  iat: number
  last_name: string
  sub: string
}

interface HubspotData {
  getHubspotAccessToken: string
}

export const useHubspotChat = (user: User) => {
  // hsConversationsSettings needs to be set prior to the call, otherwise we may experience a race condition that interferes with widget load
  window.hsConversationsSettings = {
    loadImmediately: false
  }

  const [getHubspotAccessToken, { error }] = useLazyQuery(
    QUERY_HUBSPOT_ACCESS_TOKEN,
    {
      onCompleted: (data: HubspotData) => {
        const { getHubspotAccessToken: accessToken } = data
        localStorage.setItem('hubspot-jwt', accessToken)
        setHubspotJWT(accessToken)
      },
      fetchPolicy: 'no-cache'
    }
  )
  const [hubspotJWT, setHubspotJWT] = useState<string | null>(
    localStorage.getItem('hubspot-jwt')
  )

  useEffect(() => {
    if (!!hubspotJWT) {
      const hubspot = jwt.decode(hubspotJWT) as HubspotJWT
      const expirationDate = hubspot.exp * 1000
      const now = Date.now()

      if (now > expirationDate) {
        getHubspotAccessToken({
          variables: {
            userId: user.id
          }
        })
      }
    } else {
      getHubspotAccessToken({
        variables: {
          userId: user.id
        }
      })
    }
  }, [hubspotJWT])

  if (error)
    return console.error(`Unable to get hubspot token due to: ${error}`)

  return hubspotJWT
}
