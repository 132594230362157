import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { PaperClipOutlined } from '@ant-design/icons'
import { PriceMatrix } from '@slerp/controls'

const SlerpOptimizeDeliveryPricing = ({
  priceMatrix,
  storeSlug
}: {
  priceMatrix?: PriceMatrix
  storeSlug: string
}) => {
  return (
    <Col className='slerp-optimze-delivery-pricing' span={24}>
      <p className='settings-title title'>
        You are using Optimised Delivery Pricing
      </p>
      <p className='message'>
        This means your customers pay lower delivery fees. Leading to higher
        conversion and increased sales. You can check the delivery rates by
        clicking on the link below.
      </p>
      <Link
        to={`/location/${storeSlug}/slerp-optimize-delivery-pricing/${priceMatrix.id}`}
        target='_blank'
      >
        <Row className='template'>
          <Col className='gutter-row first-column' span={24}>
            <div className='paper-clip'>
              <PaperClipOutlined className='icon' />
            </div>
            <label className='file-name'>
              {priceMatrix.name || 'rate card'}.csv
            </label>
          </Col>

          {/* Hide this for now because we don't have this functionality yet */}
          {/* <Col className='gutter-row second-column' span={12}>
            <div className='activated'>
              Activated on 12.06.22
            </div>
          </Col> */}
        </Row>
      </Link>
    </Col>
  )
}

export default SlerpOptimizeDeliveryPricing
