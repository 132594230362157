import React, { useState, useEffect } from 'react'
import {
  Row,
  Table,
  Input,
  Select,
  message,
  Space,
  Typography,
  Upload,
  Button,
  Col
} from 'antd'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import Loading from '../Utils/Loading'
import {
  MUTATE_STORE_VARIANT_STOCK,
  MUTATE_STORE_VARIANT_AVAILABILITY,
  MUTATE_BULK_STOCK_COUNTS
} from './StockQueries'
import FormattedDate from '../Utils/FormattedDate'
import converter from 'csvtojson'
import { CSVLink } from 'react-csv'
import { StoreVariant } from './LocationQueries'

type BulkUploadOutgoingType = {
  category_name: string
  product_name: string
  id: string
  sku: string
  stock_available: string
  enabled: string
}

const StockCount = ({
  storeSlug,
  storeId,
  products,
  refetch,
  paginationProps
}: {
  storeSlug: string
  storeId: string
  products?: StoreVariant[]
  refetch: () => void
  paginationProps?: {
    onChange: (value: number) => void
    current: number
    showSizeChanger: boolean
    pageSizeOptions: string[]
  }
}) => {
  const data = { store_variants: products }
  const [updateStockVariant] = useMutation(MUTATE_STORE_VARIANT_STOCK)
  const [updateStoreVariantAvailability] = useMutation(
    MUTATE_STORE_VARIANT_AVAILABILITY
  )
  const [bulkStockCountsUpload] = useMutation(MUTATE_BULK_STOCK_COUNTS)

  const updateStockCount = (params: {
    id: string
    stock_count?: number
    stock_sold?: number
    stock_type?: string
  }) => {
    message.loading('Updating... Please wait.')
    return updateStockVariant({
      variables: {
        stock_updated_at: new Date(),
        ...params
      }
    }).then((result) => {
      refetch()
    })
  }

  const updateStockAvailability = (params: {
    id: string
    // accepts null only because when in_stock is edited, type should be disabled
    stock_type: null
    in_stock?: boolean
  }) => {
    message.loading('Updating... Please wait.')
    return updateStoreVariantAvailability({
      variables: {
        stock_updated_at: new Date(),
        ...params
      }
    }).then((result) => {
      refetch()
    })
  }

  const StockInput = (text: string, record: StoreVariant) => {
    const [availableDirty, setAvailableDirty] = useState(false)
    const [available, setAvailable] = useState(
      record.stock_count - record.stock_sold
    )
    const [typeDirty, setTypeDirty] = useState(false)
    const [type, setType] = useState(record.stock_type)

    useEffect(() => {
      setAvailable(record.stock_count - record.stock_sold)
      setType(record.stock_type)
    }, [record.stock_count, record.stock_type])

    const handleStockUpdate = () => {
      updateStockCount({
        id: record.id,
        stock_count: available,
        // reset stock sold to 0 everytime store variant is updated
        // but keep `available` count the same
        // this ensures stock count and virtual field `available` is equal
        // which is important in automatic reset
        stock_sold: Number(0),
        stock_type: type
      }).then(() => {
        message.success('Stock updated', 1)
        setAvailableDirty(false)
        setTypeDirty(false)
      })
    }

    const markInStock = () => {
      updateStockAvailability({
        id: record.id,
        in_stock: true,
        stock_type: null
      }).then(() => {
        message.success('Stock updated', 1)
        setAvailableDirty(false)
        setTypeDirty(false)
      })
    }

    const markSoldOut = () => {
      updateStockAvailability({
        id: record.id,
        in_stock: false,
        stock_type: null
      }).then(() => {
        message.success('Stock updated', 1)
        setAvailableDirty(false)
        setTypeDirty(false)
      })
    }

    return (
      <Space>
        {record.in_stock ? (
          <Button onClick={markSoldOut} style={{ width: '160px' }}>
            {'Mark as Sold Out'}
          </Button>
        ) : (
          <Button onClick={markInStock} style={{ width: '160px' }}>
            {'Mark as In Stock'}
          </Button>
        )}
        <Select
          value={type || ''}
          onSelect={(value) => {
            setType(value)
            if (record.stock_type !== value) {
              setTypeDirty(true)
            } else {
              setTypeDirty(false)
            }
          }}
          style={{ minWidth: '120px', marginLeft: '16px' }}
          data-testid={`${record.id}-select-stock-type`}
        >
          <Select.Option value=''>Disabled</Select.Option>
          <Select.Option value='manual'>Manual</Select.Option>
          <Select.Option value='daily'>Daily</Select.Option>
          <Select.Option value='weekly_monday'>Weekly (Monday)</Select.Option>
          <Select.Option value='weekly_tuesday'>Weekly (Tuesday)</Select.Option>
          <Select.Option value='weekly_wednesday'>
            Weekly (Wednesday)
          </Select.Option>
          <Select.Option value='weekly_thursday'>
            Weekly (Thursday)
          </Select.Option>
          <Select.Option value='weekly_friday'>Weekly (Friday)</Select.Option>
          <Select.Option value='weekly_saturday'>
            Weekly (Saturday)
          </Select.Option>
          <Select.Option value='weekly_sunday'>Weekly (Sunday)</Select.Option>
        </Select>
        <Input
          value={available}
          onChange={(event) => {
            setAvailable(Number(event.target.value))
            if (
              Number(record.stock_count - record.stock_sold) !==
              Number(event.target.value)
            ) {
              setAvailableDirty(true)
            } else {
              setAvailableDirty(false)
            }
          }}
          style={{ width: '80px' }}
          required={true}
          min={0}
          type='number'
          data-testid={`${record.id}-input-stock-availability`}
        />
        {availableDirty || typeDirty ? (
          <div style={{ width: '80px', color: 'green', cursor: 'pointer' }}>
            <CheckCircleTwoTone
              data-testid={`${record.id}-save-stock-count`}
              twoToneColor='green'
              height='1.2em'
              onClick={handleStockUpdate}
            />{' '}
            Save
          </div>
        ) : (
          <></>
        )}
      </Space>
    )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: ['product_variant', 'name'],
      key: 'name',
      render: (text: string, record: StoreVariant) => (
        <>
          <Typography.Text>{text}</Typography.Text>
        </>
      )
    },
    {
      title: 'SKU',
      dataIndex: ['product_variant', 'sku'],
      key: 'sku',
      render: (text: string) => <Typography.Text>{text}</Typography.Text>
    },
    {
      title: 'Stock Count',
      key: 'stock_count',
      render: StockInput
    },
    {
      title: 'Last Updated At',
      dataIndex: 'stock_updated_at',
      key: 'stock_updated_at',
      render: (text: string) => <FormattedDate>{text}</FormattedDate>
    }
  ]

  const onCSVUploadTest = () => {
    setUploading(true)
    const fileReader = new FileReader()
    fileReader.readAsText(file)
    fileReader.onloadend = () => {
      converter({
        trim: true,
        checkType: true,
        includeColumns: /(id|stock_available|enabled)/
      })
        .fromString(fileReader.result as string)
        .then((rows) => {
          bulkStockCountsUpload({
            variables: { bulkStockCountsParams: rows, storeId: storeId }
          })
            .then((payload) => {
              return refetch()
            })
            .then(() => {
              message.success('Bulk stock count upload success!', 4)
              setUploading(false)
              setFile(undefined)
            })
            .catch((error) => {
              message.error(`Stock counts not updated: ${error.message}`, 4)
              setUploading(false)
            })
        })
    }
  }

  const removeQuotes = (value: string) => {
    if (value) return value.replace('"', '')
    return ''
  }

  const onCSVDownload = () => {
    const items =
      data &&
      data.store_variants?.reduce(
        (acc: BulkUploadOutgoingType[], value: StoreVariant) => {
          const variant = {
            category_name: removeQuotes(
              value.product_variant.product.category.name
            ),
            product_name: removeQuotes(value.product_variant.name),
            id: value.id,
            sku: removeQuotes(value.product_variant.sku),
            stock_available: `${value.stock_count - value.stock_sold}`,
            enabled: value.stock_type === 'manual' ? 'true' : 'false'
          }
          return [...acc, variant]
        },
        []
      )
    if (items && items.length > 0) {
      const replacer = (key: string, value: string) => (!!!value ? '' : value)
      const header = Object.keys(items[0])
      const csv = [
        header.join(','),
        // assigned any but real type is BulkUploadOutgoingType to fix type issue for string index access
        ...items.map((row: any | BulkUploadOutgoingType) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        )
      ].join('\r\n')
      return csv
    }
    return ''
  }

  const [file, setFile] = useState()
  const [uploading, setUploading] = useState(false)

  return (
    <>
      <Row
        gutter={12}
        justify='end'
        style={{ margin: '16px 0' }}
        data-testid='locations-stock-counts'
      >
        <Col span={6} style={{ maxWidth: '120px' }}>
          {file && (
            <Button
              onClick={onCSVUploadTest}
              disabled={!file}
              loading={uploading}
            >
              {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
          )}

          <Upload
            beforeUpload={(file) => {
              setFile(file)
              return false
            }}
            fileList={file ? [file] : []}
            onRemove={() => setFile(undefined)}
            showUploadList={{
              showDownloadIcon: false,
              showPreviewIcon: false,
              showRemoveIcon: true
            }}
            accept={'.csv'}
          >
            {!file && <Button>Bulk Upload</Button>}
          </Upload>
        </Col>

        <Col style={{ paddingRight: 0 }}>
          <CSVLink
            data={onCSVDownload()}
            filename={`${storeSlug}_bulk_stock_counts_${Date.now()}.csv`}
            style={{
              display: 'inline-block',
              border: '1px solid #d9d9d9',
              color: 'rgba(0, 0, 0, 0.65)',
              padding: '4px 15px'
            }}
          >
            Catalogue Download
          </CSVLink>
        </Col>
      </Row>
      <Row>
        {data && data.store_variants ? (
          <Table
            data-testid='stock-count-table'
            dataSource={data ? data.store_variants : []}
            columns={columns}
            pagination={paginationProps || {}}
            style={{ width: '100%' }}
            rowKey='id'
          />
        ) : (
          <Loading />
        )}
      </Row>
    </>
  )
}

export default StockCount
