import { gql } from '@apollo/client'

const variantStockCountFragment = `
  id
  product_variant {
    sku
    name
    product {
      category {
        name
      }
    }
  }
  in_stock
  stock_count
  stock_updated_at
  stock_type
  stock_sold
`

export const buildStockCountQuery = (isPreorder?: boolean) => {
  const storeFilter = isPreorder
    ? `storeByPreorderStoreId: { slug: {_eq: $storeSlug} }`
    : `store: { slug: {_eq: $storeSlug} }`

  return gql`
    query getVariantsStockCount($storeSlug: String!) {
      store_variants(
        where: {
          ${storeFilter}
          published_at: { _is_null: false }
        }
        order_by: { product_variant: { name: asc } }
      ) { ${variantStockCountFragment} }
    }
  `
}

export const MUTATE_STORE_VARIANT_STOCK = gql`
  mutation updateStoreVariant(
    $id: uuid!
    $stock_count: Int
    $stock_sold: Int
    $stock_type: String
    $stock_updated_at: timestamp
  ) {
    update_store_variants(
      where: { id: { _eq: $id } }
      _set: {
        stock_count: $stock_count
        stock_type: $stock_type
        stock_sold: $stock_sold
        stock_updated_at: $stock_updated_at
      }
    ) {
      affected_rows
      returning {
        in_stock
        stock_count
        stock_sold
        stock_type
        store_id
      }
    }
  }
`

export const MUTATE_STORE_VARIANT_AVAILABILITY = gql`
  mutation updateStoreVariantAvailability(
    $id: uuid!
    $stock_updated_at: timestamp
    $stock_type: String
    $in_stock: Boolean
  ) {
    update_store_variants(
      where: { id: { _eq: $id } }
      _set: {
        stock_updated_at: $stock_updated_at
        stock_type: $stock_type
        in_stock: $in_stock
      }
    ) {
      affected_rows
      returning {
        in_stock
        stock_count
        stock_sold
        stock_type
        store_id
      }
    }
  }
`

export const MUTATE_BULK_STOCK_COUNTS = gql`
  mutation ($bulkStockCountsParams: [BulkUploadParams], $storeId: ID!) {
    bulkStockCountsUpload(
      bulkStockCountsParams: $bulkStockCountsParams
      storeId: $storeId
    ) {
      id
    }
  }
`

export type StoreVariantProductStock = {
  id: string
  product_variant: {
    name: string
    sku: string
    product: {
      category: {
        name: string
      }
    }
  }
  in_stock: boolean
  stock_count: number
  stock_updated_at?: Date
  stock_type?: 'manual' | ''
  stock_sold: number
}

export interface GET_STORE_STOCK_RESPONSE {
  store_variants: StoreVariantProductStock[]
}

export const EDIT_MULTIPLE_STOCK_COUNT = gql`
  mutation EditMultipleStockCount($stockCountParams: [StockCountParams]) {
    updateStockCount(stockCountParams: $stockCountParams) {
      id
    }
  }
`
