import React, { useContext } from 'react'
import gql from 'graphql-tag'
import {
  ApolloClient,
  FetchResult,
  HttpLink,
  InMemoryCache
} from '@apollo/client'
import { PARTNER_API_ENDPOINT, GRAPHQL_ENDPOINT } from '@slerp/client'
import jwt from 'jsonwebtoken'

const JWT_GENERATE = gql`
  query generateUserJwt($email: String!, $password: String!) {
    generateJwt(email: $email, password: $password) {
      token
    }
  }
`

const REQUEST_PASSWORD_CHANGE = gql`
  mutation requestPasswordChange($email: String!) {
    requestPasswordChange(email: $email)
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $tokenId: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      tokenId: $tokenId
      password: $password
      passwordConfirmation: $passwordConfirmation
    )
  }
`

const MERCHANT_FRAGMENT = `
  id
  name
  slug
  stripe_account_type
  dashboard_version
`

const GET_USER_AND_MERCHANT_BY_ID = gql`
  query GetUserById($userId: uuid!, $merchantId: uuid!) {
    user: users_by_pk(id: $userId) {
      id
      api_key
      email
      firstname
      lastname
      role
      inserted_at
      store {
        id
        name
        slug
      }
      stores: store_users {
        store {
          id
          name
          slug
        }
      }
      merchant {
        ${MERCHANT_FRAGMENT}
      }
    }
    merchant: merchants_by_pk(id: $merchantId) {
      ${MERCHANT_FRAGMENT}
    }
  }
`

const PUBLISH_NEW_MERCHANT = gql`
  mutation publishNewMerchant(
    $email: String!
    $firstname: String
    $lastname: String!
    $merchant_name: String!
    $password: String!
    $registered_company_name: String!
    $setting_email: String!
    $setting_website: String!
    $slug: String!
    $trading_since: String!
    $pos_system: String!
    $phone: String!
    $orders_per_week: Int!
    $location_count: Int!
    $live_at: String!
    $is_franchisee: String!
    $hutk: String!
    $how_can_we_help: String!
    $has_paid_marketing_ads: String!
    $has_loyalty: String!
    $has_expansion_plans: String!
    $has_crm: String!
    $existing_white_label_solution: String!
    $delivery_marketplaces: String!
    $average_order_value: Int!
    $page_uri: String!
    $instagram: String
    $has_consented: Boolean!
    $cyss_pos: String!
  ) {
    publishNewMerchant(
      email: $email
      firstname: $firstname
      lastname: $lastname
      merchantName: $merchant_name
      password: $password
      registeredCompanyName: $registered_company_name
      settingEmail: $setting_email
      settingWebsite: $setting_website
      slug: $slug
      tradingSince: $trading_since
      posSystem: $pos_system
      phone: $phone
      ordersPerWeek: $orders_per_week
      locationCount: $location_count
      liveAt: $live_at
      isFranchisee: $is_franchisee
      hutk: $hutk
      howCanWeHelp: $how_can_we_help
      hasPaidMarketingAds: $has_paid_marketing_ads
      hasLoyalty: $has_loyalty
      hasExpansionPlans: $has_expansion_plans
      hasCrm: $has_crm
      existingWhiteLabelSolution: $existing_white_label_solution
      deliveryMarketplaces: $delivery_marketplaces
      averageOrderValue: $average_order_value
      pageUri: $page_uri
      instagram: $instagram
      hasConsented: $has_consented
      cyssPos: $cyss_pos
    ) {
      merchantId
      sso
    }
  }
`

export type Merchant = {
  id: string
  name: string
  slug: string
  dashboard_version: number
  stripe_account_type: string
}

type Store = {
  id: string
  name: string
  slug: string
}

export type User = {
  id: string
  api_key: string
  firstname: string
  lastname: string
  email: string
  role: string
  store?: Store
  stores?: {
    store: Store
  }[]
  hasuraRole: string
  inserted_at: Date
}

export type PublishNewMerchantParams = {
  email: string
  firstname: string
  lastname: string
  merchant_name: string
  password: string
  registered_company_name: string
  setting_email: string
  setting_website: string
  slug: string
  trading_since: string
  pos_system: string
  phone: string
  orders_per_week: number
  location_count: number
  live_at: string
  is_franchisee: string
  hutk: string
  how_can_we_help: string
  has_paid_marketing_ads: string
  has_loyalty: string
  has_expansion_plans: string
  has_crm: string
  existing_white_label_solution: string
  delivery_marketplaces: string
  average_order_value: number
  page_uri: string
  instagram?: string
  has_consented: Array<'Yes'>
}

export type PublishNewMerchantResult = {
  merchantId: string
  sso: string
}

export type CreateSessionResponse = {
  user: User
  token: string
  merchant: Merchant
}

export type Session = {
  createSession: (
    jwt: string,
    ssoMerchant?: string
  ) => Promise<CreateSessionResponse> | undefined
  authenticate: (email: string, password: string) => {}
  requestPasswordChange: (email: string) => {}
  resetPassword: (
    tokenId: string,
    password: string,
    passwordConfirmation: string
  ) => {}
  user: User
  merchant: Merchant
  token: string
}

const initialSession: Session = {
  user: {},
  merchant: {},
  createSession: () => {},
  authenticate: () => {},
  requestPasswordChange: () => {},
  resetPassword: () => {},
  token: ''
}

export const SessionContext = React.createContext<Session>(initialSession)

export const useSession = () => {
  return useContext(SessionContext)
}

export const createSession = (
  jwToken: string,
  ssoMerchant?: string
): Promise<CreateSessionResponse> | undefined => {
  if (!jwToken) return Promise.reject(new Error('No token found'))

  const decodedToken = jwt.decode(jwToken)
  const claims =
    decodedToken && typeof decodedToken !== 'string'
      ? decodedToken[CLAIMS_NAMESPACE]
      : decodedToken

  // get merchant from search params when loggin in through SSO
  if (claims) {
    const merchantId = ssoMerchant || claims['x-hasura-merchant-id']
    const userId = claims && claims['x-hasura-user-id']
    const hasuraRole = claims && claims['x-hasura-default-role']

    const client = new ApolloClient({
      link: new HttpLink({
        uri: PARTNER_API_ENDPOINT,
        headers: {
          authorization: `Bearer ${jwToken}`,
          'x-hasura-merchant-id': merchantId,
          'x-hasura-operation-name': 'create-session'
        }
      }),
      cache: new InMemoryCache()
    })

    return client
      .query({
        query: GET_USER_AND_MERCHANT_BY_ID,
        variables: {
          userId,
          merchantId
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache'
      })
      .then((result: { data: CreateSessionResponse }) => {
        const { user: userData, merchant: merchantData } = result.data
        const userCache = { ...userData, hasuraRole }

        return Promise.resolve({
          user: userCache,
          token: jwToken,
          merchant: merchantData
        })
      })
      .catch((error) => {
        return Promise.reject(
          new Error(`Cannot find user/merchant, Error: ${error}`)
        )
      })
  }
}

const CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims'

export const authenticate = (email: string, password: string) => {
  const client = new ApolloClient({
    link: new HttpLink({
      uri: GRAPHQL_ENDPOINT
    }),
    cache: new InMemoryCache()
  })

  return client
    .query({
      query: JWT_GENERATE,
      variables: { email, password },
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache'
    })
    .then((response: { data: { generateJwt: { token: string } } }) => {
      return Promise.resolve({ token: response?.data?.generateJwt?.token })
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(new Error('Unauthorized'))
    })
}

export const requestPasswordChange = (email: string) => {
  const client = new ApolloClient({
    link: new HttpLink({
      uri: GRAPHQL_ENDPOINT
    }),
    cache: new InMemoryCache()
  })

  return client
    .mutate({
      mutation: REQUEST_PASSWORD_CHANGE,
      variables: { email },
      fetchPolicy: 'no-cache'
    })
    .then((response: { data: { requestPasswordChange: string } }) => {
      return Promise.resolve({ result: response?.data?.requestPasswordChange })
    })
}

export const resetPassword = (
  tokenId: string,
  password: string,
  passwordConfirmation: string
) => {
  const client = new ApolloClient({
    link: new HttpLink({
      uri: GRAPHQL_ENDPOINT
    }),
    cache: new InMemoryCache()
  })

  return client
    .mutate({
      mutation: RESET_PASSWORD,
      variables: { tokenId, password, passwordConfirmation },
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache'
    })
    .then((response: { data: { resetPassword: boolean } }) => {
      return Promise.resolve({
        result: response?.data?.resetPassword
      })
    })
    .catch((error) => {
      console.log(error)
      return Promise.reject(new Error('Unauthorized'))
    })
}

export const publishNewMerchant = (params: PublishNewMerchantParams) => {
  const client = new ApolloClient({
    link: new HttpLink({
      uri: GRAPHQL_ENDPOINT
    }),
    cache: new InMemoryCache()
  })

  return client
    .mutate({
      mutation: PUBLISH_NEW_MERCHANT,
      variables: params,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    })
    .then(
      (
        response: FetchResult<{ publishNewMerchant: PublishNewMerchantResult }>
      ) => {
        if (Boolean(response?.errors?.length)) {
          return Promise.reject({ errors: response.errors })
        }

        return Promise.resolve({ result: response?.data?.publishNewMerchant })
      }
    )
}

const HASURA_ADMIN_ROLES = ['merchant-admin', 'superadmin']
const MANAGER_ROLES = ['manager']

export const isAdmin = (user?: User) => {
  return HASURA_ADMIN_ROLES.includes(user?.hasuraRole)
}

export const isManager = (user?: User) => {
  return MANAGER_ROLES.includes(user?.role)
}

export const signOut = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('merchant')
  localStorage.removeItem('user')
  window.location.reload()
}
