import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography,
  DatePicker,
  Tooltip
} from 'antd'
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { createClient } from '@slerp/client'
import { useQuery, useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  GET_ORDER_QUOTE,
  GET_STORES,
  BOOK_ANOTHER_COURIER,
  GET_TIMESLOTS
} from 'components/Orders/OrderQueries'
import { OrderInfo } from 'components/Orders/Info/type'
import moment from 'moment'
import AddressSubform from './AddressSubform'
import RecipientSubform from './RecipientSubform'
import {
  humanizeTimeslot,
  constructAddressHash,
  buildRebookingVariables,
  buildDeliveryCostQuoteVariables,
  buildGetOrderQuoteVariables,
  isDateDisabled,
  getCurrentStore,
  dateFormat as dateOutputFormat,
  parseContactNumberForDisplay
} from './utils'
import { Timeslot, ValidStore } from '../types'
import { SplitHour } from '@slerp/controls'
import { SpinnerInputWrapper } from './styledComponents'

const dateFormat = 'DD/MM/YYYY'

interface OrderDetailsProps {
  order: OrderInfo
  successCallback: () => void
  cancelHandler: () => void
}

type Store = ValidStore & { split_hours: SplitHour[] }

const { useForm } = Form
const { TextArea } = Input
const { Text } = Typography

const CourierForm = ({
  order,
  successCallback,
  cancelHandler
}: OrderDetailsProps) => {
  const { user, merchant } = useSession()
  const { store, customer_details, delivery, transaction_id, dropoff_notes } =
    order
  const [form] = useForm()

  const [showDeliveryNotes, setShowDeliveryNotes] = useState(true)
  const [deliveryCost, setDeliveryCost] = useState(0)
  const [isDeliveryCostFetching, setIsDeliveryCostFetching] = useState(false)
  const [stores, setStores] = useState<ValidStore[]>([])
  const [selectedStore, setSelectedStore] = useState<Store | null>(null)
  const [isOtherReason, setIsOtherReason] = useState<boolean>(false)
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format(dateOutputFormat)
  )

  const getDeliveryCostQuote = async () => {
    const apiKey = localStorage.getItem('token') || ''
    const client = createClient(apiKey)
    const formValues = form.getFieldsValue(true)
    const variables = buildDeliveryCostQuoteVariables(order, formValues)

    setIsDeliveryCostFetching(true)
    return await client
      .query({
        query: GET_ORDER_QUOTE,
        variables
      })
      .then((response) => {
        const { cost } = response.data.quote
        setIsDeliveryCostFetching(false)
        setDeliveryCost(cost)
      })
      .catch((err) => {
        setDeliveryCost(-1)
        setIsDeliveryCostFetching(false)
      })
  }

  const [rebookCourier] = useMutation(BOOK_ANOTHER_COURIER)

  const processRebookingOfCourier = async () => {
    const formValues = form.getFieldsValue(true)
    const variables = buildRebookingVariables(
      formValues,
      customer_details,
      transaction_id,
      user,
      showDeliveryNotes
    )

    if (!variables.reason) {
      message.destroy()
      message.error(`Unable to book as a reason is required.`, 3)

      return
    }

    if (isOtherReason && !variables.reasonNotes) {
      message.destroy()
      message.error(`Unable to book. Please specify a reason.`, 3)

      return
    }

    rebookCourier({ variables })
      .then((result) => {
        successCallback()
        message.destroy()
        message.success('Additional courier booked successfully!', 3)
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to rebook courier for order ${transaction_id} due to ${error.message}`,
          3
        )
      })
  }

  const { data: getStores, loading: getStoresLoading } = useQuery(GET_STORES, {
    variables: { merchantId: merchant.id },
    onCompleted: (data) => {
      if (data?.stores.length) {
        const originalArray = [...getStores.stores]
        const stores = originalArray.sort(
          (a: { name: string }, b: { name: string }) =>
            a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        )

        setStores(stores)
      }
    }
  })

  const { data: deliveryCostData, loading: quoteQueryLoading } = useQuery(
    GET_ORDER_QUOTE,
    {
      variables: buildGetOrderQuoteVariables(
        order,
        delivery,
        store,
        customer_details
      )
    }
  )

  const { location } = form.getFieldsValue(true)

  const {
    data: timeslotsQuery,
    loading: timeslotsQueryLoading,
    refetch: refetchTimeslots
  } = useQuery(GET_TIMESLOTS, {
    variables: {
      storeId: location,
      fulfillmentDate: currentDate
    },
    onCompleted: (data) => {
      if (data?.getFixedStoreTimeslots.length) {
        form.setFieldsValue({
          delivery_time: data.getFixedStoreTimeslots[0].value || 'asap'
        })
      }
    }
  })

  const handleDateChange = (momentValue: any) => {
    const date = momentValue.format(dateOutputFormat)
    setCurrentDate(date)
  }

  useEffect(() => {
    if (deliveryCostData) {
      const { cost = 0 } = deliveryCostData?.quote || {}
      setDeliveryCost(cost)
    }
  }, [deliveryCostData])

  useEffect(() => {
    if (getStoresLoading) {
      return
    }

    const currentStore = getCurrentStore(location, stores)

    setSelectedStore(currentStore)
  }, [stores, location, getStoresLoading])

  useEffect(() => {
    refetchTimeslots()
  }, [location, currentDate, refetchTimeslots])

  const addressHash = constructAddressHash(customer_details)

  return getStoresLoading ? (
    <Spin indicator={<LoadingOutlined spin />} />
  ) : (
    <>
      <Row className='courier-form-header'>
        <Col span={24}>
          <Text strong>Book additional courier for</Text>
          <Text strong className='_cl-primary'>{` #${transaction_id}`}</Text>
        </Col>
      </Row>
      <Form
        layout='vertical'
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 14 }}
        className='rebooking-form'
        form={form}
        initialValues={{
          reason: null,
          reason_notes: null,
          location: store.id,
          drop_off: { ...addressHash },
          drop_off_subform: { ...addressHash },
          vehicle: delivery?.default_transport_type,
          first_name: customer_details?.first_name,
          last_name: customer_details?.last_name,
          contact_num: parseContactNumberForDisplay(
            customer_details?.contact_num
          ),
          customer_details_subform: customer_details && {
            ...customer_details,
            contact_num: parseContactNumberForDisplay(
              customer_details.contact_num
            )
          },
          delivery_date: 'immediately',
          delivery_time: 'asap',
          delivery_notes: dropoff_notes
        }}
      >
        <Form.Item name='location' label='Pickup from:'>
          <Select
            onChange={() => {
              getDeliveryCostQuote()
            }}
          >
            {stores.map((validStore: ValidStore) => (
              <Select.Option value={validStore.id}>
                {validStore.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <AddressSubform form={form} onSave={getDeliveryCostQuote} />

        <RecipientSubform form={form} />

        <Form.Item
          className='reasons'
          rules={[{ required: true, message: 'cannot be empty' }]}
          name='reason'
          label='Please select a reason for booking:'
        >
          <Radio.Group
            className='-left-aligned'
            onChange={(e) => setIsOtherReason(e.target.value === 'OTHER')}
          >
            <Space direction='vertical'>
              <Radio value='SPLIT'>Large order split delivery</Radio>
              <Radio value='MISSING_OR_ADDITIONAL_ITEMS'>
                Missing / Additional item(s)
              </Radio>
              <Radio value='ABSENT_CUSTOMER'>Absent customer</Radio>
              <Radio value='INCORRECT_ADDRESS'>
                Address / Customer details update
              </Radio>
              <Radio value='INCORRECT_ITEMS'>
                Redelivery due to incorrect item(s)
              </Radio>
              <Radio value='COURIER_ISSUE'>
                Redelivery due to courier issue
              </Radio>
              <Radio value='CHANGING_DO_WINDOW'>Timing update</Radio>
              <Radio value='CHANGE_PU'>Change pickup location</Radio>
              <Radio value='OTHER'>Other (please specify)</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {isOtherReason && (
          <Form.Item
            className='other'
            rules={[
              {
                required: true,
                message: 'Please specify a reason.'
              }
            ]}
            name='reason_notes'
          >
            <Input maxLength={160} />
          </Form.Item>
        )}

        <Text>Delivery schedule:</Text>
        <Row>
          <Col span={7}>
            <Form.Item
              name='delivery_date'
              valuePropName='date'
              getValueFromEvent={(momentValue) =>
                momentValue.format(dateOutputFormat)
              }
            >
              <DatePicker
                defaultValue={moment()}
                format={dateFormat}
                allowClear={false}
                style={{ width: '160px' }}
                disabledDate={isDateDisabled(selectedStore?.split_hours)}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item name='delivery_time'>
              {timeslotsQueryLoading ||
              !timeslotsQuery?.getFixedStoreTimeslots ? (
                <SpinnerInputWrapper>
                  <Spin indicator={<LoadingOutlined spin />} />
                </SpinnerInputWrapper>
              ) : (
                <Select>
                  {timeslotsQuery?.getFixedStoreTimeslots.map(
                    (timeslot: Timeslot) => (
                      <Select.Option value={timeslot.value}>
                        {humanizeTimeslot(timeslot)}
                      </Select.Option>
                    )
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name='vehicle' label='Vehicle:'>
          <Radio.Group
            onChange={(e) => getDeliveryCostQuote()}
            className='-left-aligned'
          >
            <Space direction='vertical'>
              <Radio value='bike'>Bike</Radio>
              <Radio value='motorbike'>Motorbike</Radio>
              <Radio value='car'>Car</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Row align='middle' gutter={[8, 8]}>
          <Col>
            <Switch
              checked={showDeliveryNotes}
              onChange={setShowDeliveryNotes}
            />
          </Col>
          <Col>Delivery instructions to the courier</Col>
        </Row>
        {showDeliveryNotes && (
          <Form.Item name='delivery_notes'>
            <TextArea maxLength={255} rows={3} style={{ marginBottom: 10 }} />
          </Form.Item>
        )}
        <Row gutter={[8, 8]}>
          <Col>
            {quoteQueryLoading || isDeliveryCostFetching ? (
              <>
                <Spin indicator={<LoadingOutlined spin />} />
                <span> Calculating delivery fee</span>
              </>
            ) : deliveryCost <= 0 ? (
              <span>
                This job will exceed the maximum travel distance allowed. Please
                update the pickup location and/or the drop off address to
                proceed.
              </span>
            ) : (
              <>
                <span>You will be charged a delivery fee of £</span>
                <span className='_cl-primary'>
                  {(Math.round(deliveryCost * 100) / 100).toFixed(2)}
                </span>
                <span> for adding a courier.</span>
                <Tooltip
                  title={
                    <span>
                      You will be able to view the charge in Stripe or in the
                      Additional Charges report
                    </span>
                  }
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            )}
          </Col>
        </Row>
        <Row className='_mb-0' gutter={[8, 8]} justify='end'>
          <Col>
            <Button
              type='ghost'
              className='-secondary _uppercase'
              onClick={() => cancelHandler()}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              className='-secondary _uppercase'
              type='primary'
              htmlType='submit'
              onClick={processRebookingOfCourier}
              disabled={deliveryCost <= 0}
            >
              Book
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default CourierForm
