import React from 'react'
import { Device, ScreenData } from './types'
import { Landing, LandingSidebarControl } from '../screens/Landing'
import { Shop, ShopSidebarControl } from '../screens/Shop'
import { FaBoxOpen, FaShoppingCart } from '../components/Icon'

export const SCREENS: ScreenData[] = [
  {
    key: 'landing',
    name: 'Landing',
    component: Landing,
    icon: () => <FaBoxOpen />,
    sidebarStyle: React.Fragment,
    sidebarControl: LandingSidebarControl,
    tooltipTitle: (
      <span>
        This is the first page your customers will see so make it informative
        and eye catching. A product lifestyle shot works best here.
      </span>
    )
  },
  {
    key: 'shop',
    name: 'Shop',
    component: Shop,
    icon: () => <FaShoppingCart />,
    sidebarStyle: React.Fragment,
    sidebarControl: ShopSidebarControl,
    tooltipTitle: (
      <span>
        This is the main browsing page of your store. Keep copy punchy and the
        imagery bold.
      </span>
    )
  }
]

export const DEVICE_WIDTHS: Record<Device, number> = {
  xs: 360,
  md: 768,
  lg: 1280,
  fts: 0,
  fallback: 1024
}
