import React, { useState, useMemo } from 'react'
import { uuid } from 'uuidv4'
import moment from 'moment'
import compact from 'lodash/compact'
import difference from 'lodash/difference'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import { Col, Button, message, PageHeader, Row } from 'antd'
import { useMutation } from '@apollo/client'
import { Store, SplitHour } from '@slerp/controls'
import groupBy from 'lodash/groupBy'
import Day from './Day'
import { UPDATE_STORE_SPLIT_HOURS } from 'components/Locations/LocationQueries'
import { RECALCULATE_STORE_TIMER } from 'components/Locations/actions'
import SpecialOpeningDates from './SpecialOpeningDates'
import { DayOfWeek, SplitHourRaw } from './types'
import { ValidateShift } from './utils'

interface SplitShiftsProps {
  store: Store
  refetch: () => void
  disabled?: boolean
}

const DATE_FORMAT = 'YYYY-MM-DD'
const DAY_NAME_FORMAT = 'dddd'
const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const SplitShifts = ({ store, refetch, disabled }: SplitShiftsProps) => {
  const { split_hours } = store

  const [updateStoreSplitHours, { loading: updatingStoreSplitHours }] =
    useMutation(UPDATE_STORE_SPLIT_HOURS, {
      fetchPolicy: 'no-cache'
    })
  const [recalculateStoreTimer] = useMutation(RECALCULATE_STORE_TIMER, {
    fetchPolicy: 'no-cache'
  })

  // absinthe endpoint doesn't recognize id as part of the update shape hence we remove it
  const sanitizeShifts = (shifts: SplitHourRaw[]) => {
    const newShifts = [...(shifts || [])] // clone and make sure it handles null and undefine
    newShifts.forEach((shift: SplitHourRaw) => delete shift.id)
    return newShifts
  }

  const [dailyShifts, setDailyShifts] = useState<SplitHour[]>(
    sanitizeShifts(
      (split_hours || []).filter(
        (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
      )
    )
  )

  const [specialDatedShifts, setSpecialDatedShifts] = useState<SplitHour>(
    sanitizeShifts(
      (split_hours || []).filter(
        (shift: SplitHour) => shift.enabled && !isEmpty(shift.special_date)
      )
    )
  )

  const groupShiftsByDay = useMemo(
    () => (shifts: SplitHour[]) =>
      groupBy(shifts, (shift: SplitHour) => shift.day_of_week),
    []
  )

  const groupDatedShiftsByDatestamp = (datedShifts: SplitHour[]) =>
    groupBy(datedShifts, ({ special_date }: SplitHour) =>
      moment(special_date).format(DATE_FORMAT)
    )

  const shiftsByDay = groupShiftsByDay(dailyShifts)
  const datedShiftsByDatestamp = groupDatedShiftsByDatestamp(specialDatedShifts)

  const withConflictingDailyShifts = () => {
    const failingShifts = DAYS.map((day: string) => {
      const shifts = shiftsByDay[day] || []
      return shifts
        .filter((shift: SplitHour) => shift.enabled)
        .find(
          (shift: SplitHour, index: number) =>
            !ValidateShift(shift, shifts, shiftsByDay, day, index)
        )
    })
    return !!compact(failingShifts).length
  }

  const withConflictingDatedShifts = () => {
    const failingShifts = Object.keys(datedShiftsByDatestamp).map(
      (dateStamp: string) => {
        const shifts = datedShiftsByDatestamp[dateStamp]
        const dayOfWeek = moment(dateStamp)
          .format(DAY_NAME_FORMAT)
          .toLowerCase()
        return shifts
          .filter((shift: SplitHour) => shift.enabled && !shift.closed)
          .find(
            (shift: SplitHour, index: number) =>
              !ValidateShift(shift, shifts, {}, dayOfWeek, index)
          )
      }
    )

    return !!compact(failingShifts).length
  }

  const updateShiftsState = (day: DayOfWeek, shifts: SplitHour[]) => {
    const newShifts = [...dailyShifts].filter(
      (editingShift: SplitHour) => editingShift.day_of_week !== day
    )
    shifts.forEach((shift: SplitHour) => newShifts.push(shift))
    setDailyShifts(newShifts)
  }

  const updateDatedShiftsState = (datedShifts: SplitHour[]) => {
    setSpecialDatedShifts(
      sortBy([...datedShifts], ({ special_date }: SplitHour) => special_date)
    )
  }

  const fillerShifts = () => {
    const daysWithShift = dailyShifts.map(
      ({ day_of_week }: SplitHour) => day_of_week
    )
    const daysWithNoShift = difference(DAYS, daysWithShift)
    let filler = []
    daysWithNoShift.forEach((day: string) => {
      filler.push({
        day_of_week: day,
        start_time: '08:00:00',
        seconds_length: 3600,
        enabled: false,
        special_date: null,
        closed: false
      })
    })

    return filler
  }

  const saveShifts = () => {
    updateStoreSplitHours({
      variables: {
        id: store.id,
        splitHours: [...dailyShifts, ...fillerShifts(), ...specialDatedShifts]
      }
    })
      .then((result) => {
        recalculateStoreTimer({
          variables: {
            storeId: store.id
          }
        })
      })
      .finally(() => {
        message.destroy()
        message.success('Store shifts settings updated.', 3)
        refetch()
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to update store shifts settings. Due to ${error.message}`,
          3
        )
      })
  }

  const Header = () => (
    <Row>
      <Col span={4} />
      <Col span={16}>
        <Row className='_mb-0' gutter={[8, 0]}>
          <Col span={6}>Start</Col>
          <Col span={6}>End</Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <>
      <PageHeader title='Opening Hours' className='settings-title' />
      <div data-testid='split-hours-container'>
        <Header />
        {DAYS.map((day: string) => (
          <Day
            key={uuid()}
            dayOfWeek={day}
            shifts={shiftsByDay[day] || []}
            weeklyShifts={{ ...shiftsByDay }}
            updateShifts={updateShiftsState}
            disabled={disabled || updatingStoreSplitHours}
          />
        ))}
      </div>

      <PageHeader title='Special Opening Hours' className='settings-title' />
      <div>
        <SpecialOpeningDates
          updateDatedShifts={updateDatedShiftsState}
          shifts={specialDatedShifts}
          disabled={disabled}
        />
      </div>

      <Row justify='end'>
        <Col span={12}>
          <Button
            data-testid='split-hours-save-button'
            onClick={saveShifts}
            loading={updatingStoreSplitHours}
            disabled={
              updatingStoreSplitHours ||
              disabled ||
              withConflictingDailyShifts() ||
              withConflictingDatedShifts()
            }
          >
            SAVE
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SplitShifts
