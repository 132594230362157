import React from 'react'
import { Alert } from 'antd'

const ErrorMessage = ({
  title,
  subheading
}: {
  title: string
  subheading: string
}) => {
  return (
    <Alert
      showIcon
      type='error'
      message={title}
      description={subheading}
      style={{
        marginBottom: '24px',
        display: 'inline-block',
        paddingRight: '32px'
      }}
    />
  )
}

export default ErrorMessage
