import { useQuery } from '@apollo/client'
import { GET_DELIVERY_PRICING_BANDS } from './queries'
import { UseGetDeliveryPricingBandsProps } from './types'

const useGetDeliveryPricingBands = ({
  storeId,
  orderType
}: UseGetDeliveryPricingBandsProps) => {
  const { data, loading, refetch } = useQuery(GET_DELIVERY_PRICING_BANDS, {
    variables: {
      storeId,
      orderType
    },
    fetchPolicy: 'no-cache'
  })

  return [data?.getDeliveryPricingBands || [], { loading, refetch }]
}

export default useGetDeliveryPricingBands
