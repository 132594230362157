import React from 'react'
import { Button, Descriptions, Row, Col, Input } from 'antd'

interface AdditionalNotesProps {
  additionalNotes: string
  notesInputOnChangeHandler: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  submitHandler: (e: React.MouseEvent) => void
  hasAdditionalNotes: string | null
  toggleAdditionalNotes: (e: React.MouseEvent) => void
}

const { TextArea } = Input

const AdditionalNotesForm = (props: AdditionalNotesProps) => {
  const {
    additionalNotes,
    notesInputOnChangeHandler,
    submitHandler,
    hasAdditionalNotes,
    toggleAdditionalNotes
  } = props

  return (
    <>
      <Descriptions title='Additional Notes' />
      <TextArea
        value={additionalNotes}
        maxLength={255}
        rows={3}
        style={{ marginBottom: 10 }}
        onChange={notesInputOnChangeHandler}
      />
      <Row justify='end' gutter={8}>
        <Col>
          <Button
            title='Cancel'
            type='ghost'
            onClick={toggleAdditionalNotes}
            className='-secondary _uppercase'
            data-testid='order-additional-notes-cancel'
          >
            Cancel
          </Button>
        </Col>

        <Col>
          <Button
            title='save'
            onClick={submitHandler}
            className='-secondary _uppercase'
            data-testid='order-additional-notes-save-update'
          >
            {hasAdditionalNotes ? 'Update' : 'Save'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default AdditionalNotesForm
