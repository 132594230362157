import React, { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Col, Form, Input, Button, Alert, Spin } from 'antd'
import './LoginForm.css'
import { useSession } from '@slerp/accounts'
import logo from '../../logo.png'
import errorReducer from 'errors/errorReducer'
import env from '../../env'

interface ResetPasswordResponse {
  result: string
}

const ResetPasswordForm = () => {
  const { resetPassword } = useSession()
  const [form] = Form.useForm()
  const [message, setMessage] = useState<{
    type: 'error' | 'success' | 'info' | 'warning' | undefined
    message: string
  }>()
  const [buttonLabel, setButtonLabel] = useState(<>Submit</>)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const handleError = (error?: Error) => {
      setMessage({
        message:
          error?.message || 'Unable to update password. Please try again.',
        type: 'error'
      })
      setButtonLabel(<>Submit</>)
    }

    form
      .validateFields()
      .then(async (values) => {
        const token_id = window.location.pathname.split('/').pop()
        setButtonLabel(
          <>
            Updating <Spin style={{ color: 'white' }} />
          </>
        )

        return resetPassword(
          token_id,
          values.password,
          values.password_confirmation
        )
      })
      .then((response: ResetPasswordResponse) => {
        const { result } = response

        setMessage({
          message: result,
          type: 'success'
        })
        setButtonLabel(<>Submit</>)

        window.setTimeout(() => {
          window.location.href = window.location.origin
        }, 2500)
      })
      .catch((error: Error | object) => {
        if (error instanceof Error) {
          throw errorReducer({
            origin: 'UserPassword',
            data: {
              error: error,
              message: error.message
            }
          })
        }

        throw error
      })
      .catch((error: Error) => {
        console.warn(error) // Print what's happening.
        handleError(error)
      })
  }

  const ShowError = () => {
    return message && message.message ? (
      <>
        <Alert message={message.message} type={message.type} showIcon />
        <br />
      </>
    ) : (
      <></>
    )
  }

  const PasswordValidator = (
    _rule: any,
    value: string,
    callback: (v: string) => void
  ) => {
    if (value === form.getFieldValue('password')) {
      callback()
    } else {
      callback("Password doesn't match")
    }
  }

  return (
    <Col span={24}>
      <div className='login-container'>
        <Form className='login-form' form={form}>
          <div className='login-logo'>
            <img src={logo} alt='Slerp' />
            <h1>Password Update</h1>
            <h3>Please enter your new password.</h3>
          </div>
          <ShowError />
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password.' }]}
          >
            <Input.Password
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Password'
              size='large'
              autoFocus
              data-testid='passwordInput'
            />
          </Form.Item>

          <Form.Item
            name='password_confirmation'
            rules={[
              { required: true, message: 'Please input your password.' },
              { validator: PasswordValidator }
            ]}
          >
            <Input.Password
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Password Confirmation'
              size='large'
              autoFocus
              data-testid='passwordConfirmationInput'
            />
          </Form.Item>

          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            onClick={handleSubmit}
            size='large'
          >
            {buttonLabel}
          </Button>
        </Form>
      </div>
    </Col>
  )
}

export default ResetPasswordForm
