import React, { useState, useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { CreateSessionResponse, useSession } from '@slerp/accounts'
import Loading from '../Utils/Loading'
import { Header } from 'antd/lib/layout/layout'
import { Button, Result } from 'antd'

interface LocationType {
  hash: string
  pathname: string
  search: string
  state: undefined
}

const setSearchParams = (location: LocationType) => {
  return new URLSearchParams(location.search)
}

const SignIn = () => {
  const { createSession } = useSession()
  const [isLoggingIn, setIsLoggingIn] = useState(true)
  const [hasError, setHasError] = useState(false)

  const searchParams = setSearchParams(useLocation())
  const token = searchParams.get('token') || ''
  const merchant = searchParams.get('merchant') || ''

  useEffect(() => {
    // makes sure that there is no current active session logged-in in controls
    // to prevent from redirecting to another merchant
    localStorage.removeItem('token')
    localStorage.removeItem('merchant')
    localStorage.removeItem('user')

    createSession(token, merchant)!
      .then((session: CreateSessionResponse) => {
        const { user, token, merchant } = session

        if (user && token && merchant) {
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('merchant', JSON.stringify(merchant))

          setIsLoggingIn(false)
          window.location.replace('/')
        } else {
          setIsLoggingIn(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsLoggingIn(false)
        setHasError(true)
      })

    return () => window.location.reload()
  }, [])

  return (
    <Route
      path='/'
      render={({ location }) => {
        return (
          <div className='onboarding-container'>
            <Header className='site-layout-background main-header' />
            {isLoggingIn ? <Loading signIn /> : <></>}

            {hasError ? (
              <Result
                status='500'
                title='500'
                subTitle='Something went wrong.'
                extra={
                  <Button
                    type='primary'
                    onClick={() => {
                      window.location.pathname = '/'
                    }}
                  >
                    Back to Login
                  </Button>
                }
              />
            ) : (
              <Loading signIn />
            )}
          </div>
        )
      }}
    />
  )
}

export default SignIn
