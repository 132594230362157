import React from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_STORE_STRIPE_CONNECT_URL } from '../Settings/actions'
import { Store } from '@slerp/controls'
import { StripeConnect } from '../Utils/Stripe'
import { STRIPE_CONNECT_LOCATION_OAUTH_ENDPOINT } from '@slerp/client'

interface Props {
  store: Store
  stripeId: string
  canConnect: boolean
}

const StripeConnectSettings = ({ canConnect, store, stripeId }: Props) => {
  const redirect_uri = STRIPE_CONNECT_LOCATION_OAUTH_ENDPOINT

  const { data, loading: connectLoading } = useQuery(
    QUERY_STORE_STRIPE_CONNECT_URL,
    {
      variables: {
        redirectUri: redirect_uri,
        storeId: store.id
      },
      fetchPolicy: 'no-cache'
    }
  )

  const handleConnect = () => {
    localStorage.setItem(
      'redirect-store',
      JSON.stringify({ id: store.id, slug: store.slug })
    )
  }

  return (
    <StripeConnect
      loading={connectLoading}
      stripeId={stripeId}
      isAllowed={canConnect}
      hasStripeCustomerId={canConnect}
      stripeConnectUrl={data?.storeStripeConnectUrl}
      onConnect={handleConnect}
    />
  )
}

export default StripeConnectSettings
