import React from 'react'
import { CheckOutlined } from '@ant-design/icons'

interface StepIndicatorProps {
  step: number | string
  active: boolean
  finished?: boolean
}

export const StepIndicator = ({
  step,
  active,
  finished
}: StepIndicatorProps) => {
  return (
    <div
      className={`step-indicator ${active ? '-active' : ''} ${
        finished ? '-finished' : ''
      }`}
    >
      {finished ? <CheckOutlined /> : step}
    </div>
  )
}
