export const appSettingsSuccessMessage = (
  key: string,
  value: string | boolean | null
) => {
  const str = key.replace(/_/g, ' ')
  switch (typeof value) {
    case 'string':
      switch (key) {
        case 'default_landing':
          return `Feature "default landing page" has been set to "${value}"`

        case 'location_products_view':
          return `Feature "products view" has been set to "${value}"`

        default:
          return `Feature "${str}" has been set to "${value}"`
      }

    case 'boolean':
      const toggleText = value ? 'enabled' : 'disabled'

      switch (key) {
        case 'vertical_stamp_card_layout':
          return `Feature "card layout" has been set to "${
            value ? 'vertical' : 'horizontal'
          }"`

        case 'hide_additional_button':
          return `Feature "additional button" has been ${toggleText}`

        case 'locations_map':
          return `Feature "show map button" has been ${toggleText}`

        case 'hide_status_bar':
          return `Feature "status bar" has been ${toggleText}`

        case 'footer_nav':
          return `Feature "footer navigation" has been ${toggleText}`

        case 'custom_content_enabled':
          return `Feature "custom content" has been ${toggleText}`

        default:
          return `Feature "${str}" has been ${toggleText}`
      }

    default:
      return `Feature "${str}" has been set to "${value}"`
  }
}
