import React, { useState } from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import { useSlerp } from '@slerp/client'
import { Category } from '../CategoriesList'
import errorReducer from 'errors/errorReducer'
import { UPDATE_CATEGORY } from '../../ProductQueries'
import { noWhiteSpaceRule } from 'components/Login/rules'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation } from '@apollo/client'

interface EditCategoryFormProps {
  category: Category
  cancelHandler?: () => void
}

const { useForm } = Form

const EditCategoryForm = ({
  category,
  cancelHandler
}: EditCategoryFormProps) => {
  const client = useApolloClient()
  const { user } = useSlerp()
  const [form] = useForm()
  const [remainingChars, setRemainingChars] = useState<number>(
    category?.description ? 150 - category?.description?.length : 150
  )
  const initialValues = {
    name: category?.name || '',
    description: category?.description
  }

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    fetchPolicy: 'no-cache'
  })

  const onFinish = (values: { name: string; description: string }) => {
    const { name, description } = values

    form.validateFields().then(() => {
      message.destroy()
      message.loading('Updating category', 1)
      updateCategory({
        variables: {
          id: category.id,
          merchantSlug: user.merchant.slug,
          name,
          description
        }
      })
        .then((result) => {
          message.destroy()
          message.success('Category updated!', 3)
          client.resetStore()
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Category',
            data: {
              error: error,
              message: error.message
            }
          })
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(error.message, 3)
        })
    })
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      data-testid='category-form'
    >
      <Row className='-category-row'>
        <Col>Name</Col>
      </Row>
      <Row className='-category-row'>
        <Col span={12}>
          <Form.Item name='name' rules={noWhiteSpaceRule}>
            <Input
              required
              placeholder='Name'
              data-testid='category-form-name'
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className='-category-row'>
        <Col>
          Description
          <Tooltip
            title={
              <span>
                Category descriptions can help customers better understand what
                is on offer. They should be informative and persuasive.
                <br />
                <br />
                They can also help create more unique content on your page,
                which boosts the SEO ranking of your shop page.
              </span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name='description'
            validateTrigger='onBlur'
            className='category-description'
            rules={[
              {
                validator: (_, val) => {
                  if (!val) {
                    return Promise.resolve()
                  }

                  if (val.match(/<\/?[^>]+(>|$)/g)?.length) {
                    return Promise.reject('HTML tags are not allowed')
                  }

                  if (val.length < 20) {
                    return Promise.reject('Minimum character 20')
                  }

                  return Promise.resolve()
                }
              }
            ]}
          >
            <Input.TextArea
              placeholder='Description'
              data-testid='category-form-description'
              rows={2}
              maxLength={150}
              onChange={(e) => setRemainingChars(150 - e.target.value.length)}
            />
          </Form.Item>
          <Row
            style={{
              fontSize: '0.5625rem',
              position: 'absolute',
              bottom: '-1.2rem',
              right: 0
            }}
          >
            Remaining characters {remainingChars}
          </Row>
        </Col>
      </Row>

      <Row className='-category-edit'>
        <Col span={12}>
          <Row className='-category-edit'>
            <Col>
              <Button
                onClick={() => cancelHandler && cancelHandler()}
                data-testid='category-form-discard-btn'
              >
                Discard
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify='end' className='-category-edit'>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                data-testid='category-form-save-btn'
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default EditCategoryForm
