import React from 'react'
import styled from '@emotion/styled'

import { FaAngleDown } from '../Icon'

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement>

export const Select: React.FC<SelectProps> = (props) => (
  <SelectContainer>
    <StyledSelect {...props}></StyledSelect>
    <SelectArrow>
      <FaAngleDown />
    </SelectArrow>
  </SelectContainer>
)

const SelectContainer = styled.div`
  position: relative;

  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`

const SelectArrow = styled.div`
  position: absolute;
  right: 16px;
  top: 10px;
  color: ${({ theme }: any) => theme.colors.gray500};
  z-index: ${({ theme }: any) => theme.zIndex.selectArrow};
  display: flex;
  align-items: center;
  pointer-events: none;
`

const StyledSelect = styled.select`
  position: relative;
  padding: 8px 16px;
  height: ${({ theme }: any) => theme.input.height}px;
  width: 100%;
  color: ${({ theme }: any) => theme.colors.gray600};
  font-family: ${({ theme }: any) => theme.font.sans};
  background-color: ${({ theme }: any) => theme.colors.gray100};
  border: none;
  outline: none;
  font-size: ${({ theme }: any) => theme.fontSizes.sm};

  z-index: ${({ theme }: any) => theme.zIndex.select};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    background-color: ${({ theme }: any) => theme.colors.gray100};
  }

  @media (min-width: 1025px) {
    font-size: ${({ theme }: any) => theme.fontSizes.md};
  }
`
