import { useEffect, useState } from 'react'
import { Store as FormStore } from 'rc-field-form/lib/interface'
import { useMutation } from '@apollo/client'
import {
  UPDATE_STORE_SETTINGS_WITH_MILE_RADIUS,
  UPDATE_STORE_SETTINGS_WITH_MINUTES_RADIUS,
  UPDATE_STORE_SETTINGS_WITH_AREA,
  UPDATE_BUSY_AREA_WITH_MILE_RADIUS,
  UPDATE_BUSY_AREA_WITH_MINUTES_RADIUS,
  UPDATE_BUSY_AREA_WITH_AREA
} from '../actions'
import { Store } from '@slerp/controls'
import { ApolloError } from 'apollo-boost'

export const useUpdateStoreSettings = ({
  store
}: {
  store: Store
}): [
  (form: FormStore) => Promise<any>,
  { loading: boolean; error: ApolloError | undefined }
] => {
  const [updateSettingWithMile, { loading: loadingMiles, error: errorMiles }] =
    useMutation(UPDATE_STORE_SETTINGS_WITH_MILE_RADIUS, {
      fetchPolicy: 'no-cache'
    })
  const [
    updateSettingWithMinutes,
    { loading: loadingMinutes, error: errorMinutes }
  ] = useMutation(UPDATE_STORE_SETTINGS_WITH_MINUTES_RADIUS, {
    fetchPolicy: 'no-cache'
  })
  const [updateSettingsWithArea, { loading: loadingArea, error: errorArea }] =
    useMutation(UPDATE_STORE_SETTINGS_WITH_AREA, {
      fetchPolicy: 'no-cache'
    })

  const [loading, setLoading] = useState(
    loadingMiles || loadingMinutes || loadingArea
  )
  const [error, setError] = useState(errorMiles || errorMinutes || errorArea)

  useEffect(() => {
    setLoading(loadingMiles || loadingMinutes || loadingArea)
  }, [loadingMiles, loadingMinutes, loadingArea])

  useEffect(() => {
    setError(errorMiles || errorMinutes || errorArea)
  }, [errorMiles, errorMinutes, errorArea])

  const updateSetting = (values: FormStore) => {
    const {
      delivery_radius,
      delivery_time,
      delivery_interval,
      same_day_pickup,
      pickup_interval,
      busy_preparation_time,
      moderate_preparation_time,
      quiet_preparation_time,
      delivery_area_enabled,
      busy_delivery_area_enabled,
      busy_delivery_radius,
      busy_delivery_time,
      delivery_zone_type,
      delivery_area,
      delivery_area_touched,
      kml_upload
    } = values

    const isCustomDeliveryArea = !!(
      delivery_area_touched && delivery_area?.length
    )

    const variables = {
      store_id: store.id,
      delivery_radius,
      custom_delivery_area: isCustomDeliveryArea,
      kml_upload: !!(kml_upload && isCustomDeliveryArea),
      busy_preparation_time,
      moderate_preparation_time,
      quiet_preparation_time,
      delivery_area,
      delivery_time,
      delivery_interval,
      pickup_interval,
      prep_mode: (!!delivery_area_enabled && busy_delivery_area_enabled
        ? 'busy'
        : store.settings.prep_mode
      ).toUpperCase(),
      same_day_pickup,
      busy_delivery_radius,
      busy_delivery_time,
      busy_delivery_area_enabled
    }

    const updateSettings = isCustomDeliveryArea
      ? updateSettingsWithArea
      : delivery_zone_type === 'distance'
      ? updateSettingWithMile
      : updateSettingWithMinutes

    return updateSettings({ variables })
  }

  return [updateSetting, { loading, error }]
}

export const useUpdateBusyArea = ({
  store
}: {
  store: Store
}): [
  (form: FormStore) => Promise<any>,
  { loading: boolean; error: ApolloError | undefined }
] => {
  const [updateBusyWithMile, { loading: loadingMiles, error: errorMiles }] =
    useMutation(UPDATE_BUSY_AREA_WITH_MILE_RADIUS, {
      fetchPolicy: 'no-cache'
    })
  const [
    updateBusyWithMinutes,
    { loading: loadingMinutes, error: errorMinutes }
  ] = useMutation(UPDATE_BUSY_AREA_WITH_MINUTES_RADIUS, {
    fetchPolicy: 'no-cache'
  })
  const [updateBusyWithArea, { loading: loadingArea, error: errorArea }] =
    useMutation(UPDATE_BUSY_AREA_WITH_AREA, {
      fetchPolicy: 'no-cache'
    })

  const [loading, setLoading] = useState(
    loadingMiles || loadingMinutes || loadingArea
  )
  const [error, setError] = useState(errorMiles || errorMinutes || errorArea)

  useEffect(() => {
    setLoading(loadingMiles || loadingMinutes || loadingArea)
  }, [loadingMiles, loadingMinutes, loadingArea])

  useEffect(() => {
    setError(errorMiles || errorMinutes || errorArea)
  }, [errorMiles, errorMinutes, errorArea])

  const updateSetting = (values: FormStore) => {
    const {
      busy_delivery_radius,
      busy_delivery_time,
      delivery_zone_type,
      busy_delivery_area_touched,
      busy_delivery_area,
      busy_delivery_area_enabled,
      busy_delivery_area_kml_upload
    } = values

    if (!busy_delivery_area_enabled) {
      setLoading(false)
      setError(undefined)

      return Promise.resolve()
    }

    const isCustomDeliveryArea = !!(
      busy_delivery_area_touched && busy_delivery_area?.length
    )

    const variables = {
      store_id: store.id,
      custom_busy_delivery_area: isCustomDeliveryArea,
      busy_delivery_area_kml_upload: !!(
        busy_delivery_area_kml_upload && isCustomDeliveryArea
      ),
      busy_delivery_area,
      busy_delivery_radius,
      busy_delivery_time
    }

    const updateSettings = isCustomDeliveryArea
      ? updateBusyWithArea
      : delivery_zone_type === 'distance'
      ? updateBusyWithMile
      : updateBusyWithMinutes

    return updateSettings({ variables })
  }

  return [updateSetting, { loading, error }]
}
