const required = { required: true, message: 'This is a required field.' }
const requiredRule = [required]

const noWhiteSpaceRule = [
  () => ({
    validator(_: any, value: string) {
      if (!value ?? value.length === 0)
        return Promise.reject('This is a required field.')

      return !value?.replace(/\s/g, '').length
        ? Promise.reject('Please avoid using only spaces in your inputs.')
        : Promise.resolve()
    }
  })
]

const slugInputRule = [
  () => ({
    validator(_: any, value: string) {
      if (value === '-') {
        return Promise.reject(
          'Please make sure your slug contains more than just hyphens ("-").'
        )
      }

      if (Boolean(value.length) && value.slice(-1) === '-') {
        return Promise.reject(
          'Please remove trailing hyphen ("-") in your slug.'
        )
      }

      return Promise.resolve()
    }
  })
]

const noNumberRule = [
  () => ({
    validator: (_: any, val: string) => {
      if (!val || !/\d/.test(val)) {
        return Promise.resolve()
      }
      return Promise.reject('Input should not contain numbers')
    }
  })
]

const nameRule = [
  ...noNumberRule,
  ...noWhiteSpaceRule,
  () => ({
    validator: (_: any, val: string) => {
      return (val ?? '').length < 2
        ? Promise.reject('Input cannot be less than 2 characters')
        : Promise.resolve()
    }
  })
]

export { requiredRule, noWhiteSpaceRule, slugInputRule, nameRule }
