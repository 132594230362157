import React, { useEffect, useState } from 'react'
import Form from './Form'
import { Drawer, Row, Button } from 'antd'
import { Category, StoreInfo } from '../Inventories'
import './SpecialAvailability.css'
import Bar from './Bar'

interface Props {
  isVisible: boolean
  onClose: () => void
  categories: Category[]
  storeId: string
  stores: StoreInfo[]
  isPreorder: boolean | undefined
}

const SpecialAvailabilityDrawer = (props: Props) => {
  const { isVisible, onClose, categories, storeId, stores, isPreorder } = props
  const [showForm, setShowForm] = useState<boolean>(false)
  const [categoriesWithSA, setCategoriesWithSA] = useState<Category[] | []>([])
  const [storesWithSA, setStoresWithSA] = useState<string[]>([])

  useEffect(() => {
    setCategoriesWithSA(
      categories.filter(
        (c: Category) => c.category_special_availabilities.length
      )
    )

    const store_ids = categories
      .map((c) => c.category_special_availabilities)
      .flat()
      .map((sa) => sa.special_availability.store_special_availabilities)
      .flat()
      .map((s) => s.store.id)

    setStoresWithSA(store_ids)
  }, [categories])

  useEffect(() => {
    if (!storesWithSA.includes(storeId)) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
  }, [categoriesWithSA])

  return (
    <Drawer
      title={`${
        isPreorder
          ? 'Special availabilities for pre-order inventory'
          : 'Special availabilities for same day inventory'
      }`}
      visible={isVisible}
      onClose={onClose}
      destroyOnClose
      forceRender
      width={708}
    >
      {showForm ? (
        <Row className='_mt-16'>
          <Form
            categories={categories}
            storeId={storeId}
            stores={stores}
            isPreorder={isPreorder}
            hasExistingSA={storesWithSA.includes(storeId)}
            onClose={() => setShowForm(false)}
          />
        </Row>
      ) : (
        <Row className='_mt-16' justify='end'>
          <Button type='primary' onClick={() => setShowForm(true)}>
            Create Special Availability
          </Button>
        </Row>
      )}
      {categoriesWithSA.map((category: Category) => {
        return (
          <Bar
            key={category.id}
            categories={categories}
            currentCategory={category}
            storeId={storeId}
            stores={stores}
            isPreorder={isPreorder}
            hasExistingSA={storesWithSA.includes(storeId)}
          />
        )
      })}
    </Drawer>
  )
}

export default SpecialAvailabilityDrawer
