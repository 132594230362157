import React from 'react'
import { Col, Row } from 'antd'

const VariantGroupHeader = () => {
  return (
    <Row className='header _mb-0 _cl-text'>
      <Col span={18}>Variants</Col>
    </Row>
  )
}

export default VariantGroupHeader
