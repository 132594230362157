import isEmpty from 'lodash/isEmpty'
import { uuid } from 'uuidv4'

type FulfillmentType = 'delivery' | 'pickup' | 'order_at_table'
interface FulfillmentTypeAvailability {
  id: string
  product_id: null | string
  modifier_id?: null | string
  fulfillment_type: FulfillmentType
  is_available: boolean
  inserted_at: string
  updated_at: string
}

const FulfillmentTypes = ['delivery', 'pickup', 'order_at_table']

export const getAvailabilities = (
  productId: string | null,
  availabilities: FulfillmentTypeAvailability[],
  fulfillmentTypes: FulfillmentType,
  modifierId?: string | null
) => {
  // make sure to prefil missing fulfillment type
  FulfillmentTypes.forEach((type: FulfillmentType) => {
    const availability = availabilities.find(
      ({ fulfillment_type }: FulfillmentTypeAvailability) =>
        fulfillment_type === type
    )
    if (isEmpty(availability)) {
      availabilities.push({
        id: uuid(),
        product_id: productId,
        fulfillment_type: type,
        is_available: false,
        inserted_at: 'now()',
        updated_at: 'now()',
        modifier_id: modifierId
      })
    }
  })

  // do the necessary changes
  return availabilities.map(
    ({
      id,
      product_id,
      modifier_id,
      fulfillment_type,
      is_available,
      inserted_at,
      updated_at
    }: FulfillmentTypeAvailability) => ({
      id,
      product_id,
      modifier_id,
      fulfillment_type,
      inserted_at,
      is_available: fulfillmentTypes.includes(fulfillment_type),
      updated_at: 'now()'
    })
  )
}
