import React from 'react'
import * as DOMPurify from 'dompurify'
import { Col, PageHeader, Row } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_SIGNATURE_AND_TERMS } from './SettingsQueries'
import Loading from '../Utils/Loading'

const TermsAndConditions = ({ merchantId }: { merchantId: string }) => {
  const { data, loading } = useQuery(QUERY_MERCHANT_SIGNATURE_AND_TERMS, {
    variables: { merchantId: merchantId }
  })

  const latestData = data?.signatures[0]
  const term = latestData?.term?.terms_and_conditions
  const termDate = latestData?.term?.updated_at

  return (
    <>
      {(!loading && term && (
        <>
          <PageHeader title='Terms & Conditions' />

          <Row
            className='_mb-48 term-info'
            align='middle'
            justify='space-between'
          >
            <Col>
              <span className='_uppercase'>Signed by:</span>{' '}
              <span id='signee'>
                {latestData?.first_name} {latestData?.last_name}
              </span>{' '}
              on{' '}
              <strong>
                {moment(latestData?.inserted_at).format('D MMM YYYY')}
              </strong>
            </Col>
            <Col>
              <span className='_uppercase'>Last updated:</span>{' '}
              <strong className='_ml-12'>
                {moment(termDate).format('D MMM YYYY')}
              </strong>
            </Col>
          </Row>
          <Row className='terms general-settings' justify='center'>
            <Col span={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(term, {
                    USE_PROFILES: { html: true },
                    ADD_ATTR: ['target']
                  })
                }}
              />
            </Col>
          </Row>
        </>
      )) || <Loading />}
    </>
  )
}

export default TermsAndConditions
