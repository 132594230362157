import React, { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { uuid } from 'uuidv4'
import { Tabs } from 'antd'
import { Result, Skeleton } from 'antd'
import OrderSteps from './OrderSteps'
import OrderActions from './OrderActions'
import {
  ChargesInformation,
  BasicInformation,
  DeliveryInformation,
  PaymentInformation,
  PickupInformation
} from './Tab'
import { SUBSCRIPTION_ORDER } from 'components/Orders/OrderQueries'
import { OrderInfo } from './type'
import RefundForm from 'components/Orders/RefundForm'

interface OrderInformationProps {
  transactionId: string
  refetchCallback: () => void
  setFooterEntries?: (entries: React.ReactNode | React.ReactNode[]) => void
}

const { TabPane } = Tabs
const OrderInformation = ({
  transactionId,
  refetchCallback,
  setFooterEntries
}: OrderInformationProps) => {
  const [order, setOrder] = useState<OrderInfo>(null)
  const [showRefundForm, setShowRefundForm] = useState<boolean>(false)
  const { data: orderData, loading: fetchingOrderData } = useSubscription(
    SUBSCRIPTION_ORDER,
    {
      variables: { transactionId }
    }
  )

  useEffect(() => {
    if (orderData) {
      const { orders } = orderData
      setOrder(orders[0])
    }
  }, [orderData])

  const handleTabsChange = (activeKey: string) => {
    if (!setFooterEntries) {
      return
    }

    if (activeKey === '4') {
      setFooterEntries(
        <p className='order-drawer-footer'>
          * Download the Additional Charges report for more details
        </p>
      )

      return
    }

    setFooterEntries(null)
  }

  return (
    <>
      {fetchingOrderData && <Skeleton active />}
      {orderData && orderData.message === null && (
        <Result status='500' title='500' />
      )}
      {order && (
        <>
          <div className='order-header' data-testid='order-info-steps-actions'>
            <OrderSteps order={order} />
            <OrderActions
              order={order}
              successCallback={() => {
                refetchCallback()
              }}
              setShowRefundForm={setShowRefundForm}
            />
          </div>
          {showRefundForm && (
            <RefundForm
              key={uuid()}
              discardCallback={() => setShowRefundForm(false)}
              transactionId={order.transaction_id}
              deliveries={[order.delivery, ...order.additional_couriers]}
              successCallback={() => {
                setShowRefundForm(false)
                refetchCallback()
              }}
            />
          )}
          <Tabs
            defaultActiveKey='1'
            data-testid='order-info-tabs'
            onChange={handleTabsChange}
          >
            <TabPane tab='Info' key='1'>
              <BasicInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
            {order.fulfillment_type === 'delivery' && (
              <TabPane
                tab='Delivery'
                key='2'
                data-testid='order-info-tabs-delivery'
              >
                <DeliveryInformation
                  order={order}
                  reloadOrder={() => {
                    refetchCallback()
                  }}
                />
              </TabPane>
            )}
            {order.fulfillment_type === 'pickup' && (
              <TabPane tab='Pickup' key='2'>
                <PickupInformation order={order} />
              </TabPane>
            )}
            <TabPane tab='Payment' key='3'>
              <PaymentInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
            <TabPane tab='Additional Charges' key='4'>
              <ChargesInformation
                order={order}
                reloadOrder={() => {
                  refetchCallback()
                }}
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  )
}

export default OrderInformation
