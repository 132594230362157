import ReactPixel from 'react-facebook-pixel'
import env from '../../../env'

export const initializePixel = () => {
  ReactPixel.init(env.PIXEL_ID)
}

export const PixelPageViewTracking = () => {
  ReactPixel.pageView()
}

export const trackPixelLead = () => {
  ReactPixel.trackCustom('Lead', {
    category: 'Onboarding'
  })
}
