import React from 'react'
import { Button, Form, Input, Row, Col, Divider, PageHeader } from 'antd'
import AddressInput from './AddressInput'
import { useSession } from '@slerp/accounts'
import { useApolloClient } from '@apollo/client'
import AddressForm from 'components/Locations/GeneralSettings/Forms/AddressForm'
import {
  UKPhoneValidatorRule,
  UniqueLocationNameValidatorRule,
  noAddressSelectedRule
} from './rules'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

export interface AddressHash {
  validateTrigger: boolean
  noSuggestionSelected: boolean
  formattedAddress: string
  line1: string
  line2: string
  city: string
  country: string
  postalCode: string
  lat: number
  lng: number
}

const DetailsForm = (props: any) => {
  const { merchant } = useSession()
  const [form] = Form.useForm()
  const client = useApolloClient()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 }
  }

  const updateAddressFields = (addressHash: AddressHash) => {
    form.setFieldsValue({
      address_line_1: addressHash.line1,
      address_line_2: addressHash.line2,
      address_city: addressHash.city,
      address_postcode: addressHash.postalCode,
      address_country: addressHash.country,
      address_lat: addressHash.lat,
      address_lng: addressHash.lng
    })
  }

  return (
    <Form
      {...layout}
      form={form}
      name='detailsForm'
      initialValues={props.formData}
      labelCol={{ span: 24 }}
      colon={false}
    >
      <PageHeader title='Location Details' className='settings-title' />
      <Form.Item
        name='storeName'
        label='Name:'
        rules={[
          {
            required: true,
            message: 'Location name is required'
          },
          UniqueLocationNameValidatorRule(client, merchant.id)
        ]}
        required
      >
        <Input placeholder='Location name' />
      </Form.Item>
      <Form.Item
        name='email'
        label='Email:'
        rules={[
          {
            required: true,
            message: 'Email is required'
          },
          {
            type: 'email',
            message: 'Please input a valid email'
          }
        ]}
        required
      >
        <Input placeholder='Location email' />
      </Form.Item>
      <Form.Item label='Contact number:'>
        <Input.Group>
          <Row gutter={8} className='_mb-0'>
            <Col span={5}>
              <Input defaultValue='+44' disabled />
            </Col>
            <Col span={19}>
              <Form.Item
                name='contactNumber'
                noStyle
                rules={[UKPhoneValidatorRule]}
                validateTrigger='onBlur'
              >
                <Input
                  placeholder='7911123456'
                  type='number'
                  onFocus={onFocusScrollNumber}
                  onBlur={onBlurScrollNumber}
                />
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <Form.Item name='address' label='Address:' rules={noAddressSelectedRule}>
        <AddressInput onSelectCallback={updateAddressFields} />
      </Form.Item>
      <AddressForm addressLineBlurCallback={() => form.validateFields()} />
      <Form.Item
        name='courierInstructions'
        label='Courier instructions:'
        className='_mb-48'
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Divider />
      <Col>
        <Button htmlType='submit' className='_center-vertical _ml-auto'>
          Next
        </Button>
      </Col>
    </Form>
  )
}

export default DetailsForm
