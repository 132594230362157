import React from 'react'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Row from 'antd/lib/row'
import { maximumTimePeriodRule } from './Rules'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

interface TimePeriodProps {
  disabled?: boolean
  timePeriodUnitChangeHandler: () => void
}

const TimePeriod = ({
  disabled = false,
  timePeriodUnitChangeHandler
}: TimePeriodProps) => {
  return (
    <>
      <Input.Group>
        <Row gutter={8}>
          <Col span={2}>
            <Form.Item name='time_period_amount' rules={maximumTimePeriodRule}>
              <Input
                type='number'
                required
                min={1}
                disabled={disabled}
                onFocus={onFocusScrollNumber}
                onBlur={onBlurScrollNumber}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='time_period_unit'>
              <Select
                disabled={disabled}
                onChange={timePeriodUnitChangeHandler}
              >
                <Select.Option value='day'>Day(s)</Select.Option>
                <Select.Option value='week'>Week(s)</Select.Option>
                <Select.Option value='month'>Month(s)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
    </>
  )
}

export default TimePeriod
