import React from 'react'
import { useSession } from '@slerp/accounts'
import { ASSET_HOST } from '@slerp/client'
import ExportFilters from './ExportFilters'

const StatementsFilters = () => {
  const { merchant } = useSession()

  return (
    <ExportFilters
      url={`https://${merchant.slug}.${ASSET_HOST}/controls/payouts`}
      datePickerLabel='Payout'
    />
  )
}

export default StatementsFilters
