import React from 'react'
import { OrderInfo } from '../type'
import { Col } from 'antd'
import RefundButton from './RefundButton'

interface FulfilledType {
  order: OrderInfo
  setShowRefundForm: (value?: any) => void
}

const Fulfilled = ({ order, setShowRefundForm }: FulfilledType) => {
  return (
    <Col>
      <RefundButton order={order} onClick={() => setShowRefundForm(true)} />
    </Col>
  )
}

export default Fulfilled
