import gql from 'graphql-tag'

export const GET_MERCHANT_THEME = gql`
  query getmerchant($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      theme
    }
  }
`

export const MUTATE_UPDATE_THEME = gql`
  mutation updateTheme($domain: String!, $theme: jsonb!) {
    update_merchants(
      where: { slug: { _eq: $domain } }
      _set: { theme: $theme }
    ) {
      returning {
        id
        theme
      }
    }
  }
`
