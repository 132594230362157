import { useQuery } from '@apollo/client'
import { GET_ZERO_ORDER_COMMISSION_RATES } from './queries'
import { DEFAULT_COMMISSION_RATES } from './utils'
import { Response } from './types'

const useGetZeroOrderCommissionRates = ({
  zeroOrderRateId
}: {
  zeroOrderRateId: string
}): Response => {
  const { data, loading, refetch, error } = useQuery(
    GET_ZERO_ORDER_COMMISSION_RATES,
    {
      variables: {
        zeroOrderRateId
      },
      fetchPolicy: 'no-cache'
    }
  )

  const zeroCommissionRate =
    data?.zero_order_rates?.[0] || DEFAULT_COMMISSION_RATES

  return [zeroCommissionRate, { loading, refetch, error }]
}

export default useGetZeroOrderCommissionRates
