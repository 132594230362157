import React, { lazy, Suspense } from 'react'
import { Drawer } from 'antd'
import { LoyaltyCardType, LoyaltyRewardType, StoreType } from './types'
import Spin from 'antd/lib/spin'
interface LoyaltyDrawerProps {
  activeCard: LoyaltyCardType | null
  activeRewards: LoyaltyRewardType[]
  stores: StoreType[]
  visible: boolean
  onClose: () => void
  onDrawerClose: () => void
}

const LoyaltyCardForm = lazy(() => import('./LoyaltyCardForm'))

const LoyaltyDrawer = (props: LoyaltyDrawerProps) => {
  const { activeCard, activeRewards, onClose, onDrawerClose, stores, visible } =
    props

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      className='order-drawer'
      title='Loyalty Card Settings'
      destroyOnClose={true}
    >
      <Suspense fallback={<Spin />}>
        <LoyaltyCardForm
          activeCard={activeCard}
          activeRewards={activeRewards}
          stores={stores}
          onDrawerClose={onDrawerClose}
        />
      </Suspense>
    </Drawer>
  )
}

export default LoyaltyDrawer
