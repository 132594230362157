interface PreorderSetting {
  pickup_enabled: boolean
  delivery_enabled: boolean
}

interface StoreFlag {
  id: string
  slug: string
  name: string
  merchant_id: string
  same_day_pickup: boolean
  same_day_delivery: boolean
  table_enabled: boolean
  pre_order_settings: PreorderSetting[]
}

const samedayDeliveryStores = (stores: StoreFlag[]) =>
  stores.filter((store: StoreFlag) => store.same_day_delivery)

const preorderDeliveryStores = (stores: StoreFlag[]) =>
  stores
    .filter(({ pre_order_settings }) => pre_order_settings[0] != null) // get all stores with pre_order_settings
    .filter(({ pre_order_settings }) => pre_order_settings[0].delivery_enabled) // check if delivery is enabled

const samedayPickupStores = (stores: StoreFlag[]) =>
  stores.filter((store: StoreFlag) => store.same_day_pickup)

const preorderPickupStores = (stores: StoreFlag[]) =>
  stores
    .filter(({ pre_order_settings }) => pre_order_settings[0] != null) // get all stores with pre_order_settings
    .filter(({ pre_order_settings }) => pre_order_settings[0].pickup_enabled) // check if delivery is enabled

const orderAtTableStores = (stores: StoreFlag[]) =>
  stores.filter((store: StoreFlag) => store.table_enabled)

const storesSettingsFlags = (stores: StoreFlag[]) => {
  const anyStoresWithDeliveryEnabled =
    !!samedayDeliveryStores(stores).length ||
    !!preorderDeliveryStores(stores).length
  const anyStoresWithPickupEnabled =
    !!samedayPickupStores(stores).length ||
    !!preorderPickupStores(stores).length
  const anyStoresWithOrderAtTableEnabled = !!orderAtTableStores(stores).length

  return {
    anyStoresWithDeliveryEnabled,
    anyStoresWithPickupEnabled,
    anyStoresWithOrderAtTableEnabled
  }
}

export { storesSettingsFlags }
