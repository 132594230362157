export const theme = {
  colors: {
    white: '#ffffff',

    gray50: '#F9FAFB',
    gray100: '#F3F4F6',
    gray200: '#E5E7EB',
    gray300: '#D1D5DB',
    gray400: '#9CA3AF',
    gray500: '#6B7280',
    gray600: '#4B5563',
    gray700: '#374151',
    gray800: '#1F2937',
    gray900: '#111827',
    grayGradient: `linear-gradient(112.83deg, #4B5563 -56.48%, rgba(255, 255, 255, 0) 37.41%, #6B7280 87.41%), #4B5563`,

    violet50: '#F5F3FF',
    violet100: '#EDE9FE',
    violet200: '#DDD6FE',
    violet300: '#C4B5FD',
    violet400: '#A78BFA',
    violet500: '#8B5CF6',
    violet600: '#7C3AED',
    violet700: '#6D28D9',
    violet800: '#5B21B6',
    violet900: '#4C1D95',

    green: '#57CD82',
    blue: '#32B5FF',
    primary: '#D092DD',
    secondary: '#4A4A68',
    secondary75: '#77778E',
    subtitle: '#8C8CA1',
    background10: '#FAFAFB',
    background25: '#F3F2F4',
    background50: '#E7E5E9',
    background75: '#DBD9DF',
    text: '#4A4A68'
  },

  boxShadows: {
    elevation: `0px 2px 2px rgba(0, 0, 0, 0.05)`
  },

  fontSizes: {
    sm: '0.75rem',
    md: '0.825rem',
    lg: '1rem',
    xl: '1.5rem',
    xl2: '1.75rem',
    xl3: '2.25rem'
  },

  font: {
    sans: `"poppins", sans-serif`
  },

  zIndex: {
    uploaderImage: 48,
    uploaderOverlay: 49,
    uploaderBadge: 50,
    select: 50,
    selectArrow: 51,
    content: 99,
    sidebar: 1,
    dropdown: 120,
    tooltip: 1000,
    autosave: 1100
  },

  input: {
    height: 36
  }
}
