import { Decimal } from 'decimal.js'

export const computeVat = (netPrice: number, vatPercentage: number) => {
  const decimalNetPrice = new Decimal(netPrice)
  const decimalVat = new Decimal(vatPercentage).dividedBy(new Decimal(100))
  return decimalNetPrice.times(decimalVat)
}

const formatDecimalPrice = (decimalPrice: Decimal) => decimalPrice.toNumber()

export const computeNetPrice = (grossPrice: number, vatPercentage: number) => {
  const decimalGrossPrice = new Decimal(grossPrice)
  const decimalVat = new Decimal(vatPercentage).dividedBy(new Decimal(100))
  const decimalNetPrice = decimalGrossPrice.dividedBy(
    decimalVat.plus(new Decimal(1))
  )

  return formatDecimalPrice(decimalNetPrice)
}

export const computeGrossPrice = (netPrice: number, vatPercentage: number) => {
  const decimalNetPrice = new Decimal(netPrice)
  const decimalTotalVat = computeVat(netPrice, vatPercentage)
  const decimalGrossPrice = decimalNetPrice.plus(decimalTotalVat)

  return formatDecimalPrice(decimalGrossPrice)
}

export const onFocusScrollNumber = (e: React.ChangeEvent<HTMLInputElement>) =>
  e.target.addEventListener('wheel', (e: Event) => e.preventDefault(), {
    passive: false
  })

export const onBlurScrollNumber = (e: React.ChangeEvent<HTMLInputElement>) =>
  e.target.removeEventListener('wheel', (e: Event) => e.preventDefault())
