import React, { useState, useEffect } from 'react'
import isNumber from 'lodash/isNumber'
import partition from 'lodash/partition'
import isEmpty from 'lodash/isEmpty'
import { uuid } from 'uuidv4'
import {
  Avatar,
  Col,
  Checkbox,
  Drawer,
  Divider,
  InputNumber,
  message,
  Skeleton,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  Row
} from 'antd'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import {
  GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_PREORDER_INVENTORY_BY_PRODUCT_NAME,
  GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_SAMEDAY_INVENTORY_BY_PRODUCT_NAME,
  GET_STORE_VARIANT_PRODUCT_PRICINGS,
  UPSERT_STORE_VARIANT_CORE_PRICING,
  DELETE_LOCATION_FULFILMENT_TYPE_PRICINGS,
  GET_PRODUCT_BASIC_INFO_BY_STORE_VARIANT_ID
} from './Queries'
import Actions from './Actions'
import CorePricingRow from './CorePricingRow'
import FulfillmentTypePricingForm from './Form/FulfillmentTypePricingForm'
import { getVariantCorePricing } from './utils'
import { ProductVariant, Store, Pricing } from './types'
import { computeNetPrice, computeGrossPrice } from 'components/Utils/price'
import { BulbOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import errorReducer from 'errors/errorReducer'

interface DrawerProps {
  visible: boolean
  storeVariantId: string
  storeId: string
  productId: string
  variantName: string
  onClose: () => void
  isPreorder: boolean
}

const { TabPane } = Tabs
const { Text } = Typography

const PricingDrawer = ({
  visible,
  storeVariantId,
  storeId,
  productId,
  variantName,
  onClose,
  isPreorder
}: DrawerProps) => {
  const client = useApolloClient()
  const [productVariant, setProductVariant] = useState<ProductVariant>()
  const [store, setStore] = useState<Store>()
  const [corePricing, setCorePricing] = useState<Pricing>()
  const [fulfillmentTypePricings, setFulfillmentTypePricings] = useState<
    Pricing[]
  >([])
  const [grossPrice, setGrossPrice] = useState<number | null>(null)
  const [defaultVat, setDefaultVat] = useState<number | null>(null)
  const [
    applyLocationPricingToOtherInventory,
    setApplyLocationPricingToOtherInventory
  ] = useState<boolean>(false)
  const [
    applyFulfillmentTypePricingToOtherInventory,
    setApplyFulfillmentTypePricingToOtherInventory
  ] = useState<boolean>(false)

  const [oatDefaultVat, setOatDefaultVat] = useState<number | null>(null)
  const [showGlobalPrice, setShowGlobalPrice] = useState<boolean>(false)
  const [showGlobalFulfillmentTypePrice, setShowGlobalFulfillmentTypePrice] =
    useState<boolean>(false)
  const [showFulfillmentTypePricing, setShowFulfillmentTypePricing] =
    useState<boolean>(false)
  const [showCustomVatForOat, setShowCustomVatForOat] = useState<boolean>(false)
  const [upsertStoreVariantCorePricing, { loading: upsertingPricing }] =
    useMutation(UPSERT_STORE_VARIANT_CORE_PRICING)
  const [deleteLocationFulfilmentTypePricing] = useMutation(
    DELETE_LOCATION_FULFILMENT_TYPE_PRICINGS
  )
  const { data: storeVariantProductPricingsData, loading } = useQuery(
    GET_STORE_VARIANT_PRODUCT_PRICINGS,
    {
      variables: { storeVariantId },
      fetchPolicy: 'no-cache'
    }
  )
  const { data: variantBasicInfoData, loading: fetchingVariantInfo } = useQuery(
    GET_PRODUCT_BASIC_INFO_BY_STORE_VARIANT_ID,
    {
      variables: { storeVariantId: storeVariantId },
      fetchPolicy: 'no-cache'
    }
  )

  const variantBasicInfo =
    variantBasicInfoData?.store_variants_by_pk?.product_variant || {}

  const { data: storeVariantFromOppositeInventory } = useQuery(
    isPreorder
      ? GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_SAMEDAY_INVENTORY_BY_PRODUCT_NAME
      : GET_STORE_VARIANT_ID_AND_PRICINGS_FROM_PREORDER_INVENTORY_BY_PRODUCT_NAME,
    {
      variables: {
        storeId: storeId,
        variantName: variantName,
        productId: productId
      },
      fetchPolicy: 'no-cache'
    }
  )

  const oatEnabled =
    storeVariantProductPricingsData?.store_variants[0]?.store?.table_enabled ||
    false
  const oatPricing = fulfillmentTypePricings.find(
    (pricing: Pricing) => pricing.fulfillment_type === 'order_at_table'
  )

  const updateFulfillmentTypePricingsVat = () => {
    const updatedPricings = [...fulfillmentTypePricings].map(
      (fulfillmentTypePricing: Pricing) => {
        const {
          id,
          price_with_vat,
          price,
          fulfillment_type,
          variant_id,
          store_variant_id
        } = fulfillmentTypePricing

        const vat =
          showCustomVatForOat && fulfillment_type === 'order_at_table'
            ? oatDefaultVat || 0
            : defaultVat || 0

        return {
          id: id ? id : uuid(),
          price_with_vat,
          vat,
          price: price ? computeNetPrice(price_with_vat, vat || 0) : null,
          fulfillment_type,
          variant_id,
          store_variant_id,
          inserted_at: 'now()',
          updated_at: 'now()',
          published_at: 'now()'
        }
      }
    )
    setFulfillmentTypePricings(updatedPricings)
  }

  useEffect(() => {
    if (oatPricing && corePricing) {
      setShowCustomVatForOat(oatPricing?.vat !== corePricing?.vat)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oatPricing, corePricing])

  useEffect(() => {
    if (storeVariantProductPricingsData && storeVariantFromOppositeInventory) {
      const { product_variant, store, storeByPreorderStoreId } =
        storeVariantProductPricingsData.store_variants[0] || {}
      const variantCorePricing = getVariantCorePricing(
        product_variant,
        storeVariantId
      )
      const variantFulfillmentTypePricings =
        product_variant.location_fulfillment_type_pricing?.filter(
          (pricing: Pricing) => pricing.fulfillment_type !== null
        ) || []
      const oatVariantPricing = variantFulfillmentTypePricings.find(
        (pricing: Pricing) => pricing.fulfillment_type === 'order_at_table'
      )
      const { vat, price_with_vat } = variantCorePricing

      /*
      const withVariantPricing = !isEmpty(
        variantFulfillmentTypePricings.find(
          (pricing: Pricing) => pricing.published_at != null
        )
      )
         */
      const withVariantPricing = !isEmpty(variantFulfillmentTypePricings)

      setShowFulfillmentTypePricing(withVariantPricing)
      setProductVariant(product_variant)
      setStore(store || storeByPreorderStoreId)
      setCorePricing(variantCorePricing)
      setFulfillmentTypePricings(variantFulfillmentTypePricings)
      setOatDefaultVat(oatVariantPricing?.vat || 0)
      setDefaultVat(vat)
      setGrossPrice(price_with_vat)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeVariantProductPricingsData, storeVariantFromOppositeInventory])

  useEffect(() => {
    const priceWithoutVat =
      grossPrice !== null && defaultVat !== null
        ? computeNetPrice(grossPrice, defaultVat)
        : null
    setCorePricing((prevState: Pricing) => ({
      ...prevState,
      price: priceWithoutVat,
      price_with_vat: grossPrice,
      vat: defaultVat
    }))
    updateFulfillmentTypePricingsVat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grossPrice, defaultVat])

  useEffect(() => {
    if (fulfillmentTypePricings && !!fulfillmentTypePricings.length) {
      const [[pricing], rest] = partition(
        fulfillmentTypePricings,
        (pricing) => pricing.fulfillment_type === 'order_at_table'
      )

      const vat = showCustomVatForOat
        ? oatDefaultVat || 0
        : corePricing.vat || 0

      const updatedPricing = {
        id: pricing ? pricing.id : uuid(),
        price_with_vat: pricing?.price_with_vat,
        vat,
        price: pricing?.price_with_vat
          ? computeNetPrice(pricing?.price_with_vat, vat || 0)
          : null,
        fulfillment_type: 'order_at_table',
        variant_id: productVariant.id,
        store_variant_id: storeVariantId,
        inserted_at: 'now()',
        updated_at: 'now()',
        published_at: 'now()'
      }
      setFulfillmentTypePricings([...rest, updatedPricing])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oatDefaultVat, corePricing])

  const savePricing = () => {
    const otherInventoryStoreVariantId: string =
      storeVariantFromOppositeInventory?.store_variants[0]?.id
    const [[otherCorePricing], otherFulfillmentPricings] = partition(
      storeVariantFromOppositeInventory?.store_variants[0]?.pricings,
      (pricing) => pricing.fulfillment_type === null
    )
    const otherInventoryFulfillmentTypePricings =
      applyFulfillmentTypePricingToOtherInventory
        ? fulfillmentTypePricings.map((pricing) => {
            const existingPricing = otherFulfillmentPricings.find(
              (otherPricing) =>
                otherPricing.fulfillment_type === pricing.fulfillment_type
            )

            return {
              ...pricing,
              id: existingPricing?.id || uuid(),
              store_variant_id: otherInventoryStoreVariantId,
              published_at: showFulfillmentTypePricing ? 'now()' : null
            }
          })
        : []
    const otherInventoryStoreVariantCorePricing =
      applyLocationPricingToOtherInventory
        ? [
            {
              ...corePricing,
              id: otherCorePricing?.id || uuid(),
              store_variant_id: otherInventoryStoreVariantId,
              inserted_at: 'now()',
              updated_at: 'now()',
              published_at: 'now()'
            }
          ]
        : []

    const newFulfillmentTypePricings = fulfillmentTypePricings.map(
      (fulfillmentTypePrice: Pricing) => ({
        ...fulfillmentTypePrice,
        published_at: showFulfillmentTypePricing ? 'now()' : null,
        updated_at: 'now()'
      })
    )

    upsertStoreVariantCorePricing({
      variables: {
        values: [
          ...newFulfillmentTypePricings,
          {
            ...corePricing,
            inserted_at: 'now()',
            updated_at: 'now()',
            published_at: 'now()'
          },
          ...otherInventoryFulfillmentTypePricings,
          ...otherInventoryStoreVariantCorePricing
        ]
      }
    })
      .then((res) => {
        message.destroy()
        message.success('Your pricing changes have been saved.')
        client.cache.reset()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Product',
          data: {
            error: error,
            message: error.message
          }
        })
      })
  }

  const toggleLocationFulfilmentTypePricings = (setToPublish: boolean) => {
    const newFulfillmentTypePricings = fulfillmentTypePricings.map(
      (fulfillmentTypePrice: Pricing) => ({
        ...fulfillmentTypePrice,
        published_at: setToPublish ? 'now()' : null,
        updated_at: 'now()'
      })
    )

    if (!setToPublish) {
      deleteLocationFulfilmentTypePricing({
        variables: {
          pricingIds: newFulfillmentTypePricings.map(({ id }: Pricing) => id)
        }
      })
        .then((res) => {
          setFulfillmentTypePricings([])
          client.cache.reset()
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Product',
            data: {
              error: error,
              message: error.message
            }
          })
        })
    }
  }

  const toggleCustomVatForOatHandler = (val: boolean) => {
    if (!val) setOatDefaultVat(null)
    setShowCustomVatForOat(val)
  }

  const toggleDisplayFulfillmentTypePricing = () => {
    const currentValue = showFulfillmentTypePricing
    setShowFulfillmentTypePricing(!currentValue)
    toggleLocationFulfilmentTypePricings(!currentValue)
  }

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      width={708}
      className='location-inventory-pricings-drawer'
    >
      {loading && <Skeleton active />}
      {productVariant && corePricing && (
        <div className='location-inventory-pricings'>
          <div className='location-inventory-pricings-header'>
            <div
              className='location-inventory-pricings-title'
              data-testid='location-inventory-pricings-product-name-header'
            >
              {productVariant.name}
            </div>
            <Actions
              saveHandler={savePricing}
              discardHandler={onClose}
              loading={upsertingPricing}
            />
          </div>
          <Tabs defaultActiveKey='1'>
            <TabPane
              tab={
                <>
                  Pricing
                  <Tooltip
                    title={
                      <span>
                        The pricing tab in the location inventory allows you to
                        assign a price unique to this location. It will override
                        any pricing given beforehand, unless removed.
                      </span>
                    }
                    placement='bottom'
                  >
                    <QuestionCircleOutlined
                      className='_mr-0 _ml-8'
                      style={{ marginRight: '-8px' }}
                    />
                  </Tooltip>
                </>
              }
              key='1'
            >
              <Row
                className='_p-10 _bg-gray-3 _br-4'
                align='middle'
                gutter={[8, 8]}
              >
                <Col>
                  <Avatar
                    size={32}
                    style={{
                      color: '#ffffff',
                      backgroundColor: '#d092dd'
                    }}
                    icon={<BulbOutlined />}
                  />
                </Col>
                <Col data-testid='location-inventory-pricings-applying-location-note'>
                  Any changes will be applied only to
                  <span className='_cl-primary'> {store.name} </span>
                  store
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Text strong>Your product price</Text>
                </Col>
                <Col span={12}>
                  <Row className='_mb-0' justify='end' gutter={[16, 0]}>
                    <Col>Show global pricing</Col>
                    <Col className='_pr-10'>
                      <Switch onChange={setShowGlobalPrice} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <CorePricingRow
                disabled={upsertingPricing}
                variantId={productVariant.id}
                variantBasicInfo={variantBasicInfo}
                globalPricing={{
                  price_with_vat: computeGrossPrice(
                    productVariant.price,
                    productVariant.vat
                  ),
                  price_without_vat: productVariant.price,
                  vat: productVariant.vat
                }}
                pricing={corePricing}
                tableEnabled={
                  storeVariantProductPricingsData?.store_variants[0]?.store
                    ?.table_enabled || false
                }
                isPreorder={isPreorder}
                showGlobalPrice={showGlobalPrice}
                updateGrossPriceHandler={setGrossPrice}
                updateDefaultVatHandler={setDefaultVat}
                reload={() => client.resetStore()}
              />
              <Row
                className='_p-10 _br-4'
                align='middle'
                justify='end'
                gutter={[8, 8]}
              >
                <Checkbox
                  className='_mr-12'
                  data-testid='apply-core-location-pricing-to-other-inventory'
                  onChange={(e) =>
                    setApplyLocationPricingToOtherInventory(e.target.checked)
                  }
                >
                  Apply to {isPreorder ? 'same day' : 'pre-order'} inventory
                </Checkbox>
              </Row>

              <Divider className='_mt-32 _mb-32' />

              <Row>
                <Col className='_pl-4 _pr-4' span={2}>
                  <Switch
                    onChange={toggleDisplayFulfillmentTypePricing}
                    defaultChecked={showFulfillmentTypePricing}
                  />
                </Col>
                <Col span={9} className='_pl-4 _pr-4'>
                  <Text>Assign price by fulfilment type</Text>
                </Col>
                <Col span={12}>
                  <Row className='_mb-0' justify='end' gutter={[16, 0]}>
                    <Col>*price including VAT</Col>
                  </Row>
                </Col>
              </Row>
              {showFulfillmentTypePricing && (
                <>
                  {oatEnabled && (
                    <Row>
                      <Col className='_pl-4 _pr-4' span={2}>
                        <Switch
                          checked={showCustomVatForOat}
                          defaultChecked={
                            oatPricing && oatPricing?.vat !== corePricing?.vat
                          }
                          onChange={(val: boolean) =>
                            toggleCustomVatForOatHandler(val)
                          }
                        />
                      </Col>
                      <Col span={9} className='_pl-4 _pr-4'>
                        <Text>Different VAT for Order at table</Text>
                      </Col>
                      <Col span={12}>
                        {showCustomVatForOat && (
                          <Row className='_mb-0' justify='end' gutter={[16, 0]}>
                            <Col>
                              <InputNumber
                                defaultValue={oatPricing?.vat || 0}
                                disabled={!isNumber(oatPricing?.price)}
                                min={0}
                                className='width-100'
                                data-testid='product-form-vat'
                                onChange={(
                                  val: string | number | undefined
                                ) => {
                                  setOatDefaultVat(val)
                                }}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col span={12}>
                      <Text strong>Your products fulfilment type prices</Text>
                    </Col>
                    <Col span={12}>
                      <Row className='_mb-0' justify='end' gutter={[16, 0]}>
                        <Col>Show global fulfilment type pricing</Col>
                        <Col className='_pr-10'>
                          <Switch
                            onChange={setShowGlobalFulfillmentTypePrice}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <FulfillmentTypePricingForm
                    oatDefaultVat={oatDefaultVat}
                    customOatVat={showCustomVatForOat}
                    variantId={productVariant.id}
                    storeVariantId={storeVariantId}
                    variantBasicInfo={variantBasicInfo}
                    corePricing={corePricing}
                    fulfillmentTypePricings={fulfillmentTypePricings}
                    tableEnabled={oatEnabled}
                    updateFulfillmentTypePricings={setFulfillmentTypePricings}
                    showGlobalFulfillmentTypePrice={
                      showGlobalFulfillmentTypePrice
                    }
                    globalFulfillmentTypePricing={
                      productVariant.global_ft_pricing
                    }
                  />
                  <Row
                    className='_p-10 _br-4'
                    align='middle'
                    justify='end'
                    gutter={[8, 8]}
                  >
                    <Checkbox
                      className='_mr-12'
                      data-testid='apply-location-fulfillment-type-pricings-to-other-inventory'
                      onChange={(e) =>
                        setApplyFulfillmentTypePricingToOtherInventory(
                          e.target.checked
                        )
                      }
                    >
                      Apply to {isPreorder ? 'same day' : 'pre-order'} inventory
                    </Checkbox>
                  </Row>
                </>
              )}
            </TabPane>
          </Tabs>
        </div>
      )}
    </Drawer>
  )
}

export default PricingDrawer
