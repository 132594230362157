import React from 'react'
import styled from '@emotion/styled'
import { ThemeType } from '../../contexts/Theme'

interface Props {
  as?: string
  size?: keyof ThemeType['fontSizes']
  color?: keyof ThemeType['colors']
  weight?: string
  uppercased?: boolean
}

export const Text: React.FC<Props> = ({
  as,
  size,
  color,
  weight,
  uppercased,
  children
}) => {
  return (
    <StyledText
      size={size}
      color={color}
      weight={weight}
      uppercased={uppercased}
      as={as}
    >
      {children}
    </StyledText>
  )
}

const StyledText = styled.span<Props>`
  font-family: ${({ theme }: any) => theme.font.sans};
  font-size: ${({ theme, size }: any) => theme.fontSizes[size || 'sm']};
  ${({ theme, color }: any) =>
    color && `color: ${theme.colors[color || 'gray600']}`};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  ${({ uppercased }) => uppercased && 'text-transform: uppercase;'}
  line-height: 1;

  @media (min-width: 1025px) {
    font-size: ${({ theme, size }: any) => theme.fontSizes[size || 'md']};
  }
`
