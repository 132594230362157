import React, { useEffect, useRef } from 'react'
import { useShop } from '../../contexts/Shop'
import { useIframe } from '../../contexts/Iframe'
import { HOST, useSlerp } from 'packages/@slerp/client'
import { useApp } from '../../contexts/App'

export const Shop = (props: any) => {
  const { store } = useShop()
  const { canvasMaxHeight } = useApp()

  const { iframeKey } = useIframe()
  const { user } = useSlerp()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const url = `https://${user.merchant.slug}.${HOST}/order/store/${store.slug}`

  useEffect(() => {
    iframeRef.current?.contentWindow?.postMessage('Trigger refetch menu', url)
  }, [])

  return (
    <>
      <iframe
        src={url}
        height={canvasMaxHeight}
        width={'100%'}
        key={iframeKey}
        id='shopIframe'
        ref={iframeRef}
      />
    </>
  )
}

export default Shop
