import { gql } from '@apollo/client'

export const CREATE_SETTINGS = gql`
  mutation createPreOrderSettings(
    $id: uuid!
    $store_id: uuid!
    $pickup_enabled: Boolean = false
    $delivery_enabled: Boolean = false
    $pickup_interval: Int
    $pickup_times: String
    $pickup_schedule: jsonb
    $pickup_timeslots_order_limit: Int
    $cutoffs: jsonb
    $special_cutoffs_map: jsonb
    $pre_order_day_allowance: Int
    $pickup_preorder_mov: numeric
  ) {
    insert_pre_order_settings_one(
      object: {
        id: $id
        store_id: $store_id
        pickup_enabled: $pickup_enabled
        pickup_interval: $pickup_interval
        pickup_times: $pickup_times
        pickup_schedule: $pickup_schedule
        pickup_timeslots_order_limit: $pickup_timeslots_order_limit
        delivery_enabled: $delivery_enabled
        cutoffs: $cutoffs
        special_cutoffs_map: $special_cutoffs_map
        pre_order_day_allowance: $pre_order_day_allowance
      }
    ) {
      id
    }

    update_stores(
      where: { id: { _eq: $store_id } }
      _set: { preorder_pickup_minimum_order_value: $pickup_preorder_mov }
    ) {
      affected_rows
    }
  }
`

export const CREATE_DELIVERY_SETTINGS = gql`
  mutation createPreOrderSettings(
    $id: uuid!
    $store_id: uuid!
    $delivery_enabled: Boolean = false
    $pickup_enabled: Boolean = false
    $delivery_courier_partner: Boolean
    $fixed_courier_fee_enabled: Boolean
    $custom_fixed_fee: numeric
    $delivery_interval: Int
    $fixed_delivery_times_enabled: Boolean
    $delivery_times: String
    $delivery_schedule: jsonb
    $delivery_zone: String
    $fixed_delivery_times_order_limit: Int
    $delivery_timeslots_order_limit: Int
    $settings: jsonb!
    $preorder_delivery_minimum_order_value: numeric
    $courier_fee_vat_percentage: numeric
  ) {
    update_stores(
      where: { id: { _eq: $store_id } }
      _append: { settings: $settings }
      _set: {
        preorder_delivery_minimum_order_value: $preorder_delivery_minimum_order_value
      }
    ) {
      affected_rows
      returning {
        settings
      }
    }
    insert_pre_order_settings_one(
      object: {
        id: $id
        store_id: $store_id
        delivery_enabled: $delivery_enabled
        delivery_courier_partner: $delivery_courier_partner
        pickup_enabled: $pickup_enabled
        fixed_courier_fee_enabled: $fixed_courier_fee_enabled
        custom_fixed_fee: $custom_fixed_fee
        delivery_interval: $delivery_interval
        fixed_delivery_times_enabled: $fixed_delivery_times_enabled
        delivery_times: $delivery_times
        delivery_schedule: $delivery_schedule
        delivery_zone: $delivery_zone
        delivery_timeslots_order_limit: $delivery_timeslots_order_limit
        fixed_delivery_times_order_limit: $fixed_delivery_times_order_limit
        courier_fee_vat_percentage: $courier_fee_vat_percentage
      }
    ) {
      id
    }
  }
`

export const UPDATE_DELIVERY_SETTINGS = gql`
  mutation updatePreOrderDeliverySettings(
    $store_id: uuid!
    $delivery_enabled: Boolean
    $delivery_courier_partner: Boolean
    $fixed_courier_fee_enabled: Boolean
    $pre_order_rate_cards_enabled: Boolean
    $custom_fixed_fee: numeric
    $delivery_interval: Int
    $fixed_delivery_times_enabled: Boolean
    $delivery_times: String
    $delivery_schedule: jsonb
    $delivery_zone: String
    $fixed_delivery_times_order_limit: Int
    $delivery_timeslots_order_limit: Int
    $settings: jsonb!
    $preorder_delivery_minimum_order_value: numeric
    $courier_fee_vat_percentage: numeric
  ) {
    update_stores(
      where: { id: { _eq: $store_id } }
      _append: { settings: $settings }
      _set: {
        preorder_delivery_minimum_order_value: $preorder_delivery_minimum_order_value
      }
    ) {
      affected_rows
      returning {
        preorder_delivery_minimum_order_value
        settings
      }
    }
    update_pre_order_settings(
      where: { store_id: { _eq: $store_id } }
      _set: {
        delivery_enabled: $delivery_enabled
        delivery_courier_partner: $delivery_courier_partner
        fixed_courier_fee_enabled: $fixed_courier_fee_enabled
        pre_order_rate_cards_enabled: $pre_order_rate_cards_enabled
        custom_fixed_fee: $custom_fixed_fee
        delivery_interval: $delivery_interval
        fixed_delivery_times_enabled: $fixed_delivery_times_enabled
        delivery_times: $delivery_times
        delivery_schedule: $delivery_schedule
        delivery_zone: $delivery_zone
        delivery_timeslots_order_limit: $delivery_timeslots_order_limit
        fixed_delivery_times_order_limit: $fixed_delivery_times_order_limit
        courier_fee_vat_percentage: $courier_fee_vat_percentage
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PICKUP_SETTINGS = gql`
  mutation updatePreOrderPickupSettings(
    $store_id: uuid!
    $pickup_enabled: Boolean
    $pickup_interval: Int
    $pickup_times: String
    $pickup_schedule: jsonb
    $pickup_timeslots_order_limit: Int
    $pickup_preorder_mov: numeric
    $settings: jsonb!
  ) {
    update_pre_order_settings(
      where: { store_id: { _eq: $store_id } }
      _set: {
        pickup_enabled: $pickup_enabled
        pickup_interval: $pickup_interval
        pickup_times: $pickup_times
        pickup_schedule: $pickup_schedule
        pickup_timeslots_order_limit: $pickup_timeslots_order_limit
      }
    ) {
      affected_rows
    }

    update_stores(
      where: { id: { _eq: $store_id } }
      _append: { settings: $settings }
      _set: { preorder_pickup_minimum_order_value: $pickup_preorder_mov }
    ) {
      affected_rows
    }
  }
`
export const UPDATE_CUTOFF_SETTINGS = gql`
  mutation updatePreOrderCutoffSettings(
    $store_id: uuid!
    $cutoffs: jsonb
    $pre_order_day_allowance: Int
    $special_cutoffs_map: jsonb
  ) {
    update_pre_order_settings(
      where: { store_id: { _eq: $store_id } }
      _set: {
        cutoffs: $cutoffs
        pre_order_day_allowance: $pre_order_day_allowance
        special_cutoffs_map: $special_cutoffs_map
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_PREORDER_DELIVERY_SPLIT_HOURS = gql`
  mutation updatePreorderDeliverySplitHours(
    $id: ID!
    $splitHours: [SplitHour]
  ) {
    updatePreorderDeliverySplitHours(storeId: $id, splitHours: $splitHours) {
      id
    }
  }
`

export const UPDATE_PREORDER_PICKUP_SPLIT_HOURS = gql`
  mutation updatePreorderPickupSplitHours($id: ID!, $splitHours: [SplitHour]) {
    updatePreorderPickupSplitHours(storeId: $id, splitHours: $splitHours) {
      id
    }
  }
`

export const UPDATE_PREORDER_SETTINGS = gql`
  mutation UpdateStoreSettings(
    $storeId: uuid!
    $preOrderSettings: PreOrderSettingsObject
  ) {
    updateStoreSettings(
      storeId: $storeId
      preOrderSettings: $preOrderSettings
    ) {
      id
      preOrderSettings {
        deliveryTime
        deliveryRadius
        deliveryAreaSetting {
          deliveryArea {
            lat
            lng
          }
        }
      }
    }
  }
`
