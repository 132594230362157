import React from 'react'
import { Result, Spin } from 'antd'

const Loading = ({ signIn = false }: { signIn?: boolean }) => (
  <Result
    status='info'
    title={`${
      signIn
        ? 'Signing in to your SlerpSpace'
        : 'Please wait while we load your data'
    }`}
    subTitle={`${signIn ? '' : 'This may take a while'} `}
    style={{
      width: '100%',
      ...(signIn && {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      })
    }}
    icon={<Spin size='large' />}
  />
)

export default Loading
