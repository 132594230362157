import React, { useState, useMemo, useEffect } from 'react'
import { uuid } from 'uuidv4'
import { Col, PageHeader, Row } from 'antd'
import { SplitHour } from '@slerp/controls'
import { DayOfWeek, SplitHourRaw } from './types'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import Day from './Day'

interface SplitShiftsProps {
  settingsId: string
  splitHours: null | SplitHour[]
  setSplitHours: () => void
  disabled: boolean
}

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const SplitShifts = ({
  settingsId,
  splitHours,
  setSplitHours,
  disabled
}: SplitShiftsProps) => {
  const updatingStoreSplitHours = false
  const sanitizeShifts = (shifts: SplitHourRaw[]) => {
    const newShifts = [...(shifts || [])] // clone and make sure it handles null and undefine
    newShifts.forEach((shift: SplitHourRaw) => delete shift.id)
    return newShifts
  }

  const [dailyShifts, setDailyShifts] = useState<SplitHour[]>(
    sanitizeShifts(
      (splitHours || []).filter(
        (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
      )
    )
  )

  useEffect(() => {
    setDailyShifts(
      sanitizeShifts(
        (splitHours || []).filter(
          (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
        )
      )
    )
  }, [splitHours])

  const groupShiftsByDay = useMemo(
    () => (shifts: SplitHour[]) =>
      groupBy(shifts, (shift: SplitHour) => shift.day_of_week),
    []
  )

  const shiftsByDay = groupShiftsByDay(dailyShifts)

  const updateShiftsState = (day: DayOfWeek, shifts: SplitHour[]) => {
    const newShifts = [...dailyShifts].filter(
      (editingShift: SplitHour) => editingShift.day_of_week !== day
    )
    shifts.forEach((shift: SplitHour) => newShifts.push(shift))
    setDailyShifts(newShifts)
    setSplitHours(newShifts)
  }

  const Header = () => (
    <Row>
      <Col span={4} />
      <Col span={16}>
        <Row className='_mb-0' gutter={[8, 0]}>
          <Col span={6}>Start</Col>
          <Col span={6}>End</Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <>
      <PageHeader title='Opening Hours' className='settings-title' />
      <div data-testid='split-hours-container'>
        <Header />
        {DAYS.map((day: string) => (
          <Day
            key={uuid()}
            dayOfWeek={day}
            shifts={shiftsByDay[day] || []}
            weeklyShifts={{ ...shiftsByDay }}
            updateShifts={updateShiftsState}
            disabled={disabled || updatingStoreSplitHours}
          />
        ))}
      </div>
    </>
  )
}

export default SplitShifts
