import React, { useState } from 'react'
import { Form, Switch, message, Button, PageHeader, Divider } from 'antd'
import { useMutation, useApolloClient } from '@apollo/client'
import { UPDATE_SETTINGS_AND_SAMEDAY_PICKUP_MOV } from '../actions'
import UpdateNotice from '../../Utils/UpdateNotice'
import { LocationSettingProps } from '../Settings'
import { Store } from 'rc-field-form/lib/interface'
import TimeSlots, {
  pickup_timeslots
} from '../PreOrderSettings/General/TimeSlots'
import OrderLimit from '../PreOrderSettings/General/OrderLimit'
import MinimumOrderValueInput from './MinimumOrderValueInput'
const { useForm } = Form

type FormType = {
  disabled?: boolean
}

const PickupSettings = (props: LocationSettingProps & FormType) => {
  const { merchant, store, disabled = false } = props
  const [form] = useForm()
  const [enabled, setEnabled] = useState(
    store.settings.same_day_pickup || store.settings.scheduled_pickup
  )
  const [updateSetting, { loading, error }] = useMutation(
    UPDATE_SETTINGS_AND_SAMEDAY_PICKUP_MOV,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const client = useApolloClient()

  const onFinish = (values: Store) => {
    const {
      pickup_enabled,
      pickup_interval,
      pickup_timeslot_order_limit,
      sameday_pickup_minimum_order_value
    } = values
    form.validateFields().then(() => {
      message.loading('Updating... Please wait.')
      updateSetting({
        variables: {
          merchant_id: merchant.id,
          id: store.id,
          settings: {
            same_day_pickup: pickup_enabled,
            scheduled_pickup: pickup_enabled,
            pickup_interval,
            pickup_timeslot_order_limit: pickup_timeslot_order_limit || 0
          },
          pickup_sameday_mov:
            sameday_pickup_minimum_order_value === undefined
              ? store.sameday_pickup_minimum_order_value || 0
              : sameday_pickup_minimum_order_value
        }
      }).then((result) => {
        message.destroy()
        client.resetStore()
        message.success('Pickup settings updated', 1)
      })
    })
  }

  return (
    <>
      <Form
        data-testid='pickup-settings-form'
        layout='vertical'
        colon={false}
        form={form}
        onFinish={onFinish}
        initialValues={{
          pickup_interval: store.settings.pickup_interval,
          pickup_timeslot_order_limit:
            store.settings.pickup_timeslot_order_limit || 0
        }}
      >
        <PageHeader title='Pickup Settings' className='settings-title' />
        <Form.Item name='pickup_enabled' label='Enable Pickup'>
          <Switch
            data-testid='same-day-switch'
            onChange={setEnabled}
            defaultChecked={enabled}
            disabled={disabled}
          />
        </Form.Item>

        {enabled && (
          <>
            <MinimumOrderValueInput
              inputName='sameday_pickup_minimum_order_value'
              defaultValue={
                Number(store.sameday_pickup_minimum_order_value) || 0
              }
              disabled={disabled}
              testId='sameday-pickup-minimum-order-value'
            />

            <TimeSlots
              formName='pickup_interval'
              value={store.settings.pickup_interval}
              handleChange={(value) =>
                form.setFieldsValue({ pickup_interval: value })
              }
              timeslots={pickup_timeslots}
              disabled={disabled}
            />

            <OrderLimit
              inputName='pickup_timeslot_order_limit'
              formRef={form}
              disabled={disabled}
            />
          </>
        )}
        <Divider />
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            title='Save'
            htmlType='submit'
            className='_center-vertical _ml-auto'
            disabled={disabled}
          >
            Save
          </Button>
          <UpdateNotice updating={loading} updateError={error} />
        </Form.Item>
      </Form>
    </>
  )
}
export default PickupSettings
