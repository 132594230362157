import React from 'react'
import { Form, Switch, Typography } from 'antd'

const FulfillmentTypeToggle = ({
  handleChange,
  type,
  value,
  disabled = false,
  onClick
}: {
  value?: boolean
  handleChange: (arg0: boolean) => void
  type: 'delivery' | 'pickup'
  disabled?: boolean
  onClick: (() => void) | null
}) => {
  return (
    <Form.Item
      name={`${type}_enabled`}
      valuePropName='checked'
      label={
        type === 'delivery'
          ? 'Pre-orders:'
          : type === 'pickup'
          ? 'Pickup:'
          : 'delivery'
      }
      className='_mb-40'
    >
      <Switch
        data-testid={`${type}_enabled`}
        defaultChecked={value}
        {...(onClick ? { checked: value, onClick } : {})}
        onChange={(value) => handleChange(value)}
        className='_mr-8'
        disabled={disabled}
      />
      <Typography.Text>Enable {type}</Typography.Text>
    </Form.Item>
  )
}

export default FulfillmentTypeToggle
