import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { FileError, useDropzone } from 'react-dropzone'
import { useTheme } from '../../contexts/Theme'

import { IconContext } from '../Icon'
import { Text } from '../Text'
import { Button } from '../Button'
import { Spinner } from './Spinner'
import { message } from 'antd'

interface Props {
  file: string | null
  icon?: React.ReactElement
  isUploading: boolean
  onFileSelect: (file: File) => void
  isFavicon?: boolean
}

const Uploader: React.FC<Props> = ({
  icon,
  file = '',
  isUploading,
  onFileSelect,
  isFavicon = false
}) => {
  const theme = useTheme() as any

  const handleDrop = (files: File[]) => {
    if (isUploading) return

    onFileSelect(files[0])
  }

  const { getRootProps, getInputProps, fileRejections, isDragActive, open } =
    useDropzone({
      accept: [
        'image/png',
        'image/jpg',
        'image/jpeg',
        ...(isFavicon ? ['image/gif'] : [])
      ],
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
      onDrop: handleDrop
    })

  useEffect(() => {
    fileRejections.map(({ errors }) =>
      errors.map((e: FileError) =>
        message.error(
          `I was unable to upload an image. The file type must be one of image/png, image/jpg, image/gif, or image/jpeg.`,
          5
        )
      )
    )
  }, [fileRejections])

  return (
    <Container
      {...getRootProps()}
      isDragActive={!isUploading && isDragActive}
      isEmpty={!file}
    >
      <input {...getInputProps()} />

      {icon && (
        <Badge>
          <IconContext.Provider value={{ color: theme.colors.gray500 }}>
            {icon}
          </IconContext.Provider>
        </Badge>
      )}

      {!file && (
        <>
          <UploadText>
            <Text size='md' color='gray400'>
              Drag your logo here
            </Text>
          </UploadText>

          <Button onClick={open}>
            <Text size='sm' color='white' weight='600'>
              Upload
            </Text>
          </Button>
        </>
      )}

      {isUploading === true && (
        <>
          <SelectedImage
            src={String(file)}
            alt='File being uploaded...'
            isUploading
            isFavicon={isFavicon}
          />

          <Overlay>
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>

            <Text size='md' color='gray900'>
              Uploading...
            </Text>
          </Overlay>
        </>
      )}

      {isUploading === false && file && (
        <>
          <SelectedImage
            src={String(file)}
            alt='Uploaded file'
            isFavicon={isFavicon}
          />

          <Overlay isUploaded>
            <Button onClick={open}>
              <Text size='sm' color='white' weight='600'>
                Replace
              </Text>
            </Button>
          </Overlay>
        </>
      )}
    </Container>
  )
}

const Container = styled.div<{
  isDragActive: boolean
  isEmpty: boolean
  isFavicon: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 100%;
  border: ${({ theme, isDragActive, isEmpty }: any) => {
    if (isDragActive) return `2px dashed ${theme.colors.blue}`
    if (isEmpty) return `1px dashed ${theme.colors.gray300}`
    return `1px solid ${theme.colors.gray300}`
  }};
  border-radius: 8px;
`

const Badge = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: ${({ theme }: any) => theme.colors.gray100};
  border-radius: 4px;
  z-index: ${({ theme }: any) => theme.zIndex.uploaderBadge};
`

const UploadText = styled.div`
  margin-bottom: 8px;
`

const SpinnerContainer = styled.div`
  margin-bottom: 8px;
`

const Overlay = styled.div<{ isUploaded?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }: any) => theme.zIndex.uploaderOverlay};

  ${({ isUploaded }) =>
    isUploaded &&
    `
  opacity: 0;
  backdrop-filter: blur(2px);

    &:hover {
      opacity: 1;
      transition: 200ms all ease;
    }
  `}
`
const SelectedImage = styled.img<{
  isUploading?: boolean
  isFavicon?: boolean
}>`
  position: relative;
  ${({ isFavicon }) => isFavicon && `height: 48px`};
  width: ${({ isFavicon }) => (isFavicon ? '48px' : '100%')};

  z-index: ${({ theme }: any) => theme.zIndex.uploaderImage};
  ${({ isUploading }) => isUploading && `opacity: 0.25`};
`

export { Uploader }
