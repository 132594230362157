import React, { useState, useEffect } from 'react'
import { Select, Form, Button, Modal, Tooltip, message } from 'antd'
import { requiredRule } from './Rules'
import { TextFieldNumber } from './../Widgets'
import QRCode from 'qrcode.react'
import { LoyaltyCardType, StoreType } from './types'
import { CreditCardOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { GENERATE_STAMP_QR_TOKEN } from './LoyaltyQueries'

interface LoyaltyQRModalProps {
  activeCard: LoyaltyCardType
  visible: boolean
  onClose: () => void
  stores: StoreType[]
}

const { useForm } = Form

const LoyaltyQRModal = (props: LoyaltyQRModalProps) => {
  const { visible, onClose, activeCard, stores } = props
  const [form] = useForm()

  const [qrObject, setQRObject] = useState()
  const [qrGenerated, setQRGenerated] = useState(false)

  const [generateStampQR] = useMutation(GENERATE_STAMP_QR_TOKEN, {
    fetchPolicy: 'no-cache'
  })

  const getExpirationTime = (minInterval?: number) => {
    if (minInterval === 0) return null
    if (minInterval) return minInterval * 60

    return null
  }

  const generateQR = (values: {
    store_id: string
    stamps_per_scan: number
    expiration_in_min: number
  }) => {
    const { store_id, stamps_per_scan, expiration_in_min } = values

    const expiration_in_sec = getExpirationTime(expiration_in_min)

    if (store_id && stamps_per_scan && activeCard?.id) {
      generateStampQR({
        variables: {
          loyaltyCardId: activeCard?.id,
          secondsExpiration: expiration_in_sec
        }
      })
        .then((result) => {
          setQRGenerated(true)
          setQRObject({
            store_id,
            stamps_per_scan,
            code: result?.data?.generateStampToken?.code
          })
        })
        .catch((errors) => {
          console.error('Unable to generate QR token', errors)
          message.error(`Unable to generate QR token due to: ${errors}`, 5)
        })
    }
  }

  const downloadQR = () => {
    const fileName = activeCard?.name
      .replace(/[^a-zA-Z0-9]/g, '-')
      .toLowerCase()
    const canvas = document.getElementById('generated-qr')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${fileName}-qr.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  useEffect(() => {
    setQRObject()
    setQRGenerated(false)
    form.resetFields()
  }, [onClose])

  const qrModalFooter =
    qrGenerated && qrObject
      ? [
          <Button type='ghost' onClick={onClose}>
            Close
          </Button>,
          <Button key='submit' type='secondary' onClick={downloadQR}>
            Download
          </Button>
        ]
      : [
          <Button type='ghost' onClick={onClose}>
            Discard
          </Button>,
          <Button
            key='submit'
            type='secondary'
            htmlType='submit'
            onClick={() => {
              form.submit()
            }}
          >
            Generate QR
          </Button>
        ]

  const qrModalTitle = (
    <div className='qr-modal-header'>
      <h2 className='title'>Generate Stamp QR</h2>
      <span className='context'>
        <CreditCardOutlined />
        <p className='name'>{activeCard?.name}</p>
      </span>
    </div>
  )

  return (
    <Modal
      closable={false}
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      forceRender={true}
      className='loyalty-qr-modal'
      title={qrModalTitle}
      footer={qrModalFooter}
    >
      {qrGenerated && qrObject ? (
        <div className='loyalty-qr'>
          <QRCode
            value={JSON.stringify(qrObject)}
            size={256}
            id='generated-qr'
          />
        </div>
      ) : (
        <Form
          className='_pl-4'
          form={form}
          data-testid='loyalty-form'
          onFinish={generateQR}
          layout='vertical'
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            stamps_per_scan: activeCard?.stamps_per_scan
              ? activeCard.stamps_per_scan
              : 1
          }}
        >
          <Form.Item
            data-testid='loyalty-qr-form'
            name='store_id'
            label='Store:'
            rules={requiredRule}
          >
            <Select className='_mt-10' allowClear placeholder='Select store'>
              {stores.map((store: Store) => (
                <Select.Option key={store.id} value={store.id}>
                  {store.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='stamps_per_scan'
            label='Stamps per scan:'
            rules={requiredRule}
          >
            <TextFieldNumber
              inputType='number'
              min={1}
              step={1}
              style={{ maxWidth: '160px' }}
            />
          </Form.Item>
          <Form.Item
            name='expiration_in_min'
            label={
              <>
                Expires in (minutes):
                <Tooltip
                  title={
                    <span>
                      If the field is left blank, the newly generated QR code
                      will not expire and can be used indefinitely.
                    </span>
                  }
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          >
            <TextFieldNumber
              inputType='number'
              min={0}
              step={1}
              style={{ maxWidth: '160px' }}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

export default LoyaltyQRModal
