import React, { useState, useEffect, lazy, Suspense } from 'react'
import { uuid } from 'uuidv4'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Button from 'antd/lib/button'
import Drawer from 'antd/lib/drawer'
import Tooltip from 'antd/lib/tooltip'
import {
  UnorderedListOutlined,
  MoreOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import Products from './Products'
import Modifiers from './Modifiers'
import ModifierForm from './Modifiers/Form'
import Categories from './Products/Categories'
import ModifierGroups from './Modifiers/ModifierGroups'
import ModifierGroupsArchiveList from './Modifiers/ModifierGroups/ModifierGroupsArchiveList'
import ArchivedCategoriesList from './Products/Categories/ArchivedCategoriesList'
import { QUERY_MERCHANT_IS_SEO_ENABLED } from 'components/Merchants/MerchantQueries'
import { useSession } from '@slerp/accounts'

const ProductForm = lazy(() => import('./Products/Form'))

const { TabPane } = Tabs

const ProductsAndModifiers = () => {
  const { merchant } = useSession()
  const location = useLocation() || {}
  const history = useHistory()
  const [productDrawerVisible, setProductDrawerVisible] =
    useState<boolean>(false)
  const [categoryDrawerVisible, setCategoryDrawerVisible] =
    useState<boolean>(false)
  const [modifierGroupDrawerVisible, setModifierGroupDrawerVisible] =
    useState<boolean>(false)
  const [modiferDrawerVisible, setModifierDrawerVisible] =
    useState<boolean>(false)
  const [isSeoEnabled, setIsSeoEnabled] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('products')
  const onProductFormSuccessCallback = () => setProductDrawerVisible(false)
  const onModifierFormSuccessCallback = () => setModifierDrawerVisible(false)

  const { data: merchantSeoData } = useQuery(QUERY_MERCHANT_IS_SEO_ENABLED, {
    variables: {
      id: merchant.id
    }
  })

  useEffect(() => {
    if (location) {
      const defaultActiveKey = !!location.pathname.match('products')
        ? 'products'
        : 'modifiers'
      setActiveKey(defaultActiveKey)
    } else {
      setActiveKey('products')
    }
  }, [location])

  useEffect(() => {
    if (merchantSeoData) {
      const { is_seo_enabled } = merchantSeoData?.merchant
      setIsSeoEnabled(is_seo_enabled)
    }
  }, [merchantSeoData])

  return (
    <>
      <Drawer
        width={640}
        onClose={() => setProductDrawerVisible(false)}
        visible={productDrawerVisible}
        data-testid='add-product-drawer'
        title={
          <>
            Add Product
            <Tooltip
              title={
                <a
                  href='https://support.slerp.com/knowledge/creating-products'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Creating Products
                </a>
              }
            >
              <QuestionCircleOutlined className='_ml-12' />
            </Tooltip>
          </>
        }
      >
        <Tabs>
          <TabPane tab='General' key='1'>
            <Suspense fallback={<Spin />}>
              <ProductForm
                key={uuid()}
                isSeoEnabled={isSeoEnabled}
                discardCallback={onProductFormSuccessCallback}
                successCallback={onProductFormSuccessCallback}
              />
            </Suspense>
          </TabPane>
        </Tabs>
      </Drawer>

      <Drawer
        width={720}
        onClose={() => setCategoryDrawerVisible(false)}
        visible={categoryDrawerVisible}
        data-testid='categories-drawer'
        title='Categories'
      >
        <Tabs>
          <TabPane tab='Active' key='1'>
            <Categories key={uuid()} />
          </TabPane>
          <TabPane tab='Archived' key='2'>
            <ArchivedCategoriesList key={uuid()} />
          </TabPane>
        </Tabs>
      </Drawer>

      <Drawer
        width={720}
        onClose={() => setModifierDrawerVisible(false)}
        visible={modiferDrawerVisible}
        data-testid='add-modifier-drawer'
        title='Add Modifier'
      >
        <Tabs>
          <TabPane tab='General' key='1'>
            <ModifierForm
              key={uuid()}
              discardCallback={onModifierFormSuccessCallback}
              successCallback={onModifierFormSuccessCallback}
            />
          </TabPane>
        </Tabs>
      </Drawer>

      <Drawer
        width={720}
        onClose={() => setModifierGroupDrawerVisible(false)}
        visible={modifierGroupDrawerVisible}
        data-testid='modifier-groups-drawer'
        title='Modifier Groups'
      >
        <Tabs>
          <TabPane tab='Active' key='1'>
            <ModifierGroups />
          </TabPane>
          <TabPane tab='Archived' key='2'>
            <ModifierGroupsArchiveList />
          </TabPane>
        </Tabs>
      </Drawer>
      <Row>
        <Col span={24}>
          {activeKey === 'products' && (
            <Row justify='end' gutter={[8, 8]}>
              <Col className='orders-search'>
                <Button
                  icon={<UnorderedListOutlined />}
                  onClick={() => setCategoryDrawerVisible(true)}
                  data-testid='show-categories-drawer-btn'
                >
                  CATEGORIES
                </Button>
              </Col>
              <Col>
                <Tooltip
                  placement='bottomLeft'
                  title={
                    <span>
                      <a
                        href='https://support.slerp.com/knowledge/creating-products'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Here's our best practice guide
                      </a>{' '}
                      to creating products. Be sure once you've added a product
                      that you make it avaliable in the stores' inventories for
                      it to be live at the relavent locations.
                    </span>
                  }
                >
                  <Button
                    onClick={() => setProductDrawerVisible(true)}
                    data-testid='add-product-btn'
                  >
                    ADD
                    <MoreOutlined />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          )}
          {activeKey === 'modifiers' && (
            <Row justify='end' gutter={[8, 8]}>
              <Col>
                <Tooltip
                  placement='left'
                  title={
                    <span>
                      See{' '}
                      <a
                        href='https://support.slerp.com/knowledge/modifiers'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        these
                      </a>{' '}
                      as categories for your add-ons. For example, 'Add a side?'
                      is a group of modifiers featuring your side dishes.
                    </span>
                  }
                >
                  <Button
                    icon={<UnorderedListOutlined />}
                    onClick={() => setModifierGroupDrawerVisible(true)}
                    data-testid='show-modifier-groups-drawer-btn'
                  >
                    MODIFIER GROUPS
                  </Button>
                </Tooltip>
              </Col>
              <Col>
                <Button
                  onClick={() => setModifierDrawerVisible(true)}
                  data-testid='add-modifier-btn'
                >
                  ADD
                  <MoreOutlined />
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Tabs
                type='card'
                activeKey={activeKey}
                onChange={(value: string) => {
                  setActiveKey(value)
                  history.push(value)
                }}
                data-testid='products-modifiers-tabs'
              >
                <TabPane
                  tab={<a data-testid='products-tab-pane'>PRODUCTS</a>}
                  key='products'
                >
                  <Products />
                </TabPane>
                <TabPane
                  tab={<a data-testid='modifiers-tab-pane'>MODIFIERS</a>}
                  key='modifiers'
                >
                  <Modifiers />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ProductsAndModifiers
