import React, { useState, useEffect } from 'react'
import { Col, Divider, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import GoogleMap from 'google-map-react'
import { QUERY_ORDER_LOCATIONS } from './ReportsQueries'
import { useQuery } from '@apollo/client'
import Loading from './../Utils/Loading'

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY || ''

interface OrderMapProps {
  merchantId: string
  storeId: string | null
  period: number
  periodBoundaries: string[][]
}

const OrderMap = (props: OrderMapProps) => {
  const { storeId, merchantId, period, periodBoundaries } = props
  const [startDate] = periodBoundaries[period]
  const { data, loading } = useQuery(QUERY_ORDER_LOCATIONS, {
    variables: { merchantId, storeId, startDate: startDate }
  })

  const [heatMapPoints, setHeatMapPoints] = useState([])

  useEffect(() => {
    if (!data) return

    setHeatMapPoints(
      data.view_order_coords.map((order: any, index: number) => {
        const { lat, lng } = order.coords
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      })
    )
  }, [data, period, storeId])

  if (loading) return <Loading />

  return (
    <Col
      style={{ height: '954px' }}
      className='container-style  _pl-0 _pr-0'
      span={24}
    >
      <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
        <span className='_mt-0'>Orders Heat Map</span>
        <Tooltip
          placement='right'
          title={
            <span>
              A visual overview of{' '}
              <a
                href='https://support.slerp.com/en_gb/heat-map-overview-HkQZPemlY'
                target='_blank'
                rel='noopener noreferrer'
              >
                delivery area hotspots
              </a>
              . We suggest targeting these areas with social ads.
              <br />
              <br />
              Talk to a member of our team about our{' '}
              <a
                href='https://www.slerp.com/digital-marketing/'
                target='_blank'
                rel='noopener noreferrer'
              >
                digital marketing
              </a>{' '}
              service.
            </span>
          }
          className='_ml-8'
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Col>
      <Divider className='_mt-0 _mb-0' />
      <div style={{ height: '92.5%' }} className='_pt-24 _pr-16 _pb-16 _pl-16'>
        <GoogleMap
          bootstrapURLKeys={{
            key: MAPS_API_KEY,
            libraries: 'visualization'
          }}
          defaultCenter={{ lat: 51.5137137, lng: -0.1285204 }}
          defaultZoom={13}
          heatmapLibrary={true}
          heatmap={{
            positions: heatMapPoints,
            options: {
              radius: 30,
              opacity: 1
            }
          }}
        />
      </div>
    </Col>
  )
}

export default OrderMap
