const filterSelectOption = (input: string, option: any) => {
  if (typeof option?.children == 'object') {
    return (
      option?.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
    )
  } else {
    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
}

export { filterSelectOption }
