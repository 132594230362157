import React, { useEffect } from 'react'
import { OrderInfo } from '../type'
import { Button, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { isFullyRefunded } from '../utils'

const BEYOND_PICKING_STATUSES = [
  'cancelled',
  'picking',
  'almost_picking',
  'delivering',
  'almost_delivering',
  'delivered',
  'failed'
]

interface RefundButtonType {
  order: OrderInfo
  onClick: () => void
  disabled?: boolean
}

const RefundButton = ({ order, onClick, disabled }: RefundButtonType) => {
  const fullyRefunded = isFullyRefunded(order)
  const [isBeyondPicking, setIsBeyondPicking] = React.useState<boolean>(false)

  useEffect(() => {
    setIsBeyondPicking(
      BEYOND_PICKING_STATUSES.includes(order.delivery?.delivery_status || '')
    )
  }, [order])

  const isZeroPoundDisabled = order.total === 0 && isBeyondPicking

  const isDisabled = disabled || isZeroPoundDisabled

  if (fullyRefunded) {
    return <></>
  }

  return (
    <>
      <Tooltip
        title={
          <a
            href='https://support.slerp.com/knowledge/processing-a-refund'
            target='_blank'
            rel='noopener noreferrer'
          >
            Refunding and Cancelling Orders
          </a>
        }
        color='#8c8ca1'
      >
        <QuestionCircleOutlined className='_mr-16 _link-white' />
      </Tooltip>
      <Button
        data-testid='order-info-actions-refund'
        title='refund'
        type='primary'
        onClick={onClick}
        ghost
        disabled={isDisabled}
      >
        Refund
      </Button>
    </>
  )
}

export default RefundButton
