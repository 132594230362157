import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Row, Col, Avatar, Spin } from 'antd'
import { WarningOutlined } from '@ant-design/icons'

const StripeAlert = ({
  loading
}: {
  loading: boolean
  hasSubscription: boolean
}) => {
  return (
    <Alert
      type='info'
      message={
        <Row className='_mb-0' justify='space-between' align='middle'>
          {(loading && <Spin />) || (
            <Col span={24}>
              To complete your setup and start transacting, please enter your
              payment details{' '}
              <strong>
                <Link
                  to='/settings/accounts-and-billing'
                  className='_link-primary banner-link'
                >
                  here
                </Link>
              </strong>
            </Col>
          )}
        </Row>
      }
      showIcon
      icon={<Avatar icon={<WarningOutlined className='warning-icon' />} />}
      closable={false}
      banner
      className='stripe-alert'
    />
  )
}

export default StripeAlert
