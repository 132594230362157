import React from 'react'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { ApolloQueryResult } from '@apollo/client'

interface TableSorterProps {
  type: 'asc' | 'desc'
  onClick: () => Promise<ApolloQueryResult<any>> | undefined | void
  isActive: boolean
}

const TableSorter = ({ type, onClick, isActive }: TableSorterProps) => {
  const isAscending = type === 'asc'

  return (
    <Tooltip
      title={`Click to sort to ${isAscending ? 'ascending' : 'descending'}`}
      placement={isAscending ? 'top' : 'bottom'}
    >
      {isAscending ? (
        <CaretUpOutlined
          onClick={onClick}
          className={`table-sorter ${isActive ? 'active' : ''}`}
        />
      ) : (
        <CaretDownOutlined
          onClick={onClick}
          className={`table-sorter ${isActive ? 'active' : ''}`}
        />
      )}
    </Tooltip>
  )
}

export default TableSorter
