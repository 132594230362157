import React from 'react'
import { partition } from 'lodash'
import { uuid } from 'uuidv4'
import { Col, InputNumber, Row } from 'antd'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'
import { Pricing, Option } from '../types'
import { computeNetPrice } from 'components/Utils/price'

const FulfillmentTypePricingForm = ({
  variantId,
  storeVariantId,
  fulfillmentTypePricings,
  corePricing,
  tableEnabled,
  updateFulfillmentTypePricings,
  oatDefaultVat,
  customOatVat,
  showGlobalFulfillmentTypePrice,
  globalFulfillmentTypePricing,
  variantBasicInfo
}: {
  variantId: string
  storeVariantId: string
  corePricing: Pricing
  fulfillmentTypePricings: Pricing[]
  tableEnabled: boolean
  updateFulfillmentTypePricings: (val: Pricing[]) => void
  oatDefaultVat: number
  customOatVat: boolean
  showGlobalFulfillmentTypePrice: boolean
  globalFulfillmentTypePricing: Pricing[]
  variantBasicInfo: {
    product: { name: string }
    options: Option[]
  }
}) => {
  const { product = {}, options = [] } = variantBasicInfo || {}
  const baseProductName = product?.name || ''
  const optionNames = (options || []).map(({ value }: Option) => value)

  const globalFtPricing = globalFulfillmentTypePricing.reduce(
    (variantHashMap, ftPrice: Price) => {
      variantHashMap[ftPrice.fulfillment_type] = ftPrice.price_with_vat
      return variantHashMap
    },
    {}
  )

  const updatePricing = (
    fulfillmentType: FulfillmentType,
    price_with_vat: number | null
  ) => {
    const [[pricing], rest] = partition(
      fulfillmentTypePricings,
      (pricing) => pricing.fulfillment_type === fulfillmentType
    )

    const vat =
      fulfillmentType === 'order_at_table' && customOatVat
        ? oatDefaultVat
        : corePricing.vat || 0

    const updatedPricing = {
      id: pricing ? pricing.id : uuid(),
      price_with_vat,
      vat,
      price: price_with_vat ? computeNetPrice(price_with_vat, vat || 0) : null,
      fulfillment_type: fulfillmentType,
      variant_id: variantId,
      store_variant_id: storeVariantId,
      inserted_at: 'now()',
      updated_at: 'now()',
      published_at: 'now()'
    }

    updateFulfillmentTypePricings([...rest, updatedPricing])
  }

  return (
    <>
      <Row
        align='middle'
        className='header'
        data-testid='products-fulfillment-type-pricing-table'
      >
        <Col span={11}>Product Name</Col>
        <Col span={13}>
          <Row gutter={[8]} align='middle' className='_mb-0'>
            <Col span={8}>Delivery</Col>
            <Col span={8}>Pickup</Col>
            <Col span={8}>Order at table</Col>
          </Row>
        </Col>
      </Row>
      <Row className='_mb-0'>
        <Col span={11}>
          <VariantNameAndAvatar
            id={variantId}
            name={baseProductName}
            options={optionNames}
          />
        </Col>
        <Col span={13}>
          <Row gutter={[8]} align='middle' justify='end' className='_mb-0'>
            <Col span={8}>
              <InputNumber
                className='_w-10'
                step={0.01}
                precision={2}
                min={0}
                defaultValue={
                  fulfillmentTypePricings.find(
                    (pricing) => pricing.fulfillment_type === 'delivery'
                  )?.price_with_vat ?? undefined
                }
                onChange={(val: number | null) => {
                  updatePricing('delivery', val)
                }}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                className='_w-10'
                step={0.01}
                precision={2}
                min={0}
                defaultValue={
                  fulfillmentTypePricings.find(
                    (pricing) => pricing.fulfillment_type === 'pickup'
                  )?.price_with_vat ?? undefined
                }
                onChange={(val: number | null) => {
                  updatePricing('pickup', val)
                }}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!tableEnabled}
                className='_w-10'
                step={0.01}
                precision={2}
                min={0}
                defaultValue={
                  (tableEnabled &&
                    fulfillmentTypePricings.find(
                      (pricing) => pricing.fulfillment_type === 'order_at_table'
                    )?.price_with_vat) ??
                  undefined
                }
                onChange={(val: number | null) => {
                  updatePricing('order_at_table', val)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {showGlobalFulfillmentTypePrice && (
        <Row className='_bg-pale-green _mb-0 _pt-12 _pb-12'>
          <Col span={11}>
            <VariantNameAndAvatar
              id={variantId}
              name={baseProductName}
              options={optionNames}
            />
          </Col>
          <Col span={13}>
            <Row gutter={[8]} align='middle' justify='end' className='_mb-0'>
              <Col span={8}>
                <InputNumber
                  disabled
                  className='_w-10 _input-dark-green'
                  min={0}
                  precision={2}
                  defaultValue={globalFtPricing.delivery || 0}
                />
              </Col>
              <Col span={8}>
                <InputNumber
                  disabled
                  className='_w-10 _input-dark-green'
                  min={0}
                  precision={2}
                  defaultValue={globalFtPricing.pickup || 0}
                />
              </Col>
              <Col span={8}>
                <InputNumber
                  disabled
                  className='_w-10 _input-dark-green'
                  min={0}
                  precision={2}
                  defaultValue={globalFtPricing.order_at_table || 0}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}

export default FulfillmentTypePricingForm
