import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import { Button, Col, List, message, Row, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  ARCHIVE_MODIFIER_GROUP,
  GET_MERCHANT_MODIFIER_GROUPS
} from '../ModifierQueries'
import ModifierGroupForm from './Form'
import Loading from '../../../Utils/Loading'
import { useSession } from '@slerp/accounts'

interface arrangement {
  [id: string]: string
}

interface ModifierGroupModifier {
  modifier_id: string
}

interface ModifierGroup {
  id: string
  name: string
  description: string
  sku: string | null
  minimum: number
  minimum_enabled: boolean
  maximum: number
  maximum_enabled: boolean
  archived_at: string | null
  modifier_arrangement: arrangement
  modifier_group_modifiers: ModifierGroupModifier[]
}

const emptyModifierGroup = {
  id: '',
  name: '',
  description: '',
  sku: '',
  minimum: 0,
  minimum_enabled: false,
  maximum: 0,
  maximum_enabled: false,
  archived_at: null,
  modifier_arrangement: {},
  modifier_group_modifiers: []
}

const ModifierGroupsList = () => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [modifierGroups, setModifierGroups] = useState<Array<ModifierGroup>>([])
  const [showModifierGroupForm, setShowModifierGroupForm] =
    useState<boolean>(false)
  const [showEditModifierForm, setShowEditModifierForm] =
    useState<boolean>(false)
  const [activeModifierGroup, setActiveModifierGroup] =
    useState<ModifierGroup>(emptyModifierGroup)
  const [processingModifierGroupRow, setProcessingModifierGroupRow] =
    useState<string>('')

  const {
    data: merchantModifierGroups,
    loading: fetchingMerchantModifierGroups
  } = useQuery(GET_MERCHANT_MODIFIER_GROUPS, {
    variables: {
      merchantId: merchant.id
    }
  })

  const [archiveModifierGroup] = useMutation(ARCHIVE_MODIFIER_GROUP)

  useEffect(() => {
    if (merchantModifierGroups) {
      const { modifier_groups } = merchantModifierGroups
      setModifierGroups(modifier_groups)
    }
  }, [merchantModifierGroups])

  useEffect(() => {
    const showForm = !!activeModifierGroup.id
    setShowEditModifierForm(showForm)
  }, [activeModifierGroup])

  const archiveHandler = (id: string) => {
    message.destroy()
    message.loading(`Archiving modifier group...`, 1)
    setProcessingModifierGroupRow(id)
    archiveModifierGroup({
      variables: {
        modifierGroupId: id
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Modifier group archived!', 3)
        client.resetStore()
        setProcessingModifierGroupRow('')
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Modifier group failed to archive due to ${error.message}`,
          3
        )
        setProcessingModifierGroupRow('')
      })
  }

  const renderModifierGroupRow = (modifierGroup: ModifierGroup) => {
    const { id, name, description } = modifierGroup
    const isOperationInProgress = !isEmpty(processingModifierGroupRow)
    const isCurrentRowProcessing = processingModifierGroupRow === id

    return (
      <List.Item
        data-testid={`modifier-groups-list-item-${id}`}
        actions={[
          <Tooltip title='Archive modifier group'>
            <Button
              size='small'
              icon={<DeleteOutlined />}
              disabled={isOperationInProgress}
              loading={isCurrentRowProcessing}
              onClick={() => archiveHandler(id)}
            />
          </Tooltip>,
          <Tooltip title='Edit modifier group'>
            <Button
              size='small'
              icon={<EditOutlined />}
              disabled={isOperationInProgress}
              onClick={() => setActiveModifierGroup(modifierGroup)}
            />
          </Tooltip>
        ]}
      >
        {`${name} (${description})`}
      </List.Item>
    )
  }

  return (
    <>
      {fetchingMerchantModifierGroups && <Loading />}
      {!fetchingMerchantModifierGroups &&
        modifierGroups &&
        !showModifierGroupForm &&
        !showEditModifierForm && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <List
                bordered
                dataSource={modifierGroups}
                size='small'
                renderItem={renderModifierGroupRow}
                pagination={{
                  defaultCurrent: 1,
                  showSizeChanger: true
                }}
              />
            </Col>
          </Row>
        )}
      {showEditModifierForm && !showModifierGroupForm && (
        <ModifierGroupForm
          modifierGroupInfo={activeModifierGroup}
          onCancel={() => {
            setActiveModifierGroup(emptyModifierGroup)
            setShowEditModifierForm(false)
          }}
          onStartSave={() => {
            message.destroy()
            message.loading('Updating modifier group...', 3)
          }}
          onEndSave={() => {
            setShowEditModifierForm(false)
            setActiveModifierGroup(emptyModifierGroup)
            client.resetStore()
            message.destroy()
            message.success('Modifier group updated!', 3)
          }}
          onError={(errorMessage) => {
            message.destroy()
            message.error(
              `Unable to update modifier group due to ${errorMessage}`,
              3
            )
          }}
        />
      )}
      {showModifierGroupForm && !showEditModifierForm && (
        <Row>
          <Col span={24}>
            <ModifierGroupForm
              onCancel={() => setShowModifierGroupForm(false)}
              onStartSave={() => {
                message.destroy()
                message.loading('Creating modifier group...', 3)
              }}
              onEndSave={() => {
                setShowModifierGroupForm(false)
                client.resetStore()
                message.destroy()
                message.success('Modifier group created', 3)
              }}
              onError={(errorMessage) => {
                message.destroy()
                message.error(
                  `Unable to create modifier group due to ${errorMessage}`,
                  3
                )
              }}
            />
          </Col>
        </Row>
      )}
      {!showModifierGroupForm && !showEditModifierForm && (
        <Row justify='start'>
          <Col>
            <Button
              icon={<PlusOutlined />}
              data-testid='modifier-group-show-form-btn'
              onClick={() => setShowModifierGroupForm(true)}
            >
              ADD GROUP
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ModifierGroupsList
