export const DISCOUNT_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT =
  'Uniqueness violation. duplicate key value violates unique constraint "merchant_code"'

export const USER_EMAIL_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT =
  'Uniqueness violation. duplicate key value violates unique constraint "users_email_index"'
export const USER_EMAIL_UNIQUENESS_VIOLATION_EXCEPTION_ERROR_MESSAGE_FRAGMENT =
  'Unexpected token # in JSON at position 0'

export const PRODUCT_NAME_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT =
  'Uniqueness violation. duplicate key value violates unique constraint "products_merchant_id_slug_index"'
export const PRODUCT_CATEGORY_NAME_UNIQUENESS_VIOLATION_EXCEPTION_MESSAGE_FRAGMENT =
  'Unexpected token # in JSON at position 0'
