import { gql } from '@apollo/client'
import { Store } from '@slerp/controls'

export const QUERY_LOCATION_STRIPE_ACCOUNT_SESSION = gql`
  query getLocationStripeAccountSession($storeId: String!) {
    getLocationStripeAccountSession(storeId: $storeId) {
      account
      clientSecret
      publicKey
      expiresAt
      livemode
      object
    }
  }
`

export const QUERY_GET_STORE_FRANCHISE_ATTRIBUTION = gql`
  query getStoreFranchiseAttributions($storeId: ID!) {
    getStoreFranchiseAttributions(storeId: $storeId) {
      delivery
      pickup
      selfDelivery
      nationwideDelivery
      table
      user {
        id
      }
      lastUpdatedAt
      stripeUserId
    }
  }
`

export const GET_ADMIN_USER_EMAIL = gql`
  query getAdminUserEmail($merchant_id: uuid!) {
    users(
      where: { merchant_id: { _eq: $merchant_id }, role: { _eq: "admin" } }
      limit: 1
    ) {
      email
    }
  }
`

export const GET_MERCHANT_STORE = gql`
  query getMerchantStore(
    $email: String!
    $merchant_id: uuid!
    $store_slug: String!
  ) {
    stores(
      where: {
        _or: [
          {
            store_users: {
              user: {
                email: { _eq: $email }
                merchant_id: { _eq: $merchant_id }
                role: { _in: ["manager", "member"] }
              }
            }
          }
          {
            users: {
              email: { _eq: $email }
              merchant_id: { _eq: $merchant_id }
              role: { _eq: "member" }
            }
          }
          {
            merchant: {
              users: {
                email: { _eq: $email }
                merchant_id: { _eq: $merchant_id }
                role: { _eq: "admin" }
              }
            }
          }
        ]
        slug: { _eq: $store_slug }
        merchant_id: { _eq: $merchant_id }
      }
    ) {
      id
      name
      slug
      is_open
      settings
    }
  }
`

export const QUERY_AVAILABLE_STORE_TIMESLOTS = gql`
  query getValidStore(
    $storeId: ID!
    $address: String
    $fulfillmentType: String!
    $fulfillmentDate: String!
  ) {
    getValidStore(
      storeId: $storeId
      fulfillmentType: $fulfillmentType
      fulfillmentDate: $fulfillmentDate
      address: $address
    ) {
      id
      name
      slug
      fulfillmentTimeslots(
        fulfillmentType: $fulfillmentType
        fulfillmentDate: $fulfillmentDate
      ) {
        value
        start_time
        end_time
      }
    }
  }
`

export const QUERY_MERCHANT_STORES = gql`
  query getStore($merchant_id: uuid!, $slug: String!) {
    merchant: merchants_by_pk(id: $merchant_id) {
      id
      name
      description
      slug
      pre_order_pickup: setting(path: "pre_order_pickup")
      pre_order_delivery: setting(path: "pre_order_delivery")
      setting
      price_matrices(where: { archived_at: { _is_null: true } }) {
        id
        name
        data
        global
        stores {
          slug
        }
      }
      stores(where: { slug: { _eq: $slug } }) {
        id
        name
        description
        email
        slug
        pickup_notes
        settings
        prep_reminders
        sameday_delivery_minimum_order_value
        preorder_delivery_minimum_order_value
        sameday_pickup_minimum_order_value
        preorder_pickup_minimum_order_value
        split_hours
        is_open
        address {
          id
          geom
          contact_num
          line_1
          line_2
          zip
          country
          city
          is_new_version
        }
        archived_at
        users: store_users(
          where: {
            user: { archived_at: { _is_null: true }, role: { _eq: "member" } }
          }
        ) {
          user {
            firstname
            lastname
            email
          }
        }
        pre_order_settings {
          id
          pre_order_day_allowance
          delivery_enabled
          delivery_courier_partner
          custom_fixed_fee
          delivery_times
          fixed_delivery_times_enabled
          fixed_courier_fee_enabled
          delivery_interval
          delivery_schedule
          delivery_zone
          delivery_radius
          delivery_time
          delivery_timeslots_order_limit
          fixed_delivery_times_order_limit
          pickup_enabled
          pickup_interval
          pickup_schedule
          pickup_times
          pickup_timeslots_order_limit
          cutoffs
          special_cutoffs_map
          delivery_area_setting
          pre_order_rate_cards_enabled
          courier_fee_vat_percentage
          split_hours
          pickup_split_hours
        }
      }
    }
  }
`

export const QUERY_LOCATIONS = gql`
  query queryLocations($merchantSlug: String!) {
    stores(
      where: {
        merchant: { slug: { _eq: $merchantSlug } }
        archived_at: { _is_null: true }
      }
    ) {
      name
      id
      archived_at
    }
  }
`

export const QUERY_MERCHANT_LOCATIONS = gql`
  query queryMerchantLocations($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      stores {
        address {
          id
          geom
          line_1
          line_2
          city
          zip
        }
        email
        name
        slug
        is_open
        inserted_at
        archived_at
        id
        cutoffs
        settings
        schedules
        pre_order_settings {
          pickup_enabled
          delivery_enabled
        }
      }
    }
  }
`

export const QUERY_MERCHANT_VARIANTS_AND_MODIFIERS = gql`
  query queryMerchantVariantsAndModifiers($merchant_id: uuid!) {
    merchant: merchants_by_pk(id: $merchant_id) {
      categories {
        id
        name
        slug
      }
      variants: product_variants {
        id
        name
        sku
        description
        product {
          category {
            id
            name
            slug
          }
        }
      }
      modifiers(where: { archived_at: { _is_null: true } }) {
        id
        name
        modifier_group_modifiers {
          modifier_group {
            id
            name
            description
          }
        }
      }
    }
  }
`

export const QUERY_PRODUCTS_FOR_SYNC = gql`
  query queryProductsForSync($merchant_id: uuid!) {
    merchant: merchants_by_pk(id: $merchant_id) {
      products {
        id
        name
        description
        product_variants {
          id
          name
          description
          store_variants {
            id
            store_id
            preorder_store_id
            in_stock
            published_at
          }
        }
      }
      stores {
        id
        name
      }
    }
  }
`

export const QUERY_GET_PRICE_MATRIX = gql`
  query getPriceMatrix($id: uuid!) {
    price_matrices(where: { id: { _eq: $id } }) {
      id
      name
      global
      data
    }
  }
`

const StoreVariantFragment = `
  id
  in_stock
  stock_count
  stock_updated_at
  stock_type
  stock_sold
  product_variant {
    id
    name
    sku
    description
    published
    archived_at
    product {
      archived_at
      category {
        id
        name
        slug
      }
    }
  }
`

const StoreModifierFragment = `
  id
  in_stock
  published_at
  modifier {
    id
    name
    archived_at
    modifier_group_modifiers {
      modifier_group {
        id
        name
        description
      }
    }
  }
`

export const buildInventoryQuery = (isPreorder?: boolean) => {
  const storeFilter = isPreorder
    ? `storeByPreorderStoreId: { slug: {_eq: $slug} }`
    : `store: { slug: {_eq: $slug} }`

  return gql`
    query queryStoreVariantsAndModifiers($slug: String!) {
      store_variants(
        where: {
          published_at: { _is_null: false },
          ${storeFilter}
        }
        order_by: { product_variant: { name: asc } }
      ) { ${StoreVariantFragment} }
      store_modifiers(
        where: {
          published_at: { _is_null: false },
          ${storeFilter}
        }
        order_by: { modifier: { name: asc } }
      ) { ${StoreModifierFragment} }
    }
  `
}

export const buildPublisherQuery = (isPreorder?: boolean) => {
  const storeFilter = isPreorder
    ? `storeByPreorderStoreId: { slug: {_eq: $slug} }`
    : `store: { slug: {_eq: $slug} }`

  return gql`
    query sameDayStoreVariantsAndModifiers($slug: String!) {
      store_variants(
        where: {
          product_variant: {product: {archived_at: {_is_null: true}}}
          ${storeFilter}
        }
        order_by: { product_variant: { name: asc } }
      ) {
        ${StoreVariantFragment}
      }
      store_modifiers(
        where: {
          published_at: {_is_null: false}
          modifier: {
            modifier_group_modifiers: {
              modifier_group: {
                archived_at: {_is_null: true}
              }
            },
            archived_at: {_is_null: true},
          }
          ${storeFilter}
        }
        order_by: { modifier: { name: asc } }
      ) {
        ${StoreModifierFragment}
      }
    }
`
}

export const QUERY_EXISTING_USER_EMAIL = gql`
  query queryExistingUserEmail($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`

export const QUERY_EXISTING_LOCATION_NAME_FOR_MERCHANT = gql`
  query queryExistingLocationNameForMerchant(
    $merchantId: uuid!
    $storeName: String!
  ) {
    stores(
      where: { merchant_id: { _eq: $merchantId }, name: { _ilike: $storeName } }
    ) {
      id
    }
  }
`

export const QUERY_EXISTING_LOCATION_SLUG_FOR_MERCHANT = gql`
  query queryExistingLocationSlugForMerchant(
    $merchantId: uuid!
    $storeSlug: String!
  ) {
    stores(
      where: { merchant_id: { _eq: $merchantId }, slug: { _eq: $storeSlug } }
    ) {
      id
    }
  }
`

export const QUERY_EXISTING_OLD_STORE_SLUG = gql`
  query queryExistingOldStoreSlug($merchantId: uuid!, $storeSlug: String!) {
    slug_logs(
      where: {
        merchant_id: { _eq: $merchantId }
        old_slug: { _eq: $storeSlug }
      }
    ) {
      id
    }
  }
`

export const UPDATE_STORE_SPLIT_HOURS = gql`
  mutation UpdateSplitHours($id: ID!, $splitHours: [SplitHour]) {
    updateStoreSplitHours(storeId: $id, splitHours: $splitHours) {
      id
    }
  }
`

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!, $merchantId: ID!) {
    getUserByEmail(email: $email, merchantId: $merchantId) {
      id
    }
  }
`

export const PREVIEW_MILES_DELIVERY_AREA = gql`
  query previewDeliveryArea(
    $storeId: String!
    $lat: Float!
    $lng: Float!
    $miles: Float
  ) {
    previewDeliveryArea(
      storeId: $storeId
      lat: $lat
      lng: $lng
      miles: $miles
    ) {
      lat
      lng
    }
  }
`

export const PREVIEW_MINUTES_DELIVERY_AREA = gql`
  query previewDeliveryArea(
    $storeId: String!
    $lat: Float!
    $lng: Float!
    $minutes: Float
  ) {
    previewDeliveryArea(
      storeId: $storeId
      lat: $lat
      lng: $lng
      minutes: $minutes
    ) {
      lat
      lng
    }
  }
`

export const QUERY_STORE_INTEGRATION_SETTINGS = gql`
  query storeIntegrationSettings($storeId: uuid!) {
    store_integration_settings(where: { store_id: { _eq: $storeId } }) {
      id
      order_with_google
    }
  }
`

export type MerchantLocationResponseType = {
  merchants: {
    stores: Store[]
  }[]
}

type CategoryResponseType = {
  id: string
  name: string
}

export type VariantCategoryResponseType = CategoryResponseType & {
  slug: string
}
export type ModifierCategoryResponseType = CategoryResponseType & {
  description: string
}

export type VariantResponseType = {
  id: string
  name: string
  sku: string | null
  description: string | null
  product: {
    archived_at?: string
    category: VariantCategoryResponseType
  }
}

export type ModifierResponseType = {
  id: string
  name: string
  description?: string
  modifier_group_modifiers?: {
    modifier_group: ModifierCategoryResponseType
  }[]
}

export type MerchantProductsResponseType = {
  merchant: {
    categories: Array<VariantCategoryResponseType>
    variants: Array<VariantResponseType>
    modifiers: Array<ModifierResponseType>
  }
}

export type StoreVariantsAndModifiersResponseType = {
  store_variants: Array<StoreVariant>
  store_modifiers: Array<StoreModifier>
}

export type StoreVariantResponseType = VariantResponseType & {
  published: boolean
  archived_at: string
}

export type StoreVariant = {
  id: string
  published_at: string
  product_variant: {
    id: string
    description: string
    name: string
    sku: string
    published: boolean
    archived_at: string
    product: {
      category: {
        id: string
        slug: string
        name: string
      }
    }
  }
  in_stock: boolean
  stock_count: number
  stock_updated_at?: Date
  stock_type?: 'manual' | ''
  stock_sold: number
}

export type StoreModifier = {
  id: string
  in_stock: boolean
  published_at: string | null
  modifier: ModifierResponseType & { published_at: string; archived_at: string }
}
