import React, { useState } from 'react'
import { Form, message, Button, PageHeader, Divider } from 'antd'
import { useMutation, useApolloClient } from '@apollo/client'
import { UPDATE_SETTINGS } from '../actions'
import UpdateNotice from '../../Utils/UpdateNotice'
import { LocationSettingProps } from '../Settings'
import OrderTypeContainer from '../OrderTypeContainer'
import { BoltIcon, ClockIcon } from '@slerp/assets'

const Details = ({
  merchant,
  store,
  disabled = false
}: LocationSettingProps) => {
  const [form] = Form.useForm()
  const [asapEnabled, setAsapEnabled] = useState(store.settings.asap_enabled)
  const [asapOnly, setAsapOnly] = useState(store.settings.asap_only)
  const [updateSetting, { loading, error }] = useMutation(UPDATE_SETTINGS, {
    fetchPolicy: 'no-cache'
  })
  const client = useApolloClient()

  const onFinish = () => {
    if (form.validateFields()) {
      message.loading('Updating... Please wait.')
      updateSetting({
        variables: {
          merchant_id: merchant.id,
          id: store.id,
          settings: {
            asap_enabled: asapEnabled,
            asap_only: asapOnly
          }
        }
      })
        .then((result) => {
          message.destroy()
          client.resetStore()
          message.success('Same day details settings updated', 1)
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Unable to update Same day details settings due to ${error.message}`
          )
        })
    }
  }

  const toggleAsapEnabled = () => {
    setAsapEnabled(!asapEnabled)
  }

  const toggleAsapOnly = () => {
    setAsapOnly(!asapOnly)
  }

  return (
    <Form layout='vertical' form={form} onFinish={onFinish}>
      <PageHeader title='Details' className='settings-title' />
      <OrderTypeContainer
        enabled={asapEnabled}
        checked={asapEnabled}
        onChange={toggleAsapEnabled}
        onCardClick={toggleAsapEnabled}
        disabled={disabled}
      >
        <div className='icon-container selection'>
          <BoltIcon />
        </div>
        <div className='details'>
          <p>Asap Orders</p>
          <span>Same day</span>
        </div>
      </OrderTypeContainer>
      <OrderTypeContainer
        enabled={!asapOnly}
        checked={!asapOnly}
        onChange={toggleAsapOnly}
        onCardClick={toggleAsapOnly}
        disabled={disabled}
      >
        <div className='icon-container'>
          <ClockIcon />
        </div>
        <div className='details'>
          <p>Scheduled Orders</p>
          <span>Same day</span>
        </div>
      </OrderTypeContainer>
      <Divider />
      <Form.Item>
        <Button
          title='Save'
          htmlType='submit'
          className='_ml-auto _center-vertical'
          disabled={disabled}
        >
          Save
        </Button>
        <UpdateNotice updating={loading} updateError={error} />
      </Form.Item>
    </Form>
  )
}

export default Details
