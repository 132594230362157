import React, { ReactNode } from 'react'
import { Modal } from 'antd'

interface ModalProps {
  type: 'warning' | 'confirm'
  content: string | ReactNode
  centered?: boolean
  onCancel?: () => void
  onOk?: () => void
  okText?: string
  cancelText?: string
}

const { confirm, warning } = Modal

const CustomModal = ({
  type,
  content,
  centered = true,
  okCancel = false,
  onCancel,
  onOk,
  okText,
  cancelText
}: ModalProps) => {
  const baseProps = {
    okCancel,
    onCancel,
    onOk,
    cancelText: cancelText ? cancelText : 'Cancel',
    okText: okText ? okText : 'Ok',
    okButtonProps: {
      type: 'default'
    },
    cancelButtonProps: {
      type: 'ghost'
    }
  }

  if (type === 'warning')
    return warning({
      ...baseProps,
      title: <span className='cl-text-warning _text-weight-600'>Warning!</span>,
      content: <span data-testid='warning-text-quoted'>{content}</span>
    })

  if (type === 'confirm')
    return confirm({
      ...baseProps,
      title: <span className='cl-text-warning _text-weight-600'>Confirm!</span>,
      content: <span data-testid='confirm-text-quoted'>{content}</span>
    })
}

export default CustomModal
