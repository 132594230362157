import React, { useState } from 'react'
import { Switch, Form, Tooltip, Typography } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import TextFieldNumber from './../../../Widgets/TextFieldNumber'

const OrderLimit = (props: {
  inputName: string
  formRef?: any
  disabled?: boolean
}) => {
  const { formRef, disabled = false } = props
  const initialValue = (formRef && formRef.getFieldValue(props.inputName)) || 0
  const [enabled, setEnabled] = useState(initialValue > 0)

  const Label = () => (
    <Typography.Text
      style={{ display: 'inline-block', verticalAlign: 'middle' }}
    >
      Enable order limit
      <Tooltip
        placement='bottom'
        title='If turned on, the set limit will apply for each time slot and will block new orders once reached.'
      >
        <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
      </Tooltip>
    </Typography.Text>
  )

  return (
    <>
      <Form.Item style={{ marginBottom: '8px' }}>
        <Switch
          defaultChecked={enabled}
          onChange={(value) => setEnabled(value)}
          style={{ marginRight: '8px' }}
          disabled={disabled}
          data-testid='order-limit'
        />
        <Label />
      </Form.Item>
      {enabled && (
        <Form.Item
          name={props.inputName}
          rules={[
            {
              required: true,
              message: 'Please input a limit'
            },
            {
              min: 1,
              type: 'number',
              message: 'Order limit must be at least 1'
            },
            {
              validator: (_, val) => {
                if (!val) return Promise.resolve()

                if (!Number.isInteger(val)) {
                  return Promise.reject('Decimal is not allowed')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <TextFieldNumber
            inputType='number'
            step={1}
            value={0}
            disabled={disabled}
          />
        </Form.Item>
      )}
    </>
  )
}

export default OrderLimit
