import React, { useEffect } from 'react'
import { message } from 'antd'
import { useMutation, DocumentNode } from '@apollo/client'
import { Route, Redirect, useLocation } from 'react-router-dom'
import Loading from '../Loading'

interface Props {
  path: string
  redirectUrl: string
  connectMutation: DocumentNode
  connectVariables: {
    merchantId?: string
    storeId?: string
  }
}

export const StripeConnectRoute = ({
  path,
  redirectUrl,
  connectVariables,
  connectMutation
}: Props) => {
  const queryParams = new URLSearchParams(useLocation().search)
  const authCode = queryParams.get('code')

  const [connectAndUpdateStripeSettings, { loading: connectLoading }] =
    useMutation(connectMutation, {
      fetchPolicy: 'no-cache',
      refetchQueries: ['stripeConnect', 'getStripeData']
    })

  useEffect(() => {
    if (authCode) {
      connectAndUpdateStripeSettings({
        variables: {
          authCode,
          ...connectVariables
        }
      }).catch((error) => {
        if (error.message) {
          message.error(`Failed to connect stripe account. ${error.message}`, 1)
        } else {
          message.error('Failed to connect stripe account. Please try again', 1)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode])

  return (
    <Route
      path={path}
      render={({ location }) =>
        connectLoading ? (
          <Loading />
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
