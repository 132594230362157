import React, { useEffect, useState, useContext, ChangeEvent } from 'react'
import camelcase from 'lodash.camelcase'
import { useConfig } from '../Config'
import { useApolloClient } from '../Apollo'
import { Merchant, Store, Settings } from '../../shop/components/Landing/types'
import { ShopConfig } from '../../shop'
import {
  GET_STORE_PRODUCTS,
  GET_STORE_PRODUCT,
  GET_MERCHANT,
  GET_MERCHANT_IMAGES,
  UPDATE_MERCHANT,
  UPDATE_MERCHANT_IMAGE,
  GET_MERCHANT_MESSAGES
} from './queries'

import deepmerge from 'deepmerge'
import get from 'lodash/get'
import set from 'lodash/set'
import { useIframe } from '../Iframe'
import { message } from 'antd'

type MerchantGet = <T = any>(key: string, defaultValue?: T) => T
type MerchantImageGet = <T = string>(key: string) => T
type MerchantOnChange = <T = any>(key: string) => (value: T) => void
type MerchantOnChangeWithSanitizer = <T = any>(
  key: string,
  sanitizer: (values: T) => T
) => (value: T) => void
type MerchantOnChangeWithEvent = (
  key: string
) => (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
type MerchantImageOnChange<T = File> = (
  key: keyof MerchantImages
) => (value: T) => void

type Category = any
type Product = any

interface MerchantImages {
  cover_image: string
  header_image: string
  favicon: string
  avatar: string
  alternate_avatar: string
}

interface MerchantImagesLoadMap {
  header_image: boolean
  cover_image: boolean
  avatar: boolean
  alternate_avatar: boolean
  favicon: boolean
}

interface ContextType {
  merchant: Merchant
  merchantImages: MerchantImages
  merchantGet: MerchantGet
  merchantImageGet: MerchantImageGet
  merchantOnChange: MerchantOnChange
  merchantOnChangeWithSanitizer: MerchantOnChangeWithSanitizer
  merchantOnChangeWithEvent: MerchantOnChangeWithEvent
  merchantImageOnChange: MerchantImageOnChange
  stores: Store[]
  products: Product[]
  product: Product
  categories: Category[]
  store: Store
  config: ShopConfig
  merchantImagesLoadMap: MerchantImagesLoadMap
  merchantOnChangeWithEditor: (key: string, value: string) => void
}

const ShopContext = React.createContext<ContextType>({} as ContextType)

export const ShopProvider = ({ children }: any) => {
  const { config } = useConfig()
  const client = useApolloClient()
  const { regenerateIframeKey } = useIframe()
  const [stores, setStores] = useState<Store[]>([])
  const [merchant, setMerchant] = useState<Merchant>()

  const [merchantImages, setMerchantImages] = useState<MerchantImages>({
    cover_image: '',
    header_image: '',
    favicon: '',
    avatar: '',
    alternate_avatar: ''
  })

  const [merchantImagesLoadMap, setMerchantImagesLoadMap] =
    useState<MerchantImagesLoadMap>({
      cover_image: false,
      header_image: false,
      avatar: false,
      alternate_avatar: false,
      favicon: false
    })

  const [categories, setCategories] = useState<Category[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [product, setProduct] = useState<Product>()

  // Query merchant and its first store
  useEffect(() => {
    client
      .query({
        query: GET_MERCHANT,
        variables: { slug: config.domain }
      })
      .then((result) => {
        const [newMerchant] = result.data.merchants
        const newStores: Store[] = result.data.stores
        const preOrderSettings =
          newMerchant.pre_order_settings.length > 0
            ? newMerchant.pre_order_settings[0]
            : null
        setMerchant({ ...newMerchant, pre_order_settings: preOrderSettings })
        setStores(newStores)
      })
  }, [config.domain]) // eslint-disable-line

  useEffect(() => {
    if (!merchant || !merchant.id) return

    client
      .query({
        query: GET_MERCHANT_IMAGES,
        variables: { merchantId: merchant.id }
      })
      .then((result) => {
        setMerchantImages(result.data.getMerchantImages)
      })
  }, [merchant && merchant.id])

  useEffect(() => {
    client
      .query({
        query: GET_STORE_PRODUCTS,
        variables: {
          merchantSlug: config.domain,
          storeSlug: config.store
        }
      })
      .then((result) => {
        setCategories(result.data.categories)
        setProducts(result.data.store_variants)
      })
  }, [config.domain, config.store]) // eslint-disable-line

  // Query merchant's first product to feature
  useEffect(() => {
    client
      .query({
        query: GET_STORE_PRODUCT,
        variables: {
          merchantSlug: config.domain,
          storeSlug: config.store
        }
      })
      .then((result) => {
        const [product] = result.data.products
        setProduct(product)
      })
  }, [config.domain, config.store]) // eslint-disable-line

  useEffect(() => {
    if (!merchant || !merchant.id) return

    client
      .query({
        query: GET_MERCHANT_MESSAGES,
        variables: {
          merchantId: merchant.id
        }
      })
      .then((result) => {
        const { getMerchantMessages } = result.data
        console.log('getMerchantMessages', getMerchantMessages)
      })
  }, [merchant && merchant.id]) // eslint-disable-line

  const persistMerchant = (patchedMerchant: Partial<Settings>) => {
    const patched =
      'welcome_message_header' in patchedMerchant
        ? {
            welcome_message_header: patchedMerchant.welcome_message_header
              ? `<h1>${patchedMerchant.welcome_message_header}</h1>`
              : ''
          }
        : patchedMerchant

    const setting = { ...merchant.setting, ...patched }

    client
      .mutate({
        mutation: UPDATE_MERCHANT,
        variables: { id: merchant.id, setting: patched }
      })
      .then(() => {
        setMerchant((prev) => deepmerge<Merchant>(prev, { setting }))
        regenerateIframeKey()
      })
      .catch((error) =>
        message.error(`Unable to update setting due to ${error}`, 5)
      )
  }

  const persistMerchantWithArrayValue = (
    patchedMerchant: Partial<Settings>
  ) => {
    const setting = { ...merchant.setting, ...patchedMerchant }
    const updatedMerchant = { ...merchant, setting: setting }

    setMerchant(updatedMerchant)

    client
      .mutate({
        mutation: UPDATE_MERCHANT,
        variables: { id: merchant.id, setting: patchedMerchant }
      })
      .then(() => regenerateIframeKey())
      .catch((error) =>
        message.error(`Unable to update setting due to ${error}`, 5)
      )
  }

  /**
   * Utility to safely get a value from Merchant
   */
  const merchantGet: MerchantGet = (key, defaultValue) => {
    const value = get(merchant?.setting, key, defaultValue)

    if (key === 'welcome_message_header') {
      return value.match(/<h1>(.*?)<\/h1>/i)?.[0] ?? ''
    }

    return value
  }

  /**
   * Utility to safely get a value from MerchantImage
   */
  const merchantImageGet: MerchantGet = (key) => {
    return get(merchantImages, key, '')
  }

  /**
   * Utility to create an event handler
   */
  const merchantOnChange: MerchantOnChange = (key) => {
    return (value) => {
      const patchedMerchant = set({}, key, value)
      persistMerchant(patchedMerchant)
    }
  }

  /**
   * Utility to create a value sanitizer
   */
  const merchantOnChangeWithSanitizer: MerchantOnChangeWithSanitizer = (
    key,
    sanitizer
  ) => {
    return (value) => {
      const sanitizedValue = sanitizer(value)
      const patchedMerchant = set({}, key, sanitizedValue)

      if (Array.isArray(value)) {
        persistMerchantWithArrayValue(patchedMerchant)
      } else {
        persistMerchant(patchedMerchant)
      }
    }
  }

  /**
   * Utility to create an event handler
   */
  const merchantOnChangeWithEvent: MerchantOnChangeWithEvent = (key) => {
    return (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const patchedMerchant = set({}, key, evt.target.value)

      persistMerchant(patchedMerchant)
    }
  }

  const merchantOnChangeWithEditor = (key: string, value: string) => {
    const patchedMerchant = set({}, key, value)

    persistMerchant(patchedMerchant)
  }

  /**
   * Utility to safely set a value for MerchantImage
   */
  const merchantImageOnChange: MerchantImageOnChange = (
    key: keyof MerchantImages
  ) => {
    return async (file) => {
      // file will result to undefined if uploaded file type is not a jpg/png
      if (file === undefined) return null
      if (key === 'favicon' && (await validateImageRatio(file))) return null

      const modifiedFile = new File([file], file.name.replace(/\+/, '%2B'))
      const toBase64 = (file: File) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })

      const updatedMerchantImages = deepmerge<MerchantImages>(merchantImages, {
        [key]: await toBase64(modifiedFile)
      })

      const variables = {
        merchantId: merchant?.id,
        field: camelcase(key),
        image: modifiedFile
      }

      setMerchantImagesLoadMap({ ...merchantImagesLoadMap, [key]: true })
      setMerchantImages(updatedMerchantImages)

      client
        .mutate({
          mutation: UPDATE_MERCHANT_IMAGE,
          variables
        })
        .then(() => {
          setMerchantImagesLoadMap({ ...merchantImagesLoadMap, [key]: false })
          regenerateIframeKey()
        })
    }
  }

  const validateImageRatio = async (file: File) => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.src = URL.createObjectURL(file)
      img.onload = () => {
        const width = img.width
        const height = img.height
        const aspectRatio = 1

        if (width < 32 && height < 32) {
          return reject('image should be atleast 32x32 in size')
        } else if (width === height) {
          return resolve(false)
        } else if (aspectRatio === width / height) {
          return resolve(false)
        } else {
          return reject('aspect ratio is not 1:1')
        }
      }
      img.onerror = (error) => reject(error)
    }).catch((err) =>
      message.error(`I was unable to upload an image. This is due to ${err}`)
    )
  }

  if (!stores.length) return null

  return (
    <ShopContext.Provider
      value={{
        merchant,
        merchantImages,
        merchantGet,
        merchantImageGet,
        merchantOnChange,
        merchantOnChangeWithEvent,
        merchantOnChangeWithSanitizer,
        merchantImageOnChange,
        stores,
        store: stores[0],
        products,
        product,
        categories,
        config,
        merchantImagesLoadMap,
        merchantOnChangeWithEditor
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

export const useShop = () => useContext(ShopContext)
