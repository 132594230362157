import { useState } from 'react'
import { Button, Form, Radio, Space, Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { requiredRule, nonZeroRule, nonNegativeRule } from './Rules'
import { TextFieldNumber } from './../Widgets'

const LoyaltyBonusForm = (props) => {
  const {
    bonus,
    stores,
    loyaltyCards,
    closeFormDrawer,
    submitDisabled,
    createBonus,
    updateBonus,
    form
  } = props

  const getValidStores = (loyaltyId) => {
    if (!loyaltyId) return []
    const card = (loyaltyCards || []).find((card) => card.id === loyaltyId)
    if (card?.all_stores_enabled) return stores
    if (card?.store_ids) {
      return (stores || []).filter((store) => card.store_ids.includes(store.id))
    }
    return []
  }

  const [storeOptions, setStoreOptions] = useState(
    bonus ? getValidStores(bonus?.loyalty_card?.id) : []
  )

  const handleLoyaltyCardChange = (id) => {
    const validStores = getValidStores(id)
    setStoreOptions(validStores)
    form.setFieldsValue({
      store_id: null
    })
  }

  return (
    <>
      <div className='loyalty-bonus-header'>
        <div className='loyalty-bonus-drawer-title'>
          <p className='ant-drawer-title _mb-0 -secondary'>
            {bonus ? 'Update' : 'Add'} loyalty bonus
          </p>
        </div>
      </div>
      <div className='loyalty-bonus-actions'>
        <Button
          type='primary'
          className='discard'
          ghost
          onClick={() => {
            closeFormDrawer()
          }}
        >
          Discard
        </Button>
        <Button
          type='primary'
          onClick={() => {
            form.submit()
          }}
          disabled={submitDisabled}
        >
          {bonus ? 'Update' : 'Create'} Bonus
        </Button>
      </div>
      <Form
        form={form}
        className='_pt-24 _pl-24 _pr-24'
        layout='vertical'
        onFinish={bonus ? updateBonus : createBonus}
        data-testid='loyalty-bonus-form'
      >
        <Form.Item name='type' label='Bonus type' rules={requiredRule}>
          <Radio.Group data-testid='bonusType'>
            <Space direction='vertical' align='start'>
              <Radio value='account_creation'>Account Creation</Radio>
              <Radio value='first_purchase'>First Purchase</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name='loyalty_card_id'
          label='Loyalty Card'
          rules={requiredRule}
        >
          <Select
            className='_mt-10'
            placeholder='Select loyalty card'
            onChange={(id) => {
              handleLoyaltyCardChange(id)
            }}
          >
            {loyaltyCards?.map((card) => (
              <Select.Option key={card.id} value={card.id}>
                {card.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='store_id'
          label={
            <>
              <span>Location</span>
              <Tooltip
                title={
                  'This is the location where points will be awarded from and is for recording purposes only'
                }
              >
                <QuestionCircleOutlined className='_ml-8 _mr-8' />
              </Tooltip>
            </>
          }
          rules={requiredRule}
        >
          <Select className='_mt-10' placeholder='Select locations'>
            {storeOptions?.map((store) => (
              <Select.Option key={store.id} value={store.id}>
                {store.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='stamp_count'
          label='Number of stamps/points'
          rules={[...requiredRule, ...nonZeroRule, ...nonNegativeRule]}
        >
          <TextFieldNumber inputType='number' min={1} step={1} />
        </Form.Item>
      </Form>
    </>
  )
}

export default LoyaltyBonusForm
