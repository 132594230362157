import { gql } from '@apollo/client'

const CONFIG_QUERY = gql`
  query getStore($merchant: String!) {
    merchants(where: { slug: { _eq: $merchant } }) {
      theme
      stores(where: { archived_at: { _is_null: true } }, limit: 1) {
        slug
      }
    }
  }
`

export { CONFIG_QUERY }
