import React, { useState, useEffect } from 'react'
import { Row, Col, Form, DatePicker, Statistic, Select, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { SelectPrefixWrapper } from './../Widgets'
import { CalendarIcon, LocationIcon } from '@slerp/assets'
import { ApolloQueryResult, useLazyQuery, useQuery } from '@apollo/client'
import { useSlerp } from '@slerp/client'
import {
  QUERY_LOYALTY_CUSTOMERS_AGGREGATES,
  QUERY_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN,
  QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES,
  QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN,
  QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_AGGREGATES,
  QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN,
  QUERY_GET_LOYALTY_CUSTOMERS_LIST
} from './LoyaltyQueries'
import { QUERY_LOCATIONS } from './../Locations/LocationQueries'
import moment from 'moment'
import Loading from './../Utils/Loading'
import { CSVLink } from 'react-csv'
import { isNull, isEmpty } from 'lodash'
import { Decimal } from 'decimal.js'
import TopLoyaltyCustomers from './TopLoyaltyCustomers'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'
const queryDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss'

type LoyaltyStatTypes = {
  loyaltyCustomers: number
  stampsCollected: number
  rewardsRedeemed: number
  totalSpent: number
  averageBasketSize: number
}

export type LoyaltyCustomer = {
  id: string
  first_name: string
  last_name: string
  email: string
  marketing_opt_in: boolean
  stamps: {
    aggregate: {
      count: number
    }
  }
  redeemed_rewards: {
    aggregate: {
      count: number
    }
  }
  orders: {
    aggregate: {
      sum: {
        total: number
      }
    }
  }
}

type CSVCustomerData = {
  customer: string
  email: string
  number_of_stamps: number
  redeemed_rewards: number
  total_spent: number
  marketing_opt_in: boolean
}

type StoreType = {
  id: string
  name: string
  archived_at: null | string
}

type AggregateType = {
  aggregate: {
    count: number
  }
}

type AggregateSumTotalType = {
  aggregate: {
    count: number
    sum: {
      total: number
    }
  }
}

interface LoyaltyAggregatesType {
  stamps?: AggregateType
  redeemed_rewards?: AggregateType
  orders?: AggregateSumTotalType
}

type SortDirection = 'asc' | 'desc'
type SortBy =
  | 'stamps'
  | 'customer_all_time_rewards'
  | 'customer_rewards'
  | 'orders'

export interface HandleTableSort {
  sortBy: SortBy
  sortDirection: SortDirection
  id: string
}

const LoyaltyOverview = () => {
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(7, 'days')
  )
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [storeIds, setStoreIds] = useState<string[] | []>([])
  const [optInFilter, setOptInFilter] = useState(false)
  const [hasDateChanged, setHasDateChanged] = useState(false)
  const [activeSort, setActiveSort] = useState('total-spent-desc')
  const [currentPage, setCurrentPage] = useState(1)
  const [customers, setCustomers] = useState([])
  const [customerAggregateQuery, setCustomerAggregateQuery] = useState(
    QUERY_LOYALTY_CUSTOMERS_AGGREGATES
  )
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<string>('all_stores')

  const changeDates = (dates: Array<moment.Moment>) => {
    const [changedStartDate, changedEndDate] = dates

    if (startDate !== changedStartDate) {
      setStartDate(changedStartDate)
      setHasDateChanged(true)
    }
    if (endDate !== changedEndDate) {
      setEndDate(changedEndDate)
      setHasDateChanged(true)
    }
  }

  const { user } = useSlerp()

  const { data: storesData, loading: storesLoading } = useQuery(
    QUERY_LOCATIONS,
    {
      variables: {
        merchantSlug: user.merchant.slug
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        loadCustomersAggregate()
      }
    }
  )

  useEffect(() => {
    switch (selectedDropdownOption) {
      case 'all_stores':
        const loyaltyCustomerAndBulkLoyaltyCustomerAggregateQuery = optInFilter
          ? QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN
          : QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_AGGREGATES
        setCustomerAggregateQuery(
          loyaltyCustomerAndBulkLoyaltyCustomerAggregateQuery
        )
        break

      case 'bulk_operation':
        const bulkLoyaltyCustomerAggregateQuery = optInFilter
          ? QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN
          : QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES
        setCustomerAggregateQuery(bulkLoyaltyCustomerAggregateQuery)
        break

      default:
        const loyaltyCustomerAggregateQuery = optInFilter
          ? QUERY_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN
          : QUERY_LOYALTY_CUSTOMERS_AGGREGATES
        setCustomerAggregateQuery(loyaltyCustomerAggregateQuery)
        break
    }
  }, [selectedDropdownOption, optInFilter])

  const [
    loadCustomersAggregate,
    {
      data: loyaltyCustomersAggregateData,
      refetch: loyaltyCustomersAggregateRefetch,
      loading: loyaltyCustomersAggregateLoading
    }
  ] = useLazyQuery(customerAggregateQuery, {
    variables: {
      merchantId: user.merchant.id,
      startDate: startDate.startOf('day').format(queryDateTimeFormat),
      endDate: endDate.endOf('day').format(queryDateTimeFormat),
      storeIds: storeIds
    },
    onCompleted: () => {
      loadLoyaltyCustomersList()
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    if (
      loyaltyCustomersAggregateData &&
      loyaltyCustomersAggregateData.customers
    ) {
      const filtered_customers = loyaltyCustomersAggregateData.customers.filter(
        (customer: LoyaltyCustomer) =>
          customer.stamps.aggregate.count !== 0 ||
          customer.redeemed_rewards.aggregate.count !== 0
      )
      setCustomers(filtered_customers)
    }
  }, [loyaltyCustomersAggregateData])

  const [
    loadLoyaltyCustomersList,
    {
      data: loyaltyCustomersListData,
      refetch: refetchLoyaltyCustomersList,
      loading: loyaltyCustomersListLoading
    }
  ] = useLazyQuery(QUERY_GET_LOYALTY_CUSTOMERS_LIST, {
    variables: {
      merchantId: user.merchant.id,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      storeIds: storeIds,
      customerIds: (customers || []).map(({ id }) => id),
      optInFilter,
      limit: 100,
      offset: 0,
      sortBy: 'orders',
      sortDirection: 'desc'
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const onCSVDownload = () => {
    const items = customers?.reduce(
      (acc: CSVCustomerData[], value: LoyaltyCustomer) => {
        const customer = {
          customer: `${value.first_name} ${value.last_name}`,
          email: `${value.email}`,
          number_of_stamps: value?.stamps?.aggregate?.count,
          redeemed_rewards: value?.redeemed_rewards?.aggregate?.count,
          total_spent: value?.orders?.aggregate?.sum?.total,
          marketing_opt_in: value?.marketing_opt_in
        }
        return [...acc, customer]
      },
      []
    )
    if (items && items.length > 0) {
      const replacer = (key: string, value: string) =>
        isNull(value) ? 0 : value
      const header = Object.keys(items[0])
      const humanized_header = header.map((title) => title.replace('_', ' '))
      const csv = [
        humanized_header.join(','),
        ...items.map((row: any) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(',')
        )
      ].join('\r\n')
      return csv
    }
    return ''
  }

  useEffect(() => {
    if (isEmpty(storesData)) return
    const storeDataIds = storesData.stores.map(({ id }: StoreType) => id)

    setStoreIds(storeDataIds)
  }, [storesData])

  const handleStoreChange = (value: string) => {
    setSelectedDropdownOption(value)
    switch (value) {
      case 'all_stores':
        const storeDataIds = storesData.stores.map(({ id }: StoreType) => id)

        setStoreIds(storeDataIds)
        break

      case 'bulk_operation':
        setStoreIds([])
        break

      default:
        setStoreIds([value])
        break
    }
    setActiveSort('total-spent-desc')
    setCurrentPage(1)
  }

  // To-do: define query result type
  const handleTableSort = ({
    sortBy,
    sortDirection,
    id
  }: HandleTableSort): Promise<ApolloQueryResult<any>> | undefined => {
    if (!refetchLoyaltyCustomersList) return
    setActiveSort(id)
    return refetchLoyaltyCustomersList({
      sortBy,
      sortDirection
    })
  }

  const handlePageChangeRefetch = ({
    offset,
    limit
  }: {
    offset: number
    limit: number | undefined
  }) => {
    if (!refetchLoyaltyCustomersList) return

    return refetchLoyaltyCustomersList({
      offset,
      limit
    })
  }

  return (
    <>
      <Row justify='space-between' className='_mt-16 _mb-0'>
        <Row gutter={8} className='_mb-0'>
          <Col className='orders-form-item _mb-0 _ml-4'>
            {storesData?.stores && !storesLoading ? (
              <SelectPrefixWrapper prefixicon={<LocationIcon />}>
                <Select
                  placeholder='Filter by Location'
                  onChange={handleStoreChange}
                  defaultValue='all_stores'
                  allowClear={false}
                  className='control'
                  style={{
                    minWidth: 'max-content'
                  }}
                  dropdownStyle={{
                    minWidth: '200px'
                  }}
                >
                  <Select.Option value={'all_stores'} key={'all_stores'}>
                    All locations
                  </Select.Option>
                  <Select.Option
                    value={'bulk_operation'}
                    key={'bulk_operation'}
                  >
                    Bulk Operation
                  </Select.Option>
                  {storesData.stores.map((store: StoreType) => (
                    <Select.Option value={store.id} key={store.id}>
                      {store.name}
                    </Select.Option>
                  ))}
                </Select>
              </SelectPrefixWrapper>
            ) : (
              '...'
            )}
          </Col>
          <Col span={12}>
            <Form.Item name='date'>
              <SelectPrefixWrapper prefixicon={<CalendarIcon />}>
                <RangePicker
                  format={dateFormat}
                  allowClear={false}
                  onChange={(e: any) => {
                    changeDates(e)
                  }}
                  onOpenChange={(open) => {
                    if (open) return
                    if (!hasDateChanged) return
                    if (
                      refetchLoyaltyCustomersList === undefined ||
                      loyaltyCustomersAggregateRefetch === undefined
                    )
                      return
                    refetchLoyaltyCustomersList({
                      startDate: moment(startDate)
                        .startOf('day')
                        .format('YYYY-MM-DD'),
                      endDate: moment(endDate).endOf('day').format('YYYY-MM-DD')
                    })
                    loyaltyCustomersAggregateRefetch({
                      startDate: moment(startDate)
                        .startOf('day')
                        .format(queryDateTimeFormat),
                      endDate: moment(endDate)
                        .endOf('day')
                        .format(queryDateTimeFormat)
                    })
                    setHasDateChanged(false)
                    setActiveSort('total-spent-desc')
                    setCurrentPage(1)
                  }}
                  placeholder={['Start date', 'End date']}
                  defaultValue={[startDate, endDate]}
                  className='_pl-48'
                />
              </SelectPrefixWrapper>
            </Form.Item>
          </Col>
        </Row>
        <Col>
          <CSVLink
            data={onCSVDownload()}
            filename={`${
              user?.merchant?.slug
            }_customer_loyalty_stats_${Date.now()}.csv`}
            className='ant-btn'
            data-testid='customer-csv-export'
          >
            Export Customers
          </CSVLink>
        </Col>
      </Row>
      {!loyaltyCustomersAggregateLoading ? (
        <LoyaltyStats
          loyaltyCustomers={customers.length}
          stampsCollected={customers?.reduce(
            (acc: number, cust: LoyaltyAggregatesType) => {
              if (cust?.stamps?.aggregate?.count) {
                return acc + cust?.stamps?.aggregate?.count
              }
              return acc
            },
            0
          )}
          rewardsRedeemed={customers?.reduce(
            (acc: number, cust: LoyaltyAggregatesType) => {
              return acc + (cust?.redeemed_rewards?.aggregate?.count || 0)
            },
            0
          )}
          totalSpent={customers?.reduce(
            (acc: number, cust: LoyaltyAggregatesType) => {
              if (cust?.orders?.aggregate?.sum?.total) {
                const total = cust?.orders?.aggregate?.sum?.total
                return total + acc
              }
              return acc
            },
            0
          )}
          averageBasketSize={
            new Decimal(
              customers?.reduce((acc: number, cust: LoyaltyAggregatesType) => {
                if (cust?.orders?.aggregate?.sum?.total) {
                  const total = cust?.orders?.aggregate?.sum?.total
                  return total + acc
                }
                return acc
              }, 0)
            )
              .dividedBy(
                new Decimal(
                  customers?.reduce(
                    (acc: number, cust: LoyaltyAggregatesType) => {
                      if (cust?.orders?.aggregate?.count) {
                        const total = cust?.orders?.aggregate?.count
                        return total + acc
                      }
                      return acc
                    },
                    0
                  )
                )
              )
              .toNumber() || 0
          }
        />
      ) : (
        <Loading />
      )}
      <TopLoyaltyCustomers
        loading={loyaltyCustomersListLoading}
        data={loyaltyCustomersListData}
        customers={customers}
        toggleOptIn={() => setOptInFilter(!optInFilter)}
        optInFilter={optInFilter}
        customerAggregateRefetch={loyaltyCustomersAggregateRefetch}
        activeSort={activeSort}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleTableSort={handleTableSort}
        handlePageChangeRefetch={handlePageChangeRefetch}
      />
    </>
  )
}

const LoyaltyStats = ({
  loyaltyCustomers,
  stampsCollected,
  rewardsRedeemed,
  totalSpent,
  averageBasketSize
}: LoyaltyStatTypes) => {
  const columnSpan = 4
  return (
    <Row data-testid='loyaltyStats' justify='center'>
      <Col className='container-style _pl-0 _pr-0' flex='0 0 100%'>
        <Row className='_pt-24 _pr-8 _pb-32 _pl-8 _mb-0' justify='space-around'>
          <Col span={columnSpan} className='_text-center'>
            <Statistic value={loyaltyCustomers} className='_mb-16' />
            <div className='_cl-text-secondary'>
              CUSTOMERS
              <Tooltip title='This is the number of customers who have collected loyalty points during the selected time period'>
                <QuestionCircleOutlined className='_ml-4' />
              </Tooltip>
            </div>
          </Col>
          <Col span={columnSpan} className='_text-center'>
            <Statistic value={stampsCollected} className='_mb-16' />
            <div className='_cl-text-secondary'>
              STAMPS COLLECTED
              <Tooltip title='This is the number of stamps collected during the selected time period'>
                <QuestionCircleOutlined className='_ml-4' />
              </Tooltip>
            </div>
          </Col>
          <Col span={columnSpan} className='_text-center'>
            <Statistic value={rewardsRedeemed} className='_mb-16' />
            <div className='_cl-text-secondary'>
              REWARDS REDEEMED
              <Tooltip title='This is the number of Rewards customers have redeemed during the selected time period'>
                <QuestionCircleOutlined className='_ml-4' />
              </Tooltip>
            </div>
          </Col>
          <Col span={columnSpan} className='_text-center'>
            <Statistic
              value={totalSpent}
              prefix={'£'}
              precision={2}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>
              TOTAL SPENT
              <Tooltip title='This is the total £ spent by customers registered for a loyalty card in the selected time period'>
                <QuestionCircleOutlined className='_ml-4' />
              </Tooltip>
            </div>
          </Col>
          <Col span={columnSpan} className='_text-center'>
            <Statistic
              value={averageBasketSize}
              prefix={'£'}
              precision={2}
              className='_mb-16'
            />
            <div className='_cl-text-secondary'>
              AVERAGE BASKET SIZE
              <Tooltip title='This is the average basket (order) size for customers registered for a loyalty card in the selected time period'>
                <QuestionCircleOutlined className='_ml-4' />
              </Tooltip>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default LoyaltyOverview
