import React, { useState } from 'react'
import Form from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input/Input'
import styled from '@emotion/styled'
import Switch from 'antd/lib/switch'
import { useSession } from 'packages/@slerp/accounts'
import {
  CREATE_MERCHANT_MESSAGE,
  TOGGLE_MESSAGE_ARCHIVE_STATUS,
  UPDATE_MERCHANT_MESSAGE
} from '../contexts/Shop/queries'
import { useMutation } from '@apollo/client'
import Button from 'antd/lib/button'
import { message as antdMessage } from 'antd/lib'
import { noWhiteSpaceRule } from 'components/Login/rules'

type OrderType = 'sameday' | 'preorder' | 'all'
export type MessageType = 'announcement' | 'warning'

interface Props {
  orderType: OrderType
  messageType: MessageType
  messages: Array<Message>
}

export interface Message {
  id: string
  title: string
  description: string
  orderType: OrderType
  messageType: MessageType
  archivedAt: string | null
}

interface FormValues {
  title: string
  description: string
  archivedAt: boolean | null
}

interface ArchiveMessageResult {
  archived_at: Date | null
  id: string
  title: string
}

const textAreaRule = [
  {
    validator: (_, val) => {
      if (!val) {
        return Promise.resolve()
      }

      if (val.match(/<\/?[^>]+(>|$)/g)?.length) {
        return Promise.reject('HTML tags are not allowed')
      }

      return Promise.resolve()
    }
  },
  ...noWhiteSpaceRule
]

const generateMessageName = (
  orderType: OrderType,
  messageType: MessageType
) => {
  switch (orderType) {
    case 'sameday':
      return `Same day ${messageType}`

    case 'preorder':
      return `Pre-order ${messageType}`

    default:
      return `Same day ${messageType}`
  }
}

const MessageForm = ({ orderType, messageType, messages }: Props) => {
  const [form] = useForm()
  const { merchant } = useSession()

  const message = messages?.find(
    (msg) =>
      msg.orderType === orderType.toUpperCase() &&
      msg.messageType === messageType.toUpperCase()
  )

  const componentName = `${orderType}-${messageType}`
  const messageName = generateMessageName(orderType, messageType)

  const [createMerchantMessage, { loading: isCreateLoading }] = useMutation<{
    createMerchantMessage: Message
  }>(CREATE_MERCHANT_MESSAGE, {
    refetchQueries: ['getMerchantMessages']
  })

  const [updateMerchantMessage, { loading: isUpdateLoading }] = useMutation<{
    update_messages: { returning: Array<Message> }
  }>(UPDATE_MERCHANT_MESSAGE)

  const [toggleArchiveMerchantMessage, { loading: isToggleLoading }] =
    useMutation<{
      update_messages: { returning: Array<ArchiveMessageResult> }
    }>(TOGGLE_MESSAGE_ARCHIVE_STATUS)

  const [titleRemainingChars, setTitleRemainingChars] = useState<number>(
    30 - (message?.title.length ?? 0)
  )
  const [descriptionRemainingChars, setDescriptionRemainingChars] =
    useState<number>(300 - (message?.description.length ?? 0))
  const [showForm, setShowForm] = useState<boolean>(
    message !== undefined ? !message?.archivedAt : false
  )
  const onFinish = (values: FormValues) => {
    const { title, description } = values
    const mutation = message ? updateMerchantMessage : createMerchantMessage
    const variables = message
      ? { id: message.id, title, description }
      : {
          merchantId: merchant.id,
          title,
          description,
          messageType: messageType.toUpperCase(),
          orderType: orderType.toUpperCase()
        }

    mutation({ variables })
      .then(handleMutationSuccess)
      .catch(handleMutationError)
  }

  const handleMutationSuccess = (result: any) => {
    const updatedMessage = message
      ? result.data?.update_messages?.returning[0]
      : result.data?.createMerchantMessage
    form.setFieldsValue({
      title: updatedMessage.title,
      description: updatedMessage.description
    })
    antdMessage.success(
      `
      ${messageName} message has been
      ${message ? 'updated' : 'created'}`,
      5
    )
  }

  const handleMutationError = (error: any) => {
    antdMessage.error(
      `I was not able to ${
        message ? 'update' : 'create'
      } ${messageName} message due to: ${error}`,
      10
    )
  }

  const toggleMessageStatus = (checked: boolean) => {
    if (!message) {
      setShowForm(checked)
      return
    }

    toggleArchiveMerchantMessage({
      variables: { id: message.id, archivedAt: checked ? null : new Date() }
    })
      .then((result) => {
        const updatedMessage = result.data?.update_messages?.returning[0]
        setShowForm(!updatedMessage?.archived_at)
        antdMessage.success(
          `${messageName} message has been ${checked ? 'enabled' : 'disabled'}`
        )
      })
      .catch((error) => {
        antdMessage.error(
          `I was not able to ${
            checked ? 'enable' : 'disable'
          } "${messageName}" message due to: ${error}`,
          10
        )
      })
  }

  const handleResetForm = () => {
    setTitleRemainingChars(30 - (message?.title.length ?? 0))
    setDescriptionRemainingChars(300 - (message?.description.length ?? 0))
    form.resetFields()
  }

  return (
    <Form
      data-testid={`${componentName}-form`}
      form={form}
      layout='vertical'
      scrollToFirstError
      onFinish={onFinish}
      initialValues={{
        title: message?.title,
        description: message?.description
      }}
    >
      <Col span={24} className='_pl-0 _pr-0'>
        <Header justify='space-between' align='middle' className='_mb-0'>
          <Col>
            {messageType === 'announcement'
              ? messageType
              : `${messageType} Message`}
          </Col>
          <Switch
            data-testid={`${componentName}-form-switch`}
            defaultChecked={showForm}
            onChange={toggleMessageStatus}
            loading={isToggleLoading}
          />
        </Header>
      </Col>

      {showForm && (
        <>
          <Row>
            <Col span={24}>
              <FormItem
                name='title'
                validateTrigger='onBlur'
                rules={textAreaRule}
                label='Title'
              >
                <Input
                  placeholder='Enter title here..'
                  data-testid={`${componentName}-form-title`}
                  maxLength={30}
                  onChange={(e) =>
                    setTitleRemainingChars(30 - e.target.value.length)
                  }
                />
              </FormItem>
              <RemainingChar>
                Remaining characters {titleRemainingChars}
              </RemainingChar>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <FormItem
                name='description'
                validateTrigger='onBlur'
                rules={textAreaRule}
                label='Description'
              >
                <Input.TextArea
                  placeholder='Enter description here..'
                  data-testid={`${componentName}-form-description`}
                  autoSize
                  maxLength={300}
                  onChange={(e) =>
                    setDescriptionRemainingChars(300 - e.target.value.length)
                  }
                />
              </FormItem>
              <RemainingChar>
                Remaining characters {descriptionRemainingChars}
              </RemainingChar>
            </Col>
          </Row>

          <Col span={24} className='_pl-0 _pr-0 _mt-24'>
            <Row justify='end' gutter={[12, 12]}>
              <Col>
                <Button
                  type='primary'
                  loading={isCreateLoading || isUpdateLoading}
                  onClick={handleResetForm}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType='submit'
                  loading={isCreateLoading || isUpdateLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Form>
  )
}

const Header = styled(Row)`
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;

  padding: 12.5px 0;
`

const RemainingChar = styled(Row)`
  font-size: 0.5625rem;
  position: absolute;
  bottom: -1.2rem;
  right: 0;
`

const FormItem = styled(Form.Item)`
  label {
    font-size: 14px;
  }
`

export default MessageForm
