import { createClient } from '@slerp/client'
import { buildUpdateAdvancedSettings } from '../../../actions'
import { uuid } from 'uuidv4'

interface Settings {
  additionalFees: {
    id: string
    name: string
    amount: number
    is_active: boolean
    amount_type: 'percentage' | 'fixed'
    fulfillment_types: {
      id: string
      pickup: boolean
      delivery: boolean
      table_ordering: boolean
    }
  }
  delivery_tipping_enabled: boolean
  pickup_tipping_enabled: boolean
  oat_tipping_enabled: boolean
  tip_percentages: number[]
}

interface UpdateStoreSettingsProps {
  merchantId: string
  storeId: string
  settings: Settings
  orderWithGoogle: boolean
  hasStoreIntegrationSettings: boolean
}

const UpdateStoreSettings = async ({
  merchantId,
  storeId,
  settings,
  orderWithGoogle,
  hasStoreIntegrationSettings
}: UpdateStoreSettingsProps) => {
  const apiKey = localStorage.getItem('token') || ''
  const client = createClient(apiKey)

  return client.mutate({
    mutation: buildUpdateAdvancedSettings(hasStoreIntegrationSettings),
    variables: {
      id: uuid(),
      merchant_id: merchantId,
      store_id: storeId,
      settings: settings,
      order_with_google: orderWithGoogle
    }
  })
}

export default UpdateStoreSettings
