import React from 'react'

export interface SignOutProps {
  children: React.ReactNode
}

export const SignOut = (props: SignOutProps) => {
  const signOut = () => {
    localStorage.removeItem('merchant')
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('hubspot-jwt')
    window.HubSpotConversations.clear({ resetWidget: true })
    window.HubSpotConversations.widget.remove()
    window.hsConversationsSettings = {
      loadImmediately: false
    }
  }

  return (
    <a href='/' onClick={signOut}>
      {props.children}
    </a>
  )
}
