import React, { useState, useEffect } from 'react'
import { useSession } from '@slerp/accounts'
import moment from 'moment-timezone'
import { Button, Col, DatePicker, message, Row } from 'antd'

const DATE_FORMAT = 'YYYY-MM-DD'

interface ExportFiltersProps {
  datePickerLabel?: string
  url: string
}

const ExportFilters = ({ datePickerLabel, url }: ExportFiltersProps) => {
  const { user } = useSession()
  const [startDate, setStartDate] = useState<moment.Moment | null | undefined>(
    moment()
  )
  const [endDate, setEndDate] = useState<moment.Moment | null | undefined>(
    moment()
  )
  const [enableExportStatement, setEnableExportStatement] = useState(true)

  useEffect(() => {
    const isStartEndDatesValid =
      (startDate || moment()) <= (endDate || moment())
    setEnableExportStatement(!isStartEndDatesValid)
    if (!isStartEndDatesValid) {
      message.warning('Invalid start and end dates!', 5)
    }
  }, [endDate, startDate])

  const startDateOnChangeHandler = (e: moment.Moment | null | undefined) =>
    setStartDate(moment(e))
  const endDateOnChangeHandler = (e: moment.Moment | null | undefined) =>
    setEndDate(moment(e))
  const exportStatementUrl = () => {
    const start = (startDate || moment()).format(DATE_FORMAT)
    const end = (endDate || moment()).format(DATE_FORMAT)
    return `${url}/export?start=${start}&end=${end}&token=${user.api_key}`
  }

  return (
    <>
      <Row justify='center' gutter={[16, 16]} align='middle'>
        <Col>{datePickerLabel} Start Date</Col>
        <Col>
          <DatePicker
            allowEmpty
            defaultValue={startDate ? startDate : undefined}
            format={DATE_FORMAT}
            onChange={startDateOnChangeHandler}
          />
        </Col>
      </Row>
      <Row justify='center' gutter={[16, 24]} align='middle'>
        <Col>{datePickerLabel} End Date</Col>
        <Col>
          <DatePicker
            defaultValue={endDate ? endDate : undefined}
            format={DATE_FORMAT}
            onChange={endDateOnChangeHandler}
          />
        </Col>
      </Row>
      <Row justify='center' gutter={[16, 16]} align='middle'>
        <Col>
          <Button
            disabled={enableExportStatement}
            href={exportStatementUrl()}
            target='_blank'
            rel='noopener noreferrer'
          >
            Export
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ExportFilters
