import React, { useState, useMemo } from 'react'
import { uuid } from 'uuidv4'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import { Button, Col, Row, Tooltip } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import EmptyDateField from './EmptyDateField'
import SpecialDay from './SpecialDay'
import { SplitHour } from '../types'

interface AddSpecialSettingButtonProps {
  isAddButtonDisabled: boolean
  displaySpecialTimeField: () => void
}

interface SpecialOpeningDatesProps {
  shifts: SplitHour[] | []
  disabled: boolean
  updateDatedShifts: () => void
}

const DATE_FORMAT = 'YYYY-MM-DD'
const DAY_NAME_FORMAT = 'dddd'
const DEFAULT_SHIFT_SHAPE = {
  day_of_week: 'monday',
  start_time: '08:00:00',
  seconds_length: 3600,
  enabled: true,
  special_date: null,
  closed: false
}

const SpecialOpeningDates = ({
  shifts,
  disabled,
  updateDatedShifts
}: SpecialOpeningDatesProps) => {
  const groupShiftsByDate = useMemo(
    () => (datedShifts: SplitHour[]) =>
      groupBy(datedShifts, ({ special_date }: SplitHour) =>
        moment(special_date).format(DATE_FORMAT)
      ),
    []
  )
  const specialDates = groupShiftsByDate(shifts)
  const [isEmptyFieldVisible, setEmptyFieldVisible] = useState<boolean>(false)
  const toggleEmptyFieldFlag = () => {
    setEmptyFieldVisible(!isEmptyFieldVisible)
  }

  const addSpecialDate = (date: moment.Moment | null) => {
    const formattedDate = moment(date).format(DATE_FORMAT)
    const dayOfWeek = moment(date).format(DAY_NAME_FORMAT).toLowerCase()
    const editingDateShifts = [...(specialDates[formattedDate] || [])]

    const newDatedShifts = [
      ...editingDateShifts,
      {
        ...DEFAULT_SHIFT_SHAPE,
        day_of_week: dayOfWeek,
        special_date: formattedDate
      }
    ]
    updateDatedShifts([...shifts, ...newDatedShifts])
  }

  const updateSpecialDayShiftsState = (
    dateStamp: string,
    newShifts: SplitHour[]
  ) => {
    const updatingDatedShifts = shifts.filter(
      ({ special_date }: SplitHour) => special_date !== dateStamp
    )
    updateDatedShifts([...updatingDatedShifts, ...newShifts])
  }

  const removeDatedShifts = (dateStamp: string) => {
    const newDatedShifts = shifts.filter(
      ({ special_date }: SplitHour) => special_date !== dateStamp
    )

    updateDatedShifts(newDatedShifts)
  }

  const handleAddSpecialDate = (selectedDate: moment.Moment | null) => {
    addSpecialDate(selectedDate)
    toggleEmptyFieldFlag()
  }

  const datepickerDisabledDate = (current: moment.Moment) => {
    if (Object.keys({}).length > 0) {
      if (includes(Object.keys({}), current.format(DATE_FORMAT))) return true
    }
    return current && current < moment().startOf('day')
  }

  const AddSpecialSettingButton = (props: AddSpecialSettingButtonProps) => {
    const { isAddButtonDisabled, displaySpecialTimeField } = props
    const buttonText = 'Add special opening time'
    return isAddButtonDisabled ? (
      <Tooltip placement='right' title={'Please select a date first.'}>
        <Button
          disabled={isAddButtonDisabled}
          onClick={displaySpecialTimeField}
          icon={<PlusCircleOutlined />}
        >
          {buttonText}
        </Button>
      </Tooltip>
    ) : (
      <Button
        disabled={isAddButtonDisabled}
        onClick={displaySpecialTimeField}
        icon={<PlusCircleOutlined />}
      >
        {buttonText}
      </Button>
    )
  }

  const Header = () => (
    <Row>
      <Col span={4} />
      <Col span={16}>
        <Row className='_mb-0' gutter={[8, 0]}>
          <Col span={6}>Start</Col>
          <Col span={6}>End</Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <>
      {isEmptyFieldVisible && (
        <EmptyDateField
          handleDayChange={handleAddSpecialDate}
          datepickerDisabledDate={datepickerDisabledDate}
        />
      )}
      <Row>
        <AddSpecialSettingButton
          isAddButtonDisabled={isEmptyFieldVisible || disabled}
          displaySpecialTimeField={toggleEmptyFieldFlag}
        />
      </Row>

      {!!specialDates.length && <Header />}
      {Object.keys(specialDates).map((date: string) => (
        <SpecialDay
          key={uuid()}
          shifts={specialDates[date]}
          dateStamp={date}
          updateSpecialDayShifts={updateSpecialDayShiftsState}
          removeDatedShifts={removeDatedShifts}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default SpecialOpeningDates
