import {
  SlerpError,
  TokenError,
  StripeIntegrationError,
  UniquenessContraintError
} from 'errors'
import {
  REFUND_ORDER_AFTER_ACCEPT_INSUFFICIENT_STRIPE_FUND_ERROR_MESSAGE,
  REFUND_PENDING_ORDER_INSUFFICIENT_STRIPE_FUND_ERROR_MESSAGE,
  USER_SIGN_IN_CREDENTIALS_ERROR_MESSAGE,
  USER_PASSWORD_RESET_ERROR_MESSAGE,
  USER_DOES_NOT_EXISTS
} from './exactErrorMessages'
import {
  DISCOUNT_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT,
  PRODUCT_CATEGORY_NAME_UNIQUENESS_VIOLATION_EXCEPTION_MESSAGE_FRAGMENT,
  PRODUCT_NAME_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT,
  USER_EMAIL_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT,
  USER_EMAIL_UNIQUENESS_VIOLATION_EXCEPTION_ERROR_MESSAGE_FRAGMENT
} from './fragmentErrorMessage'

type SlerpObject =
  | 'Cart'
  | 'Order'
  | 'Discount'
  | 'Product'
  | 'Modifier'
  | 'Category'
  | 'User'
  | 'UserPassword'
  | 'Store'
  | 'Merchant'

type Payload = {
  origin: SlerpObject
  data: {
    error?: Error
    message: string
  }
}

// Converts error in the Slerp Controls context
const errorReducer = (payload: Payload): SlerpError => {
  switch (payload.origin) {
    case 'User':
      return userErrorReducer(payload)
    case 'UserPassword':
      return userResetErrorReducer(payload)
    case 'Merchant':
      return merchantErrorReducer(payload)
    case 'Order':
      return orderErrorReducer(payload)
    case 'Discount':
      return discountErrorReducer(payload)
    case 'Product':
      return productErrorReducer(payload)
    case 'Category':
      return categoryErrorReducer(payload)
    default:
      return new SlerpError(payload.data.message)
  }
}

const userErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message

  if (errorMessage === USER_SIGN_IN_CREDENTIALS_ERROR_MESSAGE) {
    return new TokenError('You have entered an invalid username or password')
  }

  return new SlerpError('Unable to sign in. Please try again.')
}

const userResetErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message
  if (errorMessage === USER_PASSWORD_RESET_ERROR_MESSAGE) {
    return new TokenError('You have entered an email that does not exist')
  } else if (errorMessage === USER_DOES_NOT_EXISTS) {
    return new TokenError('You have entered an email that does not exist')
  }

  return new SlerpError('Unable reset password. Please try again.')
}

const merchantErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message

  if (
    errorMessage.includes(
      USER_EMAIL_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT
    )
  ) {
    return new UniquenessContraintError(
      'The email address you entered is already registered with an existing user. Please use a unique email address'
    )
  }

  if (
    errorMessage.includes(
      USER_EMAIL_UNIQUENESS_VIOLATION_EXCEPTION_ERROR_MESSAGE_FRAGMENT
    )
  ) {
    return new UniquenessContraintError(
      'The email address you entered is already registered with an existing user. Please use a unique email address'
    )
  }

  return new SlerpError(message)
}

const orderErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  switch (error?.message || message) {
    case REFUND_PENDING_ORDER_INSUFFICIENT_STRIPE_FUND_ERROR_MESSAGE:
      return new StripeIntegrationError(
        'You are unable to refund this order due to insufficient funds in your Stripe account balance'
      )
    case REFUND_ORDER_AFTER_ACCEPT_INSUFFICIENT_STRIPE_FUND_ERROR_MESSAGE:
      return new StripeIntegrationError(
        'You are unable to refund this order due to insufficient funds in your Stripe account balance'
      )
    default:
      return new SlerpError(message)
  }
}

const discountErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message

  if (
    errorMessage.includes(DISCOUNT_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT)
  ) {
    return new UniquenessContraintError(
      'This discount code already exists. Please try entering a unique code'
    )
  }

  return new SlerpError(message)
}

const productErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message

  if (
    errorMessage.includes(
      PRODUCT_NAME_UNIQUENESS_VIOLATION_ERROR_MESSAGE_FRAGMENT
    )
  ) {
    return new UniquenessContraintError(
      'A product with this name already exists. Please try entering a unique product name'
    )
  }

  return new SlerpError(message)
}

const categoryErrorReducer = ({
  data: { error, message }
}: Payload): SlerpError => {
  const errorMessage = error?.message || message

  if (
    errorMessage.includes(
      PRODUCT_CATEGORY_NAME_UNIQUENESS_VIOLATION_EXCEPTION_MESSAGE_FRAGMENT
    )
  ) {
    return new UniquenessContraintError(
      'A product category with this name already exists. Please try entering a unique product category name'
    )
  }

  return new SlerpError(message)
}

export default errorReducer
