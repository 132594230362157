/**
 * Used by FormGroup internally to maintain collapse state
 */
import React, { useState, useContext } from 'react'

interface CollapsibleContextType {
  isCollapsed: boolean
  isCollapsible: boolean
  toggleCollapse: () => void
}

const CollapsibleContext = React.createContext<CollapsibleContextType>(
  {} as CollapsibleContextType
)

interface CollapsibleProps {
  isCollapsible: boolean
}

const Collapsible: React.FC<CollapsibleProps> = ({
  children,
  isCollapsible
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <CollapsibleContext.Provider
      value={{
        isCollapsed,
        isCollapsible: isCollapsible,
        toggleCollapse
      }}
    >
      {children}
    </CollapsibleContext.Provider>
  )
}

const useCollapsible = () => {
  return useContext(CollapsibleContext)
}

export { Collapsible, useCollapsible }
