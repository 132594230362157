/**
 * - Wraps react-frame-component
 * - Enables emotion inside iframes
 * - Adds auto-sizing feature
 */
import React, { useState } from 'react'
import { CacheProvider } from '@emotion/core'
import createCache from '@emotion/cache'
import weakMemoize from '@emotion/weak-memoize'
import Frame, {
  FrameContextConsumer,
  FrameContext,
  FrameComponentProps
} from 'react-frame-component'
import { FrameAutoSizer } from './FrameAutoSizer'

// type IframeProps = React.IframeHTMLAttributes<HTMLIFrameElement>

interface OwnProps {
  id: string
  // Sizer key so we re-compute frame size when breakpoints change
  sizer: string
}

type Props = FrameComponentProps & OwnProps

interface MemoizeConfig {
  container: HTMLElement
  id: string
}

/**
 * @source https://github.com/emotion-js/emotion/issues/560#issuecomment-450713994
 */
let memoizedCreateCacheWithContainer = weakMemoize(
  ({ container, id }: MemoizeConfig) => {
    let newCache = createCache({ container, key: id })
    return newCache
  }
)

const FrameProvider: React.FC<Props> = ({ id, sizer, ...props }) => {
  const [height, setHeight] = useState(0)

  return (
    <Frame {...props} style={{ height: `${height}px` }}>
      <FrameContextConsumer>
        {({ document }) => {
          return (
            <CacheProvider
              value={
                memoizedCreateCacheWithContainer({
                  container: document.head,
                  id
                }) as any
              }
            >
              <FrameAutoSizer
                sizer={sizer}
                document={document}
                onChange={({ height }) => setHeight(height)}
              >
                {props.children}
              </FrameAutoSizer>
            </CacheProvider>
          )
        }}
      </FrameContextConsumer>
    </Frame>
  )
}

export { FrameProvider, FrameContextConsumer, FrameContext }
