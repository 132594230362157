import React, { Dispatch, SetStateAction } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Card, Collapse, Col, Row } from 'antd'
import { DraggableLightIcon, RightArrowIcon } from '@slerp/assets'

const { Panel } = Collapse

const AppSettingsDraggable = ({
  children,
  itemId,
  headerTitle,
  expandedItemList,
  setExpandedItemList
}: {
  children: JSX.Element | JSX.Element[]
  itemId: string
  headerTitle: string
  expandedItemList: string[]
  setExpandedItemList: Dispatch<SetStateAction<string[]>>
}) => {
  const { setNodeRef, attributes, listeners, transition, transform } =
    useSortable({ id: itemId })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    marginBottom: 16,
    marginTop: 16
  }

  return (
    <Card style={style} className='draggable-item'>
      <Collapse
        expandIconPosition='right'
        expandIcon={({ isActive }) => (
          <RightArrowIcon
            style={{
              transform: `${isActive ? 'rotate(90deg)' : 'rotate(0deg)'}`,
              transition: 'transform 150ms',
              marginTop: '-14px',
              maxWidth: '28px',
              maxHeight: '32px'
            }}
          />
        )}
        ghost
        className={`-collapse ${
          expandedItemList.includes(itemId) ? '-active' : '-inactive'
        }`}
        activeKey={expandedItemList}
        onChange={(key: string | string[]) =>
          setExpandedItemList(key as string[])
        }
      >
        <Panel
          key={itemId}
          header={
            <Row justify='start' align='middle' gutter={16} className='_mb-0'>
              <Col ref={setNodeRef} {...attributes} {...listeners}>
                <DraggableLightIcon className='draggable-icon' />
              </Col>
              {!expandedItemList.includes(itemId) && (
                <Col className='draggable-title'>{headerTitle}</Col>
              )}
            </Row>
          }
        >
          <div
            className='draggable-content'
            style={{
              opacity: `${expandedItemList.includes(itemId) ? 1 : 0}`,
              paddingTop: `${expandedItemList.includes(itemId) ? '12px' : 0}`
            }}
          >
            {children}
          </div>
        </Panel>
      </Collapse>
    </Card>
  )
}

export default AppSettingsDraggable
