import React, { useState } from 'react'
import { Button, message, Popconfirm, Tag } from 'antd'
import { useMutation } from '@apollo/client'
import { CANCEL_COURIER } from 'components/Orders/OrderQueries'
import { Delivery } from 'components/Orders/Info/type'
import { conditionalTooltip } from '../../utils'

interface DeliveryCtaProps {
  delivery: Delivery
  transactionId: string
  canCancel: boolean
  successCallback: () => void
}

const TAG_COLOR = {
  delivered: 'green',
  pending: 'orange',
  failed: 'red'
}

const DISABLE_CANCELLATION_STATES = [
  'picking',
  'almost_picking',
  'delivering',
  'almost_delivering',
  'delivered',
  'cancelled',
  'canceled',
  'failed'
]

const DeliveryCta = ({
  delivery,
  canCancel,
  transactionId,
  successCallback
}: DeliveryCtaProps) => {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const { delivery_status, job_id } = delivery || {}
  const [cancelCourier, { loading: cancellingCourier }] =
    useMutation(CANCEL_COURIER)

  const processCancellationOfCourier = () => {
    const variables = {
      transactionId,
      jobId: job_id || ''
    }

    cancelCourier({ variables })
      .then((result) => {
        successCallback()
        message.destroy()
        message.success('Courier cancelled!', 3)
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to cancel courier for order ${transactionId} due to ${error.message}`,
          3
        )
      })
      .finally(() => setShowConfirmation(false))
  }

  const stringified_status = delivery_status || 'pending'
  const isCancelDisabled =
    cancellingCourier ||
    DISABLE_CANCELLATION_STATES.includes(stringified_status)

  const cancelButton = (
    <Button
      data-testid='order-info-delivery-cta-cancel-courier-btn'
      type='primary'
      loading={cancellingCourier}
      disabled={isCancelDisabled}
      onClick={() => setShowConfirmation(true)}
    >
      CANCEL DELIVERY
    </Button>
  )

  return (
    <>
      <Tag
        color={TAG_COLOR[stringified_status] || 'orange'}
        className='-plain_bg'
      >
        {stringified_status}
      </Tag>
      {!!job_id && canCancel && (
        <Popconfirm
          title='Are you sure you want to cancel the delivery?'
          visible={showConfirmation}
          okButtonProps={{ loading: cancellingCourier }}
          okText='Yes'
          cancelButtonProps={{ type: 'ghost' }}
          cancelText='No'
          onConfirm={processCancellationOfCourier}
          onCancel={() => setShowConfirmation(false)}
        >
          {conditionalTooltip(
            cancelButton,
            'Cancel action is not available. Please, contact the support, if you need to perform it',
            isCancelDisabled
          )}
        </Popconfirm>
      )}
    </>
  )
}

export default DeliveryCta
