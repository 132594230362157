import React from 'react'
import {
  Divider,
  Form,
  Input,
  Button,
  PageHeader,
  message,
  Tooltip
} from 'antd'
import { Store } from 'antd/lib/form/interface'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { SettingProps } from '@slerp/controls'
import { useMutation, useApolloClient } from '@apollo/client'
import { UPDATE_MARKETING_AND_ANALYTICS_SETTINGS } from './actions'
import { trustPilotEmailFormatRule } from './Rules'
import UpdateNotice from '../Utils/UpdateNotice'
import styled from '@emotion/styled'

const { useForm } = Form

const AnalyticsSettings = (props: SettingProps) => {
  const { merchant } = props
  const [form] = useForm()
  const [updateSetting, { loading, error }] = useMutation(
    UPDATE_MARKETING_AND_ANALYTICS_SETTINGS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const client = useApolloClient()

  const onFinish = (values: Store) => {
    const {
      tag_manager_id,
      pixel_id,
      google_ads_id,
      google_conversion_id,
      google_analytics_4_id,
      google_site_verification_token,
      fb_domain_verification_token,
      trust_pilot_email
    } = values

    form.validateFields().then(() => {
      message.loading('Updating... Please wait.')
      updateSetting({
        variables: {
          id: merchant.id,
          trustPilotEmail: trust_pilot_email,
          settings: {
            tag_manager_id,
            pixel_id,
            google_ads_id,
            google_conversion_id,
            google_analytics_4_id,
            google_site_verification_token,
            fb_domain_verification_token
          }
        }
      }).then((result) => {
        message.destroy()
        client.resetStore()
        message.success('Analytics Settings Updated', 1)
      })
    })
  }

  return (
    <>
      <Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        initialValues={{
          trust_pilot_email: merchant.trust_pilot_email,
          tag_manager_id: merchant.setting.tag_manager_id,
          google_ads_id: merchant.setting.google_ads_id,
          google_analytics_4_id: merchant.setting.google_analytics_4_id,
          google_conversion_id: merchant.setting.google_conversion_id,
          google_site_verification_token:
            merchant.setting.google_site_verification_token,
          fb_domain_verification_token:
            merchant.setting.fb_domain_verification_token
        }}
      >
        <PageHeader
          title={
            <>
              Analytics
              <Tooltip
                title={
                  <span>
                    By adding your Google Tag Manager, Analytics and Pixel ID
                    you will be able to see your Slerp store traffic and
                    behaviour in the corresponding dashboards. You'll then be
                    able to leverage that data to{' '}
                    <strong>
                      optimise your offering and amplify your marketing
                    </strong>
                    .
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
        />

        <Form.Item
          name='tag_manager_id'
          label='Google Tag Manager ID (Recommended):'
        >
          <Input data-testid='gtm-field' placeholder='GTM-XXXXXXX' />
        </Form.Item>

        <Form.Item name='google_analytics_4_id' label='Google Analytics 4 ID:'>
          <Input
            data-testid='google-analytics-4-field'
            placeholder='G-XXXXXXXXXX'
          />
        </Form.Item>

        <Form.Item name='pixel_id' label='Pixel ID:'>
          <Input
            data-testid='pixel-field'
            defaultValue={merchant.setting.pixel_id}
          />
        </Form.Item>

        <Form.Item
          name='google_site_verification_token'
          label='Google Site Verification Token:'
          tooltip={
            <span>
              Please enter only the content value from the meta tag e.g. if the
              meta tag is "&lt;meta name='google-site-verification'
              content='uk52z456g9hqwe03pqw14g4klw1rw6' /&gt;" , enter only{' '}
              <strong>uk52z456g9hqwe03pqw14g4klw1rw6</strong> in the field.
            </span>
          }
        >
          <Input
            data-testid='google-site-verification-field'
            defaultValue={merchant.setting.google_site_verification_token}
          />
        </Form.Item>

        <PageHeader title='Google Ads' />

        <Form.Item name='google_ads_id' label='Global Site Tag ID:'>
          <Input data-testid='ads-field' />
        </Form.Item>

        <Form.Item name='google_conversion_id' label='Conversion Event ID:'>
          <Input data-testid='conversion-field' />
        </Form.Item>

        <Divider />
        <PageHeader title='Facebook Business Settings' />

        <Form.Item
          name='fb_domain_verification_token'
          label='Domain Verification:'
          tooltip={
            <span>
              Please enter only the content value from the meta tag e.g. if the
              meta tag is "&lt;meta name='facebook-domain verification'
              content='uk52z456g9hqwe03pqw14g4klw1rw6' /&gt;" , enter only{' '}
              <strong>uk52z456g9hqwe03pqw14g4klw1rw6</strong> in the field.
            </span>
          }
        >
          <Input data-testid='fb-domain-verification-field' />
        </Form.Item>

        <Divider />
        <PageHeader title='Trustpilot Settings' />

        <Form.Item
          name='trust_pilot_email'
          label='Trustpilot Automated Feedback Service:'
          rules={trustPilotEmailFormatRule}
          tooltip={
            <span>
              {"Trustpilot's"} Automatic Feedback Service (AFS) is an invitation
              method that can be used to automate the collection of reviews. It
              is one of the fastest ways to build your Google Seller Ratings and
              stand out on Google Search. Read{' '}
              {
                <a
                  href='https://support.trustpilot.com/hc/en-us/articles/213703667'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  this article
                </a>
              }{' '}
              to learn more about how to do this.
            </span>
          }
        >
          <Input
            placeholder='XXXXXXX@invite.trustpilot.com'
            data-testid='trust-pilot-email-field'
          />
        </Form.Item>

        <Divider />

        <Form.Item>
          <Submit>
            <Button title='Save' type='primary' size='large' htmlType='submit'>
              Save
            </Button>
            <UpdateNotice updating={loading} updateError={error} />
          </Submit>
        </Form.Item>
      </Form>

      {/*
      <Alert
        message='What is this?'
        description={
          <>
            <p>
              We allow merchants to track their traffic using Google Analytics
              or Tag Manager. Simply put your Analytics ID or Tag Manager ID to
              enable it.
            </p>
            <p>
              To learn more about how Google Analytics or Tag Manager works,
              please visit https://tagmanager.google.com/
            </p>
            <p>
              For other tracking services such as Facebook Pixel or other custom
              code, we recommend Google Tag Manager to manage them.
            </p>
          </>
        }
        type='success'
        showIcon
      />
      */}
    </>
  )
}

const Submit = styled.div(() => ({
  textAlign: 'right'
}))

export default AnalyticsSettings
