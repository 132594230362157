import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/client'
import { Button, Modal, Row, Col, Popconfirm, message, Select } from 'antd'
import {
  QUERY_CUSTOMER_CARDS_AND_REWARDS,
  REDEEM_CUSTOMER_REWARD
} from './LoyaltyQueries'
import { FilterIcon, SelectDownArrowIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from '../Widgets'
import groupBy from 'lodash/groupBy'

const { Option } = Select

const RewardsRedeemModal = (props) => {
  const { visible, onClose, customer } = props

  const { data, refetch } = useQuery(QUERY_CUSTOMER_CARDS_AND_REWARDS, {
    variables: { customerId: customer?.id }
  })

  const isRewardValid = (customer_reward) => {
    if (customer_reward?.redeemed_at) {
      return false
    }

    if (customer_reward?.end_datetime) {
      return customer_reward?.end_datetime
        ? moment.utc().isBefore(moment.utc(customer_reward?.end_datetime))
        : true
    }

    return true
  }

  const groupRewards = (customer_loyalty, valid) => {
    return Object.values(
      groupBy(customer_loyalty, (card) => card?.loyalty_card?.id)
    ).reduce((acc, customer_cards) => {
      const rewards = customer_cards?.reduce((acc, card) => {
        return [...acc, ...card?.customer_rewards]
      }, [])
      const value = {
        loyalty_card: customer_cards[0]?.loyalty_card,
        rewards: rewards.filter((val) =>
          valid ? isRewardValid(val) : !isRewardValid(val)
        )
      }
      return [...acc, value]
    }, [])
  }

  const [showValid, setShowValid] = useState(true)

  const [rewardsData, setRewardsData] = useState(
    groupRewards(data?.customer_loyalty_cards || [], true)
  )

  useEffect(() => {
    const groupedRewards = groupRewards(
      data?.customer_loyalty_cards || [],
      showValid
    )
    setRewardsData(groupedRewards)
  }, [showValid, data])

  const [redeemCustomerReward] = useMutation(REDEEM_CUSTOMER_REWARD, {
    fetchPolicy: 'no-cache'
  })

  const redeem = (values) => {
    message.loading('Redeeming customer reward...')
    redeemCustomerReward({ variables: values })
      .then((result) => {
        message.destroy()
        message.success('Customer reward has been manually redeemed', 2)
        refetch()
      })
      .catch((error) => {
        console.error('Redeem Customer Reward Error: ', error)
        message.destroy()
        message.error(`Unable to redeem customer reward due to ${error}.`, 3)
      })
  }

  const modalFooter = [
    <></>,
    <Button type='ghost' onClick={onClose}>
      Discard
    </Button>
  ]

  const modalTitle = (
    <div className='qr-modal-header'>
      <h2 className='title'>Redeem Rewards</h2>
    </div>
  )

  return (
    <Modal
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      forceRender={true}
      className='loyalty-qr-modal'
      title={modalTitle}
      footer={modalFooter}
    >
      <h3>{`${customer?.first_name} ${customer?.last_name}`}</h3>
      <Row align='middle' className='_mb-16'>
        <Col span={12}>
          <SelectPrefixWrapper
            prefixicon={<FilterIcon />}
            style={{ marginRight: '24px' }}
          >
            <Select
              data-testid='rewards-options'
              suffixIcon={<SelectDownArrowIcon />}
              defaultValue={showValid}
              value={showValid}
              onChange={(value: string) => {
                setShowValid(!!value)
              }}
            >
              <Option key='valid' value={true}>
                Valid Rewards
              </Option>
              <Option key='invalid' value={false}>
                Invalid Rewards
              </Option>
            </Select>
          </SelectPrefixWrapper>
        </Col>
      </Row>
      {rewardsData?.length > 0
        ? rewardsData?.map((customer_card) => {
            return customer_card?.rewards?.length > 0 ? (
              <div className='customer-rewards-info'>
                <p className='title'>{customer_card?.loyalty_card?.name}</p>
                {customer_card?.rewards?.map((customer_reward) => (
                  <Row align='middle'>
                    <Col span={20}>
                      <span className='name'>
                        {customer_reward.reward?.name}
                      </span>
                      {customer_reward?.end_datetime && (
                        <p className='datetime'>
                          Expires in:{' '}
                          {moment
                            .utc(customer_reward?.end_datetime)
                            .tz('Europe/London')
                            .format('DD/MM/YYYY hh:mm A')}
                        </p>
                      )}
                    </Col>
                    <Col span={4}>
                      {isRewardValid(customer_reward) && (
                        <Popconfirm
                          title='Are you sure you want to manually redeem this reward?'
                          onConfirm={() =>
                            redeem({
                              customerRewardId: customer_reward?.id,
                              loyaltyCode: customer_card?.loyalty_card?.code
                            })
                          }
                          okText='Redeem'
                          cancelText='Cancel'
                        >
                          <Button>Redeem</Button>
                        </Popconfirm>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            ) : (
              'This customer has no rewards to redeem.'
            )
          })
        : 'This customer has no rewards to redeem.'}
    </Modal>
  )
}

export default RewardsRedeemModal
