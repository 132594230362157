import { gql } from '@apollo/client'

const GET_DISCOUNT = gql`
  query GetDiscount($discount_id: uuid!, $slug: String!) {
    discounts(
      where: { id: { _eq: $discount_id }, merchant: { slug: { _eq: $slug } } }
    ) {
      id
      code
      description
      target
      type
      trigger
      value
      start_date
      end_date
      quantity
      quantity_per_customer
      minimum_value
      published_at
      store_ids
      categories_discounts {
        category_id
      }
      products_discounts {
        product_id
        product {
          category_id
        }
      }
    }
  }
`

const CREATE_DISCOUNT = gql`
  mutation CreateDiscount(
    $id: uuid!
    $merchant_id: uuid!
    $code: String!
    $description: String
    $target: String!
    $type: String!
    $trigger: String!
    $value: numeric
    $start_date: date
    $end_date: date
    $quantity: Int
    $quantity_per_customer: Int
    $minimum_value: numeric
    $published_at: timestamp
    $store_ids: _varchar
    $categories: [categories_discounts_insert_input!]!
    $products: [products_discounts_insert_input!]!
  ) {
    insert_discounts(
      objects: {
        id: $id
        merchant_id: $merchant_id
        code: $code
        description: $description
        target: $target
        type: $type
        trigger: $trigger
        value: $value
        start_date: $start_date
        end_date: $end_date
        quantity: $quantity
        quantity_per_customer: $quantity_per_customer
        minimum_value: $minimum_value
        published_at: $published_at
        store_ids: $store_ids
        inserted_at: "now()"
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
    insert_categories_discounts(objects: $categories) {
      affected_rows
    }
    insert_products_discounts(objects: $products) {
      affected_rows
    }
  }
`

const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount(
    $id: uuid!
    $merchant_id: uuid!
    $code: String!
    $description: String
    $target: String!
    $type: String!
    $trigger: String!
    $value: numeric
    $start_date: date
    $end_date: date
    $quantity: Int
    $quantity_per_customer: Int
    $minimum_value: numeric
    $published_at: timestamp
    $store_ids: _varchar
    $categories: [categories_discounts_insert_input!]!
    $products: [products_discounts_insert_input!]!
  ) {
    delete_categories_discounts(where: { discount_id: { _eq: $id } }) {
      affected_rows
    }
    insert_categories_discounts(objects: $categories) {
      affected_rows
    }
    delete_products_discounts(where: { discount_id: { _eq: $id } }) {
      affected_rows
    }
    insert_products_discounts(objects: $products) {
      affected_rows
    }
    update_discounts(
      where: { id: { _eq: $id }, merchant_id: { _eq: $merchant_id } }
      _set: {
        code: $code
        description: $description
        target: $target
        type: $type
        value: $value
        trigger: $trigger
        end_date: $end_date
        start_date: $start_date
        quantity: $quantity
        quantity_per_customer: $quantity_per_customer
        minimum_value: $minimum_value
        published_at: $published_at
        store_ids: $store_ids
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`

const GET_MERCHANT_STORES = gql`
  query getSameDayOrderStores($merchantId: uuid!) {
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
    ) {
      id
      slug
      name
    }
  }
`

const GET_CATEGORIES = gql`
  query getCategories($merchantId: uuid!) {
    categories(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      value: id
      label: name
    }
  }
`

const GET_PRODUCTS = gql`
  query getProducts($merchantId: uuid!) {
    products(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      value: id
      label: name
      category_id
    }
    categories(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const TOGGLE_DISCOUNT_ACTIVATION_STATUS = gql`
  mutation toggleDiscountActivationStatus(
    $merchantId: uuid!
    $discountId: uuid!
    $published_at: timestamp
  ) {
    update_discounts(
      where: { merchant_id: { _eq: $merchantId }, id: { _eq: $discountId } }
      _set: { published_at: $published_at, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

export {
  GET_DISCOUNT,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  GET_MERCHANT_STORES,
  GET_CATEGORIES,
  GET_PRODUCTS,
  TOGGLE_DISCOUNT_ACTIVATION_STATUS
}
