import { createClient } from '@slerp/client'
import {
  GET_USER_BY_EMAIL,
  QUERY_EXISTING_LOCATION_NAME_FOR_MERCHANT
} from '../LocationQueries'
import isEmpty from 'lodash/isEmpty'
import { ApolloClient } from '@apollo/client'
import { AddressValueType } from './AutocompleteInput'
import { isValidUKPhoneNumber } from './helpers'

type UserFields =
  | 'confirm_password'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'password'

type CustomRuleType = (arg: string | boolean) => {
  users: Array<Record<UserFields, string>>
}

interface Address {
  formattedAddress: string
  noSuggestionSelected: boolean
  validateTrigger: false
}

const isEmailTaken = async (email: string, merchantId: string) => {
  const apiKey = localStorage.getItem('token') || ''
  const client = createClient(apiKey)

  return await client
    .query({
      query: GET_USER_BY_EMAIL,
      variables: { email, merchantId },
      fetchPolicy: 'no-cache'
    })
    .then((result) => {
      return !isEmpty(result?.data?.getUserByEmail)
    })
    .catch((error: Error) => {
      return false
    })
}

const uniqueEmailRule = (merchantId: string) => ({
  async validator(_: any, value: string) {
    if (!value) return Promise.resolve()
    const isTaken = await isEmailTaken(value, merchantId)

    return isTaken
      ? Promise.reject(
          'This email address is already in use, please use another'
        )
      : Promise.resolve()
  }
})

const confirmPasswordRule = (key: number, getFieldsValue: CustomRuleType) => ({
  async validator(_, value: string) {
    console.log('getFieldsValue(true)', getFieldsValue(true))

    if (!value || getFieldsValue(true)['users'][key]?.password === value) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error('The passwords you entered do not match. Please try again.')
    )
  }
})

const noAddressSelectedRule = [
  () => ({
    validator(_: any, value: Address) {
      const { formattedAddress, noSuggestionSelected } = value
      const message = 'Please type and select an address from the dropdown.'

      if (!formattedAddress ?? formattedAddress.length === 0)
        return Promise.reject(message)

      if (noSuggestionSelected) return Promise.reject(message)

      return Promise.resolve()
    }
  })
]

const UniqueLocationNameValidatorRule = (
  client: ApolloClient<any>,
  merchantId: string
) => ({
  async validator(_: any, value: string) {
    if (!value) {
      return
    }

    const {
      data: { stores }
    } = await client.query({
      query: QUERY_EXISTING_LOCATION_NAME_FOR_MERCHANT,
      variables: {
        merchantId: merchantId,
        storeName: value
      }
    })

    if (stores.length) {
      return Promise.reject(
        'This location name is already in use, please use another name'
      )
    }

    return Promise.resolve()
  }
})

const UKPhoneValidatorRule = () => ({
  async validator(_: any, value: string) {
    if (!value) {
      return
    }

    if (isValidUKPhoneNumber('+44' + value)) {
      return Promise.resolve()
    }

    return Promise.reject('Please input a valid UK phone number')
  }
})

const phoneValidatorRule = () => ({
  async validator(_: any, value: string) {
    if (!value) {
      return Promise.reject('Phone number cannot be empty')
    }

    if (value.match(/\d/g)?.length! >= 8 && value?.match(/\d/g)?.length! <= 15)
      return Promise.resolve()

    return Promise.reject('Please input a valid phone number')
  }
})

const addressValidatorRule = () => ({
  async validator(_: any, value: AddressValueType) {
    if (!value.formattedAddress) {
      return Promise.reject('Address is required')
    }

    if (value.noSuggestionSelected) {
      return Promise.reject('Please select an address from the suggestions')
    }

    if (!value || !value.validateTrigger) {
      return
    }

    if (!value.postalCode) {
      return Promise.reject('Please use a more specific address')
    }

    return Promise.resolve()
  }
})

export {
  uniqueEmailRule,
  confirmPasswordRule,
  noAddressSelectedRule,
  UniqueLocationNameValidatorRule,
  UKPhoneValidatorRule,
  addressValidatorRule,
  phoneValidatorRule
}
