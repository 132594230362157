import React, { useEffect, useState } from 'react'
import { Form, FormInstance, Input } from 'antd'
import Subform from './Subform'
import { HiddenInputs } from './styledComponents'
import { phoneValidatorRule } from 'components/Locations/LocationForm/rules'
import { parseContactNumberForDisplay } from './utils'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

interface RecipientSubformProps {
  form: FormInstance
}

const RecipientSubform = ({ form }: RecipientSubformProps) => {
  const { first_name, last_name, contact_num } = form.getFieldsValue()
  const [displayName, setDisplayName] = useState<string>(
    `${first_name} ${last_name} (${contact_num})`
  )

  useEffect(() => {
    setDisplayName(`${first_name} ${last_name} (+${contact_num})`)
  }, [first_name, last_name, contact_num])

  const handleSave = (
    setShowInputs: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const {
      customer_details_subform: { first_name, last_name, contact_num }
    } = form.getFieldsValue()

    form
      .validateFields([
        ['customer_details_subform', 'first_name'],
        ['customer_details_subform', 'last_name'],
        ['customer_details_subform', 'contact_num']
      ])
      .then(() => {
        form.setFieldsValue({
          first_name,
          last_name,
          contact_num
        })
        setDisplayName(`${first_name} ${last_name} (+${contact_num})`)
        setShowInputs(false)
      })
      .catch((error) => {
        const { errorFields } = error
        if (errorFields.length > 1) return setShowInputs(true)
      })
  }

  const handleCancel = () => {
    form.setFieldsValue({
      customer_details_subform: {
        first_name,
        last_name,
        contact_num: parseContactNumberForDisplay(contact_num)
      }
    })
  }

  return (
    <>
      <Subform
        onCancel={handleCancel}
        onSave={handleSave}
        label='Recipient:'
        value={displayName}
      >
        <Form.Item
          rules={[{ required: true, message: 'First name is required' }]}
          validateTrigger='onBlur'
          name={['customer_details_subform', 'first_name']}
          label='First name'
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Last name is required' }]}
          validateTrigger='onBlur'
          name={['customer_details_subform', 'last_name']}
          label='Last name'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Contact number'
          rules={[phoneValidatorRule]}
          validateTrigger='onBlur'
          name={['customer_details_subform', 'contact_num']}
        >
          <Input
            type='number'
            prefix='+'
            onFocus={onFocusScrollNumber}
            onBlur={onBlurScrollNumber}
          />
        </Form.Item>
      </Subform>
      <HiddenInputs>
        <Form.Item name='customer_details_subform' />
        <Form.Item name='first_name' />
        <Form.Item name='last_name' />
        <Form.Item name='contact_num' />
      </HiddenInputs>
    </>
  )
}

export default RecipientSubform
