import { uuid } from 'uuidv4'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import { Discount } from '@slerp/controls'

export const formatProductParams = (
  products: { [key: string]: string[] },
  discount_id: string
) => {
  return uniq(flatten(Object.values(products || {}))).map((id: string) => ({
    discount_id,
    product_id: id,
    id: uuid(),
    inserted_at: 'now()',
    updated_at: 'now()'
  }))
}

export const formatCategoryParams = (
  categories: { [key: string]: string[] },
  discount_id: string
) => {
  return flatten(Object.values(categories || {})).map((id: string) => ({
    discount_id,
    category_id: id,
    id: uuid(),
    inserted_at: 'now()',
    updated_at: 'now()'
  }))
}

export const formatCategoryValues = (categories: { category_id: string }[]) =>
  categories.map((item: { category_id: string }) => item.category_id)

export const formatProductValues = (
  products: { product: { category_id: string }; product_id: string }[]
) => {
  const categorizedProducts = groupBy(
    products,
    (item) => item.product.category_id
  )
  return Object.keys(categorizedProducts).reduce((acc, category_id) => {
    const products = categorizedProducts[category_id]
    return {
      ...acc,
      [category_id]: products.map(({ product_id }) => product_id)
    }
  }, {})
}

export const getDiscountProperties = ({
  target,
  trigger,
  store_ids,
  merchantStoresDeliveryPricingBands
}: Discount) => {
  const isDelivery = target === 'delivery_fee'
  const isAutomatic = trigger === 'automatic'
  const isAvailableAllStores = (store_ids || []).length === 0

  const storeIdsWithPricingBands = (store_ids || []).filter((storeId: string) =>
    merchantStoresDeliveryPricingBands.includes(storeId)
  )

  const isBlockedByActivePricingBands =
    isDelivery &&
    isAutomatic &&
    ((!!merchantStoresDeliveryPricingBands.length && isAvailableAllStores) ||
      !!storeIdsWithPricingBands.length)

  return {
    isDelivery,
    isAutomatic,
    isAvailableAllStores,
    isBlockedByActivePricingBands
  }
}
