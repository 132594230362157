import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch
} from 'antd'
import debounce from 'lodash/debounce'
import { LoadingOutlined } from '@ant-design/icons'
import { createClient } from '@slerp/client'
import { EditOutlined } from '@ant-design/icons'
import { useQuery, useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  GET_ORDER_QUOTE,
  GET_SAME_DAY_STORES,
  REBOOK_COURIER
} from './OrderQueries'

import AddressInput from '../Locations/LocationForm/AddressInput'
import { OrderDetailsProps } from './types'
import './styles.css'
import {
  addressValidatorRule,
  phoneValidatorRule
} from 'components/Locations/LocationForm/rules'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

interface SameDayStores {
  id: string
  name: string
}

interface Callbacks {
  successCallback?: () => void
}

const { useForm } = Form
const { TextArea } = Input

const CourierForm = (props: OrderDetailsProps & Callbacks) => {
  const { user, merchant } = useSession()
  const { order, successCallback } = props
  const { store, customer_details, delivery, transaction_id } = order
  const [form] = useForm()

  const [showDeliveryNotes, setShowDeliveryNotes] = useState(false)
  const [showRecipientDetails, setShowRecipientDetails] = useState(false)
  const [deliveryCost, setDeliveryCost] = useState(0)
  const [isDeliveryCostFetching, setIsDeliveryCostFetching] = useState(false)
  const [sameDayStores, setSameDayStores] = useState<Array<SameDayStores>>([])

  const getDeliveryCostQuote = async () => {
    const apiKey = localStorage.getItem('token') || ''
    const client = createClient(apiKey)
    const formValues = form.getFieldsValue(true)
    const variables = {
      quoteParams: {
        transactionId: order.transaction_id,
        vehicle: formValues.vehicle,
        storeId: store.id,
        address: {
          line_1: formValues.drop_off.line1,
          line_2: formValues.drop_off.line2,
          city: formValues.drop_off.city,
          zip: formValues.drop_off.postalCode,
          coords: {
            lat: formValues.drop_off.lat,
            lng: formValues.drop_off.lng
          }
        }
      }
    }

    setIsDeliveryCostFetching(true)
    return await client
      .query({
        query: GET_ORDER_QUOTE,
        variables
      })
      .then((response) => {
        const { cost } = response.data.quote
        setIsDeliveryCostFetching(false)
        setDeliveryCost(cost)
      })
      .catch((err) => {
        setIsDeliveryCostFetching(false)
      })
  }

  const [rebookCourier] = useMutation(REBOOK_COURIER)

  const processRebookingOfCourier = async () => {
    const formValues = form.getFieldsValue(true)
    const {
      first_name,
      last_name,
      contact_num,
      drop_off,
      location,
      vehicle,
      delivery_time,
      delivery_notes
    } = formValues
    const variables = {
      address: {
        line_1: drop_off.line1,
        line_2: drop_off.line2,
        country: customer_details?.address?.country,
        lat: Number(drop_off.lat),
        lng: Number(drop_off.lng),
        zip: drop_off.postalCode,
        city: drop_off.city,
        location_type: '',
        contact_num: `+${contact_num}`
      },
      recipient: {
        first_name,
        last_name,
        contact_num: `+${contact_num}`
      },
      transaction_id,
      vehicle,
      current_user_id: user.id,
      store_id: location,
      fulfillment_time: delivery_time,
      fulfullment_date:
        delivery_time === 'immediately' ? null : moment().format('YYYY-MM-DD'),
      dropoff_notes: delivery_notes
    }

    rebookCourier({ variables })
      .then((result) => {
        successCallback && successCallback()
        message.destroy()
        message.success('Courier rebooked!', 3)
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to rebook courier for order ${transaction_id} due to ${error.message}`,
          3
        )
      })
  }

  const { data: deliveryCostData, loading: quoteQueryLoading } = useQuery(
    GET_ORDER_QUOTE,
    {
      variables: {
        quoteParams: {
          transactionId: order.transaction_id,
          vehicle: delivery?.default_transport_type,
          storeId: store.id,
          address: {
            line_1: customer_details?.address?.line_1,
            line_2: customer_details?.address?.line_2,
            city: customer_details?.address?.city,
            zip: customer_details?.address?.zip,
            coords: {
              lat: customer_details?.address?.coords?.lat,
              lng: customer_details?.address?.coords?.lng
            }
          }
        }
      }
    }
  )

  const { data: sameDayStoreData } = useQuery(GET_SAME_DAY_STORES, {
    variables: { merchant_id: merchant.id }
  })

  const constructAddressHash = () => {
    const { address } = customer_details || {}
    return {
      formattedAddress: [
        address?.line_1,
        address?.line_2,
        address?.city,
        address?.zip
      ].join(', '),
      line1: address?.line_1 || '',
      line2: address?.line_2 || '',
      city: address?.city || '',
      country: address?.country || '',
      postalCode: address?.zip || '',
      lat: address?.coords?.lat || 0,
      lng: address?.coords?.lng || 0
    }
  }

  const parseContactNumber = (contactNumber: string = '') => {
    const countryCode = /^\+44/
    return (contactNumber.match(countryCode) || []).length
      ? contactNumber.substring(3)
      : contactNumber
  }

  useEffect(() => {
    if (sameDayStoreData) {
      const { stores } = sameDayStoreData
      setSameDayStores(stores)
    }
  }, [sameDayStoreData])

  useEffect(() => {
    if (deliveryCostData) {
      const { cost = 0 } = deliveryCostData?.quote || {}
      setDeliveryCost(cost)
    }
  }, [deliveryCostData])

  const debouncedGetDeliveryCosting = debounce(getDeliveryCostQuote, 3000)
  return (
    <>
      <Descriptions title={`Book additional courier for #${transaction_id}`} />
      <Form
        layout='vertical'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
        form={form}
        initialValues={{
          location: store.id,
          drop_off: constructAddressHash(),
          vehicle: delivery?.default_transport_type,
          first_name: customer_details.first_name,
          last_name: customer_details.last_name,
          contact_num: parseContactNumber(customer_details?.contact_num),
          delivery_time: 'immediately'
        }}
      >
        <Form.Item name='location' label='Pickup from:'>
          <Select onChange={(e) => getDeliveryCostQuote()}>
            {sameDayStores &&
              sameDayStores.map((sameDayStore) => (
                <Select.Option value={sameDayStore.id}>
                  {sameDayStore.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name='drop_off'
          label='Dropoff to:'
          rules={[
            {
              required: true,
              message: 'Address is required'
            },
            addressValidatorRule
          ]}
        >
          <AddressInput onChange={(e) => debouncedGetDeliveryCosting()} />
        </Form.Item>
        {!showRecipientDetails && (
          <Row align='middle'>
            <Col>{`${customer_details.first_name} ${customer_details.last_name} (${customer_details.contact_num})`}</Col>
            <Col offset={2}>
              <Button
                onClick={(e) => setShowRecipientDetails(true)}
                icon={<EditOutlined />}
              />
            </Col>
          </Row>
        )}
        {showRecipientDetails && (
          <Form.Item label='Recipient:'>
            <Form.Item name='first_name' label='First name'>
              <Input />
            </Form.Item>
            <Form.Item name='last_name' label='Last name'>
              <Input />
            </Form.Item>

            <Form.Item
              label='Contact number'
              rules={[phoneValidatorRule]}
              validateTrigger='onBlur'
              name='contact_num'
            >
              <Input
                type='number'
                prefix='+'
                onFocus={onFocusScrollNumber}
                onBlur={onBlurScrollNumber}
              />
            </Form.Item>
          </Form.Item>
        )}
        <Form.Item name='vehicle' label='Vehicle:'>
          <Radio.Group onChange={(e) => getDeliveryCostQuote()}>
            <Space direction='vertical'>
              <Radio value='bike'>Bike</Radio>
              <Radio value='motorbike'>Motorbike</Radio>
              <Radio value='car'>Car</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name='delivery_time' label='Delivery time:'>
          <Select>
            <Select.Option value='immediately'>ASAP</Select.Option>
          </Select>
        </Form.Item>
        <Row align='middle' gutter={[8, 8]}>
          <Col>
            <Switch onChange={setShowDeliveryNotes} />
          </Col>
          <Col>Delivery Notes</Col>
        </Row>
        {showDeliveryNotes && (
          <Form.Item name='delivery_notes' label='Delivery notes:'>
            <TextArea maxLength={255} rows={3} style={{ marginBottom: 10 }} />
          </Form.Item>
        )}
        <Row gutter={[8, 8]}>
          <Col>
            {quoteQueryLoading || isDeliveryCostFetching ? (
              <>
                <Spin indicator={<LoadingOutlined spin />} />
                <span> Calculating delivery fee</span>
              </>
            ) : (
              <>
                <span>You will be charged a delivery fee of £</span>
                <span className='primary-text'>{deliveryCost}</span>
                <span> for adding a courier.</span>
              </>
            )}
          </Col>
        </Row>
        <Form.Item>
          <Button
            type='primary'
            size='large'
            onClick={processRebookingOfCourier}
          >
            Book
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default CourierForm
