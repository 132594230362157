import { ProductCategoryDefaultVariant } from './CategoriesList'
import { uniq } from 'lodash'

interface ProductWithSingleCategory {
  id: string
  default_variant_id: string
}

export const filterProductsWithSingleCategory = (
  productCategories: ProductCategoryDefaultVariant[]
): ProductWithSingleCategory[] | [] => {
  if (productCategories.length < 1) return []

  const productsWithSingleCategory: ProductWithSingleCategory[] =
    productCategories.reduce(
      (
        acc: ProductWithSingleCategory[],
        current: ProductCategoryDefaultVariant
      ): ProductWithSingleCategory[] => {
        const filterProductWithSingleCategory =
          productCategories.filter((pc) => current.product.id === pc.product.id)
            .length === 1

        if (filterProductWithSingleCategory) {
          return [
            ...acc,
            { id: current.product.id, default_variant_id: current.product.id }
          ]
        } else {
          return acc
        }
      },
      []
    )

  return uniq(productsWithSingleCategory)
}
