import React, { useState, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { Col, Button, Row } from 'antd'
import { MAPS_API_KEY } from '@slerp/client'
import { OrderInfo, AdditionalCourier } from 'components/Orders/Info/type'
import GoogleMap from 'google-map-react'
import { Maps } from '@types/google-map-react'
import {
  MarkerIcon,
  HomeIcon,
  ShopIcon,
  MotorbikeIcon,
  BikeIcon,
  CarIcon,
  VanIcon
} from '@slerp/assets'

interface DeliveryMapDetailsProps {
  order: OrderInfo
}

type MapRef = {
  map: google.maps.Map | null
  maps: Maps | null
}

type LatLng = {
  lat: number
  lng: number
}

type ParsedCourier = {
  transport_type: string
  delivery_status: string
} & LatLng

let mapRef: MapRef = { map: null, maps: null }

const DeliveryMapDetails = ({ order }: DeliveryMapDetailsProps) => {
  const { delivery, additional_couriers, customer_details, store } = order

  const parseCouriers = (): { [key: string]: ParsedCourier } => {
    let couriers: { [key: string]: ParsedCourier } = {}
    if (!isEmpty(delivery) && !isEmpty(delivery?.driver?.location)) {
      const { delivery_status, job_id } = delivery
      const { lat, lng } = delivery?.driver?.location
      const { transport_type } = delivery?.driver

      couriers[job_id] = { lat, lng, transport_type, delivery_status }
    }

    additional_couriers.forEach(
      ({ data: deliveryData, job_id }: AdditionalCourier) => {
        const { delivery_status } = deliveryData
        const { transport_type } = deliveryData?.driver || {}
        const { lat, lng } = deliveryData?.driver?.location || {}

        couriers[job_id] = { lat, lng, transport_type, delivery_status }
      }
    )

    return couriers
  }
  const deliveryCouriers = useMemo(parseCouriers, [
    additional_couriers,
    delivery
  ])

  const [viewingCourierJobId, setViewingCourierJobId] = useState<string>(
    Object.keys(deliveryCouriers)[0] || 'none'
  )

  // Map recenter logic

  const fitBounds = useCallback((point1: LatLng, point2: LatLng) => {
    if (!mapRef.map || !mapRef.maps) {
      return
    }

    const {
      maps: {
        geometry: { spherical }
      }
    } = google

    const center = calculateCenter(point1, point2)

    const radius1 = spherical.computeDistanceBetween(
      center as any,
      point1 as any
    )
    const radius2 = spherical.computeDistanceBetween(
      center as any,
      point2 as any
    )

    const radius = radius1 > radius2 ? radius1 : radius2

    const circle = new mapRef.maps.Circle({ center, radius }).getBounds()

    mapRef.map.fitBounds(circle)
  }, [])

  const calculateCenter = (point1: LatLng, point2: LatLng): LatLng => ({
    lat: (point1.lat + point2.lat) / 2,
    lng: (point1.lng + point2.lng) / 2
  })

  const setCenter = useCallback(() => {
    if (!store?.address?.geom?.coordinates || !customer_details?.address) {
      return
    }

    const point1 = {
      lat: store.address.geom.coordinates[0],
      lng: store.address.geom.coordinates[1]
    }
    const point2 = customer_details.address

    fitBounds(point1, point2)
  }, [customer_details, store, fitBounds])

  // Google maps handlers

  const handleGoogleApiLoaded = ({ map, maps }: MapRef) => {
    mapRef = { map, maps }
    setCenter()
  }

  const createMapOptions = () => ({
    fullscreenControl: false,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_CENTER
    }
  })

  // Draw markers logic
  const iconStyles = {
    fill: 'white'
  }

  const strokeIconStyles = {
    stroke: 'white'
  }

  const drawOriginMarker = useCallback(() => {
    const coordinates = store.address.geom.coordinates

    return (
      <Marker
        key={`${order.id}-origin`}
        lat={coordinates[0]}
        lng={coordinates[1]}
        fill='#1890FF'
      >
        <ShopIcon style={iconStyles} />
      </Marker>
    )
  }, [store, order.id, iconStyles])

  const drawDestinationMarker = useCallback(() => {
    if (!customer_details) {
      return
    }

    const { lat, lng } = customer_details.address

    return (
      <Marker
        key={`${order.id}-destination`}
        lat={lat}
        lng={lng}
        fill='#F4521B'
      >
        <HomeIcon style={iconStyles} />
      </Marker>
    )
  }, [customer_details, iconStyles, order.id])

  const getVehicleIcon = useCallback(
    (transport: string) => {
      const vehicleNames: { [key: string]: string } = {
        motorcycle: 'motorbike',
        motorcycle_cargo: 'motorbike',
        motorbike_cargo: 'motorbike',
        bicycle: 'bike',
        bicycle_cargo: 'bike',
        walking: 'walk'
      }

      const vehicle = vehicleNames[transport]
        ? vehicleNames[transport]
        : transport

      const vehicleIcons: { [key: string]: React.ReactNode } = {
        bike: <BikeIcon style={iconStyles} />,
        car: <CarIcon style={iconStyles} />,
        motorbike: <MotorbikeIcon style={iconStyles} />,
        van: <VanIcon style={strokeIconStyles} />,
        walk: <BikeIcon style={iconStyles} />
      }

      return vehicleIcons[vehicle]
    },
    [iconStyles, strokeIconStyles]
  )

  const drawCourierMarker = useCallback(() => {
    const currentDelivery = deliveryCouriers[viewingCourierJobId]

    if (
      !currentDelivery ||
      !currentDelivery.delivery_status ||
      currentDelivery.delivery_status === 'delivered'
    ) {
      return
    }

    const { lat, lng, transport_type } = currentDelivery

    if ((!lat && lat !== 0) || (!lng && lng !== 0) || !transport_type) {
      return
    }

    const vehicleIcon = getVehicleIcon(transport_type)

    return (
      <Marker key={`${order.id}-delivery`} lat={lat} lng={lng}>
        {vehicleIcon}
      </Marker>
    )
  }, [viewingCourierJobId, deliveryCouriers, order.id, getVehicleIcon])

  const londonCenter = { lat: 51.5137137, lng: -0.1285204 }

  return (
    <>
      <Row>
        <Col className='_w-100 delivery-buttons'>
          {Object.keys(deliveryCouriers).map((job_id: string) => (
            <Button
              key={job_id}
              ghost={job_id !== viewingCourierJobId}
              type='primary'
              shape='round'
              className='button'
              onClick={() => setViewingCourierJobId(job_id)}
            >
              {job_id}
            </Button>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className='_w-100'>
          <GoogleMapWrapper>
            <GoogleMap
              bootstrapURLKeys={{
                key: MAPS_API_KEY
              }}
              defaultCenter={londonCenter}
              defaultZoom={12}
              options={createMapOptions}
              onGoogleApiLoaded={handleGoogleApiLoaded}
              yesIWantToUseGoogleMapApiInternals={true}
            >
              {drawOriginMarker()}
              {drawDestinationMarker()}
              {drawCourierMarker()}
            </GoogleMap>
          </GoogleMapWrapper>
        </Col>
      </Row>
    </>
  )
}

interface MarkerProps {
  key: string
  lat: number
  lng: number
  children: React.ReactNode
  fill?: string
}

const Marker = ({ key, lat, lng, fill, children }: MarkerProps) => {
  return (
    <MarkerRoot key={key} lat={lat} lng={lng}>
      <MarkerWrapper>
        <MarkerIcon style={fill ? { fill: fill } : {}} />
      </MarkerWrapper>
      <IconWrapper>{children}</IconWrapper>
    </MarkerRoot>
  )
}

const GoogleMapWrapper = styled.div(() => ({
  width: 'inherit',
  height: '300px'
}))

interface PinProps {
  lat: number
  lng: number
  key: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

const MarkerRoot = styled.div<PinProps>(() => ({
  width: '10px',
  height: '10px',
  position: 'relative'
}))

const MarkerWrapper = styled.div(() => ({
  width: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -60%)'
}))

const IconWrapper = styled.div(() => ({
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '50%',
  left: '50%',
  transform: 'translate(-50%, -55%)'
}))

export default DeliveryMapDetails
