import { createClient } from '@slerp/client'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import { Option } from '../types'
type CustomRuleType = { getFieldValue: (arg: string) => string }
type GetVariantOptionsType = {
  data?: {
    variant_options: [
      {
        options: null | Option[]
      }
    ]
  }
  loading: boolean
  networkStatus: number
}

const GET_VARIANT_OPTIONS = gql`
  query getVariantOptions($productId: uuid!) {
    variant_options: product_variants(
      where: { product_id: { _eq: $productId } }
    ) {
      options
    }
  }
`

const normalizeResponse = (response: GetVariantOptionsType) => {
  const { variant_options } = response?.data || {}
  return variant_options
}

const getVariantOptions = async (productId: string) => {
  const apiKey = localStorage.getItem('token') || ''
  const client = createClient(apiKey)
  const variables = {
    productId
  }

  return await client
    .query({
      query: GET_VARIANT_OPTIONS,
      variables
    })
    .then((response) => normalizeResponse(response))
}

const required = { required: true, message: 'This is a required field.' }
const variantGroupNameValidator = (options: String[]) => {
  const uniqueVariantGroupName = ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: string) {
      return options.includes(value)
        ? Promise.reject('Variant group name already exists.')
        : Promise.resolve()
    }
  })

  return [required, uniqueVariantGroupName]
}

const variantGroupOptionValidator = (
  productId: string,
  variantGroupName: string,
  activeVariantOptions: string[]
) => {
  const uniqueVariantOptionName = ({ getFieldValue }: CustomRuleType) => ({
    async validator(_: any, value: string) {
      const masterOptions = await getVariantOptions(productId)
      const variantGroupRelatedOptions = compact(
        masterOptions.map(({ options }: Options[]) =>
          (options || []).find(({ name }: Option) => name === variantGroupName)
        )
      ).map(({ value }: Option) => value)

      const additionalRejectCopyText = activeVariantOptions.includes(
        value.toLowerCase()
      )
        ? ''
        : 'Please unarchive the variant to use it.'

      return variantGroupRelatedOptions.includes(value.toLowerCase())
        ? Promise.reject(
            `You have a variant with this name already. ${additionalRejectCopyText}`
          )
        : Promise.resolve()
    }
  })

  return [required, uniqueVariantOptionName]
}

const nonSpecialCharacterRule = ({ getFieldValue }: CustomRuleType) => ({
  validator(_: any, tags: string[]) {
    const specialCharacterSets = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

    if (tags.length === 0) return Promise.reject('This is a required field.')

    const isWithSpecialCharacters = tags.find((tag: string) =>
      specialCharacterSets.test(tag)
    )

    return isEmpty(isWithSpecialCharacters)
      ? Promise.resolve()
      : Promise.reject("Variant options can't have special characters.")
  }
})

const singleNonSpecialCharacterRule = ({ getFieldValue }: CustomRuleType) => ({
  validator(_: any, optionName: string) {
    const specialCharacterSets = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    const isWithSpecialCharacters = specialCharacterSets.test(optionName)
    return isWithSpecialCharacters
      ? Promise.reject("Variant options can't have special characters.")
      : Promise.resolve()
  }
})

const caloriesFieldRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: number | null) {
      if (isNull(value)) {
        return Promise.resolve()
      }

      const isValid = isInteger(value)

      return isValid
        ? Promise.resolve()
        : Promise.reject('Please input flat numbers for calories')
    }
  })
]
const requiredRule = [required]
const optionsRule = [nonSpecialCharacterRule]
const optionNameRule = [required, singleNonSpecialCharacterRule]
export {
  requiredRule,
  caloriesFieldRule,
  variantGroupNameValidator,
  variantGroupOptionValidator,
  optionsRule,
  optionNameRule
}
