import React from 'react'
import styled from '@emotion/styled'
import { Radio, Space } from 'antd'

interface Option {
  key: string
  value: boolean | string
}

interface VerticalRadioProps {
  options: Array<Option>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: boolean | string
}

const VerticalRadio = ({ options, onChange, value }: VerticalRadioProps) => {
  return (
    <>
      <StyleRadioGroupContainer
        onChange={onChange}
        value={value}
        data-testid='vertical-radio-container'
      >
        <Space direction='vertical'>
          {options.map(({ key, value }: Option) => (
            <Radio key={key} value={value} data-testid={`radio-option-${key}`}>
              {key}
            </Radio>
          ))}
        </Space>
      </StyleRadioGroupContainer>
    </>
  )
}

const StyleRadioGroupContainer = styled(Radio.Group)`
  text-align: left;
  text-transform: capitalize;
`

export { VerticalRadio }
