import React from 'react'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { Tabs, Row, Col, Divider } from 'antd'
import { LocationSettingProps } from './Settings'
import DeliverySettings from './SameDaySettings/DeliverySettings'
import PickupSettings from './SameDaySettings/PickupSettings'
import Details from './SameDaySettings/Details'
import PreparationTime from './SameDaySettings/PreparationTime'

import { PageTooltip } from '../Widgets'

const { TabPane } = Tabs

const SameDaySettings = (props: LocationSettingProps) => {
  const { user } = useSession()
  const { store, merchant, refetch } = props
  const canManage = isAdmin(user) || isManager(user)
  const TABS = [
    {
      label: 'Order Types',
      key: 'details',
      content: (
        <Details {...{ store, merchant, refetch, disabled: !canManage }} />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/en_us/order-types-Hk5at4p_'
            target='_blank'
            rel='noopener noreferrer'
          >
            Order Types
          </a>
          <a
            href='https://support.slerp.com/knowledge/same-day-order-settings'
            target='_blank'
            rel='noopener noreferrer'
          >
            Same Day (ASAP)
          </a>
          <a
            href='https://support.slerp.com/knowledge/same-day-order-settings'
            target='_blank'
            rel='noopener noreferrer'
          >
            Same Day (Scheduled)
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Delivery',
      key: 'delivery',
      content: (
        <DeliverySettings
          {...{ store, merchant, refetch, disabled: !canManage }}
        />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/custom-delivery-radius'
            target='_blank'
            rel='noopener noreferrer'
          >
            Custom Radius (polygon)
          </a>
          <a
            href='https://support.slerp.com/knowledge/how-is-a-vehicle-chosen-for-an-order'
            target='_blank'
            rel='noopener noreferrer'
          >
            Delivery methods & vehicle allocation
          </a>
          <a
            href='https://support.slerp.com/knowledge/delivery-costs'
            target='_blank'
            rel='noopener noreferrer'
          >
            Delivery Costs
          </a>
          <a
            href='https://support.slerp.com/knowledge/fulfillment-types'
            target='_blank'
            rel='noopener noreferrer'
          >
            Fulfilment Types
          </a>
          <a
            href='https://support.slerp.com/knowledge/intro-to-delivery'
            target='_blank'
            rel='noopener noreferrer'
          >
            Local & Nationwide Delivery
          </a>
          <a
            href='https://support.slerp.com/en_us/order-limit-by-time-slot-BkFv9LjTO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Orders by time slot
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Pickup',
      key: 'pickup',
      content: (
        <PickupSettings
          {...{ store, merchant, refetch, disabled: !canManage }}
        />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/set-time-slots-for-same-day-orders'
            target='_blank'
            rel='noopener noreferrer'
          >
            Collection & Pick-up Timings
          </a>
          <a
            href='https://support.slerp.com/knowledge/fulfillment-types'
            target='_blank'
            rel='noopener noreferrer'
          >
            Fulfillment Types
          </a>
          <a
            href='https://support.slerp.com/en_us/order-limit-by-time-slot-BkFv9LjTO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Orders by time slot
          </a>
          <a
            href='https://support.slerp.com/en_us/intro-to-pick-up-BknFPoVTO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Pick-up & Collections
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Preparation Time',
      key: 'preparation-time',
      content: (
        <PreparationTime {...{ store, disabled: !canManage, merchant }} />
      ),
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/changing-your-preparation-time'
            target='_blank'
            rel='noopener noreferrer'
          >
            How to use Activity Mode
          </a>
        </PageTooltip>
      )
    }
  ]

  const defaultPageKey = 'details'
  const keyFromURL = window.location.hash.replace('#', '') || defaultPageKey
  const tabKeys = TABS.map((tab) => tab.key)
  const activeKey = tabKeys.includes(keyFromURL)
    ? keyFromURL.toString()
    : defaultPageKey

  return (
    <>
      <Tabs
        onTabClick={(key) =>
          (window.location.href = `${window.location.pathname}#${key}`)
        }
        defaultActiveKey={activeKey}
        /* I couldn't fix the animation 😿.
        Disabling it for now */
        animated={{ inkBar: false, tabPane: true }}
      >
        {TABS.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            <Row gutter={40} className='_mt-24 _pl-16'>
              <Col span={15}>{tab.content}</Col>
              <Col>
                <Divider type='vertical' />
              </Col>
              <Col>{tab.tooltip}</Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}
export default SameDaySettings
