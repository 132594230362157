import React from 'react'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { Button, Col, Form, Input, PageHeader, Space, message } from 'antd'
import { useMutation, useApolloClient } from '@apollo/client'
import UpdateNotice from './../../Utils/UpdateNotice'
import { LocationSettingProps } from './../Settings'
import { CREATE_STORE_USERS } from './../actions'
import StoreUsersForm from './../LocationForm/StoreUsersForm'

const UsersSettings = (props: LocationSettingProps) => {
  const { user } = useSession()
  const { store } = props
  const { users } = store
  const [updateSettings, { loading, error }] = useMutation(CREATE_STORE_USERS, {
    fetchPolicy: 'no-cache'
  })
  const client = useApolloClient()
  const canManageUsers = isAdmin(user) || isManager(user)

  const onFinish = (_: string, info: any) => {
    const { users } = info.values

    if (!users.length) {
      return message.success('Location Users Updated', 1)
    }

    message.loading('Updating... Please wait.')
    updateSettings({
      variables: {
        store_id: store.id,
        users: users.map((user: any) => ({
          firstname: user.firstName,
          lastname: user.lastName,
          email: user.email,
          password: user.password,
          role: 'member'
        }))
      }
    }).then(() => {
      message.destroy()
      client.resetStore()
      message.success('Location Users Created', 1)
    })
  }

  return (
    <Form.Provider onFormFinish={onFinish}>
      <PageHeader title='Location Users' className='settings-title' />
      <Col data-testid='existing-store-users-list' className='_pl-0'>
        {users.map(({ user }, index) => {
          return (
            <Space
              key={index}
              align='start'
              style={{
                display: 'flex',
                marginBottom: 8,
                marginRight: 8,
                marginLeft: -4
              }}
            >
              <Form.Item
                rules={[{ required: true, message: 'Missing first name' }]}
              >
                <Input
                  placeholder='First Name'
                  value={user.firstname}
                  disabled
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Missing last name' }]}
              >
                <Input placeholder='Last Name' value={user.lastname} disabled />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Missing email' }]}>
                <Input placeholder='Email' value={user.email} disabled />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Missing password' }]}
              >
                <Input
                  placeholder='Password'
                  type='password'
                  value='password'
                  disabled
                />
              </Form.Item>
            </Space>
          )
        })}
      </Col>
      {canManageUsers && (
        <>
          <PageHeader title='Add Users' className='settings-title' />
          <Col data-testid='store-users-settings-form'>
            <StoreUsersForm
              formData={{ users: [] }}
              layout='vertical'
              formActionButtons={
                <Form.Item style={{ marginTop: 32 }}>
                  <Button
                    title='Save'
                    htmlType='submit'
                    className='_ml-auto _center-vertical'
                  >
                    Save
                  </Button>
                  <UpdateNotice updating={loading} updateError={error} />
                </Form.Item>
              }
            />
          </Col>
        </>
      )}
    </Form.Provider>
  )
}
export default UsersSettings
