import React from 'react'
import { Radio, Space } from 'antd'

type CourierTypes = 'partner' | 'other'

const CourierType = ({
  value,
  handleChange,
  disabled = false
}: {
  value: CourierTypes
  handleChange: (value: CourierTypes) => void
  disabled?: boolean
}) => {
  return (
    <Radio.Group
      defaultValue={value}
      onChange={(e) => handleChange(e.target.value)}
      disabled={disabled}
      value={value}
    >
      <Space direction='vertical' align='start'>
        <Radio value={'partner'} data-testid='courier-type-partner'>
          Slerp courier partner
        </Radio>
        <Radio value={'other'} data-testid='courier-type-other'>
          My own drivers
        </Radio>
      </Space>
    </Radio.Group>
  )
}

export default CourierType
