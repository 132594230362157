import { AttributionFormObject, AttributionKeys, Location } from './types'
import { Attribution } from '@slerp/controls'
import { Decimal } from 'decimal.js'

export const ATTRIBUTIONS_MAP: { [key in AttributionKeys]: string } = {
  delivery: 'Managed delivery',
  pickup: 'Click & Collect',
  selfDelivery: 'Delivery with Own Drivers/Couriers',
  table: 'Table Ordering',
  nationwideDelivery: 'Nationwide Delivery'
}

export const chunkArray = <T>(arr: Array<T>, size = 2) => {
  const chunkedArray = []
  for (let i = 0; i < arr.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1]
    if (!last || last.length === size) {
      chunkedArray.push([arr[i]])
    } else {
      last.push(arr[i])
    }
  }
  return chunkedArray
}

interface Options {
  setDefault: boolean
}

export const attributionToFormObject = (
  attribution: Attribution,
  { setDefault }: Options = { setDefault: true }
): AttributionFormObject => {
  return Object.keys(ATTRIBUTIONS_MAP).reduce(
    (acc, cur) => {
      const value = attribution[cur as AttributionKeys]

      if (!value) {
        return setDefault ? { ...acc, [cur]: 0 } : { ...acc, [cur]: '-' }
      }

      const rawValue = parseFloat(value) * 100
      const parsedValue =
        rawValue.toFixed(2).split('.')[1] === '00'
          ? rawValue.toFixed()
          : rawValue.toFixed(2)

      return { ...acc, [cur]: parseFloat(parsedValue) }
    },
    { storeIds: [attribution?.store?.id] }
  ) as AttributionFormObject
}

export const getUnconnectedLocations = (
  locations: Location[],
  ids: string[]
) => {
  return locations.filter(
    (location) => ids.includes(location.id) && !location.hasStripeId
  )
}

export const netCommissionRate = (value: string, vatPercentage: string) => {
  const decimalGrossValue = new Decimal(Number(value || 0))
  const decimalVat = new Decimal(vatPercentage || 0)
  const decimalNetPrice = decimalGrossValue.dividedBy(decimalVat)

  return decimalNetPrice.times(100).toFixed(2)
}
