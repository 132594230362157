import React, { useContext } from 'react'
import { Col, Table, Divider, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import 'chart.js'
import { QUERY_MERCHANT_PRODUCT_STATS } from './ReportsQueries'
import { SlerpContext } from '@slerp/client'
import { useQuery } from '@apollo/client'
import Loading from '../Utils/Loading'

const { Column } = Table

const ProductOverview = () => {
  const { user } = useContext(SlerpContext)

  const { data } = useQuery(QUERY_MERCHANT_PRODUCT_STATS, {
    variables: { merchant: user.merchant.slug },
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000
  })

  return (
    (data && (
      <>
        <Col
          className='product-overview container-style _pl-0 _pr-0'
          data-testid='products-stats-component'
        >
          <Col span={24} className='_pt-12 _pr-16 _pb-12 _pl-16'>
            <span className='_mt-0'>
              Top 10 selling products in the last 30 days
            </span>
            <Tooltip
              title={
                <span>
                  This is a ranking of your <br />
                  <a
                    href='https://support.slerp.com/knowledge/top-sellers-overview'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    best performing products
                  </a>{' '}
                  by volume and revenue.
                </span>
              }
              className='_ml-8'
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Col>
          <Divider className='_mt-0 _mb-0' />
          <Table dataSource={data.products} pagination={false}>
            <Column
              className='product-table -header'
              title='Product'
              key='name'
              dataIndex='name'
              colSpan={1}
            />
            <Column
              title='Total Orders'
              key='total_orders'
              dataIndex='total_count'
              colSpan={1}
              className='product-table -header'
            />
            <Column
              title='Total Amount'
              key='total_amount'
              dataIndex='total_amount'
              render={(text: string) => `£${Number(text)}`}
              colSpan={1}
              className='product-table -header'
            />
          </Table>
        </Col>
      </>
    )) || <Loading />
  )
}

export default ProductOverview
