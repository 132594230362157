import React, { useEffect, useState, useMemo } from 'react'
import {
  Button,
  Col,
  Drawer,
  message,
  PageHeader,
  Popconfirm,
  Row,
  Table,
  Tooltip
} from 'antd'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { uuid } from 'uuidv4'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { SettingProps } from '@slerp/controls'
import { DELETE_ADMIN_USER, GET_MERCHANT_ADMIN_USERS } from './actions'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import AdminUserForm from './AdminUserForm/index'

interface AdminUser {
  id: string
  email: string
  firstname: string
  lastname: string
}

const MIN_ADMIN_USERS = 2

const MerchantAdminUsersSettings = ({ merchant, refetch }: SettingProps) => {
  const { user } = useSession()
  const client = useApolloClient()

  const [adminUsers, setAdminUsers] = useState<Array<AdminUser>>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showEditDrawer, setShowEditDrawer] = useState<boolean>(false)
  const [editingUser, setEditingUser] = useState<AdminUser>({})

  const currentUser = useMemo(
    () => adminUsers.filter(({ id }: AdminUser) => id === user.id)[0] || {},
    [adminUsers, user]
  )
  const canRemove = adminUsers.length > MIN_ADMIN_USERS

  const { data: usersData, refetch: adminUsersRefetch } = useQuery(
    GET_MERCHANT_ADMIN_USERS,
    {
      variables: {
        merchantId: merchant.id
      }
    }
  )

  const [deleteAdminUser, { loading: deletingAdminUser }] = useMutation(
    DELETE_ADMIN_USER,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const deleteUser = (id: string, email: string) => {
    deleteAdminUser({
      variables: { userId: id, merchantId: merchant.id }
    })
      .then((response) => {
        message.destroy()
        message.success(`User ${email} was deleted successfully.`)
        client.resetStore()
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(`Unable to delete user. Due to ${error.message}`, 3)
      })
  }

  const COLUMNS = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => {
        if (role === 'member') return 'Location user'
        return capitalize(role)
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (_val: string, adminUser: AdminUser) => {
        const { id, email } = adminUser
        const isCurrentUser = id === user.id
        return (
          <Row
            gutter={[16, 0]}
            className='_mb-0'
            justify='end'
            data-testid={
              isCurrentUser
                ? 'current-user-actions'
                : 'non-current-user-actions'
            }
          >
            <Col>
              <Button
                data-testid='merchant-admin-users-edit-btn'
                loading={deletingAdminUser}
                shape='circle'
                icon={<EditOutlined />}
                onClick={() => {
                  setEditingUser(adminUser)
                  setShowEditDrawer(true)
                }}
              />
            </Col>
            {canRemove && !isCurrentUser && (
              <Col>
                <Popconfirm
                  title={`Are sure you want to delete ${email}?`}
                  okText='Yes'
                  cancelButtonProps={{ type: 'ghost' }}
                  cancelText='No'
                  onConfirm={() => deleteUser(id, email)}
                >
                  <Button
                    loading={deletingAdminUser}
                    data-testid='merchant-admin-users-remove-btn'
                    shape='circle'
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </Col>
            )}
          </Row>
        )
      }
    }
  ]

  useEffect(() => {
    if (usersData) {
      const { users } = usersData
      const currentUserProfile = users.find(({ id }) => id === user.id)
      const adminUserProfiles = users.filter(({ id }) => id !== user.id)

      setAdminUsers(compact([currentUserProfile, ...adminUserProfiles]))
    }
  }, [usersData, user])

  return (
    <Col span={24}>
      <PageHeader
        title={
          <>
            Users
            <Tooltip
              title={
                <span>
                  Here you can add store managers or support team mates who need
                  their own logins.
                </span>
              }
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
        extra={[
          <Button
            key='show-modal-button'
            loading={deletingAdminUser}
            onClick={() => setShowModal(true)}
            data-testid='merchant-admin-users-add-button'
          >
            Add New User
          </Button>
        ]}
      />
      <Drawer
        width={720}
        onClose={() => setShowEditDrawer(false)}
        visible={showEditDrawer}
        title='Edit User'
      >
        <>
          <Row justify='center' align='middle'>
            <Col span={24}>
              <AdminUserForm
                canEditDetails={currentUser.id === editingUser.id}
                adminUser={editingUser}
                key={uuid()}
                onCancelCallback={() => setShowEditDrawer(false)}
                onSaveCallback={() => {
                  setShowEditDrawer(false)
                  adminUsersRefetch()
                  refetch()
                }}
              />
            </Col>
          </Row>
        </>
      </Drawer>
      <Drawer
        width={720}
        onClose={() => setShowModal(false)}
        visible={showModal}
        title='Add New User'
      >
        <Row justify='center' align='middle'>
          <Col span={24}>
            <AdminUserForm
              canEditDetails={true}
              key={uuid()}
              onCancelCallback={() => setShowModal(false)}
              onSaveCallback={() => {
                setShowModal(false)
                adminUsersRefetch()
                refetch()
              }}
            />
          </Col>
        </Row>
      </Drawer>
      <Row
        justify='center'
        align='middle'
        data-testid='merchant-admin-users-table-container'
      >
        <Col span={24}>
          {adminUsers && (
            <Table
              dataSource={adminUsers}
              columns={COLUMNS}
              className='merchant-admin-users-table'
            />
          )}
        </Col>
      </Row>
    </Col>
  )
}

export default MerchantAdminUsersSettings
