import { gql } from '@apollo/client'

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: uuid!, $settings: jsonb!) {
    update_merchants(
      where: { id: { _eq: $id } }
      _append: { setting: $settings }
    ) {
      affected_rows
      returning {
        setting
      }
    }
  }
`

export const UPDATE_GENERAL_SETTINGS = gql`
  mutation updateSettings(
    $id: uuid!
    $settings: jsonb!
    $name: String!
    $registered_company_name: String
    $cro_registered_number: String
  ) {
    update_merchants(
      where: { id: { _eq: $id } }
      _append: { setting: $settings }
      _set: {
        name: $name
        registered_company_name: $registered_company_name
        cro_registered_number: $cro_registered_number
      }
    ) {
      affected_rows
      returning {
        setting
      }
    }
  }
`

export const CREATE_COMPANY_ADDRESS = gql`
  mutation createCompanyAddress(
    $id: uuid!
    $merchant_id: uuid!
    $company_phone_number: String
    $line_1: String
    $line_2: String
    $city: String
    $country: String
    $zip: String
  ) {
    insert_addresses_one(
      object: {
        id: $id
        merchant_id: $merchant_id
        contact_num: $company_phone_number
        line_1: $line_1
        line_2: $line_2
        city: $city
        country: $country
        zip: $zip
        inserted_at: "now()"
        updated_at: "now()"
      }
    ) {
      id
      merchant_id
    }
  }
`

export const UPDATE_COMPANY_ADDRESS = gql`
  mutation updateCompanyAddress(
    $merchant_id: uuid!
    $company_phone_number: String
    $line_1: String
    $line_2: String
    $city: String
    $country: String
    $zip: String
  ) {
    update_addresses(
      where: { merchant_id: { _eq: $merchant_id } }
      _set: {
        contact_num: $company_phone_number
        line_1: $line_1
        line_2: $line_2
        city: $city
        country: $country
        zip: $zip
        updated_at: "now()"
      }
    ) {
      affected_rows
    }
  }
`

export const UPDATE_MARKETING_AND_ANALYTICS_SETTINGS = gql`
  mutation UpdateMarketingAndAnalyticsSettings(
    $id: uuid!
    $settings: jsonb!
    $trustPilotEmail: String
  ) {
    update_merchants(
      where: { id: { _eq: $id } }
      _append: { setting: $settings }
      _set: { trust_pilot_email: $trustPilotEmail }
    ) {
      affected_rows
      returning {
        setting
      }
    }
  }
`

export const UPDATE_VEHICLE_SETTINGS = gql`
  mutation updateVehicleSettings(
    $id: uuid!
    $vehicles: jsonb!
    $pre_order_vehicles: jsonb!
  ) {
    update_merchants(
      where: { id: { _eq: $id } }
      _append: { vehicles: $vehicles, pre_order_vehicles: $pre_order_vehicles }
    ) {
      affected_rows
      returning {
        vehicles
        pre_order_vehicles
      }
    }
  }
`

export const GET_MERCHANT_ADMIN_USERS = gql`
  query getMerchantAdminUsers($merchantId: uuid) {
    users(
      where: {
        merchant_id: { _eq: $merchantId }
        _or: [
          { role: { _eq: "admin" } }
          { role: { _eq: "manager" } }
          { role: { _eq: "member" } }
        ]
        archived_at: { _is_null: true }
      }
      order_by: { role: asc, firstname: asc }
    ) {
      id
      firstname
      lastname
      email
      role
    }
  }
`

export const QUERY_STRIPE_CONNECT_URL = gql`
  query stripeConnect($redirectUri: String, $merchantId: String) {
    stripeConnectUrl(redirectUri: $redirectUri, merchantId: $merchantId) {
      url
      is_connected
      type
      login_link
    }
  }
`

export const QUERY_STORE_STRIPE_CONNECT_URL = gql`
  query storeStripeConnectUrl($storeId: String, $redirectUri: String) {
    storeStripeConnectUrl(storeId: $storeId, redirectUri: $redirectUri) {
      url
      is_connected
      type
      login_link
    }
  }
`

export const CREATE_MERCHANT_ADMIN_USER = gql`
  mutation createMerchantAdminUser(
    $merchantId: String!
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
  ) {
    createMerchantUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      merchantId: $merchantId
      password: $password
    ) {
      id
    }
  }
`

export const CREATE_MERCHANT_USER_BY_ROLE = gql`
  mutation createUserStoreLevelUserByRole(
    $merchantId: String!
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $role: String!
  ) {
    createMerchantStoreLevelUserByRole(
      email: $email
      firstname: $firstname
      lastname: $lastname
      merchantId: $merchantId
      password: $password
      role: $role
    ) {
      id
    }
  }
`

export const CREATE_MULTIPLE_STORE_USER = gql`
  mutation createMerchantAdminStoreUsers(
    $merchantId: String!
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
  ) {
    createMerchantMultipleStoreUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      merchantId: $merchantId
      password: $password
    ) {
      id
    }
  }
`

export const CREATE_STORE_USERS = gql`
  mutation createStoreUsers($storeUsers: [store_users_insert_input!]!) {
    insert_store_users(objects: $storeUsers) {
      affected_rows
    }
  }
`

export const CONNECT_AND_UPDATE_STRIPE_SETTINGS = gql`
  mutation stripeUpdate($authCode: String!, $merchantId: String!) {
    connectAndUpdateStripeSettings(
      authCode: $authCode
      merchantId: $merchantId
    ) {
      id
    }
  }
`
export const UPDATE_MERCHANT_ADMIN_USER = gql`
  mutation updateAdminUser(
    $merchantId: uuid!
    $userId: uuid!
    $firstname: String!
    $lastname: String!
    $email: String!
    $role: String!
    $storeUsers: [store_users_insert_input!]!
  ) {
    update_users(
      where: { merchant_id: { _eq: $merchantId }, id: { _eq: $userId } }
      _set: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        role: $role
        updated_at: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        firstname
        lastname
        email
      }
    }
    delete_store_users(where: { user_id: { _eq: $userId } }) {
      affected_rows
    }
    insert_store_users(objects: $storeUsers) {
      affected_rows
    }
  }
`

export const DELETE_ADMIN_USER = gql`
  mutation deleteAdminUser($userId: String!, $merchantId: String!) {
    deleteMerchantUser(userId: $userId, merchantId: $merchantId) {
      id
    }
  }
`

export const CREATE_STRIPE_ACCOUNT = gql`
  mutation createStripeAccount(
    $merchant_id: ID!
    $merchant_name: String!
    $email: String!
    $stripe_token: String!
  ) {
    createStripeAccount(
      merchantId: $merchant_id
      merchantName: $merchant_name
      email: $email
      stripeToken: $stripe_token
    ) {
      cards {
        id
        last4
      }
      isSuccessful
      stripeCustomerId
    }
  }
`

export const ADD_CARD_TO_STRIPE_CUSTOMER_ACCOUNT = gql`
  mutation addCardToStripeCustomerAccount(
    $merchant_id: ID!
    $stripe_customer_id: String!
    $stripe_token: String!
  ) {
    addStripeCustomerCard(
      merchantId: $merchant_id
      stripeCustomerId: $stripe_customer_id
      stripeToken: $stripe_token
    ) {
      stripeCustomerId
      last4
      expYear
      expMonth
    }
  }
`

export const DELETE_CARD_FROM_STRIPE_CUSTOMER_ACCOUNT = gql`
  mutation deleteCardFromStripeCustomerAccount(
    $merchant_id: ID!
    $card_id: String!
    $stripe_customer_id: String!
  ) {
    deleteStripeCustomerCard(
      merchantId: $merchant_id
      cardId: $card_id
      stripeCustomerId: $stripe_customer_id
    )
  }
`

export const UPDATE_DEFAULT_SOURCE = gql`
  mutation updateDefaultSource($merchantId: ID!, $cardId: String!) {
    updateDefaultSource(merchantId: $merchantId, cardId: $cardId) {
      defaultSource
    }
  }
`
