import React from 'react'
import styled from '@emotion/styled'
import { Checkbox } from 'antd'

interface Option {
  label: string
  value: string
}

interface VerticalCheckboxProps {
  options: Array<Option>
  onChange: (e: Array<string>) => void
  values: Array<string>
}

const VerticalCheckbox = ({
  options,
  onChange,
  values
}: VerticalCheckboxProps) => {
  return (
    <>
      <StyledCheckboxGroup
        data-testid='vertical-checkbox'
        defaultValue={values}
        options={options}
        onChange={onChange}
      />
    </>
  )
}

const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
`

export { VerticalCheckbox }
