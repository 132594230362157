import React from 'react'
import { Form, Input, Row, Col } from 'antd'
import { requiredRule } from './Rules'

interface AddressFormProps {
  addressLineBlurCallback?: () => void
  disabled?: boolean
}

const AddressForm = ({
  addressLineBlurCallback,
  disabled
}: AddressFormProps) => {
  return (
    <>
      <Form.Item name='address_line_1' rules={requiredRule}>
        <Input
          placeholder='Address line 1'
          onBlur={addressLineBlurCallback}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item name='address_line_2'>
        <Input
          placeholder='Address line 2 (*optional)'
          onBlur={addressLineBlurCallback}
          disabled={disabled}
        />
      </Form.Item>
      <Row className='_mb-0' gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item name='address_city' rules={requiredRule}>
            <Input placeholder='City' disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='address_postcode' rules={requiredRule}>
            <Input placeholder='Postcode' disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name='address_country' rules={requiredRule}>
        <Input placeholder='Country' disabled={disabled} />
      </Form.Item>
      <Form.Item name='address_lat' className='_display-none'>
        <Input placeholder='lat' type='hidden' />
      </Form.Item>
      <Form.Item name='address_lng' className='_display-none'>
        <Input placeholder='lng' type='hidden' />
      </Form.Item>
    </>
  )
}

export default AddressForm
