import { createClient } from '@slerp/client'
import gql from 'graphql-tag'

const CHECK_DISCOUNT_CODE = gql`
  query checkDiscountsCode($slug: String!, $code: String!) {
    discounts(
      where: { merchant: { slug: { _eq: $slug } }, code: { _eq: $code } }
    ) {
      code
    }
  }
`
type CheckDiscountCodeValidityType = {
  data?: {
    discounts: [
      {
        code: string
      }
    ]
  }
  loading: boolean
  networkStatus: number
}

const normalizeResponse = (response: CheckDiscountCodeValidityType) => {
  const { discounts } = response?.data || {}
  return discounts
}

const checkDiscountCodeValidity = async (code: string) => {
  const apiKey = localStorage.getItem('token') || ''
  const slug = localStorage.getItem('merchant') || ''
  const client = createClient(apiKey)
  const variables = { slug, code }

  return await client
    .query({
      query: CHECK_DISCOUNT_CODE,
      variables
    })
    .then((response) => normalizeResponse(response))
}

export { checkDiscountCodeValidity }
