import React, { useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  message
} from 'antd'
import { UPDATE_CAMPAIGN } from './AppSettingsQueries'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { CalendarIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from './../Widgets'
import { requiredRule } from './Rules'

export type Campaign = {
  id: string
  title: string
  notificationTitle: string
  notificationBody: string
  publishOn: string
}

type CampaignFormType = {
  title: string
  notificationTitle: string
  notificationBody: string
  publishOn: string
}

const timeFormat = 'HH:mm'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const CampaignForm = ({ campaign, setDrawerVisibility, refetch }) => {
  const [form] = Form.useForm()

  const [publishOn, setPublishOn] = useState<moment.Moment>(
    moment(campaign.publishOn)
  )

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setDrawerVisibility(false)
      refetch()

      message.destroy()
      message.success('Campaign updated!')
    },
    onError: () => {
      message.destroy()
      message.error('Something went wrong!')
    }
  })

  const onFinish = (values: CampaignFormType) => {
    const variables = {
      id: campaign.id,
      campaign: { ...values, publishOn: publishOn.toISOString() }
    }

    updateCampaign({ variables: variables })
  }

  return (
    <Form
      className='_mt-8'
      form={form}
      onFinish={onFinish}
      initialValues={campaign}
      data-testid='campaign-form'
      layout='vertical'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 20 }}
    >
      <Form.Item
        label='Campaign title'
        name='title'
        required
        rules={requiredRule}
        className='_mb-16'
      >
        <Input placeholder='January Offers' />
      </Form.Item>
      <Form.Item
        label='Notification title'
        name='notificationTitle'
        required
        rules={requiredRule}
        className='_mb-16'
      >
        <Input placeholder='20% Off Tonight!' />
      </Form.Item>
      <Form.Item
        label='Notification body'
        name='notificationBody'
        required
        rules={requiredRule}
      >
        <Input.TextArea placeholder='Use code JAN20 to get 20% off tonight!' />
      </Form.Item>
      <Form.Item
        name='publishOn'
        label='Send notifications on'
        className='_mt-24'
      >
        <SelectPrefixWrapper prefixicon={<CalendarIcon />}>
          <DatePicker
            format={dateTimeFormat}
            allowClear={false}
            defaultValue={publishOn}
            onChange={(value: any) => {
              setPublishOn(value)
            }}
            className='_pl-48'
            showTime={{ format: timeFormat }}
          />
        </SelectPrefixWrapper>
      </Form.Item>
      <Divider />
      <Col span={24}>
        <Row gutter={16}>
          <Col>
            <Button
              onClick={() => setDrawerVisibility(false)}
              type='ghost'
              className='_uppercase'
              data-testid='campaign-form-cancel-button'
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              title={form.getFieldValue('id') ? 'Update' : 'Add'}
              type='primary'
              htmlType='submit'
              className='_uppercase'
              data-testid='campaign-form-submit-button'
            >
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  )
}

export default CampaignForm
