import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Button, Col, List, message, Row, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  GET_MERCHANT_MODIFIER_GROUPS,
  UNARCHIVE_MODIFIER_GROUP
} from '../ModifierQueries'
import Loading from '../../../Utils/Loading'

interface arrangement {
  [id: string]: string
}

interface ModifierGroupModifier {
  modifier_id: string
}

interface ModifierGroup {
  id: string
  name: string
  description: string
  sku: string | null
  minimum: number
  minimum_enabled: boolean
  maximum: number
  maximum_enabled: boolean
  archived_at: string | null
  modifier_arrangement: arrangement
  modifier_group_modifiers: ModifierGroupModifier[]
}

const ModifierGroupsArchiveList = () => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [modifierGroups, setModifierGroups] = useState<Array<ModifierGroup>>([])
  const [processingModifierGroupRow, setProcessingModifierGroupRow] =
    useState<string>('')

  const {
    data: merchantModifierGroups,
    loading: fetchingMerchantModifierGroups
  } = useQuery(GET_MERCHANT_MODIFIER_GROUPS, {
    variables: {
      merchantId: merchant.id,
      active: false
    }
  })

  const [unarchiveModifierGroup] = useMutation(UNARCHIVE_MODIFIER_GROUP)

  useEffect(() => {
    if (merchantModifierGroups) {
      const { modifier_groups } = merchantModifierGroups
      setModifierGroups(modifier_groups)
    }
  }, [merchantModifierGroups])

  const restoreHandler = (id: string) => {
    message.destroy()
    message.loading(`Restoring modifier group...`, 1)
    setProcessingModifierGroupRow(id)
    unarchiveModifierGroup({
      variables: {
        modifierGroupId: id
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Modifier group restored!', 3)
        client.resetStore()
        setProcessingModifierGroupRow('')
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Modifier group failed to restor due to ${error.message}`,
          3
        )
        setProcessingModifierGroupRow('')
      })
  }

  const renderModifierGroupRow = (modifierGroup: ModifierGroup) => {
    const { id, name, description } = modifierGroup
    const isOperationInProgress = !isEmpty(processingModifierGroupRow)
    const isCurrentRowProcessing = processingModifierGroupRow === id

    return (
      <List.Item
        data-testid={`archived-modifier-groups-list-item-${id}`}
        actions={[
          <Tooltip title='Restore modifier group'>
            <Button
              size='small'
              icon={<ReloadOutlined />}
              disabled={isOperationInProgress}
              loading={isCurrentRowProcessing}
              onClick={() => restoreHandler(id)}
            />
          </Tooltip>
        ]}
      >
        {`${name} (${description})`}
      </List.Item>
    )
  }

  return (
    <>
      {fetchingMerchantModifierGroups && <Loading />}
      {!fetchingMerchantModifierGroups && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <List
              bordered
              dataSource={modifierGroups}
              size='small'
              renderItem={renderModifierGroupRow}
              pagination={{
                defaultCurrent: 1,
                showSizeChanger: true
              }}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default ModifierGroupsArchiveList
