import React, { useState } from 'react'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Typography
} from 'antd'
import { ADD_MISC_FEE } from 'components/Orders/OrderQueries'
import { useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import { Store } from 'rc-field-form/lib/interface'
import { OrderInfo } from 'components/Orders/Info/type'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

type Props = OrderInfo & {
  refetch?: () => void
}

const MiscFeeForm = (props: Props) => {
  const { order } = props

  const DELIVERY_ADJUSTMENT = 'slerp_adjustment_delivery'
  const TOTAL_ADJUSTMENT = 'slerp_adjustment_total'
  const PRODUCT_ADJUSTMENT = 'slerp_adjustment_product'
  const WAITING_FEES = 'waiting_fees'
  const COURIER_DISCOUNT = 'courier_discount'

  const feeTypeOptions = [
    { value: WAITING_FEES, label: 'Waiting fee' },
    { value: COURIER_DISCOUNT, label: 'Courier credit' },
    { value: DELIVERY_ADJUSTMENT, label: 'Slerp Adjustment (Delivery)' },
    { value: PRODUCT_ADJUSTMENT, label: 'Slerp Adjustment (Product)' },
    { value: TOTAL_ADJUSTMENT, label: 'Slerp Adjustment (Total)' }
  ]

  const [showMiscFeeForm, setShowMiscFeeForm] = useState(false)
  const [hasCouponCode, setHasCouponCode] = useState(false)
  const [canSetAmount, setCanSetAmount] = useState(true)

  const { delivery_charge, order_items, payment } = order
  const deliveryFee = delivery_charge || 0
  const productTotal = order_items.reduce((total, orderItem) => {
    return total + orderItem.amount
  }, 0)
  const grandTotal = payment ? payment.data.amount / 100 : 0

  const [form] = Form.useForm()

  const [addMiscFee] = useMutation(ADD_MISC_FEE, {
    fetchPolicy: 'no-cache'
  })

  const handleFeeTypeChange = (feeType: string) => {
    if (feeType === COURIER_DISCOUNT) {
      setHasCouponCode(true)
      setCanSetAmount(true)
      form.setFieldsValue({ amount: 0 })
      return
    }

    if ([WAITING_FEES, COURIER_DISCOUNT].includes(feeType)) {
      setHasCouponCode(false)
      setCanSetAmount(true)
      form.setFieldsValue({ amount: 0 })
      return
    }

    setHasCouponCode(false)
    setCanSetAmount(false)

    if (feeType === PRODUCT_ADJUSTMENT) {
      form.setFieldsValue({ amount: productTotal })
    }

    if (feeType === DELIVERY_ADJUSTMENT) {
      form.setFieldsValue({ amount: deliveryFee })
    }

    if (feeType === TOTAL_ADJUSTMENT) {
      form.setFieldsValue({ amount: grandTotal })
    }
  }

  const handleSubmit = (values: Store) => {
    const amount =
      values.type === WAITING_FEES ? values.amount * -1 : values.amount

    addMiscFee({
      variables: {
        miscFeeObject: {
          id: uuid(),
          order_id: order.id,
          type: values.type,
          amount: amount,
          note: values.note,
          coupon_code: values.coupon_code,
          inserted_at: 'now()',
          updated_at: 'now()'
        }
      }
    }).then(() => {
      setShowMiscFeeForm(false)
      form.resetFields()
      props.refetch && props.refetch()
    })
  }

  return (
    <>
      <Row align='middle' justify='end'>
        <Col>
          <Tooltip
            title={
              <a
                href='https://support.slerp.com/en_us/adding-a-fee-adjustment-r1ncUBiT_'
                target='_blank'
                rel='noopener noreferrer'
              >
                Add adjustment fee
              </a>
            }
          >
            <QuestionCircleOutlined className='_mr-8' />
          </Tooltip>
          <Button
            title='accept'
            style={{ marginTop: 18 }}
            hidden={showMiscFeeForm}
            onClick={() => setShowMiscFeeForm(true)}
            icon={<PlusOutlined />}
            ghost
          >
            Add Adjustment Fee
          </Button>
        </Col>
      </Row>
      <Form
        form={form}
        name='misc-fee-form'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        hidden={!showMiscFeeForm}
        onFinish={handleSubmit}
      >
        <Typography.Title level={4} style={{ marginTop: 18, marginLeft: 110 }}>
          Add fee / adjustment
        </Typography.Title>

        <Form.Item name='type' label='Type' initialValue='waiting_fees'>
          <Select
            options={feeTypeOptions}
            onSelect={(_, option) => handleFeeTypeChange(option.value)}
          />
        </Form.Item>

        {hasCouponCode && (
          <Form.Item name='coupon_code' label='Coupon code'>
            <Input type='text' placeholder='Coupon code' />
          </Form.Item>
        )}

        <Form.Item
          name='amount'
          label='Amount'
          initialValue={0}
          rules={[
            {
              required: true,
              message: 'Amount is required'
            }
          ]}
        >
          <Input
            type='number'
            min={0}
            step={0.01}
            disabled={!canSetAmount}
            onFocus={onFocusScrollNumber}
            onBlur={onBlurScrollNumber}
          />
        </Form.Item>

        <Form.Item name='note' label='Notes'>
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 14, span: 10 }}>
          <Button
            type='default'
            onClick={() => setShowMiscFeeForm(false)}
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default MiscFeeForm
