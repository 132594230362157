import React, { useCallback, useState } from 'react'
import { Drawer, message, Spin, Tabs } from 'antd'
import _ from 'lodash'
import {
  flattenUniqueItems,
  getFlattenedPublishedIDs,
  getFlattenedUnpublishedIDs,
  generateStoreModifierInsertParams,
  generateStoreVariantInsertParams
} from './helpers'
import {
  InventoryGroup,
  InventoryPublishDrawerProps,
  SortedCategory,
  WithChanges
} from './types'
import InventoryChangesModal from './InventoryChangesModal'
import ProductVariants from './InventoryPublisher/ProductVariants'
import Modifiers from './InventoryPublisher/Modifiers'
import Actions from './InventoryPublisher/Actions'

const { TabPane } = Tabs

const InventoryPublishDrawer = (props: InventoryPublishDrawerProps) => {
  const [variants, setVariants] = useState<InventoryGroup[]>([])
  const [pristineVariants, setPristineVariants] = useState<InventoryGroup[]>([])
  const [modifiers, setModifiers] = useState<InventoryGroup[]>([])
  const [pristineModifiers, setPristineModifiers] = useState<InventoryGroup[]>(
    []
  )
  const [modifierGroupsData, setModifierGroupsData] = useState({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [variantsWithChanges, setVariantsWithChanges] = useState<WithChanges>()
  const [modifiersWithChanges, setModifiersWithChanges] =
    useState<WithChanges>()

  const [sortedVariantGroups, setSortedVariantGroups] = useState<
    SortedCategory[]
  >([])
  const [sortedModifierGroups, setSortedModifierGroups] = useState<
    InventoryGroup[]
  >([])

  const handleDetermineChanges = useCallback(
    (original: InventoryGroup[], change: InventoryGroup[]) => {
      const originals = flattenUniqueItems(original)
      const changes = flattenUniqueItems(change)

      const published = changes.filter((c) => {
        const item = originals.find((o) => o.id === c.id)

        return item?.isPublished !== c.isPublished && !item?.isPublished
      })

      const unpublished = changes.filter((c) => {
        const item = originals.find((o) => o.id === c.id)

        return item?.isPublished !== c.isPublished && item?.isPublished
      })

      return {
        published: published,
        unpublished: unpublished
      }
    },
    [variants, modifiers, pristineVariants, pristineModifiers]
  )

  const saveChanges = () => {
    const {
      publishStoreModifiers,
      unpublishStoreModifiers,
      publishStoreVariants,
      unpublishStoreVariants,
      createStoreModifiers,
      createStoreVariants,
      isPreorder,
      updateStoreCategoriesArrangements,
      updateStoreModifierGroupArrangements
    } = props

    const [orphanedVariants, persistedVariants] = _.partition(
      flattenUniqueItems(variants),
      ({ orphaned }) => !!orphaned
    )
    const publishedVariantIds = _.uniq(
      getFlattenedPublishedIDs(persistedVariants)
    )
    const unpublishedVariantIds = _.uniq(
      getFlattenedUnpublishedIDs(persistedVariants)
    )
    const publishedOrphanedVariantIds = _.uniq(
      getFlattenedPublishedIDs(orphanedVariants)
    )
    const unPublishedOrphanedVariantIds = _.uniq(
      getFlattenedUnpublishedIDs(orphanedVariants)
    )

    const [orphanedModifiers, persistedModifiers] = _.partition(
      flattenUniqueItems(modifiers),
      ({ orphaned }) => !!orphaned
    )
    const publishedModifierIds = _.uniq(
      getFlattenedPublishedIDs(persistedModifiers)
    )
    const unpublishedModifierIds = _.uniq(
      getFlattenedUnpublishedIDs(persistedModifiers)
    )
    const publishedOrphanedModifierIds = _.uniq(
      getFlattenedPublishedIDs(orphanedModifiers)
    )
    const unPublishedOrphanedModifierIds = _.uniq(
      getFlattenedUnpublishedIDs(orphanedModifiers)
    )

    const categoriesArrangementsInputs = sortedVariantGroups.map((group) => {
      return {
        categoryId: group.id,
        productIds: group.products.map((product) => {
          return product.id
        })
      }
    })

    const modifierGroupArrangementInputs = sortedModifierGroups.map((group) => {
      return {
        modifierGroupId: group.id,
        modifierIds: group.items.map((item) => {
          return item.id
        })
      }
    })

    setIsLoading(true)
    Promise.all([
      publishedVariantIds.length &&
        publishStoreVariants({
          variables: {
            variantIds: publishedVariantIds,
            storeId: props.storeInfo.id
          }
        }),
      unpublishedVariantIds.length &&
        unpublishStoreVariants({
          variables: {
            variantIds: unpublishedVariantIds,
            storeId: props.storeInfo.id
          }
        }),
      publishedOrphanedVariantIds.length &&
        createStoreVariants({
          variables: {
            storeVariants: generateStoreVariantInsertParams({
              variantIds: publishedOrphanedVariantIds,
              storeId: props.storeInfo.id,
              isPublished: true,
              isPreorder,
              inStock: true
            })
          }
        }),
      unPublishedOrphanedVariantIds.length &&
        createStoreVariants({
          variables: {
            storeVariants: generateStoreVariantInsertParams({
              variantIds: unPublishedOrphanedVariantIds,
              storeId: props.storeInfo.id,
              isPublished: false,
              isPreorder,
              inStock: true
            })
          }
        }),
      publishedModifierIds.length &&
        publishStoreModifiers({
          variables: {
            modifierIds: publishedModifierIds,
            storeId: props.storeInfo.id
          }
        }),
      unpublishedModifierIds.length &&
        unpublishStoreModifiers({
          variables: {
            modifierIds: unpublishedModifierIds,
            storeId: props.storeInfo.id
          }
        }),
      publishedOrphanedModifierIds.length &&
        createStoreModifiers({
          variables: {
            storeModifiers: generateStoreModifierInsertParams({
              modifierIds: publishedOrphanedModifierIds,
              storeId: props.storeInfo.id,
              isPublished: true,
              isPreorder,
              inStock: true
            })
          }
        }),
      unPublishedOrphanedModifierIds.length &&
        createStoreModifiers({
          variables: {
            storeModifiers: generateStoreModifierInsertParams({
              modifierIds: unPublishedOrphanedModifierIds,
              storeId: props.storeInfo.id,
              isPublished: false,
              isPreorder,
              inStock: true
            })
          }
        }),
      sortedVariantGroups.length &&
        updateStoreCategoriesArrangements({
          variables: {
            categoriesArrangementsInputs: categoriesArrangementsInputs,
            storeId: props.storeInfo.id,
            orderType: isPreorder ? 'PREORDER' : 'SAMEDAY'
          }
        }),
      sortedModifierGroups.length &&
        updateStoreModifierGroupArrangements({
          variables: {
            modifierGroupArrangementInputs: modifierGroupArrangementInputs,
            storeId: props.storeInfo.id,
            orderType: isPreorder ? 'PREORDER' : 'SAMEDAY'
          }
        })
    ])
      .then(() => props.refetchStoreInventory())
      .then(() => {
        message.success('Inventory updated successfully!', 1)
        setShowModal(false)
      })
      .catch((error: Error) => {
        message.error(`Failed to update inventory due to ${error.message}`, 3)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleOnDiscard = () => {
    props.onClose()
  }

  const handleChangesChecks = () => {
    const variantChanges = handleDetermineChanges(pristineVariants, variants)
    const modifierChanges = handleDetermineChanges(pristineModifiers, modifiers)

    const { published: publishedVariant, unpublished: unpublishedVariant } =
      variantChanges
    const { published: publishedModifier, unpublished: unpublishedModifier } =
      modifierChanges

    if (
      !publishedVariant.length &&
      !publishedModifier.length &&
      !unpublishedVariant.length &&
      !unpublishedModifier.length &&
      !sortedVariantGroups.length &&
      !sortedModifierGroups.length
    )
      return

    setVariantsWithChanges(variantChanges)
    setModifiersWithChanges(modifierChanges)
    setShowModal(true)
  }

  return (
    <Drawer
      visible={props.isVisible}
      onClose={props.onClose}
      width={708}
      className='location-inventory-publisher-drawer'
    >
      <InventoryChangesModal
        visible={showModal}
        onCancel={setShowModal}
        onOk={saveChanges}
        loading={isLoading}
        variantsWithChanges={variantsWithChanges}
        modifiersWithChanges={modifiersWithChanges}
        modifierGroupsData={modifierGroupsData?.modifier_groups}
      />
      <Spin spinning={isLoading}>
        <div className='location-inventory-publisher'>
          <div className='location-inventory-publisher-header'>
            <div
              className='location-inventory-publisher-title'
              data-testid='location-inventory-publisher-store-name-header'
            >
              {`${props.isPreorder ? 'Pre-Order' : 'Same Day'} Inventory #${
                props.storeInfo.name.length > 30
                  ? props.storeInfo.name.slice(0, 29) + '...'
                  : props.storeInfo.name
              }`}
            </div>
            <Actions
              discardHandler={handleOnDiscard}
              saveHandler={handleChangesChecks}
              loading={isLoading}
            />
          </div>
          <Tabs>
            <TabPane tab='Products' key='1'>
              <ProductVariants
                isPreorder={props.isPreorder}
                storeId={props.storeInfo.id}
                sortedGroups={sortedVariantGroups}
                setMasterVariants={setVariants}
                setMasterPristineVariants={setPristineVariants}
                setSortedGroups={setSortedVariantGroups}
              />
            </TabPane>
            <TabPane tab='Modifiers' key='2'>
              <Modifiers
                isPreorder={props.isPreorder}
                storeId={props.storeInfo.id}
                sortedGroups={sortedModifierGroups}
                setMasterModifiers={setModifiers}
                setMasterPristineModifiers={setPristineModifiers}
                setMasterModifierGroups={setModifierGroupsData}
                setSortedGroups={setSortedModifierGroups}
              />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </Drawer>
  )
}

export default InventoryPublishDrawer
