import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

import moment from 'moment'
import { Avatar, Col, Divider, Row } from 'antd'
import Moment from 'react-moment'
import {
  Delivery,
  RecipientDetails,
  AdditionalCourierAddress
} from 'components/Orders/Info/type'
import { formatFullname, getIcon } from 'components/Orders/Info/utils'
import env from '../../../../../env'
import Address from './Address'

interface CourierInfoProps {
  delivery: Delivery
  fulfillment?: string | null
  isAsap?: boolean
  customer?: RecipientDetails | null
  dropoff?: AdditionalCourierAddress | null
  store?: { id: string; name: string } | null
}

interface DetailProps {
  type: string
  content: string | ReactNode
  avatarSpan?: number
}

const CourierInfo = ({
  delivery,
  fulfillment,
  isAsap,
  customer,
  store,
  dropoff
}: CourierInfoProps) => {
  const {
    service,
    order_reference,
    proxy,
    driver = {},
    dropoff_time,
    pickup_time
  } = delivery || {}

  const Detail = ({ type, content, avatarSpan }: DetailProps) => (
    <Row align='middle' className='_mb-0'>
      <Col span={avatarSpan || 4}>
        <Avatar size={32} style={{ color: '#4a4a68' }} icon={getIcon(type)} />
      </Col>
      <Col span={20} className='_cl-text-detail _capitalize'>
        {content}
      </Col>
    </Row>
  )

  return (
    <>
      <Row className='_mb-0'>
        <Col span={24}>
          <Row className='order-sub-header'>
            <Col span={24}>COURIER DETAILS</Col>
          </Row>

          {customer && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  Name:
                </Col>
                <Col span={12} className='_cl-text-secondary-75'>
                  Phone:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='person'
                        content={`${customer?.first_name} ${customer?.last_name}`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail type='phone' content={customer?.contact_num} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          {dropoff && store && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  Pickup from:
                </Col>
                <Col span={12} className='_cl-text-secondary-75'>
                  Dropoff to:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail type='store' content={store.name} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='destination'
                        content={<Address address={dropoff} />}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          {fulfillment && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  Fulfillment date and time:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='datestamp'
                        content={
                          <Moment
                            format='ddd MMM DD YYYY'
                            tz='Europe/London'
                            utc
                          >
                            {fulfillment}
                          </Moment>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='timestamp'
                        content={
                          isAsap
                            ? 'ASAP'
                            : `${moment(fulfillment).format(
                                'hh:mm A'
                              )} - ${moment(fulfillment)
                                .add(30, 'minutes')
                                .format('hh:mm A')}`
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          <Row className='_mb-2'>
            <Col span={12} className='_cl-text-secondary-75'>
              Courier assigned:
            </Col>
            <Col span={12} className='_cl-text-secondary-75'>
              Vehicle type assigned:
            </Col>
          </Row>
          <Row className='_mb-2' align='middle'>
            <Col span={12}>
              <Row align='middle' className='_mb-0'>
                <Col span={24}>
                  {!!proxy && <Detail type='pin' content={proxy} />}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='middle' className='_mb-0'>
                <Col span={24}>
                  <Detail
                    type='vehicle'
                    content={driver?.transport_type || ''}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider className='_mt-12 _mb-12' />
          {driver && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  Driver details:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='person'
                        content={formatFullname(
                          driver?.first_name,
                          driver?.last_name
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail type='phone' content={driver?.phone || ''} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          {pickup_time && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  ETA at pickup:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='datestamp'
                        content={
                          <Moment
                            format='ddd MMM DD YYYY'
                            tz='Europe/London'
                            utc
                          >
                            {pickup_time}
                          </Moment>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='timestamp'
                        content={
                          <Moment format='hh:mm A' tz='Europe/London' utc>
                            {pickup_time}
                          </Moment>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          {dropoff_time && (
            <>
              <Row className='_mb-2'>
                <Col span={12} className='_cl-text-secondary-75'>
                  ETA at dropoff:
                </Col>
              </Row>
              <Row className='_mb-2' align='middle'>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='datestamp'
                        content={
                          <Moment
                            format='ddd MMM DD YYYY'
                            tz='Europe/London'
                            utc
                          >
                            {dropoff_time}
                          </Moment>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row align='middle' className='_mb-0'>
                    <Col span={24}>
                      <Detail
                        type='timestamp'
                        content={
                          <Moment format='hh:mm A' tz='Europe/London' utc>
                            {dropoff_time}
                          </Moment>
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className='_mt-12 _mb-12' />
            </>
          )}

          {!!service &&
            service === 'dispatch' &&
            Boolean(order_reference || false) && (
              <>
                <Row className='_mb-2'>
                  <Col span={12} className='_cl-text-secondary-75'>
                    Tracking link:
                  </Col>
                </Row>
                <div>
                  <Detail
                    avatarSpan={2}
                    type='radar'
                    content={
                      <Link
                        href={`${env.TRACKING_HOST}/${order_reference || ''}`}
                        rel='noreferrer'
                        target='_blank'
                      >{`${env.TRACKING_HOST}/${order_reference || ''}`}</Link>
                    }
                  />
                </div>
              </>
            )}
        </Col>
      </Row>
    </>
  )
}

const Link = styled.a(() => ({
  textTransform: 'lowercase',
  textDecoration: 'underline'
}))

export default CourierInfo
