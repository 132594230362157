import React from 'react'
import Switch from 'react-switch'
import { useTheme } from '../../contexts/Theme'

interface Props {
  checked: boolean
  onChange: (value: boolean) => void
}

const SwitchToggle: React.FC<Props> = (props) => {
  const theme = useTheme() as any

  return (
    <Switch
      checked={props.checked}
      onChange={props.onChange}
      onColor={theme.colors.green}
      offColor={theme.colors.gray200}
      uncheckedIcon={false}
      checkedIcon={false}
      handleDiameter={15}
      width={37}
      height={19}
    />
  )
}

export { SwitchToggle }
