import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

// initialization is on the index.html
// since this package does not include initialization function

export const HubspotPageViewTracking = (pathname: string) => {
  const { setPathPageView, setTrackPageView, setIdentity } = useTrackingCode()
  setPathPageView(pathname)
  setTrackPageView()
}
