import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useIframe } from '../../contexts/Iframe/Iframe'
import { HOST, useSlerp } from 'packages/@slerp/client'
import styled from '@emotion/styled'
import { useApp } from '../../contexts/App'

const Landing = () => {
  const { iframeKey } = useIframe()
  const { canvasMaxHeight } = useApp()
  const { user } = useSlerp()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const url = `https://${user.merchant.slug}.${HOST}`

  useEffect(() => {
    iframeRef.current?.contentWindow?.postMessage(
      'Trigger refetch landing',
      url
    )
  }, [])

  return (
    <StyledIframe
      src={url}
      key={iframeKey}
      id='landingIframe'
      ref={iframeRef}
      height={canvasMaxHeight}
    />
  )
}

const StyledIframe = styled.iframe<{
  src: string
  key: string
  ref: MutableRefObject<HTMLIFrameElement | null>
}>`
  width: 100%;
  border-radius: 8px;
  border: none;
`

export { Landing }
