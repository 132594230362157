import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useStateRef } from '../../hooks/useStateRef'
import { Screen, Device, Mode, Cursor, Page } from '../../root/types'
import { PanZoom } from 'panzoom'

export type SidebarType = 'style' | 'configure'
interface AppContextType {
  screen: Screen
  device: Device | ''
  mode: Mode
  zoom: number
  cursor: Cursor
  cursorRef: React.MutableRefObject<Cursor>
  setScreen: (screen: Screen) => void
  setMode: (mode: Mode) => void
  setZoom: (zoom: number) => void
  setCursor: (cursor: Cursor) => void
  handleZoomChange: (action: 'increase' | 'decrease') => void
  canvasMaxWidth: number
  canvasMaxHeight: number
  handleCanvasMaxSize: (width: number, height: number) => void
  canvasRef: RefObject<HTMLDivElement> | null
  panzoomRef: RefObject<PanZoom>
  toggleActiveToolbar: (type: SidebarType) => void
  activeToolbar: 'style' | 'configure'
  handleActiveDevice: (device: Device) => void
  page: Page
  setPage: (page: Page) => void
}

const ZOOM_LEVELS = [0.5, 0.75, 1, 1.25, 1.5, 2]
const AppContext = React.createContext<AppContextType>({} as AppContextType)

export const AppProvider: React.FC = ({ children }) => {
  const canvasRef = useRef<HTMLDivElement>(null)
  const panzoomRef = useRef<PanZoom>(null)

  const [screen, setScreen] = useState<Screen>('landing')
  const [device, setDevice] = useState<Device>('fts')
  const [page, setPage] = useState<Page>('web')
  const [zoom, setZoom] = useState<number>(ZOOM_LEVELS[2])

  const [mode, setMode] = useState<Mode>('style')
  const [cursor, setCursor, cursorRef] = useStateRef<Cursor>('normal')
  const [canvasMaxWidth, setCanvasMaxWidth] = useState<number>(0)
  const [canvasMaxHeight, setCanvasMaxHeight] = useState<number>(0)
  const [activeToolbar, setActiveToolbar] = useState<SidebarType>('style')
  const [deviceWidths, setDeviceWidths] = useState<Record<Device, number>>({
    xs: 360,
    md: 768,
    lg: 1024,
    fts: canvasRef?.current?.clientWidth ?? 1024,
    fallback: canvasRef?.current?.clientWidth ?? 1024
  })

  const centerPreview = (zoomFactor: number) => {
    if (!canvasRef.current) return

    panzoomRef.current?.moveTo(
      canvasRef?.current?.clientWidth / 2 -
        (deviceWidths[device] / 2) * zoomFactor,
      canvasRef?.current?.clientHeight / 2 -
        (canvasRef?.current?.clientHeight / 2) * zoomFactor
    )
  }

  const handleZoomChange = (action: 'increase' | 'decrease') => {
    setZoom((currentZoom) => {
      const closestValue = ZOOM_LEVELS.reduce((previous, current) => {
        return Math.abs(current - +currentZoom.toFixed(2)) <
          Math.abs(previous - +currentZoom.toFixed(2))
          ? current
          : previous
      }, 0)
      const currentIndex = ZOOM_LEVELS.findIndex((zl) => zl === closestValue)

      switch (action) {
        case 'increase':
          const increasedIndex = currentIndex + 1
          const zoomLevelsMax = ZOOM_LEVELS.length - 1

          if (increasedIndex <= zoomLevelsMax) {
            const zoom_level = ZOOM_LEVELS[increasedIndex]
            centerPreview(zoom_level)

            return zoom_level
          } else {
            centerPreview(currentZoom)
            return currentZoom
          }

        case 'decrease':
          const decreasedIndex = currentIndex - 1

          if (decreasedIndex >= 0) {
            const zoom_level = ZOOM_LEVELS[decreasedIndex]
            centerPreview(zoom_level)

            return zoom_level
          } else {
            centerPreview(currentZoom)
            return currentZoom
          }

        default:
          return currentZoom
      }
    })
  }

  const handleCanvasMaxSize = (width: number, height: number) => {
    setCanvasMaxWidth(width)
    setCanvasMaxHeight(height)
  }

  const handleDevicePreviewChange = (device: Device) => {
    if (!canvasRef.current) return
    if (!panzoomRef.current) return

    if (device === 'fts' || device === 'fallback') {
      panzoomRef.current?.moveTo(0, 0)

      handleCanvasMaxSize(
        canvasRef?.current?.clientWidth ?? 0,
        canvasRef?.current?.clientHeight ?? 0
      )

      setZoom(1)
      setDevice('fallback')
    } else {
      centerPreview(zoom)

      handleCanvasMaxSize(
        deviceWidths[device],
        canvasRef?.current?.clientHeight
      )
    }
  }

  const toggleActiveToolbar = (type: SidebarType) => {
    setActiveToolbar(type)
  }

  const handleActiveDevice = (device: Device) => {
    setDevice(device)
  }

  useEffect(() => {
    if (!canvasRef.current) return
    if (!panzoomRef.current) return

    switch (device) {
      case 'fts':
        handleDevicePreviewChange('fts')
        break

      case 'xs':
        handleDevicePreviewChange('xs')
        break

      case 'md':
        handleDevicePreviewChange('md')
        break

      case 'lg':
        handleDevicePreviewChange('lg')
        break

      default:
        return handleDevicePreviewChange('fts')
    }
  }, [canvasRef.current, device, panzoomRef.current])

  useEffect(() => {
    if (!canvasRef.current) return
    if (!panzoomRef.current) return

    const handleResize = () => {
      centerPreview(zoom)

      if (device !== 'fts' && device !== 'fallback') return

      handleDevicePreviewChange('fts')
      setDeviceWidths((prev) => ({
        ...prev,
        fts: canvasRef?.current?.clientWidth!
      }))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [device, zoom])

  return (
    <AppContext.Provider
      value={{
        screen,
        device,
        mode,
        zoom,
        page,
        cursor,
        cursorRef,
        setScreen,
        setMode,
        setZoom,
        setCursor,
        setPage,
        handleZoomChange,
        canvasMaxWidth,
        canvasMaxHeight,
        handleCanvasMaxSize,
        canvasRef,
        panzoomRef,
        activeToolbar,
        toggleActiveToolbar,
        handleActiveDevice
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useApp = () => {
  return useContext(AppContext)
}
