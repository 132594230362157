import React from 'react'
import { Form, Input } from 'antd'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

const CourierFee = ({ disabled = false }: { disabled?: boolean }) => {
  return (
    <Form.Item name='custom_fixed_fee' style={{ marginBottom: 0 }}>
      <Input
        type='number'
        min={0}
        step={0.01}
        placeholder='5.00'
        addonBefore={'£'}
        style={{ maxWidth: '240px' }}
        disabled={disabled}
        required
        onFocus={onFocusScrollNumber}
        onBlur={onBlurScrollNumber}
      />
    </Form.Item>
  )
}

export default CourierFee
