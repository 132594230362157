import React, { useEffect } from 'react'
import { Tabs, Tooltip } from 'antd'
import Account from './Account'
import { LocationSettingProps } from '../Settings'
import { useQuery } from '@apollo/client'
import { QUERY_LOCATION_STRIPE_ACCOUNT_SESSION } from '../LocationQueries'
import { useSession } from '@slerp/accounts'

const { TabPane } = Tabs

const AccountsAndBilling = ({ store }: LocationSettingProps) => {
  const { merchant } = useSession()

  const { data } = useQuery(QUERY_LOCATION_STRIPE_ACCOUNT_SESSION, {
    variables: { storeId: store.id }
  })

  useEffect(() => {
    const { clientSecret, publicKey } =
      data?.getMerchantStripeAccountSession || {}

    if (clientSecret && publicKey) {
      window.StripeConnect = window.StripeConnect || {}
      ;(async () => {
        window.StripeConnect.onLoad = () => {
          window.StripeConnect.init({
            publishableKey: publicKey,
            clientSecret: clientSecret,
            appearance: {
              colors: {
                primary: '#625afa',
                textLink: '#625afa'
              }
            },
            uiConfig: {
              overlay: 'dialog'
            }
          })
        }
      })()
    }
  }, [data])

  return (
    <Tabs defaultActiveKey='account' className='_mb-8'>
      <TabPane
        key='account'
        tab={
          <Tooltip title='Account'>
            <a>Account</a>
          </Tooltip>
        }
      >
        <Account store={store} />
      </TabPane>

      {merchant?.stripe_account_type === 'slerp_pay' && (
        <>
          <TabPane
            key='payment'
            tab={
              <Tooltip title='Payment'>
                <a>Payment</a>
              </Tooltip>
            }
          >
            <div className='container'>
              <stripe-connect-payments />
            </div>
          </TabPane>
          <TabPane
            key='payout'
            tab={
              <Tooltip title='Payout'>
                <a>Payout</a>
              </Tooltip>
            }
          >
            <div className='container'>
              <stripe-connect-payouts />
            </div>
          </TabPane>
        </>
      )}
    </Tabs>
  )
}

export default AccountsAndBilling
