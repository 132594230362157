import { useQuery } from '@apollo/client'
import { GET_USER_STORES } from './queries'
import { UseGetUserStoresProps, StoreUser } from './types'

const useGetUserStores = ({ userId }: UseGetUserStoresProps) => {
  const { data, loading, refetch, error } = useQuery(GET_USER_STORES, {
    variables: {
      userId
    },
    fetchPolicy: 'no-cache'
  })

  return [normalize(data?.store_users), { loading, refetch, error }]
}

const normalize = (storeUsers: StoreUser[]) => {
  if (storeUsers == null) return []
  return storeUsers.map((storeUser: StoreUser) => {
    const {
      store_id,
      store: { name }
    } = storeUser
    return {
      id: store_id,
      name
    }
  })
}

export default useGetUserStores
