import React, { useState, useRef, useEffect } from 'react'
import { Button, Carousel, Checkbox, message, Modal } from 'antd'
import {
  GET_MERCHANT_SETTINGS,
  UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS
} from './MarketingQueries'
import { useSession } from '@slerp/accounts'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'

import Carousel1 from '@slerp/assets/crm-1.jpg'
import Carousel2 from '@slerp/assets/crm-2.jpg'
import Carousel3 from '@slerp/assets/crm-3.jpg'
import Carousel4 from '@slerp/assets/crm-4.jpg'

interface ItemAgenda {
  title: string
  description: string
}

interface CarouselItemType {
  caption: string
  subcaption: string
  imageSource: string
  callToActionSource: string
  callToActionLabel: string
  agenda?: ItemAgenda[]
}

// make this configurable in the future
const CAROUSEL_ITEMS = [
  {
    caption: 'Drive more repeat business with Slerp CRM',
    subcaption:
      'Nearly 2/3 of customers who’ve ordered 3 times will do so again within 6 months.',
    imageSource: Carousel1,
    callToActionSource: 'https://www.slerp.com/crm-book-a-demo/',
    callToActionLabel: 'Book a demo'
  },
  {
    caption: 'Turn your customers into regulars',
    subcaption:
      'Start using your data to personalise your messaging and keep customers coming back.',
    imageSource: Carousel2,
    callToActionSource: 'https://www.slerp.com/crm-book-a-demo/',
    callToActionLabel: 'Book a demo'
  },
  {
    caption: 'All your customer data in one place',
    subcaption:
      'Effortlessly sync all your online ordering, table booking and wifi data.',
    imageSource: Carousel3,
    callToActionSource: 'https://www.slerp.com/crm-book-a-demo/',
    callToActionLabel: 'Book a demo'
  },
  {
    caption: 'Automated SMS & Email',
    subcaption:
      'We’ll help you set up effective automations that will generate more repeat orders with no day-to-day effort from you or your team.',
    imageSource: Carousel4,
    callToActionSource: 'https://www.slerp.com/crm-book-a-demo/',
    callToActionLabel: 'Book a demo'
  }
]

const MarketingInitiativeModal = () => {
  const client = useApolloClient()
  const { merchant, user } = useSession()
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(true)
  const [marketingModalViewers, setMarketingModalViewers] = useState<
    Array<string>
  >([])

  const slider = useRef(null)
  const skipModalCheckbox = useRef(null)

  const isLastSlide = currentSlide === CAROUSEL_ITEMS.length - 1
  const closeModalLabel = isLastSlide ? 'FINISH' : 'SKIP'

  const { data: merchantSettingData, loading: fetchingMerchantSettingsData } =
    useQuery(GET_MERCHANT_SETTINGS, {
      variables: { merchant: merchant.id }
    })

  const [updateMarketingModalViewersSettings] = useMutation(
    UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (merchantSettingData && merchantSettingData.merchants_by_pk) {
      const { marketing_modal_viewers } = merchantSettingData.merchants_by_pk
      setMarketingModalViewers(marketing_modal_viewers || [])
    }
  }, [merchantSettingData])

  const handleSkipModal = () => {
    const skipModalForGood = skipModalCheckbox.current.input.checked

    if (!skipModalForGood) {
      setShowModal(false)
      return
    }

    if (skipModalForGood) {
      const userIds = [...marketingModalViewers, user.id]
      updateMarketingModalViewersSettings({
        variables: {
          merchantId: merchant.id,
          setting: {
            marketing_modal_viewers: userIds
          }
        }
      })
        .then(() => {
          client.resetStore()
        })
        .finally(() => setShowModal(false))
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Unable to toggle hide marketing initiative modal due to ${error.message}`,
            3
          )
        })
    }
  }

  const ModalFooter = [
    <Checkbox
      ref={skipModalCheckbox}
      key='checkbox'
      disabled={fetchingMerchantSettingsData}
      data-testid='marketing-checkbox'
    >
      Don't show again
    </Checkbox>,
    <span key='buttons'>
      <Button
        key='skip'
        type='ghost'
        className='-secondary'
        onClick={handleSkipModal}
        data-testid='marketing-skip-btn'
      >
        {closeModalLabel}
      </Button>
      {!isLastSlide && (
        <Button
          key='next'
          onClick={() => slider.current.next()}
          data-testid='marketing-next-btn'
        >
          NEXT
        </Button>
      )}
    </span>
  ]

  const handleBeforeChangeSlide = (fromSlide: number, toSlide: number) =>
    setCurrentSlide(toSlide)

  return (
    <Modal
      visible={showModal}
      closable={false}
      footer={ModalFooter}
      className='marketing-initiative-modal'
      width={665}
    >
      <Carousel
        ref={slider}
        effect='fade'
        beforeChange={handleBeforeChangeSlide}
      >
        {CAROUSEL_ITEMS.map((item: CarouselItemType, index: number) => {
          return (
            <div key={index} className='carousel-item _mb-0'>
              <div className='carousel-caption'>{item.caption}</div>
              <img
                className='carousel-screen small-carousel-image'
                src={item.imageSource}
                alt='Carousel item'
                style={{
                  marginTop: '24px',
                  ...(index === 2 && { marginBottom: '24px' })
                }}
              />
              <div
                className='carousel-subcaption'
                style={{
                  margin: '8px 0 16px 0'
                }}
              >
                {item.subcaption}
              </div>

              {item.callToActionSource && (
                <Button
                  className='carousel-cta-button'
                  shape='round'
                  size='large'
                  type='primary'
                  href={item.callToActionSource}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    transform: 'scale(1)',
                    marginBottom: '40px'
                  }}
                >
                  {item.callToActionLabel}
                </Button>
              )}
            </div>
          )
        })}
      </Carousel>
    </Modal>
  )
}

export default MarketingInitiativeModal
