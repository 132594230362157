import React from 'react'
import { Col, Row } from 'antd'

import CategoriesList from './CategoriesList'

const Categories = () => {
  return (
    <Row>
      <Col span={24}>
        <CategoriesList />
      </Col>
    </Row>
  )
}

export default Categories
