import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Input } from 'antd'

type Props = {
  placeholder?: string
  value: string
  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const Textarea: React.FC<Props> = ({ value, onChange, ...props }) => {
  const [placeholderValue, setPlaceholderValue] = useState<string>(value)

  useEffect(() => {
    if (placeholderValue === value) return
    setPlaceholderValue(value)
  }, [value]) // eslint-disable-line

  const handleBlur = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return

    onChange(evt)
  }

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return

    evt.persist()
    setPlaceholderValue(evt.target.value)
  }

  return (
    <TextareaInput
      {...props}
      value={placeholderValue}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

const TextareaInput = styled(Input.TextArea)`
  padding: 8px 16px;
  width: 100%;
  font-weight: 400;
  font-family: ${({ theme }: any) => theme.font.sans};
  color: ${({ theme }: any) => theme.colors.gray600};
  background-color: ${({ theme }: any) => theme.colors.gray100};
  border: none;
  border-radius: 4px;
  outline: none;
  resize: none;
  height: 46px;

  ::placeholder {
    color: ${({ theme }: any) => theme.colors.gray400};
  }
`

export { Textarea }
