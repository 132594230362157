import React, { useState } from 'react'
import { Button, Input, Radio, Space, Row, Col, message } from 'antd'
import { useSession } from '@slerp/accounts'
import { ASSET_HOST } from '@slerp/client'

const CommonReports = () => {
  const { merchant, user } = useSession()
  const [reportType, setReportType] = useState<string>('next_day_orders')
  const commonReportExportUrl = `https://${merchant.slug}.${ASSET_HOST}/controls/common_reports/export?token=${user.api_key}&report_type=${reportType}&merchant_id=${merchant.slug}`

  const copyLinkToClipboard = () => {
    const copyText = document.querySelector(
      '#commonReportExportUrlInput'
    ) as HTMLInputElement
    copyText && copyText.select()
    document.execCommand('copy')
    message.success('Link Copied!', 3)
  }

  return (
    <>
      <Input
        id='commonReportExportUrlInput'
        value={commonReportExportUrl}
        className='reportInput'
      />
      <Radio.Group
        data-testid='common-reports-radio-options'
        defaultValue='next_day_orders'
        onChange={(e) => setReportType(e.target.value)}
      >
        <Space direction='vertical' align='start'>
          <Radio
            data-testid='common-reports-next-day-order-option'
            value='next_day_orders'
          >
            Orders with fulfillment date tomorrow
          </Radio>
          <Radio
            data-testid='common-reports-product-catalog-option'
            value='product_catalog'
          >
            Product catalog
          </Radio>
          <Radio
            data-testid='common-reports-opted-in-customers-option'
            value='opted_in_customers'
          >
            Opt’ed in Customers and Guests
          </Radio>
        </Space>
      </Radio.Group>
      <Row justify='end' gutter={[16, 16]} align='middle'>
        <Col>
          <Button
            data-testid='common-reports-copy-link-button'
            onClick={copyLinkToClipboard}
            type='default'
          >
            Copy Link
          </Button>
        </Col>
        <Col>
          <Button
            data-testid='common-reports-download-report-button'
            href={commonReportExportUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            Download Report
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default CommonReports
