import React, { useEffect, useState } from 'react'
import { Modal, Form, InputNumber, Button, Select, message } from 'antd'
import { ATTRIBUTIONS_MAP, chunkArray, getUnconnectedLocations } from './utils'
import { MUTATION_SET_FRANCHISE_ATTRIBUTIONS } from '../SettingsMutations'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { AttributionFormObject, AttributionKeys, Location } from './types'
import { Attribution } from '@slerp/controls'

interface Props {
  preset: AttributionFormObject | null
  visible: boolean
  locations: Location[]
  onClose: () => void
  onSuccess: (attributions: Attribution[]) => void
}

const { useForm } = Form

const attributionEntries = chunkArray<[string, string]>(
  Object.entries(ATTRIBUTIONS_MAP)
)

const initialValues = Object.keys(ATTRIBUTIONS_MAP).reduce(
  (acc, cur) => ({ ...acc, [cur]: 0 }),
  {}
)

export const EditAttribution = ({
  visible,
  onClose,
  onSuccess,
  locations,
  preset
}: Props) => {
  const [form] = useForm()
  const [submodalVisible, setSubmodalVisible] = useState(false)
  const [unconnectedConcent, setUnconnectedConcent] = useState(false)
  const [unconnected, setUnconnected] = useState<Location[]>([])

  useEffect(() => {
    form.setFieldsValue(preset)
    setUnconnectedConcent(false)
  }, [preset, form])

  const handleClose = () => {
    onClose()
    setUnconnectedConcent(false)
    form.resetFields()
  }

  const closeSubModal = () => {
    setSubmodalVisible(false)
  }

  const handleSuccess = (updates: Attribution[]) => {
    onSuccess(updates)
    setUnconnectedConcent(false)
  }

  const allowUnconnected = () => {
    setUnconnectedConcent(true)
    setSubmodalVisible(false)
    form.submit()
  }

  const [updateAttributions] = useMutation(
    MUTATION_SET_FRANCHISE_ATTRIBUTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (value) => {
        handleClose()

        if (value?.setFranchiseAttributions) {
          handleSuccess(value?.setFranchiseAttributions)
        }

        message.destroy()
        message.success('Attributions updated!')
      },
      onError: () => {
        message.destroy()
        message.error('Something went wrong!')
      }
    }
  )

  const handleForm = (
    values: { storeIds: string[] } & {
      [key in AttributionKeys]: string | number
    }
  ) => {
    const unconnected = getUnconnectedLocations(locations, values.storeIds)
    setUnconnected(unconnected)

    if (unconnected.length && !unconnectedConcent) {
      setSubmodalVisible(true)
      return
    }

    const variables = Object.entries(values).reduce(
      (acc, [key, value]) =>
        typeof value === 'number'
          ? { ...acc, [key]: value / 100 }
          : { ...acc, [key]: value },
      {}
    )

    updateAttributions({ variables })
  }

  const options = locations
    .map(({ name, id }) => ({ value: id, label: name }))
    .sort((optionA, optionB) => (optionA.label > optionB.label ? 1 : -1))
  const title = preset ? 'Edit Attribution' : 'Create Attribution'

  return (
    <>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={handleClose}
        title={title}
        footer={[
          <Button key='back' type='ghost' onClick={handleClose}>
            Back
          </Button>,
          <Button
            data-testid='saveAttribution'
            form='editAttribution'
            key='submit'
            type='primary'
            htmlType='submit'
          >
            {Boolean(preset) ? 'Save' : 'Create'}
          </Button>
        ]}
      >
        <Form
          data-testid='editAttribution'
          id='editAttribution'
          form={form}
          onFinish={handleForm}
          layout='vertical'
          initialValues={initialValues}
        >
          <FormItem
            label='Locations'
            name='storeIds'
            rules={[{ required: true, message: "can't be blank" }]}
            className='_mb-0 -plain'
          >
            <Select
              mode='multiple'
              allowClear
              placeholder='Select Store'
              data-testid='selectStore'
              options={options}
              disabled={Boolean(preset)}
              optionFilterProp='children'
              filterOption={(input, option) => {
                const label = (option?.label as string) || ''
                return label.toLowerCase().includes(input.toLocaleLowerCase())
              }}
              filterSort={(optionA, optionB) =>
                ((optionA?.label as string) || '')
                  .toLowerCase()
                  .localeCompare(
                    ((optionB?.label as string) || '').toLowerCase()
                  )
              }
            />
          </FormItem>
          {attributionEntries.map((cols, index) => (
            <Row key={index}>
              {cols.map(([key, label], index) => (
                <FormItem
                  key={`${key}-${index}`}
                  label={label}
                  name={key}
                  rules={[{ required: true, message: "can't be blank" }]}
                  className='_mb-0 -plain'
                >
                  <InputNumber
                    data-testid={key}
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                  />
                </FormItem>
              ))}
            </Row>
          ))}
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        visible={visible && submodalVisible}
        title='WARNING'
        width={600}
        centered
        onCancel={closeSubModal}
        footer={[
          <Button type='ghost' key='cancel-submodal' onClick={closeSubModal}>
            Cancel
          </Button>,
          <Button key='okay-submodal' onClick={allowUnconnected} type='primary'>
            OK
          </Button>
        ]}
      >
        {unconnected.length > 1 && (
          <>
            <p>
              The following locations do not have their own Stripe connected
              accounts:
            </p>
            <ul>
              {unconnected.map((location) => (
                <li key={location.id}>{location.name}</li>
              ))}
            </ul>
          </>
        )}
        {unconnected.length === 1 && (
          <p>
            The location {unconnected[0].name} does not have its own Stripe
            connected account.
          </p>
        )}
      </Modal>
    </>
  )
}

const Row = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '12px',
  width: '100%'
}))

const FormItem = styled(Form.Item)(() => ({
  minHeight: '90px',
  height: 'min-content'
}))
