import React, { useState, useEffect } from 'react'
import { Form, Button, message, PageHeader, Divider } from 'antd'
import SplitShifts from './SplitShifts'
import FulfillmentTypeToggle from './General/FulfillmentTypeToggle'
import TimeSlots, { pickup_timeslots } from './General/TimeSlots'
import FulfillmentTimesType from './General/FulfillmentTimesType'
import { UPDATE_PICKUP_SETTINGS, CREATE_SETTINGS } from './actions'
import { UPDATE_PREORDER_PICKUP_SPLIT_HOURS } from './actions'
import { useApolloClient, useMutation } from '@apollo/client'
import { Schedules, Store } from '@slerp/controls'
import { initialSchedules } from './utils'
import { SanitizeShifts, WithConflictingDailyShifts } from './SplitShifts/utils'
import isEmpty from 'lodash/isEmpty'
import { uuid } from 'uuidv4'
import OrderLimit from './General/OrderLimit'
import { Store as FormStore } from 'rc-field-form/lib/interface'
import SetupNoticeModal from './Cutoffs/SetupNoticeModal'
import ErrorMessage from 'components/Utils/ErrorMessage'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { SplitHour } from './SplitShifts/types'
import MinimumOrderValueInput from '../SameDaySettings/MinimumOrderValueInput'

const PickupForm = ({
  store,
  invalidCutoffs,
  refetch
}: {
  store: Store
  invalidCutoffs: boolean
  refetch: () => {}
}) => {
  const { user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const [showModal, setShowModal] = useState(false)
  const settings =
    store.pre_order_settings?.length > 0 ? store.pre_order_settings[0] : null
  const [pickupEnabled, setPickupEnabled] = useState(
    settings?.pickup_enabled || false
  )
  const [pickupTimes, setPickupTimes] = useState<
    'opening_hours' | 'custom_hours'
  >(settings?.pickup_times || 'opening_hours')
  const [timeslot, setTimeslot] = useState<number | string>(
    settings?.pickup_interval || 15
  )
  const [schedules, setSchedules] = useState<Schedules>(
    settings && !isEmpty(settings.pickup_schedule)
      ? settings.pickup_schedule
      : initialSchedules
  )
  const [splitHours, setSplitHours] = useState<SplitHour[]>(
    SanitizeShifts(
      (settings?.pickup_split_hours || []).filter(
        (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
      )
    )
  )
  const [form] = Form.useForm()
  const [updateStore, { loading, error }] = useMutation(
    settings ? UPDATE_PICKUP_SETTINGS : CREATE_SETTINGS,
    { fetchPolicy: 'no-cache', awaitRefetchQueries: true }
  )
  const [updatePreorderPickupSplitHours] = useMutation(
    UPDATE_PREORDER_PICKUP_SPLIT_HOURS,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const usingCustomHours = pickupTimes === 'custom_hours'
  const client = useApolloClient()

  const onFinish = (values: FormStore) => {
    form.validateFields().then(() => {
      message.loading('Updating... Please wait.')
      updateStore({
        variables: {
          ...(settings ? {} : { id: uuid() }),
          store_id: store.id,
          pickup_enabled: pickupEnabled,
          pickup_interval: timeslot,
          pickup_times: pickupTimes,
          pickup_schedule: usingCustomHours
            ? schedules
            : settings?.pickup_schedule,
          pickup_timeslots_order_limit:
            values.pickup_timeslots_order_limit || 0,
          pickup_preorder_mov:
            values.preorder_pickup_minimum_order_value === undefined
              ? store.preorder_pickup_minimum_order_value || 0
              : values.preorder_pickup_minimum_order_value,
          settings: {
            ...store.settings,
            pre_order_pickup: pickupEnabled
          }
        }
      })
        .then((result) => {
          if (usingCustomHours) {
            updatePreorderPickupSplitHours({
              variables: {
                id: store.id,
                splitHours
              }
            }).finally(() => refetch())
          }
        })
        .finally(() => {
          refetch()
          message.destroy()
          message.success('Updated pre-order pickup settings', 2)
        })
        .catch((error) =>
          message.error(
            `Unable to update your pre-order pickup settings due to: ${error}`,
            5
          )
        )
    })
  }

  // show warning modal if there is no cut-off settings data and user enables delivery
  if (!settings && pickupEnabled) {
    setShowModal(true)
    setPickupEnabled(false)
  }

  useEffect(() => {
    if (pickupTimes === 'opening_hours') {
      setSplitHours(
        SanitizeShifts(
          (settings?.pickup_split_hours || []).filter(
            (shift: SplitHour) => shift.enabled && isEmpty(shift.special_date)
          )
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupTimes])

  const isSplitShiftsInvalid = () => {
    return WithConflictingDailyShifts(splitHours)
  }

  return (
    <Form
      data-testid='preorder-pickup-settings-form'
      layout='vertical'
      colon={false}
      onFinish={onFinish}
      initialValues={{
        pickup_timeslots_order_limit: settings?.pickup_timeslots_order_limit
      }}
      form={form}
    >
      {error && (
        <ErrorMessage
          title='Pickup settings could not be updated'
          subheading={`Error: ${error}`}
        />
      )}
      <PageHeader title='Pickup' className='settings-title' />

      {showModal && (
        <SetupNoticeModal
          title='Please setup your cutoff times before enabling pickup'
          visible={showModal}
          onClose={() => {
            setShowModal(false)
            client.resetStore()
          }}
        />
      )}

      {/* Enable pickup */}
      <FulfillmentTypeToggle
        value={pickupEnabled}
        type='pickup'
        onClick={
          (invalidCutoffs || !settings) && !pickupEnabled
            ? () => setShowModal(true)
            : null
        }
        handleChange={setPickupEnabled}
        disabled={!canManage}
      />
      {pickupEnabled && (
        <>
          <Form.Item label='Pickup times:' className='_mb-40'>
            <FulfillmentTimesType
              value={pickupTimes}
              handleChange={(value) => setPickupTimes(value)}
              disabled={!canManage}
            />
          </Form.Item>
          {usingCustomHours && (
            <SplitShifts
              settingsId={settings?.id}
              splitHours={splitHours}
              setSplitHours={setSplitHours}
              disabled={false}
            />
          )}
          <TimeSlots
            formName='pickup_interval'
            value={timeslot}
            handleChange={(value) => value && setTimeslot(value)}
            timeslots={pickup_timeslots}
            disabled={!canManage}
          />
          <OrderLimit
            inputName='pickup_timeslots_order_limit'
            formRef={form}
            disabled={!canManage}
          />
          <MinimumOrderValueInput
            inputName='preorder_pickup_minimum_order_value'
            defaultValue={
              Number(store.preorder_pickup_minimum_order_value) || 0
            }
            disabled={!canManage}
            testId='preorder-pickup-minimum-order-value'
          />
        </>
      )}
      <Divider />
      <Form.Item className='_mt-32'>
        <Button
          loading={loading}
          title='Save'
          htmlType='submit'
          className='_center-vertical _ml-auto'
          disabled={!canManage || isSplitShiftsInvalid()}
        >
          {loading ? 'Saving' : 'Save'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PickupForm
