import { RadioChangeEvent } from 'antd/lib/radio'
import React from 'react'
import { Form, Radio } from 'antd'

const TimeSlots = ({
  value,
  handleChange,
  timeslots,
  formName,
  disabled = false
}: {
  formName: string
  value: number | string
  handleChange: (value: number | string) => void
  timeslots: { label: string; value: number | string }[]
  disabled?: boolean
}) => {
  return (
    <Form.Item label='Set time slots:' name={formName} className='_mb-40'>
      <Radio.Group
        defaultValue={value}
        onChange={(e: RadioChangeEvent) => handleChange(e.target.value)}
        options={timeslots}
        style={{ display: 'grid', gridRowGap: '8px' }}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export const pickup_timeslots = [
  {
    label: '5 minutes',
    value: 5
  },
  {
    label: '15 minutes',
    value: 15
  },
  {
    label: '30 minutes',
    value: 30
  },
  {
    label: '1 hour',
    value: 60
  }
]

export const delivery_timeslots = [
  {
    label: '30 minutes',
    value: 30
  },
  {
    label: '1 hour',
    value: 60
  },
  {
    label: 'Fixed time range',
    value: 'fixed'
  }
]

export const own_drivers_delivery_timeslots = [
  {
    label: '5 minutes',
    value: 5
  },
  {
    label: '15 minutes',
    value: 15
  },
  ...delivery_timeslots
]

export default TimeSlots
