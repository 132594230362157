import { gql } from '@apollo/client'

const GET_DISCOUNTS = gql`
  query getDiscount($merchantId: uuid!) {
    discounts(where: { merchant: { id: { _eq: $merchantId } } }) {
      id
      code
      description
      type
      quantity
      store_ids
      target
      trigger
      quantity_per_customer
      published_at
      start_date
      end_date
      value
    }
  }
`

const UNPUBLISH_DISCOUNTS = gql`
  mutation UnpublishDiscounts($merchantId: uuid!, $discountIds: [uuid!]) {
    update_discounts(
      where: { merchant_id: { _eq: $merchantId }, id: { _in: $discountIds } }
      _set: { published_at: null }
    ) {
      affected_rows
      returning {
        id
        code
        description
        type
        quantity
        store_ids
        target
        trigger
        quantity_per_customer
        published_at
        start_date
        end_date
        value
      }
    }
  }
`

export { GET_DISCOUNTS, UNPUBLISH_DISCOUNTS }
