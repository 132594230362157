import React from 'react'
import styled from '@emotion/styled'

const FormGroupSeparator: React.FC = () => <Separator>&nbsp;</Separator>

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }: any) => theme.colors.gray100};
`

export { FormGroupSeparator }
