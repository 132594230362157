import React, { ReactNode, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Avatar, Button, Col, Divider, message, Row, Typography } from 'antd'
import { isEmpty } from 'lodash'
import Moment from 'react-moment'
import moment from 'moment-timezone'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import AdditionalNotesForm from './Forms/AdditionalNotes'
import { UPDATE_ORDER_ADDITIONAL_NOTES } from 'components/Orders/OrderQueries'
import { OrderInfo, AddressCache } from 'components/Orders/Info/type'
import { orderAbilities, getIcon } from 'components/Orders/Info/utils'

interface OrderDetailsProps {
  order: OrderInfo
  reloadOrder: () => void
}

interface DetailProps {
  type: string
  content: string | ReactNode
}

interface AddressProps {
  address: AddressCache
}

const { Text } = Typography

const humanizePlatform = (str: string) => {
  return str === 'ios' ? 'iOS' : str?.charAt(0).toUpperCase() + str?.slice(1)
}

const OrderDetails = ({ order, reloadOrder }: OrderDetailsProps) => {
  const { merchant } = useSession()
  const {
    custom_field_name,
    custom_field_value,
    store,
    additional_notes,
    order_notes,
    transaction_id,
    note_updated_at,
    gift_wrap_message,
    inserted_at,
    create_device_category,
    create_platform
  } = order
  const [updateOrderAdditionalNotes] = useMutation(
    UPDATE_ORDER_ADDITIONAL_NOTES,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const removeOrderAdditionalNotesHandler = (e: React.MouseEvent) => {
    message.loading('Removing notes... Please wait.', 1)
    updateOrderAdditionalNotes({
      variables: {
        transactionId: transaction_id,
        slug: merchant.slug,
        additionalNotes: ''
      }
    })
      .then((response) => {
        message.success(`Order ${transaction_id} additional notes removed!`, 1)
        reloadOrder()
      })
      .catch((error) => {
        message.error(`Unable to delete additional notes due to: ${error}`, 5)
      })
  }

  const {
    name: store_name,
    address,
    address_cache: store_address_cache
  } = store
  const { canManageAdditionalNotes } = orderAbilities(order)
  const [showAdditionalNotesForm, setShowAdditionalNotesForm] =
    useState<boolean>(false)
  const orderInsertedAt = moment(inserted_at).format()
  const addressUpdatedAt = moment(address?.updated_at).format()

  const storeAddress = moment(orderInsertedAt).isAfter(addressUpdatedAt)
    ? address
    : JSON.parse(store_address_cache)

  const Address = ({ address }: AddressProps) => {
    const { address: line_1, line_2, city, zip } = address || {}
    return (
      <>
        <Row className='_mb-0'>
          <Col>
            {line_1 && `${line_1},`} {line_2 && `${line_2},`} {city}
          </Col>
        </Row>
        <Row className='_mb-0'>
          <Col>{zip}</Col>
        </Row>
      </>
    )
  }

  const Detail = ({ type, content }: DetailProps) => (
    <Row align='middle' className='_mb-0'>
      <Col span={4}>
        <Avatar size={32} style={{ color: '#4a4a68' }} icon={getIcon(type)} />
      </Col>
      <Col span={20} className='_cl-text'>
        {content}
      </Col>
    </Row>
  )

  const AdditionalNotesDetails = ({ notes }: { notes: string }) => {
    return (
      <>
        <Row
          align='middle'
          className='_mb-0'
          data-testid='order-details-additional-notes'
        >
          <Col span={2}>
            <Avatar
              size={32}
              style={{ color: '#4a4a68' }}
              icon={getIcon('note')}
            />
          </Col>
          <Col span={18}>
            <Row className='_mb-0'>
              <Col>{notes}</Col>
            </Row>
            <Row className='_mb-0'>
              <Col>
                <Text type='secondary' italic>
                  Note written at{' '}
                  {moment(note_updated_at).format('ddd MMM DD YYYY, h:mm:ss a')}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Row gutter={[8]} justify='end'>
              <Col>
                <Button
                  icon={
                    <EditOutlined
                      className='_pb-2'
                      onClick={setShowAdditionalNotesForm}
                    />
                  }
                />
              </Col>
              <Col>
                <Button
                  icon={<DeleteOutlined className='_pb-2' />}
                  onClick={removeOrderAdditionalNotesHandler}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  const displayAdditionalNotesDetails =
    canManageAdditionalNotes && !showAdditionalNotesForm && additional_notes
  const displayAddAdditionalNotesButton =
    canManageAdditionalNotes &&
    !showAdditionalNotesForm &&
    (isEmpty(additional_notes) || additional_notes.length === 0)

  return (
    <>
      <Row className='_mb-2'>
        <Col span={24} className='_cl-text-secondary-75'>
          Store:
        </Col>
      </Row>
      <Row align='middle'>
        <Col span={12}>
          <Detail type='store' content={store_name} />
        </Col>
        <Col span={12}>
          <Detail
            type='location'
            content={<Address address={storeAddress} />}
          />
        </Col>
      </Row>

      <Divider className='_mt-12 _mb-12' />
      <Row className='_mb-2'>
        <Col span={24} className='_cl-text-secondary-75'>
          Placed at:
        </Col>
      </Row>
      <Row align='middle'>
        <Col span={12}>
          <Detail
            type='datestamp'
            content={
              <Moment format='ddd MMM DD YYYY' tz='Europe/London' utc>
                {inserted_at}
              </Moment>
            }
          />
        </Col>
        <Col span={12}>
          <Detail
            type='timestamp'
            content={
              <Moment format='hh:mm:s A' tz='Europe/London' utc>
                {inserted_at}
              </Moment>
            }
          />
        </Col>
      </Row>

      <Divider className='_mt-12 _mb-12' />
      <Row className='_mb-2'>
        <Col span={24} className='_cl-text-secondary-72'>
          Platform:
        </Col>
      </Row>
      <Row align='middle'>
        <Col span={12} data-testid='order-details-platform'>
          <Detail
            type='note'
            content={
              <span className='order-platform'>
                {humanizePlatform(create_platform)} -{' '}
                {humanizePlatform(create_device_category)}
              </span>
            }
          />
        </Col>
      </Row>

      {gift_wrap_message && (
        <>
          <Divider className='_mt-12 _mb-12' />
          <Row className='_mb-2'>
            <Col span={24} className='_cl-text-secondary-72'>
              Gifting:
            </Col>
          </Row>
          <Row align='middle'>
            <Col span={12} data-testid='order-details-gift-wrap-message'>
              <Detail type='note' content={gift_wrap_message} />
            </Col>
          </Row>
        </>
      )}

      {custom_field_name && (
        <>
          <Divider className='_mt-12 _mb-12' />
          <Row className='_mb-2 _cl-text-secondary-75'>
            <Col span={24}>{custom_field_name}:</Col>
          </Row>
          <Row align='middle'>
            <Col span={12}>
              <Detail type='custom_field' content={custom_field_value} />
            </Col>
          </Row>
        </>
      )}

      {order_notes && (
        <>
          <Divider className='_mt-12 _mb-12' />
          <Row className='_mb-2'>
            <Col span={24} className='_cl-text-secondary-72'>
              Order notes:
            </Col>
          </Row>
          <Row align='middle'>
            <Col span={12} data-testid='order-details-order-notes'>
              <Detail type='note' content={order_notes} />
            </Col>
          </Row>
        </>
      )}

      {displayAdditionalNotesDetails && (
        <>
          <Divider className='_mt-12 _mb-12' />
          <Row className='_mb-2 _cl-text-secondary-75'>
            <Col span={24}>Additional notes:</Col>
          </Row>
          <AdditionalNotesDetails notes={additional_notes} />
        </>
      )}
      {showAdditionalNotesForm && (
        <>
          <Divider className='_mt-12 _mb-32' />
          <AdditionalNotesForm
            transaction_id={transaction_id}
            notes={additional_notes}
            cancelHandler={() => setShowAdditionalNotesForm(false)}
            successCallback={() => {
              setShowAdditionalNotesForm(false)
              reloadOrder()
            }}
          />
        </>
      )}
      {displayAddAdditionalNotesButton && (
        <>
          <Divider className='_mt-16 _mb-16' />
          <Row className='_mb-2'>
            <Col span={24}>
              <Button
                icon={<PlusOutlined className='_pb-2' />}
                onClick={setShowAdditionalNotesForm}
              >
                ADD ADDITIONAL NOTES
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default OrderDetails
