import React from 'react'
import { Form, Switch, Row, Radio, Tooltip, PageHeader } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { AppSettings } from 'types'

const { useForm } = Form

interface Props {
  appSettings: AppSettings
  onChange: (values: AppSettings) => void
}

const Loyalty = (props: Props) => {
  const [form] = useForm()
  const { appSettings, onChange } = props

  return (
    <Form
      className='_mt-48 _pl-16 _mb-48'
      form={form}
      data-testid='app-loyalty-form'
      onValuesChange={onChange}
      layout='vertical'
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      scrollToFirstError={true}
      initialValues={appSettings}
    >
      <PageHeader title='Loyalty' className='settings-title' />
      <Row align='middle' className='_mb-0 _pt-4 _pb-4 _pl-4'>
        <Form.Item name='use_image_stamp' valuePropName='checked'>
          <Switch data-testid='use-image-stamp' />
        </Form.Item>
        <label className='_ml-8 _mt-2 _mb-16'>Use logo for stamps</label>
      </Row>

      <Form.Item
        name='vertical_stamp_card_layout'
        className='_mt-10'
        label={
          <>
            Card layout:
            <Tooltip
              title={
                <>
                  Vertical is traditional with stamps on the card.
                  <br />
                  <br />
                  Horizontal has stamps in a section below the card.
                </>
              }
              placement='right'
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
      >
        <Radio.Group className='radio-group' data-testid='card-layout'>
          <Radio value={true}>Vertical</Radio>
          <Radio value={false}>Horizontal</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}

export default Loyalty
