import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Row
} from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useSession } from '@slerp/accounts'
import { requiredRule, refundableValueRule } from './RefundFormRules'
import { GET_ORDER_REFUNDS, REFUND_ORDER_DETAILS } from './OrderQueries'
import Loading from '../Utils/Loading'
import errorReducer from 'errors/errorReducer'
import { Delivery } from 'components/Orders/Info/type'

interface RefundFormProps {
  successCallback?: () => void
  discardCallback?: () => void
  transactionId: string
  deliveries?: Delivery[]
}

interface Refund {
  amount: number
}

interface OrderRefunds {
  total: number
  refunds: Refund[]
}

const { useForm } = Form
const { TextArea } = Input

const RefundForm = ({
  successCallback,
  discardCallback,
  transactionId,
  deliveries
}: RefundFormProps) => {
  const { merchant, user } = useSession()
  const [form] = useForm()
  const client = useApolloClient()
  const [orderTotal, setOrderTotal] = useState<number>(0)
  const [totalRefunds, setTotalRefunds] = useState<number>(0)
  const [remainingChars, setRemainingChars] = useState<number>(255)
  const { data: refundData, loading: fetchingRefundData } =
    useQuery<OrderRefunds>(GET_ORDER_REFUNDS, {
      variables: {
        merchant_id: merchant.id,
        transaction_id: transactionId
      }
    })
  const [refundOrderDetails] = useMutation(REFUND_ORDER_DETAILS, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (refundData) {
      const { orders } = refundData
      const { total = 0, refunds = [] } = orders[0]
      setOrderTotal(total)
      if ((refunds || []).length) {
        const { amount } = refunds.reduce((accum, current) => ({
          amount: accum.amount + current.amount
        }))
        setTotalRefunds(amount / 100)
      } else {
        setTotalRefunds(0)
      }
    }
  }, [refundData])

  useEffect(() => {
    const value = parseFloat(orderTotal - totalRefunds).toFixed(2)
    form.setFieldsValue({ amount: value, refundable_amount: value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTotal, totalRefunds])

  const onFinish = (values: Store) => {
    const { amount, reason } = values
    const variables = {
      amount: orderTotal > 0 ? amount.toString() : '0',
      reason,
      transactionId,
      userId: user.id
    }
    if (form.validateFields()) {
      message.destroy()
      message.loading('Refunding order...', 1)
      refundOrderDetails({
        variables
      })
        .then((response) => {
          message.destroy()
          message.success(getMessage(amount), 8)
          client.resetStore()
          successCallback && successCallback()
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Order',
            data: {
              error: error,
              message: error.message
            }
          })
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(error.message, 5)
        })
    }
  }

  const getDeliveryMessage = (delivery: Delivery, isMultiple = false) => {
    const ongoingStatuses = [
      'picking',
      'delivering',
      'almost_picking',
      'almost_delivering',
      'delivered'
    ]
    const pendingStatuses = ['pending', 'searching', 'scheduled']

    if (
      delivery?.delivery_status &&
      ongoingStatuses.includes(delivery.delivery_status)
    ) {
      if (isMultiple) {
        return (
          <i>
            <b>
              - {delivery.job_id?.slice(-3)} cannot be cancelled as the status
              was {delivery.delivery_status}
            </b>
          </i>
        )
      } else {
        return (
          <>
            <b>Order refunded!</b>
            <br />
            <i>
              Warning!: <b>The delivery cannot be cancelled</b> because the
              delivery status was {delivery.delivery_status}
            </i>
          </>
        )
      }
    }

    if (
      delivery?.delivery_status &&
      pendingStatuses.includes(delivery.delivery_status)
    ) {
      if (isMultiple) {
        return <i>- {delivery.job_id?.slice(-3)} is successfully cancelled.</i>
      } else {
        return (
          <>
            <b>Order refunded!</b>
            <br />
            <i>Warning!: The delivery is also successfully cancelled.</i>
          </>
        )
      }
    }

    if (isMultiple) {
      if (!delivery?.delivery_status) {
        return <i>- {delivery.job_id?.slice(-3)} was not assigned yet</i>
      }

      return (
        <i>
          - {delivery.job_id?.slice(-3)} is already {delivery.delivery_status}
        </i>
      )
    } else {
      return <b>Order refunded!</b>
    }
  }

  const getMessage = (amountRefunded: number) => {
    if (!deliveries || !deliveries.length) {
      return <b>Order refunded!</b>
    }

    if (amountRefunded + totalRefunds < orderTotal) {
      return <b>Order partially refunded!</b>
    }

    if (deliveries.length === 1) {
      return getDeliveryMessage(deliveries[0])
    }

    return (
      <>
        <b>Order refunded!</b>
        <br />
        <i>Warning!: The delivery ending</i>
        <br />
        {deliveries.map((delivery) => (
          <>
            {getDeliveryMessage(delivery, true)} <br />
          </>
        ))}
      </>
    )
  }

  return (
    <>
      {fetchingRefundData && <Loading />}
      {!fetchingRefundData && (
        <Row>
          <Col span={12}>
            <Form
              form={form}
              onFinish={onFinish}
              data-testid='order-refund-form'
            >
              <PageHeader
                title={orderTotal ? 'Refund payment' : 'Refund order'}
              />
              <Form.Item name='refundable_amount' style={{ display: 'none' }}>
                <Input type='hidden' />
              </Form.Item>

              {orderTotal > 0 && (
                <>
                  <Row>
                    <Col span={24}>Refund</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item name='amount' rules={refundableValueRule}>
                        <InputNumber
                          min={0.01}
                          precision={2}
                          className='_w-100'
                          data-testid='order-refund-form-amount'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col span={24}>Reason</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='reason' rules={requiredRule}>
                        <TextArea
                          autoFocus
                          required
                          rows={4}
                          placeholder='Add a reason for the refund. The customer will not see this.'
                          data-testid='order-refund-form-reason'
                          maxLength={255}
                          onChange={(e) =>
                            setRemainingChars(255 - e.target.value.length)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      fontSize: '11px',
                      position: 'absolute',
                      bottom: '-5px',
                      right: 0
                    }}
                  >
                    Remaining characters {remainingChars}
                  </Row>
                </Col>
              </Row>

              {orderTotal > 0 && (
                <>
                  <Row>
                    <Col>
                      Refunds take 5-10 days to appear on a customer's
                      statement. They will be notified of the refund via email.
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      The delivery will <b>not</b> be cancelled upon a full
                      refund when the delivery status is “picking” or
                      "delivering”. Please contact support if you need further
                      assistance.
                    </Col>
                  </Row>
                </>
              )}

              {orderTotal === 0 && (
                <Row>
                  <Col>
                    Please note that this action simply updates the order status
                    from ‘Accepted’ to ‘Refunded’. No payment is reversed.
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={12}>
                  <Row justify='start'>
                    <Button
                      data-testid='order-refund-form-discard'
                      onClick={() => {
                        discardCallback && discardCallback()
                      }}
                    >
                      Discard
                    </Button>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify='end'>
                    <Button
                      type='primary'
                      htmlType='submit'
                      data-testid='order-refund-form-submit'
                    >
                      Refund
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default RefundForm
