import React, { useEffect, useState } from 'react'
import { ThemeProvider as StyledThemeProvider, useTheme } from 'emotion-theming'
import defaultTheme from '../../shop/theme/defaultTheme'
import { Theme as ShopTheme } from '../../shop/theme/types'
import { useApolloClient } from '../Apollo'
import { GET_MERCHANT_THEME, MUTATE_UPDATE_THEME } from './queries'
import { useShop } from '../Shop'
import { theme } from './theme'
import deepmerge from 'deepmerge'
import set from 'lodash/set'
import get from 'lodash/get'
import { useIframe } from '../Iframe/Iframe'
import { message } from 'antd'

type ShopThemeGet = <T = any>(key: string, defaultValue?: T) => T
type ShopThemeOnChange = <T = any>(key: string) => (value: T) => void
type ShopThemeOnChangeWithEvent = (
  key: string
) => (evt: React.ChangeEvent) => void

type ThemeContextType = typeof theme & { shop: ShopTheme } & {
  shopThemeGet: ShopThemeGet
  shopThemeOnChange: ShopThemeOnChange
  iframeKey: string
}

// @TODO This is an alias. Fix in the future, keeping this just so we don't have to search and replace for now
export type ThemeType = ThemeContextType

/**
 * Setup emotion-theming as well as the default theme from @slerp/shop and user styling
 */
export const ThemeProvider: React.FC = ({ children }) => {
  const client = useApolloClient()
  const { regenerateIframeKey } = useIframe()

  const { config } = useShop()

  const [shopTheme, setShopTheme] = useState<ShopTheme | null>(null)

  useEffect(() => {
    client
      .query({
        query: GET_MERCHANT_THEME,
        variables: { slug: config.domain }
      })
      .then(({ data }) => {
        setShopTheme({
          ...defaultTheme,
          ...data.merchants[0].theme
        })
      })
  }, [config.domain]) // eslint-disable-line

  const persistTheme = (patchedShopTheme: Partial<ShopTheme>) => {
    if (!shopTheme) return
    const updatedShopTheme = deepmerge<ShopTheme>(shopTheme, patchedShopTheme)
    // Persist to backend
    updatedShopTheme.themeTitle = `${config.domain} custom theme`
    updatedShopTheme.designMode = false

    // Persist only relevant fields! We don't need to save everything
    // Coded this explicitly for better understanding to the reader
    // what values we're persisting and overriding
    const themePayload = {
      themeTitle: updatedShopTheme.themeTitle,
      fonts: updatedShopTheme.fonts,
      components: updatedShopTheme.components,
      colors: updatedShopTheme.colors
    }

    // Update designer
    setShopTheme(updatedShopTheme)

    // Persits
    client
      .mutate({
        mutation: MUTATE_UPDATE_THEME,
        variables: {
          domain: config.domain,
          theme: themePayload
        }
      })
      .then(() => {
        regenerateIframeKey()
      })
      .catch((error) => {
        message.error(
          `Unable to update your checkout theme due to: ${error}`,
          5
        )
      })
  }

  /**
   * Utility to safely get a value from shopTheme
   */
  const shopThemeGet: ShopThemeGet = (key, defaultValue) => {
    return get(shopTheme, key, defaultValue)
  }

  /**
   * Utility to create an event handler
   */
  const shopThemeOnChange: ShopThemeOnChange = (key) => {
    return (value) => {
      const patchedShopTheme = set({}, key, value)
      persistTheme(patchedShopTheme)
    }
  }

  /**
   * Utility to create an event handler
   */
  const shopThemeOnChangeWithEvent: ShopThemeOnChangeWithEvent = (key) => {
    return (evt) => {
      const value: string | React.ChangeEvent<Element> =
        key === 'colors.textBold'
          ? evt
          : (evt.currentTarget as HTMLInputElement)?.value

      const patchedShopTheme = set({}, key, value)
      persistTheme(patchedShopTheme)
    }
  }

  if (!shopTheme) return null

  return (
    <StyledThemeProvider
      theme={{
        ...theme,
        shop: shopTheme,
        shopThemeGet,
        shopThemeOnChange,
        shopThemeOnChangeWithEvent
      }}
    >
      {children}
    </StyledThemeProvider>
  )
}

export { useTheme }
