import React, { useEffect, useContext } from 'react'
import WebFont from 'webfontloader'
import { useTheme } from '../../contexts/Theme'
import { FrameContext, useFrameAutoSizer } from '../../components/FrameProvider'
import { FALLBACK_FONT } from '../../constants'

/**
 * Loads fonts and triggers height calculation
 */
const CanvasFontLoader: React.FC = ({ children }) => {
  const { window } = useContext(FrameContext)
  const { shop } = useTheme() as any
  const { recalculateHeight } = useFrameAutoSizer()

  useEffect(() => {
    const heading = shop.fonts.heading.family.replace(FALLBACK_FONT, '')

    WebFont.load({
      google: {
        families: [`${heading}:100,300,400,700`]
      },
      context: window,
      fontactive() {
        recalculateHeight()
      }
    })
  }, [shop.fonts.heading.family]) // eslint-disable-line

  useEffect(() => {
    const body = shop.fonts.body.family.replace(FALLBACK_FONT, '')

    WebFont.load({
      google: {
        families: [`${body}:100,300,400,700`]
      },
      context: window,
      fontactive() {
        recalculateHeight()
      }
    })
  }, [shop.fonts.body.family]) // eslint-disable-line

  return <>{children}</>
}

export { CanvasFontLoader }
