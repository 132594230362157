const env = {
  MAPS_API_KEY:
    process.env.MAPS_API_KEY || 'AIzaSyAgsFgz9KguIZUdL0vBQACiLVDSqmK2vTo',
  GRAPH_HOST: process.env.REACT_APP_GRAPH_HOST
    ? process.env.REACT_APP_GRAPH_HOST
    : 'localhost:8080',
  TRACKING_HOST:
    process.env.REACT_APP_ASSET_HOST === 'slerp.com'
      ? 'https://direct.delivery/tracking'
      : `https://dispatch.${
          process.env.REACT_APP_ASSET_HOST || 'slerpdemo.com'
        }/tracking`,
  ASSET_HOST: process.env.REACT_APP_ASSET_HOST
    ? process.env.REACT_APP_ASSET_HOST
    : 'slerp.com',
  PARTNER_API_HOST: process.env.REACT_APP_PARTNER_API_HOST
    ? process.env.REACT_APP_PARTNER_API_HOST
    : 'partner.api.slerpdemo.com',
  REPORTING_HOST: process.env.REACT_APP_REPORTING_HOST
    ? process.env.REACT_APP_REPORTING_HOST
    : 'reporting.slerp.com',
  JWT_ENCRYPTION_KEY: process.env.REACT_APP_JWT_ENCRYPTION_KEY
    ? process.env.REACT_APP_JWT_ENCRYPTION_KEY
    : 'AAAAAAAAAAAAAA',
  JWT_SECRET: process.env.REACT_APP_JWT_SECRET
    ? process.env.REACT_APP_JWT_SECRET
    : 'secret',
  HELP_CENTRE_URL: process.env.HELP_CENTRE_URL
    ? process.env.HELP_CENTRE_URL
    : 'https://support.slerp.com/',
  CONFIG_DOMAIN: process.env.CONFIG_DOMAIN
    ? process.env.CONFIG_DOMAIN
    : 'controls',
  STRIPE_CONNECT_OAUTH_ENDPOINT:
    process.env.NODE_ENV === 'production'
      ? `https://controls.${process.env.REACT_APP_ASSET_HOST}/settings/stripe/connect`
      : 'http://127-0-0-1.nip.io:3002/settings/stripe/connect',
  STRIPE_CONNECT_LOCATION_OAUTH_ENDPOINT: `https://controls.${process.env.REACT_APP_ASSET_HOST}/locations/stripe/connect`,
  USERFLOW_TOKEN:
    process.env.REACT_APP_USERFLOW_TOKEN &&
    process.env.REACT_APP_USERFLOW_TOKEN,
  GA4_ID: process.env.REACT_APP_GA4_ID ? process.env.REACT_APP_GA4_ID : '',
  PIXEL_ID: process.env.REACT_APP_PIXEL_ID
    ? process.env.REACT_APP_PIXEL_ID
    : '',
  LINKEDIN_INSIGHTS_ID: process.env.REACT_APP_LINKEDIN_INSIGHTS_ID
    ? process.env.REACT_APP_LINKEDIN_INSIGHTS_ID
    : '',
  LINKEDIN_CONVERSION_ID: process.env.REACT_APP_LINKEDIN_CONVERSION_ID
    ? process.env.REACT_APP_LINKEDIN_CONVERSION_ID
    : '',
  HUBSPOT_ANALYTICS_ID: process.env.REACT_APP_HUBSPOT_ANALYTICS_ID
    ? process.env.REACT_APP_HUBSPOT_ANALYTICS_ID
    : ''
}

export default env
