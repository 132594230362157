import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import Divider from 'antd/lib/divider'
import { useSession } from '@slerp/accounts'
import { createCategory } from '../../Actions'
import { Store } from 'antd/lib/form/interface'
import { MerchantSettings } from '@slerp/controls'
import { noWhiteSpaceRule } from 'components/Login/rules'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  GET_MERCHANT_SETTINGS,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  CREATE_CATEGORY
} from '../../ProductQueries'
import '../../Products.css'

const { useForm } = Form

interface CategoryFormProps {
  merchantCategories: Category[]
  onCancel?: () => void
  onStartSave?: () => void
  onEndSave?: () => void
}

interface Category {
  id: string
  name: string
  description: string
  slug: string
}

const CategoryForm = (props: CategoryFormProps) => {
  const { onCancel, onStartSave, onEndSave } = props
  const [form] = useForm()
  const { merchant } = useSession()
  const [remainingChars, setRemainingChars] = useState<number>(150)
  const [merchantSettings, setMerchantSettings] = useState<MerchantSettings>()
  const { data: merchantSettingsData } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: { merchantSlug: merchant.slug }
  })
  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )
  const [createMerchantCategory, { error: createCategoryError }] =
    useMutation(CREATE_CATEGORY)

  useEffect(() => {
    if (merchantSettingsData) {
      const { setting } = merchantSettingsData.merchants[0]
      setMerchantSettings(setting)
    }
  }, [merchantSettingsData])

  const initialValues = {
    name: '',
    description: null
  }

  const onFinish = (values: Store) => {
    const { name, description } = values

    if (merchantSettings) {
      form.validateFields().then(() => {
        onStartSave && onStartSave()
        return createCategory(
          merchant.slug,
          name,
          description,
          merchantSettings,
          updateMerchantArrangementSettings,
          createMerchantCategory,
          () =>
            message.error(
              `Unable to create category due to: ${createCategoryError?.message}`,
              5
            )
        )
          .then((result) => {
            onEndSave && onEndSave()
          })
          .catch((error) => {
            message.error(
              `Unable to create category due to: ${error.message}`,
              5
            )
          })
      })
    }
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      data-testid='category-form'
    >
      <Row className='-category-row'>
        <Col>Name</Col>
      </Row>
      <Row className='-category-row'>
        <Col span={24}>
          <Form.Item name='name' rules={noWhiteSpaceRule}>
            <Input
              required
              placeholder='Type name here'
              data-testid='category-form-name'
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className='-category-row'>
        <Col>
          Description
          <Tooltip
            title={
              <span>
                Category descriptions can help customers better understand what
                is on offer. They should be informative and persuasive.
                <br />
                <br />
                They can also help create more unique content on your page,
                which boosts the SEO ranking of your shop page.
              </span>
            }
          >
            <QuestionCircleOutlined className='_ml-8 _mr-8' />
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='description'
            validateTrigger='onBlur'
            className='category-description'
            rules={[
              {
                validator: (_, val) => {
                  if (!val) {
                    return Promise.resolve()
                  }

                  if (val.match(/<\/?[^>]+(>|$)/g)?.length) {
                    return Promise.reject('HTML tags are not allowed')
                  }

                  if (val.length < 20) {
                    return Promise.reject('Minimum character 20')
                  }

                  return Promise.resolve()
                }
              }
            ]}
          >
            <Input.TextArea
              placeholder='Enter a message here..'
              data-testid='category-form-description'
              rows={2}
              maxLength={150}
              onChange={(e) => setRemainingChars(150 - e.target.value.length)}
            />
          </Form.Item>
          <Row
            style={{
              fontSize: '0.5625rem',
              position: 'absolute',
              bottom: '-1.2rem',
              right: 0
            }}
          >
            Remaining characters {remainingChars}
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row justify='space-between' className='-category-row'>
        <Button
          onClick={() => {
            form.resetFields()
            onCancel && onCancel()
          }}
        >
          Discard
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          data-testid='category-form-add-category'
        >
          Save
        </Button>
      </Row>
    </Form>
  )
}

export default CategoryForm
