import React from 'react'
import { Avatar, Col, Divider, Row } from 'antd'
import { CustomerDetails, RecipientDetails } from 'components/Orders/Info/type'
import { getIcon } from 'components/Orders/Info/utils'

interface ClientDetailsProps {
  customer: null | CustomerDetails
  recipient: null | RecipientDetails
}

interface DetailProps {
  label: string
  type: string
  content: string
}

const ClientDetails = ({ customer, recipient }: ClientDetailsProps) => {
  const { first_name, last_name, contact_num, email } = customer || {}
  const {
    first_name: recipient_first_name,
    last_name: recipient_last_name,
    contact_num: recipient_phone
  } = recipient || {}
  const clientFullName = `${first_name} ${last_name}`
  const recipientFullName = `${recipient_first_name} ${recipient_last_name}`

  const Detail = ({ label, type, content }: DetailProps) => {
    return (
      <>
        <Row className='_mb-2'>
          <Col span={24}>
            <Row className='_mb-8 _cl-text-secondary-75'>
              <Col>{label}</Col>
            </Row>
            <Row align='middle' className='_mb-0'>
              <Col span={2}>
                <Avatar
                  size={32}
                  style={{ color: '#4a4a68' }}
                  icon={getIcon(type)}
                />
              </Col>
              <Col className='_cl-text'>{content}</Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row className='order-sub-header'>
        <Col span={24}>CLIENT DETAILS</Col>
      </Row>
      <Detail type='person' label='Name:' content={clientFullName} />
      <Divider className='_mt-12 _mb-12' />
      <Detail type='phone' label='Phone:' content={contact_num} />
      <Divider className='_mt-12 _mb-12' />
      <Detail type='email' label='Email:' content={email} />
      <br />
      {recipient && (
        <>
          <Row className='order-sub-header'>
            <Col span={24}>RECIPIENT DETAILS</Col>
          </Row>
          <Detail type='person' label='Name:' content={recipientFullName} />
          <Divider className='_mt-12 _mb-12' />
          <Detail type='phone' label='Phone:' content={recipient_phone} />
        </>
      )}
    </>
  )
}

export default ClientDetails
