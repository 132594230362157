import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  message,
  Form,
  Row,
  Col,
  Divider,
  Select,
  InputNumber,
  Modal,
  Tabs
} from 'antd'
import { isEmpty } from 'lodash'
import { useForm } from 'antd/lib/form/Form'
import { BULK_UPDATE_CUSTOMER_STAMP, QUERY_STAMP_LOGS } from '../LoyaltyQueries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { InvalidEmail, LoyaltyCardType, User } from '../types'
import { nonZeroRule, requiredRule } from '../Rules'
import { useSlerp } from 'packages/@slerp/client'
import FailedEmailsStampModal from '../FailedEmailsStampModal'
import UploadCSV from './UploadCSV'

interface Props {
  onClose: () => void
  loyaltyCards: LoyaltyCardType[]
}

interface BulkStampResponse {
  data?: {
    bulkStampCustomersLoyaltyCard: string
  }
}

const BulkAddStamps = ({ onClose, loyaltyCards }: Props) => {
  const { user } = useSlerp()
  const { id: user_id, merchant } = user
  const { id: merchant_id } = merchant
  const [form] = useForm()
  const { Option } = Select
  const { TabPane } = Tabs
  const uploadRef = useRef<HTMLInputElement>(null)

  const [stampId, setStampId] = useState<string>('')
  const [showFailedModal, setShowFailedModal] = useState<boolean>(false)
  const [validEmails, setValidEmails] = useState<User[]>([])
  const [fileName, setFileName] = useState<string>('')
  const [hasFile, setHasFile] = useState<boolean>(false)
  const [invalidEmails, setInvalidEmails] = useState<InvalidEmail>({
    message: '',
    list: []
  })
  const [showEmails, setShowEmails] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('1')

  const [
    getStampLogs,
    {
      data: stampLogsData,
      loading: isLoadingStampLogs,
      startPolling,
      stopPolling
    }
  ] = useLazyQuery(QUERY_STAMP_LOGS, {
    onCompleted: (data) => {
      const { id, finished_at } = data?.stamp_logs[0] || {}
      if (!id) return
      if (finished_at) return
      startPollingHandler(id)
      setStampId(id)
    },
    onError: (error) =>
      message.error(`Unable to get stamp logs due to: ${error}`, 5),
    fetchPolicy: 'no-cache'
  })

  const [bulkStamp] = useMutation(BULK_UPDATE_CUSTOMER_STAMP)

  const {
    customer_count,
    finished_at,
    id,
    failed_email_stamps,
    to_stamp_count
  } = stampLogsData?.stamp_logs[0] || {}

  const startPollingHandler = (log_id: string) => {
    localStorage.setItem('stamp_log_id', log_id)
    message.loading({
      key: log_id,
      duration: 0,
      content: `Stamping in progress`
    })
    startPolling && startPolling(2500)
  }

  const stopPollingHandler = (log_id: string) => {
    localStorage.removeItem('stamp_log_id')
    setStampId('')
    message.destroy(log_id)

    if (to_stamp_count != 0) {
      message.error(`There's an error processing ${to_stamp_count} emails.`)
    } else {
      message.success(
        `Successfully added stamps to ${customer_count} ${
          customer_count > 1 ? 'customers' : 'customer'
        }`,
        5
      )
      message.info(`Please refresh the page`, 5)
    }
    if (!isEmpty(failed_email_stamps)) {
      setShowFailedModal(true)
    } else {
      onClose()
    }
    stopPolling && stopPolling()
  }

  const onSubmit = (values: LoyaltyCardType) => {
    if (validEmails.length === 0)
      return message.error(
        'Unable to add stamps due to the uploaded .csv file not having any valid emails',
        5
      )

    form.validateFields().then(() => {
      const { code, stamps_per_scan } = values

      bulkStamp({
        variables: {
          code: code,
          emails: validEmails.map(({ email }: User) => email),
          merchant_id: merchant_id,
          stamps_per_scan: stamps_per_scan,
          user_id: user_id
        }
      })
        .then((result: BulkStampResponse) => {
          const bulk_stamp_response =
            result?.data?.bulkStampCustomersLoyaltyCard
          const stamp_log_id = bulk_stamp_response?.replace(
            'Stamping on process: ',
            ''
          )

          getStampLogs({
            variables: {
              stampId: stamp_log_id,
              merchantId: merchant_id
            }
          })
        })
        .catch((error) =>
          message.error(`Unable to add stamps due to: ${error}`, 5)
        )
    })
  }

  useEffect(() => {
    if (!id) return
    if (finished_at === null) return

    stopPollingHandler(id)
  }, [finished_at, id])

  useEffect(() => {
    if (Boolean(invalidEmails.list.length)) {
      setActiveKey('2')
    } else if (Boolean(validEmails.length)) {
      setActiveKey('1')
    }
  }, [invalidEmails.list.length, validEmails.length])

  const validateCsvFileUpload = () => {
    if (Boolean(validEmails) && Boolean(fileName)) {
      setHasFile(true)
    } else {
      setHasFile(false)
    }
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        colon
        className='_mt-24'
      >
        <UploadCSV
          invalidEmails={invalidEmails}
          setInvalidEmails={setInvalidEmails}
          validEmails={validEmails}
          setValidEmails={setValidEmails}
          hasFile={hasFile}
          setHasFile={setHasFile}
          fileName={fileName}
          setFileName={setFileName}
          setShowEmails={setShowEmails}
          uploadRef={uploadRef}
        />

        <Col span='12' className='_mt-24 _mb-24'>
          <Form.Item
            name='code'
            label={<span className='drawer-label'>Loyalty Card:</span>}
            required
            rules={requiredRule}
            className='_mb-0'
          >
            <Select
              placeholder='Select card'
              data-testid='bulk-rewards-cards'
              onChange={(value) => {
                const selected = loyaltyCards.find(
                  (card) => card.code === value
                )

                form.setFieldsValue({ code: selected?.code })
              }}
            >
              {loyaltyCards
                ?.filter(
                  (card: LoyaltyCardType) => !card.archived_at && card.published
                )
                .map((card: LoyaltyCardType) => (
                  <Option key={card?.id} value={card?.code}>
                    {card?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item
          name='stamps_per_scan'
          initialValue={0}
          required
          label={
            <span className='drawer-label'>
              Number of stamps/points applied to each customer:
            </span>
          }
          rules={[
            ...nonZeroRule,
            {
              validator: (_, val) => {
                if (!val) return Promise.resolve()

                if (!Number.isInteger(val)) {
                  return Promise.reject('Decimal is not allowed')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <InputNumber
            min={0}
            max={99}
            style={{ width: '56px' }}
            data-testid='bulk-rewards-stamps-count'
            onChange={(value) => {
              const stamps_per_scan = Math.round(value)
              form.setFieldsValue({
                stamps_per_scan: stamps_per_scan
              })
            }}
          />
        </Form.Item>

        <Divider />

        <Row gutter={16} className='_mt-32' data-testid='bulk-rewards-cancel'>
          <Col>
            <Button type='ghost' onClick={onClose}>
              Discard
            </Button>
          </Col>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              onClick={() => validateCsvFileUpload()}
              data-testid='bulk-rewards-add-stamps'
              loading={isLoadingStampLogs || Boolean(stampId)}
            >
              Add Stamps
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={showEmails}
        cancelText='close'
        cancelButtonProps={{ type: 'ghost', className: '-secondary' }}
        okText='upload other file'
        okButtonProps={{ type: 'default' }}
        title='Email addresses'
        onCancel={() => setShowEmails(false)}
        onOk={() => uploadRef.current?.click()}
      >
        <Tabs
          className='bulk-rewards-tabs'
          activeKey={activeKey}
          onChange={(key: string) => setActiveKey(key)}
        >
          <TabPane
            key='1'
            tab={`Match (${validEmails.length})`}
            disabled={!validEmails.length}
          >
            {Boolean(validEmails.length) && (
              <Row>
                {validEmails.map(({ email }: User) => (
                  <Col
                    span='11'
                    className='email-list _ml-10 _mb-10'
                    key={email}
                  >
                    {email}
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
          <TabPane
            key='2'
            tab={`No Match (${invalidEmails.list.length})`}
            disabled={!invalidEmails.list.length}
          >
            {Boolean(invalidEmails.list.length) && (
              <Row>
                {invalidEmails.list.map((email: string) => (
                  <Col
                    span='11'
                    className='email-list _ml-10 _mb-10'
                    key={email}
                  >
                    {email}
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
        </Tabs>
      </Modal>
      {showFailedModal && (
        <FailedEmailsStampModal
          emails={failed_email_stamps}
          visible={showFailedModal}
          onClose={() => setShowFailedModal(false)}
        />
      )}
    </>
  )
}

export default BulkAddStamps
