import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'emotion-theming'
import { useTheme } from '../../contexts/Theme'

const CanvasThemeProvider: React.FC = ({ children }) => {
  const theme = useTheme() as any

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

export { CanvasThemeProvider }
