import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Table, Space, Tooltip } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { Attribution as AttributionType } from '@slerp/controls'
import { AttributionCards } from './AttributionCards'
import { AttributionLocation } from './AttributionLocation'
import { AttributionFormObject, Location } from './types'
import { EditAttribution } from './EditAttribution'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS } from '../SettingsQueries'
import { QUERY_LOCATIONS } from 'components/Locations/LocationQueries'
import { Merchant, Store } from 'types'
import { attributionToFormObject } from './utils'
import { DeleteAttribution } from './DeleteAttribution'
import { format, parseISO } from 'date-fns'
import Loading from '../../Utils/Loading'
import { Link } from 'react-router-dom'

interface Props {
  merchant: Merchant
}

type Record = {
  [key: string]: boolean
}

const Attribution = ({ merchant }: Props) => {
  const [attributions, setAttributions] = useState<AttributionType[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [storeToDelete, setStoreToDelete] = useState<{
    id: string
    name: string
  } | null>(null)
  const [presetEdit, setPresetEdit] = useState<AttributionFormObject | null>(
    null
  )
  const [openedRows, setOpenedRows] = useState<Record>({})

  const handleExpand = (storeId: string) => () => {
    const stateUpdate = { ...openedRows, [storeId]: !openedRows[storeId] }
    setOpenedRows(stateUpdate)
  }

  const handleEditAttribution = (attribution: AttributionType) => {
    const preset = attributionToFormObject(attribution)
    setPresetEdit(preset)
    setEditModalVisible(true)
  }

  const handleDeleteAttribution = (attribution: AttributionType) => {
    setStoreToDelete(attribution.store)
    setDeleteModalVisible(true)
  }

  const handleEditSuccess = (attributionUpdate: AttributionType[]) => {
    const update = [...attributions]

    attributionUpdate.forEach((attribution) => {
      const index = attributions.findIndex(
        ({ store: { id } }) => id === attribution.store.id
      )

      if (index === -1) {
        update.push(attribution)
      } else {
        update[index] = attribution
      }
    })

    setAttributions(update)
  }

  const handleDeleteSuccess = (storeId: string) => {
    const update = attributions.filter(({ store: { id } }) => id !== storeId)

    setAttributions(update)
  }

  const handleCloseEdit = () => {
    setEditModalVisible(false)
    setPresetEdit(null)
  }

  const handleCloseDelete = () => {
    setDeleteModalVisible(false)
    setStoreToDelete(null)
  }

  const COLUMNS = [
    {
      title: 'Location',
      dataIndex: ['store', 'name'],
      key: 'location_name',
      render: (location: string, record: AttributionType) => (
        <AttributionLocation
          location={location}
          onClick={handleExpand(record.store.id)}
          isExpanded={openedRows[record.store.id]}
        />
      )
    },
    {
      title: 'Attribution',
      width: '50%',
      key: 'attribution',
      render: (attribution: AttributionType) => (
        <AttributionCards
          attribution={attribution}
          isExpanded={openedRows[attribution.store.id]}
        />
      )
    },
    {
      title: 'Last Update',
      dataIndex: 'lastUpdatedAt',
      key: 'last_update',
      render: (
        lastUpdate: string,
        { user: { lastname, firstname } }: AttributionType
      ) => (
        <>
          {format(parseISO(lastUpdate), 'dd/MM/yy HH:mm')} <br /> by {firstname}{' '}
          {lastname}
        </>
      )
    },
    {
      title: 'Stripe ID',
      dataIndex: 'stripeUserId',
      key: 'stripe_id',
      render: (stripeUserId: string) => stripeUserId || '-'
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_value: any, attribution: AttributionType) => (
        <Space>
          <Button
            data-testid={`edit-${attribution.store.id}`}
            icon={<EditOutlined />}
            onClick={() => handleEditAttribution(attribution)}
          />
          <Button
            data-testid={`delete-${attribution.store.id}`}
            onClick={() => handleDeleteAttribution(attribution)}
            icon={<DeleteOutlined />}
          />
          <Link
            to={`/location/${attribution.store.slug}/settings/accounts-and-billing`}
          >
            <Button
              data-testid={`goto-${attribution.store.id}`}
              icon={<EyeOutlined />}
            />
          </Link>
        </Space>
      )
    }
  ]

  const { data: attributionsQuery, loading: loadingAttributions } = useQuery<{
    franchiseAttribution: AttributionType[]
  }>(QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS, {
    variables: {
      merchantId: merchant?.id
    }
  })

  const { data: locationsQuery, loading: loadingLocations } = useQuery<{
    stores: Store[]
  }>(QUERY_LOCATIONS, {
    variables: {
      merchantSlug: merchant?.slug
    }
  })

  useEffect(() => {
    if (attributionsQuery?.franchiseAttribution && locationsQuery?.stores) {
      const locations = locationsQuery.stores.map((location) => ({
        ...location,
        hasStripeId: attributionsQuery.franchiseAttribution.some(
          ({ store, stripeUserId }) => store.id === location.id && stripeUserId
        )
      }))

      setLocations(locations)
    }
  }, [attributionsQuery, locationsQuery])

  useEffect(() => {
    if (attributionsQuery?.franchiseAttribution) {
      const attributions = attributionsQuery.franchiseAttribution.filter(
        (attribution) => Boolean(attribution.user)
      )

      setAttributions(attributions)
    }
  }, [attributionsQuery])

  if (loadingAttributions || loadingLocations) {
    return <Loading />
  }

  return (
    <div>
      <DeleteAttribution
        visible={deleteModalVisible}
        onClose={handleCloseDelete}
        store={storeToDelete}
        onSuccess={handleDeleteSuccess}
      />
      <EditAttribution
        preset={presetEdit}
        visible={editModalVisible}
        locations={locations}
        onClose={handleCloseEdit}
        onSuccess={handleEditSuccess}
      />
      <PageHeader
        title={
          <>
            Attribution
            <Tooltip
              title={
                <span>
                  Attribution determines the proportion of the partner amount
                  allocated to the location. For example, if the attribution is
                  90%, the location will receive 90% of the partner amount and
                  the partner will receive the balance 10%.
                </span>
              }
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
        extra={
          <Button
            data-testid='createAttribution'
            onClick={() => setEditModalVisible(true)}
          >
            Create New
          </Button>
        }
      />
      <Table
        data-testid='main-settings-attribution-table'
        columns={COLUMNS}
        dataSource={attributions}
        rowKey={(record: AttributionType) => record.store.id}
      />
    </div>
  )
}

export default Attribution
