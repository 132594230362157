import React from 'react'
import { Button, Modal, Result, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const SetupNoticeModal = ({
  visible,
  onClose,
  title
}: {
  visible: boolean
  onClose: () => void
  title?: string
}) => {
  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <Result
        status='warning'
        icon={<ExclamationCircleOutlined />}
        title={
          <Typography className='_mb-12'>
            {title || 'Please setup your cutoff times'}
          </Typography>
        }
        subTitle='Cutoffs determine the days that pre-orders can be placed for, and the times that the pre-orders must be placed by.'
        extra={
          <Button
            type='primary'
            key='console'
            onClick={() => {
              window.location.href = `${window.location.pathname}#cutoffs`
              onClose()
            }}
          >
            Setup cutoff times
          </Button>
        }
      />
    </Modal>
  )
}

export default SetupNoticeModal
