import isEmpty from 'lodash/isEmpty'

const required = { required: true, message: 'This is a required field' }
const requiredRule = [required]

const nonNegativeRule = [
  {
    validator: (_: any, val: number | string) => {
      const num = val && Number(val)
      return num < 0
        ? Promise.reject('Value cannot be negative')
        : Promise.resolve()
    }
  }
]

const nonEmptySelectRule = [
  {
    validator: (_: any, val: string[]) => {
      return isEmpty(val)
        ? Promise.reject('Please select at least one store')
        : Promise.resolve()
    }
  }
]

export { requiredRule, nonNegativeRule, nonEmptySelectRule }
