import React, { useEffect, useState, useRef } from 'react'
import { Card, Checkbox, Col, Collapse, Row } from 'antd'
import {
  InventoryGroup,
  InventoryItem,
  ModifierGroupArrangement
} from '../types'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import { DraggableIcon, RightArrowIcon, DownArrowIcon } from '@slerp/assets'
import isEmpty from 'lodash/isEmpty'
import arrayMove from 'array-move'

interface ModifierCollapsePanelsProps {
  inventoryGroups: InventoryGroup[]
  sortedGroups: InventoryGroup[]
  modifierGroupArrangements?: ModifierGroupArrangement[]
  onChange: (updatedInventoryItems: InventoryItem[]) => void
  setSortedGroups: () => void
}

const sortableContainerStyle = {
  margin: '0'
}

const ModifierCollapsePanels = ({
  inventoryGroups,
  sortedGroups,
  modifierGroupArrangements,
  onChange,
  setSortedGroups
}: ModifierCollapsePanelsProps) => {
  const containerRef = useRef(null)

  const [localArrangements, setLocalArrangements] = useState<
    ModifierGroupArrangement[]
  >(modifierGroupArrangements)
  const [selectedGroupId, setSelectedGroupId] = useState<String>('none')

  const sortModifierGroupArrangements = (
    inventoryGroups,
    modifierGroupArrangements
  ) => {
    const sorted = inventoryGroups
      .map((group) => {
        const modGroupArr = modifierGroupArrangements.find(
          (modGroupArr) => modGroupArr.modifier_group_id === group.id
        ) || {
          id: group.id,
          index: undefined,
          modifier_group_id: group.id,
          modifier_arrangements: []
        }

        const sortedItems = group.items
          .map((item) => {
            const modArr = modGroupArr?.modifier_arrangements?.find(
              (modArr) => modArr.modifier_id === item.id
            ) || {
              id: item.id,
              index: undefined,
              modifier_id: item.id,
              modifier_group_arrangement_id: modGroupArr.id
            }

            return {
              ...item,
              index: modArr?.index,
              modArr: modArr
            }
          })
          .sort((a, b) => {
            if (a.index < b.index) {
              return -1
            }
            if (a.index > b.index) {
              return 1
            }
            return 0
          })

        return {
          ...group,
          index: modGroupArr?.index,
          modGroupArr: modGroupArr,
          items: sortedItems
        }
      })
      .sort((a, b) => {
        if (a.index < b.index) {
          return -1
        }
        if (a.index > b.index) {
          return 1
        }
        return 0
      })

    return sorted
  }

  const handleGroupsSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newSortedGroups = arrayMove(sortedGroups, oldIndex, newIndex)
    const newLocalArrangements = newSortedGroups.map((sG, index) => {
      return {
        ...sG.modGroupArr,
        index: index
      }
    })

    setLocalArrangements(newLocalArrangements)
  }

  const handleItemsSortEnd = ({ oldIndex, newIndex }: SortEnd, group) => {
    const newSortedItems = arrayMove(group.items, oldIndex, newIndex)
    const newModArr = newSortedItems.map((sI, index) => {
      return {
        ...sI.modArr,
        index: index
      }
    })

    const newLocalArrangements = localArrangements.map((lA) => {
      if (group.modGroupArr.id === lA.id) {
        return {
          ...lA,
          modifier_arrangements: newModArr
        }
      } else {
        return lA
      }
    })

    setLocalArrangements(newLocalArrangements)
  }

  useEffect(() => {
    if (modifierGroupArrangements.length === 0 && inventoryGroups.length > 0) {
      const tempArr = inventoryGroups.map((iG, index) => {
        return {
          id: iG.id,
          index: index,
          modifier_group_id: iG.id,
          modifier_arrangements: []
        }
      })
    } else {
      setLocalArrangements(modifierGroupArrangements)
    }
  }, [modifierGroupArrangements])

  useEffect(() => {
    const sorted = sortModifierGroupArrangements(
      inventoryGroups,
      localArrangements
    )

    setSortedGroups(sorted)
  }, [inventoryGroups, localArrangements])

  const SortableItem = SortableElement(({ item }: { item: InventoryItem }) => {
    return (
      <Card
        bordered={false}
        className='_mb-2 _w-100'
        bodyStyle={{
          cursor: 'grab',
          padding: 8
        }}
        data-testid={`draggable-product-item-${item.label}`}
      >
        <Row align='middle' className='_mb-0'>
          <Col span={1} className='draggable-icon-light _mr-8'>
            <Col className='without-allergen' />
          </Col>

          <Col span={22}>
            <Row align='middle' className='_mb-4'>
              <Col className='_cl-text'>
                <label htmlFor={`item-${item.id}`}>{item.label}</label>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <span onClick={(e) => e.stopPropagation()}>
              <Checkbox
                id={`item-${item.id}`}
                style={{ marginLeft: 8 }}
                checked={item.isPublished}
                data-testid={item.label}
                onChange={(e) =>
                  onChange([{ ...item, isPublished: e.target.checked }])
                }
              />
            </span>
          </Col>
        </Row>
      </Card>
    )
  })

  const SortableItemsList = SortableContainer(
    ({ groupId, items }: { groupId: string; items: InventoryItem[] }) => (
      <div
        data-testid={`products-container-${groupId}`}
        style={{ overflowX: 'auto' }}
      >
        {items.map((item: InventoryItem, index: number) => (
          <SortableItem key={item.id} item={item} index={index} />
        ))}
      </div>
    )
  )

  const SortableGroup = SortableElement(
    ({ group }: { group: InventoryGroup }) => {
      return (
        <Card
          data-testid={`draggable-category-item-${group.name}`}
          className={`width-100`}
          bodyStyle={{
            cursor: 'grab',
            padding: 8
          }}
        >
          <Collapse
            defaultActiveKey={selectedGroupId}
            accordion
            expandIconPosition='right'
            expandIcon={() => {
              if (selectedGroupId === group.id) return <DownArrowIcon />
              else return <RightArrowIcon />
            }}
            ghost
            onChange={(key) => setSelectedGroupId(key as string)}
          >
            <Collapse.Panel
              key={group.id}
              header={
                <Row align='middle' className='-category-list'>
                  {selectedGroupId !== group.id && (
                    <Col span={1}>{<DraggableIcon />}</Col>
                  )}
                  <Col span={selectedGroupId !== group.id ? 22 : 23}>
                    <Row
                      align='middle'
                      className={`-category-list ${
                        selectedGroupId === group.id && '_ml-8'
                      }`}
                    >
                      <Col span={18}>{group.name}</Col>
                      <Col span={6}>
                        <Row
                          gutter={[8, 8]}
                          align='middle'
                          justify='end'
                          className='-category-list'
                        >
                          <Col>{group.items.length} Modifiers</Col>
                          <Col>
                            <span onClick={(e) => e.stopPropagation()}>
                              <Checkbox
                                id={`select-all-${group.id}-${group.name}`}
                                style={{ marginLeft: 8 }}
                                checked={_.every(
                                  group.items,
                                  (item) => item.isPublished
                                )}
                                data-testid={group.name}
                                onChange={(e) =>
                                  onChange(
                                    group.items.map((item) => ({
                                      ...item,
                                      isPublished: e.target.checked
                                    }))
                                  )
                                }
                              />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <div className='product-arrangement-wrapper'>
                {!isEmpty(group.items) ? (
                  <>
                    <SortableItemsList
                      axis='y'
                      lockAxis='y'
                      distance={1}
                      groupId={group.id}
                      items={group.items}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        handleItemsSortEnd({ oldIndex, newIndex }, group)
                      }}
                      helperClass='sortable-item'
                    />
                  </>
                ) : (
                  <div>
                    Modifier List is empty. Please try reloading the page.
                  </div>
                )}
              </div>
            </Collapse.Panel>
          </Collapse>
        </Card>
      )
    }
  )

  const SortableGroupsList = SortableContainer(
    ({ groups }: { groups: InventoryGroup[] }) => {
      return (
        <div data-testid='category-row-container'>
          {groups?.map((group: InventoryGroup, index: number) => {
            return (
              <SortableGroup
                key={group.id}
                group={group}
                index={index}
                ref={(el) => {
                  if (selectedGroupId !== group.id) return

                  containerRef?.current.scrollTo(0, el?.node?.offsetTop - 96)
                }}
              />
            )
          })}
        </div>
      )
    }
  )

  return (
    <div
      className='sortable-container'
      style={sortableContainerStyle}
      ref={containerRef}
    >
      <SortableGroupsList
        axis='y'
        lockAxis='y'
        distance={1}
        groups={sortedGroups}
        onSortEnd={handleGroupsSortEnd}
        helperClass='sortable-category-item'
      />
    </div>
  )
}

export default ModifierCollapsePanels
