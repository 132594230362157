export function isDescendant(parent: Element, child: Element): boolean {
  var node = child.parentNode
  while (node != null) {
    // eslint-disable-next-line
    if (node == parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}
