import React from 'react'
import { Drawer, Tabs } from 'antd'
import BulkAddStamps from './BulkAddStamps'
import BulkAddCard from './BulkAddCard'
import { QUERY_LOYALTY_CARDS } from '../LoyaltyQueries'
import { useQuery } from '@apollo/client'
import { useSlerp } from 'packages/@slerp/client'
import { LoyaltyCardType } from '../types'
import Loading from '../../Utils/Loading'

const { TabPane } = Tabs

interface BulkAddDrawerProps {
  onClose: () => void
  visible: boolean
}

const BulkAddDrawer = ({ onClose, visible }: BulkAddDrawerProps) => {
  const { user } = useSlerp()
  const {
    merchant: { id: merchant_id }
  } = user

  const { data, loading: isLoading } = useQuery(QUERY_LOYALTY_CARDS, {
    variables: {
      merchant_id: merchant_id
    },
    fetchPolicy: 'no-cache'
  })

  const loyaltyCards: LoyaltyCardType[] = data?.loyalty_cards || []

  if (isLoading) return <Loading />

  return (
    <Drawer
      title='Add stamps for a list of customers'
      data-testid='bulk-add-stamps-drawer'
      width={600}
      visible={visible}
      onClose={onClose}
    >
      <Tabs>
        <TabPane tab='Add Stamps' key='bulk-add-stamps'>
          <BulkAddStamps onClose={onClose} loyaltyCards={loyaltyCards} />
        </TabPane>
        <TabPane tab='Assign Exclusive Card' key='bulk-add-card'>
          <BulkAddCard
            onClose={onClose}
            loyaltyCards={loyaltyCards.filter((c) => c.exclusive)}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  )
}

export default BulkAddDrawer
