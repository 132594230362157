import React, { Dispatch, SetStateAction } from 'react'
import {
  PlusOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { Tooltip, message, Form, Row, Col, Typography } from 'antd'
import { BulkRewardImportFile } from '../utils'
import { flatMap, uniq } from 'lodash'
import { CSVLink } from 'react-csv'
import { useLazyQuery } from '@apollo/client'
import { CHECK_EMAILS } from '../LoyaltyQueries'
import { useSlerp } from '@slerp/client'

const { Text } = Typography

interface User {
  id: string
  first_name: string
  last_name: string
  email: string
}

interface InvalidEmail {
  message: string
  list: string[]
}

interface UploadCSVProps {
  invalidEmails: InvalidEmail
  setInvalidEmails: Dispatch<SetStateAction<InvalidEmail>>
  validEmails: User[]
  setValidEmails: Dispatch<SetStateAction<User[]>>
  hasFile: boolean
  setHasFile: Dispatch<SetStateAction<boolean>>
  fileName: string
  setFileName: Dispatch<SetStateAction<string>>
  setShowEmails: Dispatch<SetStateAction<boolean>>
  uploadRef: any
  exclusive?: boolean
}

const UploadCSV = (props: UploadCSVProps) => {
  const {
    validEmails,
    setValidEmails,
    invalidEmails,
    setInvalidEmails,
    hasFile,
    setHasFile,
    fileName,
    setFileName,
    setShowEmails,
    uploadRef,
    exclusive
  } = props
  const { user } = useSlerp()

  const CSVTemplate = [
    [`test1@${user.merchant.slug}.com`],
    [`test2@${user.merchant.slug}.com`],
    [`test3@${user.merchant.slug}.com`],
    [`test4@${user.merchant.slug}.com`]
  ]

  const [checkEmails] = useLazyQuery(CHECK_EMAILS, {
    onCompleted: (data) => {
      const { checkEmails } = data
      const { valid: validEmails = [], invalid: invalidEmails = [] } =
        checkEmails
      const invalid = invalidEmails.filter((str: string) => str !== '')
      const hasValid = Boolean(validEmails.length)
      const hasInvalid = Boolean(invalid.length)

      if (hasValid && !hasInvalid) {
        setValidEmails(validEmails)
        setInvalidEmails({
          message: '',
          list: []
        })
        // set invalid modal
      } else if (hasValid && hasInvalid) {
        setValidEmails(validEmails)
        setInvalidEmails({
          message: `Your upload contains ${invalid.length} email addresses that do not match your customer database`,
          list: invalid
        })
        message.warning(
          `Your upload contains ${invalid.length} email addresses that do not match your customer database`,
          5
        )
      } else if (!hasValid && hasInvalid) {
        setValidEmails([])
        setInvalidEmails({
          message: `Your upload contains ${invalid.length} email addresses that do not match your customer database`,
          list: invalid
        })
      } else {
        setValidEmails([])
        setInvalidEmails({
          message: '',
          list: []
        })
      }
    },
    onError: (error) =>
      message.error(`Unable to verify customer emails due to: ${error}`),
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const showEmailsMessage = () => {
    const hasInvalid = Boolean(invalidEmails.list.length)
    const hasValid = Boolean(validEmails.length)
    if (hasInvalid && hasValid) {
      return `Your upload contains ${invalidEmails.list.length} email addresses that do not match your customer database`
    } else if (!hasInvalid && hasValid) {
      return `You have a total of ${validEmails.length} eligible customers who are about to be updated`
    } else {
      return `Your upload contains ${invalidEmails.list.length} email addresses that do not match your customer database`
    }
  }

  return (
    <>
      <input
        ref={uploadRef}
        data-testid='bulk-rewards-csv-upload'
        hidden
        type='file'
        placeholder='Bulk Rewards'
        onChange={async (e) => {
          if (!e.target.files || e.target.files.length === 0) return
          const file = e.target.files[0]
          const type = file.type

          if (type !== 'text/csv')
            return message.error('Please upload a .csv file')

          const emailsCSV = (await BulkRewardImportFile(file)) as string
          const customersList = emailsCSV
            .split('\r\n')
            .map((str) => str.replace(/,\s*$/, '').replace(/"|'/g, '').trim())
            .filter(Boolean) // remove empty values
          const customers = uniq(
            flatMap(customersList.map((email) => email.split(',')))
          ) // used lodash flatMap here instead of what's available for es-2019(discuss upgrade to es-2019 first)

          if (customers.length > 6000 && !!exclusive) {
            return message.error(
              'Please upload a .csv file. A maximum of 6000 customers can be uploaded at once.'
            )
          }

          setFileName(file.name)
          setHasFile(true)

          checkEmails({
            variables: {
              merchantId: user.merchant.id,
              emails: customers
            }
          })
        }}
        accept='.csv'
      />
      <Form.Item
        validateStatus={!hasFile ? 'error' : undefined}
        help={
          !hasFile
            ? !!exclusive
              ? 'Please upload a .csv file. A maximum of 6000 customers can be uploaded at once.'
              : 'Please upload a .csv file'
            : undefined
        }
        name='csv_file'
        className='csv-error _mb-0'
        label={
          <>
            <span className='drawer-label _mr-8'>Upload</span>
            <Tooltip
              title={
                <>
                  Upload a .csv file containing customer emails.
                  <br />
                  <br />
                  Tip:{' '}
                  <i>
                    The amount of rows/columns does not matter, just fill each
                    cell with a valid customer email.
                  </i>
                </>
              }
              placement='right'
              className='_mt-2'
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Col>
          {Boolean(fileName) ? (
            <>
              <div className='csv-container'>
                <div className='csv-title'>
                  <div className='paper-clip'>
                    <PaperClipOutlined />
                  </div>
                  <span>{fileName}</span>
                  <div className='csv-approved'>Approved</div>
                </div>
                <div
                  className='csv-delete'
                  onClick={() => {
                    setValidEmails([])
                    setFileName('')
                    setHasFile(false)
                  }}
                >
                  delete
                </div>
              </div>
              {
                <div className='csv-message'>
                  <div>{showEmailsMessage()}</div>
                  <div onClick={() => setShowEmails(true)}>See details</div>
                </div>
              }
            </>
          ) : (
            <div
              className='bulk-upload'
              onClick={() => uploadRef.current?.click()}
            >
              <PlusOutlined />
              <span>Upload</span>
            </div>
          )}
        </Col>
      </Form.Item>
      <Row className='_mb-22'>
        <Text>
          Click{' '}
          <CSVLink
            filename='CSV Template'
            data={CSVTemplate}
            style={{ color: '#D092DD', textDecoration: 'underline' }}
          >
            here
          </CSVLink>{' '}
          to download the CSV template
        </Text>
      </Row>
    </>
  )
}

export default UploadCSV
