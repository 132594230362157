import React from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { Tooltip, Col } from 'antd'

interface ClickableTitleProps {
  type: 'ascending' | 'descending'
  onClick: () => Promise<ApolloQueryResult<any>> | undefined | void
  title: string
}

const ClickableTitle = ({ type, onClick, title }: ClickableTitleProps) => {
  return (
    <Tooltip title={`Click to sort to ${type || 'descending'}`} placement='top'>
      <Col onClick={onClick} className='_pointer'>
        {title}
      </Col>
    </Tooltip>
  )
}

export default ClickableTitle
