import isInteger from 'lodash/isInteger'
import isNull from 'lodash/isNull'

type CustomRuleType = { getFieldValue: (arg: string) => string }

const required = { required: true, message: 'This is a required field.' }
const requiredRule = [required]

const caloriesFieldRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: number | null) {
      if (isNull(value)) {
        return Promise.resolve()
      }

      const isValid = isInteger(value)

      return isValid
        ? Promise.resolve()
        : Promise.reject('Please input flat numbers for calories')
    }
  })
]
export { requiredRule, caloriesFieldRule }
