import React, { useEffect } from 'react'
import { Col, Divider, message, Row, Tooltip } from 'antd'
import isEmpty from 'lodash/isEmpty'
import Loading from 'components/Utils/Loading'
import useGetMerchantCommissionRate from 'components/CustomHooks/CommissionRates/useGetMerchantCommissionRate'
import { netCommissionRate } from './utils'
import './styles.css'
import useGetZeroOrderCommissionRates from 'components/CustomHooks/CommissionRates/useGetZeroOrderCommissionRate'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface CommissionRatesProps {
  merchantCurrentPlanSlug: string
  zeroOrderRateId: string
}

const VAT_PERCENTAGE = '1.2'

const CommissionRates = ({
  merchantCurrentPlanSlug,
  zeroOrderRateId
}: CommissionRatesProps) => {
  const [
    commission_rates,
    { loading: loadingRate, error: getMerchantCommissionRateError }
  ] = useGetMerchantCommissionRate({
    merchantCurrentPlanSlug
  })
  const [
    zero_commission_rates,
    { loading: loadingZeroRate, error: getZeroOrderCommissionRateError }
  ] = useGetZeroOrderCommissionRates({
    zeroOrderRateId
  })

  useEffect(() => {
    if (!isEmpty(getMerchantCommissionRateError)) {
      message.error(
        `Unable to fetch commission rates due to: ${getMerchantCommissionRateError?.message}`,
        5
      )
    }

    if (!isEmpty(getZeroOrderCommissionRateError)) {
      message.error(
        `Unable to fetch zero order commission rates due to: ${getZeroOrderCommissionRateError?.message}`,
        5
      )
    }
  }, [getMerchantCommissionRateError, getZeroOrderCommissionRateError])

  if (loadingRate || loadingZeroRate) return <Loading />

  const getTableRow = (values: (string | null)[]) => {
    const colSpan = 24 / values.length
    return (
      <Row className='row' align='middle'>
        {values.map((value, index) => {
          const hasValue = value !== null
          return (
            <Col
              key={`${index}-${value}`}
              className='_text-center'
              span={colSpan}
            >
              {hasValue ? netCommissionRate(value, VAT_PERCENTAGE) + '%' : '-'}
            </Col>
          )
        })}
      </Row>
    )
  }

  const zeroOrderTooltipMessage =
    'Commission will apply to purchases where products are discounted to £0 on checkout. This does not apply to rewards gained through loyalty.'

  return (
    <div style={{ maxWidth: '1728px' }}>
      <Row
        style={{ marginTop: '28px' }}
        data-testid='loyaltyStats'
        justify='center'
        align='middle'
      >
        <Col span={3}>
          <div className='pricing-header'>
            <div className='title'>
              <span>Same Day</span>
            </div>
            <div className='subtitle'>
              <span>Orders over £0</span>
            </div>
            <div className='subtitle'>
              <span>£0 Orders</span>
              <Tooltip title={zeroOrderTooltipMessage}>
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </div>
          </div>
          <div className='pricing-header'>
            <div className='title'>
              <span>Pre-Order</span>
            </div>
            <div className='subtitle'>
              <span>Orders over £0</span>
            </div>
            <div className='subtitle'>
              <span>£0 Orders</span>
              <Tooltip title={zeroOrderTooltipMessage}>
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </div>
          </div>
        </Col>
        <Col span={11}>
          <div className='pricing-table'>
            <div className='header'>
              <div className='title'>
                <span>Delivery</span>
              </div>
              <div className='subtitle'>
                <div>
                  <span>Slerp Courier</span>
                </div>
                <div>
                  <span>Own Drivers</span>
                </div>
                <div>
                  <span>Nationwide</span>
                </div>
              </div>
            </div>
            <div className='cell'>
              {getTableRow([
                commission_rates.delivery,
                commission_rates.same_day_self_delivery,
                null
              ])}
              {getTableRow([
                zero_commission_rates.delivery,
                zero_commission_rates.same_day_self_delivery,
                null
              ])}
            </div>
            <div className='cell'>
              {getTableRow([
                commission_rates.preorder_delivery,
                commission_rates.preorder_self_delivery,
                commission_rates.nationwide_delivery
              ])}
              {getTableRow([
                zero_commission_rates.preorder_delivery,
                zero_commission_rates.preorder_self_delivery,
                zero_commission_rates.nationwide_delivery
              ])}
            </div>
          </div>
        </Col>

        <Col span={5}>
          <div className='pricing-table'>
            <div className='header'>
              <div className='title'>
                <span>Click & Collect</span>
              </div>
            </div>
            <div className='cell'>
              {getTableRow([commission_rates.pickup])}
              {getTableRow([zero_commission_rates.pickup])}
            </div>
            <div className='cell'>
              {getTableRow([commission_rates.preorder_pickup])}
              {getTableRow([zero_commission_rates.preorder_pickup])}
            </div>
          </div>
        </Col>

        <Col span={5}>
          <div className='pricing-table'>
            <div className='header'>
              <div className='title'>
                <span>Table Ordering</span>
              </div>
            </div>
            <div className='cell'>
              {getTableRow([commission_rates.table])}
              {getTableRow([zero_commission_rates.table])}
            </div>
            <div className='cell'>
              {getTableRow([null])}
              {getTableRow([null])}
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
      <Row justify='end' className='_text-right'>
        <Col span={12}>* all pricing displayed is exclusive of VAT</Col>
      </Row>
    </div>
  )
}

export default CommissionRates
