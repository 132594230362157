import React, { useState, useEffect } from 'react'
import { intersection, isEmpty } from 'lodash'
import { Card, Col, Row, Tag } from 'antd'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Product } from './types'

type AllergenType = string[] | [] | null | undefined

type ProductListType = {
  id: string
  name: string
  allergens?: AllergenType
  allergen?: AllergenType
}

interface ProductArrangementProps {
  productArrangement?: object
  filteredProducts?: Product[]
  setAllProductArrangementHandler: (
    filteredProducts: Product[],
    currentArrangement: object
  ) => void
  isArrangementUpdating: boolean
  categoryName: string
}

const SortableProduct = SortableElement(
  ({
    productName,
    productAllergen
  }: {
    productName: string
    productAllergen: AllergenType
  }) => {
    return (
      <Card
        bordered={false}
        className='draggable-product-card _w-100'
        bodyStyle={{
          cursor: 'grab',
          padding: 8
        }}
        data-testid={`draggable-product-item-${productName}`}
      >
        <Row align='middle' className='_mb-0'>
          <Col span={1} className='draggable-icon-light _mr-8'>
            <Col
              className={`${
                isEmpty(productAllergen) ? 'without-allergen' : 'with-allergen'
              }`}
            />
          </Col>
          <Col span={23}>
            <Row
              align='middle'
              className={`${(isEmpty(productAllergen) && '_mb-0') || '_mb-4'}`}
            >
              <Col className='_cl-text'>{productName}</Col>
            </Row>
            <Row align='middle' className='mb-0'>
              {productAllergen?.map((allergen) => (
                <Col key={`${productName}-${productAllergen}`}>
                  <Tag className='product-allergen'>{allergen}</Tag>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Card>
    )
  }
)

const formatProductArrangements = (
  filteredProductList?: ProductListType[],
  predefinedArrangement?: object
) => {
  if (!predefinedArrangement || isEmpty(predefinedArrangement)) return []
  const arrangedProducts: ProductListType[] = []
  const unArrangedProducts: ProductListType[] = []

  const productsWithArrangement = intersection(
    filteredProductList?.map((product: ProductListType) => product.id),
    Object.values(predefinedArrangement)
  )
  filteredProductList?.forEach((prod: ProductListType) => {
    !productsWithArrangement.includes(prod.id)
      ? unArrangedProducts.push(prod)
      : arrangedProducts.push(prod)
  })

  const sortedProducts = arrangedProducts?.sort(
    (a: ProductListType, b: ProductListType) => {
      const rankA = Object.values(predefinedArrangement).findIndex(
        (val?: string) => val === a.id
      )
      const rankB = Object.values(predefinedArrangement).findIndex(
        (val?: string) => val === b.id
      )
      return rankA - rankB
    }
  )
  return [...sortedProducts, ...unArrangedProducts]
}

const ProductArrangement = (props: ProductArrangementProps) => {
  const {
    filteredProducts,
    productArrangement,
    setAllProductArrangementHandler,
    isArrangementUpdating,
    categoryName
  } = props
  const filteredProductList = filteredProducts?.map(
    (product: ProductListType) => ({
      id: product.id,
      name: product.name,
      allergen: product.allergens
    })
  )

  const [filteredProductOrder, setFilteredProductOrder] = useState<
    Array<ProductListType>
  >(formatProductArrangements(filteredProductList, productArrangement))
  const [sorting, setSorting] = useState<boolean>(false)

  const handleProductSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (!filteredProductOrder) return
    const newArrangement = arrayMove(filteredProductOrder, oldIndex, newIndex)
    setFilteredProductOrder(newArrangement)

    setSorting(true)
  }

  useEffect(() => {
    if (!filteredProducts) return
    setFilteredProductOrder(
      formatProductArrangements(filteredProductList, productArrangement)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAllProductArrangementHandler])

  useEffect(() => {
    if (!sorting) return
    setAllProductArrangementHandler(
      filteredProductOrder,
      productArrangement as {}
    )
    setSorting(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleProductSortEnd])

  const SortableProductsList = SortableContainer(
    ({ items }: { items: ProductListType[] }) => (
      <div data-testid={`products-container-${categoryName}`}>
        {items.map((item: ProductListType, index: number) => (
          <SortableProduct
            key={item.id}
            productName={item.name}
            productAllergen={item.allergen}
            index={index}
            disabled={isArrangementUpdating}
          />
        ))}
      </div>
    )
  )

  return (
    <div className='product-arrangement-wrapper'>
      {filteredProductOrder && !isEmpty(filteredProductOrder) ? (
        <>
          <div className='container'>
            <SortableProductsList
              axis='y'
              lockAxis='y'
              distance={1}
              items={filteredProductOrder}
              onSortEnd={handleProductSortEnd}
              helperClass='sortable-item'
            />
          </div>
        </>
      ) : (
        <div>Product List is empty. Please try reloading the page.</div>
      )}
    </div>
  )
}

export default ProductArrangement
