import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Divider, Row } from 'antd'
import { useLazyQuery, useQuery } from '@apollo/client'
import flatMap from 'lodash/flatMap'
import cloneDeep from 'lodash/cloneDeep'
import { useSession } from '@slerp/accounts'
import InventoryCollapsePanels from './InventoryCollapsePanels'
import {
  countPublished,
  flattenItems,
  formatStoreVariantsForInventoryDrawer,
  getOrphanedVariants
} from '../helpers'
import {
  GET_ALL_STORE_SAMEDAY_INVENTORY,
  GET_ALL_STORE_PREORDER_INVENTORY,
  GET_MERCHANT_CATEGORIES,
  GET_MERCHANT_ACTIVE_VARIANTS,
  GET_STORE_CATEGORIES_ARRANGEMENTS
} from '../DashboardQueries'

import { GET_PRODUCTS_CATEGORIES } from 'components/ProductsAndModifiers/Products/ProductQueries'
import { ProductCategory } from 'components/ProductsAndModifiers/Products/Form'
import {
  InventoryGroup,
  InventoryItem,
  SortedCategory,
  StoreInventory,
  StoreVariant,
  CategoriesArrangements
} from '../types'

interface ProductVariantsProps {
  isPreorder: boolean
  storeId: string
  setMasterPristineVariants: () => void
  setMasterVariants: () => void
  sortedGroups: SortedCategory[]
  setSortedGroups: () => void
}

const ProductVariants = ({
  isPreorder,
  storeId,
  sortedGroups,
  setMasterPristineVariants,
  setMasterVariants,
  setSortedGroups
}: ProductVariantsProps) => {
  const { merchant } = useSession()
  const [variants, setVariants] = useState<InventoryGroup[]>([])

  const [categoriesArrangements, setCategoriesArrangements] = useState<
    Array<CategoriesArrangements>
  >([])

  const allVariants = flattenItems(variants)

  const updateVariants = (variantsToUpdate: InventoryItem[]) => {
    const updatedVariants = cloneDeep(variants)
    variantsToUpdate.forEach((variant) => {
      updatedVariants.forEach((v, index) => {
        updatedVariants[index].items = updatedVariants[index].items.map(
          (item) => {
            if (item.id !== variant.id) return item
            return { ...item, isPublished: variant.isPublished }
          }
        )
      })
    })

    setVariants(updatedVariants)
    setMasterVariants(updatedVariants)
  }

  const { data: storeInventoryData, loading: storeInventoryLoading } =
    useQuery<StoreInventory>(
      isPreorder
        ? GET_ALL_STORE_PREORDER_INVENTORY
        : GET_ALL_STORE_SAMEDAY_INVENTORY,
      {
        variables: { storeId },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
      }
    )

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    GET_MERCHANT_CATEGORIES,
    {
      variables: { merchantId: merchant.id },
      fetchPolicy: 'no-cache'
    }
  )

  const { data: variantsMasterData } = useQuery<{
    variants: ProductVariant[]
  }>(GET_MERCHANT_ACTIVE_VARIANTS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  useQuery(GET_STORE_CATEGORIES_ARRANGEMENTS, {
    variables: { storeId, orderType: isPreorder ? 'preorder' : 'sameday' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCategoriesArrangements(data.categories_arrangements)
    }
  })

  const [getProducsCategories] = useLazyQuery(GET_PRODUCTS_CATEGORIES, {
    onCompleted: (data: { products_categories: Array<ProductCategory> }) => {
      if (!storeInventoryData) return

      const { products_categories } = data
      const { store_variants } = storeInventoryData

      const missing_variants: Array<StoreVariant> = store_variants.filter(
        (sv: StoreVariant) =>
          products_categories.some(
            (pc: ProductCategory) =>
              pc.product_id === sv.product_variant.product.id &&
              pc.category_id !== sv.product_variant.product.category_id
          )
      )

      const involved_categories: Array<ProductCategory> =
        products_categories.filter((pc: ProductCategory) =>
          store_variants.some(
            (sv: StoreVariant) =>
              pc.product_id === sv.product_variant.product.id &&
              pc.category_id !== sv.product_variant.product.category_id
          )
        )

      const cloned_variants = flatMap(missing_variants, (sv: StoreVariant) => {
        return involved_categories.reduce((acc: Array<StoreVariant>, pc) => {
          if (pc.product_id === sv.product_variant.product.id) {
            const clonedStoreVariant: StoreVariant = cloneDeep(sv)
            clonedStoreVariant.product_variant.product.category.name =
              pc.category.name
            clonedStoreVariant.product_variant.product.category_id =
              pc.category_id

            return [...acc, clonedStoreVariant]
          }

          return acc
        }, [])
      })

      initInventories(cloned_variants)
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const publishedVariantsCount = countPublished(variants)
  const areAllVariantsSelected = publishedVariantsCount === allVariants.length

  const initInventories = (missingVariants: StoreVariant[]) => {
    if (!storeInventoryData) return

    const combinedVariants = [
      ...storeInventoryData.store_variants,
      ...missingVariants
    ]

    const orphanedVariants = getOrphanedVariants(
      storeInventoryData.store_variants,
      variantsMasterData?.product_variants
    )

    const storeVariants = formatStoreVariantsForInventoryDrawer(
      [...combinedVariants, ...orphanedVariants],
      categoriesData.categories
    )
    setVariants(storeVariants)
    setMasterPristineVariants(storeVariants)
    setMasterVariants(storeVariants)
  }

  useEffect(() => {
    if (!storeInventoryLoading && !categoriesLoading) {
      if (!storeInventoryData) return

      const { store_variants } = storeInventoryData
      const productIds = [
        ...new Set(
          store_variants.map((v: StoreVariant) => v.product_variant.product.id)
        )
      ].map((id) => ({
        product_id: { _eq: id }
      }))

      getProducsCategories({
        variables: {
          productIds
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeInventoryData, categoriesData])

  return (
    <>
      <Row justify='space-between' className='_mb-14'>
        <Col>
          <Checkbox
            checked={areAllVariantsSelected}
            onChange={(e) => {
              const allPublished = allVariants.map((variant) => ({
                ...variant,
                isPublished: e.target.checked
              }))
              updateVariants(allPublished)
            }}
          >
            Select All Products
          </Checkbox>
        </Col>
        <Col>{publishedVariantsCount} selected</Col>
      </Row>
      <InventoryCollapsePanels
        inventoryGroups={variants}
        sortedGroups={sortedGroups}
        categoriesArrangements={categoriesArrangements}
        onChange={updateVariants}
        setSortedGroups={setSortedGroups}
      />
      <Divider className='_mb-0' />
    </>
  )
}

export default ProductVariants
