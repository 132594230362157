import styled from '@emotion/styled'
import React from 'react'
import Radio from 'antd/lib/radio'
import { useApp } from '../../contexts/App'

const OPTIONS = [
  { label: 'Web', value: 'web' },
  {
    label: 'Mobile App',
    value: 'mobile',
    disabled: true
  },
  { label: 'Messages', value: 'messages' }
]

const RadioGroup = () => {
  const { setPage } = useApp()

  return (
    <StyledRadioGroup
      options={OPTIONS}
      optionType='button'
      buttonStyle='solid'
      defaultValue='web'
      onChange={(e) => setPage(e.target.value)}
    />
  )
}

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 2px;
  padding: 2px;
  border-radius: 4px;
  background: ${({ theme }: any) => theme.colors.background25};

  label {
    border: none;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-disabled {
    background: ${({ theme }: any) => theme.colors.background25};
    border-radius: 4px;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-disabled:hover {
    max-height: 29px;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper:hover {
    border-radius: 4px;
  }

  .ant-radio-button-wrapper span {
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    color: ${({ theme }: any) => theme.colors.subtitle};
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-checked {
    span {
      color: white;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper:first-child {
    border: none;
  }
`

export default RadioGroup
