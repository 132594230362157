import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  message,
  Row,
  Timeline,
  Typography
} from 'antd'
import {
  ClockCircleOutlined,
  HomeOutlined,
  ShopOutlined
} from '@ant-design/icons'
import {
  GET_STORES,
  CANCEL_COURIER,
  GET_MERCHANT_DELIVERY_SETTINGS
} from './OrderQueries'
import { useSession } from '@slerp/accounts'
import { useQuery, useMutation } from '@apollo/client'
import {
  AdditionalCourierType,
  CustomerDetailsType,
  DeliveryType,
  StoreType
} from './types'
interface CouriersListProps {
  delivery: DeliveryType | null
  additionalCouriers: AdditionalCourierType[]
  store: StoreType
  customer: CustomerDetailsType
  orderFullfillmentTimeRange: string | null
  transactionId: string
  cancellationSuccessCallback: () => void
  orderState: string
}

interface Address {
  flat_number: string | null
  line_1: string
  line_2: string
  city: string
  zip: string
}

interface Stores {
  [id: string]: string
}

interface SingleStore {
  id: string
  slug: string
  name: string
}

interface DeliverySettings {
  courier_partner_enabled: boolean
}

const { Panel } = Collapse
const { Text } = Typography

const buildFullAddress = (address: Address) => {
  const { flat_number, line_1, line_2, city, zip } = address
  // 2nd Floor, Tea Building, Shoreditch High Street, London E1 6JJ
  return [flat_number, line_1, line_2, `${city} ${zip}`]
    .filter((line) => line)
    .join(', ')
}

const defaultAddress = {
  flat_number: '',
  line_1: '',
  line_2: '',
  city: '',
  zip: ''
}

const CouriersList = ({
  customer,
  delivery,
  additionalCouriers,
  store,
  orderFullfillmentTimeRange,
  transactionId,
  cancellationSuccessCallback,
  orderState
}: CouriersListProps) => {
  const { merchant } = useSession()
  const [merchantStores, setMerchantStores] = useState<Stores>({})
  const [merchantDeliverySettings, setMerchantDeliverySettings] =
    useState<DeliverySettings>({
      courier_partner_enabled: false
    })
  const [cancellingCourierJobId, setCancellingCourierJobId] =
    useState<string>('')
  const { data: storesData } = useQuery(GET_STORES, {
    variables: {
      merchantId: merchant.id
    }
  })

  const { data: merchantDeliverySettingsData } = useQuery(
    GET_MERCHANT_DELIVERY_SETTINGS,
    {
      variables: {
        merchantId: merchant.id
      }
    }
  )

  useEffect(() => {
    if (storesData) {
      const { stores } = storesData
      const storesHashMap = Object.assign(
        {},
        ...stores.map((store: SingleStore) => ({ [store.id]: store.name }))
      )
      setMerchantStores(storesHashMap)
    }
  }, [storesData])

  useEffect(() => {
    if (merchantDeliverySettingsData) {
      const { stores } = merchantDeliverySettingsData?.merchants
      const { pre_order_settings } = stores[0]
      const { delivery_courier_partner: courier_partner_enabled } =
        pre_order_settings[0] || { delivery_courier_partner: false }
      setMerchantDeliverySettings({
        courier_partner_enabled
      })
    }
  }, [merchantDeliverySettingsData])

  const [cancelCourier] = useMutation(CANCEL_COURIER)

  const processCancellationOfCourier = async (jobId: string) => {
    const variables = {
      transactionId,
      jobId
    }
    setCancellingCourierJobId(jobId)
    cancelCourier({ variables })
      .then((result) => {
        cancellationSuccessCallback && cancellationSuccessCallback()
        setCancellingCourierJobId('')
        message.destroy()
        message.success('Courier cancelled!', 3)
      })
      .catch((error) => {
        setCancellingCourierJobId('')
        message.destroy()
        message.error(
          `Unable to cancel courier for order ${transactionId} due to ${error.message}`,
          3
        )
      })
  }

  const customerFullAddress = buildFullAddress(
    customer?.address || defaultAddress
  )

  const allowCancellation = () => {
    const { courier_partner_enabled } = merchantDeliverySettings
    return orderFullfillmentTimeRange === 'ASAP' && courier_partner_enabled
  }
  const courierPanel = (courierInfo: AdditionalCourierType) => {
    const { address, job_id, fulfillment_date, store_id, dropoff_notes } =
      courierInfo
    const destinationAddress = buildFullAddress(address)

    return (
      <Panel
        header={`${merchantStores[store_id]} (${fulfillment_date || 'ASAP'})`}
        key={job_id}
        extra={
          allowCancellation() && (
            <Button
              disabled={cancellingCourierJobId.length > 0}
              loading={cancellingCourierJobId === job_id}
              danger
              size='small'
              onClick={() => processCancellationOfCourier(job_id)}
            >
              Cancel Courier
            </Button>
          )
        }
      >
        <Row gutter={[8, 16]}>
          <Col>
            <Descriptions bordered column={1}>
              {job_id && (
                <Descriptions.Item label='Job ID'>{job_id}</Descriptions.Item>
              )}
              <Descriptions.Item label='Fulfilment Date'>
                <ClockCircleOutlined /> {fulfillment_date || 'ASAP'}
              </Descriptions.Item>
              {dropoff_notes && (
                <Descriptions.Item label='Delivery Notes'>
                  {dropoff_notes}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row gutter={[8, 8]}>
              <Text style={{ color: '#f5ab35', fontWeight: 'bold' }}>
                Booking received by courier partner
              </Text>
            </Row>
            <Row>
              <Timeline>
                <Timeline.Item
                  dot={<ShopOutlined style={{ color: '#f5ab35' }} />}
                >
                  {merchantStores[store_id]}
                </Timeline.Item>
                <Timeline.Item
                  dot={<HomeOutlined style={{ color: '#f5ab35' }} />}
                >
                  {destinationAddress}
                </Timeline.Item>
              </Timeline>
            </Row>
          </Col>
        </Row>
      </Panel>
    )
  }

  return (
    <>
      <Descriptions title='Courier Details' layout='horizontal' size='small' />
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
            <Panel
              header={`${store.name} (${orderFullfillmentTimeRange})`}
              key='1'
              extra={
                orderState === 'accepted' &&
                delivery &&
                delivery.job_id &&
                delivery &&
                delivery.delivery_status !== 'cancelled' &&
                allowCancellation() && (
                  <Button
                    disabled={cancellingCourierJobId.length > 0}
                    loading={cancellingCourierJobId === delivery.job_id}
                    danger
                    onClick={() =>
                      processCancellationOfCourier(delivery.job_id || '')
                    }
                    size='small'
                  >
                    Cancel Courier
                  </Button>
                )
              }
            >
              <Row gutter={[8, 16]}>
                <Col>
                  <Descriptions bordered column={1}>
                    {delivery && delivery.job_id && (
                      <Descriptions.Item label='Job ID'>
                        {delivery.job_id}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label='Fulfilment Date'>
                      <ClockCircleOutlined /> {orderFullfillmentTimeRange}
                    </Descriptions.Item>
                    {delivery && orderState === 'pending' && (
                      <Descriptions.Item label='Vehicle To Be Booked'>
                        {delivery.default_transport_type}
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </Col>
              </Row>
              <Row>
                <Col>
                  {delivery && (
                    <Row gutter={[8, 8]}>
                      {delivery.delivery_status === 'cancelled' && (
                        <Text style={{ color: '#e84747', fontWeight: 'bold' }}>
                          Courier booking cancelled
                        </Text>
                      )}
                      {delivery.delivery_status !== 'cancelled' &&
                        ['pending', 'accepted'].includes(orderState) && (
                          <Text
                            style={{ color: '#f5ab35', fontWeight: 'bold' }}
                          >
                            Booking received by courier partner
                          </Text>
                        )}
                      {orderState === 'fulfilled' && (
                        <Text style={{ fontWeight: 'bold' }}>
                          This order was fulfilled manually
                        </Text>
                      )}
                    </Row>
                  )}
                  <Row>
                    <Timeline>
                      <Timeline.Item
                        dot={<ShopOutlined style={{ color: '#f5ab35' }} />}
                      >
                        {store.name}
                      </Timeline.Item>
                      <Timeline.Item
                        dot={<HomeOutlined style={{ color: '#f5ab35' }} />}
                      >
                        {customerFullAddress}
                      </Timeline.Item>
                    </Timeline>
                  </Row>
                </Col>
              </Row>
            </Panel>
            {additionalCouriers.map((additionalCourier) =>
              courierPanel(additionalCourier)
            )}
          </Collapse>
        </Col>
      </Row>
    </>
  )
}

export default CouriersList
