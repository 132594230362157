import React from 'react'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/intersection'

interface AppliedModifier {
  modifier: Modifier
  modifier_group: ModifierGroup
  quantity: number
}

interface Modifier {
  name: string
  id: string
}

interface ModifierGroup {
  id: string
  name: string
  modifier_arrangement?: { [key: string]: string }
}

interface Props {
  orderQuantity: number
  appliedModifiers: AppliedModifier[]
  arrangement?: { [key: string]: string }
}

const AppliedModifiers = ({
  appliedModifiers,
  arrangement,
  orderQuantity
}: Props) => {
  if (appliedModifiers.length > 0) {
    return (
      <ModifierGroups {...{ appliedModifiers, arrangement, orderQuantity }} />
    )
  } else {
    return <span>N/A</span>
  }
}

const ModifierGroups: Function = ({
  orderQuantity,
  appliedModifiers,
  arrangement
}: Props): JSX.Element[] => {
  // applied modifiers grouped by modifier group id
  const groupedModifiers = groupBy(
    appliedModifiers,
    (item) => item.modifier_group.id
  )

  // applied modifier group ids
  const groupKeys = Object.keys(groupedModifiers)

  // product modifier group arrangement
  const groupArrangement =
    arrangement && !isEmpty(arrangement)
      ? Object.values(arrangement)
      : groupKeys
  const arrangementMatchedGroups = intersection(groupArrangement, groupKeys)

  const nonArrangedGroupIds = groupKeys.filter(
    (groupId: string) => !arrangementMatchedGroups.includes(groupId)
  )

  const totalArrangement = [...arrangementMatchedGroups, ...nonArrangedGroupIds]
  return totalArrangement.map((groupId, index) => {
    const modifiers = groupedModifiers[groupId]
    const { modifier_group } = modifiers[0]
    return (
      <div key={index} data-testid='appliedModifiers'>
        <span>{modifier_group.name}:</span>
        <br />
        <Modifiers
          orderQuantity={orderQuantity}
          appliedModifiers={modifiers}
          arrangement={modifier_group.modifier_arrangement}
        />
      </div>
    )
  })
}

const Modifiers: Function = ({
  orderQuantity,
  appliedModifiers,
  arrangement
}: Props): JSX.Element[] => {
  const groupedItems = keyBy(appliedModifiers, (item) => item.modifier.id)
  const itemKeys = Object.keys(groupedItems)

  const itemArrangement =
    arrangement && !isEmpty(arrangement) ? Object.values(arrangement) : itemKeys

  const excludedItems = itemKeys.filter(
    (itemId: string) => !itemArrangement.includes(itemId)
  )
  const totalArrangement = [...itemArrangement, ...excludedItems]
  return totalArrangement.map((itemId: string) => {
    const item = groupedItems[itemId]
    return (
      item && (
        <React.Fragment key={itemId}>
          <span>
            {item.quantity * orderQuantity} x {item.modifier.name}
          </span>
          <br />
        </React.Fragment>
      )
    )
  })
}

export default AppliedModifiers
