import { gql } from '@apollo/client'

const GET_MERCHANT_COMMISSION_RATES = gql`
  query GetMerchantCommissionRates($merchantCurrentPlanSlug: String!) {
    commission_rates(where: { slug: { _eq: $merchantCurrentPlanSlug } }) {
      delivery
      same_day_self_delivery
      preorder_delivery
      preorder_self_delivery
      nationwide_delivery
      pickup
      preorder_pickup
      table
    }
  }
`

const GET_ZERO_ORDER_COMMISSION_RATES = gql`
  query GetMerchantZeroOrderCommissionRates($zeroOrderRateId: uuid!) {
    zero_order_rates(where: { id: { _eq: $zeroOrderRateId } }) {
      delivery
      same_day_self_delivery
      preorder_delivery
      preorder_self_delivery
      nationwide_delivery
      pickup
      preorder_pickup
      table
    }
  }
`

export { GET_MERCHANT_COMMISSION_RATES, GET_ZERO_ORDER_COMMISSION_RATES }
