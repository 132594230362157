import React from 'react'
import { OrderInfo } from './type'
import { Button, Row } from 'antd'
import {
  PendingCTA,
  AcceptedCTA,
  FulfilledCTA,
  RefundedCTA
} from './CallToAction'
import { getIcon } from 'components/Orders/Info/utils'
import UserFlow from 'components/Utils/Userflow'

interface OrderActionsType {
  order: OrderInfo
  successCallback: () => void
  setShowRefundForm: () => void
}

const OrderActions = ({
  order,
  successCallback,
  setShowRefundForm
}: OrderActionsType) => {
  const { transaction_id, status } = order
  const { userflow } = UserFlow()

  const print = (orderId: string) => {
    userflow.remount()
    userflow.setBaseZIndex(0)
    const url = `${window.location.origin}/print-order/${orderId}`
    const printPage = window.open(url, '_blank')

    userflow.remount()
    userflow.setBaseZIndex(99999)
    if (printPage) printPage.onafterprint = printPage.close
  }

  return (
    <div className='order-actions' data-testid='order-info-actions'>
      <span
        className='transaction'
        data-testid='order-info-actions-transaction-id'
      >
        Order{' '}
        <span className='id' data-testid='order-details-transaction-no'>
          #{transaction_id}
        </span>
      </span>
      <Button
        data-testid='order-info-actions-print'
        onClick={() => print(order.transaction_id)}
        className='print'
        type='primary'
        ghost
        icon={getIcon('printer')}
      >
        Print
      </Button>
      <Row className='_mb-0' align='middle' gutter={8}>
        <Row justify='end' gutter={16} style={{ margin: 10 }}>
          {status === 'pending' && (
            <PendingCTA order={order} successCallback={successCallback} />
          )}
          {status === 'accepted' && (
            <AcceptedCTA
              order={order}
              successCallback={successCallback}
              setShowRefundForm={setShowRefundForm}
            />
          )}
          {status === 'fulfilled' && (
            <FulfilledCTA order={order} setShowRefundForm={setShowRefundForm} />
          )}
          {status === 'refunded' && (
            <RefundedCTA order={order} setShowRefundForm={setShowRefundForm} />
          )}
        </Row>
      </Row>
    </div>
  )
}

export default OrderActions
