import React from 'react'
import { Col, InputNumber, PageHeader, Row, Form, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Store } from '@slerp/controls'
import StripeConnectStoreSettings from '../StripeConnectStoreSettings'
import { useQuery } from '@apollo/client'
import { QUERY_GET_STORE_FRANCHISE_ATTRIBUTION } from '../LocationQueries'
import Loading from 'components/Utils/Loading'
import {
  ATTRIBUTIONS_MAP,
  attributionToFormObject,
  chunkArray
} from 'components/Settings/AccountsAndBilling/utils'
import styled from '@emotion/styled'
import { useSession } from 'packages/@slerp/accounts'

interface Props {
  store: Store
}

const { useForm } = Form

const Account = ({ store }: Props) => {
  const [form] = useForm()
  const { user } = useSession()

  const { data, loading } = useQuery(QUERY_GET_STORE_FRANCHISE_ATTRIBUTION, {
    variables: {
      storeId: store.id
    }
  })

  const attribution = attributionToFormObject(
    data?.getStoreFranchiseAttributions || {},
    { setDefault: false }
  )
  const attributionChunk = chunkArray(Object.entries(ATTRIBUTIONS_MAP), 3)
  const rolesAllowedToConnect = ['admin', 'manager']

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <Col span={14}>
            <StripeConnectStoreSettings
              canConnect={rolesAllowedToConnect.includes(user.role)}
              store={store}
              stripeId={data?.getStoreFranchiseAttributions?.stripeUserId}
            />
          </Col>
          {attribution && (
            <>
              <PageHeader
                title={
                  <>
                    Attribution
                    <Tooltip
                      title={
                        <span>
                          Attribution determines the proportion of the partner
                          amount allocated to the location. For example, if the
                          attribution is 90%, the location will receive 90% of
                          the partner amount and the partner will receive the
                          balance 10%.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </>
                }
              />
              <Form
                data-testid='storeAttribution'
                id='storeAttribution'
                form={form}
                layout='vertical'
                initialValues={attribution}
              >
                {attributionChunk.map((row, index) => (
                  <Row key={index} className='_mb-32' gutter={[40, 40]}>
                    {row.map(([key, label]) => (
                      <Col span={6} key={key}>
                        <Form.Item
                          label={label}
                          name={key}
                          className='_mb-0 -plain'
                        >
                          <DisabledInput
                            disabled
                            data-testid={key}
                            style={{ width: '100%' }}
                            formatter={(value) => `${value}%`}
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                ))}
              </Form>
            </>
          )}
        </>
      )}
    </>
  )
}
export default Account

const DisabledInput = styled(InputNumber)(() => ({
  '&.ant-input-number-disabled': {
    color: 'black',
    backgroundColor: 'transparent'
  }
}))
