import React from 'react'
import { compact } from 'lodash'
import { Col, Row } from 'antd'
import {
  AdditionalCourierAddress,
  CustomerAddress
} from 'components/Orders/Info/type'

const Address = ({
  address
}: {
  address: AdditionalCourierAddress | CustomerAddress
}) => {
  const { flat_number = '', line_1 = '', line_2 = '', zip } = address || {}
  const fullAddress = compact([flat_number, line_1, line_2]).join(', ')
  return (
    <>
      <Row className='_mb-0'>
        <Col>{fullAddress}</Col>
      </Row>
      <Row className='_mb-0'>
        <Col>{zip}</Col>
      </Row>
    </>
  )
}

export default Address
