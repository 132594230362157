import React from 'react'
import { Avatar, Col, Divider, Row } from 'antd'
import { OrderInfo, AddressCache } from 'components/Orders/Info/type'
import {
  formatFulfillmentDate,
  formatFulfillmentTime,
  getIcon
} from 'components/Orders/Info/utils'
import moment from 'moment-timezone'

interface PickupDetailsProps {
  order: OrderInfo
}

interface AddressProps {
  address: AddressCache
}

const PickupDetails = ({ order }: PickupDetailsProps) => {
  const { store, inserted_at } = order
  const {
    name: store_name,
    address_cache: store_address_cache,
    address
  } = store

  const orderInsertedAt = moment(inserted_at).format()
  const addressUpdatedAt = moment(address?.updated_at).format()

  const storeAddress = moment(orderInsertedAt).isAfter(addressUpdatedAt)
    ? address
    : JSON.parse(store_address_cache)

  const Address = ({ address }: AddressProps) => {
    const { address: street, city, zip } = address || {}
    return (
      <>
        <Row className='_mb-0'>
          <Col>{`${street}, ${city}`}</Col>
        </Row>
        <Row className='_mb-0'>
          <Col>{zip}</Col>
        </Row>
      </>
    )
  }

  const Detail = ({ type, content }: DetailProps) => (
    <Row align='middle' className='_mb-0'>
      <Col span={4}>
        <Avatar size={32} style={{ color: '#4a4a68' }} icon={getIcon(type)} />
      </Col>
      <Col span={20} className='_cl-text-detail _capitalize'>
        {content}
      </Col>
    </Row>
  )

  return (
    <Row className='_mb-0'>
      <Col span={24} className='_p-16'>
        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Pickup from:
          </Col>
        </Row>
        <Row align='middle'>
          <Col span={12}>
            <Detail type='store' content={store_name} />
          </Col>
          <Col span={12}>
            <Detail
              type='location'
              content={<Address address={storeAddress} />}
            />
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Fulfillment date and time:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail
                  type='datestamp'
                  content={formatFulfillmentDate(order)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail
                  type='timestamp'
                  content={formatFulfillmentTime(order)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />
      </Col>
    </Row>
  )
}

export default PickupDetails
