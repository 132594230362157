import gql from 'graphql-tag'

export const ACCEPT_ORDERS = gql`
  mutation OrderMutations($transactionIds: [String]!, $userId: ID) {
    acceptOrders(transactionIds: $transactionIds, userId: $userId) {
      id
      transactionId
      status
    }
  }
`

export const COMPLETE_ORDERS = gql`
  mutation CompleteOrders($transactionIds: [String]!, $userId: ID) {
    completeOrders(transactionIds: $transactionIds, userId: $userId) {
      id
      transactionId
      status
    }
  }
`

export const ACCEPT_ORDER = gql`
  mutation OrderMutations($transactionId: String!, $userId: ID) {
    acceptOrder(transactionId: $transactionId, userId: $userId) {
      id
      transactionId
      status
    }
  }
`

export const REJECT_ORDER = gql`
  mutation OrderMutations($transactionId: String!, $userId: ID) {
    rejectOrder(transactionId: $transactionId, userId: $userId) {
      id
      transactionId
      status
    }
  }
`

export const COMPLETE_ORDER = gql`
  mutation OrderMutations($transactionId: String!, $userId: ID) {
    completeOrder(transactionId: $transactionId, userId: $userId) {
      id
      transactionId
      status
    }
  }
`

export const UPDATE_ORDER_ADDITIONAL_NOTES = gql`
  mutation UpdateOrderAdditionalNotes(
    $slug: String!
    $transactionId: String!
    $additionalNotes: String!
  ) {
    update_orders(
      where: {
        merchant: { slug: { _eq: $slug } }
        transaction_id: { _eq: $transactionId }
      }
      _set: { additional_notes: $additionalNotes, note_updated_at: "now()" }
    ) {
      affected_rows
      returning {
        transaction_id
        note_updated_at
      }
    }
  }
`

export const UPDATE_ORDER_TIMESLOT = gql`
  mutation UpdateOrderTimeslot(
    $transactionId: String!
    $fulfillmentDate: String!
    $fulfillmentTime: String!
    $userId: ID!
  ) {
    updateOrderTimeslot(
      transactionId: $transactionId
      fulfillmentDate: $fulfillmentDate
      fulfillmentTime: $fulfillmentTime
      userId: $userId
    ) {
      transaction_id
    }
  }
`

const ordersFragment = `
    store_id
    transaction_id
    key: transaction_id
    store {
      name
      address {
        zip
      }
    }
    subtotal
    total
    tip_value
    fulfillment_type
    fulfillment_date
    status
    create_device_category
    create_platform
    create_os_type
    inserted_at
`

export const QUERY_ORDERS = gql`
  query merchantOrdersList(
    $slug: String!
    $query: String
    $limit: Int = 10
    $statuses: [String!]
    $storeIds: [uuid!]
    $startDate: timestamp
    $endDate: timestamp
  ) {
    orders(
      where: {
        merchant: { slug: { _eq: $slug } }
        status: { _in: $statuses }
        store_id: { _in: $storeIds }
        transaction_id: { _ilike: $query }
        _or: [
          { fulfillment_date: { _gte: $startDate, _lte: $endDate } }
          {
            _and: [
              { fulfillment_date: { _is_null: true } }
              { inserted_at: { _gte: $startDate, _lte: $endDate } }
            ]
          }
        ]
      }
      order_by: { inserted_at: desc }
      limit: $limit
    ) {
      ${ordersFragment}
    }
    orders_aggregate(
      where: {
        merchant: { slug: { _eq: $slug } }
        status: { _in: $statuses }
        transaction_id: { _ilike: $query }
        store_id: { _in: $storeIds }
        _or: [
          { fulfillment_date: { _gte: $startDate, _lte: $endDate } }
          {
            _and: [
              { fulfillment_date: { _is_null: true } }
              { inserted_at: { _gte: $startDate, _lte: $endDate } }
            ]
          }
        ]
      }
      limit: $limit
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`

export const QUERY_ORDERS_BY_EMAIL = gql`
  query merchantOrdersListByEmail(
    $slug: String!
    $limit: Int = 10
    $statuses: [String!]
    $storeIds: [uuid!]
    $startDate: timestamp
    $endDate: timestamp
    $email: jsonb!
  ) {
    orders(
      where: {
        merchant: { slug: { _eq: $slug } }
        status: { _in: $statuses }
        store_id: { _in: $storeIds }
        customer_details: {_contains: $email }
        _or: [
          { fulfillment_date: { _gte: $startDate, _lte: $endDate } }
          {
            _and: [
              { fulfillment_date: { _is_null: true } }
              { inserted_at: { _gte: $startDate, _lte: $endDate } }
            ]
          }
        ]
      }
      order_by: { inserted_at: desc }
      limit: $limit
    ) {
      ${ordersFragment}
    }
    orders_aggregate(
      where: {
        merchant: { slug: { _eq: $slug } }
        status: { _in: $statuses }
        customer_details: {_contains: $email}
        store_id: { _in: $storeIds }
        _or: [
          { fulfillment_date: { _gte: $startDate, _lte: $endDate } }
          {
            _and: [
              { fulfillment_date: { _is_null: true } }
              { inserted_at: { _gte: $startDate, _lte: $endDate } }
            ]
          }
        ]
      }
      limit: $limit
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`

const SUBSCRIPTION_LATEST_ORDERS = gql`
  subscription ordersList {
    orders(order_by: { inserted_at: desc }, limit: 80) {
      merchant {
        name
      }
      transaction_id
      transaction_id
      as
      key
      store {
        name
      }
      total
      fulfillment_type
      fulfillment_date
      status
      inserted_at
      customer_details
    }
  }
`

const ORDER_FIELDS = `
  id
  merchant {
    name
    pre_order_settings {
      cutoffs
      special_cutoffs
      fixed_delivery_times
      fixed_delivery_times_enabled
      delivery_schedule
      delivery_interval
    }
    order_custom_field_enabled: setting(path: "order_custom_field_enabled")
    vat_registration_number: setting(path: "vat_id")
    registered_company_name
    address {
      line_1
      line_2
      city
      zip
    }
  }
  transaction_id
  store {
    email
    id
    name
    settings
    address {
      geom
      line_1
      line_2
      city
      zip
      flat_number
      updated_at
    }
    pre_order_settings {
      delivery_courier_partner
      fixed_delivery_times_enabled
      delivery_interval
      delivery_schedule
      delivery_times
    }
    address_cache
  }
  delivery
  delivery_charge_reduction
  delivery_charge_reduction_reason
  delivery_pricing_band
  delivery_charge
  delivery_vat_charge
  delivery_charge_vat
  tip_value
  delta_delivery_charge
  delivery_eta
  fulfillment_date
  fulfillment_type
  fulfillment_time_range
  inserted_at
  discounts_cache
  customer_details
  recipient_details
  payment
  refunds {
    id
    reason
    amount
    inserted_at
    user {
      firstname
      lastname
    }
  }
  dropoff_notes
  order_notes
  additional_notes
  gift_wrap_price
  gift_wrap_message
  note_updated_at
  status
  order_items {
    applied_modifiers {
      vat
      price
      quantity
      modifier {
        id
        name
      }
      modifier_group {
        id
        name
        modifier_arrangement
      }
    }
    product_variant {
      image
      name
      product {
        modifier_group_arrangement
      }
    }
    variant_price
    variant_vat
    quantity
    vat
    amount
  }
  misc_fees {
    id
    type
    amount
    coupon_code
    note
  }
  additional_store_fee
  additional_store_fee_cache
  additional_couriers(order_by: { inserted_at: asc }) {
    address
    store_id
    fulfillment_date
    job_id: data(path: "job_id")
    delivery_status: data(path: "delivery_status")
    recipient_details
    dropoff_notes
    data
    store {
      id
      name
    }
  }
  subtotal
  total
  custom_field_value
  custom_field_name
  merchant_settings
  create_device_category
  create_platform
  create_os_type
`

const SUBSCRIPTION_ORDER = gql`
  subscription subscribeToOrder($transactionId: String!) {
    orders(where: { transaction_id: { _eq: $transactionId } }) {
      ${ORDER_FIELDS}
    }
  }
`

const QUERY_ORDER = gql`
  query subscribeOrder($transactionId: String!) {
    orders(where: { transaction_id: { _eq: $transactionId } }) {
      ${ORDER_FIELDS}
    }
  }
`

const ADD_MISC_FEE = gql`
  mutation addMiscFee($miscFeeObject: misc_fees_insert_input!) {
    insert_misc_fees_one(object: $miscFeeObject) {
      id
    }
  }
`

const GET_ORDER_QUOTE = gql`
  query quote($quoteParams: QuoteParams) {
    quote(quoteParams: $quoteParams) {
      cost
    }
  }
`

const GET_SAME_DAY_STORES = gql`
  query GetSameDayStore($merchant_id: uuid!) {
    stores(
      where: {
        merchant_id: { _eq: $merchant_id }
        archived_at: { _is_null: true }
      }
    ) {
      id
      name
    }
  }
`

const REBOOK_COURIER = gql`
  mutation rebookCourier(
    $current_user_id: String!
    $transaction_id: String!
    $store_id: String!
    $address: AddressParams!
    $currentUserId: String!
    $transactionId: String!
    $storeId: String!
    $recipient: RecipientDetailsParams!
    $vehicle: String!
    $fulfillmentTime: String!
    $fulfillmentDate: String!
    $reason: RebookingReason!
    $dropoffNotes: String
    $notes: String
    $reasonNotes: String
  ) {
    rebookCourier(
      address: $address
      currentUserId: $currentUserId
      transactionId: $transactionId
      storeId: $storeId
      recipient: $recipient
      vehicle: $vehicle
      fulfillmentTime: $fulfillmentTime
      fulfillmentDate: $fulfillmentDate
      reason: $reason
      dropoffNotes: $dropoffNotes
      notes: $notes
      reasonNotes: $reasonNotes
    ) {
      status
    }
  }
`

const BOOK_ANOTHER_COURIER = gql`
  mutation rebookCourier(
    $address: AddressParams!
    $currentUserId: String!
    $transactionId: String!
    $storeId: String!
    $recipient: RecipientDetailsParams!
    $vehicle: String!
    $fulfillmentTime: String!
    $fulfillmentDate: String!
    $reason: RebookingReason!
    $dropoffNotes: String
    $notes: String
    $reasonNotes: String
  ) {
    rebookCourier(
      address: $address
      currentUserId: $currentUserId
      transactionId: $transactionId
      storeId: $storeId
      recipient: $recipient
      vehicle: $vehicle
      fulfillmentTime: $fulfillmentTime
      fulfillmentDate: $fulfillmentDate
      reason: $reason
      dropoffNotes: $dropoffNotes
      notes: $notes
      reasonNotes: $reasonNotes
    ) {
      status
    }
  }
`

const CANCEL_COURIER = gql`
  mutation cancelCourier($transactionId: String!, $jobId: String) {
    cancelDelivery(transactionId: $transactionId, jobId: $jobId) {
      status
    }
  }
`

const GET_STORES = gql`
  query getStores($merchantId: uuid!) {
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
    ) {
      id
      split_hours
      slug
      name
    }
  }
`

const GET_MERCHANT_DELIVERY_SETTINGS = gql`
  query getMerchantdeliverySettings($merchantId: uuid!) {
    merchants: merchants_by_pk(id: $merchantId) {
      stores(limit: 1) {
        pre_order_settings {
          delivery_courier_partner
        }
      }
    }
  }
`

const GET_ORDER_REFUNDS = gql`
  query getOrderRefunds($transaction_id: String, $merchant_id: uuid) {
    orders(
      where: {
        transaction_id: { _eq: $transaction_id }
        merchant_id: { _eq: $merchant_id }
      }
    ) {
      total
      refunds: payment(path: "data.refunds.data")
    }
  }
`

const REFUND_ORDER_DETAILS = gql`
  mutation RefundOrderDetails(
    $amount: String!
    $reason: String!
    $transactionId: String!
    $userId: ID!
  ) {
    refundOrder(
      refundDetails: { amount: $amount, reason: $reason }
      transactionId: $transactionId
      userId: $userId
    ) {
      id
      status
    }
  }
`

const GET_TIMESLOTS = gql`
  query getFixedStoreTimeslots($storeId: String!, $fulfillmentDate: String!) {
    getFixedStoreTimeslots(
      storeId: $storeId
      fulfillmentDate: $fulfillmentDate
    ) {
      value
      startTime
      endTime
    }
  }
`

const GET_MERCHANT_CHARGES = gql`
  query merchant_charges($orderId: uuid!) {
    merchant_charges(
      where: {
        _or: [
          { additional_courier: { order_id: { _eq: $orderId } } }
          { refund: { order_id: { _eq: $orderId } } }
          { transaction_log: { order_id: { _eq: $orderId } } }
          { order_id: { _eq: $orderId } }
        ]
      }
    ) {
      id
      amount
      inserted_at
      charge_type
      reverted_at
      forwarded_fee_charge
      delivery_fee_charge
      zero_order_fee_amount
      transaction_log {
        application_fee_amount
      }
      refund {
        amount
        order {
          transaction_logs {
            application_fee_amount
          }
        }
      }
    }
  }
`

const GET_CHARGES = gql`
  query refunds($orderId: uuid!) {
    refunds(where: { order_id: { _eq: $orderId } }) {
      amount
    }
  }
`

const GET_ORDER_DISCOUNT = gql`
  query discounts($merchantId: uuid!, $code: String!) {
    discounts(
      where: { merchant_id: { _eq: $merchantId }, code: { _eq: $code } }
    ) {
      id
      code
      description
    }
  }
`

export {
  SUBSCRIPTION_LATEST_ORDERS,
  SUBSCRIPTION_ORDER,
  QUERY_ORDER,
  ADD_MISC_FEE,
  GET_ORDER_QUOTE,
  GET_SAME_DAY_STORES,
  REBOOK_COURIER,
  BOOK_ANOTHER_COURIER,
  GET_STORES,
  CANCEL_COURIER,
  GET_MERCHANT_DELIVERY_SETTINGS,
  GET_ORDER_REFUNDS,
  REFUND_ORDER_DETAILS,
  GET_TIMESLOTS,
  GET_MERCHANT_CHARGES,
  GET_CHARGES,
  GET_ORDER_DISCOUNT
}
