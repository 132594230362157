import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Tabs, Row } from 'antd'
import { useParams } from 'react-router-dom'

const { TabPane } = Tabs

interface MerchantType {
  id: string
  centralized_perorders: boolean
  description?: string
  name: string
  pre_order_delivery: boolean
  pre_order_pickup: boolean
  slug: string
  stores: []
  setting: {
    [key: string]: string | boolean
  }
}

interface SettingsTabsType {
  merchant: MerchantType
}

const SettingsTabs = ({ merchant }: SettingsTabsType) => {
  const { slug } = useParams()
  let history = useHistory()

  useEffect(() => {
    history.push(history.location.pathname)
  }, [])

  return (
    <>
      <Row data-testid='location-settings-tabs' className='_mb-0'>
        {merchant && (
          <Tabs
            type='card'
            defaultActiveKey={`${history.location.pathname}`}
            className='_mb-8'
          >
            <TabPane
              key={`/location/${slug}/settings/general`}
              tab={
                <Link
                  to={`/location/${slug}/settings/general`}
                  data-testid='location-settings-general'
                >
                  General
                </Link>
              }
            ></TabPane>
            <TabPane
              key={`/location/${slug}/settings/same-day`}
              tab={
                <Link
                  to={`/location/${slug}/settings/same-day`}
                  data-testid='location-settings-sameday'
                >
                  Same Day Orders
                </Link>
              }
            ></TabPane>
            <TabPane
              key={`/location/${slug}/settings/pre-order`}
              tab={
                <Link
                  to={`/location/${slug}/settings/pre-order`}
                  data-testid='location-settings-preorder'
                >
                  Pre-orders
                </Link>
              }
            ></TabPane>
            <TabPane
              key={`/location/${slug}/settings/table`}
              tab={
                <Link
                  to={`/location/${slug}/settings/table`}
                  data-testid='location-settings-tableorders'
                >
                  Table Orders
                </Link>
              }
            ></TabPane>
            {merchant.setting.franchise_enabled && (
              <TabPane
                key={`/location/${slug}/settings/accounts-and-billing`}
                tab={
                  <Link
                    to={`/location/${slug}/settings/accounts-and-billing`}
                    data-testid='location-settings-accounts-and-billing'
                  >
                    Account and Billing
                  </Link>
                }
              ></TabPane>
            )}
          </Tabs>
        )}
      </Row>
    </>
  )
}

export default SettingsTabs
