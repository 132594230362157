import isEmpty from 'lodash/isEmpty'

const TRUST_PILOT_EMAIL_DOMAIN_NAME = 'invite.trustpilot.com'
const CRO_REGISTERED_NUMBER_LENGTH = 8
const companySuffixes = ['limited', 'ltd', 'llp', 'plc', 'ltd.', 'llp.', 'plc.']

const emailTypeRule = {
  type: 'email',
  message: 'Please provide a proper email format.'
}

const trustPilotEmailFormatRule = [
  emailTypeRule,
  {
    validator: (_: any, value: string) => {
      if (isEmpty(value)) return Promise.resolve()
      const validator = new RegExp(`${TRUST_PILOT_EMAIL_DOMAIN_NAME}$`)

      return validator.test(value)
        ? Promise.resolve()
        : Promise.reject(
            `Trustpilot email should have a domain name of ${TRUST_PILOT_EMAIL_DOMAIN_NAME}`
          )
    }
  }
]

const companySuffixRule = [
  {
    validator: (_: any, val: string) => {
      if (!val) return Promise.reject('Company Name is required')

      return companySuffixes.some((suffix) =>
        val.toLowerCase().endsWith(suffix)
      )
        ? Promise.resolve()
        : Promise.reject(
            'Registered company name must end with either one of the ff: limited, ltd, LLP, PLC'
          )
    }
  }
]

const companyRegisteredNumberRule = [
  {
    validator: (_: any, val: string) => {
      // guard clause to check if value is of 8 characters (alphanumeric)
      if (!val) return Promise.reject('Company registration number is required')
      if (val.length !== CRO_REGISTERED_NUMBER_LENGTH)
        return Promise.reject(
          'Company registration number must consist of 8 alphanumeric characters'
        )

      const prefix = (val || '').substring(0, 2)
      const suffix = (val || '').substring(2, CRO_REGISTERED_NUMBER_LENGTH)

      if (!/^[a-zA-Z\d]+$/.test(prefix.toLowerCase()))
        return Promise.reject('First two characters must be alphanumeric')

      if (!/^\d+$/.test(suffix))
        return Promise.reject('Last six characters must be numeric')

      return Promise.resolve()
    }
  }
]

export {
  emailTypeRule,
  trustPilotEmailFormatRule,
  companySuffixRule,
  companyRegisteredNumberRule
}
