import React from 'react'
import { Checkbox } from 'antd'

const OrderTypeContainer = (props: {
  children: React.ReactNode
  enabled: boolean
  checked: boolean
  onChange?: () => void
  onCardClick?: () => void
  disabled?: boolean
}) => {
  const { enabled, onCardClick, onChange, checked, disabled = false } = props
  return (
    <div className='order-type'>
      <Checkbox
        defaultChecked={enabled}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <div
        className={`card ${enabled ? '-active' : ''}`}
        onClick={disabled ? () => {} : onCardClick}
      >
        {props.children}
      </div>
    </div>
  )
}

export default OrderTypeContainer
