import React, { useState } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import TimePicker from 'antd/lib/time-picker'
import moment, { Moment } from 'moment'
import { format } from 'date-fns'
import TextFieldNumber from './../../../Widgets/TextFieldNumber'

const CutoffTimeFields = ({
  disabled = false,
  updateCutoff,
  time,
  dayDiff
}: {
  disabled?: boolean
  updateCutoff?: (key: string, value: any) => void
  dayDiff?: number | null
  time?: string | null
}) => {
  const [cutOffTime, setCutOffTime] = useState<Moment>(
    moment(time || '00:00:00', 'hh:mm:ss')
  )

  const onChange = (time: Moment | null) => {
    const timeAsDate = time
      ? time.toDate()
      : moment('00:00:00', 'HH:mm:ss').toDate()
    const formattedTime = format(timeAsDate, 'HH:mm:ss')
    setCutOffTime(moment(formattedTime, 'hh:mm:ss'))

    updateCutoff && updateCutoff('time', formattedTime)
  }

  return (
    <Row className='_mb-0' align='middle' gutter={8}>
      <Col>
        <TextFieldNumber
          inputType='number'
          placeholder=''
          style={{ maxWidth: '160px' }}
          disabled={disabled}
          min={1}
          defaultValue={dayDiff || 1}
          required
          value={dayDiff}
          onChange={(e: number) => updateCutoff && updateCutoff('day_diff', e)}
        />
      </Col>
      <span style={{ margin: '0 16px' }}>
        {dayDiff <= 1 ? 'day' : 'days'} before at
      </span>
      <TimePicker
        data-testid='cutoff-row-timepicker'
        format='h:mm a'
        showNow={false}
        disabled={disabled}
        value={cutOffTime}
        allowClear={false}
        onChange={onChange}
        onSelect={onChange}
      />
    </Row>
  )
}

export default CutoffTimeFields
