import React, { useState, useEffect } from 'react'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { Input, message, Row, Col, Button } from 'antd'
import {
  CREATE_STRIPE_ACCOUNT,
  ADD_CARD_TO_STRIPE_CUSTOMER_ACCOUNT
} from '../actions'
import { useMutation, useApolloClient } from '@apollo/client'
import { Merchant } from 'types'

const inputStyles = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif',
      color: '#4a4a68',
      fontWeight: 500,
      lineHeight: '40px',
      '::placeholder': {
        color: '#d2d2da',
        fontSize: '14px'
      }
    }
  }
}

const CardDetails = (props: {
  merchant: Merchant
  showModalHandler: (show: boolean) => void
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const client = useApolloClient()

  const { merchant, showModalHandler } = props

  const [isCompleted, setIsCompleted] = useState(false)
  const [hasCardNumber, setHasCardNumber] = useState(false)
  const [hasExpiry, setHasExpiry] = useState(false)
  const [hasCVC, setHasCVC] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')

  const [createStripeAccount, { loading }] = useMutation(CREATE_STRIPE_ACCOUNT)
  const [addCardToStripeCustomerAccount, { loading: addCardLoading }] =
    useMutation(ADD_CARD_TO_STRIPE_CUSTOMER_ACCOUNT, {
      refetchQueries: ['merchantConnectedStripeCards']
    })

  useEffect(() => {
    setIsCompleted(!!nameOnCard && hasCardNumber && hasExpiry && hasCVC)
  }, [nameOnCard, hasCardNumber, hasExpiry, hasCVC])

  const submitHandler = async () => {
    try {
      const stripeToken = await stripe?.createToken(
        elements?.getElement(CardNumberElement)!,
        {
          name: nameOnCard
        }
      )

      if (!!merchant?.subscription?.stripe_customer_id) {
        addCardToStripeCustomerAccount({
          variables: {
            merchant_id: merchant?.id,
            stripe_customer_id: merchant?.subscription?.stripe_customer_id,
            stripe_token: stripeToken?.token?.id
          }
        })
          .then(() => {
            message.success(
              'Succesfully added card details to stripe customer account',
              5
            )
            showModalHandler(false)
          })
          .catch((error) => {
            message.error(error.message, 5)
          })
      } else {
        createStripeAccount({
          variables: {
            merchant_id: merchant?.id,
            merchant_name: merchant?.name,
            email: merchant?.setting?.email,
            stripe_token: stripeToken?.token?.id
          }
        })
          .then(() => {
            message.success(
              'Succesfully added card details to stripe customer account',
              5
            )
            client.resetStore()
          })
          .catch((error) => {
            message.error(`Unable to add card details due to: ${error}`, 5)
          })
      }
    } catch (error) {
      message.error(`Unable to create stripe token due to: ${error}`, 5)
    }
  }

  return (
    <Col className='stripe-inputs' span={24}>
      <Input
        placeholder='Name on card'
        value={nameOnCard}
        onChange={(e) => setNameOnCard(e.target.value)}
        className='card-name _mb-16'
        autoFocus
      />
      <Col span={24} className='ant-input stripe-input _mb-16'>
        <CardNumberElement
          onChange={(e) => setHasCardNumber(e.complete)}
          options={inputStyles}
        />
      </Col>
      <Row className='_mb-0' justify='space-between'>
        <Col span={12} className='ant-input stripe-input'>
          <CardExpiryElement
            onChange={(e) => setHasExpiry(e.complete)}
            options={inputStyles}
          />
        </Col>
        <Col span={12} className='ant-input stripe-input'>
          <CardCvcElement
            onChange={(e) => setHasCVC(e.complete)}
            options={inputStyles}
          />
        </Col>
      </Row>
      <Row className='_mt-32 _mb-0' justify='center'>
        <Button
          type='ghost'
          className='_mr-16'
          onClick={() => showModalHandler(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !stripe || !elements || !isCompleted || loading || addCardLoading
          }
          onClick={submitHandler}
          loading={loading || addCardLoading}
        >
          Submit
        </Button>
      </Row>
    </Col>
  )
}

export default CardDetails
