const ALLERGENS = {
  celery: 'Celery',
  cereals: 'Cereals containing gluten',
  crustaceans: 'Crustaceans',
  eggs: 'Eggs',
  fish: 'Fish',
  gluten: 'Gluten',
  lupin: 'Lupin',
  milk: 'Milk',
  molluscs: 'Molluscs',
  mustard: 'Mustard',
  nuts: 'Nuts',
  peanuts: 'Peanuts',
  sesame: 'Sesame',
  soybeans: 'Soybeans',
  sulphur: 'Sulphur dioxide and sulphites',
  treenuts: 'Tree nuts',
  wheat: 'Wheat'
}

export { ALLERGENS }
