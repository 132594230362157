type CustomRuleType = { getFieldValue: (arg: number) => number }

const required = { required: true, message: 'This is a required field.' }
const requiredRule = [required]

const vatPercentageFieldRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: number) {
      const isDecimal = value?.toString().includes('.')

      if (isDecimal) {
        const input = value && value?.toString().split('.')

        return input && input[1]?.length < 3
          ? Promise.resolve()
          : Promise.reject(
              'Please re-enter a new VAT % amount to 2 decimal places or less'
            )
      } else {
        return Promise.resolve()
      }
    }
  })
]

export { requiredRule, vatPercentageFieldRule }
