import React from 'react'
import { ATTRIBUTIONS_MAP } from './utils'
import { AttributionKeys } from './types'
import { Attribution } from '@slerp/controls'

import {
  AttributionCard,
  AttributionFlexBox,
  CardTitle,
  CardValue
} from './StyledComponents'

interface Props {
  attribution: Attribution
  isExpanded: boolean
}

const parsePersent = (value: string | null) => {
  const persent = value ? parseFloat(value) * 100 : 0

  return persent.toFixed(2).split('.')[1] === '00'
    ? persent.toFixed()
    : persent.toFixed(2)
}

export const AttributionCards = ({ attribution, isExpanded }: Props) => {
  return (
    <AttributionFlexBox maxHeight={isExpanded ? 'none' : '44px'}>
      {Object.entries(ATTRIBUTIONS_MAP).map(([key, name]) => (
        <AttributionCard key={key}>
          <CardTitle>{name}</CardTitle>
          <CardValue>
            {parsePersent(attribution[key as AttributionKeys])}%
          </CardValue>
        </AttributionCard>
      ))}
    </AttributionFlexBox>
  )
}
