import React from 'react'
import { Col, Row, InputNumber } from 'antd'
import { GlobalPricing } from './types'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'

interface GlobalPricingRowProps {
  variantId: string
  variantName: string
  options: string[]
  pricing: GlobalPricing
}

const GlobalPricingRow = ({
  variantId,
  variantName,
  options,
  pricing
}: GlobalPricingRowProps) => {
  const { price_with_vat, price_without_vat, vat } = pricing

  const PriceField = ({ value, testId }) => (
    <InputNumber
      disabled
      min={0}
      precision={2}
      className='_w-10 _input-dark-green'
      data-testid={testId}
      defaultValue={value}
    />
  )

  return (
    <Row
      className='_bg-pale-green _mb-0 _pt-12 _pb-12'
      data-testId='global-pricing-row'
    >
      <Col span={11}>
        <VariantNameAndAvatar
          id={variantId}
          name={variantName}
          options={options}
          testId='some-testid'
        />
      </Col>
      <Col span={13}>
        <Row
          gutter={[8]}
          align='middle'
          justify='end'
          className='_mb-0'
          key={variantId}
          data-testid='variant-global-pricing-row'
        >
          <Col span={8}>
            <PriceField
              value={price_with_vat}
              testId='variant-global-row-gross-price-field'
            />
          </Col>
          <Col span={8}>
            <PriceField value={vat} testId='variant-global-row-vat-field' />
          </Col>
          <Col span={8}>
            <PriceField
              value={price_without_vat}
              testId='variant-global-row-price-field'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default GlobalPricingRow
