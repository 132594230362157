import React, { useState } from 'react'
import { uuid } from 'uuidv4'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import { useMutation, useApolloClient } from '@apollo/client'
import { Input, InputNumber, message, Table, Switch } from 'antd'
import { NormalizedVariant, ProductVariant, Option } from '../types'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'
import ActionsBanner from 'components/Widgets/ActionsBanner'
import {
  isValidFloat,
  NormalizeProductVariant,
  SortVariantsByOptions,
  isCaloriesValueValid,
  isLimitValueValid
} from '../utils'
import { UPSERT_PRODUCT_VARIANTS } from '../VariantQueries'

interface VariantsListProps {
  variants: ProductVariant[]
  baseName: string
}

interface FieldValue {
  [key: string]: string | number | boolean | undefined
}

const VariantsList = ({ variants, baseName }: VariantsListProps) => {
  const client = useApolloClient()
  const [variantsList, setVariantsList] = useState<ProductVariant[]>(
    SortVariantsByOptions(NormalizeProductVariant(variants))
  )
  const [originalVariantsList] = useState<ProductVariant[]>(
    SortVariantsByOptions(NormalizeProductVariant(variants))
  )
  const [invalidCalorieRows, setInvalidCalorieRows] = useState<string[]>([])
  const [invalidLimitRows, setInvalidLimitRows] = useState<string[]>([])
  const [upsertProductVariants] = useMutation(UPSERT_PRODUCT_VARIANTS)

  const updateVariant = (variantId: string, fieldUpdate: FieldValue) => {
    const variantsCopy = [...variantsList]
    const editingVariantIndex = variantsCopy.findIndex(
      ({ id }: NormalizedVariant) => id === variantId
    )
    let editingVariant = { ...variantsCopy.splice(editingVariantIndex, 1)[0] }
    const invalidCalorieRowsClone = [...uniq(invalidCalorieRows)]
    const invalidLimitRowsClone = [...uniq(invalidLimitRows)]

    if (isEmpty(editingVariant)) return

    if (Object.keys(fieldUpdate).includes('sku')) {
      editingVariant.sku = fieldUpdate.sku
    }

    if (Object.keys(fieldUpdate).includes('calories_per_serving')) {
      const calorie_data = isEmpty(fieldUpdate.calories_per_serving)
        ? null
        : {
            id: uuid(),
            serving_unit: 'servings',
            amount_per_serving: 1,
            calories_per_serving: parseInt(
              fieldUpdate.calories_per_serving || 0
            )
          }

      if (isCaloriesValueValid(fieldUpdate.calories_per_serving)) {
        const calorieIndex = invalidCalorieRowsClone.findIndex(
          (id: string) => id === editingVariant.id
        )
        if (calorieIndex >= 0) invalidCalorieRowsClone.splice(calorieIndex, 1)
      } else {
        invalidCalorieRowsClone.push(editingVariant.id)
      }

      editingVariant.calorie_data = calorie_data
    }

    if (Object.keys(fieldUpdate).includes('limit')) {
      if (isLimitValueValid(fieldUpdate.limit)) {
        const limitIndex = invalidLimitRowsClone.findIndex(
          (id: string) => id === editingVariant.id
        )
        if (limitIndex >= 0) invalidLimitRowsClone.splice(limitIndex, 1)
      } else {
        invalidLimitRowsClone.push(editingVariant.id)
      }
      editingVariant.limit = parseInt(fieldUpdate.limit || 0)
    }

    if (Object.keys(fieldUpdate).includes('alcoholic')) {
      const restrictions = {
        id: uuid(),
        alcoholic: fieldUpdate.alcoholic
      }

      editingVariant.restrictions = restrictions
    }
    variantsCopy.push({ ...editingVariant, updated_at: 'now()' })
    setVariantsList(SortVariantsByOptions([...variantsCopy]))
    setInvalidCalorieRows([...invalidCalorieRowsClone])
    setInvalidLimitRows([...invalidLimitRowsClone])
  }

  const saveChanges = () => {
    upsertProductVariants({
      variables: {
        values: variantsList
      }
    })
      .then((res) => {
        message.destroy()
        message.success('Variants updated!', 3)
        client.cache.reset()
      })
      .catch((e: Error) => {
        message.destroy()
        message.error(`Unable to update variants due to ${error}`, 3)
      })
  }

  const resetChanges = () => {
    setVariantsList(originalVariantsList)
    setInvalidCalorieRows([])
    setInvalidLimitRows([])
  }
  const saveButtonDisabled =
    invalidCalorieRows.length > 0 || invalidLimitRows.length > 0

  const columns = [
    {
      title: 'Variants',
      key: 'key',
      render: ({ id, options }: NormalizedVariant) => {
        const variantOptions = (options || []).map(({ value }: Option) => value)
        return (
          <>
            <VariantNameAndAvatar
              id={id}
              name={baseName}
              testId='variants-list-avatar'
              options={variantOptions}
            />
          </>
        )
      }
    },
    {
      title: 'SKU',
      key: 'sku',
      render: ({ id, sku }: NormalizedVariant) => {
        return (
          <Input
            defaultValue={sku}
            placeholder='SKU'
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              updateVariant(id, { sku: e.target.value })
            }
          />
        )
      }
    },
    {
      title: 'Calories',
      key: 'calories',
      render: ({ id, calorie_data }: NormalizedVariant) => {
        return (
          <InputNumber
            min={0}
            defaultValue={calorie_data?.calories_per_serving}
            value={calorie_data?.calories_per_serving}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              const value = isValidFloat(e.target.value) ? e.target.value : ''
              updateVariant(id, { calories_per_serving: value })
            }}
          />
        )
      }
    },
    {
      title: 'Order limit',
      key: 'order_limit',
      render: ({ id, limit }: NormalizedVariant) => {
        return (
          <InputNumber
            min={0}
            defaultValue={limit}
            value={limit}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              const value = isValidFloat(e.target.value) ? e.target.value : 0
              updateVariant(id, { limit: value })
            }}
          />
        )
      }
    },
    {
      title: 'Age verification',
      key: 'age_verification',
      align: 'center',
      render: ({ id, restrictions }: NormalizedVariant) => {
        return (
          <Switch
            defaultChecked={restrictions?.alcoholic}
            checked={restrictions?.alcoholic}
            onChange={(value) => updateVariant(id, { alcoholic: value })}
          />
        )
      }
    }
  ]
  return (
    <>
      <ActionsBanner
        saveButtonDisabled={saveButtonDisabled}
        saveHandler={saveChanges}
        discardHandler={resetChanges}
        compact={true}
      />
      <br />
      <Table
        columns={columns}
        dataSource={variantsList}
        rowKey='id'
        pagination={false}
      />
    </>
  )
}

export default VariantsList
