import React from 'react'
import styled from '@emotion/styled'

import { useApp } from '../contexts/App'
import { FaDesktop, FaMobile, FaTablet } from '../components/Icon'
import { Tooltip } from '../components/Tooltip'
import { DEVICE_WIDTHS } from './constants'
import { Divider } from 'antd'

const ViewportSettings: React.FC = () => {
  const { device, handleActiveDevice } = useApp()

  return (
    <Container>
      <FitToScreen
        onClick={() => handleActiveDevice('fts')}
        isActive={device === 'fts' || device === 'fallback'}
      >
        Fit To Screen
      </FitToScreen>
      <Divider type='vertical' />
      <ViewportList>
        <Tooltip text='Mobile' attachment='bottom center'>
          <ViewportItem
            isActive={device === 'xs'}
            onClick={() => handleActiveDevice('xs')}
          >
            <FaMobile />
          </ViewportItem>
        </Tooltip>

        <Tooltip
          text={`Tablet (>= ${DEVICE_WIDTHS.md}px)`}
          attachment='bottom center'
        >
          <ViewportItem
            isActive={device === 'md'}
            onClick={() => handleActiveDevice('md')}
          >
            <FaTablet />
          </ViewportItem>
        </Tooltip>

        <Tooltip
          text={`Desktop (>= ${DEVICE_WIDTHS.lg}px)`}
          attachment='bottom center'
        >
          <ViewportItem
            isActive={device === 'lg'}
            onClick={() => handleActiveDevice('lg')}
          >
            <FaDesktop />
          </ViewportItem>
        </Tooltip>
      </ViewportList>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 2px;
  height: 24px;
  padding: 4px 0;
`

const ViewportList = styled.div`
  display: flex;
  align-items: center;
`

const FitToScreen = styled.div<{ isActive: boolean }>`
  padding: 4px 8px;

  text-transform: uppercase;
  font-weight: 600;
  transition: font-weight 100ms;
  cursor: pointer;
  color: ${({ theme, isActive }: any) =>
    isActive ? theme.colors.secondary : theme.colors.gray400};
`

const ViewportItem = styled.button<{ isActive: boolean }>`
  color: ${({ theme, isActive }: any) =>
    isActive ? theme.colors.gray900 : theme.colors.gray400};
  padding: 8px;
  background: transparent;
  border: 0;
  cursor: pointer;
`

export { ViewportSettings }
