import { useMemo, useRef, useEffect } from 'react'
import { Store } from '@slerp/controls'
import debounce from 'lodash/debounce'
import { useLazyQuery } from '@apollo/client'
import {
  PREVIEW_MILES_DELIVERY_AREA,
  PREVIEW_MINUTES_DELIVERY_AREA
} from 'components/Locations/LocationQueries'
import { message } from 'antd'

export const MIN_MINUTES = 4
export const MIN_MILES = 0.5

export const usePreviewDelivery = ({
  onCompleted,
  store
}: {
  store: Store
  onCompleted: (data: any) => void
}) => {
  const [previewMiles] = useLazyQuery(PREVIEW_MILES_DELIVERY_AREA, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError: (err) => {
      message.destroy()
      message.error(err.message)
    }
  })
  const [previewMinutes] = useLazyQuery(PREVIEW_MINUTES_DELIVERY_AREA, {
    fetchPolicy: 'no-cache',
    onCompleted,
    onError: (err) => {
      message.destroy()
      message.error(err.message)
    }
  })

  const previewArea = useMemo(
    () =>
      debounce(async (value: number, isByTime: boolean) => {
        const storeId = store.id
        const lat = store.address.geom.coordinates[0]
        const lng = store.address.geom.coordinates[1]

        const variables = { storeId, lat, lng, minutes: value, miles: value }

        const previewArea = isByTime ? previewMinutes : previewMiles

        previewArea({ variables })
      }, 1000),
    [store?.address?.geom?.coordinates, store.id, previewMiles, previewMinutes]
  )

  return previewArea
}

export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
