import React from 'react'
import { Col, Divider } from 'antd'
import { AppSettings } from 'types'
import Product from './Product'
import OrderNow from './OrderNow'
import Loyalty from './Loyalty'

interface Props {
  appSettings: AppSettings
  onChange: (values: AppSettings) => void
}

const Designer = (props: Props) => {
  const { appSettings, onChange } = props

  return (
    <>
      <Col span={12}>
        <Product appSettings={appSettings} onChange={onChange} />
        <Col span={12}>
          <Divider />
        </Col>
        <Loyalty appSettings={appSettings} onChange={onChange} />
        <Col span={12}>
          <Divider />
        </Col>
        <OrderNow appSettings={appSettings} onChange={onChange} />
      </Col>
    </>
  )
}

export default Designer
