import React, { useState } from 'react'
import moment from 'moment'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Charge, OrderInfo, Refund } from 'components/Orders/Info/type'
import {
  GET_MERCHANT_CHARGES,
  GET_CHARGES
} from 'components/Orders/OrderQueries'
import { getChargeDescription, getChargeStatus, toFixed } from './utils'
import { Table } from 'antd'

interface ChargesInformationProps {
  order: OrderInfo
  reloadOrder: () => void
}

const ChargesInformation = ({ order }: ChargesInformationProps) => {
  const [charges, setCharges] = useState<Array<Charge>>([])
  const [refunds, setRefunds] = useState<Refund[]>([])

  const [getRefunds] = useLazyQuery(GET_CHARGES, {
    variables: { orderId: order.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRefunds(data?.refunds)
    }
  })

  const { loading } = useQuery(GET_MERCHANT_CHARGES, {
    variables: { orderId: order.id },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (
        data.merchant_charges.some(
          (charge: Charge) => charge.charge_type === 'refund_application_fee'
        )
      ) {
        getRefunds()
      }

      setCharges(data.merchant_charges)
    }
  })

  const columns = [
    {
      title: 'Date',
      dataIndex: 'inserted_at',
      key: 'inserted_at',
      render: (inserted_at: string) => (
        <span>{moment(inserted_at).format('DD/MM/YYYY')}</span>
      )
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: string) => <span>£{toFixed(amount)}</span>
    },
    {
      title: 'Description',
      dataIndex: 'charge_type',
      key: 'amcharge_typeount',
      render: (_charge_type: string, charge: Charge) => (
        <span>{getChargeDescription(charge, order, refunds)}</span>
      ),
      width: '50%'
    },
    {
      title: 'Status',
      dataIndex: 'reverted_at',
      key: 'reverted_at',
      render: (reverted_at: string) => (
        <span>{getChargeStatus(reverted_at)}</span>
      )
    }
  ]

  return (
    <>
      <Table
        data-testid='additional-charges-table'
        dataSource={charges}
        columns={columns}
        pagination={{
          defaultPageSize: 5,
          position: ['bottomCenter'],
          showSizeChanger: true
        }}
        loading={loading}
        rowKey='id'
      />
    </>
  )
}

export default ChargesInformation
