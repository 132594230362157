import React from 'react'
import { Col, Divider, Row } from 'antd'
import VariantPricingForm from './Form/VariantPricing'
import GlobalPricingRow from './GlobalPricingRow'
import { Pricing, GlobalPricing, Option } from './types'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'

interface CorePricingFormProps {
  disabled: boolean
  variantId: string
  pricing: Pricing
  globalPricing: GlobalPricing
  tableEnabled: boolean
  showGlobalPrice: boolean
  isPreorder: boolean
  updateGrossPriceHandler: (val: number | null) => void
  updateDefaultVatHandler: (val: number | null) => void
  reload: () => void
  variantBasicInfo: {
    product: { name: string }
    options: Option[]
  }
}

const CorePricingRow = ({
  variantBasicInfo,
  disabled,
  variantId,
  pricing,
  globalPricing,
  tableEnabled,
  showGlobalPrice,
  isPreorder,
  reload,
  updateGrossPriceHandler,
  updateDefaultVatHandler
}: CorePricingFormProps) => {
  const { product = {}, options = [] } = variantBasicInfo || {}
  const baseProductName = product?.name || ''
  const optionNames = (options || []).map(({ value }: Option) => value)

  const Header = () => (
    <Row
      align='middle'
      className='header'
      data-testid='location-inventory-pricing-table'
    >
      <Col span={11}>Product Name</Col>
      <Col span={13}>
        <Row gutter={[8]} align='middle' className='_mb-0'>
          <Col span={8} data-testId='core-pricing-row-price-header'>
            Price
          </Col>
          <Col span={8} data-testId='core-pricing-row-vat-header'>
            VAT
          </Col>
          <Col span={8} data-testId='core-pricing-row-vat-ex-header'>
            Price excl. VAT
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <>
      <Header />
      <Row className='_mb-0'>
        <Col span={11}>
          <VariantNameAndAvatar
            id={variantId}
            name={baseProductName}
            options={optionNames}
            testId='core-pricing-row-variant-name'
          />
        </Col>
        <Col span={13}>
          <VariantPricingForm
            disabled={disabled}
            pricing={pricing}
            updateGrossPriceHandler={updateGrossPriceHandler}
            updateDefaultVatHandler={updateDefaultVatHandler}
            reset={() => reload()}
          />
        </Col>
      </Row>

      {showGlobalPrice && (
        <>
          <Divider className='_br-cl-light-gray-green _mb-0' />
          <GlobalPricingRow
            variantId={variantId}
            variantName={baseProductName}
            options={optionNames}
            pricing={globalPricing}
          />
        </>
      )}
    </>
  )
}

export default CorePricingRow
