import React, { useState, useEffect, createRef } from 'react'
import { useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  message,
  Button,
  Col,
  Row,
  DatePicker,
  Descriptions,
  Form,
  Alert
} from 'antd'
import { UPDATE_ORDER_TIMESLOT } from './OrderQueries'
import { EditOutlined } from '@ant-design/icons'
import { OrderDetailsProps } from './types'
import moment, { Moment } from 'moment'
import { disabledDate, generatePov3Timeslots } from './Helpers'

const { useForm } = Form

export interface Timeslot {
  value: string
  range: string
}

interface formTypes {
  fulfillmentDate?: Moment
}

interface OrderFetchType {
  ordersListRefetch?: () => {}
  orderRefetch?: () => {}
}

const FulfillmentTimeSlot = (props: OrderFetchType & OrderDetailsProps) => {
  const dropdown = createRef<HTMLSelectElement>()
  const { order, ordersListRefetch, orderRefetch } = props
  const { user } = useSession()
  const { transaction_id, store, merchant } = order
  const [form] = useForm()
  const [showForm, setShowForm] = useState(false)
  const [newFulfillmentDate, setNewFulfillmentDate] = useState<Date | null>(
    null
  )
  const [timeslots, setTimeslots] = useState<Timeslot[]>([])
  const [selectedTimeslot, setSelectedTimeslot] = useState<string>('')

  const [updateOrderTimeslot] = useMutation(UPDATE_ORDER_TIMESLOT, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (!newFulfillmentDate) return setTimeslots([])

    return setTimeslots(
      generatePov3Timeslots(store, newFulfillmentDate, new Date())
    )
  }, [newFulfillmentDate, store])

  const updateOrderTimeslotHandler = (values: formTypes) => {
    const { fulfillmentDate } = values
    updateOrderTimeslot({
      variables: {
        transactionId: transaction_id,
        fulfillmentDate:
          fulfillmentDate?.format('YYYY-MM-DD') ||
          moment(newFulfillmentDate).format('YYYY-MM-DD'),
        // used state as i cant seem to pull value for fulfillmentTime from the form
        fulfillmentTime: moment(selectedTimeslot).format('HH:mm'),
        userId: user.id
      }
    }).then((response) => {
      message.success(
        `Timeslot has been updated for order ${transaction_id}!`,
        1
      )
      ordersListRefetch && ordersListRefetch()
      orderRefetch && orderRefetch()
    })
  }

  return (
    <React.Fragment>
      {showForm ? (
        <Form
          form={form}
          // ref={formRef}
          onFinish={updateOrderTimeslotHandler}
        >
          <Descriptions title='Change Fulfillment Time' />
          <Row gutter={[8, 16]}>
            <Col>
              <Form.Item name='fulfillmentDate' label='Date'>
                <DatePicker
                  format='DD-MM-YYYY'
                  disabledDate={(value) =>
                    disabledDate(
                      value.toDate(),
                      moment().toDate(),
                      store,
                      merchant.pre_order_settings[0],
                      order
                    )
                  }
                  value={moment(newFulfillmentDate)}
                  placeholder='Start date'
                  style={{ width: 170 }}
                  onChange={(value) => {
                    setNewFulfillmentDate(value ? value.toDate() : value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name='fulfillmentTime' label='Time'>
                {/* had issues with changing select value when date is changed when I used antd's Select component */}
                <select
                  ref={dropdown}
                  name='fulfillmentTime'
                  style={{
                    padding: '4px 11px',
                    borderColor: '#d9d9d9',
                    background: 'white',
                    borderRadius: '2px'
                  }}
                  defaultValue={''}
                  value={selectedTimeslot}
                  onChange={(e) => setSelectedTimeslot(e.target.value)}
                  required
                >
                  <option key='disabledOption' value={''}>
                    {timeslots?.length > 0
                      ? 'Select timeslot'
                      : 'No available timeslots'}
                  </option>
                  {timeslots &&
                    timeslots.length > 0 &&
                    timeslots.map((slot: Timeslot, index: number) => (
                      <option key={index} value={slot.value}>
                        {slot.range}
                      </option>
                    ))}
                </select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Alert
              message='Note, if you use SlerpComposer and have already imported your order, you need to change the date in SlerpComposer as well.'
              description={
                <>
                  <span>
                    To learn more about Composer please click{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.slerp.com/project/ordermanagement-composer/'
                    >
                      here
                    </a>
                  </span>
                </>
              }
              type='warning'
              showIcon
            />
          </Row>
          <Row justify='end' gutter={16} style={{ margin: 10 }}>
            <Col>
              <Button
                title='cancel'
                type='default'
                size='small'
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                key='submit'
                type='primary'
                size='small'
                htmlType='submit'
                disabled={!newFulfillmentDate || !selectedTimeslot}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <React.Fragment>
          <Button
            type='primary'
            shape='circle'
            size='small'
            icon={<EditOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => setShowForm(true)}
          />
          <span>Change fulfillment time </span>
          <a
            href='https://support.slerp.com/knowledge/change-fulfillment-time'
            target='_blank'
            rel='noopener noreferrer'
            className='_ml-8 fulfillment-change-help _underline'
          >
            learn more
          </a>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default FulfillmentTimeSlot
