import { gql } from '@apollo/client'

const GET_DELIVERY_PRICING_BANDS = gql`
  query GetDeliveryPricingBands($storeId: uuid!, $orderType: OrderType!) {
    getDeliveryPricingBands(storeId: $storeId, orderType: $orderType) {
      id
      lowerThreshold
      upperThreshold
      percentageDiscount
    }
  }
`

const CREATE_DELIVERY_PRICING_BAND = gql`
  mutation AddDeliveryPricingBand(
    $storeId: uuid!
    $lowerThreshold: Decimal!
    $upperThreshold: Decimal
    $percentageDiscount: Int!
    $orderType: OrderType!
  ) {
    addDeliveryPricingBand(
      storeId: $storeId
      lowerThreshold: $lowerThreshold
      upperThreshold: $upperThreshold
      percentageDiscount: $percentageDiscount
      orderType: $orderType
    ) {
      id
      lowerThreshold
      upperThreshold
      percentageDiscount
    }
  }
`

const UPDATE_DELIVERY_PRICING_BAND = gql`
  mutation UpdateDeliveryPricingBand(
    $storeId: uuid!
    $orderType: OrderType!
    $id: uuid!
    $lowerThreshold: Decimal
    $upperThreshold: Decimal
    $percentageDiscount: Int
  ) {
    editDeliveryPricingBand(
      storeId: $storeId
      orderType: $orderType
      id: $id
      lowerThreshold: $lowerThreshold
      upperThreshold: $upperThreshold
      percentageDiscount: $percentageDiscount
    ) {
      id
      lowerThreshold
      upperThreshold
      percentageDiscount
    }
  }
`

const REMOVE_DELIVERY_PRICING_BAND = gql`
  mutation RemoveDeliveryPricingBand(
    $storeId: uuid!
    $orderType: OrderType!
    $id: uuid!
  ) {
    removeDeliveryPricingBand(
      storeId: $storeId
      orderType: $orderType
      id: $id
    ) {
      id
      lowerThreshold
      upperThreshold
      percentageDiscount
    }
  }
`

const GET_MERCHANT_PRICING_BANDS = gql`
  query GetMerchantPricingBands($merchantId: uuid!) {
    merchants(where: { id: { _eq: $merchantId } }) {
      stores(where: { archived_at: { _is_null: true } }) {
        store_order_type_settings {
          store_id
          order_type
          store_delivery_settings {
            delivery_pricing_bands
          }
        }
      }
    }
  }
`

const UPSERT_DELIVERY_PRICING_BANDS = gql`
  mutation UpsertDeliveryPricingBands(
    $storeId: uuid!
    $orderType: OrderType!
    $deliveryPricingBands: [DeliveryPricingBandParam]!
  ) {
    setDeliveryPricingBands(
      deliveryPricingBands: $deliveryPricingBands
      orderType: $orderType
      storeId: $storeId
    ) {
      id
      lowerThreshold
      upperThreshold
      percentageDiscount
    }
  }
`

export {
  CREATE_DELIVERY_PRICING_BAND,
  GET_DELIVERY_PRICING_BANDS,
  UPDATE_DELIVERY_PRICING_BAND,
  REMOVE_DELIVERY_PRICING_BAND,
  GET_MERCHANT_PRICING_BANDS,
  UPSERT_DELIVERY_PRICING_BANDS
}
