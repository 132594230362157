import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { uniq } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useLazyQuery } from '@apollo/client'
import { QUERY_GET_PRICE_MATRIX } from '../../LocationQueries'
import Loading from '../../../Utils/Loading'
import { QUERY_GET_STORE_SLUG_LOG } from 'components/Locations/Dashboard/DashboardQueries'

const VEHICLE_TYPES = ['bike', 'motorbike', 'car']
const KILOMETER_TO_MILE = 0.62

const initialValues = {
  car: { 2: '1' },
  motorbike: { 2: '1.1' },
  bike: { 2: '1.2' }
}

interface PriceMatrix {
  [key: string]: { [key: number]: string }
}

const PricingTable = () => {
  const { id, slug } = useParams()
  const history = useHistory()
  const [priceMatrix, setPriceMatrix] = useState<PriceMatrix>(initialValues)

  const { data, loading } = useQuery(QUERY_GET_PRICE_MATRIX, {
    variables: { id }
  })

  // Checks slug has been change
  const [getLog, { data: storeSlugData }] = useLazyQuery(
    QUERY_GET_STORE_SLUG_LOG,
    {
      variables: { storeSlug: slug }
    }
  )

  useEffect(() => {
    getLog()
  }, [getLog])

  useEffect(() => {
    if (storeSlugData?.slug_logs?.length) {
      const store = storeSlugData.slug_logs[0].store
      const currentLocation = window.location.pathname.match(
        /slerp-optimize-delivery-pricing.*/
      )
      if (store.slug !== slug) {
        // redirects to new slug
        history.replace(`/location/${store.slug}/${currentLocation}`)
      }
    }
  }, [storeSlugData])

  useEffect(() => {
    if (data?.price_matrices.length > 0) {
      setPriceMatrix(data.price_matrices[0].data)
    }
  }, [data])

  const dataSource = VEHICLE_TYPES.reduce(
    (acc: { [key: number]: string }[], vehicle: string) => {
      return [
        ...acc,
        {
          vehicle:
            vehicle === 'car'
              ? 'Car/Small Van'
              : vehicle[0].toUpperCase() + vehicle.slice(1),
          ...priceMatrix[vehicle]
        }
      ]
    },
    []
  )

  // creates an object that contains the kilometers data as keys and values
  const kilometers = Object.entries(priceMatrix['car']).reduce(
    (acc: { [key: number]: string }, curr: [string, string]) => ({
      ...acc,
      [curr[0]]: curr[0]
    }),
    {}
  )

  // adds a custom vehicle key for the kilometers object
  const kilometersAddCustomVehicleKey = {
    ...kilometers,
    vehicle: 'Distance(km)'
  }

  // inserts kilometers(at the beginning) along with dataSource, this will show it as the first row of our table
  const dataSourceWithKilometers = [
    kilometersAddCustomVehicleKey,
    ...dataSource
  ]

  const allKeys = VEHICLE_TYPES.reduce((acc: string[], vehicle: string) => {
    return [...acc, ...Object.keys(priceMatrix[vehicle])]
  }, [])

  const distanceKeys = uniq(allKeys)
    .sort((a, b) => a - b)
    .map((key) => ({
      key: key,
      dataIndex: key,
      title: (
        <div className='max-content'>
          {(+key * KILOMETER_TO_MILE).toFixed(1)}
        </div>
      )
    }))

  const columns = [
    {
      key: 'vehicle',
      title: <div className='max-content'>Distance(mi)</div>,
      dataIndex: 'vehicle',
      width: 200,
      scroll: {
        x: true
      }
    },
    ...distanceKeys
  ]

  return loading ? (
    <Loading />
  ) : (
    <Table
      dataSource={dataSourceWithKilometers}
      columns={columns}
      pagination={false}
      className='rate-card'
    />
  )
}

export default PricingTable
