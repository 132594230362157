import { createClient } from '@slerp/client'
import {
  UPLOAD_PRODUCT_IMAGE,
  UPLOAD_ADDITIONAL_PRODUCT_IMAGES,
  UPLOAD_SINGLE_ADDITIONAL_PRODUCT_IMAGE,
  REMOVE_IMAGE
} from '../ProductQueries'

interface UploadProductImageProps {
  variantId: string
  image: File
}

const apiKey = localStorage.getItem('token') || ''
const UploadProductImage = async ({
  variantId,
  image
}: UploadProductImageProps) => {
  const client = createClient(apiKey)
  const variables = {
    variantId,
    image,
    override: true
  }

  return client.mutate({
    mutation: UPLOAD_PRODUCT_IMAGE,
    variables
  })
}

interface UploadAdditionalImagesProps {
  variantId: string
  images: File[]
}

export const UploadAdditionalImages = async ({
  variantId,
  images
}: UploadAdditionalImagesProps) => {
  const client = createClient(apiKey)
  const variables = {
    variantId,
    img1: images[0],
    img2: images[1],
    img3: images[2],
    img4: images[3]
  }

  return client.mutate({
    mutation: UPLOAD_ADDITIONAL_PRODUCT_IMAGES,
    variables
  })
}

interface UploadAdditionalImageProps {
  variantId: string
  image: File
}

// Being used in single upload
export const UploadAdditionalImage = async ({
  variantId,
  image
}: UploadAdditionalImageProps) => {
  const client = createClient(apiKey)
  const variables = {
    variantId,
    img1: image
  }

  return client.mutate({
    mutation: UPLOAD_SINGLE_ADDITIONAL_PRODUCT_IMAGE,
    variables
  })
}

interface RemoveImageProps {
  imageId: string
}

export const RemoveImage = async ({ imageId }: RemoveImageProps) => {
  const client = createClient(apiKey)
  const variables = {
    imageId
  }
  return client.mutate({
    mutation: REMOVE_IMAGE,
    variables
  })
}

export default UploadProductImage
