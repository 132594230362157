import React, { ReactNode, useState } from 'react'
import { Drawer } from 'antd'
import OrderInfo from './OrderInfo'
import OrderInformation from './Info'

interface OrderDrawerProps {
  order: any
  onClose: any
  visible: boolean
  ordersRefetch?: () => {}
  ordersDataRefetch?: () => {}
  redesignEnabled?: boolean
}

const OrderDrawer = (props: OrderDrawerProps) => {
  const {
    order,
    onClose,
    ordersRefetch,
    visible,
    ordersDataRefetch,
    redesignEnabled
  } = props

  const [footerEntries, setFooterEntries] = useState<
    ReactNode | ReactNode[] | null
  >(null)

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      className='order-drawer'
      footer={
        <>
          {footerEntries}
          <p className='order-drawer-footer'>
            * Slerp fees will be deducted in your monthly statement
          </p>
          <p className='order-drawer-footer'>
            * Delivery fee is based on the provided delivery location, in the
            case of an incorrect delivery location, the courier company may
            change the delivery estimate, contact the customer or subsequently
            cancel the delivery.
          </p>
        </>
      }
    >
      {order && !redesignEnabled && (
        <OrderInfo
          order={order}
          ordersRefetch={ordersRefetch}
          ordersDataRefetch={ordersDataRefetch}
        />
      )}
      {order && redesignEnabled && (
        <OrderInformation
          transactionId={order.transaction_id}
          refetchCallback={() => {
            ordersRefetch && ordersRefetch()
            ordersDataRefetch && ordersDataRefetch()
          }}
          setFooterEntries={setFooterEntries}
        />
      )}
    </Drawer>
  )
}

export default OrderDrawer
