import React, { useState, useEffect } from 'react'
import { Table, Tag, Button, Row, Select, Col, Input, Tooltip } from 'antd'
import {
  PlusOutlined,
  SearchOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import {
  QUERY_MERCHANT_LOCATIONS,
  MerchantLocationResponseType
} from './LocationQueries'
import { useQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import FormattedDate from '../Utils/FormattedDate'
import { SelectPrefixWrapper } from '../Widgets'
import { FilterIcon, SelectDownArrowIcon } from '@slerp/assets'
import debounce from 'lodash/debounce'
import { Store } from '@slerp/controls'

interface PreOrderSettings {
  delivery_enabled: boolean
  pickup_enabled: boolean
}

const { Column } = Table
const { Option } = Select

const LocationsList = () => {
  const { merchant } = useSession()
  const { data, loading } = useQuery<MerchantLocationResponseType>(
    QUERY_MERCHANT_LOCATIONS,
    {
      variables: { slug: merchant.slug },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }
  )

  const [isArchived, setIsArchived] = useState(0)

  const stores =
    data &&
    data.merchants[0].stores.filter((store) =>
      isArchived ? !!store.archived_at : !store.archived_at
    )

  const [locations, setLocations] = useState<Array<Store>>(stores as Store[])
  const [fuzzySearchKeyword, setFuzzySearchKeyword] = useState('')

  const filterStores = (stores: Store[]) => {
    if (!fuzzySearchKeyword) return stores

    const parsedFilter = fuzzySearchKeyword.replace(/\s/g, '').toLowerCase()
    return stores.filter((store) => {
      const nameQuery = store?.name?.toLowerCase().includes(parsedFilter)
      const zipQuery = store?.address?.zip.toLowerCase().includes(parsedFilter)

      return nameQuery || zipQuery
    })
  }

  useEffect(() => {
    if (data) return setLocations(stores as Store[])
  }, [data, isArchived])

  useEffect(() => {
    if (data) {
      setLocations(filterStores(stores as Store[]))
    }
  }, [fuzzySearchKeyword])

  const debouncedFuzzySearchKeyword = debounce(setFuzzySearchKeyword, 200)

  return (
    <>
      <Row align='middle' className='_mb-16'>
        <Col span={12}>
          <SelectPrefixWrapper
            prefixicon={<FilterIcon />}
            style={{ marginRight: '24px' }}
          >
            <Select
              data-testid='stores-tab-select'
              suffixIcon={<SelectDownArrowIcon />}
              defaultValue={0}
              onChange={(value: number) => setIsArchived(value)}
              value={isArchived}
            >
              <Option key='active' value={0}>
                Active Locations
              </Option>
              <Option key='archived' value={1}>
                Archived Locations
              </Option>
            </Select>
          </SelectPrefixWrapper>
        </Col>

        <Col span={12}>
          <Row align='middle' justify='end' gutter={8}>
            <Col>
              <Input
                data-testid='products-search-filter'
                prefix={<SearchOutlined />}
                placeholder='Search'
                onChange={(e) => debouncedFuzzySearchKeyword(e.target.value)}
              />
            </Col>
            <Col>
              <Link
                to='/locations/new'
                className='btn-text _link-white _ml-12 _ml-auto'
              >
                <Button
                  className='_center-vertical _uppercase'
                  icon={<PlusOutlined />}
                >
                  Add Location
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        data-testid='location-list-component'
        dataSource={locations}
        pagination={false}
        loading={loading}
      >
        <Column
          title={
            <>
              Location
              <Tooltip
                title={
                  <span>
                    Click the location name to manage settings, inventory and
                    users.
                  </span>
                }
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          dataIndex='name'
          key='name'
          render={(text: string, record: Store) => {
            return (
              <Link to={`/location/${record.slug}`}>
                <strong className='_link-primary'>
                  {text}
                  <br />
                </strong>
                {record.address && record.address.zip}
              </Link>
            )
          }}
        />
        <Column
          title='Order at Table'
          dataIndex={['settings', 'table_enabled']}
          render={(value: string) => (
            <>
              {value ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>}
            </>
          )}
        />
        <Column
          title='Same Day Delivery'
          dataIndex={['settings', 'same_day_delivery']}
          render={(value: string) => (
            <>
              {value ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>}
            </>
          )}
        />
        <Column
          title='Same Day Pick-up'
          dataIndex={['settings', 'same_day_pickup']}
          render={(value: string) => (
            <>
              {value ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>}
            </>
          )}
        />
        <Column
          title='Pre-Order Delivery'
          dataIndex={['pre_order_settings']}
          render={(value: Array<PreOrderSettings>) => (
            <>
              {value[0]?.delivery_enabled ? (
                <Tag color='green'>Yes</Tag>
              ) : (
                <Tag color='red'>No</Tag>
              )}
            </>
          )}
        />
        <Column
          title='Pre-Order Pickup'
          dataIndex={['pre_order_settings']}
          render={(value: Array<PreOrderSettings>) => {
            return (
              <>
                {value[0]?.pickup_enabled ? (
                  <Tag color='green'>Yes</Tag>
                ) : (
                  <Tag color='red'>No</Tag>
                )}
              </>
            )
          }}
        />
        <Column
          title='Date Created'
          dataIndex={'inserted_at'}
          render={(value: string) => <FormattedDate>{value}</FormattedDate>}
        />
        {isArchived && (
          <Column
            title='Date Archived'
            dataIndex={'archived_at'}
            render={(value: string) =>
              value && <FormattedDate>{value}</FormattedDate>
            }
          />
        )}
      </Table>
    </>
  )
}

export default LocationsList
