import React from 'react'
import { Button, Form, Input, message } from 'antd'
import { useMutation, useApolloClient } from '@apollo/client'
import { Store } from 'antd/lib/form/interface'
import { ADD_VARIANT_TO_GROUP } from '../VariantQueries'
import { variantGroupOptionValidator } from './rules'
import { ProductVariant, Option } from '../types'

interface VariantOptionFormProps {
  productId: string
  variantGroupName: string
  discardHandler: () => void
  variantGroupOptions: string[]
  variants: ProductVariant[]
}

const { useForm } = Form

const VariantOptionForm = ({
  productId,
  variantGroupName,
  discardHandler,
  variantGroupOptions,
  variants
}: VariantOptionFormProps) => {
  const testIdKey = 'product-variant-option-form'
  const [form] = useForm()
  const initialValues = {
    group_name: variantGroupName
  }
  const client = useApolloClient()

  const [addVariantToGroup, { loading: addingVariantToGroup }] =
    useMutation(ADD_VARIANT_TO_GROUP)

  const addOptionToVariantGroup = (values: Store) => {
    const { option_name } = values

    addVariantToGroup({
      variables: {
        optionName: variantGroupName,
        optionValue: option_name,
        productId
      }
    })
      .then((result) => {
        client.resetStore()
        message.destroy()
        message.success(
          `Successfully added option ${option_name} to ${variantGroupName}.`
        )
      })
      .catch((error: Error) => {
        message.destroy()
        message.error(
          `Unable to add option ${option_name} to ${variantGroupName} due to ${error.message}`,
          3
        )
      })
  }
  const activeVariantOptions = variants
    .map(({ options }: ProductVariant) =>
      options.find(({ name }: Option) => name === variantGroupName)
    )
    .map(({ value }: Option) => value.toLowerCase())

  return (
    <Form
      form={form}
      labelCol={{
        span: 4
      }}
      wrapperCol={{
        span: 8
      }}
      data-testid={`${testIdKey}`}
      initialValues={initialValues}
      onFinish={addOptionToVariantGroup}
    >
      <Form.Item name='group_name' label='Group name'>
        <Input disabled data-testid={`${testIdKey}-group-name`} />
      </Form.Item>
      <Form.Item
        name='option_name'
        rules={variantGroupOptionValidator(
          productId,
          variantGroupName,
          activeVariantOptions
        )}
        label='Option name'
      >
        <Input data-testid={`${testIdKey}-option-name`} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 8,
          offset: 4
        }}
      >
        <Button
          type='ghost'
          disabled={addingVariantToGroup}
          className='_uppercase _mr-8'
          data-testid='discount-discard-button'
          onClick={discardHandler}
        >
          Discard
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          disabled={addingVariantToGroup}
          className='_uppercase'
          data-testid='some-id'
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default VariantOptionForm
