import React from 'react'
import styled from '@emotion/styled'
import { useApp } from '../../contexts/App'
import { SCREENS } from '../constants'

const ConfigureSidebar = () => {
  const { screen, activeToolbar, toggleActiveToolbar } = useApp()
  const mode = 'control'
  const screenData = SCREENS.find(({ key }) => key === screen)
  const ActiveSidebarControl = screenData && screenData.sidebarControl

  return (
    <Container
      activeToolbar={activeToolbar}
      onMouseOver={() => toggleActiveToolbar('configure')}
    >
      <Content activeToolbar={activeToolbar}>
        <ScrollableContent>
          {mode === 'control' && ActiveSidebarControl && (
            <ActiveSidebarControl />
          )}
        </ScrollableContent>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ activeToolbar: 'style' | 'configure' }>`
  position: relative;
  z-index: 1000;

  display: flex;
  padding-top: 8px;

  /* Toolbar label */
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    z-index: 500;

    display: flex;
    content: 'configure';
    padding: 16px 4px;
    border-radius: 16px;
    background-color: white;
    width: auto;

    writing-mode: vertical-rl;
    text-orientation: mixed;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme }: any) => theme.colors.background75};

    transform: translateY(-50%);

    animation: ${({ activeToolbar }) =>
      activeToolbar === 'configure'
        ? 'openConfigureLabel 250ms forwards, activeToolbarLabel 250ms forwards'
        : 'closeConfigureLabel 250ms forwards, inactiveToolbarLabel 250ms forwards'};
  }

  &::before {
    z-index: 1;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
    animation: ${({ activeToolbar }) =>
      activeToolbar === 'configure'
        ? 'openConfigureLabel 250ms forwards, activeToolbarLabel 250ms forwards, openOpacity 250ms forwards'
        : 'closeConfigureLabel 250ms forwards, inactiveToolbarLabel 250ms forwards, closeOpacity 250ms forwards'};
  }
`

const Content = styled.div`
  position: relative;
  z-index: ${({ theme }: any) => theme.zIndex.sidebar};

  display: flex;
  flex-flow: column;
  height: 100%;
  padding: 0 16px 0 24px;
  max-width: 282px;

  @media (min-width: 1025px) {
    max-width: 382px;
  }

  border-right: 1px solid ${({ theme }: any) => theme.colors.gray100};
  background-color: ${({ theme }: any) => theme.colors.white};

  animation: ${({ activeToolbar }) =>
        activeToolbar === 'configure' ? 'openContent' : 'closeContent'}
      250ms forwards,
    ${({ activeToolbar }) =>
        activeToolbar === 'configure' ? 'openOpacity' : 'closeOpacity'}
      250ms forwards;

  overflow-y: scroll;
`

const ScrollableContent = styled.div`
  display: flex;
  flex-flow: column;
`

export { ConfigureSidebar }
