import React from 'react'
import { PageHeader, Space } from 'antd'

interface PageTooltipTypes {
  title: string
  children: React.ReactChild | React.ReactChild[]
}

export const PageTooltip = (props: PageTooltipTypes) => (
  <>
    <PageHeader title={props.title} className='tooltip-title' />
    <Space className='tooltip-link' direction='vertical' align='start'>
      {props.children}
    </Space>
  </>
)
