import {
  AppliedModifier,
  Cart,
  CartSession,
  CartItem,
  AdditionalStoreFee
} from '../Landing/types'
import { Decimal } from 'decimal.js'
import { ISOtoUTC } from './../NavBar/utils'
import { isBefore } from 'date-fns'
import { isEmpty } from 'lodash'

type StoreVariant = {
  variant_id: string
  in_stock: boolean
  published_at?: string
}

type Metadata = {
  pickup_type?: string
  recipient?: string
}

export const filterAlcoholicProducts = (cartItems: CartItem[]) => {
  return cartItems.filter((item: CartItem) => {
    if (
      item.product_variant.restrictions.alcoholic ||
      hasAlcoholicModifier(item.applied_modifiers)
    )
      return item
    return null
  })
}

export const computeModifiersTotal = (modifiers: AppliedModifier[]) => {
  const total = modifiers.reduce((acc: Decimal, modifier: AppliedModifier) => {
    return acc.plus(new Decimal(modifier.amount))
  }, new Decimal(0))

  return total.toDecimalPlaces(2, Decimal.ROUND_HALF_UP).toNumber()
}

export const computeCartTotal = (
  cartItems: CartItem[],
  giftWrapPrice = 0,
  discountAmount = 0,
  deliveryCharge = 0,
  discountTarget: string | null = null,
  additionalStoreFeeAmount = new Decimal(0)
) => {
  const normalizeNegative = (value: Decimal) => {
    return value.lessThan(new Decimal(0)) ? new Decimal(0) : value
  }

  const itemsTotal = computeItemsTotal(cartItems)
  const itemDiscounts =
    discountTarget === 'all_products'
      ? new Decimal(discountAmount)
      : new Decimal(0)

  const deliveryDiscounts =
    discountTarget === 'delivery_fee'
      ? new Decimal(discountAmount)
      : new Decimal(0)
  const deliveryTotal = normalizeNegative(
    new Decimal(deliveryCharge).minus(deliveryDiscounts)
  )

  // revisions needed for different discount targets
  return normalizeNegative(itemsTotal.minus(itemDiscounts))
    .plus(new Decimal(giftWrapPrice))
    .plus(additionalStoreFeeAmount)
    .plus(deliveryTotal)
    .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
    .toNumber()
}

export const computeItemsTotal = (cartItems: CartItem[]) => {
  return cartItems.reduce((acc: Decimal, cartItem: CartItem) => {
    return acc.plus(new Decimal(cartItem.amount))
  }, new Decimal(0))
}

export const computeAdditionalStoreFee = (
  storeFee: AdditionalStoreFee,
  cartItems: CartItem[],
  fulfillmentType: string
) => {
  if (isEmpty(storeFee)) {
    return new Decimal(0)
  }

  const { amount, amount_type, fulfillment_type } = storeFee

  if (!amount || !amount_type || fulfillmentType !== fulfillment_type) {
    return new Decimal(0)
  }

  if (amount_type === 'fixed') {
    return new Decimal(amount)
  }

  const itemsTotal = computeItemsTotal(cartItems)
  const percent = new Decimal(amount).div(new Decimal(100))

  return itemsTotal.mul(percent)
}

export const isCartDateExpired = (cartSession: CartSession) =>
  cartSession.cart &&
  cartSession.cart.deliver_by &&
  isBefore(ISOtoUTC(cartSession.cart.deliver_by.toString()), new Date())

export const isStoreSwitched = (slug: string, cart?: Cart) => {
  if (cart) return !cart.is_preorder && cart.store.slug !== slug

  return false
}

const hasAlcoholicModifier = (modifiers: AppliedModifier[]) => {
  const alcoholicModifiers = modifiers.find(
    (item: any) => item.modifier.restrictions.alcoholic === true
  )

  return alcoholicModifiers ? true : false
}

export const isItemAvailable = (
  storeVariant?: StoreVariant,
  isPreOrder?: boolean
) => {
  if (!storeVariant) return false
  return (
    storeVariant &&
    (storeVariant.in_stock || isPreOrder) &&
    storeVariant.published_at !== null
  )
}

export const checkMinimumOrderValue = (
  fulfillmentType: string,
  cartTotal: number,
  minimumOrderValue?: number | string
) => {
  if (fulfillmentType === 'pickup') return true
  if (!minimumOrderValue) return true

  return cartTotal >= sanitizeNumber(minimumOrderValue)
}

export const formatMinimumOrderValue = (
  minimumOrderValue?: string | number
) => {
  if (!minimumOrderValue) return ''

  return sanitizeNumber(minimumOrderValue).toFixed(2)
}

export const formatMoney = (price: number) =>
  price.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP'
  })

export const sanitizeNumber = (value?: string | number) => {
  if (!value) return Number(0)
  if (typeof value === 'string') return parseFloat(value)
  return value
}

export const isTableOrder = (metadata?: Metadata) => {
  if (!metadata) return false

  return metadata?.pickup_type === 'table'
}

export const getTableNumber = (metadata?: Metadata) => {
  if (!metadata) return ''

  return metadata && metadata.recipient ? metadata.recipient : ''
}
