import React, { useState } from 'react'
import { Col, message, InputNumber, Row, Switch, Typography } from 'antd'
import { Decimal } from 'decimal.js'
import { useMutation } from '@apollo/client'
import { computeNetPrice } from 'components/Utils/price'
import { isValidFloat } from '../utils'
import { UPSERT_VARIANT_PRICING } from '../Queries'
import {
  DeliveryFulfillmentTypePricing,
  PickupFulfillmentTypePricing,
  OrderAtTableFulfillmentTypePricing,
  FulfillmentTypePricing,
  MerchantFlags
} from '../types'
import errorReducer from 'errors/errorReducer'

interface FulfillmentTypePricingFormProps {
  delivery: DeliveryFulfillmentTypePricing
  pickup: PickupFulfillmentTypePricing
  order_at_table: OrderAtTableFulfillmentTypePricing
  successCallbackHandler?: () => void
  flags: MerchantFlags
  variantCoreVat: number
  updateFulfilmentTypePricingHandler: () => void
}

const { Text } = Typography

const FulfillmentTypePricingForm = ({
  delivery,
  pickup,
  order_at_table,
  successCallbackHandler,
  flags,
  variantCoreVat,
  updateFulfilmentTypePricingHandler
}: FulfillmentTypePricingFormProps) => {
  const [showCustomVatForOat, setShowCustomVatForOat] = useState<boolean>(
    order_at_table.vat !== variantCoreVat
  )
  const {
    anyStoresWithDeliveryEnabled: deliveryEnabled,
    anyStoresWithPickupEnabled: pickupEnabled,
    anyStoresWithOrderAtTableEnabled: orderAtTableEnabled
  } = flags
  const [upsertVariantPricing, { loading }] = useMutation(
    UPSERT_VARIANT_PRICING,
    {
      refetchQueries: ['getMerchantProductVariants']
    }
  )

  const updatePrice = (
    pricing: FulfillmentTypePricing,
    price_with_vat: number | null
  ) => {
    const { vat } = pricing
    const newPricingsValues = {
      ...pricing,
      vat,
      price_with_vat,
      price:
        price_with_vat !== null && vat !== null
          ? computeNetPrice(price_with_vat, vat)
          : null,
      inserted_at: 'now()',
      updated_at: 'now()'
    }
    updateFulfilmentTypePricingHandler(newPricingsValues)
  }

  const updateVat = (pricing: FulfillmentTypePricing, vat: number | null) => {
    const { price_with_vat } = pricing
    const newPricingsValues = {
      ...pricing,
      vat,
      price_with_vat,
      price:
        vat !== null && vat !== null
          ? computeNetPrice(price_with_vat, vat)
          : null,
      inserted_at: 'now()',
      updated_at: 'now()'
    }

    message.destroy()
    message.loading('Updating variant pricing... Please Wait.')

    upsertVariantPricing({
      variables: {
        values: [newPricingsValues]
      }
    })
      .then((res) => {
        successCallbackHandler && successCallbackHandler()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Product',
          data: {
            error: error,
            message: error.message
          }
        })
      })
  }

  const toggleCustomVatForOatHandler = (val: boolean) => {
    if (!val) {
      updateVat(order_at_table, variantCoreVat)
    }
    setShowCustomVatForOat(val)
  }

  return (
    <>
      <Row>
        <Col className='_pl-4 _pr-4'>
          <Switch
            disabled={
              loading ||
              !orderAtTableEnabled ||
              order_at_table.price_with_vat == null
            }
            defaultChecked={order_at_table.vat !== variantCoreVat}
            checked={showCustomVatForOat}
            onChange={(val: boolean) => toggleCustomVatForOatHandler(val)}
          />
        </Col>
        <Col>
          <Text>Different VAT for Order at table</Text>
        </Col>
        <Col>
          {showCustomVatForOat && (
            <Row className='_mb-0' justify='end' gutter={[16, 0]}>
              <Col span={15}>
                <InputNumber
                  defaultValue={order_at_table.vat || null}
                  disabled={loading || !orderAtTableEnabled}
                  min={0}
                  className='_w-10'
                  data-testid='products-fulfillment-type-pricing-table-oat-vat'
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    const oatVat = isValidFloat(e.target.value)
                      ? new Decimal(e.target.value || 0).toNumber()
                      : null
                    updateVat(order_at_table, oatVat)
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row
        gutter={[8]}
        align='middle'
        justify='end'
        className='_mb-0'
        data-testid='variant-fulfillment-type-pricing-form'
      >
        <Col span={8}>
          <InputNumber
            min={0}
            precision={2}
            disabled={loading || !deliveryEnabled}
            className='_w-10'
            required
            data-testid='fulfillment-type-price-form-delivery-price'
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              updatePrice(
                delivery,
                isValidFloat(e.target.value)
                  ? new Decimal(e.target.value || 0).toNumber()
                  : null
              )
            }}
            defaultValue={delivery.price_with_vat || undefined}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            min={0}
            disabled={loading || !pickupEnabled}
            precision={2}
            className='_w-10'
            data-testid='fulfillment-type-price-form-pickup-price'
            defaultValue={pickup.price_with_vat || undefined}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              updatePrice(
                pickup,
                isValidFloat(e.target.value)
                  ? new Decimal(e.target.value || 0).toNumber()
                  : null
              )
            }}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            min={0}
            disabled={loading || !orderAtTableEnabled}
            precision={2}
            className='_w-10'
            data-testid='fulfillment-type-price-form-order-at-table-price'
            defaultValue={order_at_table.price_with_vat || undefined}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              updatePrice(
                order_at_table,
                isValidFloat(e.target.value)
                  ? new Decimal(e.target.value || 0).toNumber()
                  : null
              )
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default FulfillmentTypePricingForm
