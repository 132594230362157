import React from 'react'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import { Tabs, Col, Row, Divider } from 'antd'
import { LocationSettingProps } from './Settings'
import StoreDetails from './GeneralSettings/StoreDetails'
import SplitShifts from './GeneralSettings/SplitShifts'
import UsersSettings from './GeneralSettings/UsersSettings'
import AdvancedStoreSettings from './GeneralSettings/StoreSettings/AdvancedStoreSettings'

import { PageTooltip } from '../Widgets'

const { TabPane } = Tabs

const GeneralSettings = (props: LocationSettingProps) => {
  const { store, merchant, refetch } = props
  const { user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const TABS = [
    {
      label: 'Details',
      key: 'details',
      content: <StoreDetails {...{ store, merchant, refetch }} />,
      tooltip: ''
    },
    {
      label: 'Opening Hours',
      key: 'opening-hours',
      content: <SplitShifts {...{ store, refetch, disabled: !canManage }} />,
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/en_us/location-opening-times-BJ5hwriaO'
            target='_blank'
            rel='noopener noreferrer'
          >
            Location Opening Times
          </a>
          <a
            href='https://support.slerp.com/knowledge/special-opening-times'
            target='_blank'
            rel='noopener noreferrer'
          >
            Special opening times
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Users',
      key: 'users',
      content: <UsersSettings {...{ store, merchant, refetch }} />,
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://slerp.kustomer.help/company-locations-logins-SJ978vi6O'
            target='_blank'
            rel='noopener noreferrer'
          >
            Location Logins
          </a>
        </PageTooltip>
      )
    },
    {
      label: 'Advanced Settings',
      key: 'advanced-settings',
      content: <AdvancedStoreSettings {...{ store, merchant, refetch }} />,
      tooltip: (
        <PageTooltip title='Related articles from our Help Centre'>
          <a
            href='https://support.slerp.com/knowledge/additional-fee'
            target='_blank'
            rel='noopener noreferrer'
          >
            Additional Fee
          </a>
          <a
            href='https://support.slerp.com/knowledge/minimum-order-value'
            target='_blank'
            rel='noopener noreferrer'
          >
            Minimum Order Value
          </a>
        </PageTooltip>
      )
    }
  ]

  const defaultPageKey = 'details'
  const keyFromURL = window.location.hash.replace('#', '') || defaultPageKey
  const tabKeys = TABS.map((tab) => tab.key)
  const activeKey = tabKeys.includes(keyFromURL)
    ? keyFromURL.toString()
    : defaultPageKey

  return (
    <>
      <Tabs
        onTabClick={(key) =>
          (window.location.href = `${window.location.pathname}#${key}`)
        }
        defaultActiveKey={activeKey}
        /* I couldn't fix the animation 😿.
        Disabling it for now */
        animated={{ inkBar: false, tabPane: true }}
      >
        {TABS.filter((tab) => !tab.hidden).map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            <Row gutter={40} className='_mt-24 _pl-16'>
              <Col span={15}>{tab.content}</Col>
              <Col>
                <Divider type='vertical' />
              </Col>
              <Col>{tab.tooltip}</Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </>
  )
}
export default GeneralSettings
