import * as React from 'react'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/core'
import memoize from '@emotion/memoize'
import stylisPluginExtraScope from 'stylis-plugin-extra-scope'

/**
 * @source https://github.com/emotion-js/emotion/issues/560#issuecomment-450713994
 */
let memoizedCreateCacheWithScope = memoize((scope) => {
  return createCache({
    stylisPlugins: [stylisPluginExtraScope(scope)],
    // Prefix used as a namespace for the generated css names by emotion
    // https://github.com/emotion-js/emotion/issues/2038#issuecomment-708307204
    key: 'root'
  })
})

interface Props {
  scope: string
}

const ScopeProvider: React.FC<Props> = (props) => {
  return (
    <CacheProvider value={memoizedCreateCacheWithScope(props.scope) as any}>
      {props.children}
    </CacheProvider>
  )
}

export { ScopeProvider }
