import React, { useState, useRef } from 'react'

function useStateRef<T>(
  defaultValue: T
): [T, (value: T) => void, React.MutableRefObject<T>] {
  const [state, internalSetState] = useState<T>(defaultValue)
  const ref = useRef<T>(defaultValue)
  const setState = (value: T) => {
    ref.current = value
    internalSetState(value)
  }
  return [state, setState, ref]
}

export { useStateRef }
