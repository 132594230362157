import React from 'react'
import { Tag, Tooltip } from 'antd'

/** For status filtering  */
const OrderStatusesFilter = () => {
  return [
    {
      text: 'Completed',
      value: 'fulfilled'
    },
    {
      text: 'Accepted',
      value: 'accepted'
    },
    {
      text: 'Fulfilled',
      value: 'fulfilled'
    },
    {
      text: 'Cancelled',
      value: 'cancelled'
    },
    {
      text: 'Pending',
      value: 'pending'
    }
  ]
}

const OrderStatusTag = (status: string) => {
  switch (status) {
    case 'accepted':
      return (
        <Tooltip
          title={
            <span>
              The order has been taken on and is in the process of being
              fulfilled.{' '}
            </span>
          }
        >
          <Tag color='green'>Accepted</Tag>
        </Tooltip>
      )
    case 'fulfilled':
      return (
        <Tooltip
          title={
            <span>The order had been completed and is with the customer. </span>
          }
        >
          <Tag color='blue'>Fulfilled</Tag>
        </Tooltip>
      )
    case 'refunded':
      return (
        <Tooltip title={<span>The order has been refunded.</span>}>
          <Tag color='gray'>Refunded</Tag>
        </Tooltip>
      )
    case 'cancelled':
      return (
        <Tooltip
          title={
            <span>The order has been cancelled by your courier partner.</span>
          }
        >
          <Tag color='red'>Cancelled</Tag>
        </Tooltip>
      )
    case 'pending':
      return (
        <Tooltip
          title={<span>The order has yet to be accepted and processed.</span>}
        >
          <Tag color='yellow'>Pending</Tag>
        </Tooltip>
      )
    case 'rejected':
      return (
        <Tooltip
          title={
            <span>The order was not accepted by a member of your team.</span>
          }
        >
          <Tag color='magenta'>Rejected</Tag>
        </Tooltip>
      )
    default:
      return <Tag color='lime'>{status}</Tag>
  }
}
export { OrderStatusesFilter, OrderStatusTag }
