/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, Global, css } from '@emotion/core'
import 'sanitize.css'

const GlobalStyle: React.FC = () => {
  return (
    <Global
      styles={(css as any)`
        @import url('https://use.typekit.net/tfv8ywh.css');

        * {
          box-sizing: border-box;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      `}
    />
  )
}

export { GlobalStyle }
