import React from 'react'
import { Spin } from 'antd'
import { ApolloError } from '@apollo/client'

export interface UpdateNoticeProps {
  updating: boolean
  updateError?: ApolloError
}

const UpdateNotice = (props: UpdateNoticeProps) => (
  <>
    {props.updating ? (
      <>
        {' '}
        Updating... <Spin />
      </>
    ) : (
      <></>
    )}
    {props.updateError ? (
      <>
        {' '}
        - Error updating... Please try again later. {props.updateError.message}
      </>
    ) : (
      <></>
    )}
  </>
)

export default UpdateNotice
