import moment from 'moment-timezone'
import { StoreType } from './types'

export const isExpired = (
  loyalty_card,
  currentDay,
  timezone = 'Europe/London'
) => {
  const now = currentDay.utc().tz(timezone)
  if (!loyalty_card?.ends_at) return false
  const expiration = moment.utc(loyalty_card.ends_at).tz(timezone)
  return expiration.isBefore(now)
}

export const stringListForMutation = (
  currentList?: string[],
  stringList?: string[]
) => {
  if (!stringList && !currentList) return '{}'

  if (!stringList) return `{${currentList?.join(',')}}`

  return `{${stringList?.join(',')}}`
}

export const filterStoresForQR = (
  stores: StoreType[],
  allStoresEnabled: boolean,
  currentStoreIds?: string[]
) => {
  if (allStoresEnabled) return stores

  if (!currentStoreIds) return stores

  return stores.filter((store: StoreType) =>
    currentStoreIds?.includes(store.id)
  )
}

export const BulkRewardImportFile = (file: File) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.readAsText(file)
    fileReader.onloadend = () => {
      return resolve(fileReader.result as string)
    }
  })
}
