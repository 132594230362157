import React, { SyntheticEvent } from 'react'
import { Input, Spin, message } from 'antd'
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete'
import { extractGoogleAddressComponents } from '@slerp/helpers'

export type AddressValueType = {
  geocoded?: boolean
  validateTrigger?: boolean
  postalCode?: string
  formattedAddress?: string
  noSuggestionSelected?: boolean
  line1?: string
  line2?: string
  city?: string
  country?: string
  lat?: number
  lng?: number
}

interface Props {
  value?: string
  placeholder?: string
  onChange?: (value: string, geocodedAddress: AddressValueType) => void
  onInput?: (e: SyntheticEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const AutocompleteInput = ({
  value,
  onChange,
  onInput,
  disabled = false,
  placeholder
}: Props) => {
  const LONDON = new google.maps.LatLng(51.5285582, -0.2416806)
  const options = {
    location: LONDON,
    radius: 23.5,
    types: ['geocode', 'establishment'],
    componentRestrictions: { country: 'GB' }
  }

  const handleOnChange = (address: string, placeId?: string) => {
    if (!placeId) {
      return (
        onChange &&
        onChange(address, {
          geocoded: false,
          validateTrigger: false,
          noSuggestionSelected: true
        })
      )
    }

    geocodeByPlaceId(placeId)
      .then(async (res) => ({
        result: res[0],
        latLng: await getLatLng(res[0])
      }))
      .then(({ result, latLng }) => {
        const { formatted_address, address_components } = result
        const components = extractGoogleAddressComponents(address_components)
        const { line1, line2, streetNumber, city, zip } = components
        const addressHash = {
          geocoded: true,
          validateTrigger: true,
          noSuggestionSelected: false,
          formattedAddress: formatted_address,
          line1: streetNumber ? `${streetNumber} ${line2}` : line2,
          line2: line1,
          city: city,
          country: 'GB',
          postalCode: zip,
          lat: latLng.lat,
          lng: latLng.lng
        }

        onChange && onChange(address, addressHash)
      })
  }

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleOnChange}
      onSelect={handleOnChange}
      searchOptions={options}
      onError={(status) => message.warn(`Invalid address: ${status}`)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <Input
            {...getInputProps({
              placeholder,
              className: 'location-search-input',
              disabled: disabled
            })}
            onInput={onInput}
            data-testid='address-input-field'
          />
          <div className='autocomplete-dropdown-container'>
            {loading && <Spin />}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item'
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    padding: '12px auto'
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    padding: '12px auto'
                  }
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  )
}

export default AutocompleteInput
