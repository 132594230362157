import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import { SplitHour, DayOfWeek } from './types'

type Boundaries = number[]

const TIME_FORMAT = 'hh:mm a'
const TWENTY_FOUR_TIME_FORMAT = 'HH:mm'
const DAY_NAME_FORMAT = 'dddd'
const HOURS = 24
const SECONDS = 3600
const TWELVE_PM_IN_SECONDS = 43000

const timeToSeconds = (time) => {
  const [hour, minute] = time.split(':')
  return parseInt(hour) * SECONDS + parseInt(minute * 60)
}

const GetShiftLengthInSeconds = (startTime: string, endTime: string) => {
  const start = timeToSeconds(startTime)
  const end = timeToSeconds(endTime)
  const offset = HOURS * SECONDS

  return end < start ? offset + (end - start) : end - start
}

// gets the very first start time and the very last end time
const ShiftBoundaries = ({ start_time, seconds_length }: SplitHour) => {
  const startTime = timeToSeconds(start_time)
  const endTime = timeToSeconds(
    moment(start_time, TIME_FORMAT)
      .add(seconds_length, 's')
      .format(TWENTY_FOUR_TIME_FORMAT)
  )
  const isOvernightShift = endTime < startTime
  const endTimeWithOffset = isOvernightShift
    ? HOURS * SECONDS + endTime
    : endTime

  return [startTime, endTimeWithOffset]
}

const GetNextDayShift = (
  shiftDay: DayOfWeek,
  weeklyShifts: { [key: string]: SplitHour }
) => {
  const shiftDays = {
    monday: 'tuesday',
    tuesday: 'wednesday',
    wednesday: 'thursday',
    thursday: 'friday',
    friday: 'saturday',
    saturday: 'sunday',
    sunday: 'monday'
  }

  return weeklyShifts[shiftDays[shiftDay]] || []
}

const IsShiftWithConflict = (boundaries: Boundaries[], shiftTime: number) => {
  return !!(
    boundaries.find(
      ([startBoundary, endBoundary]: number[]) =>
        shiftTime >= startBoundary && shiftTime <= endBoundary
    ) || []
  ).length
}

const ValidateShift = (
  { start_time, seconds_length }: SplitHour,
  shifts: SplitHour[],
  weeklyShifts: Record<DayOfWeek, SplitHour[]>,
  shiftDay: DayOfWeek,
  index: number
) => {
  const startTime = timeToSeconds(start_time)
  const endTime = timeToSeconds(
    moment(start_time, TIME_FORMAT)
      .add(seconds_length, 's')
      .format(TWENTY_FOUR_TIME_FORMAT)
  )
  const isOvernightShift = endTime < startTime

  const validatingShifts = [...shifts] // clone it
  validatingShifts.splice(index, 1) // remove current validating shift
  const boundaries = validatingShifts.map((shift: SplitHour) =>
    ShiftBoundaries(shift)
  )
  // see if the current startTime and endTime passes boundaries checks, in short inbetween start and end boundaries
  if (IsShiftWithConflict(boundaries, startTime)) return false
  if (IsShiftWithConflict(boundaries, endTime)) return false

  if (index !== shifts.length - 1) return true // if the current validating shift is not the last, dont bother validating for the next day shift
  if (endTime >= TWELVE_PM_IN_SECONDS) return true
  if (!isOvernightShift) return true // the following validations is for overnight shifts only
  const nextDayShift = GetNextDayShift(shiftDay, weeklyShifts)[0] || {}
  if (isEmpty(nextDayShift)) return true // it passes if there no next day shift anyways
  const nextDayShiftBoundaries = [ShiftBoundaries(nextDayShift)]
  // see if the current shifts endTime conflicts with the next days start time
  if (endTime >= nextDayShiftBoundaries[0][0]) return false

  return true
}

const DisabledDate = (datePickerDate: Date, dayOfWeek: DayOfWeek) => {
  const currentDate = moment()
  const pickerDate = moment(datePickerDate)
  const isBeforeToday = pickerDate.isBefore(
    moment(currentDate.toDate()).startOf('day')
  )
  const isNotCurrentDayOfWeek =
    pickerDate.format(DAY_NAME_FORMAT).toLowerCase() !== dayOfWeek

  if (isBeforeToday) return true
  if (isNotCurrentDayOfWeek) return true

  return false
}

export { GetShiftLengthInSeconds, ValidateShift, DisabledDate }
