import gql from 'graphql-tag'

const StoreVariantsFragment = `
  id
  in_stock
  store_id
  stock_count
  stock_type
  stock_sold
  published_at
  variant_id
  product_variant {
    name
    id
    description
    attributes
    options
    image
    restrictions
    limit
    price
    vat
    options
    published
    additionalImages: additional_images {
      id
    }
    source: product {
      name
      product_id: id
      slug
      category_id
      productVariants: product_variants(
        where: {
          archived_at: { _is_null: true }
          published: { _eq: true }
        }
      ) {
        price
      }
    }
  }
`

const CategoryFragment = `
  categories(
    where: {
      merchant: { slug: { _eq: $merchantSlug } }
      archived_at: { _is_null: true }
    },
    limit: 3
  ) {
    id
    name
    slug
  }
`

export const GET_STORE_PRODUCTS = gql`
  query getStoresByMerchant($merchantSlug: String!, $storeSlug: String!) {
    store_variants(
      where: {
        store: { slug: { _eq: $storeSlug } }
        published_at: { _is_null: false }
        product_variant: {
          product: { archived_at: { _is_null: true } }
          archived_at: { _is_null: true }
          default_variant: { _eq: true }
          published: { _eq: true }
        }
      },
      limit: 3
    ) {
      ${StoreVariantsFragment}
    }
    ${CategoryFragment}
  }
`

export const GET_STORE_PRODUCT = gql`
  query getProduct($merchantSlug: String!, $storeSlug: String!) {
    products(
      where: {
        merchant: { slug: { _eq: $merchantSlug } }
        archived_at: { _is_null: true }
      }
      limit: 5
    ) {
      id
      name
      description
      default_vat
      slug
      type
      default_variant_id
      options
      variants: product_variants(
        where: {
          store_variants: {
            published_at: { _is_null: false }
            in_stock: { _eq: true }
          }
        }
      ) {
        default_variant
        id
        name
        sku
        description
        options
        image
        price
        published
        restrictions
        limit
        vat
        store_variants(where: { store: { slug: { _eq: $storeSlug } } }) {
          in_stock
          store_id
          stock_count
          stock_type
          stock_sold
          published_at
          product_variant {
            id
          }
        }
        additionalImages: additional_images {
          id
        }
        source: product {
          name
          slug
          category_id
          product_variants {
            price
          }
        }
      }
      modifier_group_arrangement
      product_modifiers: product_modifier_groups {
        modifier_group {
          id
          name
          minimum
          minimum_enabled
          maximum
          maximum_enabled
          modifier_arrangement
          modifier_assocs: modifier_group_modifiers(
            where: {
              modifier: {
                store_modifiers: {
                  published_at: { _is_null: false }
                  store: { slug: { _eq: $storeSlug } }
                }
              }
            }
          ) {
            id
            modifier {
              price
              id
              name
              vat
              sku
              store_modifiers(where: { store: { slug: { _eq: $storeSlug } } }) {
                in_stock
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_MERCHANT = gql`
  mutation updateSettings($id: uuid!, $setting: jsonb!) {
    update_merchants(
      where: { id: { _eq: $id } }
      _append: { setting: $setting }
    ) {
      affected_rows
    }
  }
`

export const GET_MERCHANT = gql`
  query getMerchantBySlug($slug: String!) {
    merchants(where: { slug: { _eq: $slug } }) {
      id
      name
      description
      avatar
      slug
      currency
      setting
      cover_image: setting(path: "cover_image")
      header_image: setting(path: "header_image")
      website: setting(path: "website")
      welcome_message: setting(path: "welcome_message")
      welcome_message_header: setting(path: "welcome_message_header")
      promotional_banner: setting(path: "promotional_banner")
      closed_store_message: setting(path: "closed_store_message")
      order_note_placeholder: setting(path: "order_note_placeholder")
      scheduled_pickup: setting(path: "scheduled_pickup")
      signup_promo_message: setting(path: "signup_promo_message")
      tag_manager_id: setting(path: "tag_manager_id")
      pixel_id: setting(path: "pixel_id")
      pre_order_pickup: setting(path: "pre_order_pickup")
      pre_order_delivery: setting(path: "pre_order_delivery")
      pickup_address_enabled: setting(path: "pickup_address_enabled")
      pre_order_settings {
        address: addresses {
          geom
        }
        cutoffs
        delivery_courier_partner
        delivery_zone
        delivery_interval
        delivery_schedule
        delivery_radius
        fixed_courier_fee_enabled
        fixed_delivery_times_enabled
        fixed_delivery_times
        delivery_enabled
        pickup_enabled
        pre_order_day_allowance
        special_cutoffs
        store_team_preparation
      }
    }
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant: { slug: { _eq: $slug } }
      }
    ) {
      id
      name
      slug
      is_open
      address {
        line_1
        line_2
        city
        zip
        geom
      }
      archived_at
      cutoffs
      special_cutoffs
      advanced_special_schedules
      settings
    }
  }
`

export const GET_MERCHANT_IMAGES = gql`
  query getMerchantImages($merchantId: ID) {
    getMerchantImages(merchantId: $merchantId) {
      cover_image
      header_image
      avatar
      alternate_avatar
      favicon
    }
  }
`

export const UPDATE_MERCHANT_IMAGE = gql`
  mutation uploadMerchantImage(
    $merchantId: ID!
    $image: Upload!
    $field: String
  ) {
    uploadMerchantImage(merchantId: $merchantId, image: $image, field: $field) {
      name
      setting {
        header_image
        cover_image
      }
      avatar
      alternate_avatar
      favicon
    }
  }
`

export const GET_MERCHANT_MESSAGES = gql`
  query getMerchantMessages($merchantId: ID!) {
    getMerchantMessages(merchantId: $merchantId) {
      id
      title
      description
      orderType
      messageType
      archivedAt
    }
  }
`

export const CREATE_MERCHANT_MESSAGE = gql`
  mutation createMerchantMessage(
    $merchantId: ID!
    $title: String!
    $description: String!
    $orderType: OrderType!
    $messageType: MessageType!
  ) {
    createMerchantMessage(
      merchantId: $merchantId
      title: $title
      description: $description
      orderType: $orderType
      messageType: $messageType
    ) {
      title
      description
      orderType
      messageType
    }
  }
`

export const UPDATE_MERCHANT_MESSAGE = gql`
  mutation updateMerchantMessage(
    $id: uuid
    $title: String!
    $description: String!
  ) {
    update_messages(
      where: { id: { _eq: $id } }
      _set: { title: $title, description: $description }
    ) {
      returning {
        id
        title
        description
      }
    }
  }
`

export const TOGGLE_MESSAGE_ARCHIVE_STATUS = gql`
  mutation toggleMessageArchiveStatus($id: uuid, $archivedAt: timestamp) {
    update_messages(
      where: { id: { _eq: $id } }
      _set: { archived_at: $archivedAt }
    ) {
      returning {
        id
        title
        archived_at
      }
    }
  }
`
