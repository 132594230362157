import { uuid } from 'uuidv4'
import { ProductVariant, Pricing } from './types'

const setDefaultPricing = (variant: ProductVariant, storeId: string) => {
  return {
    id: uuid(),
    variant_id: variant.id,
    store_variant_id: storeId,
    price: null,
    price_with_vat: null,
    vat: null
  }
}

const getVariantCorePricing = (
  variant: ProductVariant,
  storeVariantId: string
) => {
  const { location_fulfillment_type_pricing = [] } = variant
  const pricing = (location_fulfillment_type_pricing || []).find(
    ({ store_variant_id, fulfillment_type }: Pricing) =>
      store_variant_id === storeVariantId && fulfillment_type === null
  )
  return pricing || setDefaultPricing(variant, storeVariantId)
}

export { getVariantCorePricing }
