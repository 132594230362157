import React, { useState, useEffect, useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { useQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  Col,
  Button,
  Input,
  message,
  Space,
  Table,
  Tabs,
  Tooltip,
  PageHeader,
  Row,
  Switch,
  Form
} from 'antd'
import FormattedDate from '../Utils/FormattedDate'
import {
  QUERY_MERCHANT_GUESTS,
  QUERY_MERCHANT_CUSTOMERS_LIST
} from './CustomersQueries'
import {
  SearchOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  MinusOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { Customer, Guest } from '@slerp/controls'
import { ASSET_HOST } from '@slerp/client'
import StampCustomerModal from '../Loyalty/StampCustomerModal'
import RewardsRedeemModal from '../Loyalty/RewardsRedeemModal'
import { LoyaltyCustomer } from 'components/Loyalty/LoyaltyOverview'
import TableSorter from './../Utils/TableSorter'
import ClickableTitle from 'components/Utils/ClickableTitle'
import ExclusiveCardModal from 'components/Loyalty/ExclusiveCardModal'

const { Column } = Table
const { TabPane } = Tabs
const { useForm } = Form

const Customers = () => {
  const [customerSearchFilter, setCustomerSearchFilter] = useState<string>('')
  const [guestSearchFilter, setGuestSearchFilter] = useState('')
  const [activeTab, setActiveTab] = useState('1')
  const [updatingSetting, setUpdatingSetting] = useState<boolean>(false)
  const [customersList, setCustomersList] = useState<Array<Customer>>([])
  const [customersCount, setCustomersCount] = useState(0)
  const [guestsList, setGuestsList] = useState<Array<Guest>>([])
  const [currentPage, setCurrentPage] = useState(1)

  const { merchant, user } = useSession()
  const customerExportUrl = `https://${merchant.slug}.${ASSET_HOST}/controls/customer/export`
  const guestsExportUrl = `https://${merchant.slug}.${ASSET_HOST}/controls/guests/export`
  const [stampModalVisible, setStampModalVisible] = useState(false)
  const [modalCustomer, setModalCustomer] = useState<LoyaltyCustomer | null>(
    null
  )
  const [activeSort, setActiveSort] = useState('stamps-desc')
  const [rewardsModalVisible, setRewardsModalVisible] = useState<boolean>(false)
  const [exclusiveModalVisible, setExclusiveModalVisible] =
    useState<boolean>(false)
  const [form] = useForm()

  const {
    data: customersData,
    loading,
    fetchMore,
    error: customersFetchError,
    refetch
  } = useQuery(QUERY_MERCHANT_CUSTOMERS_LIST, {
    variables: {
      merchantId: merchant.id,
      limit: 100,
      offset: 0,
      orderBy: {
        number_of_stamps: 'desc_nulls_last'
      } as {}
    },
    notifyOnNetworkStatusChange: true
  })

  const { data: guestsData, loading: guestsLoading } = useQuery(
    QUERY_MERCHANT_GUESTS,
    {
      variables: { merchantId: merchant.id },
      notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    if (!customersData) return
    setCustomersList(activeTab === '1' && customersData?.view_customers_list)
    setCustomersCount(
      customersData &&
        activeTab === '1' &&
        customersData?.customers_aggregate.aggregate.count
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersData, activeTab])

  useEffect(() => {
    // always default to the very first page
    setCurrentPage(1)
  }, [activeSort])

  useEffect(() => {
    if (!guestsData) return
    setGuestsList(activeTab === '2' && guestsData?.orders)
  }, [guestsData, activeTab])

  const filterCustomerHandler = useCallback(
    (input: String) => {
      fetchMore({
        variables: {
          firstName: `%${input}%`,
          lastName: `%${input}%`,
          email: `%${input}%`
        }
      })
        .then((response) => {
          setCustomersList(response?.data?.view_customers_list)
          setCustomersCount(response.data.customers_aggregate.aggregate.count)
        })
        .catch((error) =>
          message.error(
            `Unable to fetch customers list due to: ${
              customersFetchError || error
            }`,
            5
          )
        )
    },
    [customersFetchError, fetchMore]
  )

  const filterGuestHandler = useCallback(
    (guests: Guest) => {
      const { first_name = '', last_name = '', email = '' } = guests
      const parsedFilter = guestSearchFilter.replace(/\s/g, '').toLowerCase()
      return (
        (first_name || '').toLowerCase().includes(parsedFilter) ||
        (last_name || '').toLowerCase().includes(parsedFilter) ||
        (email || '').toLowerCase().includes(parsedFilter)
      )
    },
    [guestSearchFilter]
  )

  useEffect(() => {
    const isEmpty = Object.values(customerSearchFilter).every((value) => !value)

    switch (activeTab) {
      case '1':
        if (customersData && !isEmpty) {
          filterCustomerHandler(customerSearchFilter.toLowerCase())
        } else {
          setCustomersList(customersData?.view_customers_list)
          setCustomersCount(customersData?.customers_aggregate.aggregate.count)
        }
        setCurrentPage(1)
        break
      case '2':
        guestsData && guestSearchFilter && activeTab === '2'
          ? setGuestsList(guestsData?.orders.filter(filterGuestHandler))
          : setGuestsList(guestsData?.orders)
        setCurrentPage(1)
        break
      default:
        setCustomersList(customersData?.view_customers_list)
        setCustomersCount(customersData?.customers_aggregate.aggregate.count)
        setGuestsList(guestsData?.orders)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    customerSearchFilter,
    guestSearchFilter,
    filterCustomerHandler,
    filterGuestHandler
  ])

  useEffect(() => {
    setCustomerSearchFilter('')
    debounceSetGuestSearchFilter('')

    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, activeSort])

  const triggerCustomerSearchFilter = (searchParam: string) =>
    setCustomerSearchFilter(searchParam)
  const debounceSetCustomerSearchFilter = useMemo(
    () => debounce(triggerCustomerSearchFilter, 400),
    []
  )

  useEffect(() => {
    return () => debounceSetCustomerSearchFilter.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const triggerGuestSearchFilter = (searchParam: string) =>
    setGuestSearchFilter(searchParam)
  const debounceSetGuestSearchFilter = debounce(triggerGuestSearchFilter, 400)

  const searchBox = (
    <Space className='_pb-4 _pr-2'>
      <Tooltip
        title={
          <>
            {activeTab === '1' ? (
              <span>
                You can input customer's first name, last name or email to
                search for their details(<strong>case insensitive</strong>).
              </span>
            ) : (
              <span>
                Please input guest's <strong>complete</strong> first name, last
                name or email to search for their details(
                <strong>case sensitive</strong>).
              </span>
            )}
          </>
        }
        placement='left'
      >
        <>
          {activeTab === '1' ? (
            <Form form={form}>
              <Form.Item name='search-box-customers'>
                <Input
                  onChange={(e) =>
                    debounceSetCustomerSearchFilter(e.target.value)
                  }
                  placeholder='Search Customers'
                  prefix={<SearchOutlined />}
                  key='search-box-customers'
                />
              </Form.Item>
            </Form>
          ) : (
            <Input
              onChange={(e) => debounceSetGuestSearchFilter(e.target.value)}
              placeholder='Search Guests'
              prefix={<SearchOutlined />}
              key='search-box-guests'
            />
          )}
        </>
      </Tooltip>
    </Space>
  )

  const toggleCustomerAccountsEnabled = (checked: boolean) => {
    message.destroy()
    message.loading('Updating setting enable accounts creation...', 3)
    setUpdatingSetting(true)
    ToggleCustomerAccountsEnabledSetting({
      merchantId: merchant.id,
      value: checked
    })
      .then((result) => {
        setUpdatingSetting(false)
        setCustomerAccountsEnabled(checked)
        message.destroy()
        message.success('Setting enable accounts creation updated!', 3)
      })
      .catch((error: Error) => {
        setUpdatingSetting(false)
        message.destroy()
        message.error(
          `Unable to update enable accounts creation setting due to ${error.message}.`,
          3
        )
      })
  }

  return (
    <>
      <PageHeader
        title={`Latest ${activeTab === '1' ? 'Customers' : 'Guests'}`}
        extra={[
          <Row
            gutter={[8, 0]}
            align='middle'
            justify='center'
            className='-accounts _pr-2'
          >
            <Col>
              <Tooltip
                title={
                  <span>
                    Exports all customer data to CSV. Be sure to read about
                    marketing opt-in for GDPR compliance{' '}
                    <a
                      href='https://support.slerp.com/en_gb/marketing-opt-in-rJgMQOqWK'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </span>
                }
              >
                <Button
                  href={`${
                    activeTab === '1' ? customerExportUrl : guestsExportUrl
                  }?token=${user.api_key}`}
                  target='_blank'
                >
                  {activeTab === '1' ? 'Export Customers' : 'Export Guests'}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        ]}
      />
      <Tabs
        activeKey={activeTab}
        tabBarExtraContent={searchBox}
        onChange={setActiveTab}
      >
        <TabPane
          tab={
            <>
              Customers
              <Tooltip
                placement='bottom'
                title={
                  <span>
                    At checkout, if customers choose to save their details and
                    opt-in
                    <br /> to marketing communications their details will appear
                    in the 'Customers' list.
                    <br />
                    <br />
                    Find out more{' '}
                    <a
                      href='https://support.slerp.com/knowledge/customers-and-guests'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </span>
                }
              >
                <QuestionCircleOutlined
                  className='_ml-8'
                  style={{ marginRight: '-8px' }}
                />
              </Tooltip>
            </>
          }
          key='1'
        >
          <Table
            data-testId='customers-component'
            rowClassName='customer-list-row-item'
            dataSource={customersList}
            pagination={{
              defaultPageSize: 100,
              current: currentPage,
              onChange: (page, pageSize) => {
                const offset = (pageSize || 100) * page - (pageSize || 100)
                setCurrentPage(page)

                fetchMore({
                  variables: {
                    offset: offset,
                    limit: pageSize,
                    firstName: `%${customerSearchFilter.toLowerCase()}%`,
                    lastName: `%${customerSearchFilter.toLowerCase()}%`,
                    email: `%${customerSearchFilter.toLowerCase()}%`
                  }
                })
                  .then((response) => {
                    const incoming = response.data.view_customers_list
                    const incomingCount =
                      response.data.customers_aggregate.aggregate.count
                    setCustomersList(incoming)
                    setCustomersCount(incomingCount)
                  })
                  .catch((error) =>
                    message.error(
                      `Unable to fetch customers list due to: ${
                        customersFetchError || error
                      }`,
                      5
                    )
                  )
              },
              total: customersCount
            }}
            loading={loading}
          >
            <Column
              title='First Name'
              key='first_name'
              dataIndex='first_name'
            />
            <Column title='Last Name' key='last_name' dataIndex='last_name' />
            <Column title='Email' key='email' dataIndex='email' />
            <Column
              title='Created'
              key='inserted_at'
              dataIndex='inserted_at'
              render={(text: Date) => <FormattedDate>{text}</FormattedDate>}
            />
            <Column
              title={
                <Row
                  datatest-id='customers-stamps-column-sorter'
                  className='_mb-0 custom-sorter'
                  align='middle'
                  gutter={8}
                >
                  <ClickableTitle
                    onClick={() => {
                      setActiveSort('stamps-desc')
                      return refetch({
                        orderBy: { number_of_stamps: 'desc_nulls_last' }
                      })
                    }}
                    title='Number of stamps'
                    type='descending'
                  />
                  <Col>
                    <Space direction='vertical' size={0} className='_mb-2'>
                      <div className='_center-vertical'>
                        <TableSorter
                          onClick={() => {
                            setActiveSort('stamps-asc')
                            refetch({
                              orderBy: { number_of_stamps: 'asc_nulls_last' }
                            })
                          }}
                          type='asc'
                          isActive={activeSort === 'stamps-asc'}
                        />
                      </div>
                      <div className='_center-vertical'>
                        <TableSorter
                          onClick={() => {
                            setActiveSort('stamps-desc')
                            refetch({
                              orderBy: { number_of_stamps: 'desc_nulls_last' }
                            })
                          }}
                          type='desc'
                          isActive={activeSort === 'stamps-desc'}
                        />
                      </div>
                    </Space>
                  </Col>
                </Row>
              }
              key='stamps'
              dataIndex={'number_of_stamps'}
            />
            <Column
              title={
                <Row
                  datatest-id='customers-cards-column-sorter'
                  className='_mb-0 custom-sorter'
                  align='middle'
                  gutter={8}
                >
                  <ClickableTitle
                    onClick={() => {
                      setActiveSort('rewards-redeemed-desc')

                      return refetch({
                        orderBy: { redeemed_rewards: 'desc_nulls_last' }
                      })
                    }}
                    title='Rewards redeemed'
                    type='descending'
                  />
                  <Col>
                    <Space direction='vertical' size={0} className='_mb-2'>
                      <div className='_center-vertical'>
                        <TableSorter
                          onClick={() => {
                            setActiveSort('rewards-redeemed-asc')
                            refetch({
                              orderBy: { redeemed_rewards: 'asc_nulls_first' }
                            })
                          }}
                          type='asc'
                          isActive={activeSort === 'rewards-redeemed-asc'}
                        />
                      </div>
                      <div className='_center-vertical'>
                        <TableSorter
                          onClick={() => {
                            setActiveSort('rewards-redeemed-desc')
                            refetch({
                              orderBy: { redeemed_rewards: 'desc_nulls_last' }
                            })
                          }}
                          type='desc'
                          isActive={activeSort === 'rewards-redeemed-desc'}
                        />
                      </div>
                    </Space>
                  </Col>
                </Row>
              }
              key='redeemed_rewards'
              dataIndex={'redeemed_rewards'}
            />
            <Column
              title='Add Stamps'
              align='center'
              render={(record: LoyaltyCustomer) => (
                <>
                  <Button
                    icon={<PlusOutlined />}
                    className='_mr-8'
                    onClick={() => {
                      setStampModalVisible(true)
                      setModalCustomer(record)
                    }}
                  ></Button>
                </>
              )}
            />
            <Column
              title='Redeem a Reward'
              align='center'
              render={(record: LoyaltyCustomer) => (
                <>
                  <Button
                    icon={<MinusOutlined />}
                    className='_mr-8'
                    onClick={() => {
                      setRewardsModalVisible(true)
                      setModalCustomer(record)
                    }}
                  ></Button>
                </>
              )}
            />
            <Column
              title='Exclusivity settings'
              align='center'
              render={(record: LoyaltyCustomer) => (
                <Button
                  icon={<SettingOutlined />}
                  className='_mr-8'
                  onClick={() => {
                    setExclusiveModalVisible(true)
                    setModalCustomer(record)
                  }}
                />
              )}
            />
          </Table>
        </TabPane>
        <TabPane
          tab={
            <>
              Guests
              <Tooltip
                placement='bottom'
                title={
                  <span>
                    If customers opt-out of saving their credentials and
                    receiving any marketing communication they'll be added to
                    the 'Guests' list. Meaning their information cannot be
                    exported or manipulated.
                    <br />
                    <br /> Find out more{' '}
                    <a
                      href='https://support.slerp.com/knowledge/customers-and-guests'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </span>
                }
              >
                <QuestionCircleOutlined
                  className='_ml-8'
                  style={{ marginRight: '-8px' }}
                />
              </Tooltip>
            </>
          }
          key='2'
        >
          <Table
            data-testid='guests-component'
            dataSource={guestsList}
            pagination={{
              defaultPageSize: 100,
              current: currentPage,
              onChange: (page) => setCurrentPage(page)
            }}
            loading={guestsLoading}
          >
            <Column
              title='First Name'
              key='first_name'
              dataIndex='first_name'
            />
            <Column title='Last Name' key='last_name' dataIndex='last_name' />
            <Column title='Email' key='email' dataIndex='email' />
            <Column title='Address' key='address' dataIndex='address' />
            <Column title='City' key='city' dataIndex='city' />
            <Column title='Postcode' key='zip' dataIndex='zip' />
          </Table>
        </TabPane>
      </Tabs>
      <StampCustomerModal
        customer={modalCustomer}
        visible={stampModalVisible}
        onClose={() => {
          setStampModalVisible(false)
          setModalCustomer(null)
        }}
      />
      <RewardsRedeemModal
        customer={modalCustomer}
        visible={rewardsModalVisible}
        onClose={() => {
          setRewardsModalVisible(false)
          setModalCustomer(null)
        }}
      />
      <ExclusiveCardModal
        customer={modalCustomer}
        visible={exclusiveModalVisible}
        onClose={() => {
          setExclusiveModalVisible(false)
          setModalCustomer(null)
        }}
      />
    </>
  )
}

export default Customers
