import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { Button, Table, Tooltip, Switch, message } from 'antd'
import { Discount } from '@slerp/controls'
import { descriptionText, scheduleText, numberOfUsesText } from './Helpers'
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  GET_MERCHANT_STORES,
  TOGGLE_DISCOUNT_ACTIVATION_STATUS
} from './Actions'
import { useSession } from '@slerp/accounts'
import moment from 'moment'
import useGetMerchantDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useGetMerchantDeliveryPricingBands'
import { getDiscountProperties } from './utils'

interface DiscountsTableProps {
  data: Discount[]
  trigger: 'code' | 'automatic'
}

interface Store {
  id: string
  slug: string
  name: string
}

const { Column } = Table

const DiscountsTable = ({ data, trigger }: DiscountsTableProps) => {
  const history = useHistory()
  const { merchant } = useSession()
  const client = useApolloClient()

  const [toggleDiscountActivation, { loading }] = useMutation(
    TOGGLE_DISCOUNT_ACTIVATION_STATUS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const { data: stores } = useQuery(GET_MERCHANT_STORES, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  const [deliveryPricingBands] = useGetMerchantDeliveryPricingBands({
    merchantId: merchant.id
  })

  const merchantStoresDeliveryPricingBands = useMemo(() => {
    return Object.keys(deliveryPricingBands).filter((storeId: string) => {
      const { sameday = [], preorder = [] } = deliveryPricingBands[storeId]
      return !!(sameday || []).length || !!(preorder || []).length
    })
  }, [deliveryPricingBands])

  const storesWithPricingBands = (stores?.stores || [])
    .filter(({ id }: Store) => merchantStoresDeliveryPricingBands.includes(id))
    .map(({ name }: Store) => name)

  const handleToggleDiscountActivation = (
    discountId: string,
    discountCode: string,
    checked: boolean
  ) => {
    toggleDiscountActivation({
      variables: {
        merchantId: merchant.id,
        discountId: discountId,
        published_at: checked ? moment().format() : null
      }
    })
      .then((result) => {
        message.success(
          <span>
            {checked ? 'Activated' : 'Deactivated'}{' '}
            <strong>{discountCode}</strong> successfully!
          </span>,
          5
        )
        client.resetStore()
      })
      .catch((error) => {
        message.error(
          `Failed to ${
            checked ? 'activate' : 'deactivate'
          } discount due to: ${error}`
        )
      })
  }

  return (
    <Table data-testid='discounts-list-component' dataSource={data}>
      <Column
        dataIndex='id'
        key='activate-discount'
        render={(value: string, discount: Discount) => {
          const { isBlockedByActivePricingBands } = getDiscountProperties({
            ...discount,
            merchantStoresDeliveryPricingBands:
              merchantStoresDeliveryPricingBands
          })
          const pricingBandBlockMessage = isBlockedByActivePricingBands && (
            <>
              <span>
                The following locations currently have active tiered delivery
                pricing:
              </span>
              <br />
              <br />
              {storesWithPricingBands.map((storeName) => (
                <>
                  <span>{storeName}</span>
                  <br />
                </>
              ))}
              <br />
              <span>
                Please either remove these locations from being selected in this
                discount or remove the tiered delivery pricing from the above
                locations.
              </span>
            </>
          )

          return (
            <Tooltip placement='rightTop' title={pricingBandBlockMessage}>
              <Switch
                loading={loading}
                disabled={loading || isBlockedByActivePricingBands}
                onChange={(checked) =>
                  handleToggleDiscountActivation(
                    discount.id,
                    discount.code,
                    checked
                  )
                }
                checked={moment(discount.published_at).isValid()}
              />
            </Tooltip>
          )
        }}
      />
      <Column
        title={
          <>
            Code
            <Tooltip
              title={
                <span>Click on the discount code to see full details.</span>
              }
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
        dataIndex='code'
        key='code'
        render={(value: string, discount: Discount) => {
          return (
            <Link to={`/discounts/${trigger}/edit/${discount.id}`}>
              <strong className='_cl-primary'>{value}</strong>
            </Link>
          )
        }}
      />
      <Column
        title='Description'
        dataIndex='id'
        key='value'
        render={(_id: string, discount: Discount) => {
          return discount.description || descriptionText(discount)
        }}
      />
      <Column
        title='Dates active'
        dataIndex='id'
        key='dates_active'
        render={(id: string) => {
          const discount = data.find((disc: Discount) => disc.id === id) || {}
          return Object.keys(discount).length && scheduleText(discount)
        }}
      />
      <Column
        title='Number of uses'
        dataIndex='id'
        key='number_of_uses'
        render={(id: string) => {
          const discount = data.find((disc: Discount) => disc.id === id) || {}
          return Object.keys(discount).length && numberOfUsesText(discount)
        }}
      />
      <Column
        title='Actions'
        dataIndex='id'
        key='actions'
        render={(value: string, discount: Discount) => {
          return (
            <Tooltip title='Edit' color='#d092dd'>
              <Button
                data-testid={`discount-edit-button-${discount.code}`}
                icon={<EditOutlined />}
                size='small'
                onClick={() =>
                  history.push(`/discounts/${trigger}/edit/${discount.id}`)
                }
              />
            </Tooltip>
          )
        }}
      />
    </Table>
  )
}

export default DiscountsTable
