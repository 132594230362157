/*

This theme should be styled-system compatible:

For more info about styled-system. Go here: https://styled-system.com/

This theme file is from  https://primer.style/components/docs/primer-theme 
*/

// import Theme from './types'

export default {
  themeTitle: 'Default Theme',
  designMode: false,
  breakpoints: ['544px', '768px', '1012px', '1280px'],
  mediaQueries: {
    viewport2: '@media(min-width: 360px)',
    viewport3: '@media(min-width: 360.1px)',
    viewport4: '@media(min-width: 480.1px)',
    viewport5: '@media(min-width: 544.1px)',
    viewport7: '@media(min-width: 768.1px)',
    viewport9: '@media(min-width: 960.1px)',
    viewport10: '@media(min-width: 1012.1px)',
    viewport11: '@media(min-width: 1080.1px)',
    viewport12: '@media(min-width: 1220.1px)',
    viewport14: '@media(min-width: 1440.1px)',
    viewport16: '@media(min-width: 1660.1px)'
  },
  maxWidths: {
    small: '544px',
    medium: '768px',
    large: '1012px',
    xlarge: '1280px'
  },
  fonts: {
    heading: {
      family:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      weight: '700'
    },
    body: {
      family:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      weight: '400'
    },
    mono: 'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace'
  },
  defaults: {
    navbarHeight: '48px',
    productSectionBackgroundColor: '#E9E9E9',
    transitions: {
      background: 'background 200ms ease-in-out'
    }
  },
  components: {
    banner: {
      height: '360px',
      overlay: {
        position: 'full',
        textAlignment: 'center',
        background: 'rgba(255, 255, 255, 0.8)'
      },
      color: 'black',
      background: 'white'
    },
    categories: {
      background: '#f5f5f5',
      color: '#000000',
      position: 'top'
    },
    navbar: {
      height: '48px',
      padding: '8px 16px'
    },
    footer: {
      height: '48px',
      width: '180px'
    },
    designer: {
      lightgray: '#eaeaea'
    }
  },
  fontWeights: {
    light: 300,
    normal: 400,
    bold: 600
  },
  colors: {
    primary: 'rgba(0,0,0,1)',
    secondary: '#1A237E',
    accentA: '#24292e',
    accentB: '#24292e',
    black: '#1b1f23',
    white: '#ffffff',
    lightgray: 'gray',
    lineColor: '#e9e9e9',
    // text colors
    textBody: '#000000',
    textMute: '#757575',
    textBold: '#000000',
    // states
    // TODO: Edit this
    state: {
      warning: {
        1: 'hsl(50, 100%, 90%)',
        5: 'hsl(50, 100%, 50%)',
        9: 'hsl(50, 100%, 10%)'
      },
      error: {
        1: 'hsl(1, 50%, 90%)',
        5: 'hsl(1, 66%, 55%)',
        9: 'hsl(1, 100%, 10%)'
      },
      failure: '#d73a49',
      pending: '#dbab09',
      queued: '#dbab09',
      success: '#28a745',
      unknown: '#959da5'
    }
  },
  borders: [0, '1px solid #d2d2d2'],
  fontSizes: [12, 14, 16, 20, 24, 28, 32, 40, 48],
  lineHeights: {
    condensedUltra: 1,
    condensed: 1.25,
    default: 1.5
  },
  radii: [0, 3, 6, 12],
  shadows: {
    small: '0 1px 1px rgba(27, 31, 35, 0.1)',
    medium: '0 1px 5px rgba(27, 31, 35, 0.15)',
    large: '0 1px 15px rgba(27, 31, 35, 0.15)',
    'extra-large': '0 10px 50px rgba(27, 31, 35, 0.07)',
    formControl: 'rgba(27, 31, 35, 0.075) 0px 1px 2px inset',
    formControlFocus: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 0.2em'
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 80, 96, 112, 128]
} as any
