import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  keyName?: string
}

const TextInput: React.FC<InputProps> = ({
  value,
  onChange,
  keyName = '',
  ...props
}) => {
  const stripWelcomeHeader = (val: string) =>
    val.match(/<h1>(.*?)<\/h1>/)?.[1] ?? val

  const initialValue =
    keyName === 'welcome_message_header'
      ? stripWelcomeHeader(value as string)?.trim()
      : value
  const [inputValue, setInputValue] = useState<string>(initialValue as string)

  useEffect(() => {
    if (inputValue !== initialValue) {
      setInputValue(initialValue as string)
    }
  }, [value, initialValue])

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value)
  }

  const handleBlur = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange?.(evt)
  }

  return (
    <StyledInput
      {...props}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      maxLength={keyName === 'welcome_message_header' ? 50 : 0}
    />
  )
}

const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: ${({ theme }: any) => theme.input.height}px;
  padding: 8px 16px;
  font-family: ${({ theme }: any) => theme.font.sans};
  color: ${({ theme }: any) => theme.colors.gray600};
  background-color: ${({ theme }: any) => theme.colors.gray100};
  border: none;
  border-radius: 4px;
  outline: 0;

  z-index: ${({ theme }: any) => theme.zIndex.select};

  ::placeholder {
    color: ${({ theme }: any) => theme.colors.gray400};
  }
`

export { TextInput }
