import React from 'react'
import { Select, SelectProps } from '../Select'
import { FALLBACK_FONT } from '../../constants'

const fonts = [
  'Roboto',
  'Ubuntu',
  'Roboto Mono',
  'Roboto Slab',
  'Red Hat Display',
  'Rubik',
  'Libre Franklin',
  'Teko',
  'Exo',
  'Barlow',
  'Overpass',
  'DM Sans',
  'Josefin Sans',
  'Cairo',
  'Bai Jamjuree',
  'Lato',
  'Lora',
  'Nunito',
  'Archivo',
  'Open Sans',
  'Playfair Display',
  'Poppins',
  'Work Sans',
  'M PLUS 1p',
  'Hind',
  'Chivo',
  'Karla',
  'IBM Plex Sans',
  'Space Mono',
  'Montserrat',
  'Arimo',
  'Outfit',
  'Inter',
  'Inconsolata',
  'Libre Baskerville',
  'Source Sans Pro',
  'Manrope',
  'Chelsea Market'
]

const FontSelect: React.FC<SelectProps> = (props) => {
  const onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(evt)
  }

  return (
    <Select {...props} onChange={onChange}>
      {fonts
        .sort((fontA, fontB) => fontA.localeCompare(fontB))
        .map((font) => (
          <option value={`${font}${FALLBACK_FONT}`} key={font}>
            {font}
          </option>
        ))}
    </Select>
  )
}

export { FontSelect }
