import React, { useState, useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import intersection from 'lodash/intersection'
import { Button, Col, message, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import {
  GET_PRODUCT_MODIFIER_GROUP,
  buildMerchantModifierGroups
} from '../ProductQueries'
import { useSession } from '@slerp/accounts'
import ProductModifierGroupForm from './Form'
import ModifierGroupsArrangements from './ModifierGroupsArrangements'
import Loading from '../../../Utils/Loading'
import '../Products.css'

interface ModifierGroupsProps {
  productId: string
}

interface ModifierArrangement {
  modifier: {
    id: string
    name: string
  }
}

interface ModifierGroup {
  id: string
  name: string
  sku: string | null
  description: string
  minimum_enabled: boolean
  minimum: number
  maximum_enabled: boolean
  maximum: number
  archived_at: string | null
  modifier_arrangement: ModifierArrangement[]
}

const ModifierGroups = (props: ModifierGroupsProps) => {
  const { productId } = props
  const { merchant } = useSession()
  const client = useApolloClient()
  const [availableModifierGroups, setAvailableModifierGroups] = useState<
    Array<ModifierGroup>
  >([])
  const [showAvailableModifierGroupsForm, setShowAvailableModifierGroupsForm] =
    useState<boolean>(false)
  const [currentModifierArrangements, setCurrentModifierArrangements] =
    useState<Array<string>>([])
  const query = buildMerchantModifierGroups()
  const { data: modifierGroupsData, loading: fetchingModifierGroupsData } =
    useQuery(query, {
      variables: {
        merchantId: merchant.id
      }
    })
  const {
    data: productModifierGroupData,
    loading: fetchingProductModifierGroup
  } = useQuery(GET_PRODUCT_MODIFIER_GROUP, {
    variables: { productId }
  })

  useEffect(() => {
    if (modifierGroupsData && productModifierGroupData) {
      const { modifier_groups } = modifierGroupsData
      const { modifier_groups: product_modifier_groups, products } =
        productModifierGroupData
      const {
        modifier_group_arrangement: currentModifierGroupArrangement = {}
      } = products[0] || {}
      const assignedModifierGroupIds = product_modifier_groups.map(
        (pmg: { id: string }) => pmg.id
      )
      // restrict modifiers groups to only those available for selection
      const assignedModifierGroupFilter = (modGrp: ModifierGroup) =>
        !assignedModifierGroupIds.includes(modGrp.id)

      const availableModifierGroups = assignedModifierGroupIds.length
        ? modifier_groups.filter(assignedModifierGroupFilter)
        : modifier_groups

      // lets get the absolute valid arrangement with real life existing values
      // by intersecting the current arrangement to the actual modifier groups of a
      // product record
      const intersectedArrangement: Array<string> = !isEmpty(
        currentModifierGroupArrangement
      )
        ? intersection(
            Object.values(currentModifierGroupArrangement),
            assignedModifierGroupIds
          )
        : assignedModifierGroupIds

      setAvailableModifierGroups(availableModifierGroups)
      setCurrentModifierArrangements(intersectedArrangement)
    }
  }, [modifierGroupsData, productModifierGroupData])

  return (
    <>
      {(fetchingModifierGroupsData || fetchingProductModifierGroup) && (
        <Loading />
      )}
      {!fetchingModifierGroupsData && !fetchingProductModifierGroup && (
        <>
          <Row>
            <Col>
              Add modifier groups (for example, Sides) and modifier items (for
              example, Fries) to allow your customers to customise their orders.
            </Col>
          </Row>

          {modifierGroupsData && currentModifierArrangements && (
            <ModifierGroupsArrangements
              productId={productId}
              modifierGroups={modifierGroupsData?.modifier_groups}
              productModifierGroupsArrangements={currentModifierArrangements}
              onSaveStart={() => {
                message.destroy()
                message.loading(
                  `Updating product modifier groups arrangements...`,
                  3
                )
              }}
              onSaveEnd={() => {
                client.resetStore()
                message.destroy()
                message.success(`Updated modifier groups arrangements`, 3)
              }}
              onSaveError={(errorMessage?: string) => {
                message.destroy()
                message.error(
                  `Unable to update modifier groups arrangements due to ${errorMessage}`,
                  3
                )
              }}
            />
          )}

          {!showAvailableModifierGroupsForm && (
            <Row justify='start' gutter={[16, 16]}>
              <Col>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setShowAvailableModifierGroupsForm(true)}
                  data-testid='product-modifier-group-show-form-btn'
                >
                  ADD MODIFIER GROUP
                </Button>
              </Col>
            </Row>
          )}

          {availableModifierGroups && showAvailableModifierGroupsForm && (
            <ProductModifierGroupForm
              productId={productId}
              modifierGroups={availableModifierGroups}
              currentModifierArrangements={currentModifierArrangements}
              onCancel={() => setShowAvailableModifierGroupsForm(false)}
              onSaveStart={() => {
                message.destroy()
                message.loading(`Adding modifier group to product...`, 3)
              }}
              onSaveEnd={() => {
                client.resetStore()
                message.destroy()
                message.success(`Modifier group added to product!`, 3)
                setShowAvailableModifierGroupsForm(false)
              }}
              onSaveError={(errorMessage?: string) => {
                message.destroy()
                message.error(
                  `Unable to add modifier group to product due to ${errorMessage}`,
                  3
                )
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default ModifierGroups
