import moment from 'moment-timezone'
import isEmpty from 'lodash/isEmpty'
import { checkDiscountCodeValidity } from './DiscountQueries'

type CustomRuleType = { getFieldValue: (arg: string) => string }

const required = { required: true, message: 'This is a required field' }
const requiredRule = [required]
const discountCodeRule = [
  required,
  { min: 4, message: 'Minimum discount code length is 4' },
  ({ getFieldValue }: CustomRuleType) => ({
    async validator(_: any, value: string) {
      const current_code = getFieldValue('current_code') || ''
      if (current_code === value) {
        return Promise.resolve()
      }
      const res = (await checkDiscountCodeValidity(value)) || []
      const isValid = res.length
      return isValid
        ? Promise.reject(`${value} already exists`)
        : Promise.resolve()
    }
  })
]
const percentageValueRule = [
  required,
  {
    validator: (_: any, val: number) =>
      val > 100 ? Promise.reject('Max value exceeded') : Promise.resolve()
  }
]

const startDateRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: moment.Moment) {
      const endDate = getFieldValue('end_date')

      if (isEmpty(endDate) || isEmpty(value)) {
        return Promise.resolve()
      }

      const isValid = value.diff(endDate, 'seconds') <= 0
      return isValid ? Promise.resolve() : Promise.reject('Invalid date')
    }
  })
]

const endDateRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: moment.Moment) {
      const startDate = getFieldValue('start_date')
      if (value) {
        const isValid = value.diff(startDate, 'seconds') >= 0
        return isValid ? Promise.resolve() : Promise.reject('Invalid date')
      } else {
        return Promise.resolve()
      }
    }
  })
]

export {
  requiredRule,
  discountCodeRule,
  percentageValueRule,
  startDateRule,
  endDateRule
}
