import React from 'react'
import SlerpPayLogo from '@slerp/assets/slerp-pay.svg'
import StripeLogo from '@slerp/assets/stripe-logo.svg'
import { PageHeader, Button, Space, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { ImageFlexBox, LogoImage } from './StyledComponents'

type StripeUrlType = 'slerp_pay' | 'default'

interface StripeConnectBadgeProps {
  type?: StripeUrlType
  stripeId?: string
  url: string
  loading: boolean
  isConnected: boolean
  loginUrl: string
  hasStripeCustomerId: boolean
  onConnect?: () => void
  isAllowed?: boolean
}

const StripeConnectBadge = ({
  type,
  stripeId,
  url,
  loading,
  isConnected,
  loginUrl,
  hasStripeCustomerId,
  onConnect,
  isAllowed = true
}: StripeConnectBadgeProps) => {
  const displayMessage = isConnected
    ? `Your ${
        type === 'slerp_pay' ? 'Slerp Pay' : 'Stripe'
      } account is connected successfully.`
    : `Connect your stripe account to receive ${
        type === 'slerp_pay' ? 'customer payments' : 'your funds'
      }.`
  const btnText = isConnected ? 'RECONNECT' : 'CONNECT'

  const handleConnect = () => {
    if (onConnect) {
      onConnect()
    }
  }

  const creditCardText =
    'In order to connect to Stripe you will need to add your Credit Card details first'
  const unauthorizedText =
    'In order to connect to Stripe you should have sufficient rights'

  const unauthorizedDashboardText =
    'In order to use Stripe Dashboard you should have sufficient rights'

  return (
    <div className='stripe-connect-card'>
      <span className='container'>
        <div className='subtext'>{displayMessage}</div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <Space>
            {loginUrl && (
              <Tooltip
                title={unauthorizedDashboardText}
                color='#d092dd'
                overlayStyle={{
                  visibility: `${isAllowed ? 'hidden' : 'visible'}`
                }}
              >
                <Button type='primary' disabled={!isAllowed}>
                  <a
                    type='primary'
                    href={loginUrl}
                    target='_blank'
                    rel='noreferrer'
                    className='primary'
                  >
                    <span className='btntext'>VIEW DASHBOARD</span>
                  </a>
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={isAllowed ? creditCardText : unauthorizedText}
              color='#d092dd'
              overlayStyle={{
                visibility: `${
                  hasStripeCustomerId && isAllowed ? 'hidden' : 'visible'
                }`
              }}
            >
              <Button
                type={loginUrl ? 'default' : 'primary'}
                href={url}
                onClick={handleConnect}
                disabled={hasStripeCustomerId === false}
              >
                <span className='btntext'>{btnText}</span>
              </Button>
            </Tooltip>
          </Space>
        )}
      </span>

      <ImageFlexBox>
        {type === 'slerp_pay' ? (
          <LogoImage
            className='icon'
            data-testid='slerp-pay-logo'
            src={SlerpPayLogo}
            alt='Slerp Pay Logo'
          />
        ) : (
          <LogoImage
            className='icon'
            data-testid='stripe-logo'
            src={StripeLogo}
            alt='Stripe Logo'
          />
        )}

        {stripeId && (
          <div className='subtext'>Connected Account ID: {stripeId}</div>
        )}
      </ImageFlexBox>
    </div>
  )
}

interface StripeConnectProps {
  loading: boolean
  stripeId?: string
  isAllowed?: boolean
  hasStripeCustomerId: boolean
  stripeConnectUrl: {
    type?: StripeUrlType
    url: string
    is_connected: boolean
    login_link: string
  }
  onConnect?: () => void
}

export const StripeConnect = ({
  stripeConnectUrl,
  stripeId,
  loading,
  hasStripeCustomerId,
  onConnect,
  isAllowed
}: StripeConnectProps) => {
  return (
    <>
      <PageHeader
        title={
          <>
            Payment Solution
            <Tooltip
              title={
                <span>
                  Visit Stripe's dashboard{' '}
                  <a
                    href='https://dashboard.stripe.com/login'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    here
                  </a>
                  .
                </span>
              }
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
      />

      <StripeConnectBadge
        url={stripeConnectUrl?.url}
        stripeId={stripeId}
        type={stripeConnectUrl?.type}
        isConnected={stripeConnectUrl?.is_connected}
        loginUrl={stripeConnectUrl?.login_link}
        loading={loading}
        hasStripeCustomerId={hasStripeCustomerId}
        onConnect={onConnect}
        isAllowed={isAllowed}
      />
    </>
  )
}
