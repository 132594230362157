import { useQuery } from '@apollo/client'
import { GET_MERCHANT_COMMISSION_RATES } from './queries'
import { DEFAULT_COMMISSION_RATES } from './utils'
import { Response } from './types'

const useGetMerchantCommissionRates = ({
  merchantCurrentPlanSlug
}: {
  merchantCurrentPlanSlug: string
}): Response => {
  const { data, loading, refetch, error } = useQuery(
    GET_MERCHANT_COMMISSION_RATES,
    {
      variables: {
        merchantCurrentPlanSlug
      },
      fetchPolicy: 'no-cache'
    }
  )

  const commissionRates =
    data?.commission_rates?.[0] || DEFAULT_COMMISSION_RATES

  return [commissionRates, { loading, refetch, error }]
}

export default useGetMerchantCommissionRates
