import React from 'react'
import { DatePicker, Row, Space, Switch, Tooltip } from 'antd'

interface EmptyDateFieldProps {
  handleDayChange: (selectedDate: moment.Moment | null) => void
  datepickerDisabledDate: (date: moment.Moment) => boolean
}

const EmptyDateField = ({
  handleDayChange,
  datepickerDisabledDate
}: EmptyDateFieldProps) => {
  return (
    <Row data-testid='empty-date-field'>
      <Space size='large'>
        <DatePicker
          placeholder='Select date'
          format={'DD/MM/YYYY'}
          onChange={handleDayChange}
          disabledDate={datepickerDisabledDate}
        />
        <Tooltip placement='right' title={'Please select a date first.'}>
          <Switch disabled />
        </Tooltip>
      </Space>
    </Row>
  )
}

export default EmptyDateField
