import { gql } from '@apollo/client'

const GET_MERCHANT_SETTINGS = gql`
  query getMerchantMarketingModalViewers($merchant: uuid!) {
    merchants_by_pk(id: $merchant) {
      marketing_modal_viewers: setting(path: "marketing_modal_viewers")
    }
  }
`

const UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS = gql`
  mutation updateMarketingModalViewersSettings(
    $merchantId: uuid!
    $setting: jsonb!
  ) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { setting: $setting }
    ) {
      affected_rows
      returning {
        marketing_modal_viewers: setting(path: "marketing_modal_viewers")
      }
    }
  }
`

export { GET_MERCHANT_SETTINGS, UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS }
