import { gql } from '@apollo/client'

export const QUERY_MERCHANT_CUSTOMERS = gql`
  query getMerchantCustomers(
    $merchantId: uuid!
    $limit: Int!
    $offset: Int!
    $firstName: String
    $lastName: String
    $email: String
    $order_by: [customers_order_by!]
  ) {
    customers(
      limit: $limit
      offset: $offset
      where: {
        merchant_id: { _eq: $merchantId }
        _or: [
          { first_name: { _ilike: $firstName } }
          { last_name: { _ilike: $lastName } }
          { email: { _ilike: $email } }
        ]
      }
      order_by: $order_by
    ) {
      id
      merchant_id
      first_name
      last_name
      email
      birthdate
      inserted_at
      stamps: stamps_aggregate {
        aggregate {
          count
        }
      }
      redeemed_rewards: customer_rewards_aggregate(
        where: { redeemed_at: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
    }
    customers_aggregate(
      where: {
        merchant: { id: { _eq: $merchantId } }
        _or: [
          { first_name: { _ilike: $firstName } }
          { last_name: { _ilike: $lastName } }
          { email: { _ilike: $email } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const QUERY_MERCHANT_GUESTS = gql`
  query getMerchantGuests($merchantId: uuid!) {
    orders(
      where: {
        merchant_id: { _eq: $merchantId }
        customer_id: { _is_null: true }
        customer_details: { _is_null: false }
        details_disclosed: { _eq: true }
      }
      order_by: { inserted_at: desc }
    ) {
      first_name: customer_details(path: "first_name")
      last_name: customer_details(path: "last_name")
      email: customer_details(path: "email")
      contact_number: customer_details(path: "contact_num")
      zip: customer_details(path: "address.zip")
      city: customer_details(path: "address.city")
      address: customer_details(path: "address.line_2")
    }
  }
`

export const QUERY_MERCHANT_CUSTOMERS_LIST = gql`
  query getMerchantCustomersList(
    $merchantId: uuid!
    $orderBy: [view_customers_list_order_by!]
    $limit: Int!
    $offset: Int!
    $firstName: String
    $lastName: String
    $email: String
  ) {
    view_customers_list(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: {
        merchant_id: { _eq: $merchantId }
        _or: [
          { first_name: { _ilike: $firstName } }
          { last_name: { _ilike: $lastName } }
          { email: { _ilike: $email } }
        ]
      }
    ) {
      id
      merchant_id
      first_name
      last_name
      email
      birthdate
      redeemed_rewards
      number_of_stamps
      inserted_at
    }

    customers_aggregate(
      where: {
        merchant: { id: { _eq: $merchantId } }
        _or: [
          { first_name: { _ilike: $firstName } }
          { last_name: { _ilike: $lastName } }
          { email: { _ilike: $email } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
