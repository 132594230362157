import React, { useState, useEffect } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  Form,
  Slider,
  FormInstance,
  Row,
  Col,
  Switch,
  Typography,
  Tooltip,
  Checkbox,
  Button
} from 'antd'
import DeliveryAreaSettings from '../../SameDaySettings/DeliveryAreaSettings'
import { Store, LatLng } from '@slerp/controls'
import cloneDeep from 'lodash/cloneDeep'
import {
  MIN_MINUTES,
  MIN_MILES,
  usePreviewDelivery,
  usePrevious
} from './utils'
import ReadKml from './ReadKml'

const BusyDeliveryZone = ({
  store,
  form,
  disabled = false,
  deliveryRadius,
  deliveryTime,
  isByTime,
  deliveryArea
}: {
  form: FormInstance
  store: Store
  deliveryArea: LatLng[]
  disabled?: boolean
  deliveryRadius: number
  deliveryTime: number
  isByTime: boolean
}) => {
  const settings = store.settings
  const defaultRadius = settings?.delivery_radius || deliveryRadius
  const defaultTime = settings?.delivery_time || deliveryTime

  const [busyDeliveryArea, setBusyDeliveryArea] = useState<LatLng[]>(
    cloneDeep(settings.busy_delivery_area) || null
  )
  const [busyAreaActive, setBusyAreaActive] = useState<boolean>(
    settings.busy_delivery_area_enabled
  )

  const [deliveryBusyRadius, setDeliveryBusyRadius] =
    useState<number>(defaultRadius)
  const [deliveryBusyTime, setDeliveryBusyTime] = useState<number>(defaultTime)

  const [isChanged, setIsChanged] = useState<boolean>(false)

  const [busyDeliveryAreaTouched, setBusyDeliveryAreaTouched] =
    useState<boolean>(store.settings.custom_busy_delivery_area)

  const handleQueryComplete = (response: { previewDeliveryArea: LatLng[] }) => {
    setBusyDeliveryArea(cloneDeep(response.previewDeliveryArea))
    updateTouched(false)
  }

  const previewBusyArea = usePreviewDelivery({
    store,
    onCompleted: handleQueryComplete
  })
  const oldIsByTime = usePrevious(isByTime)

  useEffect(() => {
    if (oldIsByTime === undefined || oldIsByTime === isByTime) {
      return
    }

    const busyUpdate = isByTime ? deliveryBusyTime : deliveryBusyRadius

    previewBusyArea(busyUpdate, isByTime)
  }, [
    isByTime,
    previewBusyArea,
    deliveryBusyTime,
    deliveryBusyRadius,
    oldIsByTime
  ])

  const handleBusyRadiusUpdate = (value: number) => {
    setDeliveryBusyRadius(value)
    previewBusyArea(value, isByTime)
  }

  const handleBusyTimeUpdate = (value: number) => {
    setDeliveryBusyTime(value)
    previewBusyArea(value, isByTime)
  }

  const handleBusyAreaToggle = (value: boolean) => {
    if (value) {
      const busyUpdate = isByTime ? deliveryBusyTime : deliveryBusyRadius
      previewBusyArea(busyUpdate, isByTime)
    }

    setBusyAreaActive(value)
  }

  const updateTouched = (touched: boolean) => {
    setBusyDeliveryAreaTouched(touched)
    form.setFieldsValue({
      busy_delivery_area_touched: touched
    })

    if (!touched) {
      form.setFieldsValue({
        busy_delivery_area_kml_upload: false
      })
    }
  }

  const handleRestore = () => {
    setBusyDeliveryArea(cloneDeep(settings.busy_delivery_area) || null)
    updateTouched(store.settings.custom_busy_delivery_area)
    setIsChanged(false)

    setDeliveryBusyRadius(defaultRadius)
    setDeliveryBusyTime(defaultTime)

    form.setFieldsValue({
      busy_delivery_radius: defaultRadius,
      busy_delivery_time: defaultTime
    })
  }

  const handleTouch = (path: LatLng[]) => {
    setBusyDeliveryArea(cloneDeep(path))
    setIsChanged(true)
    updateTouched(true)
    form.setFieldsValue({
      busy_delivery_area: path
    })
  }

  const handleKmlUpload = (path: LatLng[]) => {
    handleTouch(path)
    setIsChanged(true)
    form.setFieldsValue({
      busy_delivery_area_kml_upload: true
    })
  }

  return (
    <>
      <Form.Item hidden name='busy_delivery_area' />
      <Form.Item hidden name='busy_delivery_area_kml_upload' />
      <Form.Item hidden name='busy_delivery_area_touched'>
        <Checkbox className='checkbox' checked={busyDeliveryAreaTouched} />
      </Form.Item>
      <Row style={{ margin: 0, marginTop: 14 }} align='middle'>
        <Col>
          <Form.Item
            name='busy_delivery_area_enabled'
            valuePropName='checked'
            style={{ margin: 0 }}
          >
            <Switch
              onChange={handleBusyAreaToggle}
              size='default'
              disabled={disabled}
              style={{ margin: 0 }}
            />
          </Form.Item>
        </Col>
        <Col style={{ margin: 0 }}>
          <label htmlFor='busy_delivery_area_enabled'>
            <Typography.Text style={{ marginLeft: 12 }}>
              Enable Busy Mode Delivery Zone
            </Typography.Text>
            <Tooltip title="When enabled, this will only apply when 'Busy' is set as the Activity Mode in Preparation Time settings.">
              <QuestionCircleOutlined className='_mt-2 _ml-4' />
            </Tooltip>
          </label>
        </Col>
      </Row>

      {busyAreaActive && (
        <>
          <Row className='_mt-24' justify='space-between' align='middle'>
            <Typography.Title
              style={{ marginTop: '8px', marginBottom: '8px' }}
              level={5}
            >
              Busy Delivery Zone
            </Typography.Title>
            {isChanged && <Button onClick={handleRestore}>Undo Changes</Button>}
          </Row>
          <Row align='middle'>
            <Col className='delivery-zone' span={21}>
              <Form.Item
                className={`item ${isByTime ? '' : '-hidden'}`}
                name='busy_delivery_time'
              >
                <Slider
                  step={1}
                  min={MIN_MINUTES}
                  max={deliveryTime}
                  className='slider'
                  value={deliveryBusyTime}
                  onChange={handleBusyTimeUpdate}
                  disabled={disabled}
                  tooltipVisible={false}
                />
              </Form.Item>

              <Form.Item
                name='busy_delivery_radius'
                className={`item ${isByTime ? '-hidden' : ''}`}
              >
                <Slider
                  step={0.1}
                  min={MIN_MILES}
                  max={deliveryRadius}
                  className='slider'
                  value={deliveryBusyRadius}
                  onChange={handleBusyRadiusUpdate}
                  disabled={disabled}
                  tooltipVisible={false}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              {isByTime ? (
                <span>{deliveryBusyTime} minutes</span>
              ) : (
                <span>{deliveryBusyRadius} miles</span>
              )}
            </Col>
          </Row>
          {busyDeliveryAreaTouched && isChanged && (
            <div className='popup'>
              <Typography.Text style={{ marginTop: 0, color: '#1513A0' }}>
                You have set a custom busy delivery area; any new changes will
                override it.
              </Typography.Text>
              <Tooltip
                title={
                  <span>
                    For more information see{' '}
                    <a
                      href='https://support.slerp.com/knowledge/custom-delivery-radius'
                      rel='noreferrer'
                      target='_blank'
                    >
                      here
                    </a>
                  </span>
                }
              >
                <QuestionCircleOutlined
                  color='#77778E'
                  className='_mt-2 _ml-4'
                />
              </Tooltip>
            </div>
          )}
          <Form.Item className='map'>
            <DeliveryAreaSettings
              storeLocation={store.address.geom.coordinates}
              deliveryPaths={deliveryArea}
              busyDeliveryPaths={busyDeliveryArea || []}
              onTouched={handleTouch}
            />
          </Form.Item>
          <Row className='_mt-12'>
            <Col>
              <Typography.Text>
                The Busy Delivery Zone will automatically be activated when your
                location activity mode is ‘Busy’. When the activity mode is
                ‘Quiet’ or ‘Moderate’, the Regular Delivery Zone will be
                applied.
              </Typography.Text>
            </Col>
          </Row>

          <ReadKml onUpload={handleKmlUpload} />
        </>
      )}
    </>
  )
}

export default BusyDeliveryZone
