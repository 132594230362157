import { uuid } from 'uuidv4'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import {
  FulfillmentTypePricing,
  FulfillmentType,
  Variant,
  VariantPricing
} from './types'

const setDefaultPricing = (
  variant: Variant,
  fulfillmentType: FulfillmentType
) => {
  const { id, vat } = variant
  return {
    id: uuid(),
    variant_id: id,
    fulfillment_type: fulfillmentType,
    price: null,
    price_with_vat: null,
    vat: vat,
    published_at: 'now()'
  }
}

const getVariantPricing = (variants: Variant[]) =>
  variants.map((variant: Variant) => {
    const { pricings = [], name, id } = variant
    const deliveryPricing = pricings.find(
      ({ fulfillment_type }: FulfillmentTypePricing) =>
        fulfillment_type === 'delivery'
    )
    const pickupPricing = pricings.find(
      ({ fulfillment_type }: FulfillmentTypePricing) =>
        fulfillment_type === 'pickup'
    )
    const orderAtTablePricing = pricings.find(
      ({ fulfillment_type }: FulfillmentTypePricing) =>
        fulfillment_type === 'order_at_table'
    )

    return {
      variant_id: id,
      name,
      delivery: deliveryPricing || setDefaultPricing(variant, 'delivery'),
      pickup: pickupPricing || setDefaultPricing(variant, 'pickup'),
      order_at_table:
        orderAtTablePricing || setDefaultPricing(variant, 'order_at_table')
    }
  })

const isValidFloat = (val: number | null | string) => {
  const parsed = parseFloat(val)
  return !isNaN(parsed)
}

const anyPublishedFulfilmentTypePricing = (variants: Variant[]) => {
  return !isEmpty(flatten(variants.map(({ pricings }: Variant) => pricings)))
}
/*
const anyPublishedFulfilmentTypePricing = (
  variantPricings: VariantPricing[]
) => {
  return !isEmpty(
    variantPricings.find(
      ({ delivery, pickup, order_at_table }: VariantPricing) =>
        (delivery.published_at ||
          pickup.published_at ||
          order_at_table.published_at) != null
    )
  )
}
   */

export { getVariantPricing, isValidFloat, anyPublishedFulfilmentTypePricing }
