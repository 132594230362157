import React, { useState, useEffect } from 'react'
import { isNumber as isNumeric, isEmpty } from 'lodash'
import { Col, InputNumber, Row } from 'antd'
import { computeGrossPrice, computeNetPrice } from 'components/Utils/price'

interface VariantPriceFormProps {
  id: string
  vat: number
  price: number
  updateCorePricingHandler?: () => void
  coreVatChangeCallbackHandler?: () => void
}

const VariantPricingForm = ({
  id,
  price,
  vat,
  updateCorePricingHandler,
  coreVatChangeCallbackHandler
}: VariantPriceFormProps) => {
  const [grossPrice, setGrossPrice] = useState<number>(
    computeGrossPrice(price, vat)
  )
  const [defaultVat, setDefaultVat] = useState<number>(vat)
  const [priceWithoutVat, setPriceWithoutVat] = useState<number>(
    computeNetPrice(grossPrice, defaultVat)
  )

  useEffect(() => {
    setPriceWithoutVat(computeNetPrice(grossPrice, defaultVat))
  }, [grossPrice, defaultVat])

  const updatePrice = () => {
    const variables = {
      id,
      price: priceWithoutVat,
      vat: defaultVat
    }
    const vatUpdated = vat !== defaultVat

    updateCorePricingHandler(variables)
    if (vatUpdated) coreVatChangeCallbackHandler(id)
  }

  return (
    <Row
      gutter={[8]}
      align='middle'
      justify='end'
      className='_mb-0'
      key={id}
      data-testid='variant-general-pricing-form'
    >
      <Col span={8}>
        <InputNumber
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-price-form-gross-price-field'
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (isEmpty(e.target.value)) {
              return
            }
            updatePrice()
          }}
          onChange={(val: string | number | undefined) =>
            isNumeric(val) && setGrossPrice(val)
          }
          defaultValue={grossPrice}
        />
      </Col>
      <Col span={8}>
        <InputNumber
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-price-form-vat-field'
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (isEmpty(e.target.value)) {
              return
            }
            updatePrice()
          }}
          onChange={(val: string | number | undefined) => {
            if (isNumeric(val)) setDefaultVat(val)
          }}
          defaultValue={defaultVat}
        />
      </Col>
      <Col span={8}>
        <InputNumber
          disabled
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-price-form-price-field'
          defaultValue={priceWithoutVat}
          value={priceWithoutVat}
        />
      </Col>
    </Row>
  )
}

export default VariantPricingForm
