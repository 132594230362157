import React, { useState, useEffect } from 'react'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import Divider from 'antd/lib/divider'
import Form from 'antd/lib/form'
import message from 'antd/lib/message'
import PageHeader from 'antd/lib/page-header'
import Row from 'antd/lib/row'
import Space from 'antd/lib/space'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Typography from 'antd/lib/typography'

import CutoffTimeFields from './Cutoffs/CutoffFields'
import { useForm } from 'antd/lib/form/Form'
import { Cutoff, Store } from '@slerp/controls'
import {
  computeAllowance,
  formatCutoffs,
  getInitialAmount,
  getUnit
} from './utils'
import { UPDATE_CUTOFF_SETTINGS, CREATE_SETTINGS } from './actions'
import { RECALCULATE_STORE_TIMER } from '../actions'
import { useApolloClient, useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import TimePeriod from './Cutoffs/TimePeriod'
import SpecialCutoffTimesForm from './Cutoffs/SpecialCutoffTimesForm'
import { keyBy } from 'lodash'
import { Store as FormStore } from 'rc-field-form/lib/interface'
import ErrorMessage from 'components/Utils/ErrorMessage'
import { validateCutoffs } from '../PreOrderSettings'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'

const DEFAULT_PRE_ORDER_DAY_ALLOWANCE = 90

const CutoffTimesForm = ({ store }: { store: Store }) => {
  const { user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const settings =
    store.pre_order_settings?.length > 0 ? store.pre_order_settings[0] : null
  const [cutoffs, setCutoffs] = useState<{ [key: string]: Cutoff }>(
    formatCutoffs(settings?.cutoffs)
  )
  const [specialCutoffs, setSpecialCutoffs] = useState<{
    [key: string]: Cutoff
  }>(
    settings?.special_cutoffs_map
      ? keyBy(settings?.special_cutoffs_map, (cutoff) => cutoff.id)
      : {}
  )

  const [cutoffsValid, setCutoffsValid] = useState(
    validateCutoffs(settings?.cutoffs)
  )

  const [form] = useForm()
  const [updateStore, { loading, error }] = useMutation(
    settings ? UPDATE_CUTOFF_SETTINGS : CREATE_SETTINGS,
    { fetchPolicy: 'no-cache' }
  )
  const [recalculateStoreTimer] = useMutation(RECALCULATE_STORE_TIMER, {
    fetchPolicy: 'no-cache'
  })
  const client = useApolloClient()

  const onFinish = (values: FormStore) => {
    if (
      (settings?.delivery_enabled || settings?.pickup_enabled) &&
      !validateCutoffs(cutoffs)
    ) {
      message.error(
        'To enable pre-orders, you must enable at least one cut-off day',
        3
      )
      return setCutoffsValid(false)
    }

    if (form.validateFields()) {
      message.loading('Updating... Please wait.')
      const formatted_cutoffs = Object.keys(specialCutoffs).reduce(
        (acc: { [key: string]: Cutoff }, key: string) => {
          const cutoff = specialCutoffs[key]
          if (cutoff && !!cutoff.cutoff_date)
            return {
              ...acc,
              [cutoff.cutoff_date]: {
                ...cutoff,
                time: !!cutoff.time ? cutoff.time : '00:00:00'
              }
            }
          return acc
        },
        {}
      )

      updateStore({
        variables: {
          ...(settings ? {} : { id: uuid() }),
          store_id: store.id,
          special_cutoffs_map: formatted_cutoffs,
          cutoffs: { id: settings?.cutoffs?.id || uuid(), ...cutoffs },
          pre_order_day_allowance: computeAllowance(
            values.time_period_amount,
            values.time_period_unit
          )
        }
      })
        .then((result) => {
          recalculateStoreTimer({
            variables: {
              storeId: store.id
            }
          })
        })
        .finally(() => {
          message.destroy()
          client.resetStore()
          message.success('Updated pre-order cutoffs', 1)
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Unable to update pre-order cutoffs. Due to ${error.message}`,
            3
          )
        })
    }
  }

  const time_period_unit = getUnit(
    settings?.pre_order_day_allowance || DEFAULT_PRE_ORDER_DAY_ALLOWANCE
  )
  const time_period_amount = getInitialAmount(
    settings?.pre_order_day_allowance || DEFAULT_PRE_ORDER_DAY_ALLOWANCE,
    time_period_unit
  )

  const validateTimePeriodAmount = () =>
    form.validateFields(['time_period_amount'])

  useEffect(() => {
    form.validateFields(['time_period_amount'])
  }, [time_period_unit, time_period_amount])

  return (
    <Form
      data-testid='preorder-cutoff-settings-form'
      colon={false}
      form={form}
      onFinish={onFinish}
      initialValues={{ time_period_amount, time_period_unit }}
      scrollToFirstError
      labelCol={{ span: 24 }}
    >
      {error && (
        <ErrorMessage
          title='Cutoff settings could not be updated'
          subheading={`Error: ${error}`}
        />
      )}

      <Row className='_mb-0 settings-title' align='middle'>
        <Col>
          <span className='_text-weight-600 _text-font-size-14'>
            Cut-off times
          </span>
        </Col>
        <Col>
          <Tooltip
            title='Cut-off time allows you to set a time that the customer must place the order to get it for that day.'
            placement='right'
          >
            <QuestionCircleOutlined className='_ml-8' />
          </Tooltip>
        </Col>
      </Row>
      {!cutoffsValid && (
        <p className='_cl-text-error'>
          To enable pre-orders, you must enable at least one cut-off day
        </p>
      )}
      <Form.Item>
        {Object.keys(cutoffs).map((key) => {
          const cutoff = cutoffs[key]
          if (cutoff)
            return (
              <CutoffTime
                day={key}
                cutoff={cutoff}
                updateCutoff={(value: Cutoff) =>
                  setCutoffs({ ...cutoffs, [key]: value })
                }
                key={`cutoffs-${cutoff.id}`}
                disabled={!canManage}
              />
            )
        })}
      </Form.Item>

      <Form.Item
        label={
          <span className='_text-weight-600 _text-font-size-14'>
            Special Cut-off times
          </span>
        }
        tooltip='Special cut-off times let you change your usual cut-offs. You can either extend or restrict order placements beyond your normal cut-off times.'
      >
        <SpecialCutoffTimesForm
          cutoffs={specialCutoffs}
          setCutoffs={setSpecialCutoffs}
          disabled={!canManage}
        />
      </Form.Item>

      <Form.Item
        label={
          <span className='_text-weight-600 _text-font-size-14'>
            Pre-order time period
          </span>
        }
        tooltip={
          <span>
            The pre-order period allows you to specify how far in advance a
            customer can place an order.
            <br />
            <br />
            The maximum period can be set to 90 Days, 12 Weeks or 6 Months.
          </span>
        }
      >
        <TimePeriod
          disabled={!canManage}
          timePeriodUnitChangeHandler={validateTimePeriodAmount}
        />
      </Form.Item>

      <Divider />
      <Form.Item>
        <Button
          data-testid='save-button'
          loading={loading}
          title='Save'
          htmlType='submit'
          className='_ml-auto _center-vertical'
          disabled={!canManage}
        >
          {loading ? 'Saving' : 'Save'}
        </Button>
      </Form.Item>
    </Form>
  )
}

type CutoffTimeProps = {
  day: string
  cutoff: Cutoff
  updateCutoff: (cutoff: Cutoff) => void
  disabled?: boolean
}

const CutoffTime = ({
  day,
  cutoff,
  updateCutoff,
  disabled = false
}: CutoffTimeProps) => {
  const { enabled, time, day_diff } = cutoff

  return (
    <Form.Item data-testid={`cutoff-row-${day}`} labelAlign='left'>
      <Row className='_mb-0' align='middle'>
        <Col span={12}>
          <Switch
            data-testid='cutoff-row-day-switch'
            onChange={(checked) => {
              const time = checked ? '23:59:00' : cutoff.time
              updateCutoff({ ...cutoff, enabled: checked, time })
            }}
            checked={enabled}
            disabled={disabled}
          />
          <Typography.Text
            style={{ textTransform: 'capitalize', marginLeft: '8px' }}
          >
            {day}
          </Typography.Text>
        </Col>
        <Col>
          {enabled && (
            <CutoffTimeFields
              updateCutoff={(key: string, value: any) =>
                updateCutoff({ ...cutoff, [key]: value })
              }
              time={time}
              dayDiff={day_diff}
              disabled={disabled}
            />
          )}
        </Col>
      </Row>
    </Form.Item>
  )
}

export default CutoffTimesForm
