import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { isActionKeyPressed } from '../utils'
import { Text } from '../../components/Text'

const SaveAttempt = () => {
  const [isAttemptingToSave, setIsAttemptingToSave] = useState(false)
  const timeoutRef = useRef<number>()

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      if (isActionKeyPressed(evt) && evt.key.toLowerCase() === 's') {
        evt.preventDefault()

        window.clearTimeout(timeoutRef.current)

        setIsAttemptingToSave(true)

        timeoutRef.current = window.setTimeout(() => {
          setIsAttemptingToSave(false)
        }, 3000)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (!isAttemptingToSave) return null

  return (
    <Container>
      <Text>
        {/* eslint-disable jsx-a11y/accessible-emoji */}
        <span role='img' arial-label='Magic'>
          ✨
        </span>{' '}
        Changes are saved automagically.
      </Text>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  right: 32px;
  bottom: 32px;
  padding: 16px;
  color: ${({ theme }: any) => theme.colors.white};
  background: ${({ theme }: any) => theme.colors.gray900};
  z-index: ${({ theme }: any) => theme.zIndex.autosave};
  border-left: 6px solid ${({ theme }: any) => theme.colors.gray500};
  border-right: 6px solid transparent;
  border-radius: 4px;
  pointer-events: none;
  user-touch: none;
`

export { SaveAttempt }
