import React, { useEffect, useState } from 'react'
import { useSession } from '@slerp/accounts'
import { REPORTS_ENDPOINT, JWT_ENCRYPTION_KEY, JWT_SECRET } from '@slerp/client'
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Radio,
  Checkbox,
  Row,
  Select,
  Tooltip,
  TimePicker
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import _ from 'lodash'
import * as jwtEncrypt from 'jwt-token-encrypt'
import {
  linePerOrderItemFields,
  linePerOrderFields,
  orderStatusFields
} from './constants'
import { buildReportingDate } from '../../Utils/Reports'
import './styles.css'

interface OptionType {
  label: string
  value: string
}

const { Option } = Select

const dateFormat = 'YYYY-MM-DD'

const getOutputFields = (reportType: string) => {
  return reportType === 'orderitem'
    ? linePerOrderItemFields
    : linePerOrderFields
}

const SelectOptions = (options: OptionType[]) => {
  return options.map((field) => (
    <Option key={field.value} value={field.value}>
      {field.label}
    </Option>
  ))
}

const OrderSummaryFilters = () => {
  const { merchant } = useSession()
  const [jwtToken, setJwtToken] = useState('')
  const [reportUrl, setReportUrl] = useState('')
  const [startTime, setStartTime] = useState()
  const [startDate, setStartDate] = useState<moment.Moment | null | undefined>(
    moment()
  )
  const [endTime, setEndTime] = useState()
  const [endDate, setEndDate] = useState<moment.Moment | null | undefined>(
    moment()
  )
  const [fileType, setFileType] = useState('csv')
  const [dateType, setDateType] = useState('fulfillment')
  const [reportType, setReportType] = useState('order')
  const [vatTypes, setVatTypes] = useState([
    'value_including_vat',
    'value_excluding_vat',
    'vat_percentage'
  ])
  const [outputFields, setOutputFields] = useState(getOutputFields(reportType))
  const [selectedOutputFields, setSelectedOutputFields] = useState(
    _.chain(getOutputFields(reportType))
      .map((outputField) => outputField.value)
      .value()
  )
  const [selectedStatuses, setSelectedStatuses] = useState(
    orderStatusFields.map((orderStatusField) => orderStatusField.value)
  )
  const vatTypeOptions = [
    { label: 'Value including VAT', value: 'value_including_vat' },
    { label: 'Value excluding VAT', value: 'value_excluding_vat' },
    { label: 'Percentage VAT', value: 'vat_percentage' }
  ]

  const getJwtToken = async () => {
    const publicData = {
      merchant_id: merchant.id,
      merchant_slug: merchant.slug
    }
    const encryption = {
      key: JWT_ENCRYPTION_KEY,
      algorithm: 'aes-256-cbc'
    }
    const jwtDetails = {
      secret: JWT_SECRET,
      key: 'slerp'
    }
    const token = await jwtEncrypt.generateJWT(
      jwtDetails,
      publicData,
      encryption
    )
    return token
  }

  const dateChangeHandler = (
    target: 'startDate' | 'endDate' | 'startTime' | 'endTime',
    value: moment.Moment | null | undefined
  ) => {
    switch (target) {
      case 'startDate':
        setStartDate(value)
        break
      case 'endDate':
        setEndDate(value)
        break
      case 'startTime':
        setStartTime(value)
        break
      case 'endTime':
        setEndTime(value)
        break
    }
  }

  useEffect(() => {
    getJwtToken()
      .then((token) => setJwtToken(token))
      .catch((err) => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOutputFields(getOutputFields(reportType))
    const additionalOrderItemsFields = [
      'name',
      'modifiers',
      'category',
      'price_per_quantity',
      'quantity',
      'sku'
    ]

    if (reportType === 'order') {
      setSelectedOutputFields((prev: string[]) =>
        prev.filter(
          (value: string) => !additionalOrderItemsFields.includes(value)
        )
      )
    }

    if (reportType === 'orderitem') {
      setSelectedOutputFields((prev: string[]) => [
        ...prev,
        ...additionalOrderItemsFields
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  useEffect(() => {
    const baseUrl = REPORTS_ENDPOINT
    const startDateTimeString = buildReportingDate(
      startDate,
      startTime,
      'start'
    )
    const endDateTimeString = buildReportingDate(endDate, endTime, 'end')

    const params = new URLSearchParams()

    params.set('JWT', jwtToken)
    params.set('startDateTime', startDateTimeString)
    params.set('endDateTime', endDateTimeString)
    params.set('outputType', fileType)
    params.set('reportType', reportType)
    params.set('dateType', dateType)

    vatTypes.forEach((vat) => {
      params.append('outputField', vat)
    })

    const outputFieldValues = outputFields.map((field) => field.value)
    selectedOutputFields
      .filter((outputField) => outputFieldValues.includes(outputField))
      .forEach((outputFieldValue) => {
        params.append('outputField', outputFieldValue)
      })

    selectedStatuses.forEach((statusFieldValue) => {
      params.append('status', statusFieldValue)
    })

    setReportUrl(`${baseUrl}/?${params.toString()}`)
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    fileType,
    reportType,
    dateType,
    selectedOutputFields,
    selectedStatuses,
    jwtToken,
    vatTypes
  ])

  const copyReportUrl = () => {
    const copyText = document.querySelector(
      '#reportUrlInput'
    ) as HTMLInputElement
    copyText && copyText.select()
    document.execCommand('copy')
    message.success('Link Copied!', 3)
  }

  return (
    <>
      <Input id='reportUrlInput' value={reportUrl} className='reportInput' />
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>Start Date</Col>
        <Col span={6}>
          <DatePicker
            defaultValue={startDate ? startDate : undefined}
            format={dateFormat}
            onChange={(value) => dateChangeHandler('startDate', value)}
          />
        </Col>
        <Col span={4}>Start Time</Col>
        <Col>
          <TimePicker
            allowClear={false}
            format='hh:mm a'
            minuteStep={60}
            use12Hours={true}
            onChange={(value) => dateChangeHandler('startTime', value)}
          />
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>End Date</Col>
        <Col span={6}>
          <DatePicker
            defaultValue={endDate ? endDate : undefined}
            format={dateFormat}
            onChange={(value) => dateChangeHandler('endDate', value)}
          />
        </Col>
        <Col span={4}>End Time</Col>
        <Col>
          <TimePicker
            allowClear={false}
            format='hh:mm a'
            minuteStep={60}
            use12Hours={true}
            onChange={(value) => dateChangeHandler('endTime', value)}
          />
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>Filter/Order by</Col>
        <Col>
          <Radio.Group
            onChange={(e) => setDateType(e.target.value)}
            defaultValue={dateType}
          >
            <Radio value='fulfillment'>Fullfilment date</Radio>
            <Radio value='inserted'>Order date</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>VAT Fields</Col>
        <Col>
          <Checkbox.Group
            options={vatTypeOptions}
            defaultValue={vatTypes}
            onChange={(value: string[]) => setVatTypes(value)}
          />
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>Show rows per</Col>
        <Col>
          <Radio.Group
            onChange={(e) => setReportType(e.target.value)}
            defaultValue={reportType}
          >
            <Radio value='order'>Order</Radio>
            <Radio value='orderitem'>
              Order item
              <Tooltip
                title={
                  <span>
                    Certain export fields such as Stamps Collected and Rewards
                    Redeemedmay only display on the first row of an order item.
                  </span>
                }
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>Output fields</Col>
        <Col flex='500px'>
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            defaultValue={selectedOutputFields}
            value={selectedOutputFields}
            onChange={(fields: Array<string>) =>
              setSelectedOutputFields(fields)
            }
            dropdownClassName='select-multiple'
          >
            {SelectOptions(outputFields)}
          </Select>
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>Status</Col>
        <Col flex='500px'>
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            defaultValue={selectedStatuses}
            onChange={(fields: Array<string>) => setSelectedStatuses(fields)}
            dropdownClassName='select-multiple'
          >
            {SelectOptions(orderStatusFields)}
          </Select>
        </Col>
      </Row>
      <Row justify='start' gutter={[16, 16]} align='middle'>
        <Col span={6}>File type</Col>
        <Col>
          <Radio.Group
            onChange={(e) => setFileType(e.target.value)}
            defaultValue={fileType}
          >
            <Radio value='csv'>CSV</Radio>
            <Radio value='xlsx'>XLSX</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row justify='center' gutter={[16, 16]}>
        <Col>
          <Button onClick={copyReportUrl}>Copy Link</Button>
        </Col>
        <Col>
          <Button href={reportUrl} target='_blank' rel='noopener noreferrer'>
            Export
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default OrderSummaryFilters
