import { FaChevronDown } from 'react-icons/fa'
import React, { useState } from 'react'

export type EnvType =
  | 'development'
  | 'preprod'
  | 'demo'
  | 'staging'
  | 'production'

/*
  You need to restart the server if you changed branches
  while the server is running to see the updated branch label
*/

const VersionBanner = ({
  environment,
  branch,
  commitRef
}: {
  environment: EnvType
  branch: string
  commitRef?: string
}) => {
  const [displayed, setDisplayed] = useState(true)
  return (
    <div
      className={`version-banner-container ${
        environment === 'production' ? '-hidden' : ''
      }`}
    >
      <div
        onClick={() => setDisplayed(!displayed)}
        className={`version-banner ${!displayed ? '-hidden' : ''}`}
      >
        <div className='text'>
          <strong>{environment}: </strong>
          {branch}
          {commitRef ? `@${commitRef.substring(0, 7)}` : ''}
        </div>
        <FaChevronDown className='icon' />
      </div>
    </div>
  )
}

export default VersionBanner
