import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { GetShiftLengthInSeconds, ValidateShift } from './utils'
import DayShiftRange from './DayShiftRange'
import { DayOfWeek, SplitHour } from './types'

interface DayProps {
  dayOfWeek: DayOfWeek
  shifts: SplitHour[] | []
  weeklyShifts: { [key: string]: SplitHour }
  updateShifts: () => void
  disabled: boolean
}

const Day = ({
  dayOfWeek,
  shifts,
  weeklyShifts,
  updateShifts,
  disabled
}: DayProps) => {
  const [dayShifts, setDayShifts] = useState<SplitHour[]>(shifts)
  const defaultShiftShape = {
    day_of_week: dayOfWeek,
    start_time: '08:00:00',
    seconds_length: 3600,
    enabled: true,
    special_date: null,
    closed: false
  }
  const addNewShift = () => {
    const newDayShifts = [...dayShifts, defaultShiftShape]
    setDayShifts(newDayShifts)
    updateShifts(dayOfWeek, newDayShifts)
  }
  const updateShiftStart = (startTime: string, index: number) => {
    const newDayShifts = [...dayShifts]
    newDayShifts[index].start_time = startTime ? `${startTime}:00` : '08:00:00'
    setDayShifts(newDayShifts)
    updateShifts(dayOfWeek, newDayShifts)
  }
  const updateShiftDuration = (
    endTime: string,
    startTime: string,
    index: number
  ) => {
    const shiftLength = GetShiftLengthInSeconds(startTime, endTime)
    const newDayShifts = [...dayShifts]

    if (newDayShifts[index]) {
      newDayShifts[index].seconds_length = shiftLength
      setDayShifts(newDayShifts)
      updateShifts(dayOfWeek, newDayShifts)
    }
  }

  const removeShift = (index: number) => {
    const newDayShifts = [...dayShifts]
    if (newDayShifts[index]) {
      newDayShifts.splice(index, 1)
      updateShifts(dayOfWeek, newDayShifts)
    }
    setDayShifts(newDayShifts)
  }

  return (
    <Row className='_mb-32' align='top' data-testid='split-hours-day-row'>
      <Col span={4}>
        <Row
          align='middle'
          className='_mb-0'
          data-testid='split-hours-day-row-day-name'
        >
          {capitalize(dayOfWeek)}:
        </Row>
      </Col>
      <Col span={16}>
        {dayShifts
          .filter((splitHour: SplitHour) => splitHour.enabled)
          .map((splitHour: SplitHour, index: number) => {
            const { start_time, seconds_length } = splitHour
            const classNames = ValidateShift(
              splitHour,
              dayShifts,
              weeklyShifts,
              dayOfWeek,
              index
            )
              ? ''
              : '-error'
            return (
              <DayShiftRange
                classNames={classNames}
                disabled={disabled}
                index={index}
                secondsLength={seconds_length}
                startTime={start_time}
                updateShiftStart={updateShiftStart}
                updateShiftDuration={updateShiftDuration}
                removeShift={removeShift}
              />
            )
          })}
        <Row align='top' className='_mb-0'>
          <Col>
            <Button
              icon={<PlusCircleOutlined />}
              type='link'
              size='small'
              disabled={disabled}
              className='-primary'
              data-testid='split-hours-day-row-add'
              onClick={addNewShift}
            >
              Add shift
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Day
