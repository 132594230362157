import React, { useState } from 'react'
import { Col, Avatar, Row, Upload } from 'antd'
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons'
import { UploadModifierImage } from '../Actions'
import { generateAssetURL } from 'packages/@slerp/helpers/asset-url'

interface ModifierImagesProps {
  modifierId: string
  modifierName: string
  modifierImage: string | null
}

const ModifierImages = ({
  modifierId,
  modifierName,
  modifierImage
}: ModifierImagesProps) => {
  const [imageUrl, setImageUrl] = useState(
    generateAssetURL({
      id: modifierId,
      type: 'modifier',
      size: 'original',
      fileName: modifierImage ?? '',
      itemName: modifierName
    })
  )
  const [imageUploadLoading, setImageUploadLoading] = useState(false)

  const beforeImageUpload = (file: File) => {
    setImageUploadLoading(true)
    return true // this always expects a boolean return value
  }
  return (
    <>
      <Row justify='center'>
        <Col>
          <Upload
            name='avatar'
            accept='.png, .jpg, .jpeg'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            customRequest={({ file, onSuccess, onError }) =>
              UploadModifierImage({
                modifierId,
                image: file
              })
                .then((res) => {
                  const { image: url } = res.data.uploadModifierImageFile
                  setImageUploadLoading(false)
                  setImageUrl(url)
                  onSuccess(res, file)
                })
                .catch((error: Error) => {
                  setImageUploadLoading(false)
                  onError(error)
                })
            }
            beforeUpload={beforeImageUpload}
          >
            {!imageUploadLoading ? (
              <Avatar size={400} shape='square' src={imageUrl} />
            ) : imageUploadLoading ? (
              <LoadingOutlined />
            ) : (
              <FileImageOutlined />
            )}
          </Upload>
        </Col>
      </Row>
    </>
  )
}

export default ModifierImages
