import React from 'react'
import {
  ApolloProvider as OriginalApolloProvider,
  ApolloClient,
  useApolloClient,
  InMemoryCache,
  HttpLink,
  split
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { createLink } from 'apollo-absinthe-upload-link'
import { useConfig } from '../Config'
import env from '../../../../../env'

const scheme = (proto: string) => {
  if (process.env.NODE_ENV === 'production') {
    return 'https'
  } else {
    if (env.GRAPH_HOST === 'graph.api.slerpdemo.com') return 'https'
    return window.location.protocol === 'https:' ? `${proto}s` : proto
  }
}

const GRAPH_HOST =
  process.env.NODE_ENV === 'production'
    ? env.GRAPH_HOST
    : process.env.REACT_APP_GRAPH_HOST || env.GRAPH_HOST

const GRAPHQL_ENDPOINT = `${scheme('https')}://${GRAPH_HOST}/v1/graphql`
const ABSINTHE_GRAPHQL_ENDPOINT = `https://${env.ASSET_HOST}/graphql`
const ABSINTHE_OPERATION_NAMES = ['uploadMerchantImage']

const httpLink = (apiKey: string) =>
  new HttpLink({
    uri: GRAPHQL_ENDPOINT,
    headers: { authorization: apiKey }
  })

// Since we now use JWT. For backwards compatibility, use the user token.
const parseJson = (value?: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return {}
  }
}

const userDetails = localStorage.getItem('user') || undefined
const user = parseJson(userDetails)

const splitLink = (apiKey = undefined || '') => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        ABSINTHE_OPERATION_NAMES.includes(definition?.name?.value || '')
      )
    },
    createLink({
      uri: ABSINTHE_GRAPHQL_ENDPOINT,
      headers: { authorization: user?.api_key }
    }),
    httpLink(apiKey)
  )
}

const createClient = (apiKey: string) => {
  const client = new ApolloClient({
    link: splitLink(apiKey),
    cache: new InMemoryCache({
      addTypename: false
    })
  })

  return client
}

const ApolloProvider: React.FC = (props) => {
  const { config } = useConfig()

  return (
    <OriginalApolloProvider client={createClient(config.apiKey)}>
      {props.children}
    </OriginalApolloProvider>
  )
}

export { ApolloProvider, useApolloClient }
