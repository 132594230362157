import { gql } from '@apollo/client'

const QUERY_MERCHANT_SIGNATURE_AND_TERMS = gql`
  query getMerchantSignatureAndTerms($merchantId: uuid!) {
    signatures(
      where: { merchant_id: { _eq: $merchantId } }
      order_by: { inserted_at: desc_nulls_last }
    ) {
      first_name
      last_name
      inserted_at

      term {
        terms_and_conditions
        inserted_at
      }
    }
  }
`

const QUERY_STRIPE_PUBLISHABLE_KEY = gql`
  query getPublishableKey($merchantId: uuid!) {
    getStripePublishableKey(merchantId: $merchantId)
  }
`

const QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS = gql`
  query franchiseAttribution($merchantId: uuid!) {
    franchiseAttribution(merchantId: $merchantId) {
      delivery
      pickup
      selfDelivery
      nationwideDelivery
      table
      store {
        id
        name
        slug
      }
      user {
        id
        firstname
        lastname
      }
      stripeUserId
      lastUpdatedAt
    }
  }
`

export {
  QUERY_MERCHANT_SIGNATURE_AND_TERMS,
  QUERY_STRIPE_PUBLISHABLE_KEY,
  QUERY_MERCHANT_FRANCHISE_ATTRIBUTIONS
}
