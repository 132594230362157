import React from 'react'
import { Modal } from 'antd'
import { CSVLink } from 'react-csv'
import { useSlerp } from '@slerp/client'

interface FailedEmailsStampModalProps {
  emails: string[]
  onClose: () => void
  visible: boolean
}

const FailedEmailsStampModal = (props: FailedEmailsStampModalProps) => {
  const { emails, onClose, visible } = props
  const { user } = useSlerp()

  const csv = emails.map((email) => [email])
  const fileName = `${
    user?.merchant?.slug
  }_failed_emails_stamp_${Date.now()}.csv`

  const ModalFooter = (
    <>
      <div />
      <CSVLink
        data={csv}
        filename={fileName}
        className='ant-btn'
        data-testid='failed_emails_stamp_export'
      >
        Export Customers
      </CSVLink>
    </>
  )

  const ModalTitle = (
    <div className='qr-modal-header'>
      <h2 className='title'>Failed Emails</h2>
    </div>
  )

  return (
    <Modal
      closable={false}
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      forceRender={true}
      title={ModalTitle}
      footer={ModalFooter}
      className='loyalty-qr-modal'
    >
      {emails.map((email) => (
        <p>{email}</p>
      ))}
    </Modal>
  )
}

export default FailedEmailsStampModal
