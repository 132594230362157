import React, { useContext, useLayoutEffect } from 'react'

interface FrameAutoSizerProps {
  sizer: string
  document: HTMLDocument
  onChange: ({ height }: { height: number }) => void
}

interface FrameAutoSizerContextType {
  recalculateHeight: () => void
}

// Expose manual trigger (e.g., when fonts load on CanvasFontLoader)
const FrameAutoSizerContext = React.createContext<FrameAutoSizerContextType>(
  {} as FrameAutoSizerContextType
)

const FrameAutoSizer: React.FC<FrameAutoSizerProps> = ({
  sizer,
  document,
  onChange,
  children
}) => {
  useLayoutEffect(() => {
    onChange({ height: document.body.scrollHeight })
  }, [sizer]) // eslint-disable-line

  const recalculateHeight = () => {
    onChange({ height: document.body.scrollHeight })
  }

  return (
    <FrameAutoSizerContext.Provider value={{ recalculateHeight }}>
      {children}
    </FrameAutoSizerContext.Provider>
  )
}

const useFrameAutoSizer = () => {
  return useContext(FrameAutoSizerContext)
}

export { FrameAutoSizer, useFrameAutoSizer }
