import React from 'react'
import { uuid } from 'uuidv4'
import { useMutation } from '@apollo/client'
import { Store as FormStore } from 'antd/lib/form/interface'
import { Button, Col, Form, Select, Row } from 'antd'
import { filterSelectOption } from '@slerp/antd-utils'
import { CREATE_PRODUCT_MODIFIER_GROUPS } from '../../ProductQueries'
import { requiredRule } from './rules'
import '../../Products.css'

interface ModifierArrangement {
  modifier: {
    id: string
    name: string
  }
}

interface ModifierGroup {
  id: string
  name: string
  sku: string | null
  minimum_enabled: boolean
  minimum: number
  maximum_enabled: boolean
  maximum: number
  archived_at: string | null
  modifier_arrangement: ModifierArrangement[]
}

interface ProductModifierGroupFormProps {
  modifierGroups: ModifierGroup[]
  productId: string
  onCancel?: () => void
  onSaveStart?: () => void
  onSaveEnd?: () => void
  onSaveError?: (errorMessage?: string) => void
  currentModifierArrangements: string[]
}

const { Option } = Select
const { useForm } = Form

const ProductModifierGroupForm = (props: ProductModifierGroupFormProps) => {
  const {
    productId,
    modifierGroups,
    onCancel,
    onSaveStart,
    onSaveEnd,
    onSaveError,
    currentModifierArrangements
  } = props

  const [createProductModifierGroup, { loading: savingProductModifierGroup }] =
    useMutation(CREATE_PRODUCT_MODIFIER_GROUPS)
  const [form] = useForm()

  const onFinish = (values: FormStore) => {
    if (form.validateFields()) {
      onSaveStart && onSaveStart()
      const { modifier_group_id } = values
      const updatedArrangement = [...currentModifierArrangements]
      updatedArrangement.push(modifier_group_id)

      createProductModifierGroup({
        variables: {
          values: {
            id: uuid(),
            product_id: productId,
            modifier_group_id
          },
          productId,
          modifierGroupArrangement: { ...updatedArrangement }
        }
      })
        .then((result) => {
          onSaveEnd && onSaveEnd()
        })
        .catch((error) => {
          onSaveError && onSaveError(error.message)
        })
    }
  }

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col span={12}>
            <Row>
              <Col>Modifier Groups</Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name='modifier_group_id' rules={requiredRule}>
                  <Select
                    showSearch
                    className='width-100'
                    data-testid='variants-mod-group-form-modifier-group-id'
                    optionFilterProp='name'
                    filterOption={filterSelectOption}
                  >
                    {modifierGroups.map((modifierGroup) => {
                      const { id, name, description } = modifierGroup
                      return (
                        <Option
                          key={id}
                          value={id}
                          data-testid={`variants-mod-group-form-modifier-group-${id}`}
                          data-qa={id}
                        >
                          {`${name} (${description})`}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row align='middle'>
              <Col span={12}>
                <Row justify='start'>
                  <Button
                    loading={savingProductModifierGroup}
                    data-testid='product-variant-form-discard'
                    size='small'
                    onClick={() => onCancel && onCancel()}
                  >
                    Discard
                  </Button>
                </Row>
              </Col>
              <Col span={12}>
                <Row justify='end'>
                  <Button
                    loading={savingProductModifierGroup}
                    size='small'
                    type='primary'
                    htmlType='submit'
                    data-testid='product-variant-form-add-product'
                  >
                    Add
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ProductModifierGroupForm
