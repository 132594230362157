import React from 'react'
import { useMutation, useApolloClient } from '@apollo/client'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  PageHeader,
  Row,
  Slider,
  message,
  Tooltip
} from 'antd'
import { Store, Merchant } from '@slerp/controls'
import { UPDATE_STORE_PREPARATION_TIME } from './../actions'

interface PreparationTimeProps {
  disabled: boolean
  store: Store
  merchant: Merchant
}

interface FormValues {
  quiet_preparation_time: number | 5
  moderate_preparation_time: number | 5
  busy_preparation_time: number | 5
}

const PreparationTime = (props: PreparationTimeProps) => {
  const { disabled = false, store, merchant } = props
  const [form] = Form.useForm()
  const client = useApolloClient()

  const onFinish = (values: FormValues) => {
    const {
      quiet_preparation_time,
      moderate_preparation_time,
      busy_preparation_time
    } = values
    message.loading('Updating... Please wait.')

    updateStorePreparationTime({
      variables: {
        merchant_id: merchant.id,
        id: store.id,
        settings: {
          quiet_preparation_time: quiet_preparation_time,
          moderate_preparation_time: moderate_preparation_time,
          busy_preparation_time: busy_preparation_time
        }
      }
    })
      .then(() => {
        message.destroy()
        message.success('Store Updated', 1)
      })
      .catch(() => {
        message.error(`Unable to update settings due to: ${error}`, 5)
      })
      .finally(() => {
        client.resetStore()
      })
  }

  const [updateStorePreparationTime, { loading, error }] = useMutation(
    UPDATE_STORE_PREPARATION_TIME,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const initialFormValues = {
    quiet_preparation_time: store?.settings.quiet_preparation_time,
    moderate_preparation_time: store?.settings.moderate_preparation_time,
    busy_preparation_time: store?.settings.busy_preparation_time
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const activityModeSliderMarks = {
    5: '5 mins',
    30: '30 mins',
    60: '60 mins'
  }

  return (
    <Form
      data-testid='preparation-time-form'
      {...layout}
      form={form}
      initialValues={initialFormValues}
      onFinish={onFinish}
    >
      <PageHeader title='Activity Mode' className='settings-title' />
      <Form.Item name='quiet_preparation_time' label='Quiet'>
        <Slider
          marks={activityModeSliderMarks}
          step={5}
          min={5}
          max={60}
          tipFormatter={(val) => `${val} mins`}
          className='_ml-24 _mr-24'
          disabled={disabled}
          data-testid='quiet-preparation-time-slider'
        />
      </Form.Item>
      <Form.Item name='moderate_preparation_time' label='Moderate'>
        <Slider
          marks={activityModeSliderMarks}
          step={5}
          min={5}
          max={60}
          tipFormatter={(val) => `${val} mins`}
          className='_ml-24 _mr-24'
          disabled={disabled}
          data-testid='moderate-preparation-time-slider'
        />
      </Form.Item>
      <Form.Item
        name='busy_preparation_time'
        label={
          <>
            Busy
            <Tooltip
              zIndex={9999999}
              title='When Busy Mode is activated, the Busy Mode Delivery Zone may apply. See the Delivery tab to set up.'
            >
              <QuestionCircleOutlined className='_mt-2 _ml-4' />
            </Tooltip>
          </>
        }
      >
        <Slider
          marks={activityModeSliderMarks}
          step={5}
          min={5}
          max={60}
          tipFormatter={(val) => `${val} mins`}
          className='_ml-24 _mr-24'
          disabled={disabled}
          data-testid='busy-preparation-time-slider'
        />
      </Form.Item>
      <Row className='_mt-32'>
        <Col>
          <Button
            title='Save'
            htmlType='submit'
            className='_center-vertical _ml-auto'
            disabled={disabled}
            loading={loading}
            data-testid='preparation-time-save-btn'
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default PreparationTime
