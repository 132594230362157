import React, { useState } from 'react'
import {
  PageHeader,
  Button,
  Form,
  Row,
  Col,
  Select,
  Radio,
  Input,
  Space,
  Switch,
  Tooltip,
  message
} from 'antd'
import { useSession, isAdmin, isManager } from '@slerp/accounts'
import {
  QuestionCircleOutlined,
  PercentageOutlined,
  PoundOutlined
} from '@ant-design/icons'
import { uuid } from 'uuidv4'
import { LocationSettingProps } from '../../Settings'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { UpdateStoreSettings } from './Actions'
import { useQuery } from '@apollo/client'
import { QUERY_STORE_INTEGRATION_SETTINGS } from 'components/Locations/LocationQueries'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'
interface FetcherType {
  refetch: () => void
}

interface StoreIntegrationSettings {
  store_integration_settings: Array<{ id: string; order_with_google: boolean }>
}

const AdvancedStoreSettings = ({
  merchant,
  store,
  refetch
}: LocationSettingProps & FetcherType) => {
  const { user } = useSession()
  const canManage = isAdmin(user) || isManager(user)
  const [form] = Form.useForm()
  const {
    additional_fee,
    tip_percentages,
    delivery_tipping_enabled,
    pickup_tipping_enabled,
    oat_tipping_enabled
  } = store.settings

  const [orderWithGoogleEnabled, setOrderWithGoogleEnabled] =
    useState<boolean>(false)

  const {
    data,
    loading,
    refetch: refetchStoreIntegrationSettings
  } = useQuery<StoreIntegrationSettings>(QUERY_STORE_INTEGRATION_SETTINGS, {
    variables: {
      storeId: store.id
    },
    onCompleted: (data: StoreIntegrationSettings) => {
      if (!data) return

      const { order_with_google } = data.store_integration_settings[0] ?? false
      setOrderWithGoogleEnabled(order_with_google)
    },
    onError: (error) => {
      message.error(
        `Unable to load store integration settings due to: ${error}`,
        5
      )
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const [additionalFeeEnabled, setAdditionalFeeEnabled] = useState<boolean>(
    !!additional_fee?.is_active
  )
  const [tippingEnabled, setTippingEnabled] = useState<boolean>(
    delivery_tipping_enabled || pickup_tipping_enabled || oat_tipping_enabled
  )
  const [selectedAmountType, setSelectedAmountType] = useState<
    'fixed' | 'percentage'
  >(additional_fee?.amount_type || 'fixed')

  const DISABLED_FEE_VALUES = {
    id: uuid(),
    is_active: false,
    amount: 0.0,
    name: '',
    amount_type: 'fixed',
    fulfillment_types: {
      id: uuid(),
      pickup: false,
      delivery: false,
      table_ordering: false
    }
  }

  const initialFormValues = {
    name: additional_fee?.name || '',
    amount: additional_fee?.amount || 0.0,
    is_active: additional_fee?.is_active || false,
    amount_type: additional_fee?.amount_type || 'fixed',
    fulfillment_types: {
      pickup: !!additional_fee?.fulfillment_types?.pickup,
      delivery: !!additional_fee?.fulfillment_types?.delivery,
      table_ordering: !!additional_fee?.fulfillment_types?.table_ordering
    },
    enable_tippings: {
      delivery_tipping_enabled: delivery_tipping_enabled,
      pickup_tipping_enabled: pickup_tipping_enabled,
      oat_tipping_enabled: oat_tipping_enabled
    },
    tip_percentages: tip_percentages.filter((tp) => tp > 0)
  }

  const FulfillmentTypeValidatorRule = ({ getFieldValue }: any) => ({
    validator() {
      const selectedTypes = getFieldValue('fulfillment_types')
      if (Object.values(selectedTypes).some((selected) => selected)) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error('At least 1 selected fulfillment type is required')
      )
    }
  })

  const TippingValidatorRule = ({ getFieldValue }: any) => ({
    validator() {
      const selectedTypes = getFieldValue('enable_tippings')
      if (Object.values(selectedTypes).some((selected) => selected)) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error('Select at least one fulfilment type to enable tipping')
      )
    }
  })

  const TippingPercentageValidatorRule = ({ getFieldValue }: any) => ({
    validator() {
      const percentages = getFieldValue('tip_percentages')
      const hasZero = percentages.some(
        (p: string) => p === '0' || p === '0.0' || p === '0.00'
      )
      const containsLettersSpecialChars = percentages.some(
        (p: string) => !Number(p)
      )

      if (hasZero) {
        return Promise.reject(new Error('Zero percentages are not allowed'))
      }

      if (containsLettersSpecialChars) {
        return Promise.reject(new Error('Only Numbers are allowed'))
      }

      if (percentages.length <= 3) {
        return Promise.resolve()
      }

      return Promise.reject(
        new Error('Cannot have more than 3 tip percentages')
      )
    }
  })

  const handleOnSubmit = (values: any) => {
    const additionalFees = additionalFeeEnabled
      ? {
          id: uuid(),
          amount: values.amount,
          amount_type: values.amount_type,
          name: values.name,
          is_active: true,
          fulfillment_types: {
            ...values.fulfillment_types,
            id: uuid()
          }
        }
      : DISABLED_FEE_VALUES

    const hasStoreIntegrationSettings =
      Boolean(data?.store_integration_settings[0]?.id) ?? false

    const tipping_settings = tippingEnabled
      ? {
          delivery_tipping_enabled:
            !!values.enable_tippings.delivery_tipping_enabled,
          pickup_tipping_enabled:
            !!values.enable_tippings.pickup_tipping_enabled,
          oat_tipping_enabled: !!values.enable_tippings.oat_tipping_enabled
        }
      : {
          delivery_tipping_enabled: false,
          pickup_tipping_enabled: false,
          oat_tipping_enabled: false
        }

    if (tippingEnabled) {
      values.tip_percentages.push(0)
    }

    const settings = {
      ...tipping_settings,
      tip_percentages: tippingEnabled
        ? values.tip_percentages.map((tp: string) => Number(tp))
        : tip_percentages,
      additional_fee: additionalFees
    }

    message.loading('Updating... Please wait.', 0)

    UpdateStoreSettings({
      merchantId: merchant.id,
      storeId: store.id,
      settings,
      orderWithGoogle: orderWithGoogleEnabled,
      hasStoreIntegrationSettings: hasStoreIntegrationSettings
    })
      .then(() => {
        refetch()
        refetchStoreIntegrationSettings()
        message.destroy()
        message.success('Advanced store settings updated!', 1)
      })
      .catch((error: Error) => {
        refetch()
        message.destroy()
        message.error(
          `Unable to update advanced store settings due to ${error.message}`,
          3
        )
      })
  }

  return (
    <Form
      data-testid='advanced-store-settings-form'
      form={form}
      initialValues={initialFormValues}
      layout='vertical'
      wrapperCol={{ span: 8 }}
      onFinish={handleOnSubmit}
    >
      <PageHeader title='Additional Fees' className='settings-title' />
      <Row gutter={[8, 8]} className='_mb-24' align='middle'>
        <Switch
          defaultChecked={additionalFeeEnabled}
          onChange={setAdditionalFeeEnabled}
          data-testid='additional-fees-toggle'
          id='additional-fees-toggle-switch'
          disabled={!canManage}
        />
        <label className='_ml-8' htmlFor='additional-fees-toggle-switch'>
          Apply additional fee
        </label>
        <Tooltip
          title={
            <span>
              An{' '}
              <a
                href='https://support.slerp.com/knowledge/additional-fee'
                target='_blank'
                rel='noopener noreferrer'
              >
                additional fee
              </a>{' '}
              can be applied to all orders. This can cover anything from a
              service charge to charity donation.
            </span>
          }
          className='_mt-2'
        >
          <QuestionCircleOutlined className='_ml-8' />
        </Tooltip>
      </Row>

      {additionalFeeEnabled && (
        <>
          <Form.Item
            name='name'
            className='_mt-24'
            label={
              <>
                Fee name
                <Tooltip
                  placement='right'
                  title='This name will appear before the fee amount to describe this additional charge in the checkout summary and the customer receipt'
                  className='_ml-8'
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            rules={[
              {
                required: true,
                message: 'Fee name is required'
              }
            ]}
            data-testid='additional-fee-name'
          >
            <Input
              data-testid='additional-fee-name-input'
              disabled={!canManage}
            />
          </Form.Item>
          <Form.Item
            name='fulfillment_types'
            className='_mb-4'
            label={
              <>
                Fulfilment types
                <Tooltip
                  placement='right'
                  title='Only orders under the selected fulfillment type(s) will have the additional fee'
                  className='_ml-8'
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            rules={[FulfillmentTypeValidatorRule]}
            data-testid='additional-fee-fulfillment-types'
            required
          >
            <Form.Item
              name={['fulfillment_types', 'delivery']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='additional-fee-fulfillment-type-checkbox-delivery'
                onChange={() => form.validateFields(['fulfillment_types'])}
                disabled={!canManage}
              >
                Delivery
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['fulfillment_types', 'pickup']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='additional-fee-fulfillment-type-checkbox-pickup'
                onChange={() => form.validateFields(['fulfillment_types'])}
                disabled={!canManage}
              >
                Pickup
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['fulfillment_types', 'table_ordering']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='additional-fee-fulfillment-type-checkbox-table-ordering'
                onChange={() => form.validateFields(['fulfillment_types'])}
                disabled={!canManage}
              >
                Table Ordering
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item
            name='amount_type'
            label='Amount type'
            data-testid='additional-fee-amount-type'
          >
            <Radio.Group
              onChange={(e) => setSelectedAmountType(e.target.value)}
              disabled={!canManage}
            >
              <Space direction='vertical' align='start'>
                <Radio
                  value='fixed'
                  data-testid='additional-fee-amount-type-radio-fixed'
                >
                  Fixed
                </Radio>
                <Radio
                  value='percentage'
                  data-testid='additional-fee-amount-type-radio-percentage'
                >
                  Percentage
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name='amount'
            label='Amount'
            rules={[
              {
                required: true,
                message: 'Amount is required'
              }
            ]}
            data-testid='additional-fee-amount'
          >
            <Input
              addonBefore={
                selectedAmountType === 'fixed' ? <PoundOutlined /> : undefined
              }
              addonAfter={
                selectedAmountType === 'percentage' ? (
                  <PercentageOutlined />
                ) : undefined
              }
              type='number'
              step='0.01'
              defaultValue='0'
              min='0'
              data-testid='additional-fee-amount-input'
              disabled={!canManage}
              onFocus={onFocusScrollNumber}
              onBlur={onBlurScrollNumber}
            />
          </Form.Item>
        </>
      )}

      <PageHeader title='Tipping' className='settings-title _mt-40' />
      <Row gutter={[8, 8]} className='_mb-24'>
        <Switch
          defaultChecked={tippingEnabled}
          onChange={setTippingEnabled}
          data-testid='tipping-toggle'
          id='tipping-toggle-switch'
          disabled={!canManage}
        />
        <label className='_ml-8' htmlFor='tipping-toggle-switch'>
          Tipping
        </label>
        <Tooltip
          title={
            <span>
              Find out more about tipping{' '}
              <a
                href='https://support.slerp.com/knowledge/tipping'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </span>
          }
          className='_mt-4'
        >
          <QuestionCircleOutlined className='_ml-8' />
        </Tooltip>
      </Row>
      {tippingEnabled && (
        <>
          <Form.Item
            name='enable_tippings'
            className='_mb-4'
            label={
              <>
                Enable Tipping
                <Tooltip
                  placement='right'
                  title='Enabled tipping on fulfillment type(s)'
                  className='_ml-8'
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            }
            rules={[TippingValidatorRule]}
            data-testid='enable-tipping-fulfillment-types'
            required
          >
            <Form.Item
              name={['enable_tippings', 'delivery_tipping_enabled']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='enable-tipping-on-checkbox-delivery'
                onChange={() => form.validateFields(['enable_tippings'])}
                disabled={!canManage}
              >
                Delivery
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['enable_tippings', 'pickup_tipping_enabled']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='enable-tipping-on-checkbox-pickup'
                onChange={() => form.validateFields(['enable_tippings'])}
                disabled={!canManage}
              >
                Pickup
              </Checkbox>
            </Form.Item>
            <Form.Item
              name={['enable_tippings', 'oat_tipping_enabled']}
              valuePropName='checked'
            >
              <Checkbox
                data-testid='enable-tipping-on-checkbox-table-ordering'
                onChange={() => form.validateFields(['enable_tippings'])}
                disabled={!canManage}
              >
                Table Ordering
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Row>
            <Col span={24}>
              <Row>
                <Col>Suggested Tipping Percentages</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name='tip_percentages'
                    style={{ width: '100%' }}
                    rules={[TippingPercentageValidatorRule]}
                    required
                  >
                    <Select
                      mode='tags'
                      placeholder='Enter percentages'
                      data-testid='tipping-percentages-option'
                      onChange={() => form.validateFields(['tip_percentages'])}
                      disabled={!canManage}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}

      <PageHeader title='Order with Google' className='settings-title _mt-40' />
      <Row gutter={[8, 8]} className='_mb-24'>
        <Switch
          defaultChecked={orderWithGoogleEnabled}
          checked={orderWithGoogleEnabled}
          onChange={setOrderWithGoogleEnabled}
          data-testid='order-with-google'
          id='order-with-google-switch'
          disabled={!canManage}
          loading={loading}
        />
        <label className='_ml-8' htmlFor='order-with-google-switch'>
          Display your store on Google
        </label>
        <Tooltip
          title={
            <span>
              Enhance your visibility and connect with potential customers via
              Google Search and Maps using Order with Google. <br /> <br /> When
              enabled, this feature integrates your Slerp links into your Google
              listing, ensuring easy access for customers.
            </span>
          }
          className='_mt-4'
        >
          <QuestionCircleOutlined className='_ml-8' />
        </Tooltip>
      </Row>
      <Button
        title='Save'
        htmlType='submit'
        data-testid='additional-fee-save-btn'
        className='_center-vertical _ml-auto'
        disabled={!canManage}
      >
        Save
      </Button>
    </Form>
  )
}

export default AdvancedStoreSettings
