import { useEffect, useRef } from 'react'

const useUpdateEffect: typeof useEffect = (fn, deps) => {
  let hasMountedRef = useRef(false)

  useEffect(() => {
    if (!hasMountedRef.current) {
      hasMountedRef.current = true
      return
    }

    fn()
    // eslint-disable-next-line
  }, deps)
}

export { useUpdateEffect }
