import React, { useState, createRef } from 'react'
import { PlusCircleOutlined } from '@ant-design/icons'
import { filterSelectOption } from 'packages/@slerp/antd-utils'
import { ProductCategory } from '../Form'
import { Form, Select, Divider } from 'antd'
import { requiredRule } from 'components/Orders/RefundFormRules'
import { Category } from '@slerp/controls'

const { Option } = Select

const CategoryDropdown = ({
  categories,
  categoryLoading,
  handleCreateCategory
}: {
  categories: Category[]
  categoryLoading: boolean
  handleCreateCategory: (searchValue: string) => Promise<void> | undefined
}) => {
  const createCategoryButton = createRef<HTMLButtonElement>()
  const [categoryOptions, setCategoryOptions] = useState<ProductCategory[]>([])
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const categoryPlaceholder =
    categories.length > 0
      ? 'Type to search or create a category'
      : 'Type to create your first category'

  const filterOptions = (value: string) =>
    categories.filter((cat) => {
      return cat.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
    })

  const showCreateCategoryButton = (
    searchValue: string,
    options: { children: string }[]
  ) =>
    searchValue.length > 0 &&
    (options.length === 0 ||
      !options.find(
        (option: { children: string }) => searchValue === option.children
      ))

  return (
    <Form.Item name='product_category_ids' rules={requiredRule}>
      <Select
        showSearch
        mode='multiple'
        dropdownClassName='select-multiple'
        placeholder={showPlaceholder ? categoryPlaceholder : ''}
        optionFilterProp='name'
        filterOption={filterSelectOption}
        data-testid='product-form-category'
        notFoundContent={<></>}
        loading={categoryLoading}
        disabled={categoryLoading}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => setShowPlaceholder(true)}
        onInputKeyDown={(e) => {
          // Triggers category creation on enter
          // when there are no available options
          if (e.key === 'Enter' && categoryOptions.length === 0) {
            createCategoryButton.current?.click()
          }
        }}
        onSearch={(value) => setCategoryOptions(filterOptions(value))}
        dropdownRender={(menu) => {
          const { options, searchValue } = menu.props
          return (
            <div>
              <div className='select-option-guide'>
                <p className='text'>{categoryPlaceholder}</p>
              </div>
              {(categories.length > 0 || searchValue.length > 0) && (
                <Divider className='_my-0' />
              )}
              {showCreateCategoryButton(searchValue, options) && (
                <>
                  <button
                    data-testid='product-form-category-create-button'
                    className={`select-option-extra ${
                      options.length === 0 ? '-active' : ''
                    }`}
                    onClick={() => handleCreateCategory(searchValue)}
                    ref={createCategoryButton}
                  >
                    <div className='icon'>
                      <PlusCircleOutlined />
                    </div>
                    <p className='text'>Create "{searchValue}" category</p>
                    {options.length === 0 && (
                      <p className='help'>Press enter</p>
                    )}
                  </button>
                  {options.length > 0 && <Divider className='_my-0' />}
                </>
              )}
              {menu}
            </div>
          )
        }}
      >
        {categories?.map((cat) => {
          return (
            <Option
              value={cat.id}
              key-testid={`product-form-category-${cat.id}`}
              data-testid={`product-form-category-${cat.id}`}
            >
              {cat.name}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default CategoryDropdown
