import React from 'react'
import styled from '@emotion/styled'
import { useCollapsible } from './Collapsible'
import { FaAngleUp, FaAngleDown, IconContext } from '../Icon'
import { useTheme } from '../../contexts/Theme'

const FormGroupTitleMenu: React.FC = ({ children }) => {
  const { isCollapsed, isCollapsible, toggleCollapse } = useCollapsible()
  const theme = useTheme() as any

  return (
    <Menu
      isCollapsible={isCollapsible}
      onClick={isCollapsible ? toggleCollapse : undefined}
    >
      {children}
      {isCollapsible && (
        <IconContext.Provider value={{ color: theme.colors.gray400 }}>
          {isCollapsed ? <FaAngleDown /> : <FaAngleUp />}
        </IconContext.Provider>
      )}
    </Menu>
  )
}

const Menu = styled.div<{ isCollapsible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isCollapsible }) => isCollapsible && 'cursor: pointer'};
`

export { FormGroupTitleMenu }
