import React from 'react'
import styled from '@emotion/styled'

type ElementProps = React.ButtonHTMLAttributes<HTMLButtonElement>

type Variant = 'primary'

interface OwnProps {
  variant?: Variant
}

type Props = OwnProps & ElementProps

export const Button: React.FC<Props> = ({ variant, ...props }) => {
  return (
    <StyledButton {...props} variant={variant || 'primary'}>
      {props.children}
    </StyledButton>
  )
}

const StyledButton = styled.button<OwnProps>`
  display: inline-flex;
  padding: 8px 16px;
  font-family: ${({ theme }: any) => theme.font.sans};
  border: none;
  border-radius: 16px;

  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;

  ${({ theme, variant }: any) =>
    variant === 'primary' &&
    `
    background-color: ${theme.colors.gray900};
  `};
`
