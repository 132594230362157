import React from 'react'
import { Col, Button, Row } from 'antd'
import { uuid } from 'uuidv4'
import { PlusOutlined } from '@ant-design/icons'
import PricingBandRow from './PricingBandRow'
import { DeliveryPricingBand } from './types'
import {
  ValidateDeliveryPricingBands,
  IsWithInfiniteUpperThreshold
} from './utils'

interface DeliveryPricingBandsProps {
  pricingBands: DeliveryPricingBand[]
  updateHandler: () => void
}

const DeliveryPricingBands = ({
  pricingBands,
  updateHandler
}: DeliveryPricingBandsProps) => {
  const isPricingBandInvalid = ValidateDeliveryPricingBands({
    pricingBands: pricingBands || []
  })

  const isWithInfiniteUpperThreshold = IsWithInfiniteUpperThreshold({
    pricingBands: pricingBands || []
  })

  const addPricingBandRow = () => {
    const lastPricingBandRow = [...pricingBands].sort(
      ({ lowerThreshold: a }, { lowerThreshold: b }) =>
        parseInt(a) - parseInt(b)
    )[pricingBands.length - 1]

    updateHandler([
      ...pricingBands,
      {
        lowerThreshold: lastPricingBandRow?.upperThreshold || 0,
        upperThreshold: 0,
        percentageDiscount: 0
      }
    ])
  }

  const updatePricingBands = ({ fieldName, pricingBandIndex, value }) => {
    const newPricingBands = [...pricingBands].map(
      (pricingBand: DeliveryPricingBand, index: number) => {
        if (pricingBandIndex !== index) return pricingBand
        return {
          ...pricingBand,
          [fieldName]: value
        }
      }
    )
    updateHandler(newPricingBands)
  }

  const removePricingBandFromState = (index: number) => {
    const newPricingBands = [...pricingBands]
    newPricingBands.splice(index, 1)
    updateHandler(newPricingBands)
  }

  const ColumnHeaders = () => (
    <Row gutter={[4, 0]} className='_mb-0'>
      <Col span={5}>
        <Row className='_mb-0' justify='start'>
          From
        </Row>
      </Col>
      <Col span={5}>
        <Row className='_mb-0' justify='start'>
          To
        </Row>
      </Col>
      <Col span={5}>
        <Row className='_mb-0' justify='start'>
          Discount
        </Row>
      </Col>
      <Col span={1} />
    </Row>
  )

  return (
    <>
      <Row className='_mb-0' data-testid='delivery-pricing-bands-container'>
        <Col span={22}>
          {!!(pricingBands || []).length && <ColumnHeaders />}
          {(pricingBands || []).map(
            (pricingBand: DeliveryPricingBand, index: number) => (
              <PricingBandRow
                pricingBand={pricingBand}
                key={uuid()}
                onChange={updatePricingBands}
                onRemove={() => removePricingBandFromState(index)}
                pricingBandIndex={index}
                onlyRow={(pricingBands || []).length === 1}
                isLastRow={(pricingBands || []).length - 1 === index}
              />
            )
          )}
        </Col>
      </Row>
      <Row className='_mb-0'>
        <Col>
          <Button
            data-testid='delivery-pricing-bands-add-btn'
            type='dashed'
            icon={<PlusOutlined />}
            onClick={addPricingBandRow}
            disabled={isPricingBandInvalid || isWithInfiniteUpperThreshold}
          >
            Create new
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default DeliveryPricingBands
