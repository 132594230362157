import styled from '@emotion/styled'

export const ImageFlexBox = styled.div(() => ({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'end',
  justifyContent: 'space-between',
  paddingBottom: '8px',
  textAlign: 'end'
}))

export const LogoImage = styled.img(() => ({
  width: '100px'
}))
