import React from 'react'
import { Col, Row, Table, Typography } from 'antd'
import {
  AdditionalCharge,
  AppliedModifier,
  OrderInfo,
  OrderItem
} from 'components/Orders/Info/type'
import { useSession } from '@slerp/accounts'
import AppliedModifiers from './AppliedModifiers'
import MiscFeeForm from './Forms/MiscFeeForm'
import { getAdditionalCharges } from 'components/Orders/Info/utils'
import AppliedDiscount from './AppliedDiscount'

interface OrderItemsDetailsProps {
  order: OrderInfo
  reloadOrder: () => void
}

const { Column } = Table
const { Text } = Typography

const OrderItemsDetails = ({ order, reloadOrder }: OrderItemsDetailsProps) => {
  const { merchant } = useSession()
  const { order_items } = order
  const additionalCharges = getAdditionalCharges(order)

  return (
    <>
      <Table dataSource={order_items} pagination={false}>
        <Column
          width={210}
          title='Product'
          className='-plain _text-weight-600'
          dataIndex='product_variant.name'
          key='product'
          render={(text: string, record: OrderItem) => {
            return (
              <span>
                {record.quantity} x {record.product_variant.name}
              </span>
            )
          }}
        />
        <Column
          width={310}
          title='Applied modifiers'
          className='-plain'
          dataIndex='applied_modifiers'
          key='applied_modifiers'
          render={(value: AppliedModifier[], record: OrderItem) => {
            return value && value.length > 0 ? (
              <AppliedModifiers
                orderQuantity={record.quantity}
                appliedModifiers={value}
                arrangement={
                  record.product_variant.product.modifier_group_arrangement
                }
              />
            ) : (
              <span>N/A</span>
            )
          }}
        />
        <Column
          title='Price'
          className='-plain align-right'
          dataIndex='amount'
          key='amount'
          render={(price: number) => {
            return <span>£{price.toFixed(2)}</span>
          }}
        />
      </Table>
      {!!additionalCharges.length && (
        <Row justify='end'>
          <Col span={16}>
            <Table
              dataSource={additionalCharges}
              pagination={false}
              showHeader={false}
            >
              <Column
                title='Charge name'
                dataIndex='name'
                key='charge_name'
                render={(name: string, record: AdditionalCharge) => (
                  <>
                    <Text strong>{name} </Text>
                    <span className='_cl-text-secondary-75'>
                      {record.description && `(${record.description}): `}
                      {record.tooltip}
                    </span>
                  </>
                )}
              />
              <Column
                title='Charge amount'
                dataIndex='amount'
                key='charge_amount'
                className='align-right'
                width={130}
                render={(amount: string, record: AdditionalCharge) => (
                  <span>
                    {record.amountBefore && <s>{record.amountBefore}</s>}{' '}
                    {amount}
                  </span>
                )}
              />
            </Table>
          </Col>
          {order?.discounts_cache?.code && (
            <Col span={16}>
              <AppliedDiscount
                discountCode={order.discounts_cache.code}
                merchantId={merchant.id}
              ></AppliedDiscount>
            </Col>
          )}
        </Row>
      )}
      <MiscFeeForm order={order} refetch={reloadOrder} />
      <Row justify='end' className='_mb-0'>
        <Col span={3}>
          <Text strong className='_cl-primary'>
            Net Total*:
          </Text>
        </Col>
        <Col span={4}>£{order.total.toFixed(2)}</Col>
      </Row>
    </>
  )
}

export default OrderItemsDetails
