import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { isEmpty } from 'lodash'
import { Button, Row, Col, Input, message } from 'antd'
import { useSession } from '@slerp/accounts'
import { UPDATE_ORDER_ADDITIONAL_NOTES } from 'components/Orders/OrderQueries'

interface AdditionalNotesProps {
  transaction_id: string
  notes: string
  successCallback: () => void
  cancelHandler: () => void
}

const { TextArea } = Input

const AdditionalNotes = ({
  transaction_id,
  notes,
  successCallback,
  cancelHandler
}: AdditionalNotesProps) => {
  const { merchant } = useSession()
  const [newNotes, setNewNotes] = useState<string>(notes)
  const [updateOrderAdditionalNotes] = useMutation(
    UPDATE_ORDER_ADDITIONAL_NOTES,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const noteOnChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNewNotes(e.target.value)

  const updateOrderAdditionalNotesHandler = (e: React.MouseEvent) => {
    message.loading(
      `${isEmpty(notes) ? 'Adding in' : 'Updating'} notes... Please wait.`,
      1
    )
    updateOrderAdditionalNotes({
      variables: {
        transactionId: transaction_id,
        slug: merchant.slug,
        additionalNotes: newNotes
      }
    })
      .then((response) => {
        message.success(
          `Order ${transaction_id} additional notes ${
            isEmpty(notes) ? 'added' : 'updated'
          }`,
          1
        )
        successCallback()
      })
      .catch((error) => {
        message.error(
          `Unable to ${
            isEmpty(notes) ? 'added' : 'updated'
          } additional notes due to: ${error}`,
          5
        )
      })
  }

  return (
    <>
      <TextArea
        defaultValue={notes}
        onChange={noteOnChangeHandler}
        maxLength={255}
        rows={3}
        className='_mb-8'
      />
      <Row justify='end' gutter={8}>
        <Col>
          <Button
            title='Cancel'
            type='ghost'
            className='-secondary _uppercase'
            data-testid='order-additional-notes-cancel'
            onClick={() => cancelHandler()}
          >
            Cancel
          </Button>
        </Col>

        <Col>
          <Button
            title='save'
            className='-secondary _uppercase'
            data-testid='order-additional-notes-save-update'
            onClick={updateOrderAdditionalNotesHandler}
          >
            {isEmpty(notes) ? 'Save' : 'Update'}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default AdditionalNotes
