import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { Checkbox, Col, Divider, Row } from 'antd'
import { useSession } from '@slerp/accounts'
import ModifierCollapsePanels from './ModifierCollapsePanels'
import {
  countPublished,
  flattenItems,
  formatStoreModifiersForInventoryDrawer,
  formatModifierToStoreModifierFormat
} from '../helpers'
import {
  GET_ALL_STORE_PREORDER_INVENTORY,
  GET_ALL_STORE_SAMEDAY_INVENTORY,
  GET_MERCHANT_MODIFIER_GROUPS,
  GET_MERCHANT_ACTIVE_MODIFIERS,
  GET_STORE_MODIFIER_GROUP_ARRANGEMENTS
} from '../DashboardQueries'
import {
  InventoryGroup,
  InventoryItem,
  ModifierGroup,
  StoreInventory,
  ModifierGroupArrangement
} from '../types'

interface ModifiersProps {
  isPreorder: boolean
  storeId: string
  setMasterModifiers: () => void
  setMasterPristineModifiers: () => void
  setMasterModifierGroups: () => void
  sortedGroups: InventoryGroup[]
  setSortedGroups: () => void
}

const Modifiers = ({
  isPreorder,
  storeId,
  sortedGroups,
  setMasterModifiers,
  setMasterPristineModifiers,
  setMasterModifierGroups,
  setSortedGroups
}: ModifiersProps) => {
  const { merchant } = useSession()
  const [modifiers, setModifiers] = useState<InventoryGroup[]>([])
  const [_, setPristineModifiers] = useState<InventoryGroup[]>([])

  const [modifierGroupArrangements, setModifierGroupArrangements] = useState<
    Array<ModifierGroupArrangement>
  >([])

  const allModifiers = flattenItems(modifiers)

  const updateModifiers = (modifiersToUpdate: InventoryItem[]) => {
    const updatedModifiers = cloneDeep(modifiers)

    modifiersToUpdate.forEach((modifier) => {
      const groupIndexes: number[] = []

      modifier.allGroupIds?.forEach((id) => {
        const index = findIndex(updatedModifiers, { id: id })

        if (index !== -1) groupIndexes.push(index)
      })

      groupIndexes.forEach((groupIndex) => {
        updatedModifiers[groupIndex].items = updatedModifiers[
          groupIndex
        ].items.map((item) => {
          if (item.id !== modifier.id) return item
          return { ...item, isPublished: modifier.isPublished }
        })
      })
    })

    setModifiers(updatedModifiers)
    setMasterModifiers(updatedModifiers)
  }

  const { data: storeInventoryData } = useQuery<StoreInventory>(
    isPreorder
      ? GET_ALL_STORE_PREORDER_INVENTORY
      : GET_ALL_STORE_SAMEDAY_INVENTORY,
    {
      variables: { storeId },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const { data: modifierGroupsData } = useQuery<{
    modifier_groups: ModifierGroup[]
  }>(GET_MERCHANT_MODIFIER_GROUPS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  const { data: modifierMasterData } = useQuery<{
    modifiers: Modifier[]
  }>(GET_MERCHANT_ACTIVE_MODIFIERS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  useQuery(GET_STORE_MODIFIER_GROUP_ARRANGEMENTS, {
    variables: { storeId, orderType: isPreorder ? 'preorder' : 'sameday' },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setModifierGroupArrangements(data.modifier_group_arrangements)
    }
  })

  useEffect(() => {
    if (!storeInventoryData) return
    if (!modifierGroupsData?.modifier_groups) return
    if (!modifierMasterData?.modifiers) return

    const orphanedModifiers = formatModifierToStoreModifierFormat(
      storeInventoryData.store_modifiers,
      modifierMasterData?.modifiers
    )

    const storeModifiers = formatStoreModifiersForInventoryDrawer(
      [...storeInventoryData.store_modifiers, ...orphanedModifiers],
      modifierGroupsData.modifier_groups
    )

    setModifiers([...storeModifiers])
    setPristineModifiers([...storeModifiers])
    setMasterPristineModifiers([...storeModifiers])
    setMasterModifierGroups({ ...modifierGroupsData })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeInventoryData, modifierGroupsData, modifierMasterData])

  return (
    <>
      <Row justify='space-between' className='_mb-14'>
        <Col>
          <Checkbox
            checked={countPublished(modifiers) === allModifiers.length}
            onChange={(e) => {
              const allPublished = allModifiers.map((modifier) => ({
                ...modifier,
                isPublished: e.target.checked
              }))
              updateModifiers(allPublished)
            }}
          >
            Select All Modifiers
          </Checkbox>
        </Col>
        <Col>{countPublished(modifiers)} selected</Col>
      </Row>
      <ModifierCollapsePanels
        inventoryGroups={modifiers}
        sortedGroups={sortedGroups}
        modifierGroupArrangements={modifierGroupArrangements}
        onChange={updateModifiers}
        setSortedGroups={setSortedGroups}
      />
      <Divider />
    </>
  )
}

export default Modifiers
