import React, { useState, useEffect } from 'react'
import { uuid } from 'uuidv4'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import InputNumber from 'antd/lib/input-number'
import intersection from 'lodash/intersection'
import { filterSelectOption } from '@slerp/antd-utils'
import { useSession } from '@slerp/accounts'
import { FetchResult, useQuery, useMutation } from '@apollo/client'
import { Store as FormStore } from 'antd/lib/form/interface'
import SortableModifiersList from './SortableModifiersList'
import { PlusCircleOutlined } from '@ant-design/icons'
import {
  buildGetMerchantModifiersQuery,
  UPDATE_MODIFIER_GROUP,
  CREATE_MODIFIER_GROUP
} from '../../ModifierQueries'
import { minimumNumerOfItemsRule, requiredRule } from './rules'
import '../../Modifiers.css'

interface Modifier {
  id: string
  name: string
  sku: string
  price: number
  vat: number
  alcoholic: boolean
  image: string | null
  archived_at: string | null
  modifier_groups: {
    modifier_group_id: string
  }[]
}

interface arrangement {
  [id: string]: string
}

interface ModifierGroupModifier {
  modifier_id: string
}

interface ModifierGroup {
  id: string
  name: string
  description: string
  sku: string | null
  minimum: number
  minimum_enabled: boolean
  maximum: number
  maximum_enabled: boolean
  archived_at: string | null
  modifier_arrangement: arrangement
  modifier_group_modifiers: ModifierGroupModifier[]
}

interface ModifierGroupFormProps {
  onCancel?: () => void
  onStartSave?: () => void
  onEndSave?: (result?: FetchResult) => void
  onError?: (errMessage?: string) => void
  modifierGroupInfo?: ModifierGroup
  showModifierItemFields?: boolean
  showLimitFields?: boolean
}

const { useForm } = Form
const { Option } = Select

const ModifierGroupForm = ({
  onCancel,
  onStartSave,
  onEndSave,
  onError,
  modifierGroupInfo,
  showModifierItemFields = true,
  showLimitFields = true
}: ModifierGroupFormProps) => {
  const { merchant, user } = useSession()
  const [form] = useForm()
  const initialValues = {
    name: modifierGroupInfo?.name || '',
    sku: modifierGroupInfo?.sku || '',
    description: modifierGroupInfo?.description || '',
    minimum_enabled: modifierGroupInfo?.minimum_enabled || false,
    minimum: modifierGroupInfo?.minimum || 0,
    maximum_enabled: modifierGroupInfo?.maximum_enabled || false,
    maximum: modifierGroupInfo?.maximum || 0
  }

  const [modifiers, setModifiers] = useState<Array<Modifier>>([])
  const [selectedModifiers, setSelectedModifiers] = useState<Array<string>>([])
  const [minimumEnabled, setMinimumEnabled] = useState<boolean>(
    initialValues.minimum_enabled
  )
  const [maximumEnabled, setMaximumEnabled] = useState<boolean>(
    initialValues.maximum_enabled
  )
  const [showModifiersSelect, setShowModifiersSelect] = useState<boolean>(false)

  const { data: merchantModifiersData } = useQuery(
    buildGetMerchantModifiersQuery(true),
    {
      variables: {
        merchantId: merchant.id
      }
    }
  )

  const [createModifierGroup] = useMutation(CREATE_MODIFIER_GROUP, {
    fetchPolicy: 'no-cache'
  })
  const [updateModifierGroup] = useMutation(UPDATE_MODIFIER_GROUP, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (merchantModifiersData) {
      const { modifiers } = merchantModifiersData
      const activeModifierIds = modifiers.map((mod: Modifier) => mod.id)
      const arrangementModifierIds = Object.values(
        modifierGroupInfo?.modifier_arrangement || []
      )
      const validModifierGroupArrangement = intersection(
        arrangementModifierIds,
        activeModifierIds
      )
      setSelectedModifiers(validModifierGroupArrangement)
      setModifiers(modifiers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantModifiersData])

  const filteredModifiers = () => {
    const newModifiers = modifiers.filter((modifier: Modifier) => {
      return !selectedModifiers.includes(modifier.id)
    })
    return newModifiers
  }

  const onSelectionOfModifier = (modifierId: string) => {
    setSelectedModifiers([...selectedModifiers, modifierId])
    setShowModifiersSelect(false)
  }

  const modifierSortHandler = (newArrangement: string[]) => {
    setSelectedModifiers([...newArrangement])
  }

  const removeModifierHandler = (modifierId: string) => {
    const newSelectedModifiers = selectedModifiers.filter(
      (mod: string) => mod !== modifierId
    )
    setSelectedModifiers(newSelectedModifiers)
  }

  const onFinish = (values: FormStore) => {
    const {
      name,
      sku,
      description,
      minimum_enabled,
      minimum,
      maximum_enabled,
      maximum
    } = values
    const variables = {
      name,
      sku,
      description,
      updated_by_id: user.id,
      minimum_enabled: minimum_enabled || false,
      minimum: minimum_enabled ? minimum : 0,
      maximum_enabled: maximum_enabled || false,
      maximum: maximum_enabled ? maximum : 0,
      merchant_id: merchant.id,
      modifier_arrangement: { ...selectedModifiers }
    }

    form.validateFields().then(() => {
      onStartSave && onStartSave()
      if (modifierGroupInfo?.id) {
        updateModifierGroup({
          variables: {
            ...variables,
            id: modifierGroupInfo.id,
            modifier_group_modifiers: selectedModifiers.map(
              (modifierId: string) => ({
                id: uuid(),
                modifier_id: modifierId,
                modifier_group_id: modifierGroupInfo.id
              })
            )
          }
        })
          .then((result) => {
            onEndSave && onEndSave()
          })
          .catch((error: Error) => {
            onError && onError(error.message)
          })
      } else {
        const modifierGroupId = uuid()

        createModifierGroup({
          variables: {
            ...variables,
            id: modifierGroupId,
            modifier_group_modifiers: selectedModifiers.map(
              (modifierId: string) => ({
                id: uuid(),
                modifier_id: modifierId,
                modifier_group_id: modifierGroupId
              })
            )
          }
        })
          .then((result) => {
            onEndSave && onEndSave(result)
          })
          .catch((error: Error) => {
            onError && onError(error.message)
          })
      }
    })
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      data-testid='modifier-group-form'
      initialValues={initialValues}
    >
      <Row>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Row>
                <Col>Name</Col>
              </Row>
              <Row className='-modifier-group'>
                <Col span={24}>
                  <Form.Item name='name' className='mb-0' rules={requiredRule}>
                    <Input
                      placeholder='name'
                      data-testid='modifier-group-form-name'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row>
                <Col>SKU</Col>
              </Row>
              <Row className='-modifier-group'>
                <Col span={12}>
                  <Form.Item
                    name='sku'
                    className='mb-0'
                    rules={[
                      ...requiredRule,
                      {
                        validator: (_: any, val: string) => {
                          if (val.includes('?')) {
                            return Promise.reject(
                              'Question marks are not allowed'
                            )
                          }
                          return Promise.resolve()
                        }
                      }
                    ]}
                  >
                    <Input
                      placeholder='sku'
                      disabled={
                        !!modifierGroupInfo?.id &&
                        !!modifierGroupInfo?.archived_at
                      }
                      data-testid='modifier-group-form-sku'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row>
                <Col>Description</Col>
              </Row>
              <Row className='-modifier-group'>
                <Col span={12}>
                  <Form.Item
                    name='description'
                    className='mb-0'
                    rules={requiredRule}
                  >
                    <Input
                      placeholder='description'
                      data-testid='modifier-group-form-description'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {showModifierItemFields && (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row className='-modifier-group'>
                  <Col>Modifiers</Col>
                </Row>
                <Row gutter={[8, 8]} className='-modifier-group'>
                  <Col span={12}>
                    <SortableModifiersList
                      arrangements={selectedModifiers}
                      modifiers={modifiers}
                      onSortEndHandler={modifierSortHandler}
                      onRemoveModifierHandler={removeModifierHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  {showModifiersSelect && (
                    <Col span={12}>
                      {modifiers && (
                        <Select
                          key={uuid()}
                          showSearch
                          allowClear
                          className='width-100'
                          optionFilterProp='name'
                          filterOption={filterSelectOption}
                          onChange={(val: string) => onSelectionOfModifier(val)}
                          placeholder='Select modifier'
                          data-testid='modifier-group-form-modifiers'
                        >
                          {filteredModifiers().map((modifier) => {
                            return (
                              <Option
                                value={modifier.id}
                                data-testid={`modifier-group-form-modifiers-${modifier.id}`}
                              >
                                {modifier.name}
                              </Option>
                            )
                          })}
                        </Select>
                      )}
                    </Col>
                  )}
                  {!showModifiersSelect && (
                    <Button
                      onClick={() => setShowModifiersSelect(true)}
                      icon={<PlusCircleOutlined />}
                    >
                      Add modifier
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          )}

          {showLimitFields && (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <Form.Item name='minimum_enabled' className='mb-0 _pl-2'>
                      <Switch
                        title='minimumEnabled'
                        defaultChecked={minimumEnabled}
                        data-testid='modifier-group-form-minimum-enabled'
                        onChange={setMinimumEnabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <span>Set minimum number items customer must select</span>
                  </Col>
                </Row>
                {minimumEnabled && (
                  <Row>
                    <Col>
                      <Form.Item
                        name='minimum'
                        className='mb-0'
                        rules={minimumNumerOfItemsRule}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                          defaultValue={initialValues.minimum}
                          className='width-100'
                          data-testid='modifier-group-form-minimum'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}

          {showLimitFields && (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row align='middle'>
                  <Col span={2}>
                    <Form.Item name='maximum_enabled' className='mb-0 _pl-2'>
                      <Switch
                        title='maximumEnabled'
                        defaultChecked={maximumEnabled}
                        data-testid='modifier-group-form-maximum-enabled'
                        onChange={(e: boolean) => {
                          form.validateFields()
                          setMaximumEnabled(e)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <span>Set maximum number items customer must select</span>
                  </Col>
                </Row>
                {maximumEnabled && (
                  <Row>
                    <Col>
                      <Form.Item
                        name='maximum'
                        className='mb-0'
                        rules={requiredRule}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                          defaultValue={initialValues.maximum}
                          className='width-100'
                          data-testid='modifier-group-form-maximum'
                          onChange={() => form.validateFields()}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}

          <Divider />

          <Row gutter={16}>
            <Col>
              <Button
                data-testid='modifier-group-form-discard'
                onClick={() => onCancel && onCancel()}
                type='ghost'
              >
                Discard
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                data-testid='modifier-group-form-add-modifier-group'
              >
                {modifierGroupInfo?.id ? 'Update' : 'Add'} Modifier Group
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default ModifierGroupForm
