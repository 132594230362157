import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Checkbox,
  DatePicker,
  Row,
  Select,
  Typography,
  TimePicker,
  message,
  Divider,
  Form as AntdForm
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { CloseOutlined } from '@ant-design/icons'
import { filterSelectOption } from '@slerp/antd-utils'
import moment, { Moment } from 'moment-timezone'
import { format } from 'date-fns'
import { Category, StoreInfo } from '../types'
import { useMutation, useApolloClient } from '@apollo/client'
import {
  ADD_SPECIAL_AVAILABILITY_TO_CATEGORY,
  EDIT_SPECIAL_AVAILABILITY_FOR_CATEGORY
} from '../DashboardQueries'
import './SpecialAvailability.css'

const { Text, Title } = Typography

interface Props {
  categories: Category[]
  storeId: string
  stores: StoreInfo[]
  isPreorder: boolean | undefined
  hasExistingSA?: boolean
  isEdit?: boolean
  initialValues?: InitialValues
  onClose: () => void
}

interface InitialValues {
  category_name: string
  category_id: string
  special_availability_id: string
  by_day: string[]
  applied_to_both_inventories: boolean
  start_date: Moment
  end_date: Moment | null
  start_time: Moment
  end_time: Moment
  store_ids: string[]
}

interface Variables {
  categoryId: string
  specialAvailabilityId?: string
  newCategoryId?: string
  specialAvailability: {
    byday: string[]
    startDate: string | undefined
    orderType: string
    storeIds: string[]
    startTime: string | undefined
    endTime: string | undefined
    endDate?: string | undefined
  }
}

interface StoreSwitchOption {
  children: string
  key: string
  value: string
}

const DAYS: string[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]

const Form = (props: Props) => {
  const [form] = useForm()
  const {
    categories,
    storeId,
    stores,
    isPreorder,
    hasExistingSA,
    isEdit,
    initialValues,
    onClose
  } = props
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string | null>()
  const [startTime, setStartTime] = useState<string>()
  const [endTime, setEndTime] = useState<string>()
  const [showStore, setShowStore] = useState<boolean>(false)
  const [selectedStores, setSelectedStores] = useState<string[]>([])
  const [storeIdFilter, setStoreIdFilter] = useState<string[]>([])

  const client = useApolloClient()
  const [addSpecialAvailability] = useMutation(
    ADD_SPECIAL_AVAILABILITY_TO_CATEGORY,
    { fetchPolicy: 'no-cache' }
  )

  const [updateSpecialAvailability] = useMutation(
    EDIT_SPECIAL_AVAILABILITY_FOR_CATEGORY,
    { fetchPolicy: 'no-cache' }
  )

  const configureAvailability = isEdit
    ? updateSpecialAvailability
    : addSpecialAvailability

  useEffect(() => {
    if (initialValues) {
      if (initialValues.store_ids.length) {
        setShowStore(true)
        setSelectedStores(initialValues.store_ids)
        setStoreIdFilter(initialValues.store_ids)
      }
      dateHandler(initialValues.start_date, 'start')
      dateHandler(initialValues.end_date, 'end')
      timeHandler(initialValues.start_time, 'from')
      timeHandler(initialValues.end_time, 'to')
    }
  }, [initialValues])

  const timeHandler = (time: Moment | null, when: string) => {
    const timeAsDate = time ? time.toDate() : moment('00:00:00').toDate()
    const formattedTime = format(timeAsDate, 'HH:mm:ss')

    if (when === 'from') setStartTime(formattedTime)
    if (when === 'to') setEndTime(formattedTime)
    return null
  }

  const dateHandler = (date: Moment | null, when: string) => {
    if (date) {
      const formattedDate = moment(date.toDate()).format('YYYY-MM-DD')

      if (when === 'start') setStartDate(formattedDate)
      if (when === 'end') setEndDate(formattedDate)

      return null
    }

    if (when === 'end') setEndDate(null)
    return null
  }

  const rearrangeDays = (days: string[]) => {
    days.sort((a, b) => {
      // Find the index of each day in the dayOrder array
      const indexA = DAYS.indexOf(a)
      const indexB = DAYS.indexOf(b)

      // Compare the indexes to determine the ordering
      return indexA - indexB
    })

    return days
  }

  const storeChangeHandler = (values: string[]) => {
    const store_ids = stores.map((store: StoreInfo) => store.id)

    if (values.includes('all')) {
      if (values.length === store_ids.length) {
        const new_value = values.filter((v) => v !== 'all')
        setStoreIdFilter(new_value)
        setSelectedStores(new_value)
        return
      }

      setStoreIdFilter([...store_ids, 'all'])
      setSelectedStores(store_ids)
      return
    }

    if (values.length === store_ids.length) {
      if (values.every((v) => store_ids.includes(v))) {
        setStoreIdFilter([...store_ids, 'all'])
        setSelectedStores(values)
        return
      }

      setStoreIdFilter([])
      setSelectedStores([])
      return
    }

    if (values.length === 0) {
      setShowStore(false)
      form.setFieldsValue({ apply_to_multiple_locations: false })
    }

    setStoreIdFilter(values)
    setSelectedStores(values.filter((v) => v !== 'all'))
  }

  const applyToMultipleLocationHandler = () => {
    setShowStore((prev) => !prev)

    if (!showStore && !storeIdFilter.length) {
      // for default value
      const store_ids = stores.map((s) => s.id)
      setStoreIdFilter([...store_ids, 'all'])
      setSelectedStores(store_ids)
    }
  }

  const onFinish = (values: InitialValues) => {
    const { category_id, by_day, applied_to_both_inventories } = values

    const isAppliedToBothInventories = () => {
      if (applied_to_both_inventories) return 'ALL'

      return isPreorder ? 'PREORDER' : 'SAMEDAY'
    }

    form.validateFields().then(() => {
      message.loading('Creating... Please wait.')
      let variables: Variables = {
        categoryId: category_id,
        specialAvailability: {
          byday: rearrangeDays(by_day),
          orderType: isAppliedToBothInventories(),
          storeIds: showStore ? selectedStores : [storeId],
          startDate,
          startTime,
          endTime
        }
      }

      if (endDate) {
        variables = {
          ...variables,
          specialAvailability: {
            ...variables.specialAvailability,
            endDate
          }
        }
      }

      if (isEdit) {
        variables = {
          ...variables,
          specialAvailabilityId: initialValues!.special_availability_id
        }
      }

      if (isEdit && category_id !== initialValues?.category_id) {
        variables = {
          ...variables,
          categoryId: initialValues!.category_id,
          newCategoryId: category_id
        }
      }

      configureAvailability({
        variables: variables
      })
        .finally(() => {
          message.destroy()
          client.resetStore()
          message.success(
            `Successfully ${
              isEdit ? 'updated' : 'added'
            } special availability.`,
            1
          )
          onClose()
        })
        .catch((error: Error) => {
          message.destroy()
          message.error(
            `Unable to ${
              isEdit ? 'update' : 'add'
            } special availability. Due to ${error.message}`,
            3
          )
        })
    })
  }

  return (
    <AntdForm
      data-testid='special-availability-form'
      form={form}
      onFinish={onFinish}
      layout='vertical'
      scrollToFirstError
      initialValues={{
        by_day: DAYS,
        applied_to_both_inventories: true,
        ...initialValues
      }}
    >
      <Row className='_mb-0' justify='space-between'>
        <Col>
          <Title level={4}>
            {isEdit ? 'Update availability' : 'Add new availability'}
          </Title>
        </Col>
        <Col>
          <Row gutter={[8, 0]}>
            {hasExistingSA && (
              <Col>
                <Button onClick={onClose} type='ghost'>
                  Cancel
                </Button>
              </Col>
            )}
            <Col>
              <Button type='primary' htmlType='submit'>
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {!isEdit && (
        <Row className='_mb-24'>
          <Text>
            Here you can create categories that will be available for a limited
            time within a day.{' '}
            <i>i.e a Lunch category available from 12-2pm from Monday-Friday</i>
          </Text>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <AntdForm.Item
            name='category_id'
            rules={[{ required: true, message: 'Please select a category' }]}
            label='Select Category'
          >
            <Select data-testid='special-availability-category-select'>
              {categories!.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntdForm.Item name='start_date' label='Start'>
            <DatePicker
              className='_w-100'
              format='DD MMM YYYY'
              onChange={(val) => dateHandler(val, 'start')}
              disabledDate={(current) =>
                current && current < moment().subtract(1, 'days').endOf('day')
              }
              showToday={false}
            />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntdForm.Item
            name='by_day'
            rules={[{ required: true, message: 'Please select a day/s' }]}
            label='Repeats every week'
            tooltip='Select the days on which you would like the special availability schedule to repeat itself every week.'
          >
            <Checkbox.Group className='_w-100 _pl-8'>
              <Row justify='space-between' align='middle'>
                {DAYS.map((day) => (
                  <Col key={day}>
                    <Checkbox
                      key={day}
                      className={`day -${day.toLowerCase()}`}
                      value={day}
                    />
                  </Col>
                ))}
                <Col>
                  <Button
                    type='text'
                    onClick={() => form.setFieldsValue({ by_day: [] })}
                    className='clear'
                  >
                    <CloseOutlined /> Clear
                  </Button>
                </Col>
              </Row>
            </Checkbox.Group>
          </AntdForm.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <AntdForm.Item
            name='start_time'
            rules={[{ required: true, message: 'Please select a time' }]}
            label='From'
          >
            <TimePicker
              data-testid='from-timepicker'
              className='_w-100'
              format='HH:mm'
              showNow={false}
              minuteStep={5}
              onChange={(val) => timeHandler(val, 'from')}
            />
          </AntdForm.Item>
        </Col>
        <Col span={8}>
          <AntdForm.Item
            name='end_time'
            rules={[
              {
                required: true,
                validator: (_: any, time: Moment) => {
                  if (time) {
                    const formattedTime = format(time.toDate(), 'HH:mm:ss')
                    return formattedTime > startTime!
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(`Time should be greater than ${startTime}`)
                        )
                  }
                  return Promise.reject(new Error('Please select a time'))
                }
              }
            ]}
            label='To'
          >
            <TimePicker
              data-testid='to-timepicker'
              className='_w-100'
              format='HH:mm'
              showNow={false}
              minuteStep={5}
              disabled={!startTime}
              onChange={(val) => timeHandler(val, 'to')}
            />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntdForm.Item
            name='end_date'
            label={
              <>
                End <i>(Optional)</i>
              </>
            }
          >
            <DatePicker
              className='_w-100'
              format='DD MMM YYYY'
              disabled={!startDate}
              disabledDate={(current) =>
                current && current < moment(startDate).endOf('day')
              }
              onChange={(val) => dateHandler(val, 'end')}
              showToday={false}
            />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row className={showStore ? '_mb-0' : ''}>
        <Col span={24}>
          <Row justify='start' className='_mb-0'>
            <AntdForm.Item
              name='apply_to_multiple_locations'
              valuePropName='checked'
              className='_mb-0'
            >
              <Checkbox onClick={applyToMultipleLocationHandler}>
                Apply to multiple locations
              </Checkbox>
            </AntdForm.Item>
          </Row>
        </Col>
      </Row>
      {showStore && (
        <Row>
          <Col span={24}>
            <AntdForm.Item>
              <Select
                allowClear
                data-testid='stores-filter-dropdown'
                placeholder='Select More Stores'
                onChange={storeChangeHandler}
                filterOption={(input: string, option: StoreSwitchOption) =>
                  filterSelectOption(input, option)
                }
                tagRender={({ value, label, onClose }) => {
                  if (value === 'all') return <></>

                  return (
                    <div
                      className='ant-select-selection-overflow-item'
                      style={{ opacity: '1' }}
                    >
                      <span className='ant-select-selection-item'>
                        <span className='ant-select-selection-item-content'>
                          {label}
                        </span>
                        <span
                          className='ant-select-selection-item-remove'
                          unselectable='on'
                          aria-hidden='true'
                          style={{ userSelect: 'none' }}
                        >
                          {!isEdit && value === storeId ? (
                            <></>
                          ) : (
                            <span
                              role='img'
                              aria-label='close'
                              className='anticon anticon-close'
                              onClick={onClose}
                            >
                              <svg
                                viewBox='64 64 896 896'
                                focusable='false'
                                data-icon='close'
                                width='1em'
                                height='1em'
                                fill='currentColor'
                                aria-hidden='true'
                              >
                                <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
                              </svg>
                            </span>
                          )}
                        </span>
                      </span>
                    </div>
                  )
                }}
                value={storeIdFilter}
                mode='multiple'
                dropdownClassName='select-multiple'
              >
                <Select.Option
                  value='all'
                  key={'all'}
                  className='_px-8 _my-2'
                  data-testid='location-option-all'
                >
                  All locations
                </Select.Option>
                {stores.map((store: StoreInfo) => (
                  <Select.Option
                    value={store.id}
                    key={store.id}
                    disabled={!isEdit && store.id === storeId}
                    className='_px-8 _my-2'
                    data-testid={`stores-filter-by-store-${store.name}`}
                  >
                    {store.name}
                  </Select.Option>
                ))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Row justify='start' className='_mb-0'>
            <AntdForm.Item
              name='applied_to_both_inventories'
              valuePropName='checked'
            >
              <Checkbox>
                Apply to {!!isPreorder ? 'same day' : 'pre-order'} inventory
              </Checkbox>
            </AntdForm.Item>
          </Row>
          <Divider />
        </Col>
      </Row>
    </AntdForm>
  )
}

export default Form
