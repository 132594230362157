import React, { useState } from 'react'
import { Button, Col, Collapse, Empty, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { uuid } from 'uuidv4'
import snakeCase from 'lodash/snakeCase'
import capitalize from 'lodash/capitalize'
import VariantGroupHeader from './VariantGroupHeader'
import VariantGroupTable from './VariantGroupTable'
import VariantOptionForm from './Forms/VariantOptionForm'
import {
  GroupVariantsByGroupName,
  SymbolizeVariantGroupNames,
  SymbolizeVariantGroupOptions
} from './utils'
import { ProductVariant, Product, OptionsAllergens, Option } from './types'
import { ALLERGENS } from '../types'

interface VariantGroupProps {
  product: Product
  variants: ProductVariant[]
}

const { Panel } = Collapse

const VariantGroupsWithAllergens = ({
  product,
  variants
}: VariantGroupProps) => {
  const [showNewVariantGroupForm, setShowNewVariantGroupForm] = useState<
    String[]
  >([])
  const variantsByOptions = GroupVariantsByGroupName(
    product?.options || [],
    variants
  )
  const variantOptionNames = SymbolizeVariantGroupNames(product?.options || [])
  const productVariantOptionValues = SymbolizeVariantGroupOptions(
    product?.options || []
  )

  const snakeCasedOptionAllergens: OptionsAllergens = Object.entries(
    product.options_allergens ?? []
  ).reduce((acc, [key, value]: [string, Record<string, Array<ALLERGENS>>]) => {
    return {
      ...acc,
      [snakeCase(key)]: value
    }
  }, {})

  const withOptions = Object.keys(variantsByOptions).find(
    (key: string) => !!(variantsByOptions[key] || []).length
  )
  const getVariantOptions = (variantGroupName: string) => {
    const option = (product.options || []).filter(
      ({ name }: Option) => snakeCase(name) === variantGroupName
    )[0]

    return option?.values
  }

  return (
    <>
      {!withOptions && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No variants created'
        />
      )}
      {withOptions && (
        <Row
          className='variant-groups _mb-0'
          data-testid='variant-groups-container'
        >
          <Col span={24}>
            <VariantGroupHeader />
            <Collapse
              defaultActiveKey={Object.keys(variantsByOptions)[0]}
              ghost
              accordion
            >
              {Object.keys(variantsByOptions).map((key: string) => {
                const options = variantsByOptions[key]
                return !!options.length ? (
                  <Panel header={capitalize(variantOptionNames[key])} key={key}>
                    <div
                      data-testid={`variant-groups-options-container-${key}`}
                    >
                      <VariantGroupTable
                        productId={product.id}
                        baseName={product.name}
                        variants={options}
                        variantOptions={getVariantOptions(key)}
                        variantGroupName={variantOptionNames[key]}
                        optionAllergens={snakeCasedOptionAllergens[key]}
                      />
                      <Row className='_mb-0 _mt-8' justify='end'>
                        <Col>
                          <Button
                            data-testid={`variant-group-options-${key}`}
                            icon={<PlusOutlined />}
                            type='dashed'
                            shape='round'
                            className='-rounded'
                            ghost
                            onClick={() =>
                              setShowNewVariantGroupForm([
                                ...showNewVariantGroupForm,
                                key
                              ])
                            }
                          >
                            New Variant
                          </Button>
                        </Col>
                      </Row>
                      {showNewVariantGroupForm.includes(key) && (
                        <VariantOptionForm
                          key={uuid()}
                          productId={product.id}
                          variantGroupName={variantOptionNames[key]}
                          variants={options}
                          variantGroupOptions={productVariantOptionValues[key]}
                          discardHandler={() => {
                            setShowNewVariantGroupForm(
                              showNewVariantGroupForm.filter(
                                (groupName) => groupName !== key
                              )
                            )
                          }}
                        />
                      )}
                    </div>
                  </Panel>
                ) : (
                  []
                )
              })}
            </Collapse>
          </Col>
        </Row>
      )}
    </>
  )
}

export default VariantGroupsWithAllergens
