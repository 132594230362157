import React from 'react'
import styled from '@emotion/styled'
import { RightArrowIcon } from '@slerp/assets'

interface Props {
  location: string
  onClick: () => void
  isExpanded: boolean
}

export const AttributionLocation = ({
  location,
  onClick,
  isExpanded
}: Props) => {
  return (
    <Root onClick={onClick}>
      <Icon rotate={isExpanded ? 'rotate(90deg)' : ''} />
      <Text>{location}</Text>
    </Root>
  )
}

const Root = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  cursor: 'pointer'
}))

const Icon = styled(RightArrowIcon)(({ rotate }: { rotate: string }) => ({
  width: '24px',
  height: '24px',
  fill: '#A4A4B3',
  transform: rotate
}))

const Text = styled.p(() => ({
  margin: 0
}))
