import React from 'react'
import styled from '@emotion/styled'
import { Text } from '../Text'

const FieldGroupLabel: React.FC = (props) => {
  return (
    <Label {...props}>
      <Text>{props.children}</Text>
    </Label>
  )
}

const Label = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: ${({ theme }: any) => theme.fontSizes.sm};
  color: ${({ theme }: any) => theme.colors.text};
  margin-bottom: 8px;
`

export { FieldGroupLabel }
