import React, { useState } from 'react'
import Row from 'antd/lib/row'
import Tag from 'antd/lib/tag'
import Col from 'antd/lib/col'
import Tabs from 'antd/lib/tabs'
import Space from 'antd/lib/space'
import Steps from 'antd/lib/steps'
import Alert from 'antd/lib/alert'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import Typography from 'antd/lib/typography'
import Descriptions from 'antd/lib/descriptions'
import { uuid } from 'uuidv4'
import { capitalize, isEmpty } from 'lodash'
import RefundForm from './RefundForm'
import { useSession } from '@slerp/accounts'
import { useMutation } from '@apollo/client'
import Moment from 'react-moment'
import moment from 'moment-timezone'
import EditOutlined from '@ant-design/icons/EditOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import PrinterOutlined from '@ant-design/icons/PrinterOutlined'
import CodeSandboxOutlined from '@ant-design/icons/CodeSandboxOutlined'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled'
import {
  ACCEPT_ORDER,
  COMPLETE_ORDER,
  REJECT_ORDER,
  UPDATE_ORDER_ADDITIONAL_NOTES
} from './OrderQueries'
import AppliedModifiers from './AppliedModifiers'
import FulfillmentTimeSlot from './FulfillmentTimeSlot'
import { OrderDetailsProps } from './types'
import AdditionalNotesForm from './AdditionalNotesForm'
import MiscFeeForm from './MiscFeeForm'
import CourierForm from './CourierForm'
import CouriersList from './CouriersList'
import { MAPS_API_KEY } from '@slerp/client'
import errorReducer from 'errors/errorReducer'

const { Step } = Steps
const { TabPane } = Tabs
const { Column } = Table
const { Text } = Typography

interface GoogleMapStaticProps {
  geom: {
    lat: string
    lng: string
  }
}

interface RefundItem {
  name: string
  reason: string
  amount: string
  value: number
  created: string
}

const GoogleMapStatic = (props: GoogleMapStaticProps) => {
  const apikey = MAPS_API_KEY // https://console.cloud.google.com/home/dashboard?project=slerp-admin&authuser=2&organizationId=699337665845&folder
  const { geom } = props

  return (
    <img
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${geom.lat},${geom.lng}&zoom=13&size=720x300&maptype=roadmap&markers=color:green%7Clabel:G%7C${geom.lat},${geom.lng}&key=${apikey}`}
      alt='Google Map'
    />
  )
}

const GOOD_ORDER_STATES = ['pending', 'accepted', 'fulfilled']
const STEPS = [
  { title: 'Pending' },
  { title: 'Accepted' },
  { title: 'Completed' }
]
const COURIER_SUPPORT_ORDER_STATES = ['accepted', 'fulfilled', 'refunded']

const DELIVERY_ADJUSTMENT = 'slerp_adjustment_delivery'
const TOTAL_ADJUSTMENT = 'slerp_adjustment_total'
const PRODUCT_ADJUSTMENT = 'slerp_adjustment_product'
const WAITING_FEES = 'waiting_fees'
const COURIER_DISCOUNT = 'courier_discount'

export const FEE_TYPE_NAMES = {
  [WAITING_FEES]: 'Waiting fee',
  [COURIER_DISCOUNT]: 'Courier credit',
  [DELIVERY_ADJUSTMENT]: 'Slerp Adjustment (Delivery)',
  [PRODUCT_ADJUSTMENT]: 'Slerp Adjustment (Product)',
  [TOTAL_ADJUSTMENT]: 'Slerp Adjustment (Total)'
}

const getOrderCurrentStep = (status: string) => {
  let step = 0
  switch (status) {
    case 'pending':
      step = 0
      break
    case 'accepted':
      step = 1
      break
    case 'fulfilled':
      step = 2
      break
  }
  return step
}

interface OrderFetchType {
  ordersListRefetch?: () => {}
  orderRefetch?: () => {}
  ordersDataRefetch?: () => {}
}

const OrderDetails = (props: OrderDetailsProps & OrderFetchType) => {
  const { order, ordersListRefetch, orderRefetch, ordersDataRefetch } = props
  const {
    customer_details,
    customer_details: { address },
    delivery,
    payment,
    store,
    order_items,
    status,
    transaction_id,
    additional_couriers,
    recipient_details,
    gift_wrap_message,
    note_updated_at,
    is_preorder,
    merchant_settings
  } = order
  const storeAddress = store.address_cache
    ? JSON.parse(store.address_cache)
    : {}
  const [orderState, setOrderState] = useState(status)
  const [showRefundForm, setShowRefundForm] = useState<boolean>(false)
  const [showAdditionalNotesForm, setShowAdditionalNotesForm] = useState(false)
  const [additionalNotes, setAdditionalNotes] = useState(order.additional_notes)
  const [additionalNotesBackup, setAdditionalNotesBackup] = useState(
    order.additional_notes
  )
  const [additionalNotesTimeStamp, setAdditionalNotesTimeStamp] = useState<
    string | null
  >(note_updated_at)
  const [showCourierForm, setShowCourierForm] = useState(false)

  const isUsingSlerpCourierPartner = is_preorder
    ? merchant_settings?.delivery_courier_partner
    : merchant_settings?.same_day_delivery_courier_partner

  const isFullyRefunded = () => {
    const refundsTotal = refunds().reduce(
      (total: number, refundItem: RefundItem) => total + refundItem.value,
      0
    )
    const paymentTotal = (payment?.data?.amount || 0) / 100

    return refundsTotal >= paymentTotal
  }

  const refunds = () => {
    if (isEmpty(order.payment?.data?.refunds?.data)) {
      return []
    }

    const {
      payment: {
        data: { refunds }
      }
    } = order
    const { data: refundsData = [] } = refunds
    let refundItems = (refundsData || []).map((refund) => {
      const { metadata, amount, created } = refund
      const formattedAmount = (amount || 0) / 100
      return {
        name: 'Refund',
        reason: metadata?.reason,
        amount: `-£${formattedAmount.toFixed(2)}`,
        value: formattedAmount,
        created: created && moment.unix(created).format('DD MMM YYYY h:mma')
      }
    })
    return refundItems
  }

  const additionalCharges = () => {
    const {
      delivery_charge,
      discounts_cache,
      gift_wrap_price,
      gift_wrap_message,
      misc_fees
    } = order
    let charges = []
    if (gift_wrap_price && gift_wrap_price > 0) {
      charges.push({
        name: 'Gifting',
        description: gift_wrap_message,
        amount: `£${gift_wrap_price.toFixed(2)}`
      })
    }
    if (discounts_cache && !isEmpty(discounts_cache.code)) {
      const discountAmount = parseFloat(discounts_cache?.total_discount || 0)
      charges.push({
        name: 'Discount',
        description: discounts_cache.code,
        amount: `-£${discountAmount.toFixed(2)}`
      })
    }
    if (delivery_charge && delivery_charge > 0) {
      charges.push({
        name: 'Deliveries fee',
        description: 'Charged to customer',
        amount: `£${delivery_charge.toFixed(2)}`
      })
    }
    if (misc_fees && !isEmpty(misc_fees)) {
      misc_fees.forEach((fee) => {
        const prefix = fee.amount < 0 ? '-£' : '£'
        charges.push({
          name: FEE_TYPE_NAMES[fee.type],
          description: fee.note || '',
          amount: `${prefix}${Math.abs(fee.amount)}`
        })
      })
    }
    return charges
  }
  const { user, merchant } = useSession()
  const [acceptOrder, { loading: acceptOrderLoading }] = useMutation(
    ACCEPT_ORDER,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [rejectOrder, { loading: rejectOrderLoading }] = useMutation(
    REJECT_ORDER,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [completeOrder, { loading: completeOrderLoading }] = useMutation(
    COMPLETE_ORDER,
    {
      fetchPolicy: 'no-cache'
    }
  )
  const [updateOrderAdditionalNotes] = useMutation(
    UPDATE_ORDER_ADDITIONAL_NOTES,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const acceptOrderHandler = (e: React.MouseEvent) => {
    message.loading('Accepting order... Please wait.', 1)
    acceptOrder({
      variables: { transactionId: transaction_id, userId: user.id }
    }).then((response) => {
      const { acceptOrder } = response?.data || {}
      setOrderState(acceptOrder.status)
      message.success(`Order ${acceptOrder.transactionId} accepted!`, 1)
      ordersListRefetch && ordersListRefetch()
      orderRefetch && orderRefetch()
      ordersDataRefetch && ordersDataRefetch()
    })
  }

  const rejectOrderHandler = (e: React.MouseEvent) => {
    message.loading('Rejecting order... Please wait.', 1)
    rejectOrder({
      variables: { transactionId: transaction_id, userId: user.id }
    })
      .then((response) => {
        const { rejectOrder } = response?.data || {}
        setOrderState(rejectOrder.status)
        message.success(`Order ${rejectOrder.transactionId} rejected!`, 1)
        ordersListRefetch && ordersListRefetch()
        orderRefetch && orderRefetch()
        ordersDataRefetch && ordersDataRefetch()
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Order',
          data: {
            error: error,
            message: error.message
          }
        })
      })
      .catch((error) => {
        message.error(`${error.message}`)
      })
  }

  const completeOrderHandler = (e: React.MouseEvent) => {
    message.loading('Completing order... Please wait.', 1)
    completeOrder({
      variables: { transactionId: transaction_id, userId: user.id }
    }).then((response) => {
      const { completeOrder } = response?.data || {}
      setOrderState(completeOrder.status)
      message.success(`Order ${completeOrder.transactionId} completed!`, 1)
      ordersListRefetch && ordersListRefetch()
      orderRefetch && orderRefetch()
      ordersDataRefetch && ordersDataRefetch()
    })
  }

  const updateOrderAdditionalNotesHandler = (e: React.MouseEvent) => {
    message.loading(
      `${additionalNotes ? 'Updating' : 'Adding in'} notes... Please wait.`,
      1
    )
    updateOrderAdditionalNotes({
      variables: {
        transactionId: transaction_id,
        slug: merchant.slug,
        additionalNotes
      }
    })
      .then((response) => {
        const { update_orders } = response?.data || {}
        const { transaction_id: orderTransactionId } =
          update_orders.returning[0]
        message.success(
          `Order ${orderTransactionId} additional notes ${
            additionalNotes ? 'updated' : 'added'
          }`,
          1
        )
        ordersListRefetch && ordersListRefetch()
        orderRefetch && orderRefetch()
        ordersDataRefetch && ordersDataRefetch()
        setShowAdditionalNotesForm(false)
        setAdditionalNotesBackup(additionalNotes)
        setAdditionalNotesTimeStamp(
          response?.data.update_orders.returning[0].note_updated_at
        )
      })
      .catch((error) => {
        message.error(
          `Unable to ${
            additionalNotes ? 'update' : 'save'
          } additional notes due to: ${error}`,
          5
        )
      })
  }

  const deleteOrderAdditionalNotesHandler = (e: React.MouseEvent) => {
    message.loading('Removing notes... Please wait.', 1)
    updateOrderAdditionalNotes({
      variables: {
        transactionId: transaction_id,
        slug: merchant.slug,
        additionalNotes: ''
      }
    })
      .then((response) => {
        const { update_orders } = response?.data || {}
        const { transaction_id: orderTransactionId } =
          update_orders.returning[0]
        message.success(
          `Order ${orderTransactionId} additional notes removed!`,
          1
        )
        ordersListRefetch && ordersListRefetch()
        orderRefetch && orderRefetch()
        ordersDataRefetch && ordersDataRefetch()
        setAdditionalNotes('')
        setAdditionalNotesBackup('')
        setShowAdditionalNotesForm(false)
      })
      .catch((error) => {
        message.error(`Unable to delete additional notes due to: ${error}`, 5)
      })
  }

  const canChangeFulfillmentDate = () => {
    const validStatus = ['accepted', 'pending'].includes(order.status)
    const isPreOrder =
      order?.fulfillment_date &&
      !!!moment
        .tz(order.fulfillment_date, 'YYYY-MM-DD', 'Europe/London')
        .isSame(
          moment.tz(order.inserted_at, 'YYYY-MM-DD', 'Europe/London'),
          'day'
        )
    const isDelivery = order?.fulfillment_type === 'delivery'
    return validStatus && isPreOrder && isDelivery
  }

  const renderCallToActionButtons = (status: string) => {
    const ctaActionInProgress =
      acceptOrderLoading || rejectOrderLoading || completeOrderLoading
    const pendingCta = (
      <>
        <Col>
          <Tooltip
            title={
              <a
                href='https://support.slerp.com/knowledge/accepting-rejecting-orders-via-slerp-app'
                target='_blank'
                rel='noopener noreferrer'
              >
                Accepting & Rejecting Orders
              </a>
            }
            color='#8c8ca1'
          >
            <QuestionCircleOutlined className='_mr-16 _cl-white' />
          </Tooltip>
          <Button
            data-testId='order-details-reject-btn'
            title='reject'
            type='primary'
            disabled={ctaActionInProgress}
            onClick={rejectOrderHandler}
            ghost
          >
            Reject
          </Button>
        </Col>
        <Col>
          <Button
            data-testId='order-details-accept-btn'
            title='accept'
            type='primary'
            disabled={ctaActionInProgress}
            onClick={acceptOrderHandler}
          >
            Accept
          </Button>
        </Col>
      </>
    )
    const acceptedCta = (
      <>
        <Col>
          <Tooltip
            title={
              <a
                href='https://support.slerp.com/knowledge/processing-a-refund'
                target='_blank'
                rel='noopener noreferrer'
              >
                Refunding and Cancelling Orders
              </a>
            }
            color='#8c8ca1'
          >
            <QuestionCircleOutlined className='_mr-16 _link-white' />
          </Tooltip>
          <Button
            data-testId='order-details-refund-btn'
            title='refund'
            type='primary'
            disabled={ctaActionInProgress}
            onClick={() => setShowRefundForm(true)}
            hidden={isFullyRefunded()}
            ghost
          >
            Refund
          </Button>
        </Col>
        <Col>
          <Button
            data-testId='order-details-complete-btn'
            title='fulfill'
            type='primary'
            disabled={ctaActionInProgress}
            onClick={completeOrderHandler}
          >
            {`${capitalize(order.fulfillment_type)} complete`}
          </Button>
        </Col>
      </>
    )
    const refundedCta = (
      <Col>
        {!isFullyRefunded() && (
          <Tooltip
            title={
              <a
                href='https://support.slerp.com/knowledge/processing-a-refund'
                target='_blank'
                rel='noopener noreferrer'
              >
                Refunding and Cancelling Orders
              </a>
            }
            color='#8c8ca1'
          >
            <QuestionCircleOutlined className='_mr-16 _link-white' />
          </Tooltip>
        )}
        <Button
          title='refund'
          type='primary'
          disabled={ctaActionInProgress}
          onClick={() => setShowRefundForm(true)}
          hidden={isFullyRefunded()}
          ghost
        >
          Refund
        </Button>
      </Col>
    )
    const fulfilledCta = (
      <Col>
        {!isFullyRefunded() && (
          <Tooltip
            title={
              <a
                href='https://support.slerp.com/knowledge/processing-a-refund'
                target='_blank'
                rel='noopener noreferrer'
              >
                Refunding and Cancelling Orders
              </a>
            }
            color='#8c8ca1'
          >
            <QuestionCircleOutlined className='_mr-16 _link-white' />
          </Tooltip>
        )}
        <Button
          data-testId='order-details-refund-btn'
          title='refund'
          type='primary'
          disabled={ctaActionInProgress}
          onClick={() => setShowRefundForm(true)}
          hidden={isFullyRefunded()}
          ghost
        >
          Refund
        </Button>
      </Col>
    )
    return (
      <Row justify='end' gutter={16} style={{ margin: 10 }}>
        {status === 'pending' ? pendingCta : []}
        {status === 'accepted' ? acceptedCta : []}
        {status === 'fulfilled' ? fulfilledCta : []}
        {status === 'refunded' ? refundedCta : []}
      </Row>
    )
  }

  const renderOrderSteps = (status: string) => {
    const specificRefundStatusText = isFullyRefunded()
      ? 'Fully Refunded'
      : 'Partially Refunded'

    if (GOOD_ORDER_STATES.includes(status)) {
      return (
        <div className='order-steps'>
          <Steps current={getOrderCurrentStep(status)} size='large'>
            {STEPS.map((step, index) => (
              <Step title={step.title} key={index} />
            ))}
          </Steps>
        </div>
      )
    }

    return (
      <div className='fallback-order-step'>
        <p className='icon'>
          <ExclamationCircleFilled />
        </p>
        {status === 'refunded' ? specificRefundStatusText : status}
      </div>
    )
  }

  const toggleAdditionalNotes = (e: React.MouseEvent) => {
    setShowAdditionalNotesForm(!showAdditionalNotesForm)
    setAdditionalNotes(additionalNotesBackup)
  }
  const setOrderAdditionalNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setAdditionalNotes(e.target.value)

  const toggleCourierForm = (e: React.MouseEvent) =>
    setShowCourierForm(!showCourierForm)

  return (
    <>
      <div className='order-header'>
        {renderOrderSteps(orderState)}
        <div className='order-actions'>
          <span className='transaction'>
            Order{' '}
            <span className='id' data-testId='order-details-transaction-no'>
              #{transaction_id}
            </span>
          </span>
          <Button
            className='print'
            onClick={() => print(order.transaction_id)}
            type='primary'
            ghost
            icon={<PrinterOutlined />}
          >
            Print
          </Button>
          <Row className='_mb-0' align='middle' gutter={8}>
            {renderCallToActionButtons(orderState)}
          </Row>
        </div>
      </div>
      {showRefundForm && (
        <RefundForm
          key={uuid()}
          discardCallback={() => setShowRefundForm(false)}
          transactionId={transaction_id}
          successCallback={() => {
            setShowRefundForm(false)
            setOrderState('refunded')
            orderRefetch && orderRefetch()
            ordersListRefetch && ordersListRefetch()
          }}
        />
      )}
      {!showRefundForm && (
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Info' key='1'>
            <Row>
              <Col span={8}>
                <Descriptions
                  title='CLIENT DETAILS'
                  layout='vertical'
                  size='small'
                  column={1}
                >
                  <Descriptions.Item label='Name'>
                    {customer_details.first_name} {customer_details.last_name}
                  </Descriptions.Item>
                  <Descriptions.Item label='Email'>
                    {customer_details.email}
                  </Descriptions.Item>
                  <Descriptions.Item label='Number'>
                    {customer_details.contact_num}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8}>
                <Descriptions
                  title='ORDER FROM'
                  layout='vertical'
                  size='small'
                  column={1}
                >
                  <Descriptions.Item label='Store'>
                    {store.name}
                  </Descriptions.Item>
                  <Descriptions.Item label='Address'>
                    {address &&
                      `${address.flat_number || ''} ${address.line_1 || ''} ${
                        address.line_2
                      } ${address.city} ${address.zip}`}
                  </Descriptions.Item>
                  <Descriptions.Item label='Placed At'>
                    <Moment format='DD/MM/YYYY hh:mm A' tz='Europe/London' utc>
                      {order.inserted_at}
                    </Moment>
                  </Descriptions.Item>
                  <Descriptions.Item label='Order notes'>
                    {order.order_notes}
                  </Descriptions.Item>
                  <Descriptions.Item label={order.custom_field_name}>
                    {order.custom_field_value}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={8}>
                <Descriptions
                  title='ORDER FOR'
                  layout='vertical'
                  size='small'
                  column={1}
                >
                  <Descriptions.Item label='Name'>
                    {recipient_details?.first_name}{' '}
                    {recipient_details?.last_name}
                  </Descriptions.Item>
                  <Descriptions.Item label='Number'>
                    {recipient_details?.contact_num}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            {gift_wrap_message && (
              <Row>
                <Descriptions
                  title='Gifting'
                  layout='vertical'
                  size='small'
                  column={16}
                >
                  <Descriptions.Item>
                    <Alert message={gift_wrap_message} type='success' />
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            )}

            <Divider />
            {additionalNotes && !showAdditionalNotesForm && (
              <>
                <Descriptions title='Additional Notes' size='small' />
                <Row align='middle' justify='space-between' className='_mb-0'>
                  <Col span={20}>
                    <Space direction='vertical' size={1}>
                      <Text>{additionalNotes}</Text>
                      {additionalNotesTimeStamp && (
                        <Text type='secondary' italic>
                          Note written at{' '}
                          {moment(additionalNotesTimeStamp).format(
                            'MMM DD YYYY, h:mm:ss a'
                          )}
                        </Text>
                      )}
                    </Space>
                  </Col>
                  <Col span={4}>
                    <Row
                      className='_mb-0'
                      align='middle'
                      gutter={8}
                      justify='end'
                    >
                      <Col>
                        <Tooltip title='Delete'>
                          <Button
                            onClick={deleteOrderAdditionalNotesHandler}
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip title='Edit'>
                          <Button
                            onClick={toggleAdditionalNotes}
                            icon={<EditOutlined />}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Divider />
              </>
            )}

            {orderState !== 'pending' && showAdditionalNotesForm && (
              <>
                <Row gutter={[16, 8]}>
                  <Col>
                    <AdditionalNotesForm
                      additionalNotes={additionalNotes}
                      notesInputOnChangeHandler={setOrderAdditionalNotes}
                      toggleAdditionalNotes={toggleAdditionalNotes}
                      submitHandler={updateOrderAdditionalNotesHandler}
                      hasAdditionalNotes={order.additional_notes}
                    />
                  </Col>
                </Row>
                <Divider />
              </>
            )}

            {showCourierForm && (
              <>
                <Row gutter={[16, 8]}>
                  <Col>
                    <CourierForm
                      order={order}
                      successCallback={() => {
                        ordersListRefetch && ordersListRefetch()
                        orderRefetch && orderRefetch()
                        setShowCourierForm(false)
                      }}
                    />
                  </Col>
                </Row>
                <Divider />
              </>
            )}

            {COURIER_SUPPORT_ORDER_STATES.includes(orderState) && (
              <Row
                justify='space-between'
                align='middle'
                gutter={[16, 8]}
                className='_mb-0'
              >
                <Col>
                  <Space>
                    {['accepted', 'fulfilled'].includes(orderState) &&
                      order.fulfillment_type === 'delivery' &&
                      isUsingSlerpCourierPartner && (
                        <>
                          <Button
                            data-testId='order-details-book-another-courier-btn'
                            icon={<CodeSandboxOutlined />}
                            onClick={toggleCourierForm}
                          >
                            Book another courier
                          </Button>
                        </>
                      )}
                  </Space>
                </Col>
                {['accepted', 'fulfilled', 'refunded'].includes(orderState) && (
                  <Col>
                    {!additionalNotes && !showAdditionalNotesForm && (
                      <Button
                        data-testId='order-details-add-notes-btn'
                        icon={<PlusOutlined className='_pb-2' />}
                        onClick={toggleAdditionalNotes}
                        className='_uppercase'
                      >
                        Add Note
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            )}

            <Descriptions
              title='ORDER ITEMS'
              layout='vertical'
              size='small'
              column={1}
              className='_mt-24'
            ></Descriptions>
            <Table dataSource={order_items} pagination={false}>
              <Column
                title='Product'
                dataIndex='product_variant.name'
                key='product'
                render={(text: string, record: any) => {
                  return (
                    <span>
                      {record.quantity} x {record.product_variant.name}
                    </span>
                  )
                }}
              />
              <Column
                title='Applied modifiers'
                dataIndex='applied_modifiers'
                key='applied_modifiers'
                render={(value: any, record: any) => {
                  return value && value.length > 0 ? (
                    <AppliedModifiers
                      appliedModifiers={value}
                      arrangement={
                        record.product_variant.product
                          .modifier_group_arrangement
                      }
                    />
                  ) : (
                    <span>N/A</span>
                  )
                }}
              />
              <Column
                title='Price'
                dataIndex='amount'
                key='amount'
                render={(text) => {
                  return <span>£{text}</span>
                }}
              />
            </Table>
            {!!additionalCharges().length && (
              <Table
                dataSource={additionalCharges()}
                pagination={false}
                showHeader={false}
              >
                <Column
                  title='Charge name'
                  dataIndex='name'
                  key='charge_name'
                  render={(name: string, record: any) => {
                    return <div>{name}</div>
                  }}
                />
                <Column
                  title='Charge description'
                  dataIndex='description'
                  key='charge_description'
                  render={(description: string, record: any) => {
                    return <span>{description}</span>
                  }}
                />
                <Column
                  title='Charge amount'
                  dataIndex='amount'
                  key='charge_amount'
                  render={(amount: string, record: any) => {
                    return <span>{amount}</span>
                  }}
                />
              </Table>
            )}
            {!!refunds().length && (
              <Table
                dataSource={refunds()}
                pagination={false}
                showHeader={false}
              >
                <Column
                  title='Refund'
                  dataIndex='name'
                  key='name'
                  render={(name: string, refundItem: RefundItem) => {
                    return (
                      <>
                        <Row>
                          <Col>{name}</Col>
                        </Row>
                        <Row>
                          <Col>{refundItem.created}</Col>
                        </Row>
                      </>
                    )
                  }}
                />
                <Column
                  title='Reason'
                  dataIndex='reason'
                  key='reason'
                  render={(reason: string) => {
                    return <span>{reason}</span>
                  }}
                />
                <Column
                  title='Amount'
                  dataIndex='amount'
                  key='amount'
                  render={(amount: number) => {
                    return <span>{amount}</span>
                  }}
                />
              </Table>
            )}
            <MiscFeeForm order={order} refetch={orderRefetch} />
          </TabPane>
          <TabPane
            tab={capitalize(order.fulfillment_type)}
            key='2'
            className='delivery-tab-test-class'
          >
            {delivery && (
              <Row>
                <Col span={12}>
                  <Descriptions
                    title='DELIVERY INFO'
                    layout='vertical'
                    size='small'
                    column={1}
                  >
                    <Descriptions.Item label='Job ID'>
                      {delivery.job_id}
                    </Descriptions.Item>
                    <Descriptions.Item label='Status'>
                      {delivery.delivery_status}
                    </Descriptions.Item>
                    <Descriptions.Item label='Pickup Time'>
                      {delivery.pickup_time ? (
                        <Moment
                          format='DD/MM/YYYY hh:mm A'
                          tz='Europe/London'
                          utc
                        >
                          {delivery.pickup_time}
                        </Moment>
                      ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label='Dropoff Time'>
                      {delivery.dropoff_time ? (
                        <Moment
                          format='DD/MM/YYYY hh:mm A'
                          tz='Europe/London'
                          utc
                        >
                          {delivery.dropoff_time}
                        </Moment>
                      ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label='Dropoff Notes'>
                      {order.dropoff_notes}
                    </Descriptions.Item>
                    <Descriptions.Item label='Fee'>
                      £{delivery.initial_fee}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={12}>
                  <Descriptions
                    title='DRIVER DETAILS'
                    layout='vertical'
                    size='small'
                    column={1}
                  >
                    <Descriptions.Item label='Driver Name'>
                      {delivery.driver
                        ? `${delivery.driver.first_name} ${delivery.driver.last_name}`
                        : null}
                    </Descriptions.Item>
                    <Descriptions.Item label='Phone'>
                      {delivery.driver ? delivery.driver.phone : null}
                    </Descriptions.Item>
                    <Descriptions.Item label='Transport Type'>
                      {delivery.driver ? delivery.driver.transport_type : null}
                    </Descriptions.Item>
                    <Descriptions.Item label='ETA'>
                      {order.delivery_eta} mins
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
            )}
            {storeAddress && order.fulfillment_type === 'pickup' && (
              <Descriptions
                title='PICKUP INFO'
                layout='vertical'
                size='small'
                column={1}
              >
                <Descriptions.Item label='Order From'>
                  {store.name}
                  <br />
                  {`${storeAddress.address},`}
                  <br />
                  {`${storeAddress.city} ${storeAddress.zip}`}
                  <br />
                  {order.fulfillment_time_range ? (
                    <>
                      Order for:
                      <br />
                      {`${order.fulfillment_time_range} `}
                      {`(${moment(order.fulfillment_date).format(
                        'MMMM DD YYYY'
                      )})`}
                    </>
                  ) : (
                    'ASAP'
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}

            {canChangeFulfillmentDate() && (
              <React.Fragment>
                <Divider />

                <FulfillmentTimeSlot
                  order={order}
                  ordersListRefetch={ordersListRefetch}
                  orderRefetch={orderRefetch}
                />
              </React.Fragment>
            )}

            <Divider />
            {address && (
              <GoogleMapStatic geom={{ lat: address.lat, lng: address.lng }} />
            )}
          </TabPane>
          <TabPane tab='Payment' key='3'>
            {(payment && (
              <Descriptions
                title='PAYMENT INFO'
                layout='vertical'
                size='small'
                column={1}
              >
                <Descriptions.Item label='Payment ID'>
                  <Tag color='green'>
                    {payment.data.paid ? 'Paid' : 'Unpaid'}
                  </Tag>
                  {payment.data.id}
                </Descriptions.Item>
                <Descriptions.Item label='Payment for'>
                  {payment.data.description}
                </Descriptions.Item>
                <Descriptions.Item label='Amount'>
                  £{payment.data.amount / 100}
                </Descriptions.Item>
                <Descriptions.Item label='Fee'>
                  £
                  {payment.data.balance_transaction
                    ? payment.data.balance_transaction.fee / 100
                    : 0}
                </Descriptions.Item>
                <Descriptions.Item label='Paid At'>
                  <Moment format='DD/MM/YYYY hh:mm A' tz='Europe/London' unix>
                    {payment.data.created}
                  </Moment>
                </Descriptions.Item>
                {payment.data && payment.data.source && (
                  <Descriptions.Item label='Paid By'>
                    {payment.data.source.brand} {payment.data.source.last4}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label='Captured'>
                  {payment.data.outcome.authorized}{' '}
                  {payment.data.outcome.reason}{' '}
                  {payment.data.outcome.seller_message}
                </Descriptions.Item>
                <Descriptions.Item label='Fully Refunded'>
                  <Tag>{payment.data.refunded ? 'Yes' : 'No'}</Tag>
                </Descriptions.Item>
              </Descriptions>
            )) || <>No payment data</>}
          </TabPane>
          {delivery &&
            order.fulfillment_type === 'delivery' &&
            isUsingSlerpCourierPartner && (
              <TabPane
                tab='Couriers'
                key='4'
                data-testId='order-details-couriers-tab'
              >
                <CouriersList
                  orderState={orderState}
                  transactionId={transaction_id}
                  delivery={delivery}
                  additionalCouriers={additional_couriers}
                  store={store}
                  customer={customer_details}
                  orderFullfillmentTimeRange={order.fulfillment_time_range}
                  cancellationSuccessCallback={() => {
                    ordersListRefetch && ordersListRefetch()
                    orderRefetch && orderRefetch()
                  }}
                />
              </TabPane>
            )}
        </Tabs>
      )}
    </>
  )
}

export const print = (orderId: string) => {
  const url = `${window.location.origin}/print-order/${orderId}`
  const printPage = window.open(url, '_blank')
  if (printPage) printPage.onafterprint = printPage.close
}

export default OrderDetails
