import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

const padFigure = (figure: number) => figure.toString().padStart(2, '0')

const getRemainingTime = (timestamp: string | null) => {
  if (isEmpty(timestamp)) return ''

  const offset = moment().utcOffset() / 60

  const given = moment().isDST()
    ? moment(timestamp).add(offset, 'hour')
    : moment(timestamp)

  const current = moment()

  const duration = moment.duration(given.diff(current))

  const totalHours = duration.hours() + duration.days() * 24

  return `${padFigure(totalHours)}:${padFigure(duration.minutes())}:${padFigure(
    duration.seconds()
  )}`
}

const isItemSnoozed = (timestamp: string | null) => {
  if (isEmpty(timestamp)) return false

  const offset = moment().utcOffset() / 60

  const unsnoozeAt = moment().isDST()
    ? moment(timestamp).add(offset, 'hour')
    : moment(timestamp)

  const current = moment()

  return unsnoozeAt > current
}

export { getRemainingTime, isItemSnoozed }
