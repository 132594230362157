import React, { useState } from 'react'
import { Button, Table, Switch, Row, message } from 'antd'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { useSlerp } from '@slerp/client'
import {
  QUERY_LOYALTY_BONUSES,
  QUERY_LOYALTY_CARDS,
  UPDATE_BONUS_PUBLISHED
} from './LoyaltyQueries'
import { QUERY_LOCATIONS } from './../Locations/LocationQueries'
import LoyaltyBonusFormDrawer from './LoyaltyBonusFormDrawer'
import { LoyaltyCardType, StoreType } from './types'
import moment from 'moment-timezone'

const LoyaltyBonusContainer = (props) => {
  const { user } = useSlerp()

  const { data } = useQuery(QUERY_LOYALTY_CARDS, {
    variables: { merchant_id: user?.merchant?.id },
    fetchPolicy: 'no-cache'
  })

  const [updatePublished] = useMutation(UPDATE_BONUS_PUBLISHED, {
    fetchPolicy: 'no-cache'
  })

  const { data: storesData } = useQuery(QUERY_LOCATIONS, {
    variables: { merchantSlug: user?.merchant?.slug },
    fetchPolicy: 'no-cache'
  })

  const {
    data: bonusesData,
    loading: bonusesLoading,
    refetch
  } = useQuery(QUERY_LOYALTY_BONUSES, {
    variables: { merchantId: user.merchant.id },
    fetchPolicy: 'no-cache'
  })

  const [activeBonus, setActiveBonus] = useState()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)

  const closeFormDrawer = () => {
    setDrawerVisible(false)
    setActiveBonus()
  }

  const dataColumns = [
    {
      title: 'Active',
      dataIndex: 'published_at',
      key: 'active',
      render: (text, record) => {
        return (
          <Switch
            key={record.id}
            loading={publishLoading}
            checked={record.published_at}
            onChange={(on) => {
              setPublishLoading(true)
              const publishedAt = on ? moment() : null
              message.loading(
                `${
                  on ? 'Publishing' : 'Unpublishing'
                } loyalty bonus. Please wait...`
              )
              updatePublished({
                variables: { id: record?.id, publishedAt: publishedAt }
              })
                .then(() => {
                  setPublishLoading(false)
                  message.destroy()
                  message.success(
                    `Loyalty bonus has been ${on ? 'published' : 'unpublished'}`
                  )
                  refetch()
                })
                .catch((error) => {
                  setPublishLoading(false)
                  console.error(error)
                  message.destroy()
                  message.error(
                    `Unable to ${
                      on ? 'publish' : 'unpublish'
                    } loyalty bonus. Please contact support@slerp.com`
                  )
                })
            }}
          />
        )
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => {
        const text =
          record?.type === 'account_creation'
            ? 'Account Creation'
            : 'First Purchase'
        return (
          <div
            className='_pointer _link-secondary'
            onClick={() => {
              setActiveBonus(record)
              setDrawerVisible(true)
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: 'Card',
      dataIndex: ['loyalty_card', 'name'],
      key: 'loyalty_card'
    },
    {
      title: 'Number of stamps/points',
      dataIndex: 'stamp_count',
      key: 'stamp_count'
    },
    {
      title: 'Usages',
      dataIndex: ['usages', 'aggregate', 'count'],
      key: 'usage'
    }
  ]

  return (
    <>
      <Row justify='end'>
        <Button
          type='secondary'
          onClick={() => {
            setDrawerVisible(true)
          }}
        >
          SETUP LOYALTY BONUS
        </Button>
      </Row>
      <Table
        data-testid='loyaltyBonusesTable'
        dataSource={bonusesData?.loyalty_bonuses || []}
        columns={dataColumns}
        loading={bonusesLoading}
        pagination={{
          defaultPageSize: 100,
          position: ['bottomCenter'],
          showSizeChanger: true
        }}
      />
      <LoyaltyBonusFormDrawer
        drawerVisible={drawerVisible}
        closeFormDrawer={closeFormDrawer}
        stores={storesData?.stores?.filter(
          (store: StoreType) => !store.archived_at
        )}
        loyaltyCards={data?.loyalty_cards?.filter(
          (loyalty: LoyaltyCardType) => !loyalty.archived_at
        )}
        merchant={user?.merchant}
        loyaltyBonus={activeBonus}
        refetch={refetch}
      />
    </>
  )
}

export default LoyaltyBonusContainer
