import React from 'react'
import { OrderInfo } from './type'
import { isFullyRefunded } from './utils'
import { Steps } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'

const GOOD_ORDER_STATES = ['pending', 'accepted', 'fulfilled']
const STEPS = [
  { title: 'Pending' },
  { title: 'Accepted' },
  { title: 'Completed' }
]
const { Step } = Steps

const OrderSteps = ({ order }: OrderInfo) => {
  const { status } = order

  const getOrderCurrentStep = (orderStatus: string) => {
    let step = 0
    switch (orderStatus) {
      case 'pending':
        step = 0
        break
      case 'accepted':
        step = 1
        break
      case 'fulfilled':
        step = 2
        break
    }
    return step
  }

  const specificRefundStatusText = isFullyRefunded(order)
    ? 'Fully Refunded'
    : 'Partially Refunded'

  if (GOOD_ORDER_STATES.includes(status)) {
    return (
      <div className='order-steps' data-testid='order-info-steps-good-states'>
        <Steps current={getOrderCurrentStep(status)} size='large'>
          {STEPS.map((step, index) => (
            <Step title={step.title} key={index} />
          ))}
        </Steps>
      </div>
    )
  }

  return (
    <div className='fallback-order-step' data-testid='order-info-steps-refund'>
      <p className='icon'>
        <ExclamationCircleFilled />
      </p>
      {status === 'refunded' ? specificRefundStatusText : status}
    </div>
  )
}

export default OrderSteps
