import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { ShopConfig } from '../../shop'
import { CONFIG_QUERY } from './ConfigQueries'
import env from '../../../../../env'

import Loading from '../../../../../components/Utils/Loading'

interface ContextType {
  config: ShopConfig
}

const ConfigContext = React.createContext<ContextType>({} as ContextType)

export const ConfigProvider = ({ children }: any) => {
  const { user, merchant } = useSession()
  const { data } = useQuery(CONFIG_QUERY, {
    variables: { merchant: merchant.slug }
  })

  const [config, setConfig] = useState<ShopConfig | null>({
    apiKey: (user && user.api_key) || '',
    domain: (user && merchant.slug) || '',
    store: (data && data.merchants[0].stores[0].slug) || '',
    theme: (data && data.theme) || '{}',
    assetHost: env.ASSET_HOST,
    graphHost: env.GRAPH_HOST,
    version: '2'
  })

  useEffect(() => {
    if (!user || !data) return
    setConfig({
      apiKey: (user && user.api_key) || '',
      domain: (user && merchant.slug) || '',
      store: (data && data.merchants[0].stores[0].slug) || '',
      theme: (data && data.theme) || '{}',
      assetHost: env.ASSET_HOST,
      graphHost: env.GRAPH_HOST,
      version: '2'
    })
  }, [data, user])

  if (!config) return null
  if (config.assetHost) env.ASSET_HOST = config.assetHost
  if (config.graphHost) env.GRAPH_HOST = config.graphHost

  return (
    <ConfigContext.Provider value={{ config }}>
      {user && data && config ? children : <Loading />}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => useContext(ConfigContext)
