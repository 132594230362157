import React from 'react'
import {
  FormGroupSeparator,
  FieldGroup,
  FieldGroupLabel
} from '../../components/FormGroup'

import { useApp } from '../../contexts/App'

import { useTheme } from '../../contexts/Theme'
import { FontSelect } from '../../components/FontSelect'
import { ColorInput } from '../../components/ColorInput'
import { Col, Row, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const SidebarStyleSettings: React.FC = () => {
  const { shopThemeGet, shopThemeOnChange, shopThemeOnChangeWithEvent } =
    useTheme() as any

  const { screen } = useApp()

  return (
    <div className='_pl-16'>
      <FieldGroup>
        <FieldGroupLabel>Primary Colour</FieldGroupLabel>
        <ColorInput
          value={shopThemeGet('colors.primary')}
          onChange={shopThemeOnChange('colors.primary')}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldGroupLabel>
          <Row align='middle' className='_mb-0' gutter={8}>
            <Col>Heading Font</Col>
            <Col>
              <Tooltip
                placement='right'
                title={
                  <>
                    You will be selecting a font which affects your Welcome
                    Message on the Landing page and the Location Name on the
                    Shop page.
                  </>
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        </FieldGroupLabel>
        <FontSelect
          value={shopThemeGet('fonts.heading.family')}
          onChange={shopThemeOnChangeWithEvent('fonts.heading.family')}
        />
      </FieldGroup>

      {screen === 'shop' ? (
        ''
      ) : (
        <FieldGroup>
          <FieldGroupLabel>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Heading Colour</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      You will be selecting a font colour, which affects your
                      Welcome Message on the Landing page
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FieldGroupLabel>
          <ColorInput
            value={shopThemeGet('colors.textBold')}
            onChange={shopThemeOnChangeWithEvent('colors.textBold')}
          />
        </FieldGroup>
      )}

      {screen === 'landing' ? (
        ''
      ) : (
        <>
          <FieldGroup>
            <FieldGroupLabel>Body Font</FieldGroupLabel>
            <FontSelect
              value={shopThemeGet('fonts.body.family')}
              onChange={shopThemeOnChangeWithEvent('fonts.body.family')}
            />
          </FieldGroup>
        </>
      )}

      <FormGroupSeparator />
    </div>
  )
}

export { SidebarStyleSettings }
