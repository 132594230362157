import React from 'react'
import { Button, Col, Row } from 'antd'

interface ActionsProps {
  discardHandler: () => void
  saveHandler: () => void
  loading: boolean
}

const Actions = ({ discardHandler, saveHandler, loading }: ActionsProps) => {
  return (
    <Row className='product-pricings-actions _mb-4'>
      <Col span={12}></Col>
      <Col span={12}>
        <Row className='_mb-0' justify='end' gutter={[8, 8]}>
          <Col>
            <Button ghost type='primary' onClick={() => discardHandler()}>
              Discard
            </Button>
          </Col>
          <Col>
            <Button type='primary' onClick={saveHandler} loading={loading}>
              Save
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Actions
