import React from 'react'
import { Space, Button, notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { NotificationPlacement } from 'antd/lib/notification'

interface OpenArchiveWarningArgs {
  itemId: string
  itemName: string
  restore?: (() => void) | null
  description: string
  placement?: NotificationPlacement
  duration?: number
}

export const openArchiveWarning = ({
  itemId,
  itemName,
  restore,
  description,
  placement,
  duration
}: OpenArchiveWarningArgs) => {
  notification.open({
    message: (
      <Space align='center'>
        <Space direction='vertical' className='archive-content' align='start'>
          <div className='title' data-testid={`archive-warning-${itemName}`}>
            {itemName}
          </div>
          <div
            className='description'
            data-testid={`archive-warning-description-${itemName}`}
          >
            {description}
          </div>
        </Space>
        <Space>
          {restore && (
            <Button
              data-testid={`archive-warning-restore-${itemName}`}
              type='ghost'
              className='_mb-0 _uppercase'
              onClick={() => {
                restore()
                notification.close(itemId)
              }}
            >
              Undo
            </Button>
          )}
        </Space>
      </Space>
    ),
    className: 'archive-warning',
    icon: (
      <div className='icon-container'>
        <DeleteOutlined />
      </div>
    ),
    duration: duration || 7.5,
    top: 80,
    key: itemId,
    placement: placement || 'topRight'
  })
}
