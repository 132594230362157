import { useQuery } from '@apollo/client'
import { GET_MERCHANT_PRICING_BANDS } from './queries'
import { UseGetMerchantDeliveryPricingBandsProps } from './types'
import isEmpty from 'lodash/isEmpty'

const useGetMerchantDeliveryPricingBands = ({
  merchantId
}: UseGetMerchantDeliveryPricingBandsProps) => {
  const { data, loading, refetch } = useQuery(GET_MERCHANT_PRICING_BANDS, {
    variables: {
      merchantId
    },
    fetchPolicy: 'no-cache'
  })

  const stores = ((data?.merchants || [])[0] || {})?.stores || []
  const deliveryPricingBands = stores
    .filter(
      ({ store_order_type_settings }) => !isEmpty(store_order_type_settings)
    )
    .map(({ store_order_type_settings }) => store_order_type_settings)
    .reduce((storePricingBands, storeDeliverySettings) => {
      const normalizedStoreDeliverySettings = storeDeliverySettings.reduce(
        (pricingBand, deliverySetting) => {
          const current_delivery_pricing_bands = (
            deliverySetting.store_delivery_settings || []
          ).length
            ? deliverySetting.store_delivery_settings[0]
                ?.delivery_pricing_bands || []
            : []

          const accumulation = !!pricingBand[deliverySetting.store_id]
            ? pricingBand[deliverySetting.store_id][
                deliverySetting.order_type
              ] || []
            : []

          pricingBand[deliverySetting.store_id] = {
            ...pricingBand[deliverySetting.store_id],
            [deliverySetting.order_type]: [
              ...accumulation,
              ...current_delivery_pricing_bands
            ]
          }

          return pricingBand
        },
        {}
      )

      storePricingBands = {
        ...storePricingBands,
        ...normalizedStoreDeliverySettings
      }
      return storePricingBands
    }, {})

  return [deliveryPricingBands || [], { loading, refetch }]
}

export default useGetMerchantDeliveryPricingBands
