const required = { required: true, message: 'This is a required field.' }
const requiredRule = [required]

type CustomRuleType = { getFieldValue: (arg: string) => string }

const minimumNumerOfItemsRule = [
  required,
  ({ getFieldValue }: CustomRuleType) => ({
    async validator(_: any, value: number) {
      const withMaximumValue = getFieldValue('maximum_enabled') || false
      const maximum = getFieldValue('maximum') || 0
      if (!withMaximumValue) {
        return Promise.resolve()
      }

      if (value > maximum) {
        return Promise.reject(
          'The minimum number cannot be greater than the maximum number.'
        )
      } else {
        return Promise.resolve()
      }
    }
  })
]

export { minimumNumerOfItemsRule, requiredRule }
