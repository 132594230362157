import { DeliveryPricingBand } from './types'
import isEmpty from 'lodash/isEmpty'

interface ValidateDeliveryPricingBandsProps {
  pricingBands: DeliveryPricingBand[]
  index: number
}

interface ValidateDeliveryPricingBandProps {
  pricingBand: DeliveryPricingBand
}

interface IsWithInfiniteUpperThresholdProps {
  pricingBand: DeliveryPricingBand
}

interface FormatPricingBandsForPersistenceProps {
  pricingBand: DeliveryPricingBand
}

const ValidateDeliveryPricingBand = ({
  pricingBand
}: ValidateDeliveryPricingBandProps) => {
  const { lowerThreshold, upperThreshold, percentageDiscount } =
    pricingBand || {}
  // check if lowerThreshold contains undefined
  const withInvalidLowerThreshold = lowerThreshold == null

  // check if percentageDiscount contains undefined
  const withInvalidPercentageDiscount = percentageDiscount == null

  // check if percentageDiscount is within acceptable range 1 - 100
  const withInvalidPercentageDiscountRange =
    percentageDiscount <= 0 || percentageDiscount > 100

  // check if lower range exceeds upper range
  const withInvalidLowerThresholdRange =
    parseInt(lowerThreshold) >= parseInt(upperThreshold)

  // check if higher range is lower than the lower range
  const withInvalidUpperThresholdRange =
    parseInt(upperThreshold) <= parseInt(lowerThreshold)

  return [
    withInvalidLowerThreshold ||
      withInvalidPercentageDiscount ||
      withInvalidPercentageDiscountRange ||
      withInvalidLowerThresholdRange ||
      withInvalidUpperThresholdRange,
    {
      withInvalidLowerThreshold,
      withInvalidPercentageDiscount,
      withInvalidPercentageDiscountRange,
      withInvalidLowerThresholdRange,
      withInvalidUpperThresholdRange
    }
  ]
}

const ValidateDeliveryPricingBands = ({
  pricingBands
}: ValidateDeliveryPricingBandsProps) => {
  // check if lowerThreshold contains undefined
  const withInvalidLowerThreshold = !isEmpty(
    pricingBands.find(
      ({ lowerThreshold }: DeliveryPricingBand) => lowerThreshold == null
    )
  )

  // check if percentageDiscount contains undefined
  const withInvalidPercentageDiscount = !isEmpty(
    pricingBands.find(
      ({ percentageDiscount }: DeliveryPricingBand) =>
        percentageDiscount == null
    )
  )
  // check if percentageDiscount is within acceptable range 1 - 100
  const withInvalidPercentageDiscountRange = !isEmpty(
    pricingBands.find(
      ({ percentageDiscount }: DeliveryPricingBand) =>
        percentageDiscount <= 0 || percentageDiscount > 100
    )
  )

  const withInvalidThresholdRanges = !isEmpty(
    pricingBands
      .filter(
        ({ upperThreshold }: DeliveryPricingBand) => upperThreshold != null
      )
      .find(
        ({ lowerThreshold, upperThreshold }: DeliveryPricingBand) =>
          parseInt(lowerThreshold) >= parseInt(upperThreshold)
      )
  )

  return (
    withInvalidLowerThreshold ||
    withInvalidPercentageDiscount ||
    withInvalidPercentageDiscountRange ||
    withInvalidThresholdRanges
  )
}

const IsWithInfiniteUpperThreshold = ({
  pricingBands
}: IsWithInfiniteUpperThresholdProps) => {
  const lastPricingBandIndex = pricingBands.length - 1
  const { upperThreshold } = pricingBands[lastPricingBandIndex] || {}

  return !!pricingBands.length && isEmpty(upperThreshold)
}

const FormatPricingBandsForPersistence = ({
  pricingBands
}: FormatPricingBandsForPersistenceProps) => {
  return pricingBands.map(
    ({
      lowerThreshold,
      upperThreshold,
      percentageDiscount
    }: DeliveryPricingBand) => {
      return {
        lowerThreshold: parseInt(lowerThreshold),
        upperThreshold:
          upperThreshold != null ? parseInt(upperThreshold) : null,
        percentageDiscount: parseInt(percentageDiscount)
      }
    }
  )
}

export {
  ValidateDeliveryPricingBands,
  ValidateDeliveryPricingBand,
  IsWithInfiniteUpperThreshold,
  FormatPricingBandsForPersistence
}
