import React from 'react'
import { Decimal } from 'decimal.js'
import debounce from 'lodash/debounce'
import { Col, InputNumber, Row } from 'antd'
import { computeGrossPrice, computeNetPrice } from 'components/Utils/price'
import { Pricing } from '../types'

interface VariantPriceFormProps {
  disabled: boolean
  pricing: Pricing
  updateGrossPriceHandler: (val: number | null) => void
  updateDefaultVatHandler: (val: number | null) => void
  reset?: () => void
}

const VariantPricingForm = ({
  disabled,
  pricing,
  updateDefaultVatHandler,
  updateGrossPriceHandler,
  reset
}: VariantPriceFormProps) => {
  const { id, vat, price } = pricing

  const grossPrice =
    price !== null && vat !== null ? computeGrossPrice(price, vat) : null
  const defaultVat = vat
  const priceWithoutVat =
    grossPrice !== null && defaultVat !== null
      ? computeNetPrice(grossPrice, defaultVat)
      : null
  const triggerUpdateGrossPrice = (val: number | null) => {
    updateGrossPriceHandler(val)

    if (val === null && defaultVat === null) {
      updateDefaultVatHandler(0)
    }
  }
  const debounceTriggerUpdateGrossPriced = debounce(
    triggerUpdateGrossPrice,
    400
  )

  const triggerUpdateDefaultVat = (val: number | null) => {
    updateDefaultVatHandler(val)
  }
  const debounceTriggerUpdateDefaultVat = debounce(triggerUpdateDefaultVat, 400)

  const isValidFloat = (val: any) => {
    const parsed = parseFloat(val)
    return !isNaN(parsed)
  }

  return (
    <Row
      gutter={[8]}
      align='middle'
      justify='end'
      className='_mb-0'
      key={id}
      data-testid='variant-general-pricing-form'
    >
      <Col span={8}>
        <InputNumber
          disabled={disabled}
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-pricing-form-gross-price-field'
          onChange={(val: string | number | undefined) => {
            const parsed = isValidFloat(val)
              ? new Decimal(val || 0).toNumber()
              : null
            debounceTriggerUpdateGrossPriced(parsed)

            if (parsed !== null && defaultVat === null) {
              debounceTriggerUpdateDefaultVat(0)
            }
          }}
          defaultValue={grossPrice === null ? undefined : grossPrice}
        />
      </Col>
      <Col span={8}>
        <InputNumber
          disabled={disabled}
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-pricing-form-vat-field'
          onChange={(val: string | number | undefined) => {
            const parsed = new Decimal(val || 0).toNumber()
            debounceTriggerUpdateDefaultVat(parsed)
          }}
          defaultValue={defaultVat === null ? undefined : defaultVat}
          value={defaultVat === null ? undefined : defaultVat}
        />
      </Col>
      <Col span={8}>
        <InputNumber
          disabled
          min={0}
          precision={2}
          className='_w-10'
          data-testid='variant-pricing-form-price-field'
          defaultValue={priceWithoutVat === null ? undefined : priceWithoutVat}
          value={priceWithoutVat === null ? undefined : priceWithoutVat}
        />
      </Col>
    </Row>
  )
}

export default VariantPricingForm
