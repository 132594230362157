import { Select } from 'antd'
import { uuid } from 'uuidv4'

interface HourPickerProps {
  value: string
  onChange: () => void
  classNames?: string
  dataTestId?: string
  disabled?: boolean
  style?: { [key: string]: string | number }
}

const { Option } = Select

const HourPicker = ({
  value,
  onChange,
  classNames,
  dataTestId,
  disabled = false,
  style = { width: '100%' }
}: HourPickerProps) => {
  const convertToHours = (seconds: number) => {
    const hour = Math.floor(seconds / 3600)
    const minute = Math.floor((seconds % 3600) / 60)
    const padFigure = (figure: number) => figure.toString().padStart(2, '0')
    return `${padFigure(hour)}:${padFigure(minute)}`
  }

  const hourOptions = () => {
    const limit = 86000 // max hour
    const interval = 900 // 15 minute interval
    let runningSeconds = 0
    let options = []

    while (runningSeconds < limit) {
      options.push(convertToHours(runningSeconds))
      runningSeconds = runningSeconds + interval
    }
    return options
  }

  return (
    <Select
      style={style}
      className={classNames ? classNames : ''}
      data-testid={dataTestId ? dataTestId : ''}
      disabled={disabled}
      value={value}
      onChange={onChange}
    >
      {hourOptions().map((option: string) => (
        <Option value={option} key={uuid()}>
          {option}
        </Option>
      ))}
    </Select>
  )
}

export default HourPicker
