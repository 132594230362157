import React, { useState, useEffect } from 'react'
import { useSlerp } from '@slerp/client'
import { useQuery, useMutation } from '@apollo/client'
import { Select, Form, Button, Modal, message } from 'antd'
import { requiredRule } from './Rules'
import { TextFieldNumber } from './../Widgets'
import { StoreType, LoyaltyCustomerListItem } from './types'
import {
  QUERY_LOYALTY_CARDS,
  STAMP_CUSTOMER_LOYALTY_CARD
} from './LoyaltyQueries'
import { QUERY_LOCATIONS } from './../Locations/LocationQueries'

interface StampCustomerModalProps {
  customer: LoyaltyCustomerListItem
  visible: boolean
  onClose: () => void
  stores: StoreType[]
}

const { useForm } = Form

const StampCustomerModal = (props: StampCustomerModalProps) => {
  const { visible, onClose, customer } = props
  const [form] = useForm()
  const { user } = useSlerp()

  const { data: loyaltyCards } = useQuery(QUERY_LOYALTY_CARDS, {
    variables: {
      merchant_id: user.merchant.id
    },
    fetchPolicy: 'no-cache'
  })

  const { data: storesData } = useQuery(QUERY_LOCATIONS, {
    variables: { merchantSlug: user?.merchant?.slug },
    fetchPolicy: 'no-cache'
  })

  const [stampCard, { loading: stampLoading }] = useMutation(
    STAMP_CUSTOMER_LOYALTY_CARD,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [selectedCard, setSelectedCard] = useState()
  const [validStores, setValidStores] = useState([])

  useEffect(() => {
    if (selectedCard?.all_stores_enabled) {
      setValidStores(storesData?.stores || [])
    } else {
      const storeOptions = storesData?.stores?.filter((store) =>
        selectedCard?.store_ids?.includes(store.id)
      )
      setValidStores(storeOptions || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard])

  const stampCustomerCard = (values) => {
    const params = {
      ...values,
      stamps_per_scan: Number(values.stamps_per_scan),
      customer_id: customer.id
    }

    message.loading('Stamping Customer Card. Please wait.', 1)
    stampCard({ variables: params })
      .then((result) => {
        message.destroy()
        message.success('Customer loyalty card stamp success!', 1)
      })
      .catch((error) => {
        console.error('Stamp Customer Card Error: ', error)
        message.destroy()
        message.error(
          `Unable to stamp customer loyalty card due to ${error}.`,
          3
        )
      })
  }

  const modalFooter = [
    <Button key='discard' type='ghost' onClick={onClose}>
      Discard
    </Button>,
    <Button
      key='submit'
      type='secondary'
      htmlType='submit'
      onClick={() => {
        form.submit()
      }}
      loading={stampLoading}
    >
      Add Stamps
    </Button>
  ]

  const modalTitle = (
    <div className='qr-modal-header'>
      <h2 className='title'>Add Stamps</h2>
    </div>
  )

  return (
    <Modal
      closable={false}
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      forceRender={true}
      className='loyalty-qr-modal'
      title={modalTitle}
      footer={modalFooter}
    >
      <Form
        className='_pl-4'
        form={form}
        onFinish={stampCustomerCard}
        layout='vertical'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label='Customer:'>
          <strong>{`${customer?.firstName || customer?.first_name} ${
            customer?.lastName || customer?.last_name
          }`}</strong>
        </Form.Item>
        <Form.Item name='code' label='Loyalty Card:' rules={requiredRule}>
          <Select
            className='_mt-10'
            placeholder='Select card'
            onChange={(value) => {
              const selected = loyaltyCards?.loyalty_cards?.find(
                (card) => card.code === value
              )
              setSelectedCard(selected)
            }}
          >
            {loyaltyCards?.loyalty_cards
              ?.filter((card) => !card.archived_at && card.published)
              .map((card) => (
                <Select.Option key={card?.id} value={card?.code}>
                  {card?.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {selectedCard && (
          <Form.Item name='store_id' label='Store:' rules={requiredRule}>
            <Select className='_mt-10' placeholder='Select store'>
              {validStores?.map((store) => (
                <Select.Option key={store?.id} value={store?.id}>
                  {store?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name='stamps_per_scan'
          label='Number of stamps/points:'
          rules={[
            ...requiredRule,
            {
              validator: (_, val) => {
                if (!val) return Promise.resolve()

                if (!Number.isInteger(val)) {
                  return Promise.reject('Decimal is not allowed')
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <TextFieldNumber
            inputType='number'
            min={1}
            step={1}
            style={{ maxWidth: '160px' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default StampCustomerModal
