import React from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import {
  filterSavedDates,
  formatDate,
  formatDatesWithOrders,
  getDates
} from './helpers'
import moment from 'moment'
import { OperationVariables } from 'react-apollo'
import { ApolloQueryResult } from '@apollo/client'
import { EventValue, RangeValue } from 'rc-picker/lib/interface'
import classnames from 'classnames'

type DatesWithOrdersType = { [key: string]: string[] }

const DatePicker = ({
  datesWithOrders,
  fetchDatesWithOrdersByMonth,
  merchantId,
  storeId,
  setDatesWithOrders,
  orderDateOnchangeHandler
}: {
  datesWithOrders: DatesWithOrdersType
  fetchDatesWithOrdersByMonth: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<any>>
  merchantId: string
  storeId: string
  setDatesWithOrders: (datesWithOrders: DatesWithOrdersType) => void
  orderDateOnchangeHandler: (dateRange: RangeValue<moment.Moment>) => void
}) => {
  const handleDatePickerChange = (values: EventValue<moment.Moment>[]) => {
    const dates = filterSavedDates(
      getDates(values),
      Object.keys(datesWithOrders)
    )
    const jobs = dates.map((date) =>
      fetchDatesWithOrdersByMonth({
        merchantId,
        storeId,
        date: formatDate(date)
      })
        .then((res) => ({
          month: formatDate(date, 'YYYY-MM'),
          dates: res.data.dates
        }))
        .catch((err) => {
          console.error(
            `Failed to fetch dates with orders on ${formatDate(
              date,
              'YYYY-MM'
            )}:`,
            err
          )
          throw new Error(err)
        })
    )

    Promise.all(jobs)
      .then((res) =>
        setDatesWithOrders(formatDatesWithOrders(res, datesWithOrders))
      )
      .catch((err) => console.error('Failed to fetch dates with orders:', err))
  }

  return (
    <AntDatePicker.RangePicker
      onOpenChange={(open) => {
        if (!open || !storeId) return
        handleDatePickerChange([moment()])
      }}
      onPanelChange={(values) => values && handleDatePickerChange(values)}
      onChange={orderDateOnchangeHandler}
      defaultValue={[moment(), moment()]}
      format='YYYY-MM-DD'
      dateRender={(momentDate) => {
        const formattedDate = formatDate(momentDate)
        const key = formatDate(momentDate, 'YYYY-MM')
        const hasOrder = (datesWithOrders[key] || []).includes(formattedDate)
        return (
          <div
            title={formattedDate}
            className='ant-picker-cell ant-picker-cell-in-view'
            key={`date-picker-date-${formattedDate}`}
          >
            <div
              className={classnames([
                'ant-picker-cell-inner',
                hasOrder ? '-has-orders' : ''
              ])}
            >
              {momentDate.date()}
              <div className='dot' />
            </div>
          </div>
        )
      }}
    />
  )
}

export default DatePicker
