const linePerOrderFields = [
  { value: 'location', label: 'Location' },
  { value: 'order_id', label: 'Order ID' },
  { value: 'order_date', label: 'Order date' },
  { value: 'order_date_and_time', label: 'Order date and time' },
  { value: 'fulfillment_date', label: 'Fulfillment date' },
  { value: 'fulfillment_timeslot', label: 'Fulfillment timeslot' },
  { value: 'order_status', label: 'Order status' },
  { value: 'fulfillment_type', label: 'Fulfillment type' },
  { value: 'order_type', label: 'Order type' },
  { value: 'product_total', label: 'Product total' },
  {
    value: 'product_total_after_discounts',
    label: 'Product total after discounts'
  },
  { value: 'product_discount', label: 'Product discount' },
  { value: 'discount_code', label: 'Discount Code' },
  // delivery_charge = delivery_fees_(charged_to_customer)
  {
    value: 'delivery_fees_(charged_to_customer)',
    label: 'Delivery fees (charged to customer)'
  },
  // delta_delivery_charge = delivery_subsidisation
  {
    value: 'delivery_subsidisation_post_discount_applied',
    label: 'Delivery subsidisation post-discount applied'
  },
  { value: 'self_hosted_delivery', label: 'Self hosted delivery?' },
  {
    value: 'quoted_courier_fee',
    label: 'Quoted Courier Fee'
  },
  { value: 'tips', label: 'Tips' },
  { value: 'minimum_order_value', label: 'Minimum order value' },
  { value: 'delivery_discount_applied', label: 'Delivery discount applied' },
  { value: 'refunded_amount', label: 'Refunded amount' },
  { value: 'gross_transaction_value', label: 'Gross transaction value' },
  { value: 'vat', label: 'VAT' },
  { value: 'order_items', label: 'Order items' },
  { value: 'customer_last_name', label: 'Customer last name' },
  { value: 'customer_first_name', label: 'Customer first name' },
  { value: 'customer_contact_num', label: 'Customer contact num' },
  { value: 'customer_email_address', label: 'Customer email address' },
  { value: 'recipient_address', label: 'Recipient address' },
  { value: 'recipient_city', label: 'Recipient city' },
  { value: 'recipient_name', label: 'Recipient name' },
  { value: 'recipient_contact_number', label: 'Recipient contact number' },
  { value: 'delivery_notes', label: 'Delivery notes' },
  { value: 'order_notes', label: 'Order notes' },
  { value: 'gift_wrap_message', label: 'Gifting message' },
  { value: 'platform', label: 'Platform' },
  {
    value: 'tiered_delivery_pricing_percent',
    label: 'Tiered Delivery pricing percent'
  },
  {
    value: 'tiered_delivery_actual_amount',
    label: 'Tiered Delivery actual amount'
  },
  { value: 'stamps_collected', label: 'Stamps Collected' },
  { value: 'rewards_claimed', label: 'Rewards Claimed' }
]

const linePerOrderItemFields = [
  { value: 'location', label: 'Location' },
  { value: 'order_id', label: 'Order ID' },
  { value: 'order_date', label: 'Order date' },
  { value: 'order_date_and_time', label: 'Order date and time' },
  { value: 'fulfillment_date', label: 'Fulfillment date' },
  { value: 'fulfillment_timeslot', label: 'Fulfillment timeslot' },
  { value: 'order_status', label: 'Order status' },
  { value: 'fulfillment_type', label: 'Fulfillment type' },
  { value: 'order_type', label: 'Order type' },
  { value: 'product_total', label: 'Product total' },
  {
    value: 'product_total_after_discounts',
    label: 'Product total after discounts'
  },
  { value: 'product_discount', label: 'Product discount' },
  { value: 'discount_code', label: 'Discount Code' },
  // delivery_charge = delivery_fees_(charged_to_customer)
  {
    value: 'delivery_fees_(charged_to_customer)',
    label: 'Delivery fees (charged to customer)'
  },
  // delta_delivery_charge = delivery_subsidisation
  {
    value: 'delivery_subsidisation_post_discount_applied',
    label: 'Delivery subsidisation post-discount applied'
  },
  { value: 'self_hosted_delivery', label: 'Self hosted delivery?' },
  {
    value: 'quoted_courier_fee',
    label: 'Quoted Courier Fee'
  },
  { value: 'minimum_order_value', label: 'Minimum order value' },
  { value: 'delivery_discount_applied', label: 'Delivery discount applied' },
  { value: 'refunded_amount', label: 'Refunded amount' },
  { value: 'gross_transaction_value', label: 'Gross transaction value' },
  { value: 'vat', label: 'VAT' },
  { value: 'name', label: 'Item name' },
  { value: 'modifiers', label: 'Modifiers' },
  { value: 'category', label: 'Category' },
  { value: 'price_per_quantity', label: 'Price per quantity' },
  { value: 'quantity', label: 'Quantity' },
  { value: 'customer_last_name', label: 'Customer last name' },
  { value: 'customer_first_name', label: 'Customer first name' },
  { value: 'customer_contact_num', label: 'Customer contact num' },
  { value: 'customer_email_address', label: 'Customer email address' },
  { value: 'recipient_address', label: 'Recipient address' },
  { value: 'recipient_city', label: 'Recipient city' },
  { value: 'recipient_recipient', label: 'Recipient name' },
  { value: 'recipient_contact_number', label: 'Recipient contact number' },
  { value: 'delivery_notes', label: 'Delivery notes' },
  { value: 'order_notes', label: 'Order notes' },
  { value: 'gift_wrap_message', label: 'Gift wrap message' },
  { value: 'platform', label: 'Platform' },
  { value: 'sku', label: 'SKU' },
  {
    value: 'tiered_delivery_pricing_percent',
    label: 'Tiered Delivery pricing percent'
  },
  {
    value: 'tiered_delivery_actual_amount',
    label: 'Tiered Delivery actual amount'
  },
  { value: 'stamps_collected', label: 'Stamps Collected' },
  { value: 'rewards_claimed', label: 'Rewards Claimed' }
]

const orderStatusFields = [
  { label: 'Pending', value: 'pending' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Fulfilled', value: 'fulfilled' },
  { label: 'Refunded', value: 'refunded' },
  { label: 'Cancelled', value: 'cancelled' }
]

export { linePerOrderFields, linePerOrderItemFields, orderStatusFields }
