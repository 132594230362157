import React, { ReactNode } from 'react'
import { Avatar, Col, Divider, Row } from 'antd'
import { OrderInfo } from 'components/Orders/Info/type'
import {
  getIcon,
  formatFulfillmentDate,
  formatFulfillmentTime
} from 'components/Orders/Info/utils'
import FulfillmentTimeRangeForm from './Forms/FulfillmentTimeRangeForm'
import Address from './Address'

interface DeliveryDetailsProps {
  order: OrderInfo
  reloadOrder: () => void
}

interface DetailProps {
  type: string
  content: string | ReactNode
}

const DeliveryDetails = ({ order, reloadOrder }: DeliveryDetailsProps) => {
  const { delivery, dropoff_notes, store, customer_details } = order
  const Detail = ({ type, content }: DetailProps) => (
    <Row align='middle' className='_mb-0'>
      <Col span={4}>
        <Avatar size={32} style={{ color: '#4a4a68' }} icon={getIcon(type)} />
      </Col>
      <Col span={20} className='_cl-text-detail _capitalize'>
        {content}
      </Col>
    </Row>
  )

  return (
    <Row className='_mb-0'>
      <Col span={24} className='_p-16'>
        <Row className='order-sub-header'>
          <Col span={24}>DELIVERY INFO</Col>
        </Row>

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Pickup from:
          </Col>
          <Col span={12} className='_cl-text-secondary-75'>
            Dropoff to:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail type='store' content={store.name} />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail
                  type='destination'
                  content={<Address address={customer_details?.address} />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Fulfillment date and time:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                {' '}
                <Detail
                  type='datestamp'
                  content={formatFulfillmentDate(order)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail
                  type='timestamp'
                  content={formatFulfillmentTime(order)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Vehicle to be booked:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>
            <Row align='middle' className='_mb-0'>
              <Col span={24}>
                <Detail
                  type='vehicle'
                  content={delivery?.default_transport_type || ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Dropoff notes:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>{dropoff_notes ? dropoff_notes : 'N/A'}</Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />

        <Row className='_mb-2'>
          <Col span={12} className='_cl-text-secondary-75'>
            Delivery fee:
          </Col>
        </Row>
        <Row className='_mb-2' align='middle'>
          <Col span={12}>
            {delivery?.initial_fee ? `£${delivery?.initial_fee}` : 'N/A'}
          </Col>
        </Row>
        <Divider className='_mt-12 _mb-12' />
        <FulfillmentTimeRangeForm
          order={order}
          successCallback={() => reloadOrder()}
        />
      </Col>
    </Row>
  )
}

export default DeliveryDetails
