import { Layout, BackTop, Menu } from 'antd'
import React, { useState, useEffect } from 'react'
import { PoweroffOutlined } from '@ant-design/icons'
import LocationDashboard from '../Locations/Dashboard'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'
import { useSession, signOut, isAdmin } from '@slerp/accounts'
import { default as LocationSettings } from '../Locations/Settings'
import HelpCentre from '../Home/HelpCentre'
import NotFound from '../Misc/NotFound'
import { SignOut } from '../Utils/session'
import PrintOrder from 'components/Orders/PrintOrder'
import Home from '../Home'
import LayoutContainer from './LayoutContainer'
import { useHubspotChat } from './../Utils/useHubspotChat'
import UserFlow from './../Utils/Userflow'
import StripeRedirectRoute from './StripeRedirectRoute'
import moment from 'moment'

const { Content } = Layout

export interface PageHeaderType {
  title: string
  tooltipTitle?: React.ReactElement<any> | string
}

const StaffContainer = () => {
  const { merchant, user } = useSession()
  const location = useLocation()
  const [pathContext] = location?.pathname?.split('/').slice(1, 2)
  const [pageHeader, setPageHeader] = useState<PageHeaderType | undefined>(
    undefined
  )
  const hubspotJWT = useHubspotChat(user)
  const { initUserFlow, userflow } = UserFlow()
  const isIdentified = userflow.isIdentified()

  useEffect(() => {
    user.store && setPageHeader({ title: user.store.name })
  }, [])

  useEffect(() => {
    if (!hubspotJWT) return

    initUserFlow({ user, isAdmin: isAdmin(user) })

    window.hsConversationsSettings = {
      identificationEmail: user.email,
      identificationToken: hubspotJWT
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load()
    }
  }, [window.HubSpotConversations, hubspotJWT])

  useEffect(() => {
    if (!isIdentified) return
    const userInsertedAt = moment(user.inserted_at)
    const fourteenDaysAgo = moment().subtract(14, 'days')
    const timer = setTimeout(() => {
      if (userInsertedAt.isAfter(fourteenDaysAgo)) {
        userflow.openResourceCenter()
      }
    }, 7500)

    return () => clearTimeout(timer)
  }, [isIdentified])

  useEffect(() => {
    switch (pathContext) {
      case '':
        setPageHeader({
          title: 'Insights',
          tooltipTitle: (
            <span>
              All your insights on this dashboard can be filtered by your
              store's locations and time period.
            </span>
          )
        })
        break
      case 'heat-map':
        setPageHeader({
          title: 'Insights',
          tooltipTitle: (
            <span>
              All your insights on this dashboard can be filtered by your
              store's locations and time period.
            </span>
          )
        })
        break
      case 'location':
        setPageHeader({
          title: 'Locations',
          tooltipTitle: (
            <span>
              An{' '}
              <a
                href='https://support.slerp.com/en_gb/stores-and-locations-rkkCUtEpd'
                target='_blank'
                rel='noopener noreferrer'
              >
                overview
              </a>{' '}
              of your
              <br />
              stores' set up.
            </span>
          )
        })
        break
      case 'locations':
        setPageHeader({
          title: 'Locations',
          tooltipTitle: (
            <span>
              An{' '}
              <a
                href='https://support.slerp.com/en_gb/stores-and-locations-rkkCUtEpd'
                target='_blank'
                rel='noopener noreferrer'
              >
                overview
              </a>{' '}
              of your
              <br />
              stores' set up.
            </span>
          )
        })
        break
      default:
        setPageHeader(undefined)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const userMenu = (
    <Menu>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 12px',
          fontWeight: 'bold'
        }}
      >
        {`${user.firstname}`}
        <div
          style={{
            display: 'inline-block',
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            backgroundColor: 'lightgreen',
            margin: '0 8px 0 24px'
          }}
        ></div>
      </div>
      <Menu.Item key='sign-out'>
        <SignOut>
          <PoweroffOutlined /> <span>Sign Out</span>
        </SignOut>
      </Menu.Item>
    </Menu>
  )

  if (pathContext === 'print-order')
    return <Route path='/print-order/:order_id' exact component={PrintOrder} />

  return (
    <LayoutContainer
      merchant={merchant}
      user={user}
      pageHeader={{
        isShown: !!pageHeader,
        title: pageHeader?.title
      }}
      userMenu={userMenu}
    >
      <Content
        className={`site-content -main`}
        style={{
          padding: 24,
          overflow: 'auto',
          minWidth: 1024
        }}
      >
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/:heat-map' exact component={Home} />
          <Route path='/location/:slug' exact component={LocationDashboard} />
          <Route path='/location/:slug/settings' component={LocationSettings} />
          <Route path='/help-centre' component={HelpCentre} />
          <StripeRedirectRoute path='/locations/stripe/connect' />
          <Route path='/locations'>
            <Redirect
              to={`/location/${
                user.store?.slug || user.stores?.[0].store.slug
              }`}
            />
          </Route>
          <Route
            path='/sign-out'
            component={() => {
              signOut()
              return <></>
            }}
          />
          <Route component={NotFound} />
        </Switch>
        <BackTop />
      </Content>
    </LayoutContainer>
  )
}

export default StaffContainer
