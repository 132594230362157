import { gql } from '@apollo/client'

export const MUTATION_CONNECT_AND_UPDATE_STRIPE_SETTINGS = gql`
  mutation connectAndUpdateStoreStripeSettings(
    $authCode: String!
    $storeId: String!
  ) {
    connectAndUpdateStoreStripeSettings(
      authCode: $authCode
      storeId: $storeId
    ) {
      id
    }
  }
`
