import React from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import {
  SettingOutlined,
  RiseOutlined,
  CreditCardOutlined,
  UserOutlined,
  ProjectOutlined
} from '@ant-design/icons'
import { TermsIcon } from '@slerp/assets'

const SettingsSidebar = () => {
  return (
    <>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[
          window.location.pathname.replace('/settings/', '')
        ]}
      >
        <Menu.Item key='general'>
          <SettingOutlined />
          <Link to='/settings/general'>General</Link>
        </Menu.Item>
        <Menu.Item key='delivery'>
          <CreditCardOutlined />
          <Link to='/settings/delivery'>Delivery</Link>
        </Menu.Item>
        <Menu.Item key='accounts-and-billing'>
          <ProjectOutlined />
          <Link to='/settings/accounts-and-billing'>Accounts and Billing</Link>
        </Menu.Item>
        <Menu.Item key='analytics'>
          <RiseOutlined />
          <Link to='/settings/analytics'>Marketing and Analytics</Link>
        </Menu.Item>
        <Menu.Item key='admin_users'>
          <UserOutlined />
          <Link to='/settings/admin_users'>Users</Link>
        </Menu.Item>
        <Menu.Item key='terms-and-conditions'>
          <TermsIcon className='terms-icon' />
          <Link to='/settings/terms-and-conditions'>Terms and Conditions</Link>
        </Menu.Item>
      </Menu>
    </>
  )
}

export default SettingsSidebar
