import React from 'react'
import AutocompleteInput, { AddressValueType } from './AutocompleteInput'
import { AddressHash } from './DetailsForm'

interface Props {
  value?: { formattedAddress: string }
  onChange?: (e: AddressValueType) => void
  disabled?: boolean
  onSelectCallback?: (addressHash: AddressHash) => void
}

const AddressInput = React.forwardRef(
  (
    { value, onChange, disabled = false, onSelectCallback }: Props,
    ref: any
  ) => {
    const handleOnChange = (
      address: string,
      geocodedAddress: AddressValueType
    ) => {
      if (!geocodedAddress.geocoded) {
        return (
          onChange &&
          onChange({
            formattedAddress: address,
            validateTrigger: false,
            noSuggestionSelected: true
          })
        )
      }
      onChange && onChange(geocodedAddress)
      onSelectCallback && onSelectCallback(geocodedAddress)
    }

    return (
      <div
        ref={ref}
        onPaste={() => {
          onChange &&
            onChange({ noSuggestionSelected: true, validateTrigger: true })
        }}
      >
        <AutocompleteInput
          placeholder='Enter house no. or building name'
          onChange={handleOnChange}
          disabled={disabled}
          value={value?.formattedAddress}
        />
      </div>
    )
  }
)

export default AddressInput
