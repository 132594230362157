import React from 'react'
import { BsTag as Tag } from 'react-icons/bs'
import { useQuery } from '@apollo/client'
import { GET_ORDER_DISCOUNT } from 'components/Orders/OrderQueries'
import { Tooltip } from 'antd'

type Props = {
  merchantId: string
  discountCode: string
}

const AppliedDiscount = ({ merchantId, discountCode }: Props) => {
  const { data, loading } = useQuery(GET_ORDER_DISCOUNT, {
    variables: { merchantId, code: discountCode }
  })

  const discounts = data?.discounts?.[0]

  if (loading || !discounts) {
    return null
  }

  const discountTag = (
    <div className='applied-discount'>
      <div className='content'>
        <div className='wrapper'>
          <Tag />
          <p className='code'>{discounts.code}</p>
          {!!discounts.description && (
            <span className='description'>{discounts.description}</span>
          )}
        </div>
      </div>
    </div>
  )

  if (discounts.description?.length < 25) {
    return <div className='_py-24'>{discountTag}</div>
  }

  return (
    <div className='_py-24'>
      <Tooltip placement='bottom' title={discounts.description}>
        {discountTag}
      </Tooltip>
    </div>
  )
}

export default AppliedDiscount
