import React, { lazy, Suspense } from 'react'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { useShop } from '../../contexts/Shop'
import {
  FormGroup,
  FormGroupTitleMenu,
  FormGroupTitle,
  FormGroupContent,
  FormGroupSeparator,
  FieldGroup
} from '../../components/FormGroup'
import { Uploader } from '../../components/Uploader'
import { SwitchToggle } from '../../components/SwitchToggle'
import { FaSun } from '../../components/Icon'
import { TextInput } from '../../components/TextInput'
import styled from '@emotion/styled'

const Editor = lazy(() => import('./../../components/Editor'))

const LandingSidebarControl: React.FC = () => {
  const {
    merchantGet,
    merchantImageGet,
    merchantOnChange,
    merchantImageOnChange,
    merchantImagesLoadMap,
    merchantOnChangeWithEvent
  } = useShop()

  return (
    <>
      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Upload Logo</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What will the logo be used for?</strong>
                      <br />
                      <br />
                      <div>
                        The logo will be used for consistent branding throughout
                        your Slerp customer experience. Learn how to format your
                        images{' '}
                        <a
                          className='_link-white _underline'
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://support.slerp.com/knowledge/uploading-images'
                        >
                          here
                        </a>
                        .
                      </div>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Uploader
              file={merchantImageGet('avatar')}
              onFileSelect={merchantImageOnChange('avatar')}
              isUploading={merchantImagesLoadMap.avatar}
              icon={<FaSun />}
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Upload Splash image</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>Where is the splash image?</strong>
                      <br />
                      <br />
                      <div>
                        The splash image is displayed on the right side of your
                        landing page. Learn how to format your images{' '}
                        <a
                          className='_link-white _underline'
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://support.slerp.com/knowledge/uploading-images'
                        >
                          here
                        </a>
                        .
                      </div>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Uploader
              file={merchantImageGet('header_image')}
              onFileSelect={merchantImageOnChange('header_image')}
              isUploading={merchantImagesLoadMap.header_image}
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Upload Favicon</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is a favicon?</strong>
                      <br />
                      <br />
                      <div>
                        A favicon is a small icon that serves as branding for
                        your website. Its primary purpose is to help visitors
                        locate your page more easily when multiple tabs are
                        open, allowing users to identify with your brand quickly
                        in search results
                        <br />
                        <br />
                        We will use a default icon if you do not provide a
                        favicon. Learn how to format your images{' '}
                        <a
                          className='_link-white _underline'
                          target='_blank'
                          rel='noopener noreferrer'
                          href='https://support.slerp.com/knowledge/uploading-images'
                        >
                          here
                        </a>
                        .
                      </div>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Uploader
              file={merchantImageGet('favicon')}
              onFileSelect={merchantImageOnChange('favicon')}
              isUploading={merchantImagesLoadMap.favicon}
              isFavicon
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroupSeparator />

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle tooltipHeading='' tooltipBody=''>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Welcome message</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is a welcome message?</strong>
                      <br />
                      <br />
                      This is a heading used to welcome your customers to your
                      online Slerp store.
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <div className='_mb-4'>Header:</div>
            <StyledTextInput
              value={merchantGet('welcome_message_header')}
              onChange={merchantOnChangeWithEvent('welcome_message_header')}
              placeholder='Enter your header text here ...'
              keyName='welcome_message_header'
            />
          </FieldGroup>
          <FieldGroup>
            <div className='_mb-4'>Body:</div>
            <Suspense fallback={<Spin />}>
              <Editor keyName={'welcome_message'} />
            </Suspense>
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroupSeparator />

      <FormGroup isCollapsible={false}>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Pick-up address field</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is this for?</strong>
                      <br />
                      <br />
                      Enabling this will suggest the closest store to the
                      customer.
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>

          <SwitchToggle
            checked={merchantGet('pickup_address_enabled')}
            onChange={merchantOnChange('pickup_address_enabled')}
          />
        </FormGroupTitleMenu>
      </FormGroup>

      <br />
    </>
  )
}

const StyledTextInput = styled(TextInput)`
  background-color: white;
  border: 1px solid #d1d5db;
`

export { LandingSidebarControl }
