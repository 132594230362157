import React, { useState } from 'react'

import { useSlerp } from 'packages/@slerp/client'
import { useQuery, useMutation } from '@apollo/client'

import { QUERY_LOCATIONS } from 'components/Locations/LocationQueries'
import { LoyaltyCardType, LoyaltyRewardType, StoreType } from './types'
import { FilterIcon, SelectDownArrowIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from 'components/Widgets'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import ReloadOutlined from '@ant-design/icons/ReloadOutlined'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import QrcodeOutlined from '@ant-design/icons/QrcodeOutlined'
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import {
  QUERY_LOYALTY_CARDS,
  QUERY_LOYALTY_CARD_REWARDS,
  UPDATE_ARCHIVE_SETTINGS,
  UPDATE_PUBLISH_SETTINGS
} from './LoyaltyQueries'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Table from 'antd/lib/table'
import Space from 'antd/lib/space'
import Avatar from 'antd/lib/avatar'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import LoyaltyDrawer from './LoyaltyDrawer'
import LoyaltyQRModal from './LoyaltyQRModal'
import { isExpired, filterStoresForQR } from './utils'
import moment from 'moment-timezone'
import env from 'env'

const { Column } = Table
const { Option } = Select

const LoyaltyCards = () => {
  const { user } = useSlerp()
  const { refetch, loading: isLoadingLoyaltyCards } = useQuery(
    QUERY_LOYALTY_CARDS,
    {
      variables: { merchant_id: user.merchant.id, isActive: true },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (!data) return
        const { loyalty_cards } = data

        setLoyaltyCards(loyalty_cards)
      }
    }
  )

  const { data: loyaltyRewardsData, refetch: rewardsRefetch } = useQuery(
    QUERY_LOYALTY_CARD_REWARDS,
    {
      variables: { merchant_id: user.merchant.id },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }
  )

  const { data: storesData, loading: isLoadingStores } = useQuery(
    QUERY_LOCATIONS,
    {
      variables: { merchantSlug: user?.merchant?.slug },
      fetchPolicy: 'no-cache'
    }
  )

  const [archiveMutation] = useMutation(UPDATE_ARCHIVE_SETTINGS, {
    fetchPolicy: 'no-cache'
  })

  const [publishMutation] = useMutation(UPDATE_PUBLISH_SETTINGS, {
    fetchPolicy: 'no-cache'
  })

  const [activeCard, setActiveCard] = useState<LoyaltyCardType | null>(null)
  const [activeQRCard, setActiveQRCard] = useState<LoyaltyCardType>()
  const [activeRewards, setActiveRewards] = useState<Array<LoyaltyRewardType>>(
    []
  )

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [qrDrawerVisible, setQRDrawerVisible] = useState(false)
  const [isArchived, setIsArchived] = useState(0)
  const [switchLoading, setSwitchLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [loyaltyCards, setLoyaltyCards] = useState<Array<LoyaltyCardType>>([])

  const loyaltyRewards =
    loyaltyRewardsData &&
    loyaltyRewardsData?.rewards?.map((reward: LoyaltyCardType) => reward)

  const showDrawer = (id: string) => {
    setActiveCard(
      loyaltyCards?.find((loyalty: LoyaltyCardType) => loyalty.id === id)
    )
    setActiveRewards(
      loyaltyRewards?.filter(
        (reward: LoyaltyRewardType) => reward.loyalty_card_id === id
      )
    )

    setDrawerVisible(true)
    return true
  }

  const onDrawerClose = () => {
    setActiveCard(null)
    setActiveRewards([])
    setDrawerVisible(false)
    refetch()
    rewardsRefetch()
  }

  const openQRDrawer = (id: string) => {
    setActiveQRCard(
      loyaltyCards?.find((loyalty: LoyaltyCardType) => loyalty.id === id)
    )

    setQRDrawerVisible(true)
  }

  const updateArchiveSettings = (card_id: string, name: string) => {
    setButtonLoading(true)
    message.loading(`Updating loyalty card. Please wait.`, 1)

    archiveMutation({
      variables: {
        archived_at: isArchived ? null : moment(Date.now()).format(),
        card_id: card_id,
        merchant_id: user.merchant.id
      }
    })
      .then((result) => {
        message.success(
          `${name} has been successfully ${
            isArchived ? 'activate' : 'archive'
          }d!`,
          1
        )
        setButtonLoading(false)
        refetch()
      })
      .catch((error) => {
        setButtonLoading(false)
        message.destroy()
        message.error(
          `Unable to update archive settings due to ${error.message}.`,
          3
        )
      })
  }

  const updatePublishSettings = (published: boolean, card_id: string) => {
    message.loading(`Updating publish settings. Please wait.`, 1)
    setSwitchLoading(true)

    publishMutation({
      variables: {
        published: published,
        card_id: card_id,
        merchant_id: user.merchant.id
      }
    })
      .then((result) => {
        message.success('Publish settings has been successfully updated!', 1)
        refetch()
        setSwitchLoading(false)
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to update publish settings due to ${error.message}.`,
          3
        )
        setSwitchLoading(false)
      })
  }

  const updateFilter = (value: number) => {
    refetch({ isActive: !value })
    setIsArchived(value)
  }

  const activeStores = !isLoadingStores
    ? storesData?.stores?.filter((store: StoreType) => !store.archived_at)
    : []

  return (
    <>
      <Row
        data-testid='loyalty-component'
        align='middle'
        justify='space-between'
      >
        <Col>
          <SelectPrefixWrapper prefixicon={<FilterIcon />}>
            <Select
              suffixIcon={<SelectDownArrowIcon />}
              defaultValue={0}
              onChange={updateFilter}
              value={isArchived}
              data-testid='loyalty-select'
            >
              <Option key='active' value={0}>
                Active Cards
              </Option>
              <Option key='archive' value={1} data-testid='loyalty-archived'>
                Archived Cards
              </Option>
            </Select>
          </SelectPrefixWrapper>
        </Col>
        <Col>
          <Button
            icon={<PlusOutlined className='_pb-8' />}
            className='_uppercase _ml-12 _link-white btn-text'
            onClick={() => setDrawerVisible(true)}
            data-testid='add-loyalty-card'
          >
            Add Card
          </Button>
        </Col>
      </Row>
      <Table
        data-testid='loyalty-cards-table'
        dataSource={loyaltyCards}
        pagination={{ defaultPageSize: 100 }}
        loading={isLoadingStores || isLoadingLoyaltyCards}
      >
        <Column
          title={
            <>
              <span>Published</span>
              <Tooltip
                title={`A card must be published to allow you to collect points, by default cards are saved as drafts, just click publish when you're ready to put the card live.`}
              >
                <QuestionCircleOutlined className='_ml-8 _mr-8' />
              </Tooltip>
            </>
          }
          key='published'
          dataIndex={'published'}
          render={(pub, record: { published: boolean; id: string }) => (
            <Switch
              key={`${record.id}${record.published}`}
              loading={switchLoading}
              checked={record.published}
              onChange={() => {
                updatePublishSettings(!record.published, record.id)
              }}
            />
          )}
        />
        <Column
          title='Card Info'
          key='card_info'
          dataIndex={['id', 'name', 'description', 'image']}
          render={(
            card,
            record: {
              id: string
              name: string
              description: string
              image: string
            }
          ) => (
            <span
              onClick={() => {
                showDrawer(record.id)
              }}
              key={`${record.id}${record.description}`}
              className='loyalty-edit-link'
            >
              <Space>
                <Col>
                  <Avatar
                    size={90}
                    shape='square'
                    src={`https://assets.${
                      env.ASSET_HOST
                    }/uploads/images/loyalty_card/${record.id}/${record.id}_${
                      record.image?.split('?')[0]
                    }_thumb.jpg?v=${record.image?.split('?')[1]}`}
                  />
                </Col>
                <Col>
                  <strong>{record.name}</strong>
                  <br />
                  {record.description}
                </Col>
              </Space>
            </span>
          )}
        />
        <Column
          title='Starts At'
          key='starts_at'
          dataIndex='starts_at'
          render={(
            card,
            record: { id: string; starts_at: LoyaltyCardType['starts_at'] }
          ) => (
            <div key={`${record.id}${record.starts_at}`} className='_no-wrap'>
              {moment
                .utc(record.starts_at)
                .tz('Europe/London')
                .format('DD/MM/YYYY')}
            </div>
          )}
        />
        <Column
          title='Ends At'
          key='ends_at'
          dataIndex='ends_at'
          render={(
            card,
            record: { id: string; ends_at: LoyaltyCardType['ends_at'] }
          ) => (
            <div key={`${record.id}${record.ends_at}`} className='_no-wrap'>
              {record.ends_at
                ? `${moment
                    .utc(record.ends_at)
                    .tz('Europe/London')
                    .format('DD/MM/YYYY')}`
                : 'Ongoing'}
            </div>
          )}
        />
        <Column
          title='Actions'
          key='archived_at'
          dataIndex='archived_at'
          render={(pub, record: { id: string; name: string }) => {
            const icon = isArchived ? <ReloadOutlined /> : <DeleteOutlined />

            return (
              <Row
                gutter={[8, 8]}
                align='middle'
                className='_mb-0'
                key={`${record.id}${record.name}`}
              >
                <Col>
                  <Button
                    loading={buttonLoading}
                    size='small'
                    icon={icon}
                    onClick={() =>
                      updateArchiveSettings(record.id, record.name)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size='small'
                    icon={<QrcodeOutlined />}
                    onClick={() => {
                      if (isExpired(record, moment())) {
                        message.error(
                          'Your loyalty card has expired. Please update the offer date if you want to generate a QR code.',
                          3
                        )
                      } else {
                        openQRDrawer(record.id)
                      }
                    }}
                  />
                </Col>
              </Row>
            )
          }}
        />
      </Table>
      <LoyaltyDrawer
        onClose={() => onDrawerClose()}
        activeCard={activeCard}
        activeRewards={activeRewards}
        visible={drawerVisible}
        stores={activeStores}
        onDrawerClose={onDrawerClose}
      />
      {activeQRCard && (
        <LoyaltyQRModal
          onClose={() => setQRDrawerVisible(false)}
          activeCard={activeQRCard}
          visible={qrDrawerVisible}
          stores={filterStoresForQR(
            activeStores,
            activeQRCard.all_stores_enabled,
            activeQRCard.store_ids
          )}
        />
      )}
    </>
  )
}

export default LoyaltyCards
