import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { InventoryItem, WithChanges } from './InventoryPublishDrawer'
import { Col, Divider, Modal, Row, Tabs, Tooltip } from 'antd'
import { GET_PRODUCTS_CATEGORIES } from 'components/ProductsAndModifiers/Products/ProductQueries'
import { useLazyQuery } from '@apollo/client'
import { ProductCategory } from 'components/ProductsAndModifiers/Products/Form'
import { ModifierGroup } from './Inventories'

interface InventoryChangesModalProps {
  visible: boolean
  loading: boolean
  onOk: () => void
  onCancel: Dispatch<SetStateAction<boolean>>
  variantsWithChanges: WithChanges | undefined
  modifiersWithChanges: WithChanges | undefined
  modifierGroupsData: ModifierGroup[] | undefined
}

type ChangesColumnProps = {
  changesCount: number
  changes?: InventoryItem[]
  title: string
  loading?: boolean
  actionType: 'publish' | 'unpublish'
} & (VariantProps | ModifierProps)

type VariantProps = {
  type: 'variant'
  categories: ProductCategory[]
}

type ModifierProps = {
  type: 'modifier'
  modifierGroups: ModifierGroup[] | undefined
}

const InventoryChangesModal = (props: InventoryChangesModalProps) => {
  const { TabPane } = Tabs
  const {
    onOk,
    onCancel,
    visible,
    loading,
    variantsWithChanges,
    modifiersWithChanges,
    modifierGroupsData
  } = props

  const [
    getPublishedProducsCategories,
    {
      data: publishedProductsCategoriesData,
      loading: isLoadingProductsCategories
    }
  ] = useLazyQuery<{ products_categories: ProductCategory[] }>(
    GET_PRODUCTS_CATEGORIES
  )

  const [
    getUnpublishedProducsCategories,
    {
      data: unpublishedProductsCategoriesData,
      loading: isLoadingUnpublishedProductsCategories
    }
  ] = useLazyQuery<{ products_categories: ProductCategory[] }>(
    GET_PRODUCTS_CATEGORIES
  )

  useEffect(() => {
    if ((variantsWithChanges?.published.length ?? 0) > 0) {
      const publishedProductIds = [
        ...new Set(
          variantsWithChanges?.published.map((v: InventoryItem) => v.productId)
        )
      ].map((id) => ({
        product_id: { _eq: id }
      }))

      getPublishedProducsCategories({
        variables: {
          productIds: publishedProductIds
        }
      })
    }
  }, [variantsWithChanges?.published.length])

  useEffect(() => {
    if ((variantsWithChanges?.unpublished.length ?? 0) > 0) {
      const unpublishedProductIds = [
        ...new Set(
          variantsWithChanges?.unpublished.map(
            (v: InventoryItem) => v.productId
          )
        )
      ].map((id) => ({
        product_id: { _eq: id }
      }))

      getUnpublishedProducsCategories({
        variables: {
          productIds: unpublishedProductIds
        }
      })
    }
  }, [variantsWithChanges?.unpublished.length])

  return (
    <Modal
      visible={visible}
      title='Confirm inventory changes'
      cancelButtonProps={{ type: 'ghost', className: '-secondary' }}
      okText='save'
      okButtonProps={{ type: 'default', loading: loading, disabled: loading }}
      onCancel={() => onCancel(false)}
      onOk={() => onOk()}
      className='inventory-changes'
      centered
    >
      <Tabs defaultActiveKey='0'>
        <TabPane
          key='1'
          tab={`Added (${
            (variantsWithChanges?.published.length ?? 0) +
            (modifiersWithChanges?.published.length ?? 0)
          })`}
        >
          <Row gutter={8} justify='space-around' className='_mt-16 _pr-16'>
            <Col span={11}>
              <ChangesColumn
                changesCount={variantsWithChanges?.published.length ?? 0}
                changes={variantsWithChanges?.published}
                title='Products'
                type='variant'
                actionType='publish'
                categories={
                  publishedProductsCategoriesData?.products_categories ?? []
                }
                loading={isLoadingProductsCategories}
              />
            </Col>
            <Col>
              <Divider type='vertical' />
            </Col>
            <Col span={11}>
              <ChangesColumn
                changesCount={modifiersWithChanges?.published.length ?? 0}
                changes={modifiersWithChanges?.published}
                title='Modifiers'
                type='modifier'
                actionType='publish'
                modifierGroups={modifierGroupsData}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          key='2'
          tab={`Removed (${
            (variantsWithChanges?.unpublished.length ?? 0) +
            (modifiersWithChanges?.unpublished.length ?? 0)
          })`}
        >
          <Row gutter={8} justify='space-around' className='_mt-16 _pr-16'>
            <Col span={11}>
              <ChangesColumn
                changesCount={variantsWithChanges?.unpublished.length ?? 0}
                changes={variantsWithChanges?.unpublished}
                title='Products'
                type='variant'
                actionType='unpublish'
                categories={
                  unpublishedProductsCategoriesData?.products_categories ?? []
                }
                loading={isLoadingUnpublishedProductsCategories}
              />
            </Col>
            <Col>
              <Divider type='vertical' />
            </Col>
            <Col span={11}>
              <ChangesColumn
                changesCount={modifiersWithChanges?.unpublished.length ?? 0}
                changes={modifiersWithChanges?.unpublished}
                title='Modifiers'
                type='modifier'
                actionType='unpublish'
                modifierGroups={modifierGroupsData}
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export const ChangesColumn = (props: ChangesColumnProps) => {
  const { changes, changesCount, title, type, loading, actionType } = props

  const filterInvolvedCategories = () => {
    if (type !== 'variant') return

    return changes?.map((c) => {
      if (!props.categories) return

      const involvedCategoryNames = props.categories
        .filter((g: ProductCategory) => c.productId === g.product_id)
        .map((pc) => pc.category.name)
      const isMoreThanOne = involvedCategoryNames?.length > 1

      return (
        <Tooltip
          title={
            <>
              <div className='_mb-8'>
                <strong>{c.label}</strong> will be{' '}
                {actionType === 'publish'
                  ? `added to ${
                      isMoreThanOne
                        ? 'these product categories'
                        : 'this product category'
                    }:`
                  : `removed from ${
                      isMoreThanOne
                        ? 'these product categories'
                        : 'this product category'
                    }:`}
              </div>
              <ul className='_pl-16'>
                {involvedCategoryNames.map((c) => (
                  <>
                    <li>{c}</li>
                  </>
                ))}
              </ul>
            </>
          }
          placement='left'
        >
          <Row className='list-item _mb-8' key={c.id} justify='center'>
            <span>{c.label}</span>
          </Row>
        </Tooltip>
      )
    })
  }

  const filterInvolvedModifierGroups = () => {
    if (type !== 'modifier') return

    return changes?.map((c) => {
      if (!props.modifierGroups) return

      const involvedModifierGroupNames: {
        name: string
        description: string
      }[] = props.modifierGroups
        .filter((g: ModifierGroup) => c.allGroupIds?.some((c) => c === g.id))
        .map((mg) => ({
          name: mg.name,
          description: mg.description
        }))
      const isMoreThanOne = involvedModifierGroupNames?.length > 1

      return (
        <Tooltip
          title={
            <>
              <div className='_mb-8'>
                <strong>{c.label}</strong> will be{' '}
                {actionType === 'publish'
                  ? `added to ${
                      isMoreThanOne
                        ? 'these modifier groups'
                        : 'this modifier group'
                    }:`
                  : `removed from ${
                      isMoreThanOne
                        ? 'these modifier groups'
                        : 'this modifier group'
                    }:`}
              </div>
              <ul className='_pl-16'>
                {involvedModifierGroupNames.map((c) => (
                  <>
                    <li>
                      {c.name}{' '}
                      {Boolean(c.description) ? `(${c.description})` : ''}
                    </li>
                  </>
                ))}
              </ul>
            </>
          }
          placement='right'
        >
          <Row className='list-item _mb-8' key={c.id} justify='center'>
            <span>{c.label}</span>
          </Row>
        </Tooltip>
      )
    })
  }

  if (loading) return <></>

  return (
    <>
      <Row
        justify='center'
        className={`_mb-8 -title ${
          Boolean(changesCount) ? '' : '-transparent'
        }`}
      >
        {title} ({changesCount ?? 0})
      </Row>
      {type === 'variant' ? filterInvolvedCategories() : <></>}
      {type === 'modifier' ? filterInvolvedModifierGroups() : <></>}
    </>
  )
}

export default InventoryChangesModal
