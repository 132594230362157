import styled from '@emotion/styled'

export const HiddenInputs = styled.div(
  ({ hidden = true }: { hidden?: boolean }) =>
    hidden
      ? {
          display: 'none',
          visibility: 'hidden'
        }
      : {}
)

export const SpinnerInputWrapper = styled.div(() => ({
  borderRadius: '4px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #dbd9df'
}))
