import { gql } from '@apollo/client'

export const QUERY_ORDER_LOCATIONS = gql`
  query orderCoords(
    $merchantId: uuid!
    $storeId: uuid
    $startDate: timestamp!
  ) {
    view_order_coords(
      limit: 1000
      order_by: { inserted_at: desc }
      where: {
        merchant_id: { _eq: $merchantId }
        store_id: { _eq: $storeId }
        inserted_at: { _gte: $startDate }
      }
    ) {
      coords
      store_id
      merchant_id
    }
  }
`

export const QUERY_MERCHANT_PRODUCT_STATS = gql`
  query getMerchantProductsSummary($merchant: String!) {
    products: view_merchant_product_summary(
      where: { merchant: { slug: { _eq: $merchant } } }
      order_by: { total_count: desc, total_amount: desc }
      limit: 10
    ) {
      total_count
      total_amount
      slug
      name
      id
      archived_at
      merchant_id
    }
  }
`
