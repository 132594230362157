import React from 'react'
import styled from '@emotion/styled'

const FieldGroup: React.FC = (props) => <Form>{props.children}</Form>

const Form = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 16px;
  width: 100%;

  &:not:(:last-child) {
    margin-bottom: 16px;
  }
`

export { FieldGroup }
