import React, { useMemo, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import invert from 'lodash/invert'
import { Store } from 'antd/lib/form/interface'
import { useMutation } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import { Col, Button, Form, Input, message, Row, Select, Divider } from 'antd'
import {
  CREATE_MERCHANT_ADMIN_USER,
  UPDATE_MERCHANT_ADMIN_USER,
  CREATE_STORE_USERS,
  CREATE_MERCHANT_USER_BY_ROLE
} from '../actions'
import {
  requiredRule,
  userEmailRule,
  editUserEmailRule
} from '../AdminUserFormRules'
import errorReducer from '../../../errors/errorReducer'
import LocationsSelect from './LocationsSelect'
import useGetUserStores from 'components/CustomHooks/Stores/useGetUserStores'
import { uuid } from 'uuidv4'

interface AdminUser {
  id: string
  email: string
  firstname: string
  lastname: string
}

interface AdminUserFormProps {
  adminUser?: AdminUser
  onCancelCallback: () => void
  onSaveCallback: () => void
  canEditDetails: boolean
}

const { useForm } = Form

const UserRoles = {
  admin: 'admin',
  manager: 'storeManager',
  member: 'storeUser'
}

const AdminUserForm = (props: AdminUserFormProps) => {
  const { merchant } = useSession()
  const [createMerchantAdminUser] = useMutation(CREATE_MERCHANT_ADMIN_USER)
  const [createStoreUsers] = useMutation(CREATE_STORE_USERS)
  const [createMerchantUserByRole] = useMutation(CREATE_MERCHANT_USER_BY_ROLE)
  const [updateMerchantAdminUser] = useMutation(UPDATE_MERCHANT_ADMIN_USER)
  const { adminUser, onCancelCallback, onSaveCallback, canEditDetails } = props
  const [form] = useForm()
  const isNewUser = isEmpty(adminUser)
  const [userStoresData] = useGetUserStores({
    userId: adminUser?.id
  })

  const storeUserLocationIds = useMemo(
    () => userStoresData.map(({ id }) => id),
    [userStoresData]
  )

  const getMutation = (role: 'admin' | 'storeManager' | 'storeUser') => {
    if (role === 'admin') return createMerchantAdminUser
    if (['storeManager', 'storeUser'].includes(role))
      return createMerchantUserByRole
  }

  const getVariablesByRole = (
    userRole: 'admin' | 'storeManager' | 'storeUser',
    values: Store
  ) => {
    const { firstname, lastname, email, password } = values
    const baseUserParams = {
      merchantId: merchant.id,
      firstname,
      lastname,
      email,
      password
    }

    if (userRole === 'admin') return baseUserParams
    if (userRole === 'storeManager')
      return {
        ...baseUserParams,
        role: 'manager'
      }
    if (userRole === 'storeUser')
      return {
        ...baseUserParams,
        role: 'member'
      }
  }

  const onFinish = (values: Store) => {
    form.validateFields().then(() => {
      const { role, locationIds } = values
      const variables = getVariablesByRole(role, values)

      if (isNewUser) {
        const mutation = getMutation(role)

        message.loading('Creating user... Please Wait.')
        mutation({ variables })
          .then((result) => {
            if (role === 'admin') {
              return result
            }

            return createStoreUsers({
              variables: {
                storeUsers: locationIds.map((storeId: string) => ({
                  id: uuid(),
                  is_active: true,
                  user_id: result.data.createMerchantStoreLevelUserByRole.id,
                  store_id: storeId,
                  inserted_at: 'now()',
                  updated_at: 'now()'
                }))
              }
            })
          })
          .then((result) => {
            message.destroy()
            message.success(`${values.email} created!`, 3)
            onSaveCallback()
          })
          .catch((error: Error) => {
            throw errorReducer({
              origin: 'Merchant',
              data: {
                error: error,
                message: error.message
              }
            })
          })
          .catch((error) => {
            message.error(error.message, 3)
          })
      } else {
        const { password, ...updateVariables } = {
          ...variables,
          userId: adminUser?.id,
          role: invert(UserRoles)[role],
          storeUsers:
            role === 'admin'
              ? []
              : locationIds.map((storeId: string) => ({
                  id: uuid(),
                  is_active: true,
                  user_id: adminUser?.id,
                  store_id: storeId,
                  inserted_at: 'now()',
                  updated_at: 'now()'
                }))
        }
        message.loading('updating user... Please Wait.')
        updateMerchantAdminUser({ variables: updateVariables })
          .then((result) => {
            message.destroy()
            message.success(`${values.email} updated!`, 3)
            onSaveCallback()
          })
          .catch((error: Error) => {
            throw errorReducer({
              origin: 'Merchant',
              data: {
                error: error,
                message: error.message
              }
            })
          })
          .catch((error) => {
            message.error(error.message, 3)
          })
      }
    })
  }

  const initialValues = isNewUser
    ? {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        role: 'admin',
        locationIds: storeUserLocationIds
      }
    : {
        ...adminUser,
        current_email: adminUser?.email,
        role: UserRoles[adminUser?.role],
        locationIds: storeUserLocationIds
      }

  const emailValidationRule: any = isNewUser ? userEmailRule : editUserEmailRule
  const resetLocationIds = () => {
    form.setFieldsValue({
      locationIds: []
    })
  }

  useEffect(
    () => form.setFieldsValue({ locationIds: storeUserLocationIds }),
    [storeUserLocationIds, form]
  )

  return (
    <Form
      data-testid='add-new-user-form-component'
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      wrapperCol={{ span: 16 }}
      layout='vertical'
    >
      <Form.Item name='firstname' label='First Name:' rules={requiredRule}>
        <Input disabled={!canEditDetails} />
      </Form.Item>
      <Form.Item name='lastname' label='Last Name:' rules={requiredRule}>
        <Input disabled={!canEditDetails} />
      </Form.Item>
      <Form.Item name='current_email' style={{ display: 'none' }}>
        <Input type='hidden' />
      </Form.Item>
      <Form.Item name='email' label='Email:' rules={emailValidationRule}>
        <Input disabled={!canEditDetails} />
      </Form.Item>
      {isNewUser && (
        <Form.Item name='password' label='Password:' rules={requiredRule}>
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item name='role' label='User Role:'>
        <Select
          onChange={resetLocationIds}
          data-testid='add-new-user-role-select-input'
          options={[
            {
              label: 'Admin',
              value: 'admin'
            },
            {
              label: 'Manager',
              value: 'storeManager'
            },
            {
              label: 'Location User',
              value: 'storeUser'
            }
          ]}
        />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.role !== currentValues.role
        }
      >
        {({ getFieldValue }) =>
          ['storeUser', 'storeManager'].includes(getFieldValue('role')) ? (
            <Form.Item
              data-testid='add-new-user-locations-access-select-input'
              name='locationIds'
              label='Apply access to the following locations:'
              rules={[
                {
                  required: true,
                  type: 'array',
                  min: 1,
                  message: 'At least 1 location must be selected'
                }
              ]}
            >
              <LocationsSelect userType={getFieldValue('role')} />
            </Form.Item>
          ) : null
        }
      </Form.Item>

      <Divider />
      <Row justify='space-between' gutter={[8, 8]} align='middle'>
        <Col>
          <Button onClick={onCancelCallback}>Cancel</Button>
        </Col>
        <Col>
          <Button type='primary' htmlType='submit'>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AdminUserForm
