import { Charge, OrderInfo, Refund } from '../../type'

export const getChargeDescription = (
  charge: Charge,
  order: OrderInfo,
  refunds: Refund[]
) => {
  const applicationFeeAmount =
    charge.transaction_log?.application_fee_amount ||
    charge.refund?.order?.transaction_logs?.[0]?.application_fee_amount ||
    0

  const feeAmount = toFixed(applicationFeeAmount)
  const refundAmount = toFixed(charge.refund?.amount)

  const total = toFixed(order.total)
  const forwardedFeeCharge = toFixed(charge.forwarded_fee_charge)
  const deliveryFeeCharge = toFixed(charge.delivery_fee_charge)
  const zeroOrderFeeAmount = toFixed(charge.zero_order_fee_amount)

  switch (charge.charge_type) {
    case 'zero_order_fee':
      if (charge.delivery_fee_charge && charge.zero_order_fee_amount)
        return `Free Order Commission (${zeroOrderFeeAmount}) and courier charge (${deliveryFeeCharge}) for ${order.transaction_id}.`
      else if (charge.delivery_fee_charge)
        return `Courier charge for ${order.transaction_id}.`
      else return `Free Order Commission for ${order.transaction_id}`
    case 'transaction_application_fee':
      return `Charges shortfall for ${order.transaction_id}; GTV was £${total} and application fee was £${feeAmount}`
    case 'additional_courier':
      return `Additional courier charge for ${order.transaction_id}`
    case 'reject_forwarded_fees':
      return `Card fee (£${forwardedFeeCharge}) for the rejection of ${order.transaction_id}`
    case 'refund_delivery_forwarded_fees':
      return `Card fee (£${forwardedFeeCharge}) and courier charge (£${deliveryFeeCharge}) for the full refund of ${order.transaction_id}`
    case 'refund_delivery_fees':
      return `Courier charge (£${deliveryFeeCharge}) for the full refund of ${order.transaction_id}`
    case 'refund_forwarded_fees':
      return `Card fee (£${forwardedFeeCharge}) for the full refund of ${order.transaction_id}`
    case 'refund_application_fee':
      const isPartialRefund = refunds?.length > 1
      let partnerAmount = order.total - applicationFeeAmount

      if (isPartialRefund) {
        partnerAmount += charge.refund?.amount || 0

        refunds.forEach((refund) => {
          partnerAmount -= refund.amount
        })
      }

      return `Charges shortfall for the partial refund of ${
        order.transaction_id
      }. Refund amount of £${refundAmount} is higher than the ${
        isPartialRefund ? 'balance ' : ''
      }partner amount of £${toFixed(partnerAmount)} for the transaction.`
    default:
      return ''
  }
}

export const getChargeStatus = (reverted_at: string | null) => {
  return reverted_at ? 'Refunded' : 'Completed'
}

export const toFixed = (value: number | string | null) => {
  switch (typeof value) {
    case 'number':
      return value.toFixed(2)
    case 'string':
      const parsed = parseFloat(value)
      return isNaN(parsed) ? parsed.toFixed(2) : value
    default:
      return null
  }
}
