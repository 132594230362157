export const stepper = (start: number, end: number, step: number) => {
  const length = (end - start) / step
  return Array.from({ length: length + 1 }).map(
    (_, i: number) => i * step + start
  )
}

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

export const actionKey = isMac ? 'metaKey' : 'ctrlKey'

export const isActionKeyPressed = (evt: KeyboardEvent | WheelEvent) => {
  return evt[actionKey]
}
