import React, { useState, useEffect } from 'react'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { isNumber as isNumeric, isEmpty } from 'lodash'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Spin from 'antd/lib/spin'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button'
import Switch from 'antd/lib/switch'
import Upload from 'antd/lib/upload'
import Select from 'antd/lib/select'
import Divider from 'antd/lib/divider'
import message from 'antd/lib/message'
import Tooltip from 'antd/lib/tooltip'
import Checkbox from 'antd/lib/checkbox'
import Typography from 'antd/lib/typography'
import InputNumber from 'antd/lib/input-number'
import { Category, MerchantSettings } from '@slerp/controls'
import { uuid } from 'uuidv4'
import { Store } from 'antd/lib/form/interface'
import { useSession } from '@slerp/accounts'
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
  LoadingOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { BsStars } from 'react-icons/bs'

import {
  GET_MERCHANT_SETTINGS,
  GET_STORE_IDS,
  GET_MERCHANT_CATEGORIES,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_STORE_VARIANTS,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  CREATE_CATEGORY,
  GET_PRODUCTS_MULTIPLE_CATEGORIES,
  CREATE_PRODUCTS_CATEGORIES_ENTRY,
  DELETE_PRODUCTS_CATEGORIES_ENTRY,
  PRODUCT_SLUG_CHANGE_LOG
} from '../ProductQueries'
import UploadProductImage, {
  UploadAdditionalImages
} from '../Actions/UploadProductImage'
import { requiredRule, caloriesFieldRule } from './rules'
import '../Products.css'
import { createCategory } from '../Actions'
import CategoryDropdown from '../Categories/CategoryDropdown'
import { computeGrossPrice, computeNetPrice } from 'components/Utils/price'
import generateRandomString from 'components/Utils/RandomStringGenerator'
import { slugify } from '@slerp/helpers'
import { getAvailabilities } from './utils'

import { Product } from '../'
import errorReducer from 'errors/errorReducer'
import Loading from 'components/Utils/Loading'

export interface CategorySpecialAvailabilities {
  category_id: string
  special_availability: {
    id: string
    end_time: string
    end_datetime: string
    rrule: string
    order_type: 'all' | 'sameday' | 'preorder'
    store_special_availabilities: {
      store: {
        id: string
      }
    }[]
  }
}
export interface ProductCategory {
  id: string
  product_id: string
  category_id: string
  category: {
    name: string
    category_special_availabilities?: CategorySpecialAvailabilities[]
  }
}

interface StoreId {
  id: string
}

interface ProductInfo extends Product {}

interface Props {
  discardCallback?: () => void
  successCallback?: () => void
  isSeoEnabled: boolean
  productInfo?: ProductInfo
  // Using fixed ids for product and variant because the id
  // is randomly generated upon triggering the product creation
  // and if I don't supply a fixed ID, it wont match any
  // of the provided mocks for product creation
  testProductId?: string
  testVariantId?: string
}

const { useForm } = Form
const { TextArea } = Input

export const ALLERGENS = {
  celery: 'Celery',
  cereals: 'Cereals containing gluten',
  crustaceans: 'Crustaceans',
  eggs: 'Eggs',
  fish: 'Fish',
  gluten: 'Gluten',
  lupin: 'Lupin',
  milk: 'Milk',
  molluscs: 'Molluscs',
  mustard: 'Mustard',
  nuts: 'Nuts',
  peanuts: 'Peanuts',
  sesame: 'Sesame',
  soybeans: 'Soybeans',
  sulphur: 'Sulphur dioxide and sulphites',
  treenuts: 'Tree nuts',
  wheat: 'Wheat'
}

const FULFILLMENT_TYPES = [
  { label: 'Pickup', value: 'pickup' },
  { label: 'Delivery', value: 'delivery' },
  { label: 'Table ordering', value: 'order_at_table' }
]

const ProductForm = (props: Props) => {
  const { merchant, user } = useSession()
  const client = useApolloClient()
  const [form] = useForm()

  const { discardCallback, successCallback, productInfo, isSeoEnabled } = props
  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )
  const [updateProduct, { loading: isLoadingUpdateProduct }] =
    useMutation(UPDATE_PRODUCT)
  const [createProduct, { loading: isLoadingCreateProduct }] =
    useMutation(CREATE_PRODUCT)
  const [createStoreVariants] = useMutation(CREATE_STORE_VARIANTS)
  const [createProductCategory, { loading: isLoadingCreateProductCategory }] =
    useMutation(CREATE_CATEGORY, {
      refetchQueries: ['getProductsCategoriesByCategoryIds']
    })
  const [createProductsCategories] = useMutation(
    CREATE_PRODUCTS_CATEGORIES_ENTRY
  )
  const [deleteProductsCategories] = useMutation(
    DELETE_PRODUCTS_CATEGORIES_ENTRY
  )
  const [logSlugChange] = useMutation(PRODUCT_SLUG_CHANGE_LOG, {
    fetchPolicy: 'no-cache'
  })

  const {
    data: productsCategoriesData,
    loading: isLoadingProductsCategoriesData
  } = useQuery(GET_PRODUCTS_MULTIPLE_CATEGORIES, {
    variables: {
      productId: productInfo?.id
    },
    skip: !productInfo?.id
  })

  const { data: categoriesData, loading: isLoadingCategoriesData } = useQuery(
    GET_MERCHANT_CATEGORIES,
    {
      variables: {
        merchantId: merchant.id
      }
    }
  )

  const { data: merchantStoresData } = useQuery(GET_STORE_IDS, {
    variables: {
      merchantSlug: merchant.slug
    }
  })

  const { data: merchantSettingsData } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: {
      merchantSlug: merchant.slug
    }
  })

  const hasCalories = isNumeric(
    productInfo?.default_variant?.length &&
      productInfo?.default_variant[0].calorie_data?.calories_per_serving
  )

  const initialValues = {
    name: productInfo?.name || '',
    sku: productInfo?.sku || `PROD_${generateRandomString(6)}`,
    source_id: productInfo?.source_id || '',
    description: productInfo?.description || '',
    seo_description: productInfo?.seo_description || '',
    total_price: computeGrossPrice(
      productInfo?.default_price || 0,
      productInfo?.default_vat || 0
    ),
    default_price: productInfo?.default_price || 0,
    default_vat: productInfo?.default_vat || 0,
    default_limit: productInfo?.default_limit || 0,
    alcoholic: productInfo?.alcoholic || false,
    published: isEmpty(productInfo) ? true : productInfo?.published,
    length: productInfo?.length || 0,
    width: productInfo?.width || 0,
    height: productInfo?.height || 0,
    weight: productInfo?.weight || 0,
    publish_to_stores: true,
    allergens: productInfo?.allergens || [],
    availabilities_by_fulfillment_type:
      productInfo?.availabilities && productInfo?.id
        ? productInfo?.availabilities
            .filter(({ is_available }) => is_available)
            .map(({ fulfillment_type }) => fulfillment_type)
        : FULFILLMENT_TYPES.map((ft) => ft.value) || [],
    calories: hasCalories
      ? (productInfo?.default_variant[0] || {}).calorie_data
          ?.calories_per_serving
      : null,
    product_category_ids: productsCategoriesData?.products_categories.map(
      (pc: ProductCategory) => pc.category_id
    )
  }

  const [stores, setStores] = useState<Array<StoreId>>([])
  const [merchantSettings, setMerchantSettings] = useState<MerchantSettings>()
  const [orderLimitSwitch, setOrderLimitSwitch] = useState<boolean>(
    initialValues.default_limit > 0
  )
  const [dimensionsSwitch, setDimensionsSwitch] = useState<boolean>(
    initialValues.length > 0 ||
      initialValues.width > 0 ||
      initialValues.height > 0 ||
      initialValues.weight > 0
  )
  const [grossPrice, setGrossPrice] = useState(initialValues.total_price)
  const [defaultVat, setDefaultVat] = useState<number>(
    initialValues.default_vat
  )
  const [images, setImages] = useState<File[]>([])
  const [uploadingImages, setUploadingImages] = useState<boolean>(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [remainingChars, setRemainingChars] = useState<number>(
    productInfo?.seo_description
      ? 160 - productInfo?.seo_description?.length
      : 160
  )
  const [openMetaModal, setOpenMetaModal] = useState<boolean>(false)

  useEffect(() => {
    if (merchantStoresData) {
      const { stores } = merchantStoresData
      setStores(stores)
    }
  }, [merchantStoresData])

  useEffect(() => {
    if (merchantSettingsData) {
      const { setting } = merchantSettingsData?.merchants[0]
      setMerchantSettings(setting)
    }
  }, [merchantSettingsData])

  useEffect(() => {
    if (categoriesData) {
      const { categories } = categoriesData
      setCategories(categories)
    }
  }, [categoriesData])

  const processCreateProduct = (values: Store) => {
    const {
      name,
      sku,
      description,
      seo_description,
      product_category_ids,
      default_price,
      default_vat,
      default_limit,
      length,
      height,
      width,
      weight,
      alcoholic,
      publish_to_stores,
      allergens,
      calories,
      availabilities_by_fulfillment_type,
      source_id
    } = values

    const productId = props.testProductId || uuid()
    const variantId = props.testVariantId || uuid()
    const calorieData = {
      id: variantId,
      calories_per_serving: calories,
      serving_unit: 'servings',
      amount_per_serving: 1
    }

    const availabilities = getAvailabilities(
      productId,
      [],
      availabilities_by_fulfillment_type
    )

    form.validateFields().then(() => {
      message.loading('Creating Product... Please Wait.')
      const variables = {
        slug: slugify(name),
        merchant_id: merchant.id,
        updated_by_id: user.id,
        id: productId,
        variant_id: variantId,
        name: name.trim(),
        sku: sku,
        source_id: source_id,
        description: description,
        seo_description: seo_description,
        image: null,
        default_vat: default_vat,
        price: default_price,
        default_limit: default_limit,
        height: height || 0,
        width: width || 0,
        weight: weight || 0,
        length: length || 0,
        publish_to_stores: publish_to_stores,
        restrictions: {
          id: productId,
          alcoholic: alcoholic
        },
        allergens,
        calorieData: isNumeric(calories) ? calorieData : null,
        availabilities: props.testProductId ? [] : availabilities,
        category_id: product_category_ids[0]
      }

      createProduct({
        variables
      })
        .then(async (result) => {
          message.destroy()
          message.success(`Product ${name} created!`, 3)

          // Terminating mutation here because I'm only testing up to here (for now)
          if (props.testProductId || props.testVariantId) return

          const productsCategories = categories
            .filter((cat: Category) =>
              product_category_ids.some((id: string) => id === cat.id)
            )
            .map((cat: Category) => ({
              id: uuid(),
              category_id: cat.id,
              product_id: productId,
              inserted_at: 'now()',
              updated_at: 'now()'
            }))

          const values = stores.map((data: { id: string }) => {
            return {
              id: uuid(),
              store_id: data.id,
              variant_id: variantId,
              inserted_at: 'now()',
              updated_at: 'now()',
              published_at: publish_to_stores ? 'now()' : null,
              in_stock: true
            }
          })

          const preOrderCopies = values.map((storeVariant) => {
            return {
              ...storeVariant,
              store_id: null,
              preorder_store_id: storeVariant.store_id,
              id: uuid()
            }
          })

          createStoreVariants({
            variables: {
              values: [...values, ...preOrderCopies]
            }
          })

          createProductsCategories({
            variables: {
              productCategories: productsCategories
            }
          }).catch((err) =>
            message.error(`Unable to create categories due to: ${err}`, 5)
          )

          if (merchantSettings) {
            const key =
              parseInt(
                Object.keys(merchantSettings.product_arrangement).pop()!
              ) + 1
            const updatedSettings = {
              ...merchantSettings,
              product_arrangement: {
                ...merchantSettings.product_arrangement,
                [key]: productId
              }
            }
            updateMerchantArrangementSettings({
              variables: {
                merchant: merchant.slug,
                arrangement: updatedSettings
              }
            })
          }

          //images
          if (!isEmpty(images)) {
            message.destroy()
            message.info('Uploading images...', 3)
            setUploadingImages(true)
            await UploadProductImage({
              variantId,
              image: images[0]
            }).then(async () => {
              const additionalImages = images.slice(1)
              if (!isEmpty(additionalImages)) {
                await UploadAdditionalImages({
                  variantId,
                  images: additionalImages
                })
              }
            })
            message.destroy()
            message.info('Images uploaded!', 3)
            setUploadingImages(false)
          }

          message.destroy()
          client.resetStore()
          successCallback && successCallback()
          message.success(`Product ${name} created!`, 3)
        })
        .catch((error: Error) => {
          throw errorReducer({
            origin: 'Product',
            data: {
              error: error,
              message: error.message
            }
          })
        })
        .catch((error) => {
          message.destroy()
          client.resetStore()
          message.error(error.message, 4)
          setUploadingImages(false)
        })
    })
  }

  const processUpdateProduct = (values: Store) => {
    const {
      sku,
      source_id,
      name,
      description,
      seo_description,
      default_price,
      default_vat,
      default_limit,
      length,
      height,
      width,
      weight,
      alcoholic,
      allergens,
      calories,
      availabilities_by_fulfillment_type,
      product_category_ids
    } = values

    const { products_categories }: { products_categories: ProductCategory[] } =
      productsCategoriesData

    const calorieData = {
      id: productInfo?.id || uuid(),
      calories_per_serving: calories,
      amount_per_serving: 1,
      serving_unit: 'servings'
    }

    const availabilities = getAvailabilities(
      productInfo.id,
      [...(productInfo?.availabilities || [])],
      availabilities_by_fulfillment_type
    )

    form.validateFields().then(() => {
      if (productInfo?.id) {
        const productCategoryToDelete = products_categories
          .filter((pc: ProductCategory) => {
            return !product_category_ids.some(
              (id: string) => id === pc.category_id
            )
          })
          .map((pc: ProductCategory) => ({
            id: { _eq: pc.id }
          }))

        const productsCategoriesToCreate = categories
          .filter((cat: Category) =>
            product_category_ids.some((id: string) => id === cat.id)
          )
          .filter(
            (cat: Category) =>
              !products_categories.some(
                (pc: ProductCategory) => pc.category_id === cat.id
              )
          )
          .map((cat: Category) => ({
            id: uuid(),
            category_id: cat.id,
            product_id: productInfo?.id,
            inserted_at: 'now()',
            updated_at: 'now()'
          }))

        message.loading('Updating Product... Please Wait.')
        updateProduct({
          variables: {
            id: productInfo.id,
            variant_id: productInfo.default_variant_id,
            name: name.trim(),
            slug: slugify(name.trim()),
            description: description,
            seo_description: seo_description,
            default_vat: default_vat,
            updated_by_id: user.id,
            price: default_price,
            default_limit: default_limit || 0,
            height: height || 0,
            width: width || 0,
            weight: weight || 0,
            length: length || 0,
            publish_to_stores: productInfo.publish_to_stores,
            sku: sku,
            source_id: source_id,
            restrictions: {
              id: productInfo.id,
              alcoholic: alcoholic
            },
            allergens,
            calorieData: isNumeric(calories) ? calorieData : null,
            availabilities: props.testProductId ? [] : availabilities,
            category_id: product_category_ids[0]
          }
        })
          .then((res) => {
            message.destroy()
            client.resetStore()
            if (productCategoryToDelete.length) {
              deleteProductsCategories({
                variables: {
                  productCategoryIds: productCategoryToDelete
                }
              }).catch((err) =>
                message.error(`Unable to delete categories due to: ${err}`, 5)
              )
            }

            if (productsCategoriesToCreate.length) {
              createProductsCategories({
                variables: {
                  productCategories: productsCategoriesToCreate
                }
              }).catch((err) =>
                message.error(`Unable to create categories due to: ${err}`, 5)
              )
            }

            if (!!initialValues.name && name !== initialValues.name) {
              logSlugChange({
                variables: {
                  productId: productInfo.id,
                  oldSlug: slugify(initialValues.name),
                  newSlug: slugify(name.trim())
                }
              })
            }

            message.success(`Product ${name} Updated!`, 3)
            successCallback && successCallback()
          })
          .catch((error: Error) => {
            throw errorReducer({
              origin: 'Product',
              data: {
                error: error,
                message: error.message
              }
            })
          })
          .catch((error) => {
            message.destroy()
            client.resetStore()
            message.error(error.message, 4)
          })
      }
    })
  }

  const onFinish = (values: Store) => {
    if (productInfo?.id) {
      processUpdateProduct(values)
    } else {
      processCreateProduct(values)
    }
  }

  const errorHandler = (err: Error) => {
    return message.error(`Unable to create category due to ${err}`, 5)
  }

  const handleCreateCategory = (searchValue: string) => {
    if (merchantSettings) {
      return createCategory(
        merchant.slug,
        searchValue,
        '',
        merchantSettings,
        updateMerchantArrangementSettings,
        createProductCategory,
        errorHandler
      )
        .then((res) => {
          const { id, name, slug } = res?.data?.createCategory
          message.destroy()
          message.success(`${searchValue} category has been created`, 3)
          setCategories((prev) => [...prev, { id, name, slug }])

          const current_category_ids =
            form.getFieldValue('product_category_ids') ?? []

          form.setFieldsValue({
            product_category_ids: [...current_category_ids, id]
          })
        })
        .catch((err) => {
          message.destroy()
          message.error(
            `Failed to create ${searchValue} category due to ${err}`,
            3
          )
        })
    }
  }

  const removeImage = (index: number) =>
    setImages([
      ...images.slice(0, index),
      ...images.slice(index + 1, images.length)
    ])

  const replaceImage = (image: File, index: number) =>
    setImages([
      ...images.slice(0, index),
      image,
      ...images.slice(index + 1, images.length)
    ])

  useEffect(() => {
    form.setFieldsValue({
      default_price: computeNetPrice(grossPrice, defaultVat)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grossPrice, defaultVat])

  if (isLoadingCategoriesData || isLoadingProductsCategoriesData)
    return <Loading />

  return (
    <>
      <Modal
        closable={false}
        visible={openMetaModal}
        centered
        className='meta-description-container'
        footer={[
          <Button type='primary' onClick={() => setOpenMetaModal(false)}>
            DONE
          </Button>
        ]}
      >
        <h2>Why are SEO Descriptions important?</h2>
        <p>
          SEO descriptions are used to generate a summary customers see when
          using a search engine. By following these best practices, you can
          create descriptions that boost visibility, increase clicks, and drive
          more relevant traffic to your site.
        </p>
        <p>A good meta description should:</p>
        <ol>
          <li>
            <b>Be relevant to the page</b>: Ensure the meta description
            accurately reflects the content on the page.
          </li>
          <br />
          <li>
            <b>Be concise</b>: Keep descriptions between 150-160 characters. Any
            longer, and they may be truncated, leaving key information out.
          </li>
          <br />
          <li>
            <b>Use relevant keywords</b>: Search engines may bold these terms in
            results, making your snippet stand out.
          </li>
          <br />
          <li>
            <b>Include a Call to Action (CTA)</b>: Encourage customers to take
            specific actions, such as "Order Now". A CTA can increase engagement
            and click-through rates.
          </li>
        </ol>
      </Modal>
      <Row>
        <Col span={23}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            data-testid='product-form'
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Product name:</Col>
                </Row>
                <Row data-testid='product-form-container-name'>
                  <Col span={24}>
                    <Form.Item name='name' rules={requiredRule}>
                      <Input
                        type='text'
                        autoFocus
                        placeholder='Name'
                        data-testid='product-form-name'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Row>
                  <Col>
                    SKU:
                    <Tooltip
                      title={
                        <span>
                          This is not compulsory. A Stock Keeping Unit, is a
                          unique number used to internally track your product
                          inventory and used for production and logistics.
                          <br />
                          <br />
                          Find our more about our order management tool{' '}
                          <a
                            href='https://www.slerp.com/order-management/'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      name='sku'
                      rules={[
                        {
                          validator: (_: any, val: string) => {
                            if (val.includes('?')) {
                              return Promise.reject(
                                'Question marks are not allowed'
                              )
                            }
                            return Promise.resolve()
                          }
                        }
                      ]}
                    >
                      <Input
                        type='text'
                        autoFocus
                        placeholder='SKU'
                        disabled={
                          !!productInfo?.id && !!productInfo?.archived_at
                        }
                        data-testid='product-form-sku'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    PLU:
                    <Tooltip
                      title={
                        <span>
                          POS ID
                          <br />
                          This is the identifier we use to connect this product
                          to the POS.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8 _mr-8' />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item name='source_id'>
                      <Input
                        type='text'
                        autoFocus
                        placeholder='PLU'
                        data-testid='product-form-source-id'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>
                    Product description:
                    <Tooltip
                      title={
                        <span>
                          Be sure to include keywords related to products such
                          as 'chocolate' for the{' '}
                          <strong>customer search function</strong> feature.
                          <br />
                          <br />
                          <strong>This can be enabled in Designer</strong>. Find
                          out more{' '}
                          <a
                            href='https://support.slerp.com/knowledge/product-descriptions-html'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                <Row data-testid='product-form-container-description'>
                  <Col span={24}>
                    <Form.Item name='description'>
                      <TextArea
                        autoFocus
                        rows={4}
                        placeholder='Description'
                        data-testid='product-form-description'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            {isSeoEnabled && (
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Row>
                    <Col>SEO description:</Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name='seo_description'>
                        <TextArea
                          rows={3}
                          placeholder='Enter SEO description'
                          data-testid='product-form-meta-description'
                          maxLength={160}
                          onChange={(e) =>
                            setRemainingChars(160 - e.target.value.length)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      fontSize: '11px',
                      position: 'absolute',
                      bottom: '40px',
                      right: 0
                    }}
                  >
                    Remaining characters {remainingChars}
                  </Row>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        style={{
                          color: '#77778E',
                          background: '#FAFAFB',
                          borderStyle: 'none'
                        }}
                        onClick={() => setOpenMetaModal(true)}
                      >
                        LEARN MORE
                      </Button>
                    </Col>
                    <Col>
                      {/* <Button type='ghost'>
                        <BsStars style={{ marginRight: '8px' }} />
                        GENERATE
                      </Button> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Category:</Col>
                </Row>
                <Row data-testid='product-form-container-category'>
                  <Col span={24}>
                    <CategoryDropdown
                      categories={categories}
                      categoryLoading={
                        isLoadingProductsCategoriesData ||
                        isLoadingCategoriesData ||
                        isLoadingCreateProductCategory ||
                        isLoadingCreateProduct ||
                        isLoadingUpdateProduct
                      }
                      handleCreateCategory={handleCreateCategory}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>Product availability:</Col>
                </Row>
                <Row data-testid='product-form-container-product-availability'>
                  <Col span={24}>
                    <Form.Item name='availabilities_by_fulfillment_type'>
                      <Checkbox.Group
                        options={FULFILLMENT_TYPES}
                        className='-vertical'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {isEmpty(productInfo?.id) && (
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Row>
                    <Col>
                      Image:
                      <Tooltip
                        title={
                          <span>
                            Keep a clear and consistent look and feel across
                            your photography to increase customer engagement and
                            sales. Learn more{' '}
                            <a
                              href='https://support.slerp.com/en_gb/categories/products-H1ZdJ7G6u'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              here
                            </a>
                            .
                          </span>
                        }
                      >
                        <QuestionCircleOutlined className='_ml-8 _mr-8' />
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row gutter={12} data-testid='product-form-images'>
                    {images.map((image, index) => (
                      <Col span={6}>
                        <Spin
                          spinning={uploadingImages}
                          size='large'
                          indicator={<LoadingOutlined spin />}
                        >
                          <div className='image-preview-container'>
                            <div className='overlay'>
                              <Upload
                                name='avatar'
                                accept='.png, .jpg, .jpeg'
                                showUploadList={false}
                                beforeUpload={(file: File) =>
                                  replaceImage(file, index)
                                }
                              >
                                <Button
                                  icon={<UploadOutlined />}
                                  type='text'
                                  size='large'
                                  className='image-preview-action'
                                />
                              </Upload>
                              <Button
                                icon={<DeleteOutlined />}
                                type='text'
                                size='large'
                                className='image-preview-action'
                                onClick={() => removeImage(index)}
                              />
                            </div>
                            <img
                              className='preview'
                              src={URL.createObjectURL(image)}
                              alt='product preview'
                            />
                          </div>
                        </Spin>
                      </Col>
                    ))}
                    {images.length < 4 && (
                      <Col span={images.length > 0 ? 6 : 24}>
                        <Upload
                          name='avatar'
                          accept='.png, .jpg, .jpeg'
                          listType='picture-card'
                          className={`avatar-uploader ${
                            images.length === 0 ? '-large' : '-square'
                          }`}
                          multiple
                          showUploadList={false}
                          beforeUpload={(_file, files: File[]) => {
                            const filesToUpload = files.slice(
                              0,
                              4 - images.length
                            )
                            setImages([...images, ...filesToUpload])
                          }}
                        >
                          <PlusOutlined className='icon' />
                          <Typography.Text className='title'>
                            {images.length < 3
                              ? `Upload up to ${4 - images.length} photos`
                              : 'Upload'}
                          </Typography.Text>
                          <Typography.Text className='subheading'>
                            Maximum 5MB, 1024 x 1024 pixels
                          </Typography.Text>
                        </Upload>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            )}

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>Price</Col>
                  <Col span={8}>
                    VAT
                    <Tooltip
                      title={
                        <span>
                          When listing a product price include VAT and set the
                          relevant{' '}
                          <a
                            href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            VAT band
                          </a>{' '}
                          for off-premise consumption (
                          <a
                            href='https://www.gov.uk/guidance/food-products-and-vat-notice-70114'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Gov UK Food Products VAT Notice
                          </a>
                          ).
                          <br />
                          <br />
                          Our system then surfaces net and gross price VAT in
                          your statements for your reporting. Find out more.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8 _mr-8' />
                    </Tooltip>
                  </Col>
                  <Col span={8}>Price excluding VAT</Col>
                </Row>
                <Row gutter={[8, 8]}>
                  <Col
                    span={8}
                    data-testid='product-form-container-total-price'
                  >
                    <Form.Item
                      name='total_price'
                      className='mb-0'
                      rules={requiredRule}
                    >
                      <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: '100%' }}
                        data-testid='product-form-total-price'
                        onChange={(val) => isNumeric(val) && setGrossPrice(val)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} data-testid='product-form-container-vat'>
                    <Form.Item
                      name='default_vat'
                      className='mb-0'
                      rules={requiredRule}
                    >
                      <InputNumber
                        min={0}
                        className='width-100'
                        data-testid='product-form-vat'
                        onChange={(val: string | number | undefined) => {
                          if (isNumeric(val)) setDefaultVat(val)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name='default_price' rules={requiredRule}>
                      <InputNumber
                        disabled
                        precision={2}
                        min={0}
                        className='width-100'
                        data-testid='product-form-price'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Row>
                  <Col>Calories (kcal)</Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      name='calories'
                      className='mb-0'
                      rules={caloriesFieldRule}
                    >
                      <InputNumber
                        min={0}
                        data-testid='product-form-calories'
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Row>
                  <Col>
                    Allergens:
                    <Tooltip
                      title={
                        <span>
                          In line with Natasha's law, it's mandatory to list
                          allergen information at the time of purchase.
                          <br />
                          <br />
                          Find out more{' '}
                          <a
                            href='https://support.slerp.com/knowledge/allergen-tagging-and-listing'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            here
                          </a>
                          .
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name='allergens'>
                      <Select
                        mode='multiple'
                        style={{ width: '100%' }}
                        placeholder='Select allergen(s)'
                        data-testid='product-form-allergens'
                      >
                        {Object.keys(ALLERGENS).map((key) => (
                          <Select.Option
                            key={key}
                            value={key}
                            data-testid={key}
                          >
                            {ALLERGENS[key]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_pl-2'>
              <Col span={24}>
                <Row align='middle' gutter={[8, 8]}>
                  <Col span={24}>
                    <Switch
                      title='Order limit'
                      data-testid='product-form-order-limit-toggle'
                      onChange={() => setOrderLimitSwitch(!orderLimitSwitch)}
                      checked={orderLimitSwitch}
                    />
                    <span className='pl-half-rem'>Order limit</span>
                    <Tooltip
                      title={
                        <span>
                          Limits the number of items that can be purchased by a
                          customer for a single product.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                {orderLimitSwitch && (
                  <Row data-testid='product-form-container-default-limit'>
                    <Col span={24}>
                      <Form.Item name='default_limit' rules={requiredRule}>
                        <InputNumber
                          min={0}
                          precision={0}
                          data-testid='product-form-default-limit'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_pl-2'>
              <Col span={24}>
                <Row align='middle' gutter={[8, 8]}>
                  <Col span={24}>
                    <Switch
                      title='Dimensions'
                      onChange={() => setDimensionsSwitch(!dimensionsSwitch)}
                      checked={dimensionsSwitch}
                      data-testid='product-form-dimensions-toggle'
                    />
                    <span className='pl-half-rem'>Dimensions</span>
                    <Tooltip
                      title={
                        <span>
                          The packaging size for your product used for courier
                          logistics.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
                {dimensionsSwitch && (
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Row>
                        <Col span={24}>
                          <Row>Length (cm)</Row>
                          <Row data-testid='product-form-container-length'>
                            <Col span={24}>
                              <Form.Item name='length' rules={requiredRule}>
                                <InputNumber
                                  className='width-100'
                                  min={0}
                                  data-testid='product-form-length'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row>Width (cm)</Row>
                          <Row data-testid='product-form-container-width'>
                            <Col span={24}>
                              <Form.Item name='width' rules={requiredRule}>
                                <InputNumber
                                  className='width-100'
                                  min={0}
                                  data-testid='product-form-width'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={24}>
                          <Row>Height (cm)</Row>
                          <Row data-testid='product-form-container-height'>
                            <Col span={24}>
                              <Form.Item name='height' rules={requiredRule}>
                                <InputNumber
                                  className='width-100'
                                  min={0}
                                  data-testid='product-form-height'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Row>Weight (kg)</Row>
                          <Row data-testid='product-form-container-weight'>
                            <Col span={24}>
                              <Form.Item name='weight' rules={requiredRule}>
                                <InputNumber
                                  className='width-100'
                                  min={0}
                                  data-testid='product-form-weight'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='_pl-2'>
              <Col span={24}>
                <Row align='middle'>
                  <Form.Item name='alcoholic' className='mb-0'>
                    <Switch
                      title='Alcoholic'
                      defaultChecked={initialValues.alcoholic}
                      data-testid='product-form-alcoholic'
                    />
                  </Form.Item>
                  <Col>
                    <span className='pl-half-rem'>Alcoholic</span>
                    <Tooltip
                      title={
                        <span>
                          This will trigger an over 18s consent prompt for this
                          product at checkout.
                        </span>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
            {isEmpty(productInfo?.id) && (
              <Row gutter={[8, 8]} className='_pl-2'>
                <Col span={24}>
                  <Row align='middle'>
                    <Col span={2}>
                      <Form.Item name='publish_to_stores' className='mb-0'>
                        <Switch
                          title='Make live to all locations'
                          defaultChecked={initialValues.publish_to_stores}
                          data-testid='product-form-published'
                        />
                      </Form.Item>
                    </Col>
                    <Tooltip title='Enabling this feature will make this product live in all your stores, ready for customers to purchase'>
                      <span className='pl-half-rem'>
                        Add product to all inventories and mark it as in stock
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        <a
                          href='https://support.slerp.com/knowledge/publishing-products'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Publishing Products
                        </a>
                      }
                    >
                      <QuestionCircleOutlined className='_ml-8' />
                    </Tooltip>
                    <Col></Col>
                  </Row>
                </Col>
              </Row>
            )}

            <Divider />

            <Row gutter={16}>
              <Col>
                <Button
                  data-testid='product-form-discard'
                  onClick={() => {
                    discardCallback && discardCallback()
                  }}
                  type='ghost'
                  disabled={uploadingImages}
                >
                  Discard
                </Button>
              </Col>
              <Col>
                <Tooltip
                  placement='topLeft'
                  title={
                    productInfo?.id ? null : (
                      <span>
                        Once a product is created you can then add variants,
                        such as size or flavour; and modifiers, such as sides or
                        drink pairings.
                      </span>
                    )
                  }
                  style={{
                    display: `${productInfo?.id ? 'hidden' : 'visible'}`
                  }}
                >
                  <Button
                    type='primary'
                    htmlType='submit'
                    data-testid='product-form-add-product'
                    disabled={uploadingImages}
                  >
                    {productInfo?.id ? 'Update product' : 'Add product'}
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ProductForm
