import React from 'react'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Switch from 'antd/lib/switch'

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import CutoffTimeFields from './CutoffFields'
import moment from 'moment'
import { uuid } from 'uuidv4'
import format from 'date-fns/format'
import includes from 'lodash/includes'
import omit from 'lodash/omit'

type Cutoff = {
  id: string
  enabled: boolean
  time: string | null
  day_diff: number | null
  cutoff_date: string | null
}

const SpecialCutoffTimesForm = ({
  cutoffs,
  setCutoffs,
  disabled = false
}: {
  cutoffs: { [key: string]: Cutoff }
  setCutoffs: (cutoffs: { [key: string]: Cutoff }) => void
  disabled?: boolean
}) => {
  const usedDates = Object.keys(cutoffs).map(
    (key) => cutoffs[key].cutoff_date || ''
  )
  const removeCutoff = (id: string) => setCutoffs(omit(cutoffs, id))

  const updateCutoff = (
    id: string,
    key: string,
    value: string | boolean | number
  ) => setCutoffs({ ...cutoffs, [id]: { ...cutoffs[id], [key]: value } })

  return (
    <>
      <AddButton
        disabled={disabled}
        onClick={() => {
          const id = uuid()
          setCutoffs({
            ...cutoffs,
            [id]: {
              id,
              enabled: true,
              time: '00:00:00',
              day_diff: 1,
              cutoff_date: ''
            }
          })
        }}
      />
      {Object.keys(cutoffs).map((key) => {
        const cutoff = cutoffs[key]
        return (
          <SpecialCutoffTime
            key={`special-cutoff-${cutoff.id}`}
            handleDelete={() => removeCutoff(cutoff.id)}
            usedDates={usedDates}
            updateCutoff={(key, value) => updateCutoff(cutoff.id, key, value)}
            cutoff={cutoff}
            disabled={disabled}
          />
        )
      })}
    </>
  )
}

const AddButton = ({
  onClick,
  disabled = false
}: {
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={onClick}
        disabled={disabled}
        className='_mb-8'
      >
        Add Special cut-off times
      </Button>
    </>
  )
}

const SpecialCutoffTime = ({
  cutoff,
  handleDelete,
  usedDates,
  updateCutoff,
  disabled = false
}: {
  cutoff: Cutoff
  handleDelete: () => void
  usedDates: string[]
  updateCutoff: (key: string, value: boolean | number | string) => void
  disabled?: boolean
}) => {
  return (
    <Form.Item labelAlign='left'>
      <span>Allow ordering</span>
      <Row align='middle' className='_mt-8 _mb-0'>
        <Col span={12}>
          <Row gutter={8} className='_mb-0' align='middle'>
            <Col>
              <Switch
                onChange={(checked) => updateCutoff('enabled', checked)}
                defaultChecked={cutoff.enabled}
                disabled={disabled}
              />
            </Col>
            <Col>
              <DatePicker
                autoFocus={!cutoff.cutoff_date}
                defaultValue={
                  cutoff.cutoff_date ? moment(cutoff.cutoff_date) : undefined
                }
                disabledDate={(date) =>
                  date.isBefore(moment()) ||
                  includes(usedDates, format(date.toDate(), 'yyyy-MM-dd'))
                }
                onChange={(date) =>
                  date &&
                  updateCutoff(
                    'cutoff_date',
                    format(date.toDate(), 'yyyy-MM-dd')
                  )
                }
                allowClear={false}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={8} className='_mb-0' align='middle' justify='end'>
            <Col>
              <CutoffTimeFields
                disabled={!cutoff.enabled}
                dayDiff={cutoff.day_diff}
                time={cutoff.time}
                updateCutoff={updateCutoff}
              />
            </Col>
            <Col className='_center-vertical'>
              <CloseCircleOutlined
                className='_text-font-size-18 _cl-primary'
                onClick={handleDelete}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Item>
  )
}

export default SpecialCutoffTimesForm
