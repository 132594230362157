const required = { required: true, message: 'This is a required field.' }
const requiredRule = [required]

type CustomRuleType = { getFieldValue: (arg: string) => string }

const refundableValueRule = [
  required,
  ({ getFieldValue }: CustomRuleType) => ({
    async validator(_: any, value: string) {
      const refundableAmount = getFieldValue('refundable_amount') || ''
      if (refundableAmount >= value) {
        return Promise.resolve()
      } else {
        return Promise.reject(
          `Value exceeds the allowable refundable amount of ${refundableAmount}`
        )
      }
    }
  })
]

export { requiredRule, refundableValueRule }
