import React from 'react'
import styled from '@emotion/styled'
import { Text } from '../Text'
import { FaQuestionCircle, IconContext } from '../Icon'
import { useTheme } from '../../contexts/Theme'
import { Tooltip } from '../Tooltip'

interface FormGroupTitleProps {
  tooltipHeading?: string
  tooltipBody?: string
}

const FormGroupTitle: React.FC<FormGroupTitleProps> = ({
  tooltipHeading,
  tooltipBody,
  children
}) => {
  const theme = useTheme() as any

  return (
    <Container>
      <Text size='md' color='text' weight='400'>
        {children}
      </Text>
      {Boolean(tooltipBody) && (
        <Tooltip
          heading={tooltipHeading}
          text={tooltipBody as string}
          width={240}
        >
          <TooltipContainer>
            <IconContext.Provider value={{ color: theme.colors.gray400 }}>
              <FaQuestionCircle />
            </IconContext.Provider>
          </TooltipContainer>
        </Tooltip>
      )}
    </Container>
  )
}

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const TooltipContainer = styled.div<{ width?: number }>`
  margin-left: 8px;
  display: flex;
  align-items: center;
`

export { FormGroupTitle }
