import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_PRODUCTS } from './Actions'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import { Checkbox, Col, Collapse, Form, Row, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS } from 'components/ProductsAndModifiers/Products/ProductQueries'
import { ProductCategory } from 'components/ProductsAndModifiers/Products'
import _ from 'lodash'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface CategorizedProducts {
  id: string // category id
  name: string // category name
  items: ProductWithCategories[]
}

interface Category {
  id: string
  name: string
}

interface ProductWithCategories extends Product {
  categories?: {
    name: string
    id: string
  }[]
}

const Products = ({
  merchantId,
  form
}: {
  merchantId: string
  form: FormInstance
}) => {
  const [categoryIds, setCategoryIds] = useState([])
  const [categorizedProducts, setCategorizedProducts] = useState<
    CategorizedProducts[]
  >([])
  const [selectedProducts, setSelectedProducts] = useState<
    Record<string, string[]>
  >({})

  const { data: productsData, loading } = useQuery(GET_PRODUCTS, {
    variables: { merchantId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { categories } = data

      const ids = categories?.map((category: Category) => ({
        category_id: { _eq: category.id }
      }))

      setCategoryIds(ids)
    }
  })

  const { loading: isLoadingProductsCategories } = useQuery(
    GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS,
    {
      variables: {
        categoryIds: categoryIds
      },
      skip: categoryIds.length < 1 || loading || !productsData,
      onCompleted: (data) => {
        const { products_categories } = data

        const productCategories = productsData.products.map(
          (product: Product) => {
            const belongedCategories = products_categories
              .filter((pc: ProductCategory) => product.value === pc.product_id)
              .map((pc: ProductCategory) => ({
                id: pc.category_id,
                name: pc.category?.name
              }))

            return {
              ...product,
              categories: belongedCategories
            }
          }
        )

        const completeProductsCategories: ProductWithCategories[] = flatten(
          productCategories.map((product_category: ProductWithCategories) => {
            return product_category.categories?.map((category) => ({
              ...product_category,
              category_id: category.id
            }))
          })
        )

        const involvedCategories = completeProductsCategories.filter((pc) => {
          const initialValues: Record<string, string[]> =
            form.getFieldValue('products')
          const productIds = _.flatten(Object.values(initialValues))

          return productIds.some((id: string) => id === pc.value)
        })

        const groupedProducts = groupBy(
          involvedCategories,
          (item) => item.category_id
        )

        const updatedValues = Object.entries(groupedProducts).reduce(
          (acc, [key, value]) => {
            return {
              ...acc,
              [key]: value.map((item) => item.value)
            }
          },
          {}
        )
        setSelectedProducts(updatedValues)

        formatProductCategory(
          completeProductsCategories,
          productsData.categories
        )
      }
    }
  )

  const formatProductCategory = (
    categorizedProducts: ProductWithCategories[],
    categories: Category[]
  ) => {
    const groupedProducts = groupBy(
      categorizedProducts,
      (item) => item.category_id
    )

    const categorized = _.toPairs(groupedProducts).map(
      ([categoryId, products]) => {
        const category = categories.find(
          (category) => category.id === categoryId
        ) || {
          id: '',
          name: ''
        }

        return {
          id: category.id,
          name: category.name,
          items: products.map((product: Product) => ({
            ...product,
            label: product.label,
            value: product.value,
            id: product.value,
            category_id: product.category_id
          }))
        }
      }
    )

    setCategorizedProducts(categorized)
  }

  useEffect(() => {
    form.setFieldsValue({
      products: selectedProducts
    })
  }, [selectedProducts])

  const handleToggleSelect = useCallback(
    (e: CheckboxChangeEvent, item: ProductWithCategories) => {
      if (e.target.checked) {
        setSelectedProducts((prev) => {
          const appendedCategories = item.categories?.reduce(
            (acc: Record<string, string[]>, category: Category) => {
              if (prev.hasOwnProperty(category.id)) {
                return {
                  ...acc,
                  [category.id]: [...prev[category.id], e.target.id!]
                }
              } else {
                return {
                  ...acc,
                  [category.id]: [e.target.id!]
                }
              }
            },
            {}
          )

          return { ...prev, ...appendedCategories }
        })
      } else {
        setSelectedProducts((prev) => {
          return Object.entries(prev).reduce(
            (
              acc: Record<string, string[]>,
              [key, value]: [string, string[]]
            ) => {
              const values = value.filter((id: string) => id !== e.target.id)

              return {
                ...acc,
                [key]: values
              }
            },
            {}
          )
        })
      }
    },
    [selectedProducts]
  )

  const handleToggleSelectAll = useCallback(
    (e: CheckboxChangeEvent, category: CategorizedProducts) => {
      if (e.target.checked) {
        setSelectedProducts((prev) => {
          return {
            ...prev,
            [category.id]: category.items.map((item) => item.value)
          }
        })
      } else {
        setSelectedProducts((prev) => {
          return {
            ...prev,
            [category.id]: []
          }
        })
      }
    },
    [selectedProducts]
  )

  return (
    <Form.Item
      data-testid='discount-form-products'
      label='Products'
      name='products'
      style={{ flexFlow: 'column', marginBottom: '36px' }}
      required
      rules={[
        () => ({
          validator(_, value) {
            if (
              value &&
              typeof value === 'object' &&
              flatten(Object.values(value)).length > 0
            )
              return Promise.resolve()
            return Promise.reject(
              new Error('Please select at least one product')
            )
          }
        })
      ]}
    >
      {loading || isLoadingProductsCategories ? (
        <span>
          <Spin size='small' style={{ marginRight: '16px' }} />
          Loading products...
        </span>
      ) : !productsData ? (
        <p>No products found</p>
      ) : (
        <CategorizedProducts
          categorizedProducts={categorizedProducts}
          form={form}
          selectedProducts={selectedProducts}
          handleToggleSelect={handleToggleSelect}
          handleToggleSelectAll={handleToggleSelectAll}
        />
      )}
    </Form.Item>
  )
}

type Product = {
  value: string
  label: string
  category_id: string
}

const CategorizedProducts = ({
  categorizedProducts,
  selectedProducts,
  handleToggleSelect,
  handleToggleSelectAll
}: {
  categorizedProducts: CategorizedProducts[]
  form: FormInstance
  selectedProducts: Record<string, string[]>
  handleToggleSelect: (
    e: CheckboxChangeEvent,
    item: ProductWithCategories
  ) => void
  handleToggleSelectAll: (
    e: CheckboxChangeEvent,
    category: CategorizedProducts
  ) => void
}) => {
  const getActivePanels = () => {
    const withSelectedProducts = Object.entries(selectedProducts).map(
      ([key, value]: [string, string[]]) => {
        return value.length > 0 ? key : ''
      }
    )

    return withSelectedProducts
  }

  const isChecked = (productId: string) => {
    return _.flatten(Object.values(selectedProducts)).some(
      (id) => id === productId
    )
  }

  const hasAllSelected = useCallback(
    (category: CategorizedProducts) => {
      return category.items.every((item) => {
        const productIds = _.flatten(Object.values(selectedProducts)) || []

        return productIds.includes(item.value)
      })
    },
    [selectedProducts]
  )

  return (
    <Collapse style={{ width: '100%' }} defaultActiveKey={getActivePanels()}>
      {categorizedProducts.map((category) => {
        return (
          <Collapse.Panel
            header={category.name}
            key={category.id}
            extra={
              <span onClick={(e) => e.stopPropagation()}>
                <label htmlFor={`select-all-${category.id}-${category.name}`}>
                  Select All
                </label>
                <Checkbox
                  id={`select-all-${category.id}-${category.name}`}
                  style={{ marginLeft: 8 }}
                  checked={hasAllSelected(category)}
                  data-testid={category.name}
                  onChange={(e) => handleToggleSelectAll(e, category)}
                />
              </span>
            }
          >
            <div style={{ padding: '16px 16px 0 16px' }}>
              {category.items.map((item) => {
                return (
                  <Row justify='space-between' className='_mb-16'>
                    <Col span={23}>
                      <label htmlFor={`${category.id}-${item.value}`}>
                        {item.label}
                      </label>
                    </Col>
                    <Col span={1}>
                      <Checkbox
                        id={item.value}
                        checked={isChecked(item.value)}
                        onChange={(e) => handleToggleSelect(e, item)}
                      />
                    </Col>
                  </Row>
                )
              })}
            </div>
          </Collapse.Panel>
        )
      })}
    </Collapse>
  )
}

export default Products
