import { useQuery } from '@apollo/client'
import { UseGetDiscountsProps } from './types'
import { GET_DISCOUNTS } from './queries'

const useGetDiscounts = ({ merchantId }: UseGetDiscountsProps) => {
  const { data, loading, error, refetch } = useQuery(GET_DISCOUNTS, {
    variables: {
      merchantId
    }
  })

  return [data?.discounts || [], { loading, refetch, error }]
}

export default useGetDiscounts
