import React from 'react'
import { Calendar, PageHeader } from 'antd'

const OrdersCalendar = () => {
  return (
    <>
      <PageHeader
        title='Pre-Order Calendar'
        subTitle='Find out what your orders are'
      />
      <Calendar />
    </>
  )
}

export default OrdersCalendar
