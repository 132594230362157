import React from 'react'
import { Typography } from 'antd'
import { Delivery } from 'components/Orders/Info/type'

interface DeliveryHeaderProps {
  delivery: Delivery
}

const { Text } = Typography

const DeliveryHeader = ({ delivery }: DeliveryHeaderProps) => {
  const { job_id } = delivery || {}
  return (
    <>
      <span className='_mr-2 _cl-text-secondary-75'>Job ID:</span>
      <Text strong>{job_id || '-'}</Text>
    </>
  )
}

export default DeliveryHeader
