import React from 'react'
import { Button, Col, Row, Form, Input, Select, Tooltip } from 'antd'
import { useMutation } from '@apollo/client'
import { Store as FormStore } from 'antd/lib/form/interface'
import { ADD_VARIANT_GROUP } from '../VariantQueries'
import { variantGroupNameValidator, requiredRule } from './rules'
import { Option } from '../types'

interface VariantGroupFormProps {
  productId: string
  onStartSave: () => void
  onEndSave: () => void
  onSaveFail: (error: Error) => void
  productOptions: Option[]
}

const { useForm } = Form
const VARIANT_GROUP_LIMIT = 2

const VariantGroupForm = ({
  productId,
  onStartSave,
  onEndSave,
  onSaveFail,
  productOptions
}: VariantGroupFormProps) => {
  const [form] = useForm()
  const testIdKey = 'product-variant-groups-form'
  const initialValues = {
    name: '',
    options: []
  }
  const [addVariantGroup, { loading: creatingVariantGroup }] =
    useMutation(ADD_VARIANT_GROUP)
  const saveOptions = (values: FormStore) => {
    if (!form.validateFields()) return
    const { name, options } = values

    onStartSave && onStartSave()
    addVariantGroup({
      variables: {
        optionName: name,
        optionValues: options,
        productId
      }
    })
      .then((result) => onEndSave && onEndSave())
      .catch((error: Error) => onSaveFail && onSaveFail(error))
  }
  const productOptionNames = (productOptions || []).map(
    ({ name }: Option) => name
  )
  const disableGenerateVariantGroupCreation =
    (productOptions || []).length >= VARIANT_GROUP_LIMIT

  return (
    <Form
      form={form}
      data-testid={`${testIdKey}`}
      initialValues={initialValues}
      onFinish={saveOptions}
    >
      <Row gutter={[8, 0]}>
        <Col span={7}>
          <Form.Item
            name='name'
            rules={variantGroupNameValidator(productOptionNames)}
          >
            <Input
              disabled={creatingVariantGroup}
              placeholder='Variant group name e.g Size'
              data-testid={`${testIdKey}-name`}
            />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item name='options' className='_w-100' rules={requiredRule}>
            <Select
              className='no-transform'
              mode='tags'
              disabled={creatingVariantGroup}
              placeholder='Variant values e.g Small, Medium, Large'
              data-testid={`${testIdKey}-options`}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col>
          <Tooltip
            title={
              disableGenerateVariantGroupCreation
                ? 'You have reached the maximum number of variant groups that can be created'
                : null
            }
            placement='right'
          >
            <Button
              loading={creatingVariantGroup}
              disabled={
                creatingVariantGroup || disableGenerateVariantGroupCreation
              }
              data-testid={`${testIdKey}-submit`}
              htmlType='submit'
            >
              Generate Variants
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  )
}

export default VariantGroupForm
