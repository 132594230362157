import { createClient } from '@slerp/client'
import { UPLOAD_MODIFIER_IMAGE } from '../ModifierQueries'

interface UploadModifierImageProps {
  modifierId: string
  image: File
}

const apiKey = localStorage.getItem('token') || ''
const UploadModifierImage = async ({
  modifierId,
  image
}: UploadModifierImageProps) => {
  const client = createClient(apiKey)
  const variables = {
    modifierId,
    image
  }

  return client.mutate({
    mutation: UPLOAD_MODIFIER_IMAGE,
    variables,
    refetchQueries: ['getMerchantModifiers']
  })
}

export default UploadModifierImage
