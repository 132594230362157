import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { SidebarStyleSettings } from './SidebarStyleSettings'
import { useApp } from '../../contexts/App'
import { SCREENS } from '../constants'

const StyleSidebar = () => {
  const { screen, activeToolbar, toggleActiveToolbar, page } = useApp()
  const mode = 'style'
  const screenData = SCREENS.find(({ key }) => key === screen)
  const ActiveSidebarStyle = screenData && screenData.sidebarStyle

  useEffect(() => {
    if (page === 'messages') {
      toggleActiveToolbar('style')
    }
  }, [page])

  return (
    <Container
      activeToolbar={activeToolbar}
      onMouseOver={() => {
        toggleActiveToolbar('style')
      }}
    >
      <Content activeToolbar={activeToolbar}>
        {mode === 'style' && (
          <>
            <SidebarStyleSettings />
            {ActiveSidebarStyle && <ActiveSidebarStyle />}
          </>
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div<{ activeToolbar: 'style' | 'configure' }>`
  position: relative;
  z-index: 1000;

  display: flex;

  animation: ${({ activeToolbar }) =>
      activeToolbar === 'style' ? 'openStyle' : 'closeStyle'}
    250ms forwards;

  /* Toolbar label */
  &::after,
  &::before {
    position: absolute;
    top: 50%;
    z-index: 500;

    display: flex;
    content: 'style';
    padding: 16px 4px;
    border-radius: 16px;
    background-color: white;
    width: auto;

    writing-mode: vertical-rl;
    text-orientation: mixed;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme }: any) => theme.colors.background75};

    transform: translateY(-50%);

    animation: ${({ activeToolbar }) =>
      activeToolbar === 'style'
        ? 'openStyleLabel 250ms forwards, activeToolbarLabel 200ms forwards'
        : 'closeStyleLabel 250ms forwards, inactiveToolbarLabel 200ms forwards'};
  }

  &::before {
    z-index: 1;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
    animation: ${({ activeToolbar }) =>
      activeToolbar === 'style'
        ? 'openStyleLabel 250ms forwards, activeToolbarLabel 200ms forwards, openOpacity 250ms forwards'
        : 'closeOpacity 250ms forwards, closeStyleLabel, inactiveToolbarLabel 200ms forwards'};
  }
`

const Content = styled.div<{ activeToolbar: 'style' | 'configure' }>`
  position: relative;
  z-index: ${({ theme }: any) => theme.zIndex.sidebar};

  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: ${({ theme }: any) => theme.colors.white};
  padding: 16px 24px 16px 8px;
  max-width: 282px;

  @media (min-width: 1025px) {
    max-width: 382px;
  }

  animation: ${({ activeToolbar }) =>
        activeToolbar === 'style' ? 'openContent' : 'closeContent'}
      250ms forwards,
    ${({ activeToolbar }) =>
        activeToolbar === 'style' ? 'openOpacity' : 'closeOpacity'}
      250ms forwards;

  overflow-y: scroll;
`

export { StyleSidebar }
