import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback
} from 'react'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import { Button, Card, Collapse, Col, message, Row, Spin } from 'antd'
import arrayMove from 'array-move'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import { useSession } from '@slerp/accounts'
import {
  GET_MERCHANT_SETTINGS,
  GET_MERCHANT_CATEGORIES,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  VIEW_CATEGORY_PRODUCT_COUNT
} from '../ProductQueries'
import Loading from '../../../Utils/Loading'
import '../Products.css'
import ProductArrangement from './'
import { Product } from './../types'
import { DraggableIcon, RightArrowIcon, DownArrowIcon } from '@slerp/assets'

const { Panel } = Collapse

interface ProductArrangementProps {
  products: Array<Product>
  setReorderProductDrawerVisible: Dispatch<SetStateAction<boolean>>
  reorderProductDrawerVisible: boolean
}

interface Category {
  id: string
  name: string
  slug: string
}

interface CategoryProductCount {
  category_id: string
  product_count: number
}

const CategoryArrangement = (props: ProductArrangementProps) => {
  const client = useApolloClient()
  const { merchant } = useSession()
  const {
    products,
    setReorderProductDrawerVisible,
    reorderProductDrawerVisible
  } = props
  const [categoryArrangements, setCategoryArrangements] = useState<string[]>([])
  const [isArrangementUpdating, setIsArrangementUpdating] =
    useState<boolean>(false)
  const [categoryProductCount, setCategoryProductCount] = useState<
    Array<CategoryProductCount>
  >([])
  const [selectedCategory, setSelectedCategory] = useState<string>('all')
  const [productsForArrangement, setProductsForArrangement] = useState<
    Array<Product>
  >([])

  const [allProductArrangement, setAllProductArrangement] = useState({})

  const { data, loading } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: { merchantSlug: merchant.slug }
  })

  const merchantSettings =
    !loading && data && data.merchants ? data.merchants[0].setting : null

  const {
    data: merchantCategories,
    loading: merchantCategoriesLoading,
    error: merchantCategoriesError
  } = useQuery(GET_MERCHANT_CATEGORIES, {
    variables: { merchantId: merchant.id }
  })

  const { data: categoryProductCountData } = useQuery(
    VIEW_CATEGORY_PRODUCT_COUNT,
    {
      variables: { merchantId: merchant.id }
    }
  )

  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )

  const getProductArrangements = useCallback(() => {
    if (
      merchantSettings?.product_arrangement &&
      Object.entries(merchantSettings.product_arrangement).length > 0
    ) {
      setAllProductArrangement(merchantSettings.product_arrangement)
    } else if (productsForArrangement) {
      setAllProductArrangement({ ...productsForArrangement.map((p) => p.id) })
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsForArrangement])

  useEffect(() => {
    if (merchantSettings) {
      const { category_arrangement } = merchantSettings

      if (!merchantCategories?.categories) return
      const categoryIds = merchantCategories?.categories.map(
        (category: { name: string; id: string }) => category.id
      )
      const arrangedCategories = categoryIds.sort((a: string, b: string) => {
        const rankA = Object.values(category_arrangement).findIndex(
          (val?: string) => val === a
        )
        const rankB = Object.values(category_arrangement).findIndex(
          (val?: string) => val === b
        )
        return rankA - rankB
      })
      setCategoryArrangements(arrangedCategories)
      getProductArrangements()
    }
  }, [
    merchantSettings,
    merchantCategories,
    reorderProductDrawerVisible,
    getProductArrangements
  ])

  useEffect(() => {
    if (categoryProductCountData) {
      const { view_category_product_count } = categoryProductCountData
      setCategoryProductCount(view_category_product_count)
    }
  }, [categoryProductCountData])

  useEffect(() => {
    if (products) {
      setProductsForArrangement(products)
    }
  }, [products])

  const getProductCountString = (categoryId: string) => {
    const catCountIndex = categoryProductCount
      .map((catCount: CategoryProductCount) => catCount.category_id)
      .indexOf(categoryId)
    const catCount = categoryProductCount[catCountIndex] || { product_count: 0 }
    return `${catCount.product_count} ${
      catCount.product_count > 1 ? 'products' : 'product'
    }`
  }

  const setAllProductArrangementHandler = useCallback(
    (filteredProducts: Product[], currentArrangement: object) => {
      const productsForRearrangement = filteredProducts?.map((pr) => pr.id)
      const productArrangementArray = Object.values(currentArrangement)

      // excludes the products to be rearranged from the list of all products arrangement
      const pristineProductArrangement = productArrangementArray.filter(
        (pr) => !productsForRearrangement.includes(pr)
      )

      // // creates an object for the group of pristine product arrangement(without the products to be rearranged)
      const pristineArrangementGroup = pristineProductArrangement.reduce(
        (productArrangement, id, index) => ({
          ...productArrangement,
          [index]: id
        }),
        {}
      )

      // creates an object for the group of products to be rearranged with a key/index that continues from the current arrangement group
      const newArrangementGroup = productsForRearrangement.reduce(
        (productArrangement, id, index) => ({
          ...productArrangement,
          [pristineProductArrangement.length + index]: id
        }),
        {}
      )

      const updatedProductArrangement = {
        ...pristineArrangementGroup,
        ...newArrangementGroup
      }

      setAllProductArrangement(updatedProductArrangement)
    },
    [setAllProductArrangement]
  )

  const SortableCategory = SortableElement(
    ({ category }: { category: string }) => {
      const { categories } = merchantCategories || {}
      const categoryIndex = categories
        .map((cat: Category) => cat.id)
        .indexOf(category)
      const currentCategory = categories[categoryIndex] || {}

      return (
        <Card
          data-testid={`draggable-category-item-${currentCategory.name}`}
          className={`width-100`}
          bodyStyle={{
            cursor: 'grab',
            padding: 8
          }}
        >
          <Collapse
            defaultActiveKey={selectedCategory}
            accordion
            expandIconPosition='right'
            expandIcon={() => {
              if (selectedCategory === currentCategory.id)
                return <DownArrowIcon />
              else return <RightArrowIcon />
            }}
            ghost
            onChange={(key) => setSelectedCategory(key as string)}
          >
            <Panel
              key={currentCategory.id}
              header={
                <Row align='middle' className='-category-list'>
                  {selectedCategory !== currentCategory.id && (
                    <Col span={1}>{<DraggableIcon />}</Col>
                  )}
                  <Col span={selectedCategory !== currentCategory.id ? 23 : 24}>
                    <Row
                      align='middle'
                      className={`-category-list ${
                        selectedCategory === currentCategory.id && '_ml-8'
                      }`}
                    >
                      <Col span={12}>{currentCategory.name}</Col>
                      <Col span={12}>
                        <Row
                          gutter={[8, 8]}
                          align='middle'
                          justify='end'
                          className='-category-list'
                        >
                          <Col>
                            {!isEmpty(categoryProductCount) &&
                              getProductCountString(currentCategory.id)}
                            {isEmpty(categoryProductCount) && (
                              <Spin size='small' />
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <ProductArrangement
                filteredProducts={productsForArrangement.filter(
                  (pr) => pr.category_id === currentCategory.id
                )}
                productArrangement={allProductArrangement}
                setAllProductArrangementHandler={
                  setAllProductArrangementHandler
                }
                isArrangementUpdating={isArrangementUpdating}
                categoryName={currentCategory.name}
              />
            </Panel>
          </Collapse>
        </Card>
      )
    }
  )

  const SortableCategoriesList = SortableContainer(
    ({ items }: { items: string[] }) => {
      return (
        <div data-testid='category-row-container'>
          {items?.map((item: string, i: number) => {
            return (
              <SortableCategory
                key={item}
                category={item}
                index={i}
                disabled={isArrangementUpdating || selectedCategory === item}
              />
            )
          })}
        </div>
      )
    }
  )

  const saveCategoryArrangements = () => {
    message.loading(`Updating category arrangements... Please Wait.`, 2)
    setIsArrangementUpdating(true)
    const updatedCategoryArrangement: string[] = Object.assign(
      {},
      categoryArrangements.map((cat) => cat)
    )

    updateMerchantArrangementSettings({
      variables: {
        merchant: merchant.slug,
        arrangement: {
          category_arrangement: updatedCategoryArrangement,
          product_arrangement: allProductArrangement
        }
      }
    })
      .then((result) => {
        setIsArrangementUpdating(false)
        message.destroy()
        message.success('Category arrangements updated.', 3)
        client.resetStore()
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to update category arrangements due to ${error.message}`,
          3
        )
      })
  }

  const handleCategorySortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newArrangement = arrayMove(categoryArrangements, oldIndex, newIndex)
    setCategoryArrangements(newArrangement)
  }

  if (loading || merchantCategoriesLoading) return <Loading />

  if (merchantCategoriesError)
    return <div>Something went wrong. Please try reloading the page.</div>

  if (isEmpty(merchantCategories) || isEmpty(categoryArrangements))
    return (
      <div>
        No categories found. Please create categories before reordering them.
      </div>
    )

  return (
    <>
      <>
        <Row
          gutter={8}
          align='middle'
          justify='end'
          className='reorder-strip reorder-buttons _pr-12 _mb-0'
        >
          <Col>
            <Button
              data-testid='discard-category-arrangement-button'
              loading={isArrangementUpdating}
              onClick={() => {
                setReorderProductDrawerVisible(false)
              }}
              type='ghost'
            >
              DISCARD
            </Button>
          </Col>
          <Col>
            <Button
              data-testid='save-category-arrangement-button'
              loading={isArrangementUpdating}
              disabled={isArrangementUpdating}
              onClick={() => {
                saveCategoryArrangements()
              }}
              type='primary'
            >
              SAVE
            </Button>
          </Col>
        </Row>
        <div className='sortable-container'>
          <SortableCategoriesList
            axis='y'
            lockAxis='y'
            distance={1}
            items={categoryArrangements}
            onSortEnd={handleCategorySortEnd}
            helperClass='sortable-category-item'
          />
        </div>
      </>
    </>
  )
}

export default CategoryArrangement
