import moment from 'moment-timezone'

const isoStringFormat = 'YYYY-MM-DDTHH:mm'

const buildReportingDate = (
  date: moment.Moment | null | undefined,
  time: moment.Moment | null | undefined,
  anchor: 'start' | 'end'
) => {
  const newDate =
    anchor === 'start' ? moment(date).startOf('day') : moment(date).endOf('day')
  return moment(newDate).format(isoStringFormat)
}

export { buildReportingDate }
