/* eslint-disable no-useless-escape */

import { get } from '@slerp/helpers/api'
import {
  extractGoogleAddressComponents,
  hasStreetAddress
} from '@slerp/helpers/address-parser'

const addressValidationURL = '/api/address/validate'

export const isValidAddress = (query, phone) => {
  const params = { address: query, phone: phone }
  return get(addressValidationURL, params)
}

export const isValidPhoneNumber = (phone) => {
  return phone.length === 13 && /^(\+447|\+4420|\+441)/.test(phone)
}

export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(email)
}

export const isValidPostcode = (postcode) => {
  const regex =
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

  return regex.test(postcode)
}

export const validateAddressResults = ({ line2, city, country, zip }) => {
  return !!line2.length && !!city.length && !!country.length && !!zip.length
}

export const validateSelectedAddress = (result) => {
  if (result && result.gmaps && result.location) {
    const { gmaps, location } = result
    const address = extractGoogleAddressComponents(gmaps.address_components)
    if (
      validateAddressResults(address) &&
      hasStreetAddress(gmaps.address_components)
    ) {
      return {
        address: address,
        coords: location,
        addressError: {
          isShown: false,
          message: '',
          type: ''
        }
      }
    } else {
      return {
        address: address,
        coords: location,
        addressError: {
          isShown: true,
          message:
            'Exact address cannot be found. Please enter your address manually.',
          type: 'inaccurate'
        }
      }
    }
  }
}
