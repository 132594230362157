import React, { useState, lazy, Suspense } from 'react'
import {
  Button,
  Col,
  Descriptions,
  Drawer,
  Modal,
  Row,
  Table,
  Tooltip,
  message,
  Spin
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'

import { LIST_CAMPAIGNS, ARCHIVE_CAMPAIGN } from './AppSettingsQueries'
import { useQuery, useMutation } from '@apollo/client'
import CampaignForm from './CampaignForm'
import FormattedDate from '../Utils/FormattedDate'

const SendNotifications = lazy(() => import('./SendNotifications'))

export type Campaign = {
  id: string
  title: string
  notificationTitle: string
  notificationBody: string
  notificationsSentTotal: integer
  notificationsSentSuccess: integer
  publishOn: string
  insertedAt: string
}

const CampaignHistory = ({ isPublished }) => {
  const [campaigns, setCampaigns] = useState<Array<Campaign>>([])

  const { loading, refetch } = useQuery(LIST_CAMPAIGNS, {
    variables: { isPublished: isPublished },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setCampaigns(data.listCampaigns)
    }
  })

  const [archiveCampaign] = useMutation(ARCHIVE_CAMPAIGN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const newCampaigns = campaigns.filter(
        (campaign) => campaign.id !== data.archiveCampaign.id
      )
      setCampaigns(newCampaigns)

      message.destroy()
      message.success('Campaign archived!')
    },
    onError: () => {
      message.destroy()
      message.error('Something went wrong!')
    }
  })

  const [drawerVisibility, setDrawerVisibility] = useState<Boolean>(false)
  const [modalVisibility, setModalVisibility] = useState<Boolean>(false)

  const initCampaign = {
    id: '',
    title: '',
    notificationTitle: '',
    notificationBody: '',
    notificationsSentTotal: 0,
    notificationsSentSuccess: 0,
    publishOn: '',
    insertedAt: ''
  }
  const [campaign, setCampaign] = useState<Campaign>(initCampaign)

  const [isCreate, setIsCreate] = useState<Boolean>(true)

  const onDrawerClose = () => {
    setDrawerVisibility(false)
  }

  const handleCellClick = (record: Campaign) => {
    return {
      onClick: (event) => {
        setCampaign(record)
        setModalVisibility(true)
        setDrawerVisibility(false)
      }
    }
  }

  const columns = [
    {
      title: 'Campaign Title',
      dataIndex: 'title',
      key: 'title',
      onCell: handleCellClick,
      render: (title: string) => <span>{title}</span>
    },
    {
      title: 'Audience',
      dataIndex: 'notificationsSentTotal',
      key: 'notificationsSentTotal',
      onCell: handleCellClick,
      render: (notificationsSentTotal: integer) => (
        <span>{notificationsSentTotal}</span>
      )
    },
    {
      title: 'Delivered',
      dataIndex: 'notificationsSentSuccess',
      key: 'notificationsSentSuccess',
      onCell: handleCellClick,
      hidden: !isPublished,
      render: (notificationsSentSuccess: integer, campaign: Campaign) => (
        <span>
          {campaign.notificationsSentTotal > 0
            ? (
                (100 * notificationsSentSuccess) /
                campaign.notificationsSentTotal
              ).toFixed(2)
            : 0}
          %
        </span>
      )
    },
    {
      title: 'Created At',
      dataIndex: 'insertedAt',
      key: 'insertedAt',
      onCell: handleCellClick,
      render: (insertedAt: string) => (
        <FormattedDate>{insertedAt}</FormattedDate>
      )
    },
    {
      title: isPublished ? 'Published On' : 'Scheduled for',
      dataIndex: 'publishOn',
      key: 'publishOn',
      onCell: handleCellClick,
      render: (publishOn: string) => <FormattedDate>{publishOn}</FormattedDate>
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      hidden: isPublished,
      render: (id: string, campaign: Campaign) => (
        <Row gutter={[8, 8]} align='middle' className='_mb-0'>
          <Col>
            <Tooltip title='Edit' color='#d092dd'>
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => {
                  setIsCreate(false)
                  setCampaign(campaign)
                  setModalVisibility(false)
                  setDrawerVisibility(true)
                }}
                data-testid='edit-campaign-btn'
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title='Archive' color='#d092dd'>
              <Button
                size='small'
                icon={<DeleteOutlined />}
                onClick={() => {
                  archiveCampaign({ variables: { id: campaign.id } })
                }}
                data-testid='archive-campaign-btn'
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ].filter((col) => !col.hidden)

  return (
    <>
      <Row align='middle' justify='end'>
        <Col>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsCreate(true)
              setCampaign(initCampaign)
              setDrawerVisibility(true)
            }}
            data-testid='campaign-create-btn'
          >
            Create Campaign
          </Button>
        </Col>
      </Row>
      <Table
        data-testid='campaigns-history-table'
        dataSource={campaigns}
        columns={columns}
        pagination={{
          defaultPageSize: 5,
          position: ['bottomCenter'],
          showSizeChanger: true
        }}
        loading={loading}
        rowKey='id'
      />
      <Drawer
        title={isCreate ? 'Create Campaign' : 'Edit Campaign'}
        className='order-drawer'
        visible={drawerVisibility}
        onClose={onDrawerClose}
        destroyOnClose={true}
      >
        {isCreate ? (
          <Suspense fallback={<Spin />}>
            <SendNotifications
              setDrawerVisibility={setDrawerVisibility}
              refetch={refetch}
            />
          </Suspense>
        ) : (
          <CampaignForm
            isCreate={isCreate}
            campaign={campaign}
            setDrawerVisibility={setDrawerVisibility}
            refetch={refetch}
          />
        )}
      </Drawer>
      <Modal
        title='Campaign Details'
        visible={modalVisibility}
        footer={null}
        onCancel={() => {
          setModalVisibility(false)
        }}
      >
        <Descriptions layout='vertical' column={1}>
          <Descriptions.Item
            style={{ paddingBottom: '4px' }}
            contentStyle={{ paddingBottom: '16px' }}
            label='Campaign Title'
          >
            {campaign.title}
          </Descriptions.Item>
          <Descriptions.Item
            style={{ paddingBottom: '4px' }}
            contentStyle={{ paddingBottom: '16px' }}
            label='Notification Title'
          >
            {campaign.notificationTitle}
          </Descriptions.Item>
          <Descriptions.Item
            style={{ paddingBottom: '4px' }}
            contentStyle={{ paddingBottom: '16px' }}
            label='Notification Body'
          >
            {campaign.notificationBody}
          </Descriptions.Item>
          <Descriptions.Item
            style={{ paddingBottom: '4px' }}
            contentStyle={{ paddingBottom: '16px' }}
            label={
              <>
                Audience Size
                <Tooltip
                  title='This represents the total number of guests or customers who have the potential to receive your push notifications. Please note that only those who have downloaded your app will be included in this number.'
                  placement='right'
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          >
            {campaign.notificationsSentTotal}
          </Descriptions.Item>
        </Descriptions>

        <h2>Metrics coming soon!</h2>
      </Modal>
    </>
  )
}

export default CampaignHistory
