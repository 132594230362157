import React from 'react'
import { Col, Form, Button, Input, Row, Divider } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import { uniqueEmailRule, confirmPasswordRule } from './rules'

const StoreUsersForm = (props: any) => {
  const { merchant } = useSession()
  const [form] = Form.useForm()
  const defaultValue = {
    users: [
      {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      }
    ]
  }

  return (
    <Form
      form={form}
      name='storeUsersForm'
      initialValues={props.formData || defaultValue}
      wrapperCol={{ span: 12 }}
      labelCol={{ span: 24 }}
      layout='vertical'
    >
      <Form.List name='users'>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, index) => (
                <>
                  {index !== 0 && (
                    <Row
                      align='middle'
                      gutter={[24, 8]}
                      className='_mt-40 _mb-4'
                    >
                      <Col span={16}>
                        <Divider />
                      </Col>
                      <Col span={1}>
                        <CloseCircleOutlined
                          style={{
                            fontSize: '1.125rem'
                          }}
                          onClick={() => {
                            remove(field.name)
                          }}
                          hidden={field.key < props.formData.users.length}
                        />
                      </Col>
                    </Row>
                  )}
                  <div>
                    <Form.Item
                      {...field}
                      label='First Name:'
                      name={[field.name, 'firstName']}
                      fieldKey={[field.fieldKey, 'firstName']}
                      rules={[
                        { required: true, message: 'Missing first name' }
                      ]}
                    >
                      <Input placeholder='First Name' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Last Name:'
                      name={[field.name, 'lastName']}
                      fieldKey={[field.fieldKey, 'lastName']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                    >
                      <Input placeholder='Last Name' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Email:'
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      rules={[
                        { required: true, message: 'Missing email' },
                        {
                          type: 'email',
                          message: 'Please input a valid email address'
                        },
                        () => uniqueEmailRule(merchant.id)
                      ]}
                    >
                      <Input placeholder='Email' autoComplete='off' />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Password:'
                      name={[field.name, 'password']}
                      fieldKey={[field.fieldKey, 'password']}
                      rules={[
                        { required: true, message: 'Missing password' },
                        ({ getFieldsValue }) =>
                          confirmPasswordRule(field.key, getFieldsValue)
                      ]}
                      validateTrigger='onSubmit'
                    >
                      <Input.Password
                        placeholder='Password'
                        className='_pl-4'
                        autoComplete='off'
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Confirm Password:'
                      name={[field.name, 'confirm_password']}
                      fieldKey={[field.fieldKey, 'confirm_password']}
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password'
                        },
                        ({ getFieldsValue }) =>
                          confirmPasswordRule(field.key, getFieldsValue)
                      ]}
                      validateTrigger='onSubmit'
                    >
                      <Input.Password
                        placeholder='Confirm Password'
                        className='_pl-4'
                        autoComplete='off'
                      />
                    </Form.Item>
                  </div>
                </>
              ))}
              <Form.Item>
                <Button
                  type='ghost'
                  onClick={add}
                  className='_uppercase btn-text _mt-16 -secondary'
                >
                  <PlusOutlined /> Add
                </Button>
              </Form.Item>
            </>
          )
        }}
      </Form.List>
      <Divider />
      {props.formActionButtons || (
        <Row className='_mt-32'>
          <Col>
            <Button type='default' onClick={props.handlePrevForm}>
              Back
            </Button>
          </Col>
          <Col className='_center-vertical _ml-auto'>
            <Button htmlType='submit'>Submit</Button>
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default StoreUsersForm
