import React from 'react'
import moment from 'moment-timezone'

interface FormattedDateProps {
  children: number | Date | string
}

const FormattedDate = (props: FormattedDateProps) => (
  <div className='order-datetime'>
    {props.children ? (
      <>
        <p className='date'>
          {moment.utc(props.children).tz('Europe/London').format('DD/MM/YYYY')}
        </p>
        <p className='time'>
          {moment.utc(props.children).tz('Europe/London').format('hh:mm A')}
        </p>
      </>
    ) : (
      '--'
    )}
  </div>
)

export default FormattedDate
