import { CheckboxValueType } from 'antd/lib/checkbox/Group'

export const handleCompanyWebsiteFormat = (url: string) => {
  if (!url) return ''

  const formattedUrl = removeSubdomain(url)

  if (formattedUrl.includes('https://')) return formattedUrl
  if (formattedUrl.includes('http://'))
    return formattedUrl.replace(/http:\/\//g, 'https://')
  return `https://${formattedUrl}`
}

export const removeSubdomain = (url: string) => {
  return url.replace(/www./g, '')
}

export const checkboxDataSanitizer = (
  values: string[] | CheckboxValueType[]
) => {
  return values.reduce((acc, current, index) => {
    return acc + `${current}${index === values.length - 1 ? '' : ';'}`
  }, '')
}
