import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Row, Col, Descriptions, Divider, Table, Typography } from 'antd'
import { format, parseISO } from 'date-fns'
import { isEmpty, compact } from 'lodash'
import { QUERY_ORDER } from './OrderQueries'
import AppliedModifiers from './AppliedModifiers'
import { Order } from './types'
import { FEE_TYPE_NAMES } from './OrderDetails'
import { formatMoney } from 'packages/@slerp/designer-rework/shop/components/Cart/utils'
import { getDeliveryCharge } from './Info/utils'
import { computeVat } from 'components/Utils/price'

const { Column } = Table

const PrintOrder = (props: RouteComponentProps) => {
  const {
    match: { params }
  } = props
  const orderId = (params as { order_id: string }).order_id
  const { data, loading, error } = useQuery(QUERY_ORDER, {
    variables: { transactionId: orderId }
  })
  const order: Order | null =
    !loading && data?.orders?.length > 0 ? data.orders[0] : null

  const formatMoney = (amount: number, negative?: boolean) => {
    if (typeof amount !== 'number') return ''
    return `${negative ? '-£' : '£'}${amount.toFixed(2)}`
  }

  const columnSpan = order?.recipient_details ? 8 : 12

  const isWithTradingDetails = useMemo(() => {
    const { merchant } = order || {}
    const { line_1, line_2, city, zip } = merchant?.address || {}
    const formattedAddress = compact([line_1, line_2, city, zip]).join(', ')

    return (
      !isEmpty(merchant?.registered_company_name) ||
      !isEmpty(formattedAddress) ||
      !isEmpty(merchant?.vat_registration_number)
    )
  }, [order])

  // limited to variants and modifiers
  const totalOrderVat = useMemo(() => {
    const { order_items = [] } = order || {}
    const totalVariantVat = order_items.reduce((total, order_item) => {
      const { quantity, variant_price, variant_vat, applied_modifiers } =
        order_item
      const totalModifiersVat = applied_modifiers.reduce((total, modifier) => {
        const { vat, price } = modifier
        const vatAmount = computeVat(price, vat).toNumber() * quantity
        return total + vatAmount
      }, 0)

      const vatAmount =
        computeVat(variant_price, variant_vat).toNumber() * quantity

      return total + vatAmount + totalModifiersVat
    }, 0)

    return totalVariantVat
  }, [order])

  useEffect(() => {
    if (!loading && (!order || error))
      return window.alert(`Order #${orderId} not found`)
    if (order) window.print()
  }, [error, order, orderId, loading])

  if (!order) return <></>

  return (
    <div className='print-order-container' data-testid='print-order-container'>
      <div className='panel'>
        <Typography.Title level={3}>
          Order #{orderId}
          <span className='fulfillment-type-badge'>
            {order.fulfillment_type}
          </span>
        </Typography.Title>

        <Divider />

        <Row gutter={8}>
          {isWithTradingDetails && (
            <Col span={columnSpan}>
              <TradingDetails order={order} />
            </Col>
          )}
          <Col span={columnSpan}>
            <StoreDetails order={order} />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={columnSpan}>
            <CustomerDetails order={order} />
          </Col>
          <Col span={columnSpan}>
            <FulfillmentDetails order={order} />
          </Col>
          {order.recipient_details && (
            <Col span={8}>
              <RecipientDetails order={order} />
            </Col>
          )}
        </Row>

        <Divider />

        {(order.gift_wrap_message || order.order_notes) && (
          <>
            <Row gutter={8}>
              {order.order_notes && (
                <Col span={12}>
                  <Descriptions layout='vertical' size='small' column={1}>
                    <Descriptions.Item label='Order notes'>
                      {order.order_notes}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              )}
              {order.gift_wrap_message && (
                <Col span={12}>
                  <Descriptions layout='vertical' size='small' column={1}>
                    <Descriptions.Item label='Gifting'>
                      {order.gift_wrap_message}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              )}
            </Row>
            <Divider />
          </>
        )}

        <OrderItems order={order} />

        <Table
          showHeader={false}
          pagination={false}
          dataSource={[
            {
              title: <strong>Subtotal</strong>,
              description: '',
              value: formatMoney(order.subtotal)
            },
            ...(order.gift_wrap_message
              ? [
                  {
                    title: 'Gifting',
                    description: '',
                    value: formatMoney(order.gift_wrap_price)
                  }
                ]
              : []),
            ...(order.discounts_cache && !isEmpty(order.discounts_cache?.code)
              ? [
                  {
                    title: 'Discount',
                    description: order.discounts_cache?.code,
                    value: formatMoney(
                      parseFloat(order.discounts_cache?.total_discount) || 0
                    )
                  }
                ]
              : []),
            ...(order.delivery_charge_reduction_reason ===
              'delivery_pricing_band' &&
            order.fulfillment_type !== 'pickup' &&
            order.discounts_cache?.target !== 'delivery_fee'
              ? [
                  {
                    title: 'Tiered delivery pricing discount',
                    description: `-${order.delivery_pricing_band.percentage_discount}%`,
                    value: `-£${order.delivery_charge_reduction.toFixed(2)}`
                  }
                ]
              : []),
            ...((order.delivery_charge || order.delivery_charge === 0) &&
            order.fulfillment_type === 'delivery'
              ? [
                  {
                    title: 'Delivery fee',
                    description: 'Charged to customer',
                    value: `£${getDeliveryCharge(order).toFixed(2)}`
                  }
                ]
              : []),
            ...(order.delta_delivery_charge &&
            order.fulfillment_type === 'delivery'
              ? [
                  {
                    title: 'Delivery Subsidisation',
                    description: 'Charged to partner',
                    value: `£${order.delta_delivery_charge.toFixed(2)}`
                  }
                ]
              : []),
            ...(order.additional_store_fee && order.additional_store_fee > 0
              ? [
                  {
                    title: 'Additional store fee',
                    description: order.additional_store_fee_cache?.name || '',
                    value: `£${order.additional_store_fee.toFixed(2)}`
                  }
                ]
              : []),
            ...(!isEmpty(order.misc_fees)
              ? order.misc_fees.map((fee) => ({
                  title: FEE_TYPE_NAMES[fee.type],
                  description: fee.note && fee.note.length > 0 ? fee.note : '',
                  value: formatMoney(Math.abs(fee.amount || 0), fee.amount < 0)
                }))
              : [])
          ]}
        >
          <Column width='40%' title='Title' dataIndex='title' key='title' />
          <Column
            width='30%'
            title='Description'
            dataIndex='description'
            key='description'
          />
          <Column
            title='Amount'
            dataIndex='value'
            key='value'
            align='right'
            render={(value, { title }, index) => {
              // index of 0 is Subtotal
              if (index === 0) {
                return (
                  <>
                    <Row className='_mb-0' justify='end'>
                      <Col>{value}</Col>
                    </Row>
                    <Row className='_mb-0 -vat-figure' justify='end'>
                      <Col>
                        <Typography.Text italic>
                          Inc. {formatMoney(totalOrderVat)} VAT
                        </Typography.Text>
                      </Col>
                    </Row>
                  </>
                )
              }
              if (title === 'Delivery fee') {
                return (
                  <>
                    <Row className='_mb-0' justify='end'>
                      <Col>{value}</Col>
                    </Row>
                    <Row className='_mb-0 -vat-figure' justify='end'>
                      <Col>
                        <Typography.Text italic>
                          Inc. {formatMoney(order.delivery_charge_vat)} VAT
                        </Typography.Text>
                      </Col>
                    </Row>
                  </>
                )
              }
              if (title === 'Delivery Subsidisation') {
                return (
                  <>
                    <Row className='_mb-0' justify='end'>
                      <Col>{value}</Col>
                    </Row>
                    <Row className='_mb-0 -vat-figure' justify='end'>
                      <Col>
                        <Typography.Text italic>
                          Inc.{' '}
                          {formatMoney(
                            order.delivery_vat_charge -
                              order.delivery_charge_vat
                          )}{' '}
                          VAT
                        </Typography.Text>
                      </Col>
                    </Row>
                  </>
                )
              }
              return value
            }}
          />
        </Table>

        <Typography className='_text-right _my-24 _mr-16'>
          <strong>Net Total* : </strong> {formatMoney(order.total)}
        </Typography>
      </div>
    </div>
  )
}

export const TradingDetails = ({ order }: { order: Order }) => {
  const { merchant } = order || {}
  const { line_1, line_2, city, zip } = merchant?.address || {}
  const formattedAddress = compact([line_1, line_2, city, zip]).join(', ')
  return (
    <Descriptions
      className='-print'
      title='Trading Details'
      layout='vertical'
      size='small'
      column={1}
    >
      {!isEmpty(merchant?.registered_company_name) && (
        <Descriptions.Item label='Trading Name'>
          {merchant?.registered_company_name}
        </Descriptions.Item>
      )}
      {!isEmpty(formattedAddress) && (
        <Descriptions.Item label='Trading Address'>
          {formattedAddress}
        </Descriptions.Item>
      )}
      {!isEmpty(merchant?.vat_registration_number) && (
        <Descriptions.Item label='VAt Registration Number'>
          {merchant?.vat_registration_number}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export const StoreDetails = ({ order }: { order: Order }) => {
  const { store } = order || {}
  const { line_1, line_2, city, zip } = store?.address || {}
  const formattedAddress = compact([line_1, line_2, city, zip]).join(', ')
  return (
    <Descriptions
      className='-print'
      title='Store Details'
      layout='vertical'
      size='small'
      column={1}
    >
      {!isEmpty(store?.name) && (
        <Descriptions.Item label='Store Name'>{store?.name}</Descriptions.Item>
      )}
      {!isEmpty(formattedAddress) && (
        <Descriptions.Item label='Store Address'>
          {formattedAddress}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export const CustomerDetails = ({ order }: { order: Order }) => {
  return (
    <Descriptions
      className='-print'
      title='Customer Details'
      layout='vertical'
      size='small'
      column={1}
    >
      <Descriptions.Item label='Name'>
        {order.customer_details?.first_name || ''}{' '}
        {order.customer_details?.last_name || ''}
      </Descriptions.Item>
      <Descriptions.Item label='Email'>
        {order.customer_details?.email || ''}
      </Descriptions.Item>
      <Descriptions.Item label='Phone Number'>
        {order.customer_details?.contact_num || ''}
      </Descriptions.Item>
      <Descriptions.Item label='Order Placed At'>
        {format(parseISO(order.inserted_at), 'EEE MMM dd yyyy')} |{' '}
        {format(parseISO(order.inserted_at), 'pppp')}
      </Descriptions.Item>
    </Descriptions>
  )
}

export const FulfillmentDetails = ({ order }: { order: Order }) => {
  const taxDate = order.fulfillment_date
  return (
    <Descriptions
      className='-print'
      title={`${order.fulfillment_type} Details`}
      layout='vertical'
      size='small'
      column={1}
    >
      <Descriptions.Item label='Ordered From'>
        {order.merchant.name} - {order.store.name}
      </Descriptions.Item>
      {order.fulfillment_type === 'delivery' && (
        <Descriptions.Item label='Delivery Address'>
          {order.customer_details.address &&
            `${order.customer_details.address.flat_number || ''} ${
              order.customer_details.address.line_1 || ''
            } ${order.customer_details.address.line_2 || ''} ${
              order.customer_details.address.city || ''
            } ${order.customer_details.address.zip}`}
        </Descriptions.Item>
      )}
      <Descriptions.Item label='Receipt Number'>
        {order.transaction_id}
      </Descriptions.Item>
      <Descriptions.Item label='Receipt Date'>
        {format(
          parseISO(order.fulfillment_date || order.inserted_at),
          'EEE MMM dd yyyy'
        )}{' '}
        | {order.fulfillment_time_range}
      </Descriptions.Item>
      {!isEmpty(taxDate) && (
        <Descriptions.Item label='Tax Date'>
          {format(parseISO(taxDate), 'EEE MMM dd yyyy')}
        </Descriptions.Item>
      )}
      {order.fulfillment_type === 'delivery' && !!order.dropoff_notes && (
        <Descriptions.Item label='Delivery Notes'>
          {order.dropoff_notes}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

const RecipientDetails = ({ order }: { order: Order }) => {
  return (
    <Descriptions
      className='-print'
      title='Order for'
      layout='vertical'
      size='small'
      column={1}
    >
      <Descriptions.Item label='Name'>
        {order.recipient_details?.first_name || ''}{' '}
        {order.recipient_details?.last_name || ''}
      </Descriptions.Item>
      <Descriptions.Item label='Phone Number'>
        {order.recipient_details?.contact_num || ''}
      </Descriptions.Item>
    </Descriptions>
  )
}

export const OrderItems = ({ order }: { order: Order }) => {
  return (
    <>
      <Descriptions
        className='-print'
        title='Ordered Items'
        layout='vertical'
        size='small'
        column={1}
      />

      <Table dataSource={order.order_items} pagination={false}>
        <Column
          title='Product'
          dataIndex='product_variant.name'
          key='product'
          width='40%'
          render={(
            _text: string,
            record: { quantity: number; product_variant: { name: string } }
          ) => (
            <span>
              {record.quantity} x {record.product_variant.name}
            </span>
          )}
        />
        <Column
          title='Applied Modifiers'
          dataIndex='applied_modifiers'
          key='applied_modifiers'
          width='30%'
          render={(
            value: AppliedModifier[],
            record: {
              quantity: number
              product_variant: {
                product: {
                  modifier_group_arrangement: { [key: string]: string }
                }
              }
            }
          ) =>
            value && value.length > 0 ? (
              <AppliedModifiers
                orderQuantity={record.quantity}
                appliedModifiers={value}
                arrangement={
                  record.product_variant.product.modifier_group_arrangement
                }
              />
            ) : (
              <span>N/A</span>
            )
          }
        />
        <Column
          title='Price'
          dataIndex='amount'
          key='amount'
          align='right'
          render={(
            amount,
            { quantity, variant_price = 0, variant_vat = 0, applied_modifiers }
          ) => {
            const totalModifiersVat = applied_modifiers.reduce(
              (total, modifier) => {
                const { vat = 0, price = 0 } = modifier
                const vatAmount = computeVat(price, vat).toNumber() * quantity
                return total + vatAmount
              },
              0
            )
            const variantsVat =
              computeVat(variant_price, variant_vat).toNumber() * quantity
            const totalVat = variantsVat + totalModifiersVat
            return (
              <>
                <Row className='_mb-0' justify='end'>
                  <Col>{formatMoney(amount)}</Col>
                </Row>
                <Row className='_mb-0 -vat-figure' justify='end'>
                  <Col>
                    <Typography.Text italic>
                      Inc. {formatMoney(totalVat)} VAT
                    </Typography.Text>
                  </Col>
                </Row>
              </>
            )
          }}
        />
      </Table>
    </>
  )
}

export default PrintOrder
