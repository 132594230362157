export const nameRule = [
  {
    validator: (_: any, val: string) => {
      return val.replace(/\s+/g, '').length === 0
        ? Promise.reject('Please fill out this field')
        : Promise.resolve()
    }
  },
  {
    validator: (_: any, val: string) => {
      return !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
        val
      )
        ? Promise.reject('Special characters are not allowed')
        : Promise.resolve()
    }
  },
  {
    validator: (_: any, val: string) => {
      return val.length < 2
        ? Promise.reject('Must be a minimum of 2 characters')
        : Promise.resolve()
    }
  }
]
