import React from 'react'
import { Result } from 'antd'

const FeatureNotFound = () => (
  <Result
    status='404'
    title='You have no power here!'
    subTitle='Please subscribe in order to enable this feature.'
  />
)

export default FeatureNotFound
