import React from 'react'
import arrayMove from 'array-move'
import { Button, Card, Col, Row } from 'antd'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import { DeleteOutlined, DragOutlined } from '@ant-design/icons'
import '../../Modifiers.css'

interface Modifier {
  id: string
  name: string
  sku: string
  price: number
  vat: number
  alcoholic: boolean
  image: string | null
  archived_at: string | null
  modifier_groups: {
    modifier_group_id: string
  }[]
}

interface SortableModifiersListProps {
  arrangements: string[]
  modifiers: Modifier[]
  onSortEndHandler: (newArrangements: string[]) => void
  onRemoveModifierHandler: (modifierId: string) => void
}

const SortableModifiersList = ({
  arrangements,
  modifiers,
  onSortEndHandler,
  onRemoveModifierHandler
}: SortableModifiersListProps) => {
  const SortableModifier = SortableElement(
    ({ modifierId }: { modifierId: string }) => {
      const modIndex = modifiers
        .map((mod: Modifier) => mod.id)
        .indexOf(modifierId)
      const currentModifier = modifiers[modIndex] || {}
      const { id, name } = currentModifier

      return (
        <Card
          data-testid='draggable-column-item'
          className='width-100'
          bodyStyle={{
            cursor: 'grab',
            padding: 8
          }}
        >
          <Row className='-modifier-list'>
            <Col span={1}>
              <DragOutlined />
            </Col>
            <Col span={23}>
              <Row className='-modifier-list'>
                <Col span={12}>{name}</Col>
                <Col span={12}>
                  <Row justify='end' className='-modifier-list'>
                    <Button
                      icon={<DeleteOutlined />}
                      size='small'
                      onClick={() => onRemoveModifierHandler(id)}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )
    }
  )
  const SortableModifiersList = SortableContainer(
    ({ arrangements }: { arrangements: string[] }) => {
      return (
        <div>
          {arrangements.map((arrangement: string, i: number) => {
            return (
              <SortableModifier
                key={arrangement}
                modifierId={arrangement}
                index={i}
              />
            )
          })}
        </div>
      )
    }
  )

  const handleModifierSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newArrangement = arrayMove(arrangements, oldIndex, newIndex)
    onSortEndHandler(newArrangement)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <div>
            <SortableModifiersList
              axis='y'
              lockAxis='y'
              distance={1}
              arrangements={arrangements}
              onSortEnd={handleModifierSortEnd}
              helperClass='sortable-item'
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SortableModifiersList
