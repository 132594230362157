import React, { useState, useRef } from 'react'
import {
  Col,
  Row,
  Form,
  Tabs,
  Modal,
  Select,
  Button,
  message,
  Divider
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { requiredRule } from '../Rules'
import { InvalidEmail, LoyaltyCardType, User } from '../types'
import UploadCSV from './UploadCSV'
import { useMutation } from '@apollo/client'
import { BULK_ADD_CUSTOMERS_TO_EXCLUSIVE_CARD } from '../LoyaltyQueries'
import { useSlerp } from '@slerp/client'

const { TabPane } = Tabs
const { Option } = Select

interface BulkAddCardProps {
  onClose: () => void
  loyaltyCards?: LoyaltyCardType[]
}

const BulkAddCard = (props: BulkAddCardProps) => {
  const { onClose, loyaltyCards } = props
  const [form] = useForm()
  const { user } = useSlerp()
  const [fileName, setFileName] = useState<string>('')
  const [showEmails, setShowEmails] = useState<boolean>(false)
  const [hasFile, setHasFile] = useState<boolean>(false)
  const [activeKey, setActiveKey] = useState<string>('1')
  const [validEmails, setValidEmails] = useState<User[]>([])
  const [invalidEmails, setInvalidEmails] = useState<InvalidEmail>({
    message: '',
    list: []
  })
  const uploadRef = useRef<HTMLInputElement>(null)
  const [bulkAddCustomers] = useMutation(BULK_ADD_CUSTOMERS_TO_EXCLUSIVE_CARD)

  const validateCsvFileUpload = () => {
    const has = Boolean(validEmails) && Boolean(fileName)
    setHasFile(has)
  }

  const onSubmit = ({ id }: { id: string }) => {
    if (validEmails.length === 0)
      return message.error(
        'Unable to add stamps due to the uploaded .csv file not having any valid emails',
        5
      )

    form.validateFields().then(() => {
      bulkAddCustomers({
        variables: {
          cardId: id,
          customerIds: validEmails.map(({ id }: User) => id),
          merchantId: user.merchant.id,
          userId: user.id
        }
      })
        .then((res) => message.success('Added customers successfully!', 1))
        .catch((error) =>
          message.error(`Unable to add customers due to: ${error}`, 5)
        )
    })
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        colon
        className='_mt-24'
      >
        <UploadCSV
          invalidEmails={invalidEmails}
          setInvalidEmails={setInvalidEmails}
          validEmails={validEmails}
          setValidEmails={setValidEmails}
          hasFile={hasFile}
          setHasFile={setHasFile}
          fileName={fileName}
          setFileName={setFileName}
          setShowEmails={setShowEmails}
          uploadRef={uploadRef}
        />
        <Col span='12' className='_mt-24 _mb-24'>
          <Form.Item
            name='id'
            label={<span className='drawer-label'>Loyalty Card:</span>}
            required
            rules={requiredRule}
            className='_mb-0'
          >
            <Select
              placeholder='Select card'
              data-testid='bulk-rewards-cards'
              onChange={(value) => {
                const selected = loyaltyCards?.find((card) => card.id === value)

                form.setFieldsValue({ id: selected?.id })
              }}
            >
              {!!loyaltyCards?.length &&
                loyaltyCards.map((card: LoyaltyCardType) => (
                  <Option key={card?.id} value={card?.id}>
                    {card?.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Divider />

        <Row gutter={16} className='_mt-32' data-testid='bulk-rewards-cancel'>
          <Col>
            <Button type='ghost' onClick={onClose}>
              Discard
            </Button>
          </Col>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              disabled={!loyaltyCards?.length}
              onClick={() => validateCsvFileUpload()}
              data-testid='bulk-rewards-add-card'
            >
              Assign Card
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={showEmails}
        cancelText='close'
        cancelButtonProps={{ type: 'ghost', className: '-secondary' }}
        okText='upload other file'
        okButtonProps={{ type: 'default' }}
        title='Email addresses'
        onCancel={() => setShowEmails(false)}
        onOk={() => uploadRef.current?.click()}
      >
        <Tabs
          className='bulk-rewards-tabs'
          activeKey={activeKey}
          onChange={(key: string) => setActiveKey(key)}
        >
          <TabPane
            key='1'
            tab={`Match (${validEmails.length})`}
            disabled={!validEmails.length}
          >
            {Boolean(validEmails.length) && (
              <Row>
                {validEmails.map(({ email }: User) => (
                  <Col
                    span='11'
                    className='email-list _ml-10 _mb-10'
                    key={email}
                  >
                    {email}
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
          <TabPane
            key='2'
            tab={`No Match (${invalidEmails.list.length})`}
            disabled={!invalidEmails.list.length}
          >
            {Boolean(invalidEmails.list.length) && (
              <Row>
                {invalidEmails.list.map((email: string) => (
                  <Col
                    span='11'
                    className='email-list _ml-10 _mb-10'
                    key={email}
                  >
                    {email}
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

export default BulkAddCard
