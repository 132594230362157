import React from 'react'
import { Col, Row } from 'antd'

import ModifierGroupsList from './ModifierGroupsList'

const ModifierGroups = () => {
  return (
    <Row>
      <Col span={24}>
        <ModifierGroupsList />
      </Col>
    </Row>
  )
}

export default ModifierGroups
