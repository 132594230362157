import React, { useState, useEffect, useMemo } from 'react'
import { uuid } from 'uuidv4'
import flatten from 'lodash/flatten'
import { useSession } from '@slerp/accounts'
import { useQuery, useMutation } from '@apollo/client'
import {
  VariantPricing,
  Variant,
  MerchantFlags,
  FulfillmentTypePricing,
  FulfillmentType,
  Option
} from './types'
import { Col, Divider, message, Row, Switch, Typography } from 'antd'
import { getVariantPricing, anyPublishedFulfilmentTypePricing } from './utils'
import VariantPricingForm from './Form/VariantPricing'
import FulfillmentTypePricingForm from './Form/FulfillmentTypePricing'
import { storesSettingsFlags } from 'components/Utils/MerchantStoreFlagsAndSettings'
import { GET_MERCHANT_PRODUCT_VARIANTS } from '../ProductQueries'
import {
  GET_MERCHANT_STORES_FLAGS,
  UPSERT_PRICINGS,
  DELETE_FULFILMENT_TYPE_PRICINGS
} from './Queries'
import Actions from './Actions'
import { computeNetPrice } from 'components/Utils/price'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'
import Loading from '../../../Utils/Loading'
import errorReducer from 'errors/errorReducer'

interface PricingProps {
  productId: string
  baseName: string
}

interface VariantRow {
  variant: Variant
  index: number
}

interface VariantPricingRow {
  pricing: VariantPricing
  index: number
}

interface NewCoreVariantPricing {
  id: string
  price: number
  vat: number
}

const { Title, Text } = Typography

const Pricing = ({ productId, baseName }: PricingProps) => {
  const { merchant } = useSession()
  const [variants, setVariants] = useState<Array<Variant>>([])
  const [originalVariants, setOriginalVariants] = useState<Array<Variant>>([])
  const [originalPricings, setOriginalPricings] = useState<
    Array<VariantPricing>
  >([])
  const [pricings, setPricings] = useState<Array<VariantPricing>>([])
  const [variantsForVatRecomputation, setVariantsForVatRecomputation] =
    useState<Array<String>>([])
  const [merchantFlags, setMerchantFlags] = useState<MerchantFlags>({
    allStoresWithDelivery: false,
    allStoresWithPickup: false,
    allStoresWithOrderAtTable: false
  })
  const [showFulfillmentTypePricing, setShowFulfillmentTypePricing] =
    useState<boolean>(false)
  const [upsertPricings] = useMutation(UPSERT_PRICINGS, {
    refetchQueries: ['getMerchantProductVariants']
  })
  const [deleteFulfilmentTypePricings] = useMutation(
    DELETE_FULFILMENT_TYPE_PRICINGS,
    {
      refetchQueries: ['getMerchantProductVariants']
    }
  )
  const { data: variantsData, loading: fetchingVariants } = useQuery(
    GET_MERCHANT_PRODUCT_VARIANTS,
    {
      variables: {
        merchantId: merchant.id,
        productId: productId
      }
    }
  )
  const { data: storesFlagsData } = useQuery(GET_MERCHANT_STORES_FLAGS, {
    variables: {
      merchantId: merchant.id
    }
  })
  useEffect(() => {
    if (variantsData) {
      const { product_variants } = variantsData
      const variantPricings = getVariantPricing(product_variants)
      setShowFulfillmentTypePricing(
        anyPublishedFulfilmentTypePricing(product_variants)
      )
      setPricings(variantPricings)
      setVariants(product_variants)
      setOriginalVariants(product_variants)
      setOriginalPricings(variantPricings)
    }
  }, [variantsData])

  useEffect(() => {
    if (storesFlagsData) {
      const { stores: storesFlag } = storesFlagsData
      setMerchantFlags(storesSettingsFlags(storesFlag))
    }
  }, [storesFlagsData])

  const variantCoreVat = useMemo(
    () =>
      variants.reduce((variantHashMap, variant: Variant) => {
        variantHashMap[variant.id] = variant.vat
        return variantHashMap
      }, {}),
    [variants]
  )

  const variantPricingUpdateSuccessCallback = () => {
    message.destroy()
    message.success('Variant pricing updated!')
  }

  const savePricings = () => {
    const newVariants = variants.map(
      ({ id, price, vat, inserted_at }: Variant) => {
        return {
          id,
          price,
          vat,
          inserted_at,
          updated_at: 'now()'
        }
      }
    )
    const newFulfilmentTypePricing = pricings.map(
      (variantPricing: VariantPricing) => {
        return ['delivery', 'pickup', 'order_at_table'].map(
          (fulfilmentType: FulfillmentType) => {
            const pricing = variantPricing[fulfilmentType]
            const isReapplyVatChanges =
              variantsForVatRecomputation.includes(pricing.variant_id) &&
              pricing.price_with_vat != null

            const coreVat = variantCoreVat[pricing.variant_id]

            return {
              ...pricing,
              price: isReapplyVatChanges
                ? computeNetPrice(pricing.price_with_vat, coreVat)
                : pricing.price,
              vat: isReapplyVatChanges ? coreVat : pricing.vat,
              store_variant_id: null,
              published_at: showFulfillmentTypePricing ? 'now()' : null,
              inserted_at: 'now()',
              updated_at: 'now()'
            }
          }
        )
      }
    )

    upsertPricings({
      variables: {
        variants: newVariants,
        fulfilmentTypes: showFulfillmentTypePricing
          ? flatten(newFulfilmentTypePricing)
          : []
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Variant pricing updated!')
      })
      .catch((error: Error) => {
        throw errorReducer({
          origin: 'Product',
          data: {
            error: error,
            message: error.message
          }
        })
      })
  }

  const toggleFulfilmentTypePricings = (setToPublish: boolean) => {
    const newFulfilmentTypePricing = pricings.map(
      (variantPricing: VariantPricing) => {
        return ['delivery', 'pickup', 'order_at_table'].map(
          (fulfilmentType: FulfillmentType) => {
            const pricing = variantPricing[fulfilmentType]
            return {
              ...pricing,
              published_at: setToPublish ? 'now()' : null,
              inserted_at: 'now()',
              updated_at: 'now()'
            }
          }
        )
      }
    )

    if (!setToPublish) {
      deleteFulfilmentTypePricings({
        variables: {
          pricingIds: flatten(newFulfilmentTypePricing).map(
            ({ id }: FulfillmentType) => id
          )
        }
      }).catch((error: Error) => {
        throw errorReducer({
          origin: 'Product',
          data: {
            error: error,
            message: error.message
          }
        })
      })
    }
  }

  const resetPricing = () => {
    setVariants(originalVariants)
    setPricings(originalPricings)
    setVariantsForVatRecomputation([])
  }

  const updateCoreVariantsPricing = ({
    id,
    price,
    vat
  }: NewCoreVariantPricing) => {
    const newVariants = [...variants]
    const variantIndex = variants.findIndex(
      (variant: Variant) => variant.id === id
    )
    const variant = { ...newVariants[variantIndex], vat, price }
    newVariants[variantIndex] = variant
    setVariants([...newVariants])
  }

  const updateFulfilmentTypePricing = (
    fulfilmentPricing: FulfillmentTypePricing
  ) => {
    const fulfilmentType = fulfilmentPricing.fulfillment_type
    const newPricings = [...pricings]
    const pricingIndex = pricings.findIndex(
      (price: VariantPricing) =>
        price[fulfilmentType].id === fulfilmentPricing.id
    )
    const pricing = {
      ...newPricings[pricingIndex],
      [fulfilmentType]: {
        ...newPricings[pricingIndex][fulfilmentType],
        price: fulfilmentPricing.price,
        price_with_vat: fulfilmentPricing.price_with_vat,
        vat: fulfilmentPricing.vat
      }
    }
    newPricings[pricingIndex] = pricing
    setPricings([...newPricings])
  }

  const listVariantForVatRecomputation = (variantId: string) => {
    setVariantsForVatRecomputation([...variantsForVatRecomputation, variantId])
  }

  const VariantGeneralPricingHeader = () => (
    <Row
      align='middle'
      className='header'
      data-testid='products-general-pricing-table'
    >
      <Col span={11}>Product Name</Col>
      <Col span={13}>
        <Row gutter={[8]} align='middle' className='_mb-0'>
          <Col span={8}>Price</Col>
          <Col span={8}>VAT</Col>
          <Col span={8}>Price excl. VAT</Col>
        </Row>
      </Col>
    </Row>
  )

  const VariantFulfillmentTypePricingHeader = () => (
    <Row
      align='middle'
      className='header'
      data-testid='products-fulfillment-type-pricing-table'
    >
      <Col span={11}>Product Name</Col>
      <Col span={13}>
        <Row gutter={[8]} align='middle' className='_mb-0'>
          <Col span={8}>Delivery</Col>
          <Col span={8}>Pickup</Col>
          <Col span={8}>Order at table</Col>
        </Row>
      </Col>
    </Row>
  )

  const VariantGeneralPricingRow = ({ variant, index }: VariantRow) => {
    const { id, price, vat, options } = variant
    const optionNames = (options || []).map(({ value }: Option) => value)
    const lastItem = variants.length - 1 === index
    return (
      <>
        <Row className='_mb-0'>
          <Col span={11}>
            <VariantNameAndAvatar
              id={id}
              name={baseName}
              options={optionNames}
              testId='general-pricing-table-header'
            />
          </Col>
          <Col span={13}>
            <VariantPricingForm
              id={id}
              vat={vat}
              price={price}
              updateCorePricingHandler={updateCoreVariantsPricing}
              coreVatChangeCallbackHandler={listVariantForVatRecomputation}
            />
          </Col>
        </Row>
        {!lastItem && <Divider className='_mt-12 _mb-12' />}
      </>
    )
  }

  const VariantFulfillmentTypePricingRow = ({
    pricing,
    index
  }: VariantPricingRow) => {
    const { variant_id, delivery, pickup, order_at_table } = pricing
    const baseVariant = variants.find(({ id }: Variant) => id === variant_id)
    const options = (baseVariant?.options || []).map(
      ({ value }: Option) => value
    )
    const lastItem = pricings.length - 1 === index

    return (
      <>
        <Row className='_mb-0' key={variant_id}>
          <Col span={11} align='middle'>
            <VariantNameAndAvatar
              id={variant_id}
              name={baseName}
              options={options}
              testId='fulfillment-type-pricing-table-header'
            />
          </Col>
          <Col span={13}>
            <FulfillmentTypePricingForm
              delivery={delivery}
              pickup={pickup}
              order_at_table={order_at_table}
              variantCoreVat={variantCoreVat[variant_id]}
              successCallbackHandler={variantPricingUpdateSuccessCallback}
              flags={merchantFlags}
              updateFulfilmentTypePricingHandler={updateFulfilmentTypePricing}
            />
          </Col>
        </Row>
        {!lastItem && <Divider className='_mt-12 _mb-12' />}
      </>
    )
  }

  const toggleDisplayFulfillmentTypePricing = () => {
    const currentValue = showFulfillmentTypePricing
    setShowFulfillmentTypePricing(!currentValue)
    toggleFulfilmentTypePricings(!currentValue)
  }

  return (
    <>
      {fetchingVariants && <Loading />}
      {!fetchingVariants && (
        <>
          <Actions
            discardHandler={resetPricing}
            saveHandler={savePricings}
            loading={false}
          />
          <div className='product-pricing'>
            <Title level={5}>Your product price</Title>
            {!!variants.length && (
              <>
                <VariantGeneralPricingHeader />
                {variants.map((variant: Variant, index: number) => (
                  <VariantGeneralPricingRow
                    variant={variant}
                    key={variant.id}
                    index={index}
                  />
                ))}
              </>
            )}
            <Divider className='_mt-32 _mb-32' />
            <Row>
              <Col className='_pl-4 _pr-4'>
                <Switch
                  key={uuid()}
                  defaultChecked={showFulfillmentTypePricing}
                  value={showFulfillmentTypePricing}
                  onChange={toggleDisplayFulfillmentTypePricing}
                  data-testid='display-fulfillment-type-pricing-switch'
                />
              </Col>
              <Col span={9}>
                <Text>Assign price by fulfilment type</Text>
              </Col>
              <Col span={12}>
                <Row className='_mb-0' justify='end' gutter={[16, 0]}>
                  <Col>*price including VAT</Col>
                </Row>
              </Col>
            </Row>
            {showFulfillmentTypePricing && !!pricings.length && (
              <>
                <VariantFulfillmentTypePricingHeader />
                {pricings.map((pricing: VariantPricing, index: number) => (
                  <VariantFulfillmentTypePricingRow
                    pricing={pricing}
                    key={pricing.variant_id}
                    index={index}
                  />
                ))}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default Pricing
