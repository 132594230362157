import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import Panzoom, { PanZoom } from 'panzoom'
import { useApp } from '../contexts/App'
import { useUpdateEffect } from '../hooks/useUpdateEffect'
import { Screen } from './types'
import { SCREENS } from './constants'
import { FrameProvider } from '../components/FrameProvider'
import { CanvasThemeProvider } from './CanvasThemeProvider'
import { CanvasFontLoader } from './CanvasFontLoader'
import { CanvasGlobalStyles } from './CanvasGlobalStyles'
import { isActionKeyPressed } from './utils'

const Canvas: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const {
    zoom,
    screen,
    device,
    setZoom,
    setScreen,
    cursor,
    cursorRef,
    canvasMaxWidth,
    canvasMaxHeight,
    canvasRef,
    panzoomRef
  } = useApp()

  useEffect(() => {
    panzoomRef.current = Panzoom(containerRef.current!, {
      bounds: true,
      boundsPadding: 0,
      pinchSpeed: 1,
      zoomSpeed: 0.1,
      maxZoom: 2,
      minZoom: 0.1,
      beforeWheel: (e) => {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        const shouldIgnore = !isActionKeyPressed(e)
        return shouldIgnore
      },
      beforeMouseDown: function (e) {
        // allow mouse-down panning only if space is down. Otherwise - ignore
        var shouldIgnore = cursorRef.current === 'normal'
        return shouldIgnore
      },
      smoothScroll: false,
      initialZoom: 1
    })

    panzoomRef.current.on('zoom', (e: PanZoom) => {
      setZoom(e.getTransform().scale)
    })

    return () => {
      panzoomRef.current!.dispose()
    }
    // eslint-disable-next-line
  }, [])

  useUpdateEffect(() => {
    const transform = panzoomRef.current!.getTransform()
    panzoomRef.current!.zoomAbs(transform.x, transform.y, zoom)
  }, [zoom])

  const handleScreenSelect = (screen: Screen) => {
    return () => {
      if (cursor === 'drag') return
      setScreen(screen)
    }
  }

  const data = SCREENS.find((currentScreen) => {
    return currentScreen.key === screen
  })!
  const Component = data.component

  return (
    <Content isDragging={cursor === 'drag'} ref={canvasRef}>
      <Container
        ref={containerRef}
        width={canvasMaxWidth}
        height={canvasMaxHeight}
      >
        <FrameContainer onClick={handleScreenSelect(data.key)} key={data.key}>
          <FrameBorder isActive={data.key === screen}>
            <Frame width={canvasMaxWidth} height={canvasMaxHeight}>
              <StyledFrameProvider
                width='100%'
                id={`frame-${data.key}`}
                sizer={device}
              >
                <CanvasThemeProvider>
                  <CanvasFontLoader>
                    <FrameContentWrapper>
                      <Component />
                    </FrameContentWrapper>

                    <CanvasGlobalStyles />
                  </CanvasFontLoader>
                </CanvasThemeProvider>
              </StyledFrameProvider>
            </Frame>
          </FrameBorder>
        </FrameContainer>
      </Container>
    </Content>
  )
}

const Content = styled.div<{ isDragging: boolean }>`
  position: relative;

  display: flex;
  width: 100%;
  overflow: hidden;
  background: ${({ theme }: any) => {
    return theme.colors.gray50
  }};
  outline: 0;
  z-index: ${({ theme }: any) => theme.zIndex.content};
  ${({ isDragging }) => isDragging && `cursor: grabbing`};
`

const Container = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-wrap: nowrap;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px';
`

const FrameContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
`

const FrameBorder = styled.div<{ isActive: boolean }>`
  border: 2px solid;
  ${({ isActive }: any) =>
    isActive &&
    `
  border-color: #E7E5E9;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
`}
`

const Frame = styled.div<{ width: number; height: number | 'auto' }>`
  width: ${({ width }) => width}px;
  background: ${({ theme }: any) => theme.colors.white};
  height: ${({ height }) => (height === 'auto' ? 'auto' : `${height}px`)};
`

const StyledFrameProvider = styled(FrameProvider)`
  pointer-events: none;
`

const FrameContentWrapper = styled.div`
  overflow: hidden;
  user-select: none;
`

export { Canvas }
