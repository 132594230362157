import React, { useEffect } from 'react'

const HelpCentre = () => {
  const setScrollPosition = () => {
    document.getElementById('kayakoFrame')?.scrollTo({ top: 40 })
  }

  useEffect(() => {
    setScrollPosition()
  }, [])

  window.setTimeout(setScrollPosition, 1000)

  return (
    <iframe
      id='kayakoFrame'
      src='https://slerp.kayako.com/'
      title='kayako frame'
      style={{ width: '100%', height: '100%', border: 0, overflow: 'hidden' }}
    />
  )
}

export default HelpCentre
