import ReactGA4 from 'react-ga4'
import env from '../../../env'

export interface UserParams {
  /** Typically the object that was interacted with (e.g. 'Video') */
  category: string
  /** The type of interaction (e.g. 'play') */
  action: string
  /** Useful for categorizing events (e.g. 'Fall Campaign') */
  label?: string
}

export const initializeGA4 = () => {
  ReactGA4.initialize(env.GA4_ID)
}

export const trackUserActionsGA4 = (userParams: UserParams) => {
  ReactGA4.event(userParams)
}

export const GA4PageViewTracking = (pathname: string) => {
  ReactGA4.send({
    hitType: 'pageview',
    page: pathname
  })
}
