import { gql } from '@apollo/client'

const LAYOUT_QUERY_MERCHANT_SETTING = gql`
  query getMerchantSetting($merchant: uuid!) {
    merchants_by_pk(id: $merchant) {
      name
      marketing_modal_viewers: setting(path: "marketing_modal_viewers")
      app_settings_enabled: setting(path: "app_settings_enabled")
      app_settings_additional_tabs_enabled: setting(
        path: "app_settings_additional_tabs_enabled"
      )
      integration_settings
      registered_company_name
      subscription
      has_onboarded
    }
  }
`

const QUERY_MERCHANT_ONBOARDING_STATUS = gql`
  query merchantOnboardingStatus($id: uuid!) {
    merchantOnboardingStatus(merchantId: $id) {
      hasCard
      hasLogos
      hasDiscount
      hasKeyImages
      hasLoyaltyCard
      hasModifier
      hasProduct
      hasStore
      hasStripeSetup
    }
  }
`

const QUERY_MERCHANT_HAS_STRIPE_SUBSCRIPTION = gql`
  query checkMerchantHasStripeSubscription($id: ID) {
    merchantHasStripeSubscription(merchantId: $id)
  }
`

const QUERY_TERMS = gql`
  query getTerms {
    terms(order_by: { inserted_at: desc_nulls_last }) {
      terms_and_conditions
      id
      inserted_at
    }
  }
`

const QUERY_SIGNATURE = gql`
  query getSignature($merchantId: uuid!) {
    signatures(
      where: { merchant_id: { _eq: $merchantId } }
      order_by: { inserted_at: desc_nulls_last }
    ) {
      agreed
      terms_id
    }
  }
`

const UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS = gql`
  mutation updateMarketingModalViewersSettings(
    $merchantId: uuid!
    $setting: jsonb!
  ) {
    update_merchants(
      where: { id: { _eq: $merchantId } }
      _append: { setting: $setting }
    ) {
      affected_rows
      returning {
        marketing_modal_viewers: setting(path: "marketing_modal_viewers")
      }
    }
  }
`

const QUERY_HUBSPOT_ACCESS_TOKEN = gql`
  query hubspotToken($userId: uuid!) {
    getHubspotAccessToken(userId: $userId)
  }
`

export {
  LAYOUT_QUERY_MERCHANT_SETTING,
  UPDATE_MARKETING_MODAL_VIEWERS_SETTINGS,
  QUERY_TERMS,
  QUERY_SIGNATURE,
  QUERY_MERCHANT_HAS_STRIPE_SUBSCRIPTION,
  QUERY_HUBSPOT_ACCESS_TOKEN,
  QUERY_MERCHANT_ONBOARDING_STATUS
}
