import moment from 'moment'

export const get24HourTimeFormat = (time?: moment.Moment) => {
  if (!time) {
    return null
  }

  return time.utc(true).format('HH:mm:ss')
}

export const getTimestampWithoutTimezone = (timestamp: moment.Moment) => {
  return timestamp.utc(true).toISOString()
}

export const getLine1Address = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  const streetAddress = addressComponents.find((component) =>
    component.types.includes('street_address')
  )?.long_name
  if (streetAddress) {
    return streetAddress
  }

  const streetNo = addressComponents.find((component) =>
    component.types.includes('street_number')
  )?.long_name
  const route = addressComponents.find((component) =>
    component.types.includes('route')
  )?.long_name
  const neighborhood = addressComponents.find((component) =>
    component.types.includes('neighborhood')
  )?.long_name
  const street = route || neighborhood
  return street ? [streetNo, street].filter(Boolean).join(' ') : undefined
}

export const getLine2Address = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  const neighborhood = addressComponents.find((component) =>
    component.types.includes('neighborhood')
  )?.long_name
  const premise = addressComponents.find((component) =>
    component.types.includes('premise')
  )?.long_name
  const subpremise = addressComponents.find((component) =>
    component.types.includes('subpremise')
  )?.long_name
  const floor = addressComponents.find((component) =>
    component.types.includes('floor')
  )?.long_name
  const establishment = addressComponents.find((component) =>
    component.types.includes('establishment' || 'point_of_interest')
  )?.long_name
  const addressItems = subpremise
    ? [floor, subpremise, premise]
    : [subpremise, premise]
  return (
    [...addressItems, neighborhood].filter(Boolean).join(', ') || establishment
  )
}

export const getCity = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  const locality = addressComponents.find((component) =>
    component.types.includes('locality')
  )?.long_name
  const postalTown = addressComponents.find((component) =>
    component.types.includes('postal_town')
  )?.long_name
  return locality || postalTown
}

export const isValidUKPhoneNumber = (phone: string) => {
  return phone.length === 13 && /^(\+447|\+4420|\+441|\+442)/.test(phone)
}
