import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { SearchOutlined } from '@ant-design/icons'
import { Row, Col, Input, Form, Checkbox, Select, DatePicker } from 'antd'
import { QUERY_ORDERS } from './OrderQueries'
import OrdersList from './OrdersList'
import FormattedDate from '../Utils/FormattedDate'
import moment from 'moment-timezone'
const dateFormat = 'DD-MM-YYYY'

const Orders = () => {
  const defaultStatuses = [
    'pending',
    'accepted',
    'fulfilled',
    'refunded',
    'cancelled'
  ]

  const [startDate, setStartDate] = useState<moment.Moment | null | undefined>()
  const [endDate, setEndDate] = useState<moment.Moment | null | undefined>()
  const [statuses, setStatuses] = useState(['pending'])
  const merchant = localStorage.getItem('merchant')
  const [query, setQuery] = useState(null)
  const [lastUpdate, setLastUpdate] = useState(new Date().toUTCString())
  const [limit, setLimit] = useState(50)

  const { data, refetch, networkStatus, loading } = useQuery(QUERY_ORDERS, {
    variables: { slug: merchant, query, statuses, limit, startDate, endDate },
    pollInterval: 60000,
    notifyOnNetworkStatusChange: true
  })

  // No longer being used
  const reloadOrders = () => {
    refetch({
      slug: merchant,
      query,
      statuses,
      limit,
      startDate,
      endDate
    })
  }

  const updateStatuses = (checkedValues: any) => {
    setStatuses(checkedValues)
  }

  const updateLimit = (value: any) => {
    setLimit(value)
  }

  const changeQuery = (e: any) => {
    const newValue = e.target.value ? e.target.value : null
    setQuery(newValue)
  }

  const changeDate = (e: any) => {
    if (e) {
      console.log(e)
      setStartDate(moment(e).startOf('day'))
      setEndDate(moment(e).endOf('day'))
    } else {
      setStartDate(null)
      setEndDate(null)
    }
  }

  useEffect(() => {
    setLastUpdate(new Date().toUTCString())
  }, [networkStatus])

  return (
    <Form onFinish={reloadOrders}>
      <Row>
        <Col span={0}></Col>
        <Col span={24}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Col span={7}>
              <Row>
                <Col span={12}>
                  <Form.Item label='Date' name='date'>
                    <DatePicker
                      defaultValue={startDate ? startDate : undefined}
                      format={dateFormat}
                      onChange={changeDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Search' name='query'>
                    <Input
                      prefix={
                        <SearchOutlined className='site-form-item-icon' />
                      }
                      placeholder='e.g. GQYMZY'
                      onChangeCapture={changeQuery}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={9}>
              <Form.Item>
                <Checkbox.Group
                  options={defaultStatuses}
                  defaultValue={statuses}
                  onChange={updateStatuses}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label='Limit results'>
                <Select onChange={updateLimit} defaultValue={limit}>
                  <Select.Option value={20}>20</Select.Option>
                  <Select.Option value={50}>50</Select.Option>
                  <Select.Option value={100}>100</Select.Option>
                  <Select.Option value={500}>500</Select.Option>
                  <Select.Option value={1000}>1000</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                Last Update:
                <FormattedDate>{lastUpdate}</FormattedDate>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <OrdersList
                orders={data && data.orders}
                loading={loading}
                refetch={refetch}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default Orders
