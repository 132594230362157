import React, { useState, createRef } from 'react'
import { Divider, Form, Select } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { filterSelectOption } from '@slerp/antd-utils'
import { ModifierGroup } from './index'
import ModifierGroupForm from '../ModifierGroups/Form'

const { Option } = Select

const ModifierGroupsField = ({
  modifierGroups,
  selectedGroups,
  setSelectedGroups,
  setModifierGroups
}: {
  modifierGroups: ModifierGroup[]
  selectedGroups: string[]
  setSelectedGroups: (ids: string[]) => void
  setModifierGroups: (groups: ModifierGroup[]) => void
}) => {
  const createGroupButton = createRef<HTMLButtonElement>()
  const [modifierOptions, setModifierOptions] = useState<ModifierGroup[]>([])
  const [createMode, setCreateMode] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const placeholder =
    modifierGroups.length > 0
      ? 'Type to search or create a modifier group'
      : 'Type to create your first modifier group'

  const showCreateGroupButton = (
    searchValue: string,
    options: { children: string }[]
  ) =>
    options.length === 0 ||
    !options.find(
      (option: { children: string }) => searchValue === option.children
    )

  const filterOptions = (value: string) =>
    modifierGroups.filter(
      (group) => group.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
    )

  return (
    <Form.Item name='modifier_groups'>
      <Select
        showSearch
        mode='multiple'
        placeholder={placeholder}
        className='width-100'
        optionFilterProp='name'
        filterOption={filterSelectOption}
        data-testid='modifier-form-modifier-group'
        defaultActiveFirstOption={false}
        disabled={createMode}
        onSearch={(value) => {
          setSearchValue(value)
          setModifierOptions(filterOptions(value))
        }}
        onChange={(value) => {
          setSelectedGroups(value)
          setSearchValue('')
        }}
        notFoundContent={<></>}
        onMouseDown={(e) => {
          e.preventDefault()
          return false
        }}
        value={selectedGroups}
        onInputKeyDown={(e) => {
          // Triggers category creation on enter
          // when there are no available options
          if (e.key === 'Enter' && modifierOptions.length === 0)
            createGroupButton.current?.click()
        }}
        searchValue={searchValue}
        dropdownRender={(menu) => {
          const { searchValue, options } = menu.props
          return (
            <div>
              <div className='select-option-guide'>
                <p className='text'>{placeholder}</p>
              </div>
              {(modifierGroups.length > 0 || searchValue.length > 0) && (
                <Divider className='_my-0' />
              )}
              {showCreateGroupButton(searchValue, options) && (
                <>
                  <button
                    className={`select-option-extra ${
                      options.length === 0 ? '-active' : ''
                    }`}
                    data-testid='modifier-form-group-create-button'
                    onClick={() => setCreateMode(true)}
                    ref={createGroupButton}
                  >
                    <div className='icon'>
                      <PlusCircleOutlined />
                    </div>
                    <p className='text'>
                      Create {searchValue.length > 0 ? `"${searchValue}"` : 'a'}{' '}
                      modifier group
                    </p>
                    {options.length === 0 && (
                      <p className='help'>Press enter</p>
                    )}
                  </button>
                  {options.length > 0 && <Divider className='_my-0' />}
                </>
              )}
              {menu}
            </div>
          )
        }}
      >
        {modifierGroups.map((modifierGroup: ModifierGroup) => {
          const { id, name, description } = modifierGroup
          return (
            <Option
              value={id}
              key={`modifier-form-modifier-group-${id}`}
              data-testid={`modifier-form-modifier-group-${id}`}
            >
              {`${name} (${description})`}
            </Option>
          )
        })}
      </Select>
      {createMode && (
        <div className='modifier-group-form-container'>
          <h2>Create modifier group</h2>
          <ModifierGroupForm
            onCancel={() => {
              setCreateMode(false)
              setSearchValue('')
            }}
            showModifierItemFields={false}
            showLimitFields={false}
            onEndSave={(res) => {
              if (res?.data?.insert_modifier_groups?.returning?.length > 0) {
                const newlyCreatedGroup =
                  res?.data?.insert_modifier_groups?.returning[0]
                setModifierGroups([newlyCreatedGroup, ...modifierGroups])
                setCreateMode(false)
                // I couldn't make the form.setFieldValue work for this
                // So I used a separate state :(
                setSelectedGroups([...selectedGroups, newlyCreatedGroup.id])
                setSearchValue('')
              }
            }}
            modifierGroupInfo={{
              name: searchValue || '',
              id: '',
              description: '',
              sku: '',
              minimum: 0,
              minimum_enabled: false,
              maximum: 0,
              maximum_enabled: false,
              archived_at: null,
              modifier_arrangement: {},
              modifier_group_modifiers: []
            }}
          />
        </div>
      )}
    </Form.Item>
  )
}

export default ModifierGroupsField
