import React from 'react'
import { useQuery } from '@apollo/client'
import { Result, Skeleton } from 'antd'

import OrderDetails from './OrderDetails'
import { QUERY_ORDER } from './OrderQueries'

interface OrderInfoProps {
  order: any
  ordersRefetch?: () => {}
  ordersDataRefetch?: () => {}
}

const OrderInfo = (props: OrderInfoProps) => {
  const { order, ordersRefetch, ordersDataRefetch } = props

  const { data, loading, refetch } = useQuery(QUERY_ORDER, {
    variables: { transactionId: order.transaction_id }
  })

  return (
    <>
      {loading && <Skeleton active />}
      {data && data.message === null && <Result status='500' title='500' />}
      {data && data.orders && (
        <OrderDetails
          order={data.orders[0]}
          orderRefetch={refetch}
          ordersListRefetch={ordersRefetch}
          ordersDataRefetch={ordersDataRefetch}
        />
      )}
    </>
  )
}

export default OrderInfo
