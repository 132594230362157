import React, { useState } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { Col, Row, message, Tabs } from 'antd'
import VariantGroupForm from './Forms/VariantGroupForm'
import VariantsList from './List'
import { useSession } from '@slerp/accounts'
import {
  GET_MERCHANT_PRODUCT_VARIANTS,
  GET_PRODUCT_DETAILS
} from 'components/ProductsAndModifiers/Products/ProductQueries'
import VariantGroupsWithAllergens from './VariantGroupsWithAllergens'
import Busy from 'components/Utils/Busy'
import { Product } from '../types'

interface VariantGroupsProps {
  productId: string
}

const { TabPane } = Tabs

const VariantGroups = ({ productId }: VariantGroupsProps) => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const { data: merchantProductVariantData, loading: fetchingVariants } =
    useQuery(GET_MERCHANT_PRODUCT_VARIANTS, {
      variables: {
        merchantId: merchant.id,
        productId
      }
    })
  const { data: productData, loading: fetchingProduct } = useQuery<{
    products_by_pk: Product
  }>(GET_PRODUCT_DETAILS, {
    variables: {
      productId
    }
  })
  const [isCreatingVariantGroup, setIsCreatingVariantGroup] =
    useState<boolean>(false)
  const variants = merchantProductVariantData?.product_variants
  const product = productData?.products_by_pk
  const showVariantGroupsSection =
    !isCreatingVariantGroup && !fetchingProduct && !fetchingVariants

  return (
    <>
      <Row data-testid='product-variant-groups-header'>
        <Col>
          <Row className='_mb-0'>
            <Col>
              <span className='_cl-text'>Create variants</span>
            </Col>
          </Row>
          <Row className='_mb-0'>
            <Col>
              <span className='_cl-text-secondary-75'>
                Here you can create variants of your product. For example if
                your product ranges in size, you can add Small, Medium, Large
                versions of your product.
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      {!fetchingVariants && (
        <VariantGroupForm
          productOptions={product?.options}
          productId={productId}
          onStartSave={() => {
            setIsCreatingVariantGroup(true)
            message.destroy()
            message.loading(`Creating variants...`, 3)
          }}
          onEndSave={() => {
            setIsCreatingVariantGroup(false)
            message.destroy()
            message.success(`Variants created!`, 1)
            client.resetStore()
          }}
          onSaveFail={(error: Error) => {
            setIsCreatingVariantGroup(false)
            message.destroy()
            message.error(
              `Unable to create variants due to ${error.message}`,
              3
            )
          }}
        />
      )}
      {isCreatingVariantGroup && <Busy title='Creating variant group...' />}
      {showVariantGroupsSection && (
        <Row>
          <Col span={24}>
            <Tabs
              defaultActiveKey='1'
              type='card'
              data-testid='product-variant-groups-control-tab-container'
            >
              <TabPane tab='Variant Groups' key='1'>
                <VariantGroupsWithAllergens
                  product={product}
                  variants={variants}
                />
              </TabPane>
              <TabPane tab='Generated Variants' key='2'>
                <VariantsList variants={variants} baseName={product?.name} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  )
}

export default VariantGroups
