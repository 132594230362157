import React, { useState } from 'react'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Col, Form, Input, Button, Checkbox, Alert, Spin } from 'antd'
import './LoginForm.css'
import logo from '../../logo.png'
import { useSession, Session } from '@slerp/accounts'
import errorReducer from 'errors/errorReducer'
import env from '../../env'
import { usePageViewTracker } from 'packages/@slerp/tracker'
interface AuthenticationToken {
  token: undefined | string
}

const LoginForm = () => {
  usePageViewTracker()
  const { authenticate, createSession } = useSession()
  const [form] = Form.useForm()
  const [message, setMessage] = useState<{
    type: 'error' | 'success' | 'info' | 'warning' | undefined
    message: string
  }>()
  const [buttonLabel, setButtonLabel] = useState(<>Sign In to Controls</>)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const handleError = (error?: Error) => {
      setMessage({
        message: error?.message || 'Unable to sign in. Please try again.',
        type: 'error'
      })
      setButtonLabel(<>Sign In to Controls</>)
    }

    form
      .validateFields()
      .then(async (values) => {
        setButtonLabel(
          <>
            Signing in <Spin style={{ color: 'white' }} />
          </>
        )

        return authenticate(values.email, values.password)
      })
      .then((authenticate: AuthenticationToken) => {
        return createSession(authenticate?.token)
      })
      .then((session: Session) => {
        const { user, token, merchant } = session

        if (merchant.dashboard_version === 1) {
          setMessage({
            message:
              'Please finish your onboarding. Redirecting you in a moment..',
            type: 'error'
          })
          window.setTimeout(() => {
            window.location.href = `https://${env.ASSET_HOST}/controls/login`
          }, 2500)
        } else if (user && token && merchant) {
          setMessage({ message: 'Logged in successfully!', type: 'success' })
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('merchant', JSON.stringify(merchant))

          window.setTimeout(() => {
            window.location.pathname = '/'
          }, 1000)
        } else {
          handleError()
        }
      })
      .catch((error: Error | object) => {
        if (error instanceof Error) {
          throw errorReducer({
            origin: 'User',
            data: {
              error: error,
              message: error.message
            }
          })
        }

        throw error
      })
      .catch((error: Error) => {
        console.warn(error) // Print what's happening.
        handleError(error)
      })
  }

  const ShowError = () => {
    return message && message.message ? (
      <>
        <Alert message={message.message} type={message.type} showIcon />
        <br />
      </>
    ) : (
      <></>
    )
  }

  return (
    <Col span={24}>
      <div className='login-container'>
        <Form className='login-form' form={form}>
          <div className='login-logo'>
            <img src={logo} alt='Slerp' />
            <h1>Sign in to Slerp Controls</h1>
          </div>
          <ShowError />
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Please input your username.' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Email'
              size='large'
              autoFocus
              data-testid='emailInput'
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: 'Please input your password.' }]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type='password'
              placeholder='Password'
              size='large'
              data-testid='passwordInput'
            />
          </Form.Item>

          <Form.Item>
            <Button size='small' type='link' href={'/forgot_password'}>
              Forgot your password?
            </Button>
          </Form.Item>

          <Form.Item name='remember' valuePropName='checked'>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            onClick={handleSubmit}
            size='large'
          >
            {buttonLabel}
          </Button>
        </Form>
      </div>
    </Col>
  )
}

export default LoginForm
