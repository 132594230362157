import styled from '@emotion/styled'

export const AttributionFlexBox = styled.div(
  ({ maxHeight }: { maxHeight: string }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    gap: '16px',
    padding: '0 16px',
    maxHeight
  })
)

export const AttributionCard = styled.div(() => ({
  backgroundColor: '#E7E5E9',
  padding: '2px 8px',
  borderRadius: '4px',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const Paragraph = styled.p(() => ({
  textWrap: 'nowrap',
  fontWeight: 500,
  margin: 0,
  lineHeight: '20px'
}))

export const CardTitle = styled(Paragraph)(() => ({
  color: '#8C8CA1'
}))

export const CardValue = styled(Paragraph)(() => ({
  color: '#4A4A68'
}))
