import moment, { Moment } from 'moment'
import {
  AddressHash,
  DateTimeSchedule,
  RebookFormType,
  Timeslot,
  ValidStore
} from '../types'
import {
  CustomerDetails,
  Delivery,
  OrderInfo
} from 'components/Orders/Info/type'
import { User } from 'packages/@slerp/accounts'
import { Store } from 'components/Orders/Info/type'
import { SplitHour } from '@slerp/controls'

export const dateFormat = 'YYYY-MM-DD'

export const getCurrentStore = (
  id: string,
  stores: ValidStore[]
): ValidStore | null => {
  if (!stores.length) {
    return null
  }

  return stores.find((store) => store.id === id) || stores[0]
}

export const isDateDisabled = (splitHours?: SplitHour[]) => {
  const { daysOpened, specialClosed, specialOpened } =
    splitHoursToSchedule(splitHours)

  return (current: Moment) => {
    const today = moment()

    if (today.add(-1, 'days') >= current) {
      return true
    }

    const currentDate = moment(current).format(dateFormat)

    if (specialClosed.includes(currentDate)) {
      return true
    }

    if (specialOpened.includes(currentDate)) {
      return false
    }

    return !daysOpened.includes(current.day())
  }
}

const daysOfWeekMap = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6
}

const splitHoursToSchedule = (splitHours?: SplitHour[]): DateTimeSchedule => {
  if (!splitHours) {
    return { daysOpened: [], specialClosed: [], specialOpened: [] }
  }

  return splitHours.reduce<DateTimeSchedule>(
    (acc, cur) => {
      if (!cur.enabled || (cur.closed && !cur.special_date)) {
        return acc
      }

      if (cur.closed && cur.special_date) {
        return {
          ...acc,
          specialClosed: [...acc.specialClosed, cur.special_date]
        }
      }

      if (cur.special_date) {
        return {
          ...acc,
          specialOpened: [...acc.specialOpened, cur.special_date]
        }
      }

      const dayOfWeekIndex = daysOfWeekMap[cur.day_of_week]
      const daysOpenedUpdate = acc.daysOpened.includes(dayOfWeekIndex)
        ? acc.daysOpened
        : [...acc.daysOpened, dayOfWeekIndex]

      return {
        ...acc,
        daysOpened: daysOpenedUpdate
      }
    },
    { daysOpened: [], specialClosed: [], specialOpened: [] }
  )
}

export const stringDate = (dateTime: string | null) => {
  if (!dateTime || dateTime === 'immediately') {
    return moment().format(dateFormat)
  }

  return moment(dateTime).format(dateFormat)
}

export const humanizeTimeslot = (timeslot: Timeslot): string => {
  if (timeslot.value === 'asap') {
    return 'ASAP'
  }

  const startTime = timeslot.startTime.slice(0, 5)
  const endTime = timeslot.endTime.slice(0, 5)

  return `${startTime} - ${endTime}`
}

export const parseContactNumberForDisplay = (contactNumber: string = '') => {
  return contactNumber.replace('+', '')
}

export const constructAddressHash = (
  customerDetails: CustomerDetails | null
): AddressHash => {
  const { address } = customerDetails || {}

  return {
    address: [address?.line_1, address?.line_2, address?.city, address?.zip]
      .filter((item) => Boolean(item))
      .join(', '),
    line1: address?.line_1 || '',
    line2: address?.line_2 || '',
    city: address?.city || '',
    country: address?.country || '',
    postalCode: address?.zip || ''
  }
}

export const buildRebookingVariables = (
  formValues: RebookFormType,
  customerDetails: CustomerDetails | null,
  transactionId: string,
  user: User,
  useDeliveryNotes: boolean
) => {
  const {
    first_name,
    last_name,
    contact_num,
    drop_off,
    location,
    vehicle,
    delivery_time,
    delivery_date,
    delivery_notes,
    reason,
    reason_notes
  } = formValues

  const fulfillmentTime =
    delivery_time === 'asap'
      ? 'immediately'
      : moment(delivery_time).utc().format('HH:mm')

  const fulfillmentDate = stringDate(
    delivery_time === 'asap' ? null : delivery_date
  )

  return {
    address: {
      line_1: drop_off.line1,
      line_2: drop_off.line2,
      country: customerDetails?.address?.country,
      zip: drop_off.postalCode,
      city: drop_off.city,
      location_type: '',
      contact_num: `+${contact_num}`
    },
    recipient: {
      first_name,
      last_name,
      contact_num: `+${contact_num}`
    },
    transactionId,
    vehicle,
    currentUserId: user.id,
    storeId: location,
    fulfillmentTime,
    fulfillmentDate,
    dropoffNotes: useDeliveryNotes ? delivery_notes : null,
    reason: reason,
    reasonNotes: reason === 'OTHER' ? reason_notes : null
  }
}

export const buildDeliveryCostQuoteVariables = (
  order: OrderInfo,
  formValues: RebookFormType
) => {
  return {
    quoteParams: {
      transactionId: order.transaction_id,
      vehicle: formValues.vehicle,
      storeId: formValues.location,
      address: {
        line_1: formValues.drop_off.line1,
        line_2: formValues.drop_off.line2,
        city: formValues.drop_off.city,
        zip: formValues.drop_off.postalCode
      }
    }
  }
}

export const buildGetOrderQuoteVariables = (
  order: OrderInfo,
  delivery: Delivery,
  store: Store,
  customerDetails: CustomerDetails | null
) => {
  return {
    quoteParams: {
      transactionId: order.transaction_id,
      vehicle: delivery?.default_transport_type,
      storeId: store.id,
      address: {
        line_1: customerDetails?.address?.line_1,
        line_2: customerDetails?.address?.line_2,
        city: customerDetails?.address?.city,
        zip: customerDetails?.address?.zip
      }
    }
  }
}
