import React, { useState } from 'react'
import { Col, Row, Tabs } from 'antd'
import LoyaltyOverview from './LoyaltyOverview'
import LoyaltyCards from './LoyaltyCards'
import LoyaltyBonusContainer from './LoyaltyBonusContainer'

const { TabPane } = Tabs

const Loyalty = () => {
  const [activeKey, setActiveKey] = useState('overview')

  return (
    <>
      <Row>
        <Col span={24}>
          <Tabs
            type='card'
            activeKey={activeKey}
            onChange={(value: string) => setActiveKey(value)}
            data-testid='loyaltyIndex'
            destroyInactiveTabPane
          >
            <TabPane
              tab={<a data-testid='loyalty-overview-tab-pane'>OVERVIEW</a>}
              key='overview'
            >
              <LoyaltyOverview />
            </TabPane>
            <TabPane
              tab={<a data-testid='loyalty-cards-tab-pane'>YOUR CARDS</a>}
              key='loyaltyCards'
            >
              <LoyaltyCards />
            </TabPane>
            <TabPane
              tab={
                <a data-testid='loyalty-bonuses-tab-pane'>LOYALTY BONUSES</a>
              }
              key='loyaltyBonuses'
            >
              <LoyaltyBonusContainer />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default Loyalty
