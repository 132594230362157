import { useState, useEffect } from 'react'
import { Drawer, message, Form } from 'antd'
import { useMutation } from '@apollo/client'
import LoyaltyBonusForm from './LoyaltyBonusForm'
import { CREATE_LOYALTY_BONUS, UPDATE_LOYALTY_BONUS } from './LoyaltyQueries'
import { uuid } from 'uuidv4'

const { useForm } = Form
const LoyaltyBonusFormDrawer = (props) => {
  const {
    stores,
    loyaltyCards,
    merchant,
    drawerVisible,
    closeFormDrawer,
    refetch,
    loyaltyBonus
  } = props

  const [insertLoyaltyBonus] = useMutation(CREATE_LOYALTY_BONUS)
  const [updateLoyaltyBonus] = useMutation(UPDATE_LOYALTY_BONUS)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  useEffect(() => {
    if (loyaltyBonus) {
      form.setFieldsValue({
        ...loyaltyBonus,
        loyalty_card_id: loyaltyBonus?.loyalty_card?.id,
        store_id: loyaltyBonus?.store?.id
      })
    } else {
      form.resetFields()
    }
  }, [loyaltyBonus?.id])

  const createLoyaltyBonus = (values) => {
    message.loading('Creating loyalty bonus. Please wait...')
    setSubmitDisabled(true)
    insertLoyaltyBonus({
      variables: { ...values, id: uuid(), merchant_id: merchant?.id }
    })
      .then(() => {
        message.destroy()
        message.success('Successfully created loyalty bonus!', 3)
        refetch()
        closeFormDrawer()
        form.resetFields()
        setSubmitDisabled(false)
      })
      .catch((error) => {
        console.error(error)
        message.destroy()
        message.error(
          'Unable to create loyalty bonus. Please contact support@slerp.com',
          3
        )
        setSubmitDisabled(false)
      })
  }

  const updateBonus = (values) => {
    message.loading('Updating loyalty bonus. Please wait...')
    setSubmitDisabled(true)
    updateLoyaltyBonus({
      variables: { ...values, id: loyaltyBonus?.id }
    })
      .then(() => {
        message.destroy()
        message.success('Successfully updated loyalty bonus!', 3)
        refetch()
        closeFormDrawer()
        form.resetFields()
        setSubmitDisabled(false)
      })
      .catch((error) => {
        console.error(error)
        message.destroy()
        message.error(
          'Unable to update loyalty bonus. Please contact support@slerp.com',
          3
        )
        setSubmitDisabled(false)
      })
  }

  const [form] = useForm()
  return (
    <Drawer
      visible={drawerVisible}
      onClose={() => {
        closeFormDrawer()
      }}
      width={720}
      destroyOnClose={true}
      footer={null}
      className='loyalty-bonus-drawer'
    >
      <LoyaltyBonusForm
        closeFormDrawer={closeFormDrawer}
        createBonus={createLoyaltyBonus}
        updateBonus={updateBonus}
        stores={stores}
        loyaltyCards={loyaltyCards}
        submitDisabled={submitDisabled}
        bonus={loyaltyBonus}
        form={form}
      />
    </Drawer>
  )
}

export default LoyaltyBonusFormDrawer
