import React, { useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Col, Form, Input, Button, Alert, Spin } from 'antd'
import './LoginForm.css'
import logo from '../../logo.png'
import { useSession } from '@slerp/accounts'
import errorReducer from 'errors/errorReducer'
import { usePageViewTracker } from 'packages/@slerp/tracker'

interface RequestPasswordChangeResponse {
  result: string
}

const ForgotPasswordForm = () => {
  usePageViewTracker()
  document.title = 'Slerp Controls - Forgot Password'
  const { requestPasswordChange } = useSession()
  const [form] = Form.useForm()
  const [message, setMessage] = useState<{
    type: 'error' | 'success' | 'info' | 'warning' | undefined
    message: string
  }>()
  const [buttonLabel, setButtonLabel] = useState(<>Submit</>)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const handleError = (error?: Error) => {
      setMessage({
        message:
          error?.message ||
          'Unable to request for password change. Please try again.',
        type: 'error'
      })
      setButtonLabel(<>Submit</>)
    }

    form
      .validateFields()
      .then(async (values) => {
        setButtonLabel(
          <>
            Requesting <Spin style={{ color: 'white' }} />
          </>
        )

        return requestPasswordChange(values.email)
      })
      .then((response: RequestPasswordChangeResponse) => {
        const { result } = response
        setMessage({
          message: result,
          type: 'success'
        })
        setButtonLabel(<>Submit</>)
      })
      .catch((error: Error | object) => {
        if (error instanceof Error) {
          throw errorReducer({
            origin: 'UserPassword',
            data: {
              error: error,
              message: error.message
            }
          })
        }

        throw error
      })
      .catch((error: Error) => {
        console.warn(error) // Print what's happening.
        handleError(error)
      })
  }

  const ShowError = () => {
    return message && message.message ? (
      <>
        <Alert message={message.message} type={message.type} showIcon />
        <br />
      </>
    ) : (
      <></>
    )
  }

  return (
    <Col span={24}>
      <div className='login-container'>
        <Form className='login-form' form={form}>
          <div className='login-logo'>
            <img src={logo} alt='Slerp' />
            <h1>Password Reset</h1>
            <h3>
              Please enter your email and a reset link will be sent your way.
            </h3>
          </div>
          <ShowError />
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Please input your email.' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Email'
              size='large'
              autoFocus
              data-testid='emailInput'
            />
          </Form.Item>

          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            onClick={handleSubmit}
            size='large'
          >
            {buttonLabel}
          </Button>
        </Form>
      </div>
    </Col>
  )
}

export default ForgotPasswordForm
