import React from 'react'
import styled from '@emotion/styled'

import { useApp } from '../contexts/App'
import { SCREENS } from './constants'
import { Text } from '../components/Text'

import { Tooltip } from 'antd'

const PagePills: React.FC = () => {
  const { screen: activeScreen, setScreen, page } = useApp()

  if (page === 'messages') return <></>

  return (
    <Nav>
      {SCREENS.map((screen) => (
        <Tooltip title={screen.tooltipTitle}>
          <PillButton
            isActive={activeScreen === screen.key}
            onClick={() => setScreen(screen.key)}
            key={screen.key}
          >
            <Text weight='300' size='sm'>
              {screen.name}
            </Text>
          </PillButton>
        </Tooltip>
      ))}
    </Nav>
  )
}

const Nav = styled.div`
  display: flex;
  border-radius: 4px;
`

const PillButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 120px;
  color: ${({ theme, isActive }: any) =>
    isActive ? theme.colors.white : theme.colors.subtitle};
  background: ${({ theme, isActive }: any) =>
    isActive ? theme.colors.primary : 'transparent'};
  border: 0;
  border-radius: 4px;
  cursor: pointer;
`

export { PagePills }
