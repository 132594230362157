import { Cutoff, Cutoffs, Schedules, Store } from '@slerp/controls'
import { uuid } from 'uuidv4'

const initialScheduleValue = {
  enabled: false,
  start: null,
  end: null,
  special_date: null
}

export const initialSchedules = {
  monday: { id: uuid(), ...initialScheduleValue },
  tuesday: { id: uuid(), ...initialScheduleValue },
  wednesday: { id: uuid(), ...initialScheduleValue },
  thursday: { id: uuid(), ...initialScheduleValue },
  friday: { id: uuid(), ...initialScheduleValue },
  saturday: { id: uuid(), ...initialScheduleValue },
  sunday: { id: uuid(), ...initialScheduleValue }
}

export const formatSchedules = (schedules: Schedules) => {
  if (schedules) return schedules
  return initialSchedules
}

const initialCutoffValue = {
  enabled: false,
  time: '23:59:00',
  day_diff: 1,
  cutoff_date: null
}

export const initialCutoffs = {
  monday: { id: uuid(), ...initialCutoffValue },
  tuesday: { id: uuid(), ...initialCutoffValue },
  wednesday: { id: uuid(), ...initialCutoffValue },
  thursday: { id: uuid(), ...initialCutoffValue },
  friday: { id: uuid(), ...initialCutoffValue },
  saturday: { id: uuid(), ...initialCutoffValue },
  sunday: { id: uuid(), ...initialCutoffValue }
}

export const formatCutoffs = (cutoffs?: Cutoffs) => {
  if (cutoffs)
    return {
      monday: formatCutoff(cutoffs.monday),
      tuesday: formatCutoff(cutoffs.tuesday),
      wednesday: formatCutoff(cutoffs.wednesday),
      thursday: formatCutoff(cutoffs.thursday),
      friday: formatCutoff(cutoffs.friday),
      saturday: formatCutoff(cutoffs.saturday),
      sunday: formatCutoff(cutoffs.sunday)
    }
  return initialCutoffs
}

const formatCutoff = (cutoff: Cutoff) => ({
  ...cutoff,
  time: cutoff.time ? cutoff.time : '23:59:00',
  day_diff: cutoff.day_diff ? cutoff.day_diff : 1,
  enabled:
    !!cutoff.enabled && typeof cutoff.day_diff === 'number' && !!cutoff.time
})

export const getUnit = (allowance: number) => {
  if (allowance % 30 === 0) return 'month'
  if (allowance % 7 === 0) return 'week'
  return 'day'
}

export const getInitialAmount = (allowance: number, unit: string) => {
  if (unit === 'month') return allowance / 30
  if (unit === 'week') return allowance / 7
  return allowance
}

export const computeAllowance = (amount: number, unit: string) => {
  if (unit === 'month') return amount * 30
  if (unit === 'week') return amount * 7
  return amount
}

export const getStorePreorderSettings = (store: Store) =>
  store.pre_order_settings?.length > 0 ? store.pre_order_settings[0] : null

export const getEnabledCutoffs = (
  cutoffs: { [key: string]: Cutoff } | { id?: string }
) =>
  Object.values(cutoffs).filter((cutoff) => {
    if (typeof cutoff === 'string') return false // the cutoffs id thingy
    return cutoff?.enabled
  })
