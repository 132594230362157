import React, { useState } from 'react'
import {
  Divider,
  message,
  Form,
  PageHeader,
  Switch,
  Checkbox,
  Button,
  Row,
  Col,
  Input,
  Space,
  Tooltip
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { UPDATE_VEHICLE_SETTINGS } from './actions'
import { useMutation } from '@apollo/client'
import UpdateNotice from '../Utils/UpdateNotice'
import { SettingProps, VehiclesSettings } from '@slerp/controls'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'
const { useForm } = Form

type VehicleTypes = 'bike' | 'motorbike' | 'car'

const Delivery = (props: SettingProps) => {
  const { merchant } = props
  const [form] = useForm()

  const [updateVehicleSetting, { loading, error }] = useMutation(
    UPDATE_VEHICLE_SETTINGS,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const onFinish = (values: any) => {
    const { vehicles, pre_order_vehicles } = values
    if (form.validateFields()) {
      message.loading('Updating... Please wait.')
      updateVehicleSetting({
        variables: {
          id: merchant.id,
          vehicles,
          pre_order_vehicles
        }
      }).then((result) => {
        message.destroy()
        message.success('Vehicle settings updated!', 1)
      })
    }
  }

  const validateVehicleSettings = (_: any, values: VehiclesSettings) => {
    const enabledBikeEmpty = values?.bike?.enabled && !values?.bike?.threshold
    const enabledMotorbikeEmpty =
      values?.motorbike?.enabled &&
      values?.car?.enabled &&
      !values?.motorbike?.threshold
    // no need to check for car's threshold, it is always empty
    const emptyAssignedValues =
      !values?.optional_threshold && (enabledBikeEmpty || enabledMotorbikeEmpty)

    const noVehicleSelected = !!!(
      values?.bike?.enabled ||
      values?.motorbike?.enabled ||
      values?.car?.enabled
    )

    const enabledCount = [
      values?.bike?.enabled,
      values?.motorbike?.enabled,
      values?.car?.enabled
    ].filter((enabled) => enabled).length
    const lessThanTwoVehiclesForAssign =
      !values?.optional_threshold && enabledCount < 2

    const bikeMinValue = values?.bike?.enabled ? 5 : null
    const getMotorbikeMinValue = () => {
      if (values?.motorbike?.enabled && values?.car?.enabled) {
        if (values?.bike?.enabled)
          return values?.bike?.threshold >= 5
            ? Number(values?.bike?.threshold) + 5
            : 10
        return 5
      }
      return null
    }
    const motorbikeMinValue = getMotorbikeMinValue()
    const lessThanBikeMin =
      bikeMinValue && Number(values?.bike?.threshold) < bikeMinValue
    const lessThanMotorbikeMin =
      motorbikeMinValue &&
      Number(values?.motorbike?.threshold) < motorbikeMinValue

    if (noVehicleSelected)
      return Promise.reject(
        new Error('At least one vehicle type must be selected.')
      )
    if (lessThanTwoVehiclesForAssign)
      return Promise.reject(
        new Error(
          'At least 2 vehicles are required to use assigned vehicle by order value. Please disable assigned vehicle by order value or select at least 2 vehicles.'
        )
      )
    if (!values?.optional_threshold && lessThanBikeMin)
      return Promise.reject(
        new Error(`Value for bike must be above or equal to £${bikeMinValue}`)
      )
    if (!values?.optional_threshold && lessThanMotorbikeMin)
      return Promise.reject(
        new Error(
          `Value for motorbike must be above or equal to £${motorbikeMinValue}`
        )
      )
    if (emptyAssignedValues)
      return Promise.reject(new Error('Please fill up the required values.'))

    return Promise.resolve()
  }

  return (
    <Col span={12}>
      <div className='payments-and-couriers-wrapper'>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            vehicles: merchant.vehicles,
            pre_order_vehicles: merchant.pre_order_vehicles
          }}
        >
          <PageHeader
            title={
              <>
                Vehicle settings for same day orders
                <Tooltip
                  title={
                    <span>
                      This is for your on-demand/ASAP delivery orders.
                    </span>
                  }
                >
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          />
          <h3 style={{ padding: '0' }}>Vehicle Settings</h3>
          <Form.Item
            name='vehicles'
            rules={[
              {
                validator: validateVehicleSettings
              }
            ]}
          >
            <VehicleForm />
          </Form.Item>

          <Divider />
          <PageHeader
            title={
              <>
                Vehicle settings for pre-orders
                <Tooltip title={<span>This is for your pre-orders.</span>}>
                  <QuestionCircleOutlined className='_ml-8' />
                </Tooltip>
              </>
            }
          />
          <h3 style={{ padding: '0' }}>Vehicle Settings</h3>
          <Form.Item
            name='pre_order_vehicles'
            rules={[
              {
                validator: validateVehicleSettings
              }
            ]}
          >
            <VehicleForm />
          </Form.Item>
          <Divider></Divider>

          <Form.Item>
            <div className='courier-settings-submit'>
              <Button
                title='Save'
                type='primary'
                size='large'
                htmlType='submit'
              >
                Save
              </Button>
              <UpdateNotice updating={loading} updateError={error} />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Col>
  )
}

const VehicleForm = ({
  value,
  onChange
}: {
  value?: VehiclesSettings
  onChange?: () => {}
}) => {
  // optional_threshold true = switch not checked
  const [thresholdEnabled, setThresholdEnabled] = useState(
    !!!value?.optional_threshold
  )
  const [bikeEnabled, setBikeEnabled] = useState(value?.bike?.enabled)
  const [motorbikeEnabled, setMotorbikeEnabled] = useState(
    value?.motorbike?.enabled
  )
  const [carEnabled, setCarEnabled] = useState(value?.car?.enabled)

  const [bikeThreshold, setBikeThreshold] = useState(value?.bike?.threshold)
  const [motorbikeThreshold, setMotorbikeThreshold] = useState(
    value?.motorbike?.threshold
  )

  const handleOptionalThresholdChange = (checked: boolean) => {
    setThresholdEnabled(checked)

    // @ts-ignore onChange from antd can be undefined
    onChange?.({
      ...value,
      // optional_threshold true = switch not checked
      optional_threshold: !!!checked
    })
  }

  const handleVehicleEnabledChange = (
    vehicle: VehicleTypes,
    event: CheckboxChangeEvent
  ) => {
    if (vehicle === 'bike') setBikeEnabled(event.target.checked)
    if (vehicle === 'motorbike') setMotorbikeEnabled(event.target.checked)
    if (vehicle === 'car') setCarEnabled(event.target.checked)

    // @ts-ignore onChange from antd can be undefined
    onChange?.({
      ...value,
      [vehicle]: {
        ...value?.[vehicle],
        enabled: event.target.checked
      }
    })
  }

  const handleVehicleThresholdChange = (
    vehicle: VehicleTypes,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (vehicle === 'bike') setBikeThreshold(event.target.value)
    if (vehicle === 'motorbike') setMotorbikeThreshold(event.target.value)
    // car threshold is always null, goes until infinity

    // @ts-ignore onChange from antd can be undefined
    onChange?.({
      ...value,
      [vehicle]: {
        ...value?.[vehicle],
        threshold: event.target.value
          ? parseFloat(event.target.value).toFixed(2)
          : null
      }
    })
  }

  const bikeValueRanges = () => {
    if (!bikeEnabled) return null
    if (!motorbikeEnabled && !carEnabled) return null
    return (
      <Space size={'middle'}>
        <Input type='number' disabled addonBefore='£' value={0} />
        <div style={{ whiteSpace: 'nowrap' }}>to</div>
        <Input
          type='number'
          addonBefore='£'
          value={bikeThreshold}
          onChange={(event) => handleVehicleThresholdChange('bike', event)}
          onFocus={onFocusScrollNumber}
          onBlur={onBlurScrollNumber}
        />
      </Space>
    )
  }

  const motorbikeValueRanges = () => {
    if (!motorbikeEnabled) return null
    if (!bikeEnabled && !carEnabled) return null
    if (bikeEnabled && !carEnabled)
      return bikeThreshold && <span>{`Orders above £${bikeThreshold}`}</span>

    const minThreshold = bikeEnabled
      ? (Number(bikeThreshold) + 0.01).toFixed(2)
      : 0
    return (
      <Space size={'middle'}>
        <Input type='number' disabled addonBefore='£' value={minThreshold} />
        <div style={{ whiteSpace: 'nowrap' }}>to</div>
        <Input
          type='number'
          addonBefore='£'
          value={motorbikeThreshold}
          onChange={(event) => handleVehicleThresholdChange('motorbike', event)}
          onFocus={onFocusScrollNumber}
          onBlur={onBlurScrollNumber}
        />
      </Space>
    )
  }

  const carValueRanges = () => {
    if (!carEnabled) return null
    if (!bikeEnabled && !motorbikeEnabled) return null
    const secondHighestThreshold = motorbikeEnabled
      ? motorbikeThreshold
      : bikeThreshold
    return (
      secondHighestThreshold && (
        <span>{`Orders above £${secondHighestThreshold}`}</span>
      )
    )
  }

  return (
    <React.Fragment>
      <Switch
        checked={thresholdEnabled}
        onChange={handleOptionalThresholdChange}
      />
      <label style={{ marginLeft: '8px' }}>Assign vehicle by order value</label>
      <Row style={{ paddingTop: '16px', paddingBottom: '8px' }}>
        <Col span={12}>
          <Checkbox
            checked={bikeEnabled}
            onChange={(event) => handleVehicleEnabledChange('bike', event)}
          >
            Bike
          </Checkbox>
        </Col>
        <Col span={12}>{thresholdEnabled && bikeValueRanges()}</Col>
      </Row>
      <Row style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Col span={12}>
          <Checkbox
            checked={motorbikeEnabled}
            onChange={(event) => handleVehicleEnabledChange('motorbike', event)}
          >
            Motorbike
          </Checkbox>
        </Col>
        <Col span={12}>{thresholdEnabled && motorbikeValueRanges()}</Col>
      </Row>
      <Row style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        <Col span={12}>
          <Checkbox
            checked={carEnabled}
            onChange={(event) => handleVehicleEnabledChange('car', event)}
          >
            Car/Van
          </Checkbox>
        </Col>
        <Col span={12}>{thresholdEnabled && carValueRanges()}</Col>
      </Row>
    </React.Fragment>
  )
}

export default Delivery
