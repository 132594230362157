import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { Button, Card, Col, Row, Tooltip } from 'antd'
import { DeleteOutlined, DragOutlined } from '@ant-design/icons'
import {
  UPDATE_PRODUCT_MODIFIER_GROUP_ARRANGEMENTS,
  REMOVE_MODIFIER_GROUP_FROM_PRODUCT
} from '../ProductQueries'
import '../Products.css'

interface ModifierArrangement {
  modifier: {
    id: string
    name: string
  }
}

interface ModifierGroup {
  id: string
  name: string
  sku: string | null
  description: string
  minimum_enabled: boolean
  minimum: number
  maximum_enabled: boolean
  maximum: number
  archived_at: string | null
  modifier_arrangement: ModifierArrangement[]
}

interface ModifierGroupsArrangementsProps {
  productId: string
  modifierGroups: ModifierGroup[]
  productModifierGroupsArrangements: string[]
  onSaveStart?: () => void
  onSaveEnd?: () => void
  onSaveError?: (errorMessage?: string) => void
}

const ModifierGroupsArrangements = (props: ModifierGroupsArrangementsProps) => {
  const {
    productId,
    modifierGroups,
    productModifierGroupsArrangements,
    onSaveStart,
    onSaveEnd,
    onSaveError
  } = props
  const [
    updateProductModifierGroupArrangements,
    { loading: updatingArrangements }
  ] = useMutation(UPDATE_PRODUCT_MODIFIER_GROUP_ARRANGEMENTS)
  const [
    removeModifierGroupFromProduct,
    { loading: removingModifierGroupInArrangements }
  ] = useMutation(REMOVE_MODIFIER_GROUP_FROM_PRODUCT)
  const [modifierGroupsArrangements, setModifierGroupsArrangements] = useState<
    string[]
  >([])

  useEffect(() => {
    if (productModifierGroupsArrangements.length) {
      setModifierGroupsArrangements(productModifierGroupsArrangements)
    }
  }, [productModifierGroupsArrangements])

  const removeFromArrangements = (modifierGroupId: string) => {
    const arrangement = modifierGroupsArrangements.filter(
      (modGrpId: string) => modGrpId !== modifierGroupId
    )
    const variables = {
      productId,
      modifierGroupId,
      arrangement: { ...arrangement }
    }
    onSaveStart && onSaveStart()
    removeModifierGroupFromProduct({
      variables
    })
      .then((result) => {
        onSaveEnd && onSaveEnd()
      })
      .catch((error) => {
        onSaveError && onSaveError(error.message)
      })
  }

  const SortableModifierGroup = SortableElement(
    ({ modifierGroup }: { modifierGroup: string }) => {
      const modGroupIndex = modifierGroups
        .map((modGrp: ModifierGroup) => modGrp.id)
        .indexOf(modifierGroup)
      const currentModifierGroup = modifierGroups[modGroupIndex] || {}
      const { id, name = '', description = '' } = currentModifierGroup

      return (
        <Card
          data-testid='draggable-column-item'
          className='width-100'
          bodyStyle={{
            cursor: 'grab',
            padding: 8
          }}
        >
          <Row align='middle' className='-modifier-group-list'>
            <Col span={1}>
              <DragOutlined />
            </Col>
            <Col span={23}>
              <Row align='middle' className='-modifier-group-list'>
                <Col
                  span={12}
                  data-testid={`modifier-groups-list-item-${id}`}
                >{`${name} (${description})`}</Col>
                <Col span={12}>
                  <Row justify='end' className='-modifier-group-list'>
                    <Tooltip placement='top' title='Remove modifier group'>
                      <Button
                        data-testid={`remove-modifier-group-${id}`}
                        icon={<DeleteOutlined />}
                        size='small'
                        onClick={() => removeFromArrangements(id)}
                      />
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      )
    }
  )

  const SortableModifierGroupsList = SortableContainer(
    ({ items }: { items: string[] }) => {
      return (
        <div>
          {items.map((item: any, i: any) => {
            return (
              <SortableModifierGroup
                key={item}
                modifierGroup={item}
                index={i}
                disabled={
                  updatingArrangements || removingModifierGroupInArrangements
                }
              />
            )
          })}
        </div>
      )
    }
  )

  const saveArrangement = (arrangement: string[]) => {
    const variables = {
      productId,
      arrangement: { ...arrangement }
    }

    return updateProductModifierGroupArrangements({
      variables
    })
  }

  const handleCategorySortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newArrangement = arrayMove(
      modifierGroupsArrangements,
      oldIndex,
      newIndex
    )
    setModifierGroupsArrangements(newArrangement)
    onSaveStart && onSaveStart()
    saveArrangement(newArrangement)
      .then((result) => {
        onSaveEnd && onSaveEnd()
      })
      .catch((error) => {
        onSaveError && onSaveError(error.message)
      })
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <div className='categories-container'>
            <SortableModifierGroupsList
              axis='y'
              lockAxis='y'
              distance={1}
              items={modifierGroupsArrangements}
              onSortEnd={handleCategorySortEnd}
              helperClass='sortable-item'
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ModifierGroupsArrangements
