import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const spinning = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  text-indent: -9999em;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }: any) => theme.colors.gray500};
  background: ${({ theme }: any) =>
    `linear-gradient(to right, ${theme.colors.gray500} 10%, rgba(139, 92, 246, 0) 42%)`};
  position: relative;
  animation: ${spinning} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${({ theme }: any) => theme.colors.gray500};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: ${({ theme }: any) => theme.colors.white};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export { Spinner }
