import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_CATEGORIES } from './Actions'
import { Checkbox, Form, Spin } from 'antd'

const Categories = ({ merchantId }: { merchantId: string }) => {
  const { data, loading } = useQuery(GET_CATEGORIES, {
    variables: { merchantId },
    fetchPolicy: 'no-cache'
  })

  return (
    <Form.Item
      data-testid='discount-form-categories'
      label='Categories'
      name='categories'
      style={{ flexFlow: 'column' }}
      rules={[
        { required: true, message: 'Please select at least one category' }
      ]}
    >
      {loading ? (
        <span>
          <Spin size='small' style={{ marginRight: '16px' }} />
          Loading categories...
        </span>
      ) : !data ? (
        <p>No categories found</p>
      ) : (
        <Checkbox.Group
          options={data.categories}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gridGap: '8px'
          }}
        />
      )}
    </Form.Item>
  )
}

export default Categories
