import React, { useState } from 'react'
import { Row, Col, Input, Select, Tooltip } from 'antd'
import debounce from 'lodash/debounce'
import { Discount } from '@slerp/controls'
import DiscountsTable from './DiscountsTable'
import { isActive, isScheduled, isInactive } from './Helpers'
import { SearchOutlined } from '@ant-design/icons'
import { SelectDownArrowIcon, FilterIcon } from '@slerp/assets'
import { SelectPrefixWrapper } from '../Widgets'
import './styles.css'

type NumberOfUses = {
  [key: string]: number
}
type DiscountProps = {
  discounts: Discount[]
  refetch: () => {}
  numberOfUses: NumberOfUses
}

interface tooltipDescription {
  [key: string]: string
}

const Codes = (props: DiscountProps) => {
  const { discounts, numberOfUses } = props
  const [searchFilter, setSearchFilter] = useState('')
  const [discountFilter, setDiscountFilter] = useState('active')
  const triggerSearchFilter = (searchParam: string) => {
    setSearchFilter(searchParam)
  }
  const debouncedSetSearchFilter = debounce(triggerSearchFilter, 400)
  const filterHandler = (discount: Discount) => {
    const { code } = discount
    const parsedFilter = searchFilter.replace(/\s/g, '').toLowerCase()
    return code.toLowerCase().includes(parsedFilter)
  }

  const appendNumberOfUses = (discount: Discount, noOfUses: NumberOfUses) => {
    const { id } = discount
    const usage_count = noOfUses[id] || 0
    return { ...discount, usage_count }
  }

  let allDiscounts = discounts.map((discount: Discount) =>
    appendNumberOfUses(discount, numberOfUses)
  )
  allDiscounts = searchFilter
    ? allDiscounts.filter(filterHandler)
    : allDiscounts
  const activeDiscounts = allDiscounts.filter(isActive)
  const scheduledDiscounts = allDiscounts.filter(isScheduled)
  const inactiveDiscounts = allDiscounts.filter(isInactive)

  const tooltipDescription: tooltipDescription = {
    all: 'An overview of all discount codes ever created across all your stores.',
    active: 'Discount codes that are currently live.',
    scheduled:
      'Discount codes that are set to be live during a specific date range.',
    inactive: 'Discount codes that are not in use.'
  }

  return (
    <>
      <Row justify='space-between' className='_mt-16 _mb-32'>
        <Tooltip title={tooltipDescription[discountFilter]}>
          <Col className='_ml-8'>
            <SelectPrefixWrapper prefixicon={<FilterIcon />}>
              <Select
                data-testid='discount-table-filter'
                onChange={(target) => setDiscountFilter(target)}
                defaultValue='active'
                suffixIcon={<SelectDownArrowIcon />}
              >
                <Select.Option
                  value='all'
                  data-testid='discount-table-filter-all'
                >
                  All
                </Select.Option>
                <Select.Option
                  value='active'
                  data-testid='discount-table-filter-active'
                >
                  Active
                </Select.Option>
                <Select.Option
                  value='scheduled'
                  data-testid='discount-table-filter-scheduled'
                >
                  Scheduled
                </Select.Option>
                <Select.Option
                  value='inactive'
                  data-testid='discount-table-filter-inactive'
                >
                  Inactive
                </Select.Option>
              </Select>
            </SelectPrefixWrapper>
          </Col>
        </Tooltip>
        <Col>
          <Input
            data-testid='discount-code-search-field'
            onChange={(e) => debouncedSetSearchFilter(e.target.value)}
            placeholder='Search discount code'
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      {discountFilter === 'all' && (
        <div data-testid='all-tab-table'>
          <DiscountsTable data={allDiscounts} trigger='code' />
        </div>
      )}

      {discountFilter === 'active' && (
        <div data-testid='active-tab-table'>
          <DiscountsTable data={activeDiscounts} trigger='code' />
        </div>
      )}

      {discountFilter === 'scheduled' && (
        <div data-testid='scheduled-tab-table'>
          <DiscountsTable data={scheduledDiscounts} trigger='code' />
        </div>
      )}

      {discountFilter === 'inactive' && (
        <div data-testid='inactive-tab-table'>
          <DiscountsTable data={inactiveDiscounts} trigger='code' />
        </div>
      )}
    </>
  )
}

export default Codes
