import React from 'react'
import styled from '@emotion/styled'
import { useCollapsible } from './Collapsible'

const FormGroupContent: React.FC = ({ children }) => {
  const { isCollapsed, isCollapsible } = useCollapsible()

  const getMaxHeight = () => {
    if (!isCollapsible || !isCollapsed) return '1000px'
    return 0
  }

  return (
    <Content maxHeight={getMaxHeight()} hasOverflow={isCollapsed}>
      {children}
    </Content>
  )
}

const Content = styled.div<{
  maxHeight: number | string
  hasOverflow: boolean
}>`
  max-height: ${({ maxHeight }) => maxHeight};
  ${(props) => props.hasOverflow && 'overflow-y: hidden'};
  transition: max-height 0.2s ease;
`

export { FormGroupContent }
