import React from 'react'
import { OrderInfo } from '../type'
import { Col } from 'antd'
import RefundButton from './RefundButton'

interface RefundedType {
  order: OrderInfo
  setShowRefundForm: (value?: any) => void
}

const Refunded = ({ order, setShowRefundForm }: RefundedType) => {
  return (
    <>
      <Col>
        <RefundButton order={order} onClick={() => setShowRefundForm(true)} />
      </Col>
    </>
  )
}

export default Refunded
