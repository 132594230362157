import React, { useContext, useState } from 'react'
import { uuid } from 'uuidv4'

interface IframeContext {
  iframeKey: string
  regenerateIframeKey: () => void
}

const IframeContext = React.createContext<IframeContext>({} as IframeContext)

export const IframeProvider: React.FC = ({ children }) => {
  const [iframeKey, setIframeKey] = useState(uuid())

  const regenerateIframeKey = () => {
    setIframeKey(uuid())
  }

  return (
    <IframeContext.Provider
      value={{
        iframeKey,
        regenerateIframeKey
      }}
    >
      {children}
    </IframeContext.Provider>
  )
}

export const useIframe = () => {
  return useContext(IframeContext)
}
