import React from 'react'
import { Button, Col, Row } from 'antd'

interface ActionsProps {
  discardHandler?: () => void
  saveHandler?: () => void
  title?: string
  loading: boolean
  compact?: boolean
  saveButtonDisabled?: boolean
}

const ActionsBanner = ({
  discardHandler,
  saveHandler,
  loading,
  title,
  compact = false,
  saveButtonDisabled = false
}: ActionsProps) => {
  let containerClassNames = 'actions-banner _mb-0'
  if (compact) {
    containerClassNames = containerClassNames.concat(' -compact')
  }
  return (
    <Row className={containerClassNames} data-testid='actions-banner'>
      <Col span={12} className='title'>
        {title}
      </Col>
      <Col span={12}>
        <Row className='_mb-0' justify='end' gutter={[8, 8]}>
          {discardHandler && (
            <Col>
              <Button
                data-testid='actions-banner-discard'
                ghost
                type='primary'
                onClick={() => discardHandler()}
              >
                Discard
              </Button>
            </Col>
          )}
          {saveHandler && (
            <Col>
              <Button
                disabled={saveButtonDisabled}
                type='primary'
                onClick={saveHandler}
                loading={loading}
                data-testid='actions-banner-save'
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ActionsBanner
