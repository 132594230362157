import React, { useEffect, useState } from 'react'
import { Button, List, message, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useSession } from '@slerp/accounts'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { MerchantSettings } from '@slerp/controls'
import {
  GET_MERCHANT_SETTINGS,
  TOGGLE_CATEGORY_ARCHIVE_STATE,
  TOGGLE_PRODUCT_ARCHIVE_STATE,
  UPDATE_MERCHANT_ARRANGEMENT_SETTINGS,
  buildProductsCategoriesByCategoryIds,
  GET_ALL_MERCHANT_CATEGORIES
} from '../ProductQueries'
import { toggleCategoryArchiveState } from '../Actions'
import Loading from 'components/Utils/Loading'
import { filterProductsWithSingleCategory } from './utils'
import ErrorMessage from 'components/Utils/ErrorMessage'

interface Category {
  id: string
  name: string
  archived_at: string
}

const ArchivedCategoriesList = () => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [categories, setCategories] = useState<Array<Category>>([])
  const [restoringCategory, setRestoringCategory] = useState<string>('')
  const [merchantSettings, setMerchantSettings] = useState<MerchantSettings>()
  const [categoryIdsAsParams, setCategoryIdsAsParams] =
    useState<Record<'category_id', { _eq: string }>[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    data: merchantCategories,
    loading: isLoadingMerchantCategories,
    error: errorMerchantCategories
  } = useQuery<{ categories: Category[] }>(GET_ALL_MERCHANT_CATEGORIES, {
    variables: { merchantId: merchant.id },
    onCompleted: (data) => {
      if (!data) return
      const { categories } = data

      const idsParams = categories?.map((category: Category) => ({
        category_id: { _eq: category.id }
      }))

      setCategoryIdsAsParams(idsParams)
    },
    onError: (error) =>
      message.error(`Unable to load categories due to: ${error.message}`, 5),
    notifyOnNetworkStatusChange: true
  })

  const {
    data: merchantSettingsData,
    loading: isLoadingMerchantSettings,
    error: errorMerchantSettings
  } = useQuery(GET_MERCHANT_SETTINGS, {
    variables: { merchantSlug: merchant.slug }
  })

  const query = buildProductsCategoriesByCategoryIds()

  const {
    data: productsCategoriesData,
    loading: isLoadingProductsCategories,
    error: errorProductsCategories
  } = useQuery<{
    products_categories: {
      category_id: string
      product: { id: string; default_variant_id: string }
    }[]
  }>(query, {
    variables: {
      categoryIds: categoryIdsAsParams,
      active: false
    },
    skip: isLoadingMerchantCategories || !merchantCategories,
    notifyOnNetworkStatusChange: true,
    onError: (error) =>
      message.error(
        `Unable to load products_categories due to: ${error.message}`,
        5
      )
  })

  const [toggleCategoryArchiveStates] = useMutation(
    TOGGLE_CATEGORY_ARCHIVE_STATE
  )

  const [toggleProductArchiveState] = useMutation(TOGGLE_PRODUCT_ARCHIVE_STATE)

  const [updateMerchantArrangementSettings] = useMutation(
    UPDATE_MERCHANT_ARRANGEMENT_SETTINGS
  )

  useEffect(() => {
    if (merchantCategories) {
      const { categories } = merchantCategories
      const filterArchivedCategories = categories.filter(
        (category) => category.archived_at !== null
      )

      setCategories(filterArchivedCategories)
      setRestoringCategory('')
    }
  }, [merchantCategories])

  useEffect(() => {
    if (merchantSettingsData) {
      const { setting } = merchantSettingsData.merchants[0]
      setMerchantSettings(setting)
      setRestoringCategory('')
    }
  }, [merchantSettingsData])

  const restoreCategory = (category: Category) => {
    const { categories } = merchantCategories || {}
    const { products_categories } = productsCategoriesData!

    if (!categories)
      return message.error(
        'Unable to restore category due to empty categories data',
        5
      )
    if (!products_categories)
      return message.error(
        'Unable to restore category due to empty products_categories data',
        5
      )

    const productsOnCurrentCategory = products_categories.filter(
      (pc) => pc.category_id === category.id
    )
    const filterProductsCategoriesWithCurrentProducts =
      products_categories.filter((pc) =>
        productsOnCurrentCategory.some(
          (ppc) => ppc.product.id === pc.product.id
        )
      )

    const affectedProducts = filterProductsWithSingleCategory(
      filterProductsCategoriesWithCurrentProducts
    )

    if (merchantSettings) {
      setRestoringCategory(category.id)
      toggleCategoryArchiveState({
        categoryId: category.id,
        shouldArchive: false,
        merchantSlug: merchant.slug,
        merchantSettings,
        toggleArchiveState: toggleCategoryArchiveStates,
        toggleProductArchiveState,
        updateMerchantArrangementSettings,
        affectedProducts
      })
        .then(() => {
          client.resetStore()
          message.destroy()
          message.success(`Category ${category.name} restored!`, 3)
        })
        .catch((error) => {
          message.destroy()
          message.error(
            `Unable to restore category ${category.name} due to: ${error.message}`,
            3
          )
        })
    }
  }

  const renderCategoryRow = (categoryItem: Category) => {
    const isRestoring = restoringCategory === categoryItem.id

    return (
      <List.Item
        actions={[
          <Tooltip title='Restore category'>
            <Button
              data-testid={`restore-category-${categoryItem.name
                .replace(/\s+/g, '-')
                .toLowerCase()}`}
              disabled={!!restoringCategory}
              loading={isRestoring}
              size='small'
              icon={<ReloadOutlined />}
              onClick={() => restoreCategory(categoryItem)}
            />
          </Tooltip>
        ]}
      >
        {categoryItem.name}
      </List.Item>
    )
  }

  useEffect(() => {
    setIsLoading(
      isLoadingMerchantCategories ||
        isLoadingMerchantSettings ||
        isLoadingProductsCategories
    )
  }, [
    isLoadingMerchantCategories,
    isLoadingMerchantSettings,
    isLoadingProductsCategories
  ])

  if (isLoading) return <Loading />
  if (errorProductsCategories)
    return (
      <ErrorMessage
        title='Products categories error'
        subheading={errorProductsCategories?.message}
      />
    )
  if (errorMerchantCategories)
    return (
      <ErrorMessage
        title='Merchant categories error'
        subheading={errorMerchantCategories?.message}
      />
    )
  if (errorMerchantSettings)
    return (
      <ErrorMessage
        title='Merchant settings error'
        subheading={errorMerchantSettings?.message}
      />
    )

  return (
    <>
      <>
        <List
          bordered
          dataSource={categories}
          size='small'
          renderItem={renderCategoryRow}
          pagination={{
            defaultCurrent: 1,
            showSizeChanger: true
          }}
        />
      </>
    </>
  )
}

export default ArchivedCategoriesList
