import { gql } from '@apollo/client'

const attribution = `
delivery
pickup
selfDelivery
nationwideDelivery
table
store {
  id
  name
  slug
}
user {
  id
  firstname
  lastname
}
lastUpdatedAt
stripeUserId
`

const MUTATION_SET_FRANCHISE_ATTRIBUTIONS = gql`
  mutation setFranchiseAttributions(
    $storeIds: [ID]!,
    $delivery: Float,
    $pickup: Float,
    $selfDelivery: Float,
    $nationwideDelivery: Float,
    $table: Float
  ) {
    setFranchiseAttributions(
      storeIds: $storeIds,
      delivery: $delivery,
      pickup: $pickup,
      selfDelivery: $selfDelivery,
      nationwideDelivery: $nationwideDelivery,
      table: $table
    ) {
      ${attribution}
    }
  }
`

const MUTATION_REMOVE_FRANCHISE_ATTRIBUTION = gql`
  mutation removeFranchiseAttributions($storeId: ID!) {
    removeFranchiseAttributions(storeId: $storeId) {
      ${attribution}
    }
  }
`

export {
  MUTATION_SET_FRANCHISE_ATTRIBUTIONS,
  MUTATION_REMOVE_FRANCHISE_ATTRIBUTION
}
