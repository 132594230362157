import React from 'react'
import styled from '@emotion/styled'
import { useApp } from '../../contexts/App'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

export const HeadingToolbar = () => {
  const { zoom, handleZoomChange } = useApp()

  return (
    <>
      <ZoomContainer>
        <MinusOutlined onClick={() => handleZoomChange('decrease')} />
        {(zoom * 100).toFixed(0)}%
        <PlusOutlined onClick={() => handleZoomChange('increase')} />
      </ZoomContainer>
    </>
  )
}

const ZoomContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
