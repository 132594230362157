import React, { useState, useEffect, useCallback } from 'react'
import { uuid } from 'uuidv4'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import slice from 'lodash/slice'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { useSession } from '@slerp/accounts'
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Input,
  message,
  Row,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Select
} from 'antd'
import {
  SearchOutlined,
  DeleteOutlined,
  ReloadOutlined,
  EditOutlined
} from '@ant-design/icons'
import { filterSelectOption } from '@slerp/antd-utils'
import ModifierForm from './Form'
import ModifierImages from './Images'
import {
  ARCHIVE_MODIFIER,
  buildGetMerchantModifiersQuery,
  GET_MERCHANT_MODIFIER_GROUPS,
  UNARCHIVE_MODIFIER
} from './ModifierQueries'
import Loading from '../../Utils/Loading'
import './Modifiers.css'
import { openArchiveWarning } from './../../Utils/ArchiveWarning'
import { computeGrossPrice } from 'components/Utils/price'
import { generateAssetURL } from 'packages/@slerp/helpers/asset-url'

interface ModifierGroup {
  name: string
  description: string
}

interface ModifierGroups {
  modifier_group_id: string
  modifier_group: ModifierGroup
}

interface Modifier {
  id: string
  name: string
  sku: string
  price: number
  vat: number
  alcoholic: boolean
  image: string | null
  archived_at: string | null
  store_modifier: StoreModifier[]
  modifier_groups: {
    modifier_group_id: string
    modifier_group: ModifierGroup
  }[]
}

interface StoreModifier {
  published_at: string
  in_stock: boolean
}

interface arrangement {
  [id: string]: string
}

interface ModifierGroupModifier {
  modifier_id: string
}

interface ModifierGroup {
  id: string
  name: string
  description: string
  sku: string | null
  minimum: number
  minimum_enabled: boolean
  maximum: number
  maximum_enabled: boolean
  archived_at: string | null
  modifier_arrangement: arrangement
  modifier_group_modifiers: ModifierGroupModifier[]
}

const { TabPane } = Tabs
const { Option } = Select
const { Text } = Typography

const Modifiers = () => {
  const { merchant } = useSession()
  const client = useApolloClient()
  const [modifiers, setModifiers] = useState<Array<Modifier>>([])
  const [modifierGroups, setModifierGroups] = useState<Array<ModifierGroup>>([])
  const [modifiersStatusFilter, setModifiersStatusFilter] =
    useState<string>('active')
  const [modifierGroupIdFilter, setModifierGroupIdFilter] =
    useState<string>('all')
  const [fuzzySearchKeyword, setFuzzySearchKeyword] = useState('')
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [activeModifier, setActiveModifier] = useState<Modifier | undefined>(
    undefined
  )
  const [processingModifierRow, setProcessingModifierRow] = useState<string>('')

  const {
    data: merchantModifiersData,
    refetch: refetchModifiersData,
    loading: fetchingModifiersData
  } = useQuery(buildGetMerchantModifiersQuery(), {
    variables: {
      merchantId: merchant.id
    },
    notifyOnNetworkStatusChange: true
  })

  const { data: merchantModifierGroupsData } = useQuery(
    GET_MERCHANT_MODIFIER_GROUPS,
    {
      variables: {
        merchantId: merchant.id
      }
    }
  )

  const [archiveModifier] = useMutation(ARCHIVE_MODIFIER)
  const [unarchiveModifier] = useMutation(UNARCHIVE_MODIFIER)

  const filterModifiers = useCallback(
    (modifiers: Modifier[]) => {
      let tempModifiers = Object.assign([], modifiers)

      if (modifierGroupIdFilter !== 'all') {
        tempModifiers = Object.assign(
          [],
          tempModifiers.filter((modifier: Modifier) => {
            const { modifier_groups } = modifier
            const ids = modifier_groups.map((mg) => mg.modifier_group_id)
            return ids.includes(modifierGroupIdFilter)
          })
        )
      }

      if (modifiersStatusFilter === 'active') {
        tempModifiers = Object.assign(
          [],
          tempModifiers.filter((modifier: Modifier) => {
            return isEmpty(modifier.archived_at)
          })
        )
      } else {
        tempModifiers = Object.assign(
          [],
          tempModifiers.filter((modifier: Modifier) => {
            return !isEmpty(modifier.archived_at)
          })
        )
      }

      if (fuzzySearchKeyword) {
        const parsedFilter = fuzzySearchKeyword.replace(/\s/g, '').toLowerCase()
        tempModifiers = Object.assign(
          [],
          tempModifiers.filter((modifier: Modifier) => {
            const { name } = modifier
            return name.toLowerCase().includes(parsedFilter)
          })
        )
      }

      return tempModifiers
    },
    [fuzzySearchKeyword, modifiersStatusFilter, modifierGroupIdFilter]
  )

  useEffect(() => {
    if (merchantModifiersData) {
      const { modifiers } = merchantModifiersData
      setModifiers(filterModifiers(modifiers))
    }
  }, [merchantModifiersData, filterModifiers])

  useEffect(() => {
    if (merchantModifierGroupsData) {
      const { modifier_groups } = merchantModifierGroupsData
      setModifierGroups(modifier_groups)
    }
  }, [merchantModifierGroupsData])

  useEffect(() => {
    if (activeModifier) {
      const showDrawer = !!activeModifier.id
      setIsDrawerVisible(showDrawer)
    }
  }, [activeModifier])

  const debouncedFuzzySearchKeyword = debounce(setFuzzySearchKeyword, 200)

  const handleToggleModifierArchiveState = (
    modifierId: string,
    modifierName: string,
    isArchived: boolean
  ) => {
    const operation = isArchived ? 'Restoring' : 'Archiving'
    message.destroy()
    message.loading(`${operation} modifier...`, 1)
    setProcessingModifierRow(modifierId)
    if (isArchived) {
      unarchiveModifier({ variables: { modifierId } })
        .then((result) => {
          message.destroy()
          openArchiveWarning({
            itemId: modifierId,
            itemName: modifierName,
            description: 'modifier has been restored'
          })
          client.resetStore()
          setProcessingModifierRow('')
        })
        .catch((error: Error) => {
          setProcessingModifierRow('')
          message.destroy()
          openArchiveWarning({
            itemId: modifierId,
            itemName: modifierName,
            description: `modifier failed to restore due to ${error.message}`
          })
        })
    } else {
      archiveModifier({ variables: { modifierId } })
        .then((result) => {
          message.destroy()
          openArchiveWarning({
            itemId: modifierId,
            itemName: modifierName,
            restore: () =>
              handleToggleModifierArchiveState(modifierId, modifierName, true),
            description: 'modifier has been archived'
          })
          client.resetStore()
          setProcessingModifierRow('')
        })
        .catch((error: Error) => {
          setProcessingModifierRow('')
          message.destroy()
          openArchiveWarning({
            itemId: modifierId,
            itemName: modifierName,
            description: `modifier failed to archive due to ${error.message}`
          })
        })
    }
  }

  const columns = [
    {
      title: 'Modifier Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (modifierName: string, modifier: Modifier) => {
        const imageUrl = generateAssetURL({
          id: modifier.id,
          type: 'modifier',
          size: 'thumb',
          fileName: modifier.image ?? '',
          itemName: modifier.name
        })

        return (
          <>
            <Row className='_mb-0' align='middle' gutter={[8, 8]}>
              <Col span={3}>
                <Avatar size={30} shape='square' src={imageUrl} />
              </Col>
              <Col span={21}>
                <span
                  onClick={() => setActiveModifier(modifier)}
                  className='cursor-pointer'
                  data-testid={`modifier-name-${modifierName}`}
                >
                  <Text
                    style={{ width: 220 }}
                    ellipsis={{ tooltip: modifierName }}
                    data-testid={`modifier-name-text-${modifierName}`}
                  >
                    {modifierName}
                  </Text>
                </span>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      title: 'Modifier Group',
      dataIndex: 'modifier_groups',
      key: 'modifier_groups',
      render: (modifier_groups: ModifierGroups[]) => {
        let aggregatedViewEnabled = false
        let visibleGroups = []
        let aggregatedModifierGroups = []

        if ((modifier_groups || []).length === 3) {
          visibleGroups = [...modifier_groups]
        } else {
          aggregatedViewEnabled = (modifier_groups || []).length > 2
          visibleGroups = aggregatedViewEnabled
            ? slice(modifier_groups, 0, 2)
            : [...modifier_groups]
          aggregatedModifierGroups = slice(modifier_groups || [], 2)
        }

        const tags = visibleGroups.map(({ modifier_group }: ModifierGroup) => (
          <Tooltip title={modifier_group?.description}>
            <Tag className='-basic'>{modifier_group?.name}</Tag>
          </Tooltip>
        ))

        if (aggregatedViewEnabled) {
          tags.push(
            <Tooltip
              title={
                <>
                  {aggregatedModifierGroups.map(({ modifier_group }) => (
                    <>
                      <span>{modifier_group?.name}</span>
                      <br />
                      <span>({modifier_group?.description})</span>
                      <br />
                      <br />
                    </>
                  ))}
                </>
              }
            >
              <Tag className='-basic -plain-text-format'>
                {`+ ${aggregatedModifierGroups.length} more groups`}
              </Tag>
            </Tooltip>
          )
        }
        return <>{tags}</>
      }
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 100,
      render: (price: number, { vat }: Modifier) => {
        return `£${computeGrossPrice(price || 0, vat || 0).toFixed(2)}`
      }
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      width: 150
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      render: (_value: any, modifier: Modifier) => {
        const isArchived = !isEmpty(modifier.archived_at)
        const icon = isArchived ? <ReloadOutlined /> : <DeleteOutlined />

        const isOperationInProgress = !isEmpty(processingModifierRow)
        const isCurrentRowProcessing = processingModifierRow === modifier.id
        return (
          <>
            <Row className='_mb-0' gutter={[8, 8]} align='middle'>
              <Tooltip title='Edit' color='#d092dd'>
                <Button
                  data-testid={`modifier-edit-button-${modifier.name}`}
                  size='small'
                  icon={<EditOutlined />}
                  disabled={isOperationInProgress}
                  loading={isCurrentRowProcessing}
                  onClick={() => setActiveModifier(modifier)}
                />
              </Tooltip>
              <Tooltip
                title={isArchived ? 'Restore' : 'Archive'}
                color='#d092dd'
              >
                <Col>
                  <Button
                    data-testid={`modifier-archive-button-${modifier.name}`}
                    size='small'
                    icon={icon}
                    disabled={isOperationInProgress}
                    loading={isCurrentRowProcessing}
                    onClick={() =>
                      handleToggleModifierArchiveState(
                        modifier.id,
                        modifier.name,
                        isArchived
                      )
                    }
                  />
                </Col>
              </Tooltip>
            </Row>
          </>
        )
      }
    }
  ]

  const refreshModifierList = () => {
    setIsDrawerVisible(false)
    refetchModifiersData()
    setActiveModifier(undefined)
  }

  return (
    <>
      <Drawer
        key={uuid()}
        width={720}
        onClose={refreshModifierList}
        visible={isDrawerVisible}
        title='Edit Modifier'
      >
        {activeModifier && (
          <Tabs>
            <TabPane tab='General' key='1'>
              <ModifierForm
                key={uuid()}
                modifierInfo={activeModifier}
                successCallback={() => refreshModifierList()}
                discardCallback={() => refreshModifierList()}
              />
            </TabPane>
            <TabPane tab='Images' key='2'>
              <ModifierImages
                key={uuid()}
                modifierId={activeModifier.id}
                modifierImage={activeModifier.image}
                modifierName={activeModifier.name}
              />
            </TabPane>
          </Tabs>
        )}
      </Drawer>
      <Row className='_mt-16 _mb-0'>
        <Col span={24}>
          <Row className='_mb-0'>
            <Col span={12}>
              <Row className='_mb-0' gutter={[8, 8]}>
                <Col span={9}>
                  {modifierGroups && (
                    <Select
                      data-testid='modifier-groups-filter'
                      showSearch
                      className='width-100'
                      defaultValue='all'
                      optionFilterProp='name'
                      filterOption={filterSelectOption}
                      onChange={setModifierGroupIdFilter}
                    >
                      <Option
                        key='all'
                        value='all'
                        data-testid='modifier-groups-filter-option-all'
                      >
                        All Modifier Groups
                      </Option>
                      {modifierGroups.map((modifierGroup) => {
                        const { id, name, description } = modifierGroup
                        return (
                          <Option
                            value={id}
                            key={id}
                            data-testid={`modifier-groups-filter-option-${id}`}
                          >
                            {`${name} (${description})`}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                </Col>
                <Col span={5}>
                  <Select
                    data-testid='modifiers-archive-state-filter'
                    className='width-100'
                    defaultValue={modifiersStatusFilter}
                    onChange={setModifiersStatusFilter}
                  >
                    <Option value='active'>Active</Option>
                    <Option value='archived'>Archived</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify='end' gutter={[8, 8]}>
                <Col span={5}>
                  <Input
                    data-testid='modifiers-search-filter'
                    prefix={<SearchOutlined />}
                    placeholder='Search'
                    onChange={(e) =>
                      debouncedFuzzySearchKeyword(e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='_mb-0'>
            <Col span={24}>
              {fetchingModifiersData && <Loading />}
              {!fetchingModifiersData && modifiers && (
                <Table
                  dataSource={modifiers}
                  columns={columns}
                  data-testid='modifiers-table'
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Modifiers
