type CustomRuleType = { getFieldValue: (arg: string) => string }

const MAXIMUM_TIME_PERIOD = {
  day: 180,
  week: 25,
  month: 6
}

const maximumTimePeriodRule = [
  ({ getFieldValue }: CustomRuleType) => ({
    validator(_: any, value: string) {
      const timePeriodUnit = getFieldValue('time_period_unit')
      const timePeriodAmount = parseInt(value || 0)
      let exceedingMaxValues =
        timePeriodAmount > MAXIMUM_TIME_PERIOD[timePeriodUnit]

      return exceedingMaxValues
        ? Promise.reject('Time period amount exceeds acceptable range.')
        : Promise.resolve()
    }
  })
]

export { maximumTimePeriodRule }
