import React from 'react'
import { InputNumber, InputNumberProps } from 'antd'

interface TextFieldNumberProps extends InputNumberProps {
  id?: string
  min?: number
  step?: number | string
  value?: number
  autoFocus?: boolean
  addonAfter?: string
  onChange?: () => {}
  spinner?: string
  inputType?: 'number' | undefined
  disabled?: boolean
  precision?: number
}

const TextFieldNumber = (props: TextFieldNumberProps) => {
  return (
    <div
      className={`text-field-number-input ant-input-group ${
        props.inputType === 'number' ? '-number' : ''
      }`}
    >
      <InputNumber
        {...props}
        className={`${props.inputType === 'number' ? '' : '_w-100'}`}
        defaultValue={props.defaultValue}
      />
      {props.addonAfter && (
        <span className='ant-input-group-addon text-field-number-addon'>
          {props.addonAfter}
        </span>
      )}
    </div>
  )
}

export default TextFieldNumber
