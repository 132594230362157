import React, { useState, useEffect } from 'react'
import {
  Form,
  Radio,
  Slider,
  FormInstance,
  Row,
  Col,
  RadioChangeEvent,
  Checkbox,
  Button,
  Tooltip,
  Typography
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import DeliveryAreaSettings from '../../SameDaySettings/DeliveryAreaSettings'
import { Store, LatLng } from '@slerp/controls'
import cloneDeep from 'lodash/cloneDeep'
import { DEFAULT_DELIVERY_RADIUS, DEFAULT_DELIVERY_TIME } from '../../utils'
import BusyDeliveryZone from './BusyDeliveryZone'
import { usePreviewDelivery, MIN_MILES, MIN_MINUTES } from './utils'
import ReadKml from './ReadKml'

const DeliveryZone = ({
  store,
  form,
  useStorePreOrderSettings = false,
  disabled = false
}: {
  form: FormInstance
  store: Store
  useStorePreOrderSettings?: boolean
  disabled?: boolean
}) => {
  const settings: any = useStorePreOrderSettings
    ? store.pre_order_settings?.[0]
    : store.settings

  const deliveryAreaProp = useStorePreOrderSettings
    ? settings?.delivery_area_setting?.delivery_area
    : settings?.delivery_area

  const isCustom = useStorePreOrderSettings
    ? settings?.delivery_area_setting?.custom_delivery_area
    : settings?.custom_delivery_area

  const defaultRadius = settings?.delivery_radius || DEFAULT_DELIVERY_RADIUS
  const defaultTime = settings?.delivery_time || DEFAULT_DELIVERY_TIME

  const [deliveryArea, setDeliveryArea] = useState<LatLng[]>(
    cloneDeep(deliveryAreaProp)
  )
  const [isByTime, setIsByTime] = useState<boolean>(!settings?.delivery_radius)

  const [deliveryRadius, setDeliveryRadius] = useState<number>(defaultRadius)
  const [deliveryTime, setDeliveryTime] = useState<number>(defaultTime)

  const [deliveryBusyRadius, setDeliveryBusyRadius] = useState<number>(
    store.settings.busy_delivery_radius || deliveryRadius
  )
  const [deliveryBusyTime, setDeliveryBusyTime] = useState<number>(
    store.settings.busy_delivery_time || deliveryTime
  )

  const [deliveryAreaTouched, setDeliveryAreaTouched] =
    useState<boolean>(isCustom)

  const [isChanged, setIsChanged] = useState<boolean>(false)

  useEffect(() => {
    setDeliveryArea(cloneDeep(deliveryAreaProp))
    setIsByTime(!settings?.delivery_radius)
    setDeliveryRadius(settings?.delivery_radius || DEFAULT_DELIVERY_RADIUS)
    setDeliveryTime(settings?.delivery_time || DEFAULT_DELIVERY_TIME)
  }, [store, settings, deliveryAreaProp])

  useEffect(() => {
    if (deliveryBusyRadius > deliveryRadius) {
      setDeliveryBusyRadius(deliveryRadius)
    }

    if (deliveryBusyTime > deliveryTime) {
      setDeliveryBusyTime(deliveryTime)
    }
  }, [deliveryRadius, deliveryTime, deliveryBusyRadius, deliveryBusyTime])

  const handlePreviewQueryComplete = (response: {
    previewDeliveryArea: LatLng[]
  }) => {
    setDeliveryArea(cloneDeep(response.previewDeliveryArea))
    updateTouched(false)
    setIsChanged(true)
  }

  const previewDeliveryArea = usePreviewDelivery({
    store,
    onCompleted: handlePreviewQueryComplete
  })

  const handleRadiusUpdate = (value: number) => {
    setDeliveryRadius(value)
    previewDeliveryArea(value, isByTime)
  }

  const handleTimeUpdate = (value: number) => {
    setDeliveryTime(value)
    previewDeliveryArea(value, isByTime)
  }

  const handleRadioUpdate = (e: RadioChangeEvent) => {
    const isByTime = e.target.value === 'time'

    setIsByTime(isByTime)

    const update = isByTime ? deliveryTime : deliveryRadius
    previewDeliveryArea(update, isByTime)

    return e.target.value
  }

  const updateTouched = (touched: boolean) => {
    setDeliveryAreaTouched(touched)
    form.setFieldsValue({
      delivery_area_touched: touched
    })

    if (!touched) {
      form.setFieldsValue({
        kml_upload: false
      })
    }
  }

  const handleRestore = () => {
    setDeliveryArea(cloneDeep(deliveryAreaProp))
    updateTouched(isCustom)
    setIsChanged(false)

    setDeliveryRadius(defaultRadius)
    setDeliveryTime(defaultTime)

    form.setFieldsValue({
      delivery_radius: defaultRadius,
      delivery_time: defaultTime
    })
  }

  const handleTouch = (path: LatLng[]) => {
    setDeliveryArea(cloneDeep(path))
    setIsChanged(true)
    updateTouched(true)
    form.setFieldsValue({
      delivery_area: path
    })
  }

  const handleKmlUpload = (path: LatLng[]) => {
    handleTouch(path)
    form.setFieldsValue({
      kml_upload: true
    })
  }

  return (
    <div className='delivery-zone'>
      <Row justify='space-between' align='middle'>
        <Typography.Title
          style={{ marginTop: '8px', marginBottom: '8px' }}
          level={5}
        >
          Delivery Zone
        </Typography.Title>
        {isChanged && <Button onClick={handleRestore}>Undo Changes</Button>}
      </Row>
      <Form.Item hidden name='delivery_area_touched'>
        <Checkbox className='checkbox' checked={deliveryAreaTouched} />
      </Form.Item>
      <Form.Item hidden name='delivery_area' />
      <Form.Item hidden name='kml_upload' />
      <Form.Item className='item' name='delivery_zone_type'>
        <Radio.Group
          className='radio'
          onChange={handleRadioUpdate}
          disabled={disabled}
        >
          <Radio value='time' data-testid='delivery-zone-time'>
            By Driving Time
          </Radio>
          <Radio value='distance' data-testid='delivery-zone-distance'>
            By Driving Distance
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Row align='middle'>
        <Col className='delivery-zone' span={21}>
          <Form.Item
            className={`item ${isByTime ? '' : '-hidden'}`}
            name='delivery_time'
          >
            <Slider
              step={1}
              min={MIN_MINUTES}
              max={30}
              className='slider'
              value={deliveryTime}
              onChange={handleTimeUpdate}
              disabled={disabled}
              tooltipVisible={false}
            />
          </Form.Item>

          <Form.Item
            name='delivery_radius'
            className={`item ${isByTime ? '-hidden' : ''}`}
          >
            <Slider
              step={0.1}
              min={MIN_MILES}
              max={4}
              className='slider'
              value={deliveryRadius}
              onChange={handleRadiusUpdate}
              disabled={disabled}
              tooltipVisible={false}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          {isByTime ? (
            <span>{deliveryTime} minutes</span>
          ) : (
            <span>{deliveryRadius} miles</span>
          )}
        </Col>
      </Row>
      {deliveryAreaTouched && isChanged && (
        <div className='popup'>
          <Typography.Text style={{ margin: 0, color: '#1513A0' }}>
            You have set a custom delivery area; any new changes will override
            it.
          </Typography.Text>
          <Tooltip
            title={
              <span>
                For more information see{' '}
                <a
                  href='https://support.slerp.com/knowledge/custom-delivery-radius'
                  rel='noreferrer'
                  target='_blank'
                >
                  here
                </a>
              </span>
            }
          >
            <QuestionCircleOutlined color='#77778E' className='_mt-2 _ml-4' />
          </Tooltip>
        </div>
      )}
      <Form.Item className='map'>
        <DeliveryAreaSettings
          storeLocation={store.address.geom.coordinates}
          deliveryPaths={deliveryArea}
          onTouched={handleTouch}
        />
      </Form.Item>

      <ReadKml onUpload={handleKmlUpload} />

      {!useStorePreOrderSettings && (
        <BusyDeliveryZone
          store={store}
          deliveryArea={deliveryArea}
          form={form}
          disabled={disabled}
          deliveryRadius={deliveryRadius}
          deliveryTime={deliveryTime}
          isByTime={isByTime}
        />
      )}
    </div>
  )
}

export default DeliveryZone
