import React from 'react'
import styled from '@emotion/styled'
import { Collapsible } from './Collapsible'

interface FormGroupProps {
  isCollapsible?: boolean
}

const FormGroup: React.FC<FormGroupProps> = ({
  children,
  isCollapsible = true
}) => (
  <Collapsible isCollapsible={isCollapsible}>
    <FormContainer>{children}</FormContainer>
  </Collapsible>
)

const FormContainer = styled.div`
  width: 100%;
  padding: 16px;
`

export { FormGroup }
