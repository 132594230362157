/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, Global, css } from '@emotion/core'
import 'sanitize.css'

/**
 * Copy of src/index.css
 */
const CanvasGlobalStyles: React.FC = () => {
  return (
    <Global
      styles={(css as any)`
        body {
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        
        * {
            box-sizing: border-box;
        }
        
        code {
            font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
        }
        
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }
        
        ul {
            list-style-type: none;
            padding: 0;
        }
        
        .fade-enter * {
            opacity: 0;
            z-index: 1;
        }
        
        .fade-enter.fade-enter-active * {
            opacity: 1;
            transition: opacity 450ms ease-in;
        }
      `}
    />
  )
}

export { CanvasGlobalStyles }
