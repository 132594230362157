import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UNPUBLISH_DISCOUNTS } from './queries'
import { UseUnpublishDiscountsProps } from './types'

const useUnpublishDiscounts = () => {
  const [responseData, setResponseData] = useState({
    data: {},
    loading: false,
    error: null
  })

  const [unpublish] = useMutation(UNPUBLISH_DISCOUNTS)

  const unpublishDiscounts = ({
    merchantId,
    discountIds,
    successCallback
  }: UseUnpublishDiscountsProps) => {
    unpublish({
      variables: { discountIds, merchantId }
    })
      .then((response) => {
        const unpublishedDiscounts = response?.data?.update_discounts || []
        setResponseData({
          data: unpublishedDiscounts,
          loading: false,
          error: null
        })
        successCallback && successCallback()
      })
      .catch((error: Error) => {
        setResponseData({
          data: [],
          loading: false,
          error: error.message
        })
      })
  }

  return [unpublishDiscounts, responseData]
}

export default useUnpublishDiscounts
