import React from 'react'
import { SVGProps } from 'react'

export interface SelectPrefixProps {
  prefixicon: SVGProps<SVGElement>
  children: React.ReactNode
  style?: React.CSSProperties
}

export const SelectPrefixWrapper = (props: SelectPrefixProps) => (
  <div className='select-prefix-wrapper' {...props}>
    {props.children}
    <div className='-icon'>{props.prefixicon}</div>
  </div>
)

//* Usage Guide:
//* Use this component to wrap an ANTD Select Component
//* This component requires a prefixIcon prop(SVG)

//* Recommendations:
//* Add a custom suffixIcon into ANTD's Select component
