import { gql } from '@apollo/client'

export const CREATE_SIGNATURE = gql`
  mutation createSignature(
    $id: uuid!
    $agreed: Boolean!
    $firstName: String!
    $lastName: String!
    $merchantId: uuid!
    $termsId: uuid!
  ) {
    insert_signatures(
      objects: {
        id: $id
        agreed: $agreed
        first_name: $firstName
        last_name: $lastName
        merchant_id: $merchantId
        terms_id: $termsId
        inserted_at: "now()"
        signed_at: "now()"
        updated_at: "now()"
      }
    ) {
      returning {
        agreed
        first_name
        last_name
        signed_at
      }
    }
  }
`

export const CREATE_MERCHANT_SUBSCRIPTION = gql`
  mutation createMerchantSubscription($merchant_id: String!) {
    createMerchantSubscription(merchant_id: $merchant_id)
  }
`
