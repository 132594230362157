import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import React from 'react'
import styled from '@emotion/styled'
import MessageForm, { Message, MessageType } from './MessageForm'
import Divider from 'antd/lib/divider'
import { useQuery } from '@apollo/client'
import { GET_MERCHANT_MESSAGES } from '../contexts/Shop/queries'
import { useSession } from 'packages/@slerp/accounts'
import Loading from 'components/Utils/Loading'
import Tooltip from 'antd/lib/tooltip'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { MessageBubble } from './MessageBubble'

const MESSAGE_TYPE: Array<MessageType> = ['warning', 'announcement']

const Messages = () => {
  const { merchant } = useSession()
  const { data, loading: isLoadingMerchantMessages } = useQuery<{
    getMerchantMessages: Array<Message>
  }>(GET_MERCHANT_MESSAGES, { variables: { merchantId: merchant.id } })

  if (isLoadingMerchantMessages) return <Loading />

  const messages = data?.getMerchantMessages ?? []

  return (
    <Container>
      <Row className='_mb-0'>
        <Col span={12} className='_pl-0 _pr-0'>
          <Description>
            <h3>Warnings and Announcements</h3>
            <span>
              Warnings and Announcements are shown in the heading of your shop
              pages.
            </span>
          </Description>

          <Row className='_mb-0'>
            <Col span={24}>
              <SectionDivider
                className='_mt-0'
                style={{ position: 'relative', left: '-24px' }}
              />
            </Col>
          </Row>

          <Row className='_mb-0'>
            <Col span={23}>
              {renderMessageSection('Same Day', 'sameday', messages)}
            </Col>
          </Row>

          <Row className='_mb-0'>
            <Col span={24}>
              <SectionDivider
                className='_mt-0'
                style={{ position: 'relative', left: '-24px' }}
              />
            </Col>
          </Row>

          <Row className='_mb-0'>
            <Col span={23}>
              {renderMessageSection('Pre Order', 'preorder', messages)}
            </Col>
          </Row>
        </Col>
        <Col span={12} className='_pl-36 _pr-36'>
          <Row gutter={[0, 24]}>
            <MessageBubble messageType='warning' />
            <MessageBubble messageType='announcement' />
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

const renderMessageSection = (
  title: string,
  orderType: 'sameday' | 'preorder',
  messages: Message[]
) => (
  <>
    <Col span={24}>
      <Row align='middle' className='_mb-0 section-label-container'>
        <h3 className='section-label'>{title}</h3>
        <Tooltip
          title={`Display an announcement or a warning when someone is ordering for ${
            orderType === 'sameday' ? 'the same day' : 'a future date'
          }.`}
        >
          <QuestionCircleOutlined className='_ml-8' />
        </Tooltip>
      </Row>
    </Col>
    {MESSAGE_TYPE.map((messageType) => (
      <Col span={24} key={messageType} className='section-content'>
        <FormDivider />
        <MessageForm
          orderType={orderType}
          messageType={messageType}
          messages={messages}
        />
      </Col>
    ))}
  </>
)

const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
  padding: 24px 0 0 24px;

  h3 {
    font-weight: 600;
    color: ${({ theme }: any) => theme.colors.text};
    line-height: 1.5;
  }

  .ant-row > .ant-col > .ant-col .section-label {
    margin: 0;
    display: inline-block;
    font-size: 16px;
  }

  .section-label-container {
    height: 45px;
  }
`

const Description = styled.div`
  display: flex;
  flex-flow: column;

  h3 {
    margin: 0 0 6px 0;
    font-size: 18px;
    line-height: 27px;
  }

  span {
    font-size: 14px;
    line-height: 1.5;
    color: ${({ theme }: any) => theme.colors.secondary75};
  }
`

const SectionDivider = styled(Divider)`
  border-top: 2px solid ${({ theme }: any) => theme.colors.background25};
  margin: 24px 0;
  width: calc(100% + 24px);
`

const FormDivider = styled(Divider)`
  margin: 16px 0;
`

export default Messages
