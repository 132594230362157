import LinkedInTag from 'react-linkedin-insight'
import env from '../../../env'

export const initializeLinkedInInsights = () => {
  LinkedInTag.init(env.LINKEDIN_INSIGHTS_ID)
}

export const trackLinkedInLead = () => {
  LinkedInTag.track(env.LINKEDIN_CONVERSION_ID)
}
