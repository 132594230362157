import gql from 'graphql-tag'

export const QUERY_ORDER_STATS = gql`
  query orderStats(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeId: ID
  ) {
    submitted: submittedOrderStats(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      count
      gmv
    }
    accepted: acceptedOrderStats(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      count
      gmv
      asap
      pre
      ssd
      delivery
      pickup
      table
    }
    statusCounts: orderCountsByStatus(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      count
      status
    }
    platform: orderPlatformStats(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      web_mobile
      web_desktop
      ios_app_mobile
      android_app_mobile
      unknown
    }
  }
`

export const QUERY_ORDER_DAILY_SUMMARY = gql`
  query orderStats(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeId: ID
  ) {
    series: orderDailySummarySeries(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      ts
      gmv
      count
    }
  }
`

export const QUERY_ORDER_HOURLY_SUMMARY = gql`
  query orderStats(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeId: ID
  ) {
    series: orderHourlySummarySeries(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      ts
      gmv
      count
    }
  }
`

export const QUERY_MERCHANT_STORES = gql`
  query stores($merchantId: uuid!, $email: String!) {
    stores(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
        _or: [
          {
            store_users: {
              user: {
                email: { _eq: $email }
                merchant_id: { _eq: $merchantId }
                role: { _eq: "manager" }
              }
            }
          }
          {
            users: {
              email: { _eq: $email }
              merchant_id: { _eq: $merchantId }
              role: { _eq: "member" }
            }
          }
          {
            merchant: {
              users: {
                email: { _eq: $email }
                merchant_id: { _eq: $merchantId }
                role: { _eq: "admin" }
              }
            }
          }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      archived_at
    }
  }
`
