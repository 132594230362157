import React from 'react'
import { Tabs, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import StatementsFilters from './StatementsFilters'
import ChargesFilters from './ChargesFilters'
import OrderSummaryFilters from './OrderSummaryFilters'
import CommonReports from './CommonReports'
import FranchiseFilters from './FranchiseFilters'
import PayoutReconciliationFilters from './PayoutReconciliationFilters'
import { Merchant } from '@slerp/controls'

const { TabPane } = Tabs

interface ReportProps {
  merchant: Merchant
}

const Reports = ({ merchant }: ReportProps) => {
  return (
    <div>
      <Tabs size='small'>
        <TabPane
          tab={
            <>
              Statements
              <Tooltip
                title={
                  <span>
                    Statements provide a full breakdown of orders, sales and
                    fees. Learn more{' '}
                    <a
                      href='https://support.slerp.com/en_gb/statements-r1r6Cj46u'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </span>
                }
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          key='1'
        >
          <StatementsFilters />
        </TabPane>
        <TabPane
          tab={
            <>
              Order Summary
              <Tooltip
                title={
                  <span>
                    Learn how to customise your reports{' '}
                    <a
                      href='https://support.slerp.com/en_us/filtering-downloading-reports-r1qDEu9ZK'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      here
                    </a>
                    .
                  </span>
                }
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          key='2'
        >
          <OrderSummaryFilters />
        </TabPane>
        <TabPane tab='Reports' key='3'>
          <CommonReports />
        </TabPane>
        {merchant?.setting?.franchise_enabled && (
          <TabPane tab='Franchise Payment Transfers' key='4'>
            <FranchiseFilters />
          </TabPane>
        )}
        <TabPane
          tab={
            <>
              Additional Charges
              <Tooltip
                title={
                  <span>
                    Additional charges may apply, particularly if an action is
                    taken post-purchase. These are summarised in this report.
                  </span>
                }
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          key='5'
        >
          <ChargesFilters />
        </TabPane>
        <TabPane
          tab={
            <>
              Payout Reconciliation
              <Tooltip
                title={
                  <span>
                    Payout Reconciliation report provides a breakdown of
                    transactions linked to each Stripe payout.
                  </span>
                }
                placement='right'
              >
                <QuestionCircleOutlined className='_ml-8' />
              </Tooltip>
            </>
          }
          key='6'
        >
          <PayoutReconciliationFilters />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Reports
