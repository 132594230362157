import React from 'react'
import { Col, Spin, Typography, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface SpinnerProps {
  message?: string
}

const { Text } = Typography

const Spinner = ({ message }: SpinnerProps) => {
  return (
    <Row className='_mb-0'>
      <Col span={1}>
        <Spin indicator={<LoadingOutlined spin />} />
      </Col>
      {message != null && (
        <Col>
          <Text>{message}</Text>
        </Col>
      )}
    </Row>
  )
}

export default Spinner
