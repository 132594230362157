import moment from 'moment-timezone'
import {
  StoreType,
  PreOrderSettings,
  Cutoff,
  OperatingHours,
  CutoffSchedule,
  OperatingSchedules,
  Order,
  StoreSettings
} from './types'
import { Timeslot } from './FulfillmentTimeSlot'

const DATE_FORMAT = 'YYYY-MM-DD'

export const disabledDate = (
  date: Date,
  currentDate: Date,
  store: StoreType,
  preOrderSettings: PreOrderSettings,
  order: Order
) => {
  const isBeforeToday = moment(date).isBefore(
    moment(currentDate).startOf('day')
  )
  const isBeforeOrderDate = moment(date).isBefore(
    moment(order.inserted_at).endOf('day')
  )

  if (isBeforeToday) return true

  const withinOperatingSchedule = order.is_preorder
    ? isDateValidForWarehouse(date, preOrderSettings)
    : true

  return !withinOperatingSchedule
}

const isDateValidForWarehouse = (
  date: Date,
  preOrderSettings: PreOrderSettings
) => {
  if (preOrderSettings?.cutoffs) {
    const specialSchedule = preOrderSettings?.special_cutoffs?.find(
      (schedule: Cutoff) =>
        moment(date).format(DATE_FORMAT) === schedule.cutoff_date
    )
    const operatingDays = Object.keys(preOrderSettings.cutoffs).filter(
      (key) => accessCutoffDay(preOrderSettings.cutoffs, key)['enabled']
    )
    const day = moment(date).format('dddd').toLowerCase()
    return specialSchedule
      ? specialSchedule.enabled
      : operatingDays.includes(day)
  }
  return false
}

const accessCutoffDay = (cutoffs: CutoffSchedule, day: string) => {
  switch (day) {
    case 'monday':
      return cutoffs.monday
    case 'tuesday':
      return cutoffs.tuesday
    case 'wednesday':
      return cutoffs.wednesday
    case 'thursday':
      return cutoffs.thursday
    case 'friday':
      return cutoffs.friday
    case 'saturday':
      return cutoffs.saturday
    case 'sunday':
      return cutoffs.sunday
    default:
      return {
        cutoff_date: null,
        enabled: false
      }
  }
}

export const accessOperatingDay = (
  operatingSchedules: OperatingSchedules,
  day: keyof OperatingSchedules
) => {
  if (operatingSchedules[day]) return operatingSchedules[day]
  return {
    enabled: false,
    start: null,
    end: null
  }
}

/*
  Moved this variable here since it's being used by
  the three generate* functions below.
*/
const ASAP_OFFSET = 45

export const generatePov3Timeslots = (
  store: StoreType,
  fulfillmentDate: Date,
  currentDate: Date
) => {
  const settings =
    store.pre_order_settings && store.pre_order_settings.length > 0
      ? store.pre_order_settings[0]
      : null
  if (!settings) return []

  const delivery_schedule = settings.delivery_schedule || []

  if (!delivery_schedule) return []
  const day = formatFulfillmentDay(fulfillmentDate)
  const regularSchedule = accessOperatingDay(
    delivery_schedule,
    day as keyof OperatingSchedules
  )
  const schedule = regularSchedule
  if (!schedule || !schedule.enabled || !schedule.start || !schedule.end)
    return []

  if (settings.fixed_delivery_times_enabled)
    return buildFixedTimeslot(
      fulfillmentDate,
      schedule.start,
      schedule.end,
      'Europe/London'
    )

  return createTimeIntervals(
    schedule.start,
    schedule.end,
    settings.delivery_interval,
    fulfillmentDate,
    ASAP_OFFSET,
    currentDate,
    'Europe/London'
  )
}

export const generatePreorderSettingsTimeslots = (
  preOrderSettings: PreOrderSettings,
  fulfillmentDate: Date,
  currentDate: Date,
  timezone: string = 'Europe/London'
) => {
  const { fixed_delivery_times_enabled, fixed_delivery_times } =
    preOrderSettings
  if (fixed_delivery_times_enabled && fixed_delivery_times) {
    const { start, end } = fixed_delivery_times
    return buildFixedTimeslot(fulfillmentDate, start, end, timezone)
  }

  const { delivery_schedule: deliverySchedule, delivery_interval: interval } =
    preOrderSettings
  if (!deliverySchedule) return []
  const day = formatFulfillmentDay(fulfillmentDate)
  const schedule = accessOperatingDay(deliverySchedule, day)
  if (!schedule) return []
  const { start, end, enabled } = schedule

  return enabled && start && end
    ? createTimeIntervals(
        start,
        end,
        interval,
        fulfillmentDate,
        ASAP_OFFSET,
        currentDate,
        timezone
      )
    : []
}

const formatFulfillmentDay = (fulfillmentDate: Date) =>
  moment(fulfillmentDate)
    .format('dddd')
    .toLowerCase() as keyof OperatingSchedules

export const buildFixedTimeslot = (
  fulfillmentDate: Date,
  start: string,
  end: string,
  timezone: string = 'Europe/London'
) => {
  if (fulfillmentDate && start && end) {
    const startString = buildTimeString(start)
    const endString = buildTimeString(end)

    const [startHour, startMinutes] = start.split(':')
    const adjustedDate = moment
      .tz(fulfillmentDate, timezone)
      .set({
        minute: parseInt(startMinutes, 10),
        hour: parseInt(startHour, 10)
      })
      .toISOString()

    return [
      {
        value: adjustedDate,
        range: `${startString} - ${endString}`
      }
    ]
  }
  return []
}

const buildTimeString = (timeString: string) => {
  const [hour, minute] = timeString.split(':')
  const parsedHour = parseInt(hour, 10)

  if (parsedHour === 12) {
    return `${parsedHour}:${minute} PM`
  } else if (parsedHour > 12) {
    return `${parsedHour - 12}:${minute} PM`
  } else {
    return `${parsedHour}:${minute} AM`
  }
}

const createTimeIntervals = (
  start: string | null,
  end: string | null,
  interval: number,
  selectedDate: Date,
  asapWindow: number,
  currentDate: Date,
  timezone: string = 'Europe/London'
) => {
  const [startHour, startMinutes] = start ? start.split(':') : [null, null]
  const [endHour, endMinutes] = end ? end.split(':') : [null, null]

  const selectedUTCDate = moment(selectedDate, timezone)
    .format(DATE_FORMAT)
    .toString()
  // Store Opening time
  const openingTime =
    startHour && startMinutes
      ? moment
          .tz(selectedUTCDate, DATE_FORMAT, timezone)
          .set({
            minute: parseInt(startMinutes, 10),
            hour: parseInt(startHour, 10)
          })
          .toDate()
      : null

  // Store closing time
  const closingTime =
    endHour && endMinutes
      ? moment
          .tz(selectedUTCDate, DATE_FORMAT, timezone)
          .set({
            minute: parseInt(endMinutes, 10),
            hour: parseInt(endHour, 10)
          })
          .toDate()
      : null

  const startTime = getClosestTime(
    openingTime,
    selectedDate,
    interval,
    asapWindow,
    currentDate,
    timezone
  )
  let intervals: Timeslot[] = []
  let referenceTime = moment.tz(startTime, timezone)

  while (
    referenceTime &&
    closingTime &&
    referenceTime
      .seconds(0)
      .milliseconds(0)
      .isBefore(moment(closingTime).seconds(0).milliseconds(0))
  ) {
    const value = referenceTime.toISOString()
    const nextReference = referenceTime.clone().add(interval, 'minutes')
    const range = `${referenceTime.format('HH:mm').toString()} - ${nextReference
      .format('HH:mm')
      .toString()}`

    const newInterval = { value, range }

    intervals = [...intervals, newInterval]

    referenceTime = nextReference
  }

  return intervals
}

const getClosestTime = (
  openingTime: Date | null,
  fulfillmentDate: Date,
  interval: number,
  asapWindow: number,
  currentDate: Date,
  timezone: string = 'Europe/London'
) => {
  const startOfDate = moment.tz(fulfillmentDate, timezone).startOf('day')
  const offsetOpening = moment
    .tz(openingTime, timezone)
    .clone()
    .add(-asapWindow, 'minutes')

  const startTime =
    openingTime &&
    moment
      .tz(startOfDate, timezone)
      .isSame(moment.tz(openingTime, timezone), 'day') &&
    moment.tz(currentDate, timezone).isAfter(offsetOpening)
      ? getNextTime(interval, currentDate, asapWindow, timezone)
      : openingTime

  return startTime
}

const getNextTime = (
  interval: number,
  currentDate: Date,
  asapWindow: number,
  timezone: string = 'Europe/London'
) => {
  const earliestDateTime = moment(currentDate)
    .clone()
    .add(asapWindow, 'minutes')

  const minutes = moment(earliestDateTime).minutes()
  const addedMinutes = Math.ceil(minutes / interval) * interval
  return earliestDateTime
    .clone()
    .startOf('hour')
    .add(addedMinutes, 'minutes')
    .toDate()
}

const getStorePrepTime = (settings: StoreSettings, fulfillmentType: string) => {
  const prepTime = getPrepTime(settings)

  return prepTime < 30
    ? getPrepTimeForFulfillment(prepTime, fulfillmentType)
    : prepTime
}

const getPrepTime = (storeSettings: StoreSettings) => {
  const {
    prep_mode,
    quiet_preparation_time,
    moderate_preparation_time,
    busy_preparation_time
  } = storeSettings
  if (prep_mode === 'quiet') return quiet_preparation_time
  if (prep_mode === 'moderate') return moderate_preparation_time
  if (prep_mode === 'busy') return busy_preparation_time
  return 0
}

const getPrepTimeForFulfillment = (
  prepTime: number,
  fulfillmentType: string
) => {
  if (fulfillmentType === 'delivery') {
    return 30
  } else {
    return prepTime
  }
}
