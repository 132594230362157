import { LatLng } from '@slerp/controls'
import isEqual from 'lodash/isEqual'

type MapRef = {
  map: any
  maps: any
}

interface DeliveryAreaProps {
  path: LatLng[]
  center: any
  radius: number
  onTouched: (path: any) => void
  mapRef: MapRef
  options: any
}

export class DeliveryArea {
  path: LatLng[] = []
  polygon: any
  center: LatLng
  radius: number
  onTouched: (path: LatLng[]) => void
  mapRef: MapRef

  constructor(props: DeliveryAreaProps) {
    this.radius = props.radius
    this.center = props.center

    this.onTouched = props.onTouched
    this.mapRef = props.mapRef

    this.polygon = new props.mapRef.maps.Polygon({
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.05,
      map: props.mapRef.map,
      ...props.options
    })

    const path = props.path?.length ? props.path : this.calculateInitialPath()
    this.setPath(path)
  }

  private fitBounds(path: LatLng[]) {
    const bounds = new this.mapRef.maps.LatLngBounds()
    path.forEach((point: LatLng) => {
      bounds.extend(point)
    })

    this.mapRef.map.fitBounds(bounds)
  }

  public resetToDefault() {
    this.setPath(this.calculateInitialPath())
  }

  calculateInitialPath() {
    const POLYGON_POINTS = 8

    const SKEW_FACTOR = 1.6
    const MILES_TO_DEGREES = 1.0 / 69
    const latRadius = this.radius * MILES_TO_DEGREES
    const lngRadius = latRadius * SKEW_FACTOR

    const latCenter = this.center.lat
    const lngCenter = this.center.lng

    let paths = []

    for (let i = 0; i < POLYGON_POINTS; i++) {
      const theta = (Math.PI * 2 * i) / POLYGON_POINTS
      const lat = latCenter + Math.cos(theta) * latRadius
      const lng = lngCenter + Math.sin(theta) * lngRadius

      paths.push({ lat, lng })
    }

    return paths
  }

  private updatePath() {
    this.path = this.polygon
      .getPath()
      .getArray()
      .map((latLng: any) => ({ lat: latLng.lat(), lng: latLng.lng() }))
  }

  private addListeners() {
    const path = this.polygon.getPath()

    if (!path) {
      return
    }

    path.addListener('set_at', () => {
      this.updatePath.bind(this)()
      this.onTouched(this.path)
    })
    path.addListener('insert_at', () => {
      this.updatePath.bind(this)()
      this.onTouched(this.path)
    })
  }

  getPath() {
    return this.path
  }

  setPath(path: LatLng[]) {
    if (isEqual(this.path, path)) {
      return
    }

    this.fitBounds(path)
    this.path = path
    this.polygon.setPaths(path)
    this.addListeners()
  }

  setActive(active: boolean) {
    this.polygon.setVisible(active)
  }
}
