import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import Subform from './Subform'
import { HiddenInputs } from './styledComponents'
import { Rule } from 'antd/lib/form'

const postcodePattern =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

interface AddressSubformProps {
  form: FormInstance
  onSave: () => void
}

const AddressSubform = ({ form, onSave }: AddressSubformProps) => {
  const { drop_off: initialValues } = form.getFieldsValue()

  const handleSave = (
    setShowInputs: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const { drop_off_subform } = form.getFieldsValue()

    form
      .validateFields([
        ['drop_off_subform', 'line1'],
        ['drop_off_subform', 'postalCode'],
        ['drop_off_subform', 'city']
      ])
      .then(() => {
        form.setFieldsValue({ drop_off: { ...drop_off_subform } })
        setShowInputs(false)
        onSave()
      })
      .catch((error) => {
        const { errorFields } = error
        if (errorFields.length > 1) return setShowInputs(true)
      })
  }

  const handleCancel = () => {
    form.setFieldsValue({ drop_off_subform: { ...initialValues } })
  }

  const postalCodeValidatorRule: Rule = () => ({
    async validator(_: any, value: string) {
      if (!postcodePattern.test(value)) {
        return Promise.reject('Postal code is invalid')
      }

      return Promise.resolve()
    }
  })

  const formattedAddress = [
    initialValues?.line1,
    initialValues?.line2,
    initialValues?.city,
    initialValues?.zip
  ]
    .filter((item) => Boolean(item))
    .join(', ')

  return (
    <>
      <Subform
        onSave={handleSave}
        onCancel={handleCancel}
        label='Dropoff to:'
        value={formattedAddress}
      >
        <Form.Item
          rules={[{ required: true, message: 'Line 1 is required' }]}
          validateTrigger='onBlur'
          label='Line 1'
          name={['drop_off_subform', 'line1']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          validateTrigger='onBlur'
          label='Line 2'
          name={['drop_off_subform', 'line2']}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Postal code is required' },
            postalCodeValidatorRule
          ]}
          validateTrigger='onBlur'
          name={['drop_off_subform', 'postalCode']}
          label='Postcode'
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'City is required'
            }
          ]}
          validateTrigger='onBlur'
          name={['drop_off_subform', 'city']}
          label='City'
        >
          <Input />
        </Form.Item>
      </Subform>
      <HiddenInputs>
        <Form.Item name='drop_off' />
        <Form.Item name='drop_off_subform' />
        <Form.Item name={['drop_off_subform', 'line2']} />
        <Form.Item name={['drop_off_subform', 'line2']} />
        <Form.Item name={['drop_off_subform', 'country']} />
        <Form.Item name={['drop_off_subform', 'lat']} />
        <Form.Item name={['drop_off_subform', 'lng']} />
        <Form.Item name={['drop_off_subform', 'formattedAddress']} />
      </HiddenInputs>
    </>
  )
}

export default AddressSubform
