import React from 'react'
import { Button, Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd'
import isNumber from 'lodash/isNumber'
import isEmpty from 'lodash/isEmpty'
import {
  CloseCircleOutlined,
  DownOutlined,
  PoundOutlined
} from '@ant-design/icons'
import { DeliveryPricingBand, PricingBandFieldType } from './type'
import { ValidateDeliveryPricingBand } from './utils'
import { PercentageOutlined } from '@ant-design/icons'
import { onFocusScrollNumber } from 'components/Utils/price'

interface PricingBandRowProps {
  pricingBand: DeliveryPricingBand
  pricingBandIndex: number
  onChange: () => void
  onRemove: () => void
  onlyRow: boolean
  isLastRow: boolean
}

interface ThresholdInputProps {
  value: number
  testId: string
  disabled: boolean
  fieldName: PricingBandFieldType
}

const PricingBandRow = ({
  pricingBand,
  disabled = false,
  onChange,
  onRemove,
  pricingBandIndex,
  onlyRow,
  isLastRow
}: PricingBandRowProps) => {
  const { lowerThreshold, upperThreshold, percentageDiscount } = pricingBand
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPricingBandInvalid, validationFailMessages] =
    ValidateDeliveryPricingBand({ pricingBand })

  const ThresholdInput = ({
    value,
    testId,
    isDisabled,
    fieldName
  }: ThresholdInputProps) => {
    const withInvalidUpperThresholdRange =
      validationFailMessages['withInvalidUpperThresholdRange']
    const withInvalidLowerThresholdRange =
      validationFailMessages['withInvalidLowerThresholdRange']
    const upperThresholdFail =
      fieldName === 'upperThreshold' && withInvalidUpperThresholdRange
    const lowerThresholdFail =
      fieldName === 'lowerThreshold' && withInvalidLowerThresholdRange
    let message = ''

    switch (fieldName) {
      case 'upperThreshold':
        message =
          withInvalidUpperThresholdRange &&
          `Your upper limit for this delivery pricing band cannot be lower than £${lowerThreshold}`
        break
      case 'lowerThreshold':
        message =
          withInvalidLowerThresholdRange &&
          `Your lower limit for this delivery pricing band cannot be higher than £${upperThreshold}`
        break
      default:
        message = ''
        break
    }

    const className =
      upperThresholdFail || (!isDisabled && lowerThresholdFail)
        ? `${fieldName} _w-100 -error`
        : `${fieldName} _w-100`

    return (
      <Tooltip title={message}>
        <Input
          className={className}
          disabled={isDisabled}
          type='number'
          addonAfter={<PoundOutlined />}
          data-testid={testId}
          min={0}
          defaultValue={value}
          onFocus={onFocusScrollNumber}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            event.target.removeEventListener('wheel', (e: Event) =>
              e.preventDefault()
            )
            const val = event.target.value
            const normalizedValue = isNumber(val) ? parseInt(val) : val
            onChange({
              fieldName,
              pricingBandIndex,
              value: normalizedValue
            })
          }}
        />
      </Tooltip>
    )
  }

  const PercentageInput = ({
    value,
    testId,
    isDisabled,
    fieldName
  }: ThresholdInputProps) => {
    const invalidPercentageDiscount =
      validationFailMessages['withInvalidPercentageDiscount']
    const invalidPercentageDiscountRange =
      validationFailMessages['withInvalidPercentageDiscountRange']
    const percentageFail =
      invalidPercentageDiscount || invalidPercentageDiscountRange
    const className = percentageFail ? '_w-100 -error' : '_w-100'
    const message = percentageFail && 'The discount % should be between 1-100%'

    return (
      <Tooltip title={message}>
        <Input
          disabled={isDisabled}
          className={className}
          type='number'
          addonAfter={<PercentageOutlined />}
          data-testid={testId}
          defaultValue={value}
          onFocus={onFocusScrollNumber}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            event.target.removeEventListener('wheel', (e: Event) =>
              e.preventDefault()
            )
            const normalizedValue = isEmpty(event.target.value)
              ? null
              : parseInt(event.target.value)
            onChange({
              fieldName,
              pricingBandIndex,
              value: normalizedValue
            })
          }}
        />
      </Tooltip>
    )
  }

  const isLastRowAndUpwards =
    isLastRow &&
    (upperThreshold == null || upperThreshold.length === 0 ? true : false)

  const isLastRowWithValue =
    isLastRow &&
    (upperThreshold == null || upperThreshold.length === 0 ? false : true)

  const UpperThresholdMenu = (
    <Menu
      className='-plain'
      onClick={({ key }: { key: string }) => {
        onChange({
          fieldName: 'upperThreshold',
          pricingBandIndex,
          value: null
        })
      }}
    >
      <Menu.Item key='and-upwards'>and upwards</Menu.Item>
    </Menu>
  )

  const UpperThresholdInput = () => {
    const withInvalidUpperThresholdRange =
      !!validationFailMessages['withInvalidUpperThresholdRange']
    const dropdownClassname = withInvalidUpperThresholdRange
      ? 'upperThreshold error'
      : 'upperThreshold'

    return isLastRowWithValue ? (
      <Row align='middle'>
        <Col span={20} className='upper-threshold-input-col'>
          <ThresholdInput
            testId='pricing-band-row-to-field'
            value={upperThreshold}
            isDisabled={disabled || !isLastRow}
            fieldName='upperThreshold'
          />
        </Col>
        <Col span={4} className='upper-threshold-dropdown-col'>
          <Dropdown
            overlay={UpperThresholdMenu}
            trigger={['hover']}
            className={dropdownClassname}
          >
            <div>
              <DownOutlined style={{ fontSize: '12px', cursor: 'pointer' }} />
            </div>
          </Dropdown>
        </Col>
      </Row>
    ) : (
      <ThresholdInput
        testId='pricing-band-row-to-field'
        value={upperThreshold}
        isDisabled={disabled || !isLastRow}
        fieldName='upperThreshold'
      />
    )
  }

  return (
    <>
      <Row
        gutter={[4, 0]}
        data-testid='delivery-pricing-bands-row'
        className='delivery-pricing-band-row'
      >
        <Col span={5}>
          <ThresholdInput
            testId='pricing-band-row-from-field'
            value={lowerThreshold}
            isDisabled={disabled || !onlyRow}
            fieldName='lowerThreshold'
          />
        </Col>
        <Col span={5}>
          {isLastRowAndUpwards ? (
            <Row
              gutter={[4, 0]}
              className='_mb-0 _ht-36 upwards-upper-threshold'
              align='middle'
              justify='center'
              onClick={() => {
                onChange({
                  fieldName: 'upperThreshold',
                  pricingBandIndex,
                  value: '0'
                })
              }}
            >
              <Col>and upwards</Col>
              <Col>
                <DownOutlined />
              </Col>
            </Row>
          ) : (
            <UpperThresholdInput />
          )}
        </Col>
        <Col span={5}>
          <PercentageInput
            value={percentageDiscount}
            testId='pricing-band-row-discount-field'
            isDisabled={disabled}
            fieldName='percentageDiscount'
          />
        </Col>
        <Col span={1}>
          {isLastRow && (
            <Button
              icon={<CloseCircleOutlined />}
              disabled={disabled}
              type='link'
              data-testid='pricing-band-row-remove-btn'
              className='-primary -plain'
              onClick={onRemove}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

export default PricingBandRow
