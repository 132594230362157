import { gql } from '@apollo/client'

const buildGetMerchantModifiersQuery = (isActive?: boolean) => {
  const archiveStateFilter = isActive
    ? `archived_at: { _is_null: ${isActive} }`
    : ``

  return gql`
    query getMerchantModifiers(
      $merchantId: uuid!
    ) {
      modifiers(
        where: {
          merchant_id: {
            _eq: $merchantId
          },
          ${archiveStateFilter}
        }
        order_by: { inserted_at: desc }
      ) {
        id
        name
        sku
        source_id
        price
        vat
        alcoholic: restrictions(path: "alcoholic")
        image
        archived_at
        calorie_data
        allergens
        store_modifiers(
          where: { published_at: { _is_null: false } }
        ) {
          in_stock
          published_at
        }
        modifier_groups: modifier_group_modifiers(
          where: {
            modifier_group: { archived_at: { _is_null: true } }
          }
        ) {
          modifier_group_id
          modifier_group {
            name
            description
          }
        }
        restrictions
        availabilities {
          fulfillment_type
          is_available
          id
        }
      }
    }
  `
}

const GET_MERCHANT_MODIFIER_GROUPS = gql`
  query getMerchantModifierGroups($merchantId: uuid!, $active: Boolean = true) {
    modifier_groups(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: $active }
      }
      order_by: { name: asc }
    ) {
      id
      name
      description
      sku
      source_id
      minimum
      minimum_enabled
      maximum
      maximum_enabled
      archived_at
      modifier_arrangement
      modifier_group_modifiers {
        modifier_id
      }
    }
  }
`

const CREATE_MODIFIER = gql`
  mutation createModifier(
    $isPublished: Boolean
    $modifierGroups: [String]
    $name: String
    $price: Decimal!
    $restrictions: Restriction
    $sku: String
    $sourceId: String
    $vat: Decimal!
    $calorieData: CalorieDataParams
    $allergens: [String]
    $availabilities: [CreateAvailabilityParams]
  ) {
    createModifier(
      isPublished: $isPublished
      modifierGroups: $modifierGroups
      name: $name
      price: $price
      restrictions: $restrictions
      sku: $sku
      sourceId: $sourceId
      vat: $vat
      calorieData: $calorieData
      allergens: $allergens
      availabilities: $availabilities
    ) {
      id
    }
  }
`
const UPDATE_MODIFIER = gql`
  mutation updateModifier(
    $id: ID!
    $name: String
    $sku: String
    $sourceId: String
    $price: Float
    $vat: Float
    $restrictions: Restriction
    $isPublished: Boolean
    $modifierGroups: [String]
    $calorieData: CalorieDataParams
    $allergens: [String]
    $availabilities: [UpdateAvailabilityParams]
  ) {
    updateModifier(
      id: $id
      name: $name
      sku: $sku
      sourceId: $sourceId
      price: $price
      vat: $vat
      restrictions: $restrictions
      isPublished: $isPublished
      modifierGroups: $modifierGroups
      calorieData: $calorieData
      allergens: $allergens
      availabilities: $availabilities
    ) {
      id
    }
  }
`

const GET_STORE_IDS = gql`
  query queryStores($merchantSlug: String!) {
    stores(where: { merchant: { slug: { _eq: $merchantSlug } } }) {
      id
    }
  }
`

const ARCHIVE_MODIFIER = gql`
  mutation archiveModifier($modifierId: ID!) {
    archiveModifier(modifierId: $modifierId)
  }
`

const UNARCHIVE_MODIFIER = gql`
  mutation unarchiveModifier($modifierId: ID!) {
    unarchiveModifier(modifierId: $modifierId)
  }
`

const CREATE_MODIFIER_GROUP = gql`
  mutation createModifierGroup(
    $id: uuid!
    $name: String!
    $sku: String
    $source_id: String
    $updated_by_id: uuid!
    $modifier_arrangement: jsonb
    $description: String!
    $maximum: Int
    $maximum_enabled: Boolean
    $minimum: Int
    $minimum_enabled: Boolean
    $merchant_id: uuid!
    $modifier_group_modifiers: [modifier_group_modifiers_insert_input!]!
  ) {
    insert_modifier_groups(
      objects: {
        id: $id
        name: $name
        sku: $sku
        source_id: $source_id
        updated_by_id: $updated_by_id
        modifier_arrangement: $modifier_arrangement
        description: $description
        maximum: $maximum
        maximum_enabled: $maximum_enabled
        minimum: $minimum
        minimum_enabled: $minimum_enabled
        merchant_id: $merchant_id
      }
    ) {
      returning {
        id
        name
        description
      }
    }
    insert_modifier_group_modifiers(objects: $modifier_group_modifiers) {
      affected_rows
    }
  }
`

const UPDATE_MODIFIER_GROUP = gql`
  mutation updateMerchantModifierGroups(
    $id: uuid!
    $updated_by_id: uuid!
    $name: String!
    $sku: String
    $source_id: String
    $description: String!
    $maximum: Int
    $maximum_enabled: Boolean
    $minimum: Int
    $minimum_enabled: Boolean
    $modifier_arrangement: jsonb
    $modifier_group_modifiers: [modifier_group_modifiers_insert_input!]!
  ) {
    update_modifier_groups(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        sku: $sku
        source_id: $source_id
        updated_by_id: $updated_by_id
        description: $description
        maximum: $maximum
        maximum_enabled: $maximum_enabled
        minimum: $minimum
        minimum_enabled: $minimum_enabled
        modifier_arrangement: $modifier_arrangement
      }
    ) {
      affected_rows
    }

    delete_modifier_group_modifiers(
      where: { modifier_group_id: { _eq: $id } }
    ) {
      affected_rows
    }

    insert_modifier_group_modifiers(objects: $modifier_group_modifiers) {
      affected_rows
    }
  }
`

const ARCHIVE_MODIFIER_GROUP = gql`
  mutation archiveModifierGroup($modifierGroupId: ID!) {
    archiveModifierGroup(modifierGroupId: $modifierGroupId)
  }
`

const UNARCHIVE_MODIFIER_GROUP = gql`
  mutation unarchiveModifierGroup($modifierGroupId: ID!) {
    unarchiveModifierGroup(modifierGroupId: $modifierGroupId)
  }
`

const UPLOAD_MODIFIER_IMAGE = gql`
  mutation uploadModifierImageFile($modifierId: String!, $image: Upload!) {
    uploadModifierImageFile(modifierId: $modifierId, image: $image) {
      id
      image
    }
  }
`

const TOGGLE_MODIFIER_ADD_TO_INVENTORY_STATE = gql`
  mutation toggleProductAddToInventoryState(
    $modifierId: uuid!
    $publishedAt: timestamp
  ) {
    update_store_modifiers(
      where: { modifier_id: { _eq: $modifierId } }
      _set: { published_at: $publishedAt, updated_at: "now()" }
    ) {
      affected_rows
    }
  }
`

export {
  buildGetMerchantModifiersQuery,
  GET_MERCHANT_MODIFIER_GROUPS,
  CREATE_MODIFIER,
  GET_STORE_IDS,
  UPDATE_MODIFIER,
  CREATE_MODIFIER_GROUP,
  UPDATE_MODIFIER_GROUP,
  UPLOAD_MODIFIER_IMAGE,
  TOGGLE_MODIFIER_ADD_TO_INVENTORY_STATE,
  ARCHIVE_MODIFIER_GROUP,
  UNARCHIVE_MODIFIER_GROUP,
  ARCHIVE_MODIFIER,
  UNARCHIVE_MODIFIER
}
