import React from 'react'
import { Result } from 'antd'

const NotFound = () => (
  <Result
    status='404'
    title='Page not found.'
    subTitle='Sorry, the page you were looking for does not exist. Please make sure that the url you entered is correct.'
  />
)

export default NotFound
