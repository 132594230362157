import { gql } from '@apollo/client'

export const CREATE_APP_SETTINGS = gql`
  mutation createMerchantAppSettings(
    $merchantId: uuid!
    $default_landing: String!
    $custom_content_enabled: Boolean
    $sections: [SectionParams]
  ) {
    createAppSettings(
      merchantId: $merchantId
      defaultLanding: $default_landing
      customContentEnabled: $custom_content_enabled
      sections: $sections
    ) {
      id
    }
  }
`

export const CREATE_BASIC_APP_SETTINGS = gql`
  mutation createBasicAppSettings($object: app_settings_insert_input!) {
    insert_app_settings_one(object: $object) {
      id
    }
  }
`

export const UPDATE_APP_SETTINGS = gql`
  mutation updateMerchantAppSettings(
    $id: ID!
    $default_landing: String
    $custom_content_enabled: Boolean
    $sections: [SectionParams]
    $footer_nav: Boolean
    $location_products_view: String
    $search_bar: Boolean
    $locations_map: Boolean
    $show_images: Boolean
    $hide_status_bar: Boolean
    $use_image_stamp: Boolean
    $splash_page: Boolean
    $hide_additional_button: Boolean
    $splash_additional_button_text: String
    $splash_additional_button_link: String
    $vertical_stamp_card_layout: Boolean
  ) {
    updateAppSettings(
      id: $id
      defaultLanding: $default_landing
      customContentEnabled: $custom_content_enabled
      sections: $sections
      footer_nav: $footer_nav
      location_products_view: $location_products_view
      search_bar: $search_bar
      locations_map: $locations_map
      show_images: $show_images
      hide_status_bar: $hide_status_bar
      use_image_stamp: $use_image_stamp
      splash_page: $splash_page
      hide_additional_button: $hide_additional_button
      splash_additional_button_text: $splash_additional_button_text
      splash_additional_button_link: $splash_additional_button_link
      vertical_stamp_card_layout: $vertical_stamp_card_layout
    ) {
      id
    }
  }
`

export const UPDATE_BASIC_APP_SETTINGS = gql`
  mutation updateBasicAppSettings(
    $updateValues: app_settings_set_input
    $id: uuid!
  ) {
    update_app_settings(where: { id: { _eq: $id } }, _set: $updateValues) {
      returning {
        id
      }
    }
  }
`

export const QUERY_APP_SETTINGS = gql`
  query getAppSettings($merchantId: uuid!) {
    app_settings(where: { merchant_id: { _eq: $merchantId } }) {
      id
      default_landing
      custom_content_enabled
      sections
      footer_nav
      location_products_view
      search_bar
      locations_map
      show_images
      hide_status_bar
      use_image_stamp
      splash_page
      hide_additional_button
      splash_additional_button_text
      splash_additional_button_link
      vertical_stamp_card_layout
    }
  }
`

export const SEND_APP_NOTIFICATION = gql`
  mutation sendAppNotifications(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeIds: [ID]!
    $title: String!
    $notificationTitle: String!
    $notificationBody: String!
    $hasOrdered: Boolean
    $hasStamped: Boolean
    $hasUnredeemedRewards: Boolean
    $hasBirthday: Boolean
    $count: Int
    $exception: Boolean
    $sendToAll: Boolean
    $isPublished: Boolean
    $publishOn: String
  ) {
    sendAppNotification(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      title: $title
      notificationTitle: $notificationTitle
      notificationBody: $notificationBody
      hasOrdered: $hasOrdered
      hasStamped: $hasStamped
      hasUnredeemedRewards: $hasUnredeemedRewards
      hasBirthday: $hasBirthday
      count: $count
      exception: $exception
      sendToAll: $sendToAll
      isPublished: $isPublished
      publishOn: $publishOn
    )
  }
`

export const PREVIEW_CAMPAIGN_CUSTOMER_DEVICE_TOKENS = gql`
  query previewCampaignCustomerDeviceTokens(
    $startDate: String!
    $endDate: String!
    $storeIds: [ID]!
    $hasOrdered: Boolean
    $hasStamped: Boolean
    $hasUnredeemedRewards: Boolean
    $hasBirthday: Boolean
    $count: Int
    $exception: Boolean
    $sendToAll: Boolean
  ) {
    previewCampaignCustomerDeviceTokens(
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      hasOrdered: $hasOrdered
      hasStamped: $hasStamped
      hasUnredeemedRewards: $hasUnredeemedRewards
      hasBirthday: $hasBirthday
      count: $count
      exception: $exception
      sendToAll: $sendToAll
    ) {
      id
    }
  }
`

export const LIST_CAMPAIGNS = gql`
  query listCampaigns($isPublished: Boolean) {
    listCampaigns(isPublished: $isPublished) {
      id
      title
      notificationTitle
      notificationBody
      notificationsSentTotal
      notificationsSentSuccess
      publishOn
      insertedAt
    }
  }
`

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($id: ID!, $campaign: CampaignParams!) {
    updateCampaign(id: $id, campaign: $campaign) {
      id
      title
      notificationTitle
      notificationBody
      publishOn
      insertedAt
    }
  }
`

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($id: ID!) {
    archiveCampaign(id: $id) {
      id
    }
  }
`
