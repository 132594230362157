import React, { useEffect, useState, useRef } from 'react'
import { Card, Checkbox, Col, Collapse, Row } from 'antd'
import {
  CategoriesArrangements,
  InventoryItem,
  InventoryGroup,
  SortedCategory,
  SortedProduct
} from '../types'
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc'
import { DraggableIcon, RightArrowIcon, DownArrowIcon } from '@slerp/assets'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import arrayMove from 'array-move'
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel'

interface InventoryCollapsePanelsProps {
  inventoryGroups: InventoryGroup[]
  sortedGroups: SortedCategory[]
  categoriesArrangements?: CategoriesArrangements[]
  onChange: (updatedInventoryItems: InventoryItem[]) => void
  setSortedGroups: () => void
}

const sortableContainerStyle = {
  margin: '0'
}

const InventoryCollapsePanels = ({
  inventoryGroups,
  sortedGroups,
  categoriesArrangements,
  onChange,
  setSortedGroups
}: InventoryCollapsePanelsProps) => {
  const containerRef = useRef(null)

  const [localArrangements, setLocalArrangements] = useState<
    CategoriesArrangements[]
  >(categoriesArrangements)

  const [selectedGroupId, setSelectedGroupId] = useState<string>('none')
  const [selectedProductId, setSelectedProductId] = useState<string>('none')

  const sortCategoriesArrangements = (
    inventoryGroups,
    categoriesArrangements
  ) => {
    const sorted = inventoryGroups
      .map((group) => {
        const catArr = categoriesArrangements.find(
          (catArr) => catArr.category_id === group.id
        ) || {
          id: group.id,
          index: undefined,
          category_id: group.id,
          products_arrangements: []
        }

        const groupedItemsByProduct = groupBy(
          group.items,
          (item) => item.productId
        )

        const sortedProducts = Object.entries(groupedItemsByProduct)
          .map(([productId, items]) => {
            const firstItem = items.find(() => true)
            const defaultVariant = items.find(
              (item) => item.id === firstItem.productDefaultVariantId
            )

            const prodArr = catArr?.products_arrangements?.find(
              (prodArr) => prodArr.product_id === productId
            ) || {
              id: productId,
              index: undefined,
              product_id: productId,
              categories_arrangements_id: catArr.id
            }

            return {
              id: productId,
              name: firstItem.productName,
              allergens: firstItem.productAllergens,
              default: defaultVariant,
              variants: items,
              index: prodArr?.index,
              prodArr: prodArr
            }
          })
          .sort((a, b) => {
            if (Number.isInteger(a.index) && Number.isInteger(b.index)) {
              return a.index - b.index
            }
            if (Number.isInteger(a.index) && !Number.isInteger(b.index)) {
              return -1
            }
            if (!Number.isInteger(a.index) && Number.isInteger(b.index)) {
              return 1
            }
            return 0
          })

        return {
          id: group.id,
          name: group.name,
          products: sortedProducts,
          items: group.items,
          index: catArr?.index,
          catArr: catArr
        }
      })
      .sort((a, b) => {
        if (Number.isInteger(a.index) && Number.isInteger(b.index)) {
          return a.index - b.index
        }
        if (Number.isInteger(a.index) && !Number.isInteger(b.index)) {
          return -1
        }
        if (!Number.isInteger(a.index) && Number.isInteger(b.index)) {
          return 1
        }
        return 0
      })

    return sorted
  }

  const handleGroupsSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newSortedGroups = arrayMove(sortedGroups, oldIndex, newIndex)
    const newLocalArrangements = newSortedGroups.map((sG, index) => {
      return {
        ...sG.catArr,
        index: index
      }
    })

    setLocalArrangements(newLocalArrangements)
  }

  const handleItemsSortEnd = ({ oldIndex, newIndex }: SortEnd, group) => {
    const newSortedItems = arrayMove(group.products, oldIndex, newIndex)
    const newProdArr = newSortedItems.map((sI, index) => {
      return {
        ...sI.prodArr,
        index: index
      }
    })

    const newLocalArrangements = localArrangements.map((lA) => {
      if (group.catArr.id === lA.id) {
        return {
          ...lA,
          products_arrangements: newProdArr
        }
      } else {
        return lA
      }
    })

    setLocalArrangements(newLocalArrangements)
  }

  useEffect(() => {
    if (categoriesArrangements.length === 0 && inventoryGroups.length > 0) {
      const tempArr = inventoryGroups.map((iG, index) => {
        return {
          id: iG.id,
          index: index,
          category_id: iG.id,
          products_arrangements: []
        }
      })
    } else {
      setLocalArrangements(categoriesArrangements)
    }
  }, [categoriesArrangements])

  useEffect(() => {
    const sorted = sortCategoriesArrangements(
      inventoryGroups,
      localArrangements
    )

    setSortedGroups(sorted)
  }, [inventoryGroups, localArrangements])

  const SortableProduct = SortableElement(
    ({ product }: { product: SortedProduct }) => {
      const { id: productId, name, variants, default: productDefault } = product
      const pvLength = variants.length ?? 0
      const hasVariants = pvLength > 1

      return (
        <Card
          data-testid={`draggable-category-item-${name}`}
          className={`width-100`}
          bodyStyle={{
            cursor: 'grab',
            padding: 8
          }}
        >
          <Collapse
            defaultActiveKey={selectedProductId}
            accordion
            expandIconPosition='right'
            expandIcon={() => {
              if (pvLength <= 1 && Boolean(productDefault)) return null
              if (selectedProductId === productId) return <DownArrowIcon />
              else return <RightArrowIcon />
            }}
            ghost
            onChange={(key) => setSelectedProductId(key as string)}
          >
            <Collapse.Panel
              key={productId}
              collapsible={
                pvLength > 1 || !Boolean(productDefault)
                  ? ('icon' as CollapsibleType)
                  : ('disabled' as CollapsibleType)
              }
              header={
                <Row align='middle' className='-category-list'>
                  {selectedProductId !== productId && (
                    <Col span={1}>{<DraggableIcon />}</Col>
                  )}
                  <Col span={selectedProductId !== productId ? 22 : 23}>
                    <Row
                      align='middle'
                      className={`-category-list ${
                        selectedProductId === productId && '_ml-8'
                      }`}
                    >
                      <Col span={hasVariants ? 12 : 20}>{name}</Col>
                      <Col span={hasVariants ? 12 : 4}>
                        <Row
                          gutter={[8, 4]}
                          align='middle'
                          justify='end'
                          className='-category-list'
                        >
                          <Col>
                            {(pvLength > 1 || !Boolean(productDefault)) && (
                              <>
                                {Boolean(productDefault)
                                  ? pvLength - 1
                                  : pvLength}{' '}
                                Variants
                              </>
                            )}
                          </Col>
                          <Col>
                            <span onClick={(e) => e.stopPropagation()}>
                              <Checkbox
                                id={`select-all-${productId}-${name}`}
                                style={{ margin: '0 8px' }}
                                checked={Boolean(productDefault?.isPublished)}
                                data-testid={name}
                                onChange={(e) =>
                                  onChange(
                                    variants.map((item) => ({
                                      ...item,
                                      isPublished: e.target.checked
                                    }))
                                  )
                                }
                              />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <div className='product-arrangement-wrapper'>
                {!isEmpty(variants) ? (
                  <>
                    <div>
                      {variants
                        .filter((item) => item.id != productDefault?.id)
                        .map((item) => {
                          return (
                            <Row
                              justify='space-between'
                              style={{
                                marginBottom: 4,
                                backgroundColor: '#f3f2f4',
                                padding: '8px 16px'
                              }}
                            >
                              <Col span={23}>
                                <label htmlFor={`${productId}-${item.id}`}>
                                  {item.label}
                                </label>
                              </Col>
                              <Col span={1}>
                                <Checkbox
                                  id={`${productId}-${item.id}`}
                                  checked={item.isPublished}
                                  data-testid={item.label}
                                  onChange={(e) =>
                                    onChange([
                                      { ...item, isPublished: e.target.checked }
                                    ])
                                  }
                                />
                              </Col>
                            </Row>
                          )
                        })}
                    </div>
                  </>
                ) : (
                  <div>
                    Variant List is empty. Please try reloading the page.
                  </div>
                )}
              </div>
            </Collapse.Panel>
          </Collapse>
        </Card>
      )
    }
  )

  const SortableProductsList = SortableContainer(
    ({ groupId, products }: { groupId: string; products: SortedProduct[] }) => (
      <div
        data-testid={`products-container-${groupId}`}
        style={{ overflowX: 'auto' }}
      >
        {products.map((product: SortedProduct, index: number) => (
          <SortableProduct key={product.id} product={product} index={index} />
        ))}
      </div>
    )
  )

  const SortableGroup = SortableElement(
    ({ group }: { group: InventoryGroup }) => {
      return (
        <Card
          data-testid={`draggable-category-item-${group.name}`}
          className='width-100 sortable-group'
          bodyStyle={{
            cursor: 'grab'
          }}
        >
          <Collapse
            defaultActiveKey={selectedGroupId}
            accordion
            expandIconPosition='right'
            expandIcon={() => {
              if (selectedGroupId === group.id) return <DownArrowIcon />
              else return <RightArrowIcon />
            }}
            ghost
            onChange={(key) => setSelectedGroupId(key as string)}
          >
            <Collapse.Panel
              key={group.id}
              header={
                <Row align='middle' className='-category-list'>
                  {selectedGroupId !== group.id && (
                    <Col span={1}>{<DraggableIcon />}</Col>
                  )}
                  <Col span={selectedGroupId !== group.id ? 22 : 23}>
                    <Row
                      align='middle'
                      className={`-category-list ${
                        selectedGroupId === group.id && '_ml-8'
                      }`}
                    >
                      <Col span={16}>{group.name}</Col>
                      <Col span={8}>
                        <Row
                          gutter={[8, 8]}
                          align='middle'
                          justify='end'
                          className='-category-list'
                        >
                          <Col>{group.products.length} Products</Col>
                          <Col span={4}>
                            <span onClick={(e) => e.stopPropagation()}>
                              <Checkbox
                                id={`select-all-${group.id}-${group.name}`}
                                style={{ marginLeft: 8 }}
                                checked={_.every(
                                  group.items,
                                  (item) => item.isPublished
                                )}
                                data-testid={group.name}
                                onChange={(e) =>
                                  onChange(
                                    group.items.map((item) => ({
                                      ...item,
                                      isPublished: e.target.checked
                                    }))
                                  )
                                }
                              />
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            >
              <div className='product-arrangement-wrapper'>
                {!isEmpty(group.items) ? (
                  <>
                    <SortableProductsList
                      axis='y'
                      lockAxis='y'
                      distance={1}
                      groupId={group.id}
                      products={group.products}
                      onSortEnd={({ oldIndex, newIndex }) => {
                        handleItemsSortEnd({ oldIndex, newIndex }, group)
                      }}
                      helperClass='sortable-item'
                    />
                  </>
                ) : (
                  <div>
                    Product List is empty. Please try reloading the page.
                  </div>
                )}
              </div>
            </Collapse.Panel>
          </Collapse>
        </Card>
      )
    }
  )

  const SortableGroupsList = SortableContainer(
    ({ groups }: { groups: SortedCategory[] }) => {
      return (
        <div data-testid='category-row-container'>
          {groups?.map((group: SortedCategory, index: number) => {
            return (
              <SortableGroup
                key={group.id}
                group={group}
                index={index}
                ref={(el) => {
                  if (selectedGroupId !== group.id) return

                  containerRef?.current?.scrollTo(0, el?.node?.offsetTop - 96)
                }}
              />
            )
          })}
        </div>
      )
    }
  )

  return (
    <div
      className='sortable-container'
      style={sortableContainerStyle}
      ref={containerRef}
    >
      <SortableGroupsList
        axis='y'
        lockAxis='y'
        distance={1}
        groups={sortedGroups}
        onSortEnd={handleGroupsSortEnd}
        helperClass='sortable-category-item'
      />
    </div>
  )
}

export default InventoryCollapsePanels
