import React, { useState } from 'react'
import { Button, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { HiddenInputs } from './styledComponents'

interface SubformProps {
  value: string
  label: string
  children: React.ReactNode
  onSave: (setShowInputs: React.Dispatch<React.SetStateAction<boolean>>) => void
  onCancel: () => void
}

const Subform = ({
  onSave,
  onCancel,
  value,
  label,
  children
}: SubformProps) => {
  const [showInputs, setShowInputs] = useState(false)

  const handleSave = () => {
    onSave(setShowInputs)
  }

  const handleCancel = () => {
    setShowInputs(false)
    onCancel()
  }

  return (
    <Form.Item label={label} className='rebook-subform'>
      {!showInputs && (
        <div className='courier-form-header'>
          <div>
            <span>{value}</span>
          </div>
          <Button
            onClick={(e) => setShowInputs(true)}
            icon={<EditOutlined />}
          />
        </div>
      )}

      <HiddenInputs hidden={!showInputs}>{children}</HiddenInputs>

      {showInputs && (
        <div className='buttons'>
          <Button type='ghost' className='-secondary' onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </div>
      )}
    </Form.Item>
  )
}

export default Subform
