import React, { useState } from 'react'
import {
  Button,
  message,
  Table,
  Popconfirm,
  Tag,
  Tooltip,
  Select,
  Modal
} from 'antd'
import snakeCase from 'lodash/snakeCase'
import { useMutation, useApolloClient } from '@apollo/client'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ProductVariant } from './types'
import {
  ARCHIVE_VARIANT_OPTION,
  UPDATE_VARIANT_ALLERGENS
} from './VariantQueries'
import VariantNameAndAvatar from 'components/Widgets/VariantNameAndAvatar'
import { GroupVariantsByOptionName } from './utils'
import { ALLERGENS as ALLERGENS_TYPE } from '../types'
import { ALLERGENS } from '../Form'

interface VariantGroupTableProps {
  productId: string
  baseName: string
  variants: ProductVariant[]
  variantOptions: string[]
  variantGroupName: string
  optionAllergens: Record<string, Array<ALLERGENS_TYPE>>
}

interface VariantTableRow {
  name: string
  option: string
  allergens: Array<ALLERGENS_TYPE>
}

const ALLERGEN_VALUES = Object.entries(ALLERGENS)

const VariantGroupTable = ({
  productId,
  baseName,
  variants,
  variantOptions,
  variantGroupName,
  optionAllergens
}: VariantGroupTableProps) => {
  const client = useApolloClient()
  const [archiveVariantOption, { loading: archivingVariantOption }] =
    useMutation(ARCHIVE_VARIANT_OPTION)

  const [updateVariantAllergens, { loading: loadingUpdateVariantAllergens }] =
    useMutation(UPDATE_VARIANT_ALLERGENS, {
      refetchQueries: ['getMerchantProductVariants', 'getProductDetails']
    })

  const [currentProductOption, setCurrentProductOption] =
    useState<VariantTableRow>({
      name: '',
      option: '',
      allergens: []
    })
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const variantsByOptionName = GroupVariantsByOptionName(
    variantOptions,
    variantGroupName,
    variants
  )

  const variantDatasource = variantOptions
    .filter(
      (option: string) => !!variantsByOptionName[snakeCase(option)].length
    )
    .map((option: string) => ({
      name: baseName,
      option: option,
      allergens: (Boolean(Object.keys(optionAllergens ?? {}).length)
        ? optionAllergens[option.toLowerCase()] ?? []
        : []
      ).map((allergen) => allergen)
    }))

  const archiveVariantsByOptionName = (optionName: string) => {
    message.destroy()
    message.loading('Archiving product variant group options...', 3)

    archiveVariantOption({
      variables: {
        optionName: variantGroupName,
        optionValue: optionName,
        productId
      }
    })
      .then((result) => {
        message.destroy()
        message.success('Product variant group option acrhived.', 3)
        client.resetStore()
      })
      .catch((error) => {
        message.destroy()
        message.error(
          `Unable to arhive product variants due to ${error.message}`,
          3
        )
      })
  }

  const columns = [
    {
      title: 'Variants',
      key: 'key',
      render: ({ option }: VariantTableRow) => {
        return (
          <>
            <VariantNameAndAvatar
              name={baseName}
              testId='variant-group-row-avatar'
              options={[option]}
            />
          </>
        )
      },
      width: '15%'
    },
    {
      title: 'Allergens',
      key: 'allergens',
      render: (productOption: VariantTableRow) => {
        const { allergens, name } = productOption

        const hasManyAllergens = allergens.length > 4
        const displayedAllergens = hasManyAllergens
          ? allergens.slice(0, 4)
          : allergens
        const remainingAllergens = hasManyAllergens
          ? allergens.slice(4, allergens.length)
          : []

        return (
          <div>
            {Boolean(allergens.length) &&
              (displayedAllergens ?? []).map(
                (allergen: ALLERGENS_TYPE, index: number) => {
                  if (index < 4) {
                    return (
                      <Tooltip
                        title={ALLERGENS[allergen]}
                        key={`${name}-${allergen}-${index}`}
                      >
                        <Tag className='variant-allergen'>
                          <span>{ALLERGENS[allergen]}</span>
                        </Tag>
                      </Tooltip>
                    )
                  }
                }
              )}
            {hasManyAllergens ? (
              <Tooltip
                title={remainingAllergens.map((allergen: ALLERGENS_TYPE) => (
                  <div>{ALLERGENS[allergen]}</div>
                ))}
              >
                <Tag className='variant-allergen'>
                  <span>+{remainingAllergens.length} more</span>
                </Tag>
              </Tooltip>
            ) : (
              <></>
            )}

            <Tag
              className='variant-allergen add-allergen'
              onClick={() => {
                setIsVisible(true)
                setCurrentProductOption(productOption)
              }}
            >
              <span>{allergens.length > 0 ? 'Edit' : '+ Add'} Allergens</span>
            </Tag>
          </div>
        )
      },
      width: '72.5%'
    },
    {
      title: 'Archive',
      colSpan: 12,
      key: 'archive',
      align: 'right',
      render: ({ option }: VariantTableRow) => {
        return (
          <Popconfirm
            title='Are you sure you want to archive this variant group option?'
            okText='Yes'
            cancelButtonProps={{ type: 'ghost' }}
            cancelText='No'
            onConfirm={() => archiveVariantsByOptionName(option)}
          >
            <Button
              data-testid='variant-group-option-row-archive-btn'
              className='-gray-bordered'
              loading={archivingVariantOption}
              disabled={archivingVariantOption}
              ghost
              size='small'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )
      },
      width: '12.5%'
    }
  ]

  return (
    <div data-test='variant-group-options-table'>
      <Table
        columns={columns}
        dataSource={variantDatasource}
        rowKey='id'
        pagination={false}
        className='variants-table'
      />
      <Modal
        maskClosable={false}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        okButtonProps={{
          type: 'default',
          loading: loadingUpdateVariantAllergens
        }}
        cancelButtonProps={{
          type: 'default',
          ghost: true,
          loading: loadingUpdateVariantAllergens
        }}
        title={
          <>
            Edit Allergens
            <Tooltip
              title={
                <span>
                  Customers see all allergens for products they're choosing,
                  including <strong>additional</strong> ones for specific
                  variants.
                </span>
              }
            >
              <QuestionCircleOutlined className='_ml-8' />
            </Tooltip>
          </>
        }
        className='allergens-modal'
        okText='update'
        onOk={() => {
          const optionName = currentProductOption.option

          updateVariantAllergens({
            variables: {
              productId,
              optionName: variantGroupName,
              optionValue: optionName,
              optionAllergens: currentProductOption.allergens
            }
          })
            .then((result) => {
              setIsVisible(false)
              message.success(
                `Successfully updated allergens of ${variantGroupName} ${optionName}!`,
                2
              )
            })
            .catch((error) =>
              message.error(
                `Unable to update allergens of ${variantGroupName} ${optionName} due to ${error}`,
                2
              )
            )
        }}
        centered
      >
        <Select
          allowClear
          mode='multiple'
          className='_w-100 allergen-select'
          maxTagCount={3}
          maxTagTextLength={8}
          placeholder='Select allergens'
          maxTagPlaceholder={(values) => values.length}
          value={currentProductOption.allergens ?? []}
          onChange={(value: Array<ALLERGENS_TYPE>, option) => {
            setCurrentProductOption((prev) => {
              return {
                ...prev,
                allergens: value
              }
            })
          }}
        >
          {ALLERGEN_VALUES.map(([key, allergen]) => (
            <Select.Option value={key} key={key}>
              {allergen}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  )
}

export default VariantGroupTable
