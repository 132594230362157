import React, { useState } from 'react'
import { Button, Tooltip } from 'antd'
import { CloseOutlined, PauseOutlined } from '@ant-design/icons'
import { getRemainingTime, isItemSnoozed } from './utils'

interface SnoozeButtonProps {
  itemId: string
  unsnoozeAt: string | null
  onClickHandler: (itemId: string, snooze: boolean) => void
  loading: boolean
  disabled: boolean
  dataTestId: string
}

const SnoozeButton = ({
  itemId,
  unsnoozeAt,
  onClickHandler,
  loading,
  disabled,
  dataTestId
}: SnoozeButtonProps) => {
  const [isSnoozed, setIsSnoozed] = useState<boolean>(isItemSnoozed(unsnoozeAt))
  const [buttonText, setButtonText] = useState<string>(
    isSnoozed ? getRemainingTime(unsnoozeAt) : 'Snooze'
  )

  if (isSnoozed) {
    setInterval(() => {
      if (!isItemSnoozed(unsnoozeAt)) {
        setButtonText('Snooze')
        setIsSnoozed(false)
      } else {
        setButtonText(getRemainingTime(unsnoozeAt))
      }
    }, 1000)
  }

  return (
    <Tooltip title={buttonText}>
      <Button
        block
        type='primary'
        loading={loading}
        disabled={disabled}
        icon={isSnoozed ? [] : <PauseOutlined />}
        data-testid={dataTestId}
        className={isSnoozed ? '_max-w-xs' : '-default-secondary _max-w-xs'}
        onClick={() => onClickHandler(itemId, isSnoozed)}
      >
        {isSnoozed && (
          <CloseOutlined
            style={{
              transform: 'translateX(-2.5px)'
            }}
          />
        )}
      </Button>
    </Tooltip>
  )
}

export default SnoozeButton
