import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { UPSERT_DELIVERY_PRICING_BANDS } from './queries'
import { UseUpsertDeliveryPricingBandsProps } from './types'

const useUpsertDeliveryPricingBands = () => {
  const [responseData, setResponseData] = useState({
    data: [],
    loading: false,
    error: null
  })

  const [upsert] = useMutation(UPSERT_DELIVERY_PRICING_BANDS)

  const upserDeliveryPricingBands = ({
    storeId,
    orderType,
    deliveryPricingBands
  }: UseUpsertDeliveryPricingBandsProps) => {
    setResponseData({
      data: [],
      loading: true,
      error: null
    })

    upsert({
      variables: {
        storeId,
        deliveryPricingBands,
        orderType
      }
    })
      .then((response) => {
        const data = response?.data?.setDeliveryPricingBands || []
        setResponseData({
          data: data,
          loading: false,
          error: null
        })
      })
      .catch((error: Error) => {
        setResponseData({
          data: [],
          loading: false,
          error: error.message
        })
      })
  }

  return [upserDeliveryPricingBands, responseData]
}

export default useUpsertDeliveryPricingBands
