import React from 'react'
import styled from '@emotion/styled'

import { GlobalStyle } from '../components/GlobalStyle'
import { ConfigProvider } from '../contexts/Config'
import { ShopProvider } from '../contexts/Shop'
import { ApolloProvider } from '../contexts/Apollo'
import { ThemeProvider } from '../contexts/Theme'
import { AppProvider } from '../contexts/App'
import { ScopeProvider } from '../components/FrameProvider'
import { IframeProvider } from '../contexts/Iframe/Iframe'
import { PagePills } from './PagePills'
import RadioGroup from '../components/RadioGroup/RadioGroup'
import Main from './Main'
import Divider from 'antd/lib/divider'

type PropsTodo = any

export const Root: React.FC<PropsTodo> = () => {
  return (
    <>
      <ConfigProvider>
        <ApolloProvider>
          <IframeProvider>
            <ShopProvider>
              <ScopeProvider scope={'#root'}>
                <ThemeProvider>
                  <div className='app'>
                    <AppProvider>
                      <LayoutContainer>
                        <Header>
                          <RadioGroup />
                          <PagePills />
                        </Header>
                        <Divider className='_mb-0 _mt-0' />

                        <Main />
                      </LayoutContainer>
                    </AppProvider>
                    <GlobalStyle />
                  </div>
                </ThemeProvider>
              </ScopeProvider>
            </ShopProvider>
          </IframeProvider>
        </ApolloProvider>
      </ConfigProvider>
    </>
  )
}

const LayoutContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 280px);
`

const Header = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 16px 24px;
  justify-content: space-between;
  background-color: ${({ theme }: any) => theme.colors.background10};
`
