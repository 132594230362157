import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useShop } from '../../contexts/Shop'
import { Tooltip, Row, Col } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  FormGroup,
  FormGroupTitle,
  FormGroupTitleMenu,
  FormGroupContent,
  FieldGroup,
  FormGroupSeparator
} from '../../components/FormGroup'
import { SwitchToggle } from '../../components/SwitchToggle'
import { TextInput } from '../../components/TextInput'
import { Textarea } from '../../components/Textarea'
import { Uploader } from '../../components/Uploader'
import { VerticalRadio } from '../../components/VerticalRadio'
import { VerticalCheckbox } from '../../components/VerticalCheckbox'
import { AppliedForValueSanitizer } from './Utils'
import { FaMoon } from '../../components/Icon'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

const ShopSidebarControl: React.FC = () => {
  const {
    merchantGet,
    merchantImageGet,
    merchantImageOnChange,
    merchantImagesLoadMap,
    merchantOnChangeWithEvent,
    merchantOnChange,
    merchantOnChangeWithSanitizer
  } = useShop()

  const [giftingValue, setGiftingValue] = useState(
    merchantGet('gift_wrap_price')
  )

  useEffect(() => {
    if (!isEmpty(giftingValue)) return
    isEmpty(giftingValue) && setGiftingValue(0)
  }, [giftingValue])

  return (
    <>
      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle tooltipHeading='What will the header logo be used for?'>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Upload Header Logo</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is the header logo?</strong>
                      <br />
                      <br />
                      <div>
                        The header logo will be used in the header for
                        consistent branding throughout your Slerp customer
                        experience. Learn how to format your images{' '}
                        <a
                          className='_link-white _underline'
                          href='https://support.slerp.com/knowledge/uploading-images'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          here
                        </a>
                        .
                      </div>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>
        <FormGroupContent>
          <FieldGroup>
            <Uploader
              file={merchantImageGet('alternate_avatar')}
              onFileSelect={merchantImageOnChange('alternate_avatar')}
              isUploading={merchantImagesLoadMap.alternate_avatar}
              icon={<FaMoon />}
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Store banner</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>Where is the store banner?</strong>
                      <br />
                      <br />
                      <div>
                        The store banner is displayed across the top of the
                        product page. Learn how to format your images{' '}
                        <a
                          className='_link-white _underline'
                          href='https://support.slerp.com/knowledge/uploading-images'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          here
                        </a>
                        .
                      </div>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Uploader
              file={merchantImageGet('cover_image')}
              onFileSelect={merchantImageOnChange('cover_image')}
              isUploading={merchantImagesLoadMap.cover_image}
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Promo banner</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is a promo banner?</strong>
                      <br />
                      <br />
                      The promo banner is a space for you to add text to share
                      with your customers, such as a discount code. It is
                      displayed above your list of products.
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Textarea
              value={merchantGet('promotional_banner')}
              onChange={merchantOnChangeWithEvent('promotional_banner')}
              placeholder='Use promo 10% for 10% off deliver'
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Order note placeholder</Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Textarea
              value={merchantGet('order_note_placeholder')}
              onChange={merchantOnChangeWithEvent('order_note_placeholder')}
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroupSeparator />

      <FormGroup>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Delivery note placeholder</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>What is a delivery note placeholder?</strong>
                      <br />
                      <br />A delivery note placeholder is used to guide your
                      customers on what details to add in the delivery note text
                      field.
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>
        </FormGroupTitleMenu>

        <FormGroupContent>
          <FieldGroup>
            <Textarea
              value={merchantGet('delivery_note_placeholder')}
              onChange={merchantOnChangeWithEvent('delivery_note_placeholder')}
              placeholder='Enter a message here ...'
            />
          </FieldGroup>
        </FormGroupContent>
      </FormGroup>

      <FormGroupSeparator />

      <FormGroup isCollapsible={false}>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Gifting</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <span>
                      Gifting is key part of the eCommerce customer experience.
                      <br />
                      <br /> Gifted orders on average generate a greater spend.
                      It's a great way to increase the sales of your current
                      packages or promotional items offering an aditional angle
                      to your seasonal marketing.
                    </span>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>

          <SwitchToggle
            checked={merchantGet('gift_wrap_enabled')}
            onChange={merchantOnChange('gift_wrap_enabled')}
          />
        </FormGroupTitleMenu>
      </FormGroup>

      {merchantGet('gift_wrap_enabled') && (
        <FormGroup isCollapsible={false}>
          <FormGroupTitleMenu>
            <FormGroupTitle>
              <Row align='middle' className='_mb-0' gutter={8}>
                <Col>Gifting price</Col>
                <Col>
                  <Tooltip
                    placement='right'
                    title={
                      <span>
                        Don't forget to include gifting wrapping associated
                        costs in your overall product costings.
                      </span>
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            </FormGroupTitle>
          </FormGroupTitleMenu>

          <FormGroupContent>
            <FieldGroup>
              <TextInput
                value={giftingValue}
                onInput={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault()
                    setGiftingValue(e.target.value)
                  }
                }}
                onFocus={(e) => {
                  e.currentTarget.select()
                  e.target.addEventListener(
                    'wheel',
                    (e: Event) => e.preventDefault(),
                    {
                      passive: false
                    }
                  )
                }}
                onChange={merchantOnChangeWithEvent('gift_wrap_price')}
                placeholder='Enter a price here ...'
                type='number'
                min={0}
                defaultValue={0}
                onBlur={onBlurScrollNumber}
              />
            </FieldGroup>
          </FormGroupContent>
        </FormGroup>
      )}

      {merchantGet('gift_wrap_enabled') && (
        <FormGroup isCollapsible={false}>
          <FormGroupTitleMenu>
            <FormGroupTitle>
              <Row align='middle' className='_mb-0' gutter={8}>
                <Col>Gifting description</Col>
                <Col>
                  <Tooltip
                    placement='right'
                    title={
                      <>
                        <strong>What is a gifting description?</strong>
                        <br />
                        <br />
                        Add a description of your gifting for your customers,
                        the price will be displayed next to it.
                      </>
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            </FormGroupTitle>
          </FormGroupTitleMenu>

          <FormGroupContent>
            <FieldGroup>
              <Textarea
                value={merchantGet('gift_wrap_text')}
                onChange={merchantOnChangeWithEvent('gift_wrap_text')}
                placeholder='Enter a message here ...'
              />
            </FieldGroup>
          </FormGroupContent>
        </FormGroup>
      )}

      {merchantGet('gift_wrap_enabled') && (
        <FormGroup isCollapsible={false}>
          <FormGroupTitleMenu>
            <FormGroupTitle>
              <Row align='middle' className='_mb-0' gutter={8}>
                <Col>Gifting note placeholder</Col>
                <Col>
                  <Tooltip
                    placement='right'
                    title={
                      <>
                        <strong>What is a gifting note placeholder?</strong>
                        <br />
                        <br />A gifting note placeholder is used to guide your
                        customers on what details to add in the gifting note
                        text field.
                      </>
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Col>
              </Row>
            </FormGroupTitle>
          </FormGroupTitleMenu>

          <FormGroupContent>
            <FieldGroup>
              <Textarea
                value={merchantGet('gift_wrap_placeholder')}
                onChange={merchantOnChangeWithEvent('gift_wrap_placeholder')}
                placeholder='Enter a message here ...'
              />
            </FieldGroup>
          </FormGroupContent>
        </FormGroup>
      )}

      <FormGroupSeparator />

      <FormGroup isCollapsible={false}>
        <FormGroupTitleMenu>
          <FormGroupTitle>
            <Row align='middle' className='_mb-0' gutter={8}>
              <Col>Custom Field</Col>
              <Col>
                <Tooltip
                  placement='right'
                  title={
                    <>
                      <strong>
                        Need some extra information from your customers when
                        they order (i.e. seat number or vehicle reg)?
                      </strong>
                      <br />
                      <br />
                      You can customise this additional field and make it
                      mandatory or optional at checkout for every order.
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          </FormGroupTitle>

          <SwitchToggle
            checked={merchantGet('order_custom_field')}
            onChange={merchantOnChange('order_custom_field')}
          />
        </FormGroupTitleMenu>
      </FormGroup>

      {merchantGet('order_custom_field') && (
        <>
          <FormGroup isCollapsible={false}>
            <FormGroupTitleMenu>
              <FormGroupTitle>
                <Row align='middle' className='_mb-0' gutter={8}>
                  <Col>Field name</Col>
                </Row>
              </FormGroupTitle>
            </FormGroupTitleMenu>

            <FormGroupContent>
              <FieldGroup>
                <Textarea
                  value={merchantGet('order_custom_field_name')}
                  onChange={merchantOnChangeWithEvent(
                    'order_custom_field_name'
                  )}
                  placeholder='Eg. booking reference'
                />
              </FieldGroup>
            </FormGroupContent>
          </FormGroup>

          <FormGroup isCollapsible={false}>
            <FormGroupTitleMenu>
              <FormGroupTitle>
                <Row align='middle' className='_mb-0' gutter={8}>
                  <Col>Field placeholder</Col>
                </Row>
              </FormGroupTitle>
            </FormGroupTitleMenu>

            <FormGroupContent>
              <FieldGroup>
                <Textarea
                  value={merchantGet('order_custom_field_placeholder')}
                  onChange={merchantOnChangeWithEvent(
                    'order_custom_field_placeholder'
                  )}
                  placeholder='Eg. booking reference'
                />
              </FieldGroup>
            </FormGroupContent>
          </FormGroup>

          <FormGroup isCollapsible={false}>
            <FormGroupTitleMenu>
              <FormGroupTitle>
                <Row align='middle' className='_mb-0' gutter={8}>
                  <Col>Field type</Col>
                </Row>
              </FormGroupTitle>
            </FormGroupTitleMenu>

            <FormGroupContent>
              <FieldGroup>
                <VerticalRadio
                  options={[
                    { key: 'mandatory', value: true },
                    { key: 'optional', value: false }
                  ]}
                  value={merchantGet('order_custom_field_mandatory')}
                  onChange={merchantOnChangeWithEvent(
                    'order_custom_field_mandatory'
                  )}
                />
              </FieldGroup>
            </FormGroupContent>
          </FormGroup>

          <FormGroup isCollapsible={false}>
            <FormGroupTitleMenu>
              <FormGroupTitle>
                <Row align='middle' className='_mb-0' gutter={8}>
                  <Col>Apply for</Col>
                </Row>
              </FormGroupTitle>
            </FormGroupTitleMenu>

            <FormGroupContent>
              <FieldGroup>
                <VerticalCheckbox
                  onChange={merchantOnChangeWithSanitizer(
                    'order_custom_field_apply_for',
                    AppliedForValueSanitizer
                  )}
                  values={AppliedForValueSanitizer(
                    merchantGet('order_custom_field_apply_for')
                  )}
                  options={[
                    { label: 'Delivery', value: 'delivery' },
                    { label: 'Pick up', value: 'pickup' },
                    { label: 'Table ordering', value: 'order_at_table' }
                  ]}
                />
              </FieldGroup>
            </FormGroupContent>
          </FormGroup>
        </>
      )}
    </>
  )
}

export { ShopSidebarControl }
