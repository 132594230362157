import { createClient } from '@slerp/client'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'

type CustomRuleType = { getFieldValue: (arg: string) => string }

const CHECK_USER_EMAIL_VALIDITY = gql`
  query getUserByEmail($email: String!, $merchantId: ID!) {
    getUserByEmail(email: $email, merchantId: $merchantId) {
      id
    }
  }
`

const checkUserEmailValidity = async (email: string) => {
  const apiKey = localStorage.getItem('token') || ''
  const merchant = JSON.parse(localStorage.getItem('merchant'))
  const client = createClient(apiKey)

  return client
    .query({
      query: CHECK_USER_EMAIL_VALIDITY,
      variables: { email, merchantId: merchant.id }
    })
    .then((response) => {
      return !isEmpty(response?.data?.getUserByEmail)
    })
    .catch((error: Error) => {
      return false
    })
}

const required = { required: true, message: 'This is a required field.' }
const emailFormatRule = {
  type: 'email',
  message: 'Please input a valid email address'
}
const requiredRule = [required]

const userEmailRule = [
  emailFormatRule,
  required,
  () => ({
    async validator(_: any, value: string) {
      if (!value) {
        return
      }

      await checkUserEmailValidity(value).then((result) => {
        if (result) {
          return Promise.reject(
            'This email address is already in use, please use another'
          )
        }

        return Promise.resolve()
      })
    }
  })
]

const editUserEmailRule = [
  emailFormatRule,
  required,
  ({ getFieldValue }: CustomRuleType) => ({
    async validator(_: any, value: string) {
      const current_name = getFieldValue('current_email') || ''
      if (current_name === value) {
        return Promise.resolve()
      }
      const emailAlreadyExists = await checkUserEmailValidity(value)

      return emailAlreadyExists
        ? Promise.reject(
            'This email address is already in use, please use another'
          )
        : Promise.resolve()
    }
  })
]

export { requiredRule, userEmailRule, editUserEmailRule }
