import { useEffect } from 'react'
import { PixelPageViewTracking } from './PixelTools'
import { GA4PageViewTracking } from './GAtools'
import { HubspotPageViewTracking } from './HubspotTools'

const PageViewTracking = () => {
  const pathname = window.location.pathname

  // Slightly delay for analytics to load; this is not a foolproof
  // solution though because some pages might take a while to load.
  setTimeout(() => {
    // Meta Pixel
    PixelPageViewTracking()

    // SLERP GA4
    GA4PageViewTracking(pathname)

    // Hubspot
    HubspotPageViewTracking(pathname)
  }, 2000)
}

export const usePageViewTracker = () => {
  // PageView Tracking on load
  const pathname = window.location.pathname
  useEffect(() => {
    PageViewTracking()
  }, [pathname])
}
