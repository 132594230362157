import React, { useEffect, useState, useMemo } from 'react'
import {
  Form,
  Switch,
  message,
  Button,
  PageHeader,
  Tooltip,
  Row,
  Col,
  InputNumber,
  Typography
} from 'antd'
import { Store as FormStore } from 'rc-field-form/lib/interface'
import { useMutation } from '@apollo/client'
import { UPDATE_SETTINGS_AND_MINIMUM_ORDER_VALUE } from '../actions'
import UpdateNotice from '../../Utils/UpdateNotice'
import { LocationSettingProps } from '../Settings'
import DeliveryZone from '../PreOrderSettings/Delivery/DeliveryZone'
import TimeSlots from '../PreOrderSettings/General/TimeSlots'
import OrderLimit from '../PreOrderSettings/General/OrderLimit'
import CourierType from '../PreOrderSettings/Delivery/CourierType'
import DeliveryFee from '../PreOrderSettings/Delivery/DeliveryFee'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { warningModal, FeeTypes } from './../utils'
import isEmpty from 'lodash/isEmpty'
import MinimumOrderValueInput from './MinimumOrderValueInput'
import { vatPercentageFieldRule } from '../rules'
import DeliveryPricingBands from '../DeliveryPricingBands'
import { DeliveryPricingBand } from '../DeliveryPricingBands/types'
import { Discount } from 'components/CustomHooks/Discounts/types'
import {
  isActive as isDiscountActive,
  isScheduled as isDiscountScheduled,
  descriptionText as discountDescriptionText
} from 'components/Discounts/Helpers'
import useGetDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useGetDeliveryPricingBands'
import useUpsertDeliveryPricingBands from 'components/CustomHooks/DeliveryPricingbands/useUpsertDeliveryPricingBands'
import useGetDiscounts from 'components/CustomHooks/Discounts/useGetDiscounts'
import useUnpublishDiscounts from 'components/CustomHooks/Discounts/useUnpublishDiscounts'
import {
  FormatPricingBandsForPersistence,
  ValidateDeliveryPricingBands
} from '../DeliveryPricingBands/utils'
import { Spinner, CustomModal } from 'components/Widgets'
import { Store } from '@slerp/controls'
import cloneDeep from 'lodash/cloneDeep'
import { DEFAULT_DELIVERY_RADIUS, DEFAULT_DELIVERY_TIME } from '../utils'
import { useUpdateBusyArea, useUpdateStoreSettings } from './utils'
import { onBlurScrollNumber, onFocusScrollNumber } from 'components/Utils/price'

const { useForm } = Form
const { Text } = Typography
const OWN_DRIVERS_DELIVERY_TIMESLOTS = [
  { label: '5 minute', value: 5 },
  { label: '15 minute', value: 15 },
  { label: '30 minute', value: 30 },
  { label: '1 hour', value: 60 }
]
const SCP_DELIVERY_TIMESLOTS = [
  { label: '30 minute', value: 30 },
  { label: '1 hour', value: 60 }
]

const DeliverySettings = (props: LocationSettingProps) => {
  const { merchant, store: originalStore, disabled = false, refetch } = props
  const [form] = useForm()
  const [store, setStore] = useState<Store>(cloneDeep(originalStore))
  const [enabled, setEnabled] = useState(
    store.settings.same_day_delivery || store.settings.scheduled_delivery
  )

  const [updateSetting, { loading: loadingSettings, error: errorSettings }] =
    useUpdateStoreSettings({ store })
  const [updateBusyArea, { loading: loadingBusyArea, error: errorBusyArea }] =
    useUpdateBusyArea({ store })

  const loading = loadingSettings || loadingBusyArea
  const error = errorSettings || errorBusyArea

  const [updateMinimumOrderValue] = useMutation(
    UPDATE_SETTINGS_AND_MINIMUM_ORDER_VALUE,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const [
    sourcePricingBands,
    { loading: fetchingPricingBands, refetch: refetchPricingBands }
  ] = useGetDeliveryPricingBands({
    storeId: store.id,
    orderType: 'SAMEDAY'
  })
  const [discounts, { refetch: refetchDiscounts }] = useGetDiscounts({
    merchantId: merchant?.id
  })

  const [unpublishDiscounts] = useUnpublishDiscounts()
  const [upsertDeliveryPricingBands] = useUpsertDeliveryPricingBands()

  const activeAutomaticDiscounts = useMemo(
    () =>
      discounts.filter((discount: Discount) => {
        const { store_ids, target, trigger } = discount
        const isAllStores = (store_ids || []).length === 0
        const storeCondition =
          (store_ids && (store_ids || []).includes(store.id)) || isAllStores
        return (
          storeCondition &&
          (isDiscountActive(discount) || isDiscountScheduled(discount)) &&
          target === 'delivery_fee' &&
          trigger === 'automatic'
        )
      }),
    [discounts, store.id]
  )

  const [pricingBands, setPricingBands] = useState<DeliveryPricingBand[]>([])

  const [courier, setCourier] = useState<'partner' | 'other'>(
    store.settings
      ? store.settings.same_day_delivery_courier_partner !== false
        ? 'partner'
        : 'other'
      : 'partner'
  )
  const [feeType, setFeeType] = useState<FeeTypes>('')

  const merchantPriceMatrix = merchant.price_matrices.find((pm) => pm.global)
  const storePriceMatrix = merchant.price_matrices.find((pm) => {
    const storeSlugs = pm?.stores?.map((store) => store.slug)

    if (pm.global === false && storeSlugs?.includes(store.slug)) {
      return pm
    }
  })

  const ModalContents = () => {
    return (
      <>
        <Row className='_mb-6'>
          <Col>
            The following delivery related discount will now be disabled, as you
            have enabled Delivery pricing by basket value for this location.
          </Col>
        </Row>
        {activeAutomaticDiscounts.map((discount: Discount) => (
          <Row className='_mb-0'>
            <Col>
              <Text strong>{`(${discount.code}) ${discountDescriptionText(
                discount
              )}`}</Text>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  const submit = () => {
    if (!!activeAutomaticDiscounts.length && !!pricingBands.length) {
      CustomModal({
        type: 'confirm',
        content: <ModalContents />,
        okCancel: true,
        okText: 'Proceed',
        onOk: () => form.submit()
      })
    } else {
      form.submit()
    }
  }

  const resetForm = () => {
    setStore(cloneDeep(originalStore))
    form.resetFields()
    setPricingBands(sourcePricingBands)
  }

  const onFinish = (values: FormStore) => {
    const {
      asap_only,
      scheduled_pickup,
      advanced_order_interval,
      sameday_delivery_minimum_order_value,
      delivery_area_enabled,
      delivery_timeslot_order_limit,
      courier_fee_vat_percentage
    } = values

    form
      .validateFields()
      .then(async () => {
        message.loading('Updating... Please wait.')

        await updateSetting(values)
        await updateBusyArea(values)

        return updateMinimumOrderValue({
          variables: {
            merchant_id: merchant.id,
            id: store.id,
            sameday_delivery_minimum_order_value:
              sameday_delivery_minimum_order_value === undefined
                ? store.sameday_delivery_minimum_order_value
                : sameday_delivery_minimum_order_value,
            asap_only,
            settings: enabled
              ? {
                  scheduled_delivery: enabled,
                  same_day_delivery: enabled,
                  scheduled_pickup,
                  advanced_order_interval,
                  delivery_area_enabled: !!delivery_area_enabled,
                  delivery_timeslot_order_limit:
                    delivery_timeslot_order_limit || 0,
                  same_day_delivery_courier_partner: courier === 'partner',
                  same_day_delivery_fixed_courier_fee_enabled:
                    feeType === 'custom',
                  same_day_rate_cards_enabled: feeType === 'rate_card',
                  same_day_delivery_courier_custom_fixed_fee:
                    values.custom_fixed_fee ||
                    (store.settings
                      ? store.settings
                          .same_day_delivery_courier_custom_fixed_fee || 0
                      : 0),
                  courier_fee_vat_percentage: !!courier_fee_vat_percentage
                    ? courier_fee_vat_percentage
                    : 0
                }
              : {
                  scheduled_delivery: enabled,
                  same_day_delivery: enabled
                }
          }
        })
      })
      .then(() => {
        const tasks = []
        if (!!activeAutomaticDiscounts.length && !!pricingBands.length) {
          const discountIds = activeAutomaticDiscounts.map(
            ({ id }: Discount) => id
          )
          tasks.push(
            unpublishDiscounts({
              merchantId: merchant.id,
              discountIds,
              successCallback: refetchDiscounts
            })
          )
        }

        upsertDeliveryPricingBands({
          deliveryPricingBands: FormatPricingBandsForPersistence({
            pricingBands: [...pricingBands]
          }),
          storeId: store.id,
          orderType: 'SAMEDAY'
        })

        Promise.allSettled(tasks)
      })
      .finally(() => {
        message.destroy()
        message.success('Delivery Settings Updated', 1)
        refetch()
        setTimeout(refetchPricingBands, 6000)
      })
      .catch((err) => {
        message.error(
          `Unable to update same-day store settings due to ${err}`,
          5
        )
      })
  }

  useEffect(() => {
    setStore(cloneDeep(originalStore))
  }, [originalStore])

  useEffect(() => {
    if (!fetchingPricingBands && !!sourcePricingBands.length) {
      setPricingBands(sourcePricingBands)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePricingBands])

  useEffect(() => {
    const { sameday_delivery_minimum_order_value, settings } = store
    form.setFieldsValue({
      sameday_delivery_minimum_order_value,
      settings
    })

    if (store.settings) {
      if (store.settings.same_day_delivery_fixed_courier_fee_enabled === true) {
        setFeeType('custom')
      } else if (store.settings.same_day_rate_cards_enabled === true) {
        setFeeType('rate_card')
      } else {
        setFeeType('quoted')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store])

  useEffect(() => {
    // do not display any warning if saved fee type is not rate_card
    if (!store?.settings.same_day_rate_cards_enabled) return

    if (store?.settings.same_day_rate_cards_enabled && feeType === 'quoted')
      return warningModal({
        warningType: 'quoted',
        onOk: () => setFeeType('quoted'),
        onCancel: () => setFeeType('rate_card')
      })
    if (store?.settings.same_day_rate_cards_enabled && feeType === 'custom')
      return warningModal({
        warningType: 'custom',
        onOk: () => setFeeType('custom'),
        onCancel: () => setFeeType('rate_card')
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeType])

  useEffect(() => {
    // do not display anything if courier service is "my own drivers"
    if (!store?.settings.same_day_delivery_courier_partner) return
    if (courier === 'partner') return

    // guard clause, warning will only show up if partner has SCP set in their config and is switching away from it
    if (
      store?.settings.same_day_delivery_courier_partner &&
      courier === 'other'
    )
      setFeeType('custom')

    return warningModal({
      warningType: 'courier',
      onOk: () => {
        setCourier('other')
        setFeeType('custom')

        // deliberately sets the custom fee field to null
        if (
          store.settings.same_day_delivery_courier_custom_fixed_fee <= 0 ||
          !store.settings.same_day_delivery_courier_custom_fixed_fee ||
          form.getFieldValue('custom_fixed_fee')
        )
          return form.setFieldsValue({
            custom_fixed_fee: null
          })
      },
      onCancel: () => {
        setCourier('partner')
        setFeeType('rate_card')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courier])

  useEffect(() => {
    form.setFieldsValue({ delivery_interval: getDeliveryTimeslot() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courier])

  const getDeliveryTimeslot = () => {
    const deliveryInterval = store?.settings?.delivery_interval || 30
    const usingSCP = courier === 'partner'
    // in the event like a merchant is under its own drivers and selected timeslot below
    // 30 minutes, since SCP only serves 30 and 1 hour, we make sure we still catch it and default
    // it to 30 minutes
    return usingSCP && deliveryInterval < 30 ? 30 : deliveryInterval
  }

  const DisallowSave = () => {
    const emptyFeeType = feeType === ''
    const invalidDeliveryPricingBands = !!pricingBands.length
      ? ValidateDeliveryPricingBands({ pricingBands })
      : false
    return disabled || emptyFeeType || invalidDeliveryPricingBands
  }

  return (
    <>
      <Form
        data-testid='delivery-settings-form'
        layout='vertical'
        form={form}
        onFinish={onFinish}
        initialValues={{
          busy_delivery_area_touched: store.settings.custom_busy_delivery_area,
          busy_delivery_area_kml_upload:
            store.settings.busy_delivery_area_kml_upload,
          delivery_area: store.settings.delivery_area || [],
          busy_delivery_area: store.settings.busy_delivery_area || [],
          delivery_area_touched: store.settings.custom_delivery_area,
          kml_upload: !!store.settings.kml_upload,
          delivery_zone_type: store.settings.delivery_radius
            ? 'distance'
            : 'time',
          delivery_radius:
            store.settings.delivery_radius || DEFAULT_DELIVERY_RADIUS,
          delivery_time: store.settings.delivery_time || DEFAULT_DELIVERY_TIME,
          busy_delivery_radius:
            store.settings.busy_delivery_radius ||
            store.settings.delivery_radius ||
            DEFAULT_DELIVERY_RADIUS,
          busy_delivery_time:
            store.settings.busy_delivery_time ||
            store.settings.delivery_time ||
            DEFAULT_DELIVERY_TIME,
          busy_delivery_area_enabled:
            !!store.settings.busy_delivery_area_enabled,
          delivery_interval: getDeliveryTimeslot(),
          delivery_timeslot_order_limit:
            store.settings.delivery_timeslot_order_limit || 0,
          custom_fixed_fee:
            store.settings.same_day_delivery_courier_custom_fixed_fee || 0
        }}
      >
        <PageHeader title='Delivery' className='settings-title' />
        <Form.Item name='delivery_enabled' label='Same day delivery'>
          <Switch
            data-testid='same-day-switch'
            onChange={setEnabled}
            defaultChecked={enabled}
            disabled={disabled}
          />
        </Form.Item>

        {enabled && (
          <>
            <Row gutter={32} className='_mb-0'>
              <Col span='auto'>
                <Form.Item label='Courier service:' className='_mb-40'>
                  <CourierType
                    value={courier}
                    handleChange={(value) => {
                      setCourier(value)
                      if (
                        isEmpty(storePriceMatrix) &&
                        isEmpty(merchantPriceMatrix)
                      )
                        return setFeeType('quoted')
                      if (value === 'partner') return setFeeType('rate_card')
                      if (value === 'other') return setFeeType('custom')
                    }}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              {courier === 'other' && (
                <Col span='6'>
                  <Form.Item
                    name='courier_fee_vat_percentage'
                    label={<>Courier fee VAT (%):</>}
                    className='_mb-40'
                    initialValue={
                      store?.settings?.courier_fee_vat_percentage || 0
                    }
                    rules={vatPercentageFieldRule}
                  >
                    <InputNumber
                      placeholder='0'
                      className='input-percentage'
                      type='number'
                      max={100}
                      min={0}
                      data-testid='same-day-courier-fee-vat-percentage'
                      disabled={disabled}
                      onFocus={onFocusScrollNumber}
                      onBlur={onBlurScrollNumber}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Form.Item
              label={
                <>
                  Delivery pricing:
                  <Tooltip
                    placement='right'
                    title={
                      <>
                        <a
                          href='https://support.slerp.com/knowledge/delivery-costs'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Delivery Costs
                        </a>
                      </>
                    }
                    className='_ml-8'
                  >
                    <QuestionCircleOutlined className='_mt-2' />
                  </Tooltip>
                </>
              }
              className='_mb-40'
            >
              <DeliveryFee
                storeSlug={store.slug}
                value={feeType}
                handleChange={setFeeType}
                priceMatrix={
                  !!storePriceMatrix ? storePriceMatrix : merchantPriceMatrix
                }
                courier={courier}
                disabled={disabled}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  Delivery pricing by basket value:
                  <Tooltip
                    placement='right'
                    title={
                      <>
                        Delivery pricing by basket value is a tiered reduction
                        on delivery fees based on the net value of items being
                        ordered. They cannot be used with automatic delivery
                        discounts but do work with product-based discounts and
                        loyalty rewards.
                      </>
                    }
                    className='_ml-8'
                  >
                    <QuestionCircleOutlined className='_mt-2' />
                  </Tooltip>
                </>
              }
            >
              {fetchingPricingBands && (
                <Spinner message='Fetching delivery pricing bands...' />
              )}
              {!fetchingPricingBands && (
                <DeliveryPricingBands
                  pricingBands={pricingBands}
                  updateHandler={setPricingBands}
                />
              )}
            </Form.Item>

            <MinimumOrderValueInput
              inputName='sameday_delivery_minimum_order_value'
              defaultValue={
                Number(store.sameday_delivery_minimum_order_value) || 0
              }
              disabled={disabled}
              testId='minimum-order-value'
            />

            <TimeSlots
              formName='delivery_interval'
              value={getDeliveryTimeslot()}
              handleChange={(value) =>
                form.setFieldsValue({ delivery_interval: value })
              }
              disabled={disabled}
              timeslots={
                courier === 'partner'
                  ? SCP_DELIVERY_TIMESLOTS
                  : OWN_DRIVERS_DELIVERY_TIMESLOTS
              }
            />

            <DeliveryZone disabled={disabled} store={store} form={form} />

            <OrderLimit
              inputName='delivery_timeslot_order_limit'
              formRef={form}
              disabled={disabled}
            />
          </>
        )}
        <Form.Item>
          <Row justify='space-between' className='_mt-16'>
            <Col>
              <Button
                title='Cancel'
                className='_center-vertical _ml-auto'
                type='ghost'
                onClick={resetForm}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                title='Save'
                className='_center-vertical _ml-auto'
                disabled={DisallowSave()}
                onClick={submit}
              >
                Save
              </Button>
            </Col>
          </Row>
          <UpdateNotice updating={loading} updateError={error} />
        </Form.Item>
      </Form>
    </>
  )
}
export default DeliverySettings
