import React, { useState, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Row, Col, Drawer, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import partition from 'lodash/partition'
import { useSession } from '@slerp/accounts'
import { Discount, DiscountUsages } from '@slerp/controls'
import { useQuery } from '@apollo/client'
import { QUERY_MERCHANT_DISCOUNTS } from '../Merchants/MerchantQueries'
import DiscountTabs from './DiscountTabs'
import Codes from './Codes'
import Loading from '../Utils/Loading'
import DiscountForm from './DiscountForm'

type NumberOfUses = {
  [key: string]: number
}

const Discounts = () => {
  const { merchant } = useSession()
  const history = useHistory()
  let discountCodes: Discount[] = []
  let automaticDiscounts: Discount[] = []
  let numberOfUses: NumberOfUses = {}

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerTitle, setDrawerTitle] = useState('Discount')

  const { data, refetch } = useQuery(QUERY_MERCHANT_DISCOUNTS, {
    variables: { merchantId: merchant.id },
    fetchPolicy: 'no-cache'
  })

  if (data && data.discounts) {
    ;[automaticDiscounts, discountCodes] = partition(
      data.discounts,
      (disc) => disc.trigger === 'automatic'
    )
    data.discount_usages?.forEach((discountInfo: DiscountUsages) => {
      if (discountInfo.discount_usages) {
        const { discount_id, count } = discountInfo.discount_usages
        numberOfUses[discount_id] = count
      }
    })
  }

  const setDrawerVisibility = (visible: boolean) => {
    setDrawerVisible(visible)
  }

  useEffect(() => {
    if (
      history.location.pathname.includes('discounts/code/edit') ||
      history.location.pathname.includes('discounts/code/new')
    )
      return setDrawerVisible(true)
  }, [history.location.pathname])

  useEffect(() => {
    if (!data) return

    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const DiscountFormRoutes = () => (
    <>
      <Route
        path='/discounts/code/new'
        component={() => {
          setDrawerTitle('Add Discount')
          return <DiscountForm setDrawerVisibility={setDrawerVisibility} />
        }}
      />
      <Route
        path='/discounts/code/edit/:discount_id'
        component={() => {
          setDrawerTitle('Update Discount')
          return <DiscountForm setDrawerVisibility={setDrawerVisibility} />
        }}
      />
    </>
  )

  return (
    <>
      <Row data-testid='discounts-component'>
        {(data && data.discounts && history && (
          <>
            <Col span={24}>
              <DiscountTabs
                codes={
                  <Codes
                    discounts={discountCodes}
                    numberOfUses={numberOfUses}
                    refetch={refetch}
                  />
                }
                automatic={
                  <Codes
                    discounts={automaticDiscounts}
                    numberOfUses={numberOfUses}
                    refetch={refetch}
                  />
                }
              />
            </Col>
          </>
        )) || <Loading />}
      </Row>
      <Drawer
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false)
          refetch()
          history.replace('/discounts')
        }}
        width={720}
        title={drawerTitle}
        data-testid='discounts-form'
        closeIcon={
          <Tooltip title={<span>Be sure to hit save! </span>}>
            <CloseOutlined className='close-icon' />
          </Tooltip>
        }
        className='discounts-drawer'
      >
        <DiscountFormRoutes />
      </Drawer>
    </>
  )
}

export default Discounts
