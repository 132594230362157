import { gql } from '@apollo/client'
export const QUERY_LOYALTY_CARDS = gql`
  query getLoyaltyCards($merchant_id: uuid!, $isActive: Boolean! = true) {
    loyalty_cards(
      where: {
        merchant: { id: { _eq: $merchant_id } }
        archived_at: { _is_null: $isActive }
      }
      order_by: { inserted_at: desc }
    ) {
      id
      merchant_id
      code
      name
      description
      starts_at
      ends_at
      published
      store_ids
      product_variant_ids
      stamp_type
      stamps_per_scan
      stamp_value
      stamp_label
      image
      inserted_at
      theme
      updated_at
      archived_at
      all_stores_enabled
      frequency_limit_enabled
      interval
      interval_unit
      max_stamps_enabled
      max_stamps
      exclusive
      exclusive_loyalty_customers(where: { archived_at: { _is_null: true } }) {
        customer {
          email
          first_name
          last_name
          id
        }
      }
    }
  }
`

export const QUERY_EXCLUSIVE_LOYALTY_CARDS = gql`
  query getExclusiveLoyaltyCards($merchant_id: uuid!) {
    loyalty_cards(
      where: {
        merchant: { id: { _eq: $merchant_id } }
        archived_at: { _is_null: true }
        exclusive: { _eq: true }
      }
      order_by: { inserted_at: desc }
    ) {
      id
      merchant_id
      code
      name
      description
      starts_at
      ends_at
      published
      store_ids
      product_variant_ids
      stamp_type
      stamps_per_scan
      stamp_value
      stamp_label
      image
      inserted_at
      theme
      updated_at
      archived_at
      all_stores_enabled
      frequency_limit_enabled
      interval
      interval_unit
      max_stamps_enabled
      max_stamps
      exclusive_loyalty_customers(where: { archived_at: { _is_null: true } }) {
        id
        customer_id
      }
    }
  }
`

export const QUERY_LOYALTY_CARD_REWARDS = gql`
  query getLoyaltyCardRewards($merchant_id: uuid!) {
    rewards(
      where: {
        loyalty_card: { merchant_id: { _eq: $merchant_id } }
        archived_at: { _is_null: true }
      }
    ) {
      archived_at
      name
      description
      id
      inserted_at
      loyalty_card_id
      required_quantity
      earned_reward_message
      redeem_reward_message
      reward_value_type
      reward_type
      reward_value
      product_variant_ids
      product_limit
      variants_only
      updated_at
      minimum_value_enabled
      minimum_value
      minimum_value_fulfillments
      expiration_unit
      expiration
    }
  }
`

export const QUERY_LOYALTY_BONUSES = gql`
  query getLoyaltyBonuses($merchantId: uuid!) {
    loyalty_bonuses(
      where: { merchant: { id: { _eq: $merchantId } } }
      order_by: { inserted_at: desc }
    ) {
      id
      type
      published_at
      stamp_count
      loyalty_card {
        name
        id
      }
      store {
        name
        id
      }
      usages: loyalty_bonus_usages_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const UPSERT_LOYALTY_CARD_REWARDS = gql`
  mutation upsertLoyaltyCardRewards(
    $merchant_id: uuid!
    $id: uuid!
    $code: String!
    $name: String!
    $description: String
    $starts_at: timestamp!
    $ends_at: timestamp
    $stamps_per_scan: Int!
    $stamp_value: numeric
    $stamp_label: String!
    $stamp_type: String!
    $product_variant_ids: _varchar
    $store_ids: _varchar
    $all_stores_enabled: Boolean
    $exclusive: Boolean
    $frequency_limit_enabled: Boolean
    $interval: Int
    $interval_unit: String
    $max_stamps_enabled: Boolean
    $max_stamps: Int
    $rewards: [rewards_insert_input!]!
  ) {
    loyalty_card: insert_loyalty_cards(
      objects: [
        {
          merchant_id: $merchant_id
          id: $id
          name: $name
          code: $code
          description: $description
          starts_at: $starts_at
          ends_at: $ends_at
          inserted_at: "now()"
          updated_at: "now()"
          stamps_per_scan: $stamps_per_scan
          stamp_value: $stamp_value
          stamp_label: $stamp_label
          stamp_type: $stamp_type
          product_variant_ids: $product_variant_ids
          store_ids: $store_ids
          all_stores_enabled: $all_stores_enabled
          exclusive: $exclusive
          frequency_limit_enabled: $frequency_limit_enabled
          interval: $interval
          interval_unit: $interval_unit
          max_stamps_enabled: $max_stamps_enabled
          max_stamps: $max_stamps
        }
      ]
      on_conflict: {
        constraint: loyalty_cards_pkey
        update_columns: [
          name
          description
          starts_at
          ends_at
          updated_at
          stamps_per_scan
          stamp_value
          stamp_label
          stamp_type
          product_variant_ids
          store_ids
          all_stores_enabled
          exclusive
          frequency_limit_enabled
          interval
          interval_unit
          max_stamps_enabled
          max_stamps
        ]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
    rewards: insert_rewards(
      objects: $rewards
      on_conflict: {
        constraint: rewards_pkey
        update_columns: [
          description
          name
          required_quantity
          updated_at
          reward_value
          reward_value_type
          redeem_reward_message
          earned_reward_message
          reward_type
          product_variant_ids
          product_limit
          variants_only
          minimum_value_enabled
          minimum_value
          minimum_value_fulfillments
          expiration_unit
          expiration
        ]
      }
    ) {
      affected_rows
      returning {
        id
        months_expiration
      }
    }
  }
`

export const ARCHIVE_REWARD = gql`
  mutation archiveReward($id: uuid!) {
    reward: update_rewards_by_pk(
      pk_columns: { id: $id }
      _set: { archived_at: "now()", loyalty_card_id: null }
    ) {
      id
    }
  }
`

export const CREATE_LOYALTY_BONUS = gql`
  mutation createLoyaltyBonus(
    $id: uuid!
    $type: String!
    $stamp_count: Int!
    $merchant_id: uuid!
    $store_id: uuid!
    $loyalty_card_id: uuid!
  ) {
    loyalty_bonus: insert_loyalty_bonuses_one(
      object: {
        id: $id
        type: $type
        stamp_count: $stamp_count
        merchant_id: $merchant_id
        store_id: $store_id
        loyalty_card_id: $loyalty_card_id
        inserted_at: "now()"
        updated_at: "now()"
      }
    ) {
      id
    }
  }
`

export const UPDATE_LOYALTY_BONUS = gql`
  mutation updateLoyaltyBonus(
    $id: uuid!
    $type: String!
    $stamp_count: Int!
    $store_id: uuid!
    $loyalty_card_id: uuid!
  ) {
    loyalty_bonus: update_loyalty_bonuses_by_pk(
      pk_columns: { id: $id }
      _set: {
        type: $type
        stamp_count: $stamp_count
        store_id: $store_id
        loyalty_card_id: $loyalty_card_id
        updated_at: "now()"
      }
    ) {
      id
    }
  }
`

export const SET_CUSTOMER_REWARDS_EXPIRATION = gql`
  mutation SetCustomerRewardExpiration(
    $rewardId: ID!
    $monthsExpiration: Int!
  ) {
    setCustomerRewardsExpiration(
      rewardId: $rewardId
      monthsExpiration: $monthsExpiration
    ) {
      id
      end_datetime
    }
  }
`

export const STAMP_CUSTOMER_LOYALTY_CARD = gql`
  mutation stampCustomerLoyaltyCard(
    $customer_id: ID!
    $store_id: ID!
    $code: String!
    $stamps_per_scan: Int
  ) {
    stampCustomerLoyaltyCard(
      customer_id: $customer_id
      store_id: $store_id
      code: $code
      stamps_per_scan: $stamps_per_scan
    ) {
      earned_rewards_count
      earned_reward_messages
    }
  }
`

export const ADD_EXCLUSIVE_CARDS_TO_CUSTOMER = gql`
  mutation addCustomerExclusiveLoyaltyCards(
    $customerId: ID!
    $loyaltyCardIds: [String]!
    $merchantId: ID!
    $userId: ID!
  ) {
    addCustomerExclusiveLoyaltyCards(
      customerId: $customerId
      loyaltyCardIds: $loyaltyCardIds
      merchantId: $merchantId
      userId: $userId
    )
  }
`

export const UNREDEEM_CUSTOMER_LOYALTY_CARDS = gql`
  mutation unredeemCustomerLoyaltyCards($loyaltyCardId: uuid!) {
    update_customer_loyalty_cards(
      where: { loyalty_card_id: { _eq: $loyaltyCardId } }
      _set: { redeemed_at: "now()", redeemed: false }
    ) {
      affected_rows
      returning {
        id
        redeemed_at
        redeemed
      }
    }
  }
`

export const REDEEM_CUSTOMER_REWARD = gql`
  mutation redeemCustomerReward($customerRewardId: ID!, $loyaltyCode: String!) {
    redeemCustomerReward(
      customer_reward_id: $customerRewardId
      code: $loyaltyCode
    ) {
      id
    }
  }
`

export const UPDATE_ARCHIVE_SETTINGS = gql`
  mutation updateArchiveSettings(
    $archived_at: timestamp
    $merchant_id: uuid!
    $card_id: uuid!
  ) {
    update_loyalty_cards(
      where: { id: { _eq: $card_id }, merchant_id: { _eq: $merchant_id } }
      _set: { archived_at: $archived_at, updated_at: "now()" }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_BONUS_PUBLISHED = gql`
  mutation updateBonusPublished($publishedAt: timestamp, $id: uuid!) {
    loyalty_bonus: update_loyalty_bonuses_by_pk(
      pk_columns: { id: $id }
      _set: { published_at: $publishedAt, updated_at: "now()" }
    ) {
      id
    }
  }
`

export const UPDATE_PUBLISH_SETTINGS = gql`
  mutation updatePublishSettings(
    $published: Boolean
    $merchant_id: uuid!
    $card_id: uuid!
  ) {
    update_loyalty_cards(
      where: { id: { _eq: $card_id }, merchant_id: { _eq: $merchant_id } }
      _set: { published: $published, updated_at: "now()" }
    ) {
      affected_rows
      returning {
        id
        published
      }
    }
  }
`

export const UPLOAD_LOYALTY_CARD_IMAGE = gql`
  mutation uploadLoyaltyCardImage($loyalty_card_id: ID, $image: Upload) {
    uploadLoyaltyCardImage(loyaltyCardId: $loyalty_card_id, image: $image) {
      id
    }
  }
`

export const QUERY_LOYALTY_CARD_NAME_UPDATE_VALIDATION = gql`
  query getLoyaltyCardByNameAndId(
    $merchantId: uuid!
    $name: String!
    $loyaltyCardId: uuid!
  ) {
    loyalty_cards(
      where: {
        merchant: { id: { _eq: $merchantId } }
        name: { _eq: $name }
        id: { _neq: $loyaltyCardId }
      }
    ) {
      id
    }
  }
`

export const QUERY_LOYALTY_CARD_NAME_INSERT_VALIDATION = gql`
  query getLoyaltyCardByName($merchantId: uuid!, $name: String!) {
    loyalty_cards(
      where: { merchant: { id: { _eq: $merchantId } }, name: { _eq: $name } }
    ) {
      id
    }
  }
`

const CustomersAggregateFragment = `
  id
  first_name
  last_name
  marketing_opt_in
  email
  stamps: stamps_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      store_id: { _in: $storeIds }
      forfeited_at: { _is_null: true }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_cards: customer_loyalty_cards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_rewards: customer_rewards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  orders: orders_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      store_id: { _in: $storeIds }
    }
  ) {
    aggregate {
      count
      sum {
        total
      }
    }
  }
`

const BulkCustomersAggregateFragment = `
  id
  first_name
  last_name
  marketing_opt_in
  email
  stamps: stamps_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      store_id: { _is_null: true }
      forfeited_at: { _is_null: true }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_cards: customer_loyalty_cards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_rewards: customer_rewards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  orders: orders_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      store_id: { _in: $storeIds }
    }
  ) {
    aggregate {
      count
      sum {
        total
      }
    }
  }
`

const CustomersAndBulkCustomersAggregateFragment = `
  id
  first_name
  last_name
  marketing_opt_in
  email
  stamps: stamps_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      _or: [
        {store_id: {_in: $storeIds}},
        {store_id: {_is_null: true}}
      ]
      forfeited_at: { _is_null: true }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_cards: customer_loyalty_cards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  redeemed_rewards: customer_rewards_aggregate(
    where: {
      redeemed_at: {
        _is_null: false, _gte: $startDate, _lte: $endDate
      }
    }
  ) {
    aggregate {
      count
    }
  }
  orders: orders_aggregate(
    where: {
      inserted_at: { _gte: $startDate, _lte: $endDate }
      store_id: { _in: $storeIds }
    }
  ) {
    aggregate {
      count
      sum {
        total
      }
    }
  }
`

export const QUERY_LOYALTY_CUSTOMERS_AGGREGATES = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
  ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        email: { _eq: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_in: $storeIds},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAggregateFragment}
    }
  }
`

export const QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
    ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        email: { _like: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_is_null: true},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${BulkCustomersAggregateFragment}
    }
  }
`

export const QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_AGGREGATES = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
    ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        email: { _like: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              _or: [
                {store_id: {_in: $storeIds}},
                {store_id: {_is_null: true}}
              ]
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAndBulkCustomersAggregateFragment}
    }
  }
`

export const QUERY_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
    ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        marketing_opt_in: { _eq: true }
        email: { _like: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_in: $storeIds},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAggregateFragment}
    }
  }
`

export const QUERY_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
    ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        marketing_opt_in: { _eq: true }
        email: { _like: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_is_null: true},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${BulkCustomersAggregateFragment}
    }
  }
`

export const QUERY_LOYALTY_CUSTOMERS_AND_BULK_LOYALTY_CUSTOMERS_AGGREGATES_OPT_IN = gql`
  query GetLoyaltyCustomersAggregates(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $email: String
    ) {
    customers(
      where: {
        merchant_id: { _eq: $merchantId }
        marketing_opt_in: { _eq: true }
        email: { _like: $email}
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              _or: [
                {store_id: {_in: $storeIds}},
                {store_id: {_is_null: true}}
              ]
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAndBulkCustomersAggregateFragment}
    }
  }
`

export const QUERY_LOYALTY_CUSTOMERS_BY_FILTER = gql`
  query GetLoyaltyCustomers(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $limit: Int!
    $offset: Int!
    $order_by: [customers_order_by!]
    $firstName: String
    $lastName: String
    $email: String
  ) {
    customers(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: {
        merchant_id: { _eq: $merchantId }
        email: { _ilike: $email }
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_in: $storeIds},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAggregateFragment}
    }
  }
`
export const QUERY_LOYALTY_CUSTOMERS_BY_FILTER_OPT_IN = gql`
  query GetLoyaltyCustomers(
    $merchantId: uuid!
    $startDate: timestamp!
    $endDate: timestamp!
    $storeIds: [uuid!]
    $limit: Int!
    $offset: Int!
    $order_by: [customers_order_by!]
    $firstName: String
    $lastName: String
    $email: String
  ) {
    customers(
      limit: $limit
      offset: $offset
      order_by: $order_by
      where: {
        merchant_id: { _eq: $merchantId }
        marketing_opt_in: { _eq: true }
        email: { _ilike: $email }
        _or: [
          {
            inserted_at: {_gte: $startDate, _lte: $endDate}
          }
          {
            stamps: {
              store_id: {_in: $storeIds},
              inserted_at: {_gte: $startDate, _lte: $endDate}
              forfeited_at: { _is_null: true }
            }
          }
          {
            customer_loyalty_cards: {
              redeemed_at: {_is_null: false, _gte: $startDate, _lte: $endDate}
            }
          }
          {
            orders: {inserted_at: {_gte: $startDate, _lte: $endDate}, store_id: {_in: $storeIds}}
          }
        ]
      }
    ) {
      ${CustomersAggregateFragment}
    }
  }
`

export const GET_CATEGORY_PRODUCTS = gql`
  query getCategoryProductsForLoyalty($merchantId: uuid!) {
    categories(
      where: {
        archived_at: { _is_null: true }
        merchant_id: { _eq: $merchantId }
      }
      order_by: { name: asc }
    ) {
      id
      name
      products(where: { archived_at: { _is_null: true } }) {
        id
        name
        product_variants(where: { archived_at: { _is_null: true } }) {
          name
          id
        }
      }
    }
  }
`

export const QUERY_CUSTOMER_CARDS_AND_REWARDS = gql`
  query getCustomerCardsAndRewards($customerId: uuid!) {
    customer_loyalty_cards(
      where: {
        customer_id: { _eq: $customerId }
        archived_at: { _is_null: true }
      }
    ) {
      id
      redeemed
      redeemed_at
      loyalty_card {
        id
        name
        code
      }
      customer_rewards {
        id
        end_datetime
        redeemed_at
        reward {
          id
          name
        }
      }
    }
  }
`

export const QUERY_GET_LOYALTY_CUSTOMERS_LIST = gql`
  query getLoyaltyCustomersList(
    $merchantId: ID!
    $startDate: String!
    $endDate: String!
    $storeIds: [ID]!
    $customerIds: [ID]!
    $optInFilter: Boolean!
    $limit: Int!
    $offset: Int!
    $sortBy: String!
    $sortDirection: String!
  ) {
    loyaltyCustomersList(
      merchantId: $merchantId
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      customerIds: $customerIds
      optInFilter: $optInFilter
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      id
      merchantId
      firstName
      lastName
      email
      birthdate
      insertedAt
      numberOfStamps
      allTimeRedeemedRewards
      redeemedRewards
      totalSpent
    }
  }
`

export const GENERATE_STAMP_QR_TOKEN = gql`
  mutation GenerateQrStampToken(
    $loyaltyCardId: uuid!
    $secondsExpiration: Int
  ) {
    generateStampToken(
      loyaltyCardId: $loyaltyCardId
      secondsExpiration: $secondsExpiration
    ) {
      code
      expires_at
    }
  }
`

export const CHECK_EMAILS = gql`
  query checkCustomerEmails($merchantId: ID!, $emails: [String]) {
    checkEmails(merchantId: $merchantId, emails: $emails) {
      valid {
        id
        first_name
        last_name
        email
      }
      invalid
    }
  }
`

export const BULK_UPDATE_CUSTOMER_STAMP = gql`
  mutation bulkStampCustomersLoyaltyCard(
    $code: String!
    $emails: [String]!
    $merchant_id: ID!
    $stamps_per_scan: Int!
    $user_id: ID!
  ) {
    bulkStampCustomersLoyaltyCard(
      userId: $user_id
      stampsPerScan: $stamps_per_scan
      merchantId: $merchant_id
      emails: $emails
      code: $code
    )
  }
`

export const BULK_ADD_CUSTOMERS_TO_EXCLUSIVE_CARD = gql`
  mutation bulkAddCustomersExclusiveLoyaltyCard(
    $cardId: ID!
    $customerIds: [String]!
    $merchantId: ID!
    $userId: ID!
  ) {
    bulkAddCustomersExclusiveLoyaltyCard(
      cardId: $cardId
      customerIds: $customerIds
      merchantId: $merchantId
      userId: $userId
    )
  }
`

export const QUERY_STAMP_LOGS = gql`
  query getStampLogs($stampId: uuid!, $merchantId: uuid!) {
    stamp_logs(
      where: { id: { _eq: $stampId }, merchant_id: { _eq: $merchantId } }
    ) {
      customer_count
      to_stamp_count
      total_stamp_count
      finished_at
      failed_email_stamps
      id
    }
  }
`

export const GET_ACTIVE_MERCHANT_CATEGORIES = gql`
  query getMerchantCategories($merchantId: uuid!, $active: Boolean = true) {
    categories(
      where: {
        merchant_id: { _eq: $merchantId }
        archived_at: { _is_null: $active }
      }
      order_by: { name: asc }
    ) {
      id
      name
      products(where: { archived_at: { _is_null: true } }) {
        name
      }
    }
  }
`

export const GET_PRODUCTS_CATEGORIES_BY_CATEGORY_IDS = gql`
  query productsCategories($categoryIds: [products_categories_bool_exp]) {
    products_categories(
      where: { _or: $categoryIds, product: { archived_at: { _is_null: true } } }
    ) {
      category_id
      product {
        product_variants {
          id
          name
        }
      }
    }
  }
`
